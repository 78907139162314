import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAcuracidade } from './context';
import { FilterBody } from 'components/filter/models/TableFilterModels';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { TableFilter } from 'components/filter/TableFilter';
import ManualPaging from 'components/dataTables/ManualPaging';
import ProductInfiniteList from './tableSubLevelIniniteScroll';
import { renderColumns } from './utils';

const TableInifiteScroll = ({ headers, rows, filterMap }) => {
	const {
		pagination,
		setPagination,
		tableFilters,
		setTableFilters,
		mutateAccuracyDataTable: refetchData,
	} = useAcuracidade();

	const [expandedRows, setExpandedRows] = useState<string[]>([]);

	const [mapaFiltros, setMapaFiltros] = useState<FilterBody[] | []>([]);

	const getCellWidth = useCallback((el) => {
		return el?.key === 'descricao' ? '320px !important' : '92px !important';
	}, []);

	const keysHeaders = useMemo(
		() =>
			headers?.map((el) => ({
				key: el?.key,
				prefixo: el?.prefixo || '',
				sufixo: el?.sufixo || '',
				canTruncValue: el?.canTruncValue || false,
				decimalPrecision: el?.decimalPrecision,
				noDataHelp: el?.noDataHelp,
			})) || [],
		[headers],
	);

	const headersTags = useMemo(() => {
		if (!headers?.length) return null;

		return headers.map((el) => (
			<Flex
				key={el.key}
				textAlign='center'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				color='easyBLUE.300'
				fontWeight='bold !important'
				fontSize='10px !important'
				w={getCellWidth(el)}
			>
				{el?.label?.toUpperCase?.() || ''}
			</Flex>
		));
	}, [headers, getCellWidth]);

	const toggleRow = useCallback(
		(rowName: string) => {
			if (expandedRows.includes(rowName)) {
				setExpandedRows(
					expandedRows.filter((name) => name !== rowName),
				);
			} else {
				setExpandedRows([...expandedRows, rowName]);
			}
		},
		[expandedRows],
	);

	const isRowExpanded = useCallback(
		(rowName) => expandedRows.includes(rowName),
		[expandedRows],
	);

	const renderRows = useCallback(
		(items, level = 0) => {
			if (!items?.length)
				return (
					<Flex direction='row'>
						<Flex
							pl={`${(30 * level) / 2}px`}
							w='40px !important'
						/>
						<Flex
							w='100%'
							justifyContent='center'
							alignItems='center'
						>
							<Box
								color='easyBLUE.300'
								fontSize='sm'
								pt='5px'
								pb='5px'
							>
								Nenhum dado encontrado
							</Box>
						</Flex>
					</Flex>
				);

			return items?.map((item, index) => {
				return (
					<>
						<Flex
							key={index}
							direction='row'
							_hover={{
								backgroundColor: '#f4f7fe !important',
								color: '#42adef !important',
							}}
							style={
								level === 0
									? {
											backgroundColor: '#f6f6f8',
											// borderBottom: '1px solid #e1e8ef',
									  }
									: {}
							}
							pt='10px'
							pb='10px'
						>
							<Flex
								pl={`${(30 * level) / 2}px`}
								w='40px !important'
							>
								<IconButton
									aria-label={`${item?.descricao}`}
									onClick={() => toggleRow(item?.descricao)}
									size='sm'
									icon={
										isRowExpanded(item?.descricao) ? (
											<ChevronDownIcon />
										) : (
											<ChevronRightIcon />
										)
									}
									w={6}
									h={6}
									color='black !important'
								/>
							</Flex>
							{renderColumns(keysHeaders, item, level)}
						</Flex>
						{isRowExpanded(item?.descricao) && (
							<ProductInfiniteList
								familiaId={item?.familia_id}
								keysHeaders={keysHeaders}
							/>
						)}
						<Flex
							h='2.5px'
							w='100%'
						/>
					</>
				);
			});
		},
		[isRowExpanded, keysHeaders, toggleRow],
	);

	const updateFilters = useCallback(
		(newFilters) => {
			setPagination((prev) => ({ ...prev, page: 0 }));
			setTableFilters(newFilters);
		},
		[setPagination, setTableFilters],
	);

	const renderFilters = useCallback(() => {
		return (
			<Box pb='10px'>
				<TableFilter
					mapFilters={mapaFiltros}
					filterTooltip='Filtrar (aplicado apenas no nível de produtos)'
					filters={tableFilters}
					setFilters={updateFilters}
					module='acuracidade'
					getFromExternalAPI={true}
				/>
			</Box>
		);
	}, [mapaFiltros, tableFilters, updateFilters]);

	const renderTable = useCallback(() => {
		return (
			<Box>
				<Flex
					direction='column'
					w='100%'
					boxShadow='1px 1px 1px 1.25px #ededed'
					borderRadius='md'
					style={{
						borderCollapse: 'separate',
						borderSpacing: '0px 2.5px',
					}}
				>
					{!!headersTags?.length && (
						<Flex
							direction='row'
							p='10px 0px 10px 0px'
						>
							<Flex
								color='easyBLUE.300'
								w='40px !important'
							/>
							{headersTags}
						</Flex>
					)}
					<Flex direction='column'>{renderRows(rows)}</Flex>
				</Flex>
				<ManualPaging
					pagination={pagination}
					setPagination={setPagination}
					refetchData={refetchData}
				/>
			</Box>
		);
	}, [headersTags, pagination, refetchData, renderRows, rows, setPagination]);

	useEffect(() => {
		if (!!filterMap?.length) setMapaFiltros(filterMap);
	}, [filterMap]);

	return (
		<Flex
			direction='column'
			pt='30px'
		>
			{renderFilters()}
			{renderTable()}
		</Flex>
	);
};

export default TableInifiteScroll;
