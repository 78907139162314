import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { exportRelatedCustomer } from 'services/api/requests/customer';

export const useExportRelatedCustomer = (cicloId: string) => {
	const toast = useToast();
	return useQuery(
		['export-clienteRelacionado-list', cicloId],
		exportRelatedCustomer,
		{
			refetchOnWindowFocus: false,
			retry: 1,
			enabled: false,
			onSuccess: () => {
				toast({
					title: 'Sucesso!',
					description: 'Exportação do arquivo realizada com sucesso!',
					status: 'success',
					duration: 8000,
					isClosable: true,
				});
			},
			onError: (err: any) => {
				toast({
					title: 'Erro!',
					description:
						'Não foi possível realizar a exportação do arquivo!',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
		},
	);
};
