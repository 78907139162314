import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';
import { updateSalesZone } from 'services/api/requests/salesZone';

export const useEditSalesZone = (id, body, onClose) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => updateSalesZone(id, body), {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['zonaVenda-list']);
			queryClient.invalidateQueries(['salesZoneById', id]);
			queryClient.invalidateQueries(['salesZoneWithId', id]);

			toast({
				title: 'Atualizada com sucesso!',
				description: 'Força de venda atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});

			onClose?.();
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao editar Força de venda!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
