import alb from 'services/api/alb';
import api from 'services/api/api';

export const fetchDataByFilters = async (module, body) => {
	const url = `/${module}/view`;
	const response = await api.post(url, body);
	return response.data;
};

export const fetchDataByFiltersAiPrediction = async ({ page, page_size }) => {
	const response = await alb.get('/core/ai-prediction/view', {
		params: {
			page,
			page_size,
		},
	});

	return {
		dados: response?.data?.data,
		dependencias: [],
		mapaFiltros: [],
		total_linhas: response?.data.count_lines,
	};
};
