import { useQuery } from '@tanstack/react-query';
import { getSaleById } from 'services/api/requests/commercial';

export const useGetSaleWithId = (id: Number) => {
	return useQuery(['saleWithId', id], getSaleById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
