import React, { useState, useEffect } from 'react';
import {
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { IconRobot } from '@tabler/icons';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	addWebSocketListener,
	removeWebSocketListener,
} from 'services/api/apiWebSocketBitec';
import { globalStyles } from 'theme/styles';

const RobotEasy = () => {
	const { userAttributes } = useGlobalContext();
	const navbarIcon = useColorModeValue('gray.400', 'white');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
	);
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue(
		'#E6ECFA',
		'rgba(135, 140, 189, 0.3)',
	);
	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const [hasNewLogs, setHasNewLogs] = useState(false);

	useEffect(() => {
		const customCnpj = userAttributes?.['custom:CNPJ'];

		const handler = () => {
			setHasNewLogs(true);
		};

		addWebSocketListener(customCnpj, handler);

		return () => {
			removeWebSocketListener(customCnpj, handler);
		};
	}, [userAttributes]);

	const handleLastProcessingClick = () => {
		setHasNewLogs(false);
		history.push(`/${pathCompanyName}/bots/logs`);
	};

	return (
		<Menu>
			<TooltipStandard label={'Processamento Robô'}>
				<MenuButton
					p='0px'
					color={navbarIcon}
					_hover={{ color: '#314866', cursor: 'pointer' }}
					_active={{ color: '#02044A' }}
				>
					<Box>
						<Icon
							as={IconRobot}
							boxSize={5}
							display={'flex'}
							justifyContent={'center'}
						/>
						{hasNewLogs && <NotificationIcon />}
					</Box>
				</MenuButton>
			</TooltipStandard>
			<MenuList
				boxShadow={shadow}
				p='0px'
				mt='10px'
				borderRadius='20px'
				bg={menuBg}
				border='none'
				w={'22rem'}
			>
				<div
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
						backgroundImage: `url('assets/iconRobotEasy.webp')`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'right center',
						backgroundSize: 'contain',
						opacity: 0.1,
						pointerEvents: 'none',
					}}
				/>
				<Flex
					w='100%'
					mb='0px'
					pt={'2em'}
					marginLeft={'1em'}
					flexDirection={'column'}
					alignItems={'start'}
					position='relative'
				>
					<Text
						display={'flex'}
						flexDirection={'row'}
						textAlign={'center'}
						alignItems={'center'}
						borderColor={borderColor}
						fontSize='md'
						fontWeight='700'
						color={textColor}
						paddingRight={'2em'}
					>
						<IconRobot size={'42px'} />
						Processamento Robô
					</Text>
				</Flex>
				<Flex
					flexDirection='column'
					p='10px'
				>
					<MenuItem
						_hover={{ bg: 'none' }}
						_focus={{ bg: 'none' }}
						borderRadius='8px'
						px='14px'
						onClick={() =>
							history.push(`/${pathCompanyName}/bots/settings`)
						}
					>
						<Text
							fontSize='sm'
							color='easyBLUE.300'
							_hover={{ color: 'easyBLUE.200' }}
						>
							Configurações
						</Text>
					</MenuItem>
					<MenuItem
						_hover={{ bg: 'none' }}
						_focus={{ bg: 'none' }}
						borderRadius='8px'
						px='14px'
						onClick={handleLastProcessingClick}
					>
						{hasNewLogs ? (
							<TooltipStandard
								label={'Você tem novos logs de importação'}
							>
								<Text
									fontSize='sm'
									fontWeight='bold'
									color='easyRED.300'
									_hover={{ color: 'red.600' }}
								>
									Atividades Recentes
								</Text>
							</TooltipStandard>
						) : (
							<Text
								fontSize='sm'
								color='easyBLUE.300'
								_hover={{ color: 'easyBLUE.200' }}
							>
								Atividades Recentes
							</Text>
						)}
					</MenuItem>
				</Flex>
			</MenuList>
		</Menu>
	);
};
export default RobotEasy;

const Box = styled.div`
	position: relative;
`;

const NotificationIcon = styled.span`
	position: absolute;
	right: 2px;
	bottom: 0;
	width: 5px;
	height: 5px;
	background-color: ${globalStyles.colors.easyRED[300]};
	border-radius: 50%;
`;
