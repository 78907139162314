import { currencyToNumber } from 'utils/functions/currencyToNumber';
import {
	columnFlexProps,
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import {
	renderClient,
	renderFamily,
	renderProduct,
} from 'utils/forms/autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreatePortfolio } from 'services/queryClient/wrapperHooks/portfolio/useCreateProtfolio';
import { useEditPortfolio } from 'services/queryClient/wrapperHooks/portfolio/useEditPortfolio';
import { useHistory } from 'react-router-dom';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';
import {
	validateByRegex,
	validateMin,
	validateMinDecimal,
	validateRequired,
} from '../../utils/forms/validations';

interface IFormCadastrarEditarCarteira {
	initialState: any;
	portfolioId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarCarteira = ({
	initialState,
	portfolioId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarCarteira) => {
	const history = useHistory();

	const isEditing = !!portfolioId;

	const { data: SalesChannelData, isLoading: isLoadingSalesChannelData } =
		useSalesChannelList();

	const [formInitialState, setFormInitialState] = useState<any>();

	const [familyId, setFamilyId] = useState<number>();
	const [payload, setPayload] = useState<any>();

	const { mutate: createPortfolio } = useCreatePortfolio(payload);
	const { mutate: updatePortfolio } = useEditPortfolio(payload, portfolioId);

	useEffect(() => {
		setFamilyId(initialState?.familia_id);
		setFormInitialState(initialState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialState]);

	const goToPortfolio = useCallback(() => {
		history.goBack();
	}, [history]);

	const salesChannelOptions = useMemo(() => {
		return (
			SalesChannelData?.map((channel) => {
				return (
					<option value={channel?.id}>
						{channel?.nome_canal_venda}
					</option>
				);
			}) || []
		);
	}, [SalesChannelData]);

	const handleOnChangeFamily = useCallback((value) => {
		setFamilyId(value?.id);
	}, []);

	const handleOnChangeCode = useCallback((event, form) => {
		form.setValue('sequencia_codigo', '');
	}, []);

	const isDisabledProductAutocomplete = useCallback(() => {
		return !familyId;
	}, [familyId]);

	const shouldClearAutocompleteProduct = useCallback(() => {
		if (!familyId) return false;

		return familyId !== autocompleteAdditionalData?.product?.familia?.id;
	}, [familyId, autocompleteAdditionalData?.product?.familia?.id]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				data: values?.data,
				codigo: values?.codigo,
				sequencia_codigo: values?.sequencia_codigo,
				cliente_id: values?.cliente_id,
				familia_id: values?.familia_id,
				produto_id: values?.produto_id,
				quantidade: currencyToNumber(values?.quantidade),
				canal_venda_id: Number(values?.canal_venda_id),
				receita_bruta: currencyToNumber(values?.receita_bruta),
				faturado: values?.faturado,
				ruim: values?.ruim,
			};

			setPayload(body);

			if (isEditing) updatePortfolio();
			else createPortfolio();
		},
		[createPortfolio, isEditing, updatePortfolio],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'switch',
							name: 'ruim',
							label: 'Carteira de Pedido é Ruim?',
							inputProps: {
								'data-test':
									'switch-carteira_de_pedido_e_ruim-cadastrar_editar-page-carteira_de_pedidos',
								colorScheme: 'red',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps: {
					flexDirection: 'row',
					width: '100%',
				},
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'codigo',
							label: 'Código da Carteira de Pedido',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
							inputProps: {
								'data-test':
									'input-codigo_da_carteira_de_pedido-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
								onChange: handleOnChangeCode,
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'sequencia_codigo',
							label: 'Sequência do Cód. da Carteira de Pedido',
							validate: {
								...validateMin(
									1,
									'O valor deve ser maior ou igual a 1!',
								),
								...validateByRegex(
									/^[1-9]\d*$/,
									'O valor deve ser um número inteiro válido!',
								),
							},
							inputProps: {
								'data-test':
									'input-sequencia_do_cod_da_carteira_de_pedido-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'date',
							name: 'data',
							label: 'Competência',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-competencia-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda_id',
							label: 'Canal de venda',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
								selectOptions: salesChannelOptions,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'cliente_id',
							label: 'Cliente',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-cliente-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
								searchType: 'cliente',
								renderInputLabel: renderClient,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.client
									? renderClient(
											autocompleteAdditionalData?.client,
									  )
									: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
								onChange: handleOnChangeFamily,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'produto_id',
							label: 'Produto',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: isDisabledProductAutocomplete,
							inputProps: {
								'data-test':
									'autocomplete-produto-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
								searchType: 'produto',
								renderInputLabel: renderProduct,
								autocomplete: 'off',
								initialLabel:
									autocompleteAdditionalData?.product
										? renderProduct(
												autocompleteAdditionalData?.product,
										  )
										: '',
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteProduct,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'product-quantity',
							name: 'quantidade',
							label: 'Quantidade',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								validate: (value) =>
									validateMinDecimal(
										value,
										0,
										'O valor deve ser maior ou igual a 0!',
									),
							},
							inputProps: {
								'data-test':
									'input-quantidade-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'receita_bruta',
							label: 'Receita bruta',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-receita_bruta-cadastrar_editar-page-carteira_de_pedidos',
								...defaultInputStyle,
								maskConfig: {
									allowNegative: false,
								},
							},
						}),
					},
				],
			},
		];
	}, [
		salesChannelOptions,
		autocompleteAdditionalData,
		handleOnChangeFamily,
		isDisabledProductAutocomplete,
		familyId,
		shouldClearAutocompleteProduct,
	]);

	const renderForm = useCallback(() => {
		if (isLoadingSalesChannelData) return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-carteira_de_pedidos',
						save: 'button-salvar-page-carteira_de_pedidos',
					}}
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToPortfolio}
				/>
			</>
		);
	}, [
		formInitialState,
		renderRows,
		onSubmit,
		goToPortfolio,
		isLoadingSalesChannelData,
	]);

	return renderForm();
};

export default FormCadastrarEditarCarteira;
