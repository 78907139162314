import {
	Box,
	ButtonGroup,
	Flex,
	FormControl,
	FormErrorMessage,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { IconClipboardCheck } from '@tabler/icons';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import RenderGenericInputForm from 'components/forms/RenderGenericInputForm';
import { RecalculatePlanoRestritoModal } from 'components/modal/RecalculatePlanoRestritoModal';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import GenericTreeViewInfiniteScroll from 'components/treeViewInfinityScroll/GenericTreeViewInfiniteScroll';
import { useGlobalContext } from 'contexts/GlobalContext';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	getFamiliesRestrictedPlanByCycle,
	getProductsRestrictedPlanByCycle,
} from 'services/api/requests/restrictedPlan';
import { useExportRestrictedPlan } from 'services/queryClient/wrapperHooks/restrictedPlan/useExportRestrictedPlan';
import { useUpdateRestrictedPlanByCycle } from 'services/queryClient/wrapperHooks/restrictedPlan/useUpdateRestrictedPlanByCycle';
import { numberToLocaleString } from 'utils/functions/number';
import { currencyToNumber } from 'utils/functions/numberToFormateString';
import {
	ajustColumnMonthsFromCicle,
	buildFooterFromCycle,
	buildFooterFromCycleAndStaticData,
	buildHeaderFromCycle,
	getColumnMonthChanges,
	getColumnWidthFromCycle,
	getNewTotalColumnValue,
	getNewTotalGeneralValue,
	getTotalGeralOriginal,
	getTotalIndex,
	renderFamily,
	renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan,
	resetForm,
	resetRenderizedObjectIds,
	transformDataToFormList,
	updateTotalProductRow,
} from 'utils/functions/treeview';

interface EditarPlanoModalProps {
	isOpen: boolean;
	onClose: () => void;
	cycleId: number | string | undefined;
	CycleData: any;
	checkReopenModalFromAction: any;
}

const EditRestrictedPlanModal = (props: EditarPlanoModalProps) => {
	const { isOpen, onClose, cycleId, CycleData, checkReopenModalFromAction } =
		props;

	const { configsCompany } = useGlobalContext();

	const { ...formProps } = useForm({});

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		watch,
		setValue,
		getValues,
	} = formProps;

	const {
		isOpen: isOpenModal,
		onOpen: onOpenModal,
		onClose: onCloseModal,
	} = useDisclosure();

	const [currentCycle, setCurrentCycle] = useState<any>();

	const [hasRestrictedPlan, setHasRestrictedPlan] = useState(false);

	const renderizedObjectIds = useRef<Map<string, Set<number | string>>>(
		new Map(),
	);

	const onUpdateData = useCallback(() => {
		resetForm(getValues, setValue);
		resetRenderizedObjectIds(renderizedObjectIds);

		checkReopenModalFromAction?.(true);
	}, [checkReopenModalFromAction, getValues, setValue]);

	const {
		mutateAsync: RestrictedPlanUpdate,
		isLoading: isLoadingRestrictedPlan,
	} = useUpdateRestrictedPlanByCycle(onUpdateData);

	const columnWidth = useMemo(
		() => getColumnWidthFromCycle(currentCycle),
		[currentCycle],
	);

	const buildHeader = useCallback(() => {
		return buildHeaderFromCycle({ cycle: currentCycle });
	}, [currentCycle]);

	const buildFooter = useCallback(
		({ data, formListName, append, remove }) => {
			buildFooterFromCycle({
				data,
				levelListName: formListName,
				append,
				remove,
				getValues,
				configsCompany,
				keyArrayName: 'months',
				keyDataId: 'familia_id',
				keyDataMonthName: 'mes_ano',
				keyDataValueName: 'quantidade_produtos',
				keyTotalName: 'descricao',
			});

			return buildFooterFromCycleAndStaticData({
				formListName,
				getValues,
				formProps,
				errors,
				configsCompany,
				columnWidth,
				canRenderTotalColumn: true,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[configsCompany, columnWidth],
	);

	const renderProductRow = useCallback(
		({ row, index, formListName }) => {
			return renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan(
				{
					row,
					index,
					formListName,
					formProps,
					errors,
					configsCompany,
					columnWidth,
				},
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[columnWidth, configsCompany],
	);

	const formParams = useMemo(
		() => ({
			formListName: 'family',
			renderizedObjectIds: renderizedObjectIds,
			formProps,
			manageData: [
				{
					deeper: 0,
					getSharedDataChildren: ({ data, parentId }) => {
						if (!data || !parentId) return {};

						const parent = data?.pages
							?.reduce((acc, el) => {
								return [...acc, ...(el?.rows || [])];
							}, [])
							.find((el) => el?.id === parentId);

						const formattedArrayData = ajustColumnMonthsFromCicle({
							arrayData: parent?.quantidadeProdutosPlanoRestrito,
							keyColumnValue: 'quantidade_produtos',
							cycleStartDate: currentCycle?.data_inicio,
							cycleLength: currentCycle?.duracao,
						});

						return {
							props:
								{
									months: formattedArrayData || [],
									id: parentId,
									total: parent?.quantidade_produtos || 0,
								} || {},
						};
					},
				},
				{
					deeper: 1,
					renderHeader: buildHeader,
					renderFooter: buildFooter,
					transformDataToFormList: ({ data, append }) => {
						transformDataToFormList({
							data,
							append,
							configsCompany,
							keyArrayName: 'quantidadeProdutosPlanoRestrito',
							keyDataId: 'produto_id',
							keyDataMonthName: 'mes_ano',
							keyDataValueName: 'quantidade_produtos',
							buildDescritption: (el) =>
								`${el?.codigo_produto} - ${el?.nome_produto}`,
							cycle: currentCycle,
						});
					},
				},
			],
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[buildHeader, buildFooter, configsCompany, currentCycle],
	);

	const treeview = useMemo(
		() => ({
			deeper: 0,
			parentFilterParams: { ciclo_id: cycleId },
			row: {
				filterKeyName: 'familia_id',
				renderRow: renderFamily,
				infinityQueriesFunctions: [
					{
						getData: (params) => {
							return getFamiliesRestrictedPlanByCycle({
								...params,
							});
						},
						appendData: ({ data }) => {
							const result = data?.pages?.reduce?.(
								(acc, page) => [...acc, ...(page?.rows || [])],
								[],
							);

							setHasRestrictedPlan(!!result?.length);

							return result;
						},
						queryKeyName: 'resctricted-plan-family',
						getNextPage: (lastPage, allPages, limit) => {
							return lastPage?.rows?.length === limit
								? allPages?.length
								: undefined;
						},
					},
				],
			},
			children: [
				{
					deeper: 1,
					isFormList: true,
					hasHeader: true,
					hasFooter: true,
					row: {
						renderRow: renderProductRow,
						infinityQueriesFunctions: [
							{
								getData: (params) => {
									return getProductsRestrictedPlanByCycle({
										...params,
									});
								},
								appendData: ({ data }) => {
									return data?.pages?.reduce?.(
										(acc, page) => [
											...acc,
											...(page?.rows || []),
										],
										[],
									);
								},
								queryKeyName: 'resctricted-plan-family-product',
								getNextPage: (lastPage, allPages, limit) => {
									return lastPage?.rows?.length === limit
										? allPages?.length
										: undefined;
								},
							},
						],
					},
				},
			],
		}),
		[cycleId, renderProductRow],
	);

	const handleOnClose = useCallback(() => {
		resetForm(getValues, setValue);
		resetRenderizedObjectIds(renderizedObjectIds);

		onClose();
	}, [getValues, onClose, setValue]);

	const onSubmit = useCallback(
		async (values) => {
			const result = Object.keys(values || {})
				.map((key) => {
					const familia_id = key.replace(/[A-Za-z]+_/, '');

					if (!familia_id || isNaN(Number(familia_id))) return null;

					const produtos = values[key]
						?.filter((el) => !el?.isTotalRow && el?.edited)
						?.map((el) => {
							const produto_id = el?.produto_id;

							return Object.keys(el)
								?.filter((k) =>
									k.startsWith(String(produto_id)),
								)
								?.map((k) => {
									if (k.includes('TOTAL')) return null;

									const novoValorMes = currencyToNumber(
										el[k],
									);

									if (isNaN(novoValorMes)) return null;

									const valorOriginal = currencyToNumber(
										el?.original[k],
									);

									if (novoValorMes === valorOriginal)
										return null;

									const mes = Number(
										k.replace(`${produto_id}_`, ''),
									);

									const mes_ano = moment()
										.utc()
										.month(mes - 1);

									// se o mês é menor ou igual ao ciclo atual significa que é o próximo ano
									if (mes <= currentCycle?.ciclo) {
										mes_ano.add(1, 'year');
									}

									return {
										produto_id,
										mes_ano: mes_ano
											?.startOf('month')
											?.format('YYYY-MM-DD'),
										quantidade_produtos: novoValorMes,
										ciclo_id: cycleId,
									} as any;
								})
								?.filter((el) => el !== null)
								?.reduce((acc, el) => [...acc, el], []);
						});

					return {
						familia_id,
						produtos: produtos?.reduce(
							(acc, el) => [...acc, ...el],
							[],
						),
					} as any;
				})
				?.filter((el) => el?.produtos?.length > 0)
				?.flatMap((el) => el?.produtos)
				?.reduce((acc, el) => {
					if (!acc[el?.produto_id]) {
						acc[el?.produto_id] = {
							produto_id: el?.produto_id,
							ciclo_id: el?.ciclo_id,
							lista_quantidade_mes: [],
						};
					}

					acc[el.produto_id]?.lista_quantidade_mes?.push({
						mes_ano: el?.mes_ano,
						quantidade_produtos: el?.quantidade_produtos,
					});

					return acc;
				}, {});

			const payload = Object.values(result || {});

			if (!!payload?.length) {
				await RestrictedPlanUpdate(payload);
			} else {
				await RestrictedPlanUpdate([
					{
						ciclo_id: cycleId,
						produto_id: null,
						lista_quantidade_mes: [],
					},
				]);
			}
		},
		[RestrictedPlanUpdate, currentCycle?.ciclo, cycleId],
	);

	function renderButtons() {
		return (
			<Flex
				justifyContent='end'
				mt='20px'
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-cancelar-editar_plano-modal-dashboard_capacidade'
						type={'ghost'}
						title={'Cancelar'}
						onClick={handleOnClose}
					/>
					<ButtonComponent
						data-test='button-recalcular-editar_plano-modal-dashboard_capacidade'
						type={'secondary'}
						title={'Recalcular'}
						onClick={onOpenModal}
						isDisabled={isSubmitting}
						isLoading={isLoadingRestrictedPlan}
					/>

					{!!Object.keys(errors || {}).length ? (
						<TooltipStandard label='Existem campos que não foram preenchidos, corrigir!'>
							<span>
								<ButtonComponent
									data-test='button-gerar_plano-editar_plano-modal-dashboard_capacidade'
									type='primary'
									action='submit'
									title='Gerar Plano'
									isDisabled={
										!!Object.keys(errors || {}).length ||
										isSubmitting
									}
									isLoading={isLoadingRestrictedPlan}
								/>
							</span>
						</TooltipStandard>
					) : (
						<ButtonComponent
							data-test='button-gerar_plano-editar_plano-modal-dashboard_capacidade'
							type='primary'
							action='submit'
							title='Gerar Plano'
							isDisabled={isSubmitting}
							isLoading={isLoadingRestrictedPlan}
						/>
					)}
				</ButtonGroup>
				<RecalculatePlanoRestritoModal
					isOpen={isOpenModal}
					onClose={onCloseModal}
					cycleId={cycleId}
					onUpdateData={onUpdateData}
				/>
			</Flex>
		);
	}

	const updateTotalFamily = useCallback(
		(value, name) => {
			const [familia, index, produtoId_mes] = name?.split?.('.') || '';

			if (!familia || !index || !produtoId_mes) return;

			const rowForm = value[familia]?.[index];

			const valorProdutoMesAtualizado =
				typeof rowForm[produtoId_mes] === 'number'
					? rowForm[produtoId_mes]
					: currencyToNumber(rowForm[produtoId_mes] || 0);

			const valorProdutoMesOriginal = currencyToNumber(
				rowForm?.original[produtoId_mes],
			);

			const isAdd = valorProdutoMesAtualizado > valorProdutoMesOriginal;

			let diferencaValorMes = 0;

			if (isAdd) {
				diferencaValorMes =
					valorProdutoMesAtualizado - valorProdutoMesOriginal;
			} else {
				diferencaValorMes =
					(valorProdutoMesOriginal - valorProdutoMesAtualizado) * -1;
			}

			const indexTotal = getTotalIndex({
				value,
				formListName: familia,
			});

			const familiaId = familia.replace(/[A-Za-z]+_/, '');
			const colunaMesAlterado = produtoId_mes.split('_')[1];

			setValue(
				`${familia}.${indexTotal}.alteracao.${colunaMesAlterado}.${produtoId_mes}`,
				diferencaValorMes,
			);

			const totalGeralFamiliaOriginalColuna = getTotalGeralOriginal({
				value,
				formListName: familia,
				index: indexTotal,
				key: `${familiaId}_${colunaMesAlterado}_TOTAL`,
			});

			const diferencaValorAlteradoColuna = getColumnMonthChanges({
				value,
				formListName: familia,
				index: indexTotal,
				keyMonthChanged: colunaMesAlterado,
			});

			const novoValorTotalColuna = getNewTotalColumnValue({
				columnMonthChanges: diferencaValorAlteradoColuna,
				keyChangedMonth: produtoId_mes,
				difference: diferencaValorMes,
				originalValue: totalGeralFamiliaOriginalColuna,
			});

			setValue(
				`${familia}.${indexTotal}.${familiaId}_${colunaMesAlterado}_TOTAL`,
				numberToLocaleString({
					value: novoValorTotalColuna,
					maximumFractionDigits: configsCompany?.precisao_decimal,
				}),
			);

			const novoValorTotalGeral = getNewTotalGeneralValue({
				value,
				formListName: familia,
				index: indexTotal,
				entityId: familiaId,
			});

			setValue(
				`${familia}.${indexTotal}.${familiaId}_TOTAL`,
				numberToLocaleString({
					value: novoValorTotalGeral,
					maximumFractionDigits: configsCompany?.precisao_decimal,
				}),
			);
		},
		[configsCompany?.precisao_decimal, setValue],
	);

	const markRowsAsEdited = useCallback(
		(name) => {
			const [familia, index, produto] = name?.split?.('.') || '';

			if (!familia || !index || !produto) return;

			setValue(`${familia}.${index}.edited`, true);
		},
		[setValue],
	);

	function renderAlert() {
		if (!hasRestrictedPlan) {
			return (
				<AlertComponent
					title='Atenção'
					description='Não há plano restrito para o ciclo selecionado. Verifique se foi realizado consenso para este ciclo.'
					status='warning'
				/>
			);
		}

		return null;
	}

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (
				name?.includes('TOTAL') ||
				name?.includes('edited') ||
				name?.includes('alteracao')
			)
				return;

			updateTotalProductRow({ value, name, setValue, configsCompany });

			updateTotalFamily(value, name);

			markRowsAsEdited(name);
		});
		return () => subscription.unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch]);

	useEffect(() => {
		if (CycleData && cycleId) {
			setCurrentCycle(CycleData?.find((c) => c?.id === Number(cycleId)));
		}
	}, [CycleData, cycleId]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleOnClose}
			onEsc={handleOnClose}
			isCentered
			size='full'
		>
			<ModalOverlay />
			<ModalContent borderRadius={20}>
				<ModalHeader
					display='flex'
					alignItems='end'
					justifyContent='space-between'
					tabIndex={-1}
				>
					<Box>
						<Icon
							as={IconClipboardCheck}
							boxSize={12}
							color={'#25364D'}
						/>
						<Flex
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Text
								fontSize='2xl'
								fontWeight={700}
								color={'#25364D'}
							>
								Plano Restrito
							</Text>
						</Flex>
					</Box>
					<TooltipStandard
						label='Exportar Plano Restrito para CSV'
						shouldWrapChildren={true}
					>
						<ButtonExportGenericCSV
							errorDescription='Não há dados para exportar!'
							module='plano-restrito'
							useExport={useExportRestrictedPlan}
							cycleId={cycleId}
							isDisabled={!hasRestrictedPlan}
							filters={{
								ano: currentCycle?.ano?.toString(),
								ciclo: currentCycle?.ciclo?.toString(),
							}}
						/>
					</TooltipStandard>
				</ModalHeader>
				<ModalCloseButton data-test='button-fechar-modal' />
				<ModalBody>
					{renderAlert()}
					<form onSubmit={handleSubmit(onSubmit)}>
						<GenericTreeViewInfiniteScroll
							treeview={treeview}
							formParams={formParams}
						/>
						{renderButtons()}
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default EditRestrictedPlanModal;
