import {
	ButtonGroup,
	Heading,
	Icon,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';

export const DeleteAccountModal = (props) => {
	const { title, text } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<ButtonComponent
				type={'secondary'}
				title={'Desativar Conta'}
				onClick={onOpen}
			/>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size='xl'
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalCloseButton />
					<ModalBody>
						<Icon
							as={IconTrash}
							color='easyRED.300'
							w={14}
							h={14}
						></Icon>
						<Heading
							color='gray.700'
							mb={2}
							size='md'
						>
							{title}
						</Heading>
						<Text color='gray.500'>{text}</Text>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<ButtonComponent
								type={'ghost'}
								title={'Cancelar'}
								onClick={onClose}
							/>
							<Link
								href='https://calendly.com/suporte-easy360'
								isExternal
							>
								<ButtonComponent
									type={'secondary'}
									title={'Sim, Falar com Atendente'}
								/>
							</Link>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
