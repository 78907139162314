import { IEnvironmentEntity, IURL } from '../environment';
export interface IOptionBiTecConfig extends IURL {
	user?: string;
	password?: string;
	token?: string;
}
const biTecConfProduction: IOptionBiTecConfig = {
	url: process.env.REACT_APP_BITEC_BBE_BIBOTS_API_URL_PRODUCTION || '',
	user: process.env.REACT_APP_BITEC_BBE_BIBOTS_API_USERNAME_PRODUCTION,
	password: process.env.REACT_APP_BITEC_BBE_BIBOTS_API_PASSWORD_PRODUCTION,
	token: process.env.REACT_APP_BITECH_JWT_TOKEN_PRODUCTION,
};
const biTecConfHomolog: IOptionBiTecConfig = {
	url: process.env.REACT_APP_BITEC_BBE_BIBOTS_API_URL_STAGING || '',
	user: process.env.REACT_APP_BITEC_BBE_BIBOTS_API_USERNAME_STAGING,
	password: process.env.REACT_APP_BITEC_BBE_BIBOTS_API_PASSWORD_STAGING,
	token: process.env.REACT_APP_BITECH_JWT_TOKEN_STAGING,
};
export const biTec: IEnvironmentEntity = {
	development: biTecConfHomolog,
	staging: biTecConfHomolog,
	production: biTecConfProduction,
};
