// configuração de APIs de comunicação
// Importação de Axios, ferramenta necessária para requisições no front
// import { createBrowserHistory } from 'history';
import axios from 'axios';
import customToast from 'components/toast/customToatsStandalone';
import getErrorAction from './variables/getErrorAction';
import { environmentVars } from './variables/environment';
// Criar uma configuração base para acessar a mesma url em cada requisição / Configuração padrão do AXIOS com a propriedade
// baseURL já atribuída ao servidor da API
const api = axios.create({
	baseURL: environmentVars.core.api,
	headers: {
		'Access-Control-Allow-Origin': '*',
	},
});
// Criar uma instância do history
// const history = createBrowserHistory();
// 🚧 Interceptor para pegar o erro de autenticação quando a sessão expirar e redirecionar para a página de login
api.interceptors.response.use(
	function (response) {
		// Qualquer código de status que esteja dentro do intervalo de 2xx faz com que esta função seja acionada
		// Faça algo com os dados de resposta
		return response;
	},
	function (error) {
		// Qualquer código de status que esteja fora do intervalo de 2xx faz com que esta função seja acionada
		// Faça algo com erro de resposta
		const errorData = error.response?.data[0];
		const statusCode = errorData?.statusCode;
		const errorMessage = errorData?.message;

		const errorAction = getErrorAction(statusCode, errorMessage);

		if (errorAction) {
			localStorage.clear();
			// history.push(errorAction.redirectUrl);
			window.location.href = errorAction.redirectUrl;
			customToast(errorAction.toast);
		}

		return Promise.reject(error);
	},
);

export default api;
