import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";import { postAtaConsenso } from "services/api/requests/colabAndConsensus/collaborationConsensus";
;

export const useCreateAtaConsenso = (body?: any) => {
    const queryClient = useQueryClient();
    const toast = useToast();
    
    return useMutation(postAtaConsenso, {
        onSuccess: (sucess) => {
            queryClient.invalidateQueries(["ata-consenso-list"]);
            toast({
                title: 'Registrada com sucesso!',
                description: "Ata regristada com sucesso.",
                position: 'bottom-left',
                status: 'success',
                duration: 8000,
                isClosable: true,
            })
        },
        onError: (error: any, variables, context) => {
            toast({
                title: 'Erro ao registrar ata!',
                description: error.response.data[0].message,
                position: 'bottom-left',
                status: 'error',
                duration: 8000,
                isClosable: true,
            })
        },
    })
}
