import { InputNames } from '../models/InputsModel';

interface CommonProps {
	name: InputNames;
}

interface DefineInputValue extends CommonProps {
	inputValue: { valorInicial: string; valorFinal: string };
}

interface DefineBorderColorValue extends CommonProps {
	validation?: boolean;
}

export const defineInputValue = ({
	name,
	inputValue,
}: DefineInputValue): string => {
	return name === 'valorInicial'
		? inputValue.valorInicial
		: inputValue.valorFinal;
};

export const defineBorderColorValue = ({
	name,
	validation,
}: DefineBorderColorValue): string => {
	return name === 'valorFinal' && validation ? 'red' : 'inherit';
};
