import { Chart } from 'react-google-charts';
export interface Estatistica {
	max: number;
	min: number;
}
export interface Annotations {
	y: number;
	y2: number;
}
interface PropsApexChart {
	valuesChart: any;
	haveLimit: boolean;
}
export const formatDataMount = ({
	value,
	limitMax,
	limitMin,
	mounth,
	section,
	tooltip
}: {
	mounth: string;
	value: number | string;
	section: string;
	limitMax?: number | string;
	limitMin?: number | string;
	tooltip?: any;
}) => {
	value = value ? value : 0;
	if (section === 'historico') {
		return [mounth, value, value, tooltip];
	} else if (section === 'estatistica' && limitMin === undefined) {
		return [mounth, value, value];
	} else {
		limitMax = Number(limitMax) | 0;
		limitMin = Number(limitMin) | 0;
		return [mounth, value, limitMin, limitMax, limitMax, limitMin, value, tooltip];
	}
};
const formatData = (props: PropsApexChart) => {
	const { haveLimit, valuesChart } = props;

	return [
		[
			{
				type: 'string',
				label: 'x',
			},
			{
				type: 'number',
			},
			...(haveLimit
				? [
						{
							id: 'area',
							type: 'number',
							label: '',
							role: 'interval',
						},
						{
							id: 'area',
							type: 'number',
							label: '',
							role: 'interval',
						},
						{
							type: 'number',
							role: 'interval',
							label: '',
							id: 'lineGreen',
						},
						{
							type: 'number',
							role: 'interval',
							label: '',
							id: 'lineGray',
						},
				  ]
				: []),
			{
				id: 'point',
				type: 'number',
				// aqui que tira
				role: 'interval',
			},
			{
				type: 'string',
				role: 'tooltip',
				p: { html: true }
			}
		],
		...valuesChart,
	];
};

export default function ApexChart(props: PropsApexChart) {
	const options = {
		curveType: 'function',
		// intervals: { color: "series-color" },
		colors: ['#F0243E'],
		lineWidth: 4,
		interval: {
			point: {
				style: 'points',
				color: '#F0243E',
				pointSize: 10,
				fillOpacity: 1,
			},
			lineGreen: { style: 'line', color: '#01B574', lineWidth: 2 },
			lineGray: { style: 'line', color: '#A3AED0', lineWidth: 1 },
			area: {
				style: 'area',
				color: '#A3AED0',
				curveType: 'function',
			},
		},
		legend: 'none',
		language: 'pt-BR',
		vAxis: {
			viewWindow: {
				min: 0,
				// max: 100
			},
			// format: '#.###,00',
		},
	};

	return (
		<Chart
			chartType='LineChart'
			width='100%'
			height='300px'
			data={formatData(props)}
			options={options}
			formatters={[
				{
					column: 1,
					type: 'NumberFormat',
					options: {
						pattern: '#.###,00',
					},
				},
				{
					column: 2,
					type: 'NumberFormat',
					options: {
						pattern: '#.###,00',
					},
				},
			]}
			chartLanguage='pt-BR'
		/>
	);
}

function generateTooltipContent(val1,val2){
		 		return "<div><h1>Val1:"+val1+"</h1><h1>Val2:"+val2+"</h1></div>"
     }

// import React from "react";
// import { Chart } from "react-google-charts";
// export interface Estatistica {
//     max: number,
//     min: number,
// }
// export interface Annotations {
//     y: number,
//     y2: number
// }
// interface PropsApexChart {
//     valuesChart: any,
//     haveLimit: boolean
// }
// export const formatDataMount = ({ value, limitMax, limitMin, mounth }: {
//     mounth: string,
//     value: number | string,
//     limitMax?: number | string,
//     limitMin?: number | string,
// }) => {
//     value = Number(value) | 0
//     if (limitMin === undefined)
//         return ([mounth, value, value])
//     else {
//         limitMax = Number(limitMax) | 0
//         limitMin = Number(limitMin) | 0
//         return ([mounth, value, limitMin, limitMax, limitMax, limitMin, value])
//     }
// }
// const formatData = (props: PropsApexChart) => {
//     const { haveLimit, valuesChart } = props

//     return ([
//         [
//             {
//                 type: "string",
//                 label: "x"
//             },
//             {
//                 type: "number",
//                 // role: "interval",
//                 // id: "i0"
//             },
//             // ...(haveLimit ? [
//             //     {
//             //         id: "i1",
//             //         type: "number",
//             //         label: "min",
//             //         role: "interval"
//             //     },
//             //     {
//             //         id: "i1",
//             //         type: "number",
//             //         label: "min",
//             //         role: "interval"
//             //     },
//             //     {
//             //         id: "i3",
//             //         role: "interval",
//             //         type: "number",
//             //         label: "min"
//             //     },
//             //     {
//             //         id: "i4",
//             //         role: "interval",
//             //         type: "number",
//             //         label: "min"
//             //     },
//             // ] : []),
//             {
//                 type: "number",
//                 role: "interval",
//                 id: "i1"
//             },
//         ],

//         ...valuesChart
//     ]
//     )
// }

// export default function ApexChart(props: PropsApexChart) {

//     const options = {
//         curveType: "function",
//         // intervals: { style: "line" },
//         interval: {
//             i0: {
//                 style: "points",
//                 color: "#F0243E",
//                 pointSize: 10,
//                 fillOpacity: 1
//             },
//             i1: { style: "line", color: "#D3362D", lineWidth: 4 },
//             i2: { style: "line", color: "#01B574", lineWidth: 1 },
//             i3: { style: "line", color: "#A3AED0", lineWidth: 1 },
//             i4: {
//                 style: "area",
//                 color: "#A3AED0",
//                 curveType: "function",
//             },
//         },
//         legend: "none"
//     };
//     return (
//         <Chart
//             chartType="LineChart"
//             width="100%"
//             height="300px"
//             data={formatData(props)}
//             options={options}
//         />
//     );
// }
