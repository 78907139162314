import { useQuery } from '@tanstack/react-query';
import { getCollaborationConsensusByFamily } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface UseCollaborationConsensusByFamilyListProps {
	page: string;
	cycleId: string;
	salesChannelId: string;
	familyId: string;
	companyAreaId?: string;
	salesZoneId?: string;
}
const keysQuery = [
	'page',
	'cycleId',
	'familyId',
	'companyAreaId',
	'salesChannelId',
	'salesZoneId',
];
export const useCollaborationConsensusByFamilyList = (
	query: UseCollaborationConsensusByFamilyListProps,
) => {
	const keysValues = keysQuery.map((key) =>
		query[key] === undefined ? '' : query[key],
	);
	return useQuery(
		['collaboration-consensus-by-familia', ...keysValues],
		getCollaborationConsensusByFamily,
		{
			refetchOnWindowFocus: false,
		},
	);
};
