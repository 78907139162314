import { useQuery } from '@tanstack/react-query';
import { getCollaborationExceptionsByFamilyId } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useCollaborationExceptionsByFamilyId = (
	cicloId: string,
	familiaId: string,
	// canalVendaId: string,
) => {
	return useQuery(
		['collaboration-exception-by-family', cicloId, familiaId],
		getCollaborationExceptionsByFamilyId,
		{
			refetchOnWindowFocus: false,
		},
	);
};
