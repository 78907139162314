import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';
import { createProductModel } from 'services/api/requests/productModel';

export const useCreateProductModel = (
	inputName?: string,
	formProps?,
	setAutocompleteData?,
) => {
	const queryClient = useQueryClient();

	const toast = useToast();

	return useMutation(createProductModel, {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['family-list']);
			queryClient.invalidateQueries(['family-list-with-products']);
			queryClient.invalidateQueries(['productModel-list']);

			toast({
				title: 'Criado com sucesso!',
				description: 'Modelo criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});

			if (inputName && formProps) {
				const { setValue } = formProps;

				setValue(inputName, String(success.id));

				if (setAutocompleteData)
					setAutocompleteData((prev) => ({
						...prev,
						product_model: success,
					}));
			}
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Modelo!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
