import { editWorkingDays } from "services/api/requests/workingDays";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from '@chakra-ui/react';

export const useEditWorkingDays = () => {
    const queryClient = useQueryClient();
    const toast = useToast();

    return useMutation(editWorkingDays, {
        onSuccess: (sucess) => {
            queryClient.invalidateQueries(["working-days-list"]);
            // history.goBack();
            toast({
                title: 'Atualizado com sucesso!',
                description: "Dias úteis atualizado com sucesso.",
                position: 'bottom-left',
                status: 'success',
                duration: 8000,
                isClosable: true,
            })
        },
        onError: (error: any) => {
            toast({
                title: 'Erro ao atualizar dias úteis',
                description: error.response.data[0].message,
                position: 'bottom-left',
                status: 'error',
                duration: 8000,
                isClosable: true,
            })
        },
    })
}