
// Chakra imports
import { Badge, Flex } from "@chakra-ui/react";

export default function Status(props) {
  const { status, badgeTrueText, ...rest } = props;
  // alert(badgeTrueText)
  return (
    <Flex {...rest}>
      <Badge
        colorScheme={status ===  badgeTrueText? "green": "red" }
        color={status === badgeTrueText ? "green.500" : "red.500"}
        fontSize='sm'
        align='center'
        w={{ sm: "30px", lg: "102px", auto: 'auto' }}>
        {status}
      </Badge>
    </Flex>
  );
}
