import { SelectedFilterBody } from 'components/filter/models/TableFilterModels';
import moment from 'moment';
import { InputValues } from '../models/InputsModel';

interface HandleInputChangeParams {
	name: string;
	value: string | number;
	setInputValue: React.Dispatch<React.SetStateAction<any>>;
}

interface ValidateEndValueParams {
	name: 'valorFinal' | 'valorInicial';
	value: string | number;
	tipo_coluna:
		| 'quantity'
		| 'currency'
		| 'date'
		| 'month'
		| 'time'
		| 'datetime'
		| 'boolean'
		| 'string';
	inputValue: InputValues;
}

interface FindIndexByOperationParams {
	updatedForm: SelectedFilterBody[];
	nome_coluna: string;
	operation: string;
}

interface AddOrUpdateItemInArrayParams {
	updatedForm: SelectedFilterBody[];
	nome_coluna: string;
	tipo_valor: string;
	value: string | number | string[] | number[];
	operation: string;
	dependencia?: string;
	transformar?: boolean;
}

interface RemoveItemAtIndexParams {
	updatedForm: SelectedFilterBody[];
	existingIndex: number;
}

export const handleInputChange = ({
	name,
	value,
	setInputValue,
}: HandleInputChangeParams): void => {
	setInputValue((prev: React.SetStateAction<any>) => ({
		...prev,
		[name]: value,
	}));
};

export const operationMap = {
	valorInicial: 'greater_equal',
	valorFinal: 'less_equal',
	textSearch: 'in',
	boolean: 'equal',
};

export const validateEndValue = ({
	name,
	value,
	tipo_coluna,
	inputValue,
}: ValidateEndValueParams): boolean => {
	if (name == 'valorInicial') {
		if (tipo_coluna === 'date' && inputValue.valorFinal !== '') {
			const initialTime = moment(value, 'YYYY-MM-DD');
			const endTime = moment(inputValue.valorFinal, 'YYYY-MM-DD');
			return initialTime.isAfter(endTime);
		}
		if (tipo_coluna === 'month' && inputValue.valorFinal !== '') {
			const initialTime = moment(value, 'YYYY-MM');
			const endTime = moment(inputValue.valorFinal, 'YYYY-MM');
			return initialTime.isAfter(endTime);
		}
		if (tipo_coluna === 'datetime' && inputValue.valorFinal !== '') {
			const initialTime = moment(value, 'YYYY-MM-DD HH:mm');
			const endTime = moment(inputValue.valorFinal, 'YYYY-MM-DD HH:mm');
			return initialTime.isAfter(endTime);
		}
		if (inputValue.valorFinal !== '') {
			return +value > +inputValue.valorFinal;
		}

		return false;
	} else {
		if (value === '') {
			return false; // Se o campo estiver vazio, não entre na validação
		}

		if (tipo_coluna === 'date') {
			if (inputValue.valorInicial === '') {
				return false;
			}
			const initialTime = moment(inputValue.valorInicial, 'YYYY-MM-DD');
			const endTime = moment(value, 'YYYY-MM-DD');
			return (
				!endTime.isAfter(initialTime) && !endTime.isSame(initialTime)
			);
		}
		if (tipo_coluna === 'month') {
			if (inputValue.valorInicial === '') {
				return false;
			}
			const initialTime = moment(inputValue.valorInicial, 'YYYY-MM');
			const endTime = moment(value, 'YYYY-MM');
			return (
				!endTime.isAfter(initialTime) && !endTime.isSame(initialTime)
			);
		}
		if (tipo_coluna === 'datetime') {
			if (inputValue.valorInicial === '') {
				return false;
			}
			const initialTime = moment(
				inputValue.valorInicial,
				'YYYY-MM-DD HH:mm',
			);
			const endTime = moment(value, 'YYYY-MM-DD HH:mm');
			return (
				!endTime.isAfter(initialTime) && !endTime.isSame(initialTime)
			);
		}

		if (inputValue.valorInicial === '') {
			return false;
		}

		return +value < +inputValue.valorInicial;
	}
};

export const findIndexByOperation = ({
	updatedForm,
	nome_coluna,
	operation,
}: FindIndexByOperationParams): number => {
	return updatedForm.findIndex(
		(item) =>
			item.nome_coluna === nome_coluna && item.operacao === operation,
	);
};

export const addOrUpdateItemInArray = ({
	updatedForm,
	nome_coluna,
	tipo_valor,
	value,
	operation,
	transformar,
}: AddOrUpdateItemInArrayParams): SelectedFilterBody[] => {
	const existingIndex = findIndexByOperation({
		updatedForm,
		nome_coluna,
		operation,
	});

	if (existingIndex !== -1) {
		// Se já existir, atualize o objeto existente com o valor
		updatedForm[existingIndex].valores = value;
	} else {
		// Se não existir, crie um novo objeto com o valor
		const newItem = {
			nome_coluna: nome_coluna,
			tipo_valor: tipo_valor,
			valores: value,
			operacao: operation,
			transformar,
		};
		updatedForm.push(newItem);
	}

	return updatedForm;
};

export const removeItemAtIndex = ({
	updatedForm,
	existingIndex,
}: RemoveItemAtIndexParams): SelectedFilterBody[] | [] => {
	return updatedForm.filter((option, idx) => idx !== existingIndex);
};
