import { Badge, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useGlobalContext } from 'contexts/GlobalContext';
import PricingLayout from 'layouts/auth/types/Pricing';
import { useHistory } from 'react-router-dom';
import Pack from './components/Pack';

function PlanosAssinatura() {
	const { pathCompanyName } = useGlobalContext();
	const history = useHistory();

	const goToPagamento = () => {
		history.push(`/${pathCompanyName}/cadastros/usuarios/pagamento`);
	};

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<PricingLayout
			image={
				'linear-gradient(135.81deg, #F0243E 15.92%, #314866 109.14%)'
			}
			contentTop={{ base: '0', md: '0' }}
			contentBottom={{ base: '0px', lg: 'auto' }}
		>
			<Flex
				direction='column'
				alignSelf='center'
				justifySelf='center'
				overflow='hidden'
				w={'100%'}
			>
				<Flex
					direction='column'
					textAlign='center'
					justifyContent='center'
					align='center'
					mb='38px'
				>
					<Text
						fontSize='44px'
						color='white'
						fontWeight='700'
						maxW='550px'
						lineHeight='52px'
					>
						Preços flexíveis que se adaptam ao seu negócio!
					</Text>
					<Text
						fontSize='md'
						color='white'
						fontWeight='5ormal'
						mt='10px'
						mb='26px'
						maxW='400px'
					>
						Conheça nossos planos de assinatura
					</Text>
					<Badge
						w='max-content'
						mb='60px'
						fontSize='sm'
						bg='rgba(255,255,255,0.12)'
						color='white'
						fontWeight='bold'
						textTransform='unset'
					>
						Escolha sua assinatura
					</Badge>

					<Stack
						direction={{ sm: 'column', xl: 'row' }}
						alignItems='flex-end'
						spacing='20px'
						mt='0px'
						mb='160px'
						overflow='unset !important'
						width={'60%'}
					>
						<Pack
							title='Básico'
							desc='Potencialize seu negócio.'
							button='Assinar plano básico'
							goTo={goToPagamento}
							benefits={[
								'3 usuários',
								'100 produtos',
								'SEM acessos simultâneos',
								'24 meses importação',
							]}
						/>
						<Pack
							title='Mentoria'
							desc='Personalizado para sua empresa'
							button='Assinar plano mentoria'
							highlighted
							goTo={goToPagamento}
							benefits={[
								'5 usuários',
								'Produtos ilimitados',
								'Acessos simultâneos ilimitados',
								'48 meses importação',
							]}
						/>
					</Stack>
				</Flex>
			</Flex>
		</PricingLayout>
	);
}

export default PlanosAssinatura;
