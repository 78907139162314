import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editForecast } from 'services/api/requests/forecast';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditForecast = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editForecast(body, id), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['forecastDemanda-list']);
			queryClient.invalidateQueries(['forecastWithId', id]);
			history.goBack();
			toast({
				title: 'Atualizada com sucesso!',
				description: 'Forecast atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao editar Forecast!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
