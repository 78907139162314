import {
    Avatar, ButtonGroup, Flex, Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text, useDisclosure
} from "@chakra-ui/react"
import { ButtonComponent } from "components/buttons/ButtonComponent"
import DropzoneUploadPhoto from "components/dropzone/DropzoneUploadPhoto"
import avatar4 from '../../assets/img/avatars/avatar4.png'

export const UploadPhotoAvatar = (props) => {
    const { userAttributes } = props
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
        {/* <Button onClick={onOpen}>Open Modal</Button> */}
        <Avatar
              h='96px' 
              w='96px' 
            //   src={avatar4}
            size={"xl"}
            name={userAttributes ? userAttributes?.name : "Admin Easy360"}
            background={"linear-gradient(135.81deg, #F0243E 15.92%, #314866 109.14%)"}
            color={"white"}
              border={"6px solid #F3F3F3"}
              cursor={"pointer"}
              onClick={onOpen}
            />
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={20} pt="20px">
        <ModalCloseButton />
        <ModalBody>
        <Heading color='gray.700' mb={2} size="md">
            Upload Imagem
        </Heading>
        <Text color="gray.500">
        <Flex w='100%' mb='0px' p={"2em"} flexDirection={"column"} alignItems={"center"} >
        <DropzoneUploadPhoto
            backgroundColor={"transparent"}
            border={"none"}
            height={"128px"}
            width={"128px"}
            borderRadius={"64px"}
            content={
                // <Box>
                // <Icon
                //     as={IconCloudUpload}
                //     w="80px"
                //     h="80px"
                //     color={"easyBLUE.300"}
                //     mb={"30"}
                // />
                // <Flex justify="center" mx="auto" mb="12px">
                //     <Text fontSize="20px" color={"easyBLUE.300"}>
                //     Arraste seu arquivo ou{" "}
                //     <Text as={"span"} fontWeight="500" color={"easyBLUE.300"}>
                //         clique para selecionar
                //     </Text>
                //     </Text>
                // </Flex>
                // <Text
                //     fontSize="14px"
                //     fontWeight="500"
                //     color="secondaryGray.500"
                // >
                //     PNG, JPG e GIF arquivos permitidos.
                // </Text>
                // </Box>
                <Avatar
                    h='128px' 
                    w='128px' 
                    src={avatar4}
                    border={"6px solid #F3F3F3"}
                    cursor={"pointer"}
                />
            }
            />
        </Flex>
        </Text>
        </ModalBody>
        <ModalFooter>

        <ButtonGroup>
            <ButtonComponent type={"ghost"} title={"Cancelar"} onClick={onClose}/>
            <ButtonComponent type={"secondary"} title={"Alterar"}/>
        </ButtonGroup>

        </ModalFooter>
        </ModalContent>
        </Modal>
        </>
    )
}

//   export const BasicUsage = () => {
//     const { isOpen, onOpen, onClose } = useDisclosure()
//     return (
//       <>
//         <Button onClick={onOpen}>Open Modal</Button>
  
//         <Modal isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalHeader>Modal Title</ModalHeader>
//             <ModalCloseButton />
//             <ModalBody>
//               Body
//             </ModalBody>
  
//             <ModalFooter>
//               <Button colorScheme='blue' mr={3} onClick={onClose}>
//                 Close
//               </Button>
//               <Button variant='ghost'>Secondary Action</Button>
//             </ModalFooter>
//           </ModalContent>
//         </Modal>
//       </>
//     )
//   }