


// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { MdPieChart } from "react-icons/md";
// Custom components
import { ButtonComponent } from "components/buttons/ButtonComponent";
import Card from "components/card/Card.js";
import { useGlobalContext } from "contexts/GlobalContext";
import { useHistory } from "react-router-dom";

export default function PlanoDetalhe(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const iconColor = useColorModeValue("brand.500", "white");

  const { pathCompanyName } = useGlobalContext();
  const history = useHistory();

  const goToPlans = () => {
    history.push(`/${pathCompanyName}/planos`);
  };

  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Text
        color={textColor}
        fontSize='3xl'
        fontWeight='700'
        mb='10px'
        mx='auto'
        textAlign={'center'}>
        Plano Atual
      </Text>
      <Text
        color='secondaryGray.600'
        fontSize='lg'
        fontWeight='500'
        maxW='350px'
        mx='auto'
        mb='10px'>
        Loreim ipsum lorem ipsum
      </Text>
      <Flex
        justifyContent='center'
        alignItems='center'
        w='100%'
        px='10px'
        mb='8px'></Flex>
      
      <Box boxShadow='0px 32px 41px -18px rgba(0, 0, 0, 0.2)' w='140px' mx='auto' mb='10px' mt='10px' h="150px" backgroundColor="#F4F7FE"  borderRadius="40px" >
      <Icon as={MdPieChart} color={'#F0243E'} w='60%' h='auto' marginTop={'30px'}/>
      </Box>

      <Text color='secondaryGray.600' fontSize='lg' fontWeight='500' mb='0px' mt="20px">
        Seu plano é:
      </Text>
      <Text color='#2B3674' fontSize='3xl' fontWeight='500' mb='10px'>
        BÁSICO
      </Text>
     
         <ButtonComponent onClick={() => goToPlans()} fontSize="xs" marginTop="5px" width="fit-content" margin="auto" type="primary" title="Alterar"></ButtonComponent>

    </Card>
  );
}
