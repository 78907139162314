import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { calculateApportionment } from 'services/api/requests/apportionment';

export enum RateioTypeCalculation {
	HISTORICO = 'historico',
	HISTORICO_COMPLETO = 'historicoCompleto',
	RATEIO_ANTERIOR = 'rateioAnterior',
}

export const useCalculateApportionment = (
	cycleId: number,
	timeInterval: string | number,
	type: RateioTypeCalculation,
	previousCycleId?: string | number,
) => {
	const toast = useToast();

	const queryClient = useQueryClient();

	return useQuery(
		[
			'calculate-apportionment',
			cycleId,
			timeInterval,
			type,
			previousCycleId,
		],
		calculateApportionment,
		{
			refetchOnWindowFocus: false,
			enabled: false,
			retry: false,
			onSuccess: (data) => {
				toast({
					title: 'Rateio recalculado!',
					description: `O rateio para o ciclo selecionado foi recalculado com sucesso.`,
					position: 'bottom-left',
					status: 'success',
					duration: 8000,
					isClosable: true,
				});

				queryClient.removeQueries({
					predicate: (query: any) =>
						query.queryKey[0].startsWith('apportionment-family'),
				});
			},
			onError: (error: any) => {
				toast({
					title: 'Erro ao recalcular rateio.',
					description:
						error?.response?.data[0]?.message ||
						'O rateio para o ciclo selecionado não foi recalculado.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
		},
	);
};
