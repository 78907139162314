import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';
// import XLSX from 'xlsx';
export const getHierarchy = async () => {
	const response = await api.get('/hierarquiaComercial');
	return response;
};

export const getHierarchyById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/hierarquiaComercial/hierarquiaComercialById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const getHierarchyByIdAsMutate = async (id: number) => {
	const url = `/hierarquiaComercial/hierarquiaComercialById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const exportCommercialHierarchy = async () => {
	const response = await api.get('/hierarquiaComercial/exportCSV');
	return response.data;
};

export const getCityById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/cidade/cidadeById?id=${id}`;
	const response = await api.get(url);
	console.log(response);
	return response.data;
};

export const getSaleById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/venda/vendaById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const getMemberById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/membro/membroById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const editHierarchy = async (body, id: number) => {
	const url = `/hierarquiaComercial?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const editCity = async (body, id: number) => {
	const url = `/cidade?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const editSale = async (body, id: number) => {
	const url = `/venda?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const editMember = async (body, id: number) => {
	const url = `/membro?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const createHierarchy = async (body) => {
	const url = `/hierarquiaComercial`;
	const response = await api.post(url, body);
	return response.data;
};

export const getListCitySalesZone = async () => {
	const url = `/zonaVenda/listCidadeZonaVenda`;
	const response = await api.get(url);
	return response;
};

export const updateCitySalesZone = async (body, id) => {
	console.log('body', body, id);
	const url = `/zonaVenda?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const createCitySalesZone = async (body) => {
	console.log('body', body);
	const url = `/zonaVenda/createCidadeZonaVenda`;
	const response = await api.post(url, body);
	return response.data;
};

export const getCityBySalesZone = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/zonaVenda/listCidadeZonaVendaById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const getSalesZoneListAvailableByCity = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/zonaVenda/listZonaSemCidade?cidade_id=${id}`;

	const response = await api.get(url);
	return response;
};

export const getSalesZoneListChannelById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/zonaVenda/listZonaVendaByCanalId?canal_venda_id=${id}`;

	const response = await api.get(url);
	return response;
};

export const deleteHierarchyByFilters = async (filters: any) => {
	const response = await api.post(
		'hierarquiaComercial/deletarPorFiltros',
		filters,
	);
	return response.data;
};
