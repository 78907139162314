type StyleSelectedFilterButton = {
	[key: string]: string | number;
};

export const styleSelectedFilterButton: StyleSelectedFilterButton = {
	fontSize: 'sm',
	fontWeight: 500,
	borderRadius: 'full',
	border: '1px dashed',
	backgroundColor: 'easyBLUE.100',
};
