import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import {
	getAccuracyData,
	getAccuracyDataCommonData,
	getAccuracyProductData,
} from '../../api/requests/acuracity';
import { useToast } from '@chakra-ui/react';

export const useGetAccuracyData = (payload) => {
	const toast = useToast();

	return useMutation(() => getAccuracyData(payload), {
		onSuccess: (data) => data,
		onError: (error, variables, context) => {
			toast({
				title: 'Error ao carregar dados de Acuracidade',
				description:
					'Ocorreu um erro ao processar sua solicitação. Contate o seu mentor ou envie um e-mail para: contato@easy360.ind.br.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
		retry: false,
	});
};

export const useGetAccuracyProductData = (ref, payload) => {
	return useInfiniteQuery({
		queryKey: ['acuracidade-familia-produtos', payload?.family_id],
		queryFn: ({ pageParam }) =>
			getAccuracyProductData({ ...payload, pageParam }),
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.product_list?.length === payload?.limit
				? ref?.current
				: undefined;
		},
		retry: false,
		refetchOnWindowFocus: false,
	});
};

export const useGetAccuracyDataCommonData = (
	uri,
	interval,
	demand_type,
	sales_channel?,
	indicator?,
	page?,
	size?,
) => {
	const toast = useToast();

	return useMutation(
		() =>
			getAccuracyDataCommonData(
				uri,
				interval,
				demand_type,
				sales_channel,
				indicator,
				page,
				size,
			),
		{
			onSuccess: (data) => data,
			onError: (error, variables, context) => {
				toast({
					title: 'Error ao carregar dados de Acuracidade',
					description:
						'Ocorreu um erro ao processar sua solicitação. Contate o seu mentor ou envie um e-mail para: contato@easy360.ind.br.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
			retry: false,
		},
	);
};
