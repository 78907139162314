// Chakra imports
import { Flex, Heading, SimpleGrid, useColorModeValue } from "@chakra-ui/react";


export default function CadastroCapacidade() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
      return (
        <Flex pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex direction='column' width='stretch'>
            <SimpleGrid
              columns={{ sm: 1, md: 1, lg: 1, xl: 3 }}
              gap='20px'
              mb='20px'>
              <Flex>        
              <Heading
                color={textColor}
                fontSize='xl'
                fontWeight='700'
                lineHeight='100%'>
                Cadastro Capacidade
              </Heading>
              </Flex>
            </SimpleGrid>
        </Flex>
      </Flex>
  );
}