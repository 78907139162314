import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { updateUserAccount } from 'services/api/requests/authentication';

export const useUpdateUserAccount = (setUserAttributes, setUserInfos) => {
	const toast = useToast();
	const queryClient = useQueryClient();

	return useMutation(updateUserAccount, {
		onSuccess: (sucess, variables) => {
			queryClient.invalidateQueries(['getUserAccount']);

			if (variables.senha) {
				localStorage.clear();
				Auth.signIn(variables?.email, variables.senha)
					.then(() => {
						window.location.reload();
					})
					.catch((error) => {
						toast({
							title: 'Erro ao atualizar usuário(a).',
							description: `Algum erro ocorreu ao atualizar o usuário(a). ${error.message}`,
							position: 'bottom-left',
							status: 'error',
							duration: 8000,
							isClosable: true,
						});
					});
			} else {
				Auth.currentAuthenticatedUser()
					.then(async (user) => {
						await Auth.updateUserAttributes(user, {
							name: variables.nome,
						});
						// Aqui você pode acessar os atributos atualizados do usuário
						const updatedUser = await Auth.currentUserPoolUser();
						// Setando os atributos do usuário no estado global para não precisar fazer reload da página
						setUserAttributes((prevUserAttributes) => ({
							...prevUserAttributes,
							name: updatedUser?.attributes?.name,
						}));
						setUserInfos((prevUserInfos) => ({
							...prevUserInfos,
							membro_id: variables.membro_id,
						}));
					})
					.catch((error) => {
						toast({
							title: 'Erro ao atualizar usuário(a).',
							description: `Algum erro ocorreu ao atualizar o usuário(a). ${error.message}`,
							position: 'bottom-left',
							status: 'error',
							duration: 8000,
							isClosable: true,
						});
					});
			}
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Usuário(a) atualizado(a) com sucesso!',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			console.log(error.response.data);
			if (error.response.data.length === 1) {
				const errorMessage = error.response.data[0].message;
				toast({
					title: 'Erro ao atualizar usuário(a).',
					description: `${errorMessage}`,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else if (error.response.data.length > 1) {
				error.response.data.forEach((error, index) => {
					const errorMessage = error.response.data[index].message;
					toast({
						title: 'Erro ao atualizar usuário(a).',
						description: `${errorMessage}`,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				});
			}
		},
	});
};
