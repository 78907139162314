import { IEnvironmentEntity } from '../environment';
export const indicadores: IEnvironmentEntity = {
	development: {
		url: process.env.REACT_APP_FRONTEND_INDICADORES_API_URL_STAGING,
	},
	staging: {
		url: process.env.REACT_APP_FRONTEND_INDICADORES_API_URL_STAGING,
	},
	production: {
		url: process.env.REACT_APP_FRONTEND_INDICADORES_API_URL_PRODUCTION,
	},
};
