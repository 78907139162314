import { useQuery } from '@tanstack/react-query';
import { getCustomerAndRelationshipById } from 'services/api/requests/customer';

export const useGetCustomerAndRelationshipById = (id: Number) => {
	return useQuery(
		['customerAndRelationshipById', id],
		getCustomerAndRelationshipById,
		{
			onError: (error) => {},
			refetchOnWindowFocus: false,
		},
	);
};
