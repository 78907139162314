import { AlertComponent } from 'components/alerts/AlertComponent';
import { Button, Icon, Text } from '@chakra-ui/react';
import {
	defaultInputStyle,
	defaultFormStyle,
	columnFlexProps,
} from '../../utils/forms/defaultsProps';
import { formControlProps } from '../../utils/forms/defaultsProps';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { IconEye, IconEyeOff } from '@tabler/icons';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useCallback, useState } from 'react';
import { useGetAddressByCep } from 'services/queryClient/wrapperHooks/externalServices/useGetAddressByCep';
import { useHistory } from 'react-router-dom';
import {
	validateRequired,
	validateMin,
	validateByRegex,
} from '../../utils/forms/validations';
import { useSignUpAdmin } from 'services/queryClient/wrapperHooks/authentication/useSignUpAdmin';

interface IFormCadastrarEditarUsuarios {
	initialState: any;
	perfilTab: any;
	setPayload?: any;
	payload?: any;
	userId?: any;
}

interface PasswordShow {
	passwordShow: boolean;
	confirmPasswordShow: boolean;
}

const FormCadastrarEditarUsuarios = ({
	initialState,
	perfilTab,
	setPayload,
	payload,
	userId = 0,
}: IFormCadastrarEditarUsuarios) => {
	const history = useHistory();

	const goToHistory = useCallback(() => {
		history.goBack();
	}, [history]);

	const [formProps, setFormProps] = useState<any>();
	const [cep, setCep] = useState<string>('');
	const [passwordError, setPasswordError] = useState<boolean>(false);

	const { user } = useAuthenticator((context) => [context.user]);

	const isEditing = !!userId;

	const isHiddenInputPassword = useCallback(() => {
		return isEditing;
	}, [isEditing]);

	const [showPassword, setShowPassword] = useState<PasswordShow>({
		passwordShow: false,
		confirmPasswordShow: false,
	});

	useGetAddressByCep(cep, undefined, undefined, true, formProps);

	const handleOnChangeCEP = useCallback(
		(event, _formProps) => {
			if (!formProps) {
				setFormProps(_formProps);
			}
			const value = event.target.value || '';
			if (value?.replace(/(\d{5})(\d)/, '$1-$2').length === 9) {
				setCep(value);
			}
		},
		[formProps],
	);

	const { isLoading: isLoadingSignUpAdmin } = useSignUpAdmin(payload);

	const handleClickShowPassword = useCallback(
		(input: 'passwordShow' | 'confirmPasswordShow') => {
			setShowPassword((prevState) => ({
				...prevState,
				[input]: !prevState[input],
			}));
		},
		[],
	);

	const renderAddonContentTel = useCallback(() => {
		return (
			<Text
				display={'flex'}
				onClick={() => {}}
				borderLeftRadius={10}
				borderRightRadius={0}
				border={'1px solid #e2e8f0'}
				variant='ghost'
				h={10}
				w={14}
				textAlign='center'
				justifyContent={'center'}
				alignItems={'center'}
				fontSize={'sm'}
			>
				+55
			</Text>
		);
	}, []);

	const renderAddonContentPassword = useCallback(
		(input: 'passwordShow' | 'confirmPasswordShow') => {
			return (
				<Button
					onClick={() => handleClickShowPassword(input)}
					borderLeftRadius={0}
					borderRightRadius={10}
					border={'1px solid #e2e8f0'}
					variant='ghost'
				>
					{showPassword[input] ? (
						<Icon as={IconEyeOff} />
					) : (
						<Icon as={IconEye} />
					)}
				</Button>
			);
		},
		[handleClickShowPassword, showPassword],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome',
							label: 'Nome completo',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: João da Silva',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'razao_social',
							label: 'Empresa',
							showRequiredOnLabel: true,
							inputProps: {
								...defaultInputStyle,
								defaultValue: user
									? user.attributes?.['custom:razaoSocial']
									: null,
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'telefone',
							label: 'Telefone',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: (41) 99999-9999',
								'data-mask': '(00) 00000-0000',
								addonInputType: 'tel',
								addonPosition: 'left',
								addonContent: renderAddonContentTel(),
								maxLength: 15,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'email',
							name: 'email',
							label: 'Qual o email?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: joaodasilva@easy360.ind.br',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'cep',
							label: 'CEP',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: 80215-090',
								'data-mask': '00000-000',
								pattern: '[0-9]{5}-[0-9]{3}',
								maxLength: 9,
								onChange: handleOnChangeCEP,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'bairro',
							label: 'Bairro',
							inputProps: {
								...defaultInputStyle,
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						subColumns: [
							{
								columnFlexProps: {
									flexDirection: 'row',
									w: '100%',
									mr: '30px',
								},
								inputs: [
									{
										formControlProps,
										input: {
											type: 'text',
											name: 'rua',
											label: 'Rua',
											inputProps: {
												...defaultInputStyle,
											},
											disabled: () => true,
										},
									},
								],
							},
						],
					},
					{
						subColumns: [
							{
								columnFlexProps: {
									flexDirection: 'row',
									w: '100%',
								},
								inputs: [
									{
										formControlProps: {
											pt: '10px',
											pr: '15px',
										},
										input: {
											type: 'number',
											name: 'numero',
											label: 'Nº',
											showRequiredOnLabel: true,
											validate: {
												...validateRequired(),
											},
											inputProps: {
												...defaultInputStyle,
												placeholder: 'ex.: 1341',
											},
										},
									},
									{
										formControlProps: {
											pt: '10px',
											pl: '15px',
										},
										input: {
											type: 'text',
											name: 'complemento',
											label: 'Complemento',
											inputProps: {
												...defaultInputStyle,
												placeholder:
													'ex.: Habitat Senai',
											},
										},
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'senha',
							label: 'Digite a senha',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateByRegex(
									/(?=^.{8,30}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
									'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
								),
								...validateMin(8),
							},
							labelTooltip:
								'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Ab12345@',
								maxLength: 30,
								addonInputType: showPassword.passwordShow
									? 'text'
									: 'password',
								addonPosition: 'right',
								addonContent:
									renderAddonContentPassword('passwordShow'),
							},
							hidden: isHiddenInputPassword,
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'confirmacao_de_senha',
							label: 'Digite a senha novamente',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateMin(8),
								...validateByRegex(
									/(?=^.{8,30}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
									'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
								),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Ab12345@',
								maxLength: 30,
								addonInputType: showPassword.confirmPasswordShow
									? 'text'
									: 'password',
								addonPosition: 'right',
								addonContent: renderAddonContentPassword(
									'confirmPasswordShow',
								),
							},
							hidden: isHiddenInputPassword,
						}),
					},
				],
			},
		];
	}, [
		handleOnChangeCEP,
		isHiddenInputPassword,
		renderAddonContentPassword,
		renderAddonContentTel,
		showPassword.confirmPasswordShow,
		showPassword.passwordShow,
		user,
	]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				password: values.senha,
				email: values.email,
				userGroups: [],
				dbUserData: {
					nome: values.nome,
					telefone: values.telefone?.replace(/\D/g, ''),
					email: values.email,
					cep: values.cep?.replace(/\D/g, ''),
					numero: values.numero,
					complemento: values.complemento,
					bairro: values.bairro,
					rua: values.rua,
					grupos: values.grupos,
					endereco: `${values.rua}, ${values.bairro}`,
				},
				dbUserAttributes: [
					{
						Name: 'custom:nomeFantasia',
						Value: user?.attributes?.['custom:nomeFantasia'],
					},
				],
			};

			setPayload(body);

			if (values.senha !== values.confirmacao_de_senha) {
				setPasswordError(true);
			} else {
				perfilTab.current.click();
				setPasswordError(false);
			}
		},
		[perfilTab, setPayload, user?.attributes],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToHistory}
					loadingSubmit={isLoadingSignUpAdmin}
					labelSave={'Avançar'}
				/>
				{passwordError && (
					<AlertComponent
						title={'As senhas não correspondem!'}
						description={
							'Por favor, digite a mesma senha nos dois campos!'
						}
						status={'error'}
						hasCloseButton
						actionClose={() => setPasswordError(false)}
					/>
				)}
			</>
		);
	}, [
		goToHistory,
		initialState,
		onSubmit,
		passwordError,
		renderRows,
		isLoadingSignUpAdmin,
	]);

	return renderForm();
};

export default FormCadastrarEditarUsuarios;
