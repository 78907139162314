import { useQuery } from '@tanstack/react-query';
import { getFamiliesByFilters } from 'services/api/requests/requests';

export const useFamilyListWithoutProducts = (
	produtos_status?: boolean,
	produtos_participa_rateio?: boolean,
) => {
	return useQuery(
		['family-list', produtos_status, produtos_participa_rateio],
		getFamiliesByFilters,
		{
			refetchOnWindowFocus: false,
		},
	);
};
