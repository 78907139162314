import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarCarteira from 'components/forms/FormCadastrarEditarCarteira';
import moment from 'moment';

export default function CadastrarCarteira() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastroCarteira = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Carteira de Pedidos',
			firstParagraph:
				'Para cadastrar uma nova carteira de cliente, selecione a Competência desejada e entre com os dados do tipo de carteira, Canal de Venda, Cliente, Família, código de produto, quantidade e receita bruta.',
			secondParagraph:
				'Para cadastrar uma carteira de pedidos, cadastre os canais de venda previamente em Cadastros > Comercial > Hierarquia, bem como os produtos em Cadastros > Produtos.',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroCarteira);
	}, [helpCadastroCarteira, setDataSidebar]);

	const formInitialState = {
		data: moment().format('YYYY-MM-DD'),
		cliente_id: null,
		familia_id: null,
		produto_id: null,
		quantidade: null,
		canal_venda_id: null,
		receita_bruta: null,
		faturado: false,
		ruim: false,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarCarteira initialState={formInitialState} />
			</Card>
		</Flex>
	);
}
