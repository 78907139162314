import api from '../api';

import { resourceGroup } from 'models/resource-group';

export const createResourceGroup = async (body: resourceGroup) => {
	const response = await api.post('/grupoRecurso', body);
	return response.data;
};

export const getResourceGroup = async () => {
	const response = await api.get('/grupoRecurso');
	return response.data;
};

export const getResourceGroupById = async (id: number) => {
	const response = await api.get(`grupoRecurso/grupoRecursoById?id=${id}`);
	return response.data;
};

export const getResourceGroupsByFiltersAndLimit = async (
	busca?: string,
	limit?: Number,
	orderBy?: string,
	sort?: string,
) => {
	const response = await api.get('/grupoRecurso/filtroRecurso', {
		params: {
			busca,
			limit,
			orderBy,
			sort,
		},
	});

	return response.data;
};
