import { useQuery } from '@tanstack/react-query';
import { getCollaborationConsensusExceptionByFamily } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useCollaborationConsensusExeptionsByFamilyList = (
	page: string,
	cycleId: string,
	salesChannelId: string,
	familyId: string,
	companyAreaId: string = '',
	salesZoneId: string = '',
) => {
	return useQuery(
		[
			'collaboration-consensus-exeption-by-family',
			page,
			cycleId,
			salesChannelId,
			familyId,
			companyAreaId,
			salesZoneId,
		],
		getCollaborationConsensusExceptionByFamily,
		{
			refetchOnWindowFocus: false,
		},
	);
};
