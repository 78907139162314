import { useQuery } from '@tanstack/react-query';
import { getSalesForceById } from 'services/api/requests/salesForce';

export const useGetSalesForceById = (id: Number) => {
	return useQuery(['salesForceById', id], getSalesForceById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
