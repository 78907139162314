import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { uploadForecast } from 'services/api/requests/requests';

export const useUploadForecast = (
	setSucessUpload,
	openTableModal,
	isOpen,
	onClose,
) => {
	const toast = useToast();

	return useMutation((data) => uploadForecast(data), {
		onSuccess: (sucess) => {
			if (
				sucess.hasOwnProperty('quantidade_erros') &&
				sucess.quantidade_erros > 0
			) {
				// queryClient.invalidateQueries(["venda-list"])
				console.log('Arquivo com erros!');
				openTableModal();
				setSucessUpload(false);
				if (!isOpen) {
					toast({
						title: 'Arquivo enviado com erros!',
						description:
							'O Arquivo enviado possui erros. Verifique a tabela de erros e corrija-os.',
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				}
			} else {
				console.log('Arquivo sem erros!');
				setSucessUpload(true);
				onClose();
			}
		},
		onError: () => {
			onClose();
			toast({
				title: 'Erro desconhecido!',
				description: 'Tente enviar novamente.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
			setSucessUpload(false);
		},
	});
};
