import { DeleteIconColumn } from "./DeleteIcon";

export const columnsDataModalEstoque = [
    {
        Header: "Data de Atualização",
        accessor: "data",
        filter: false,
        type: "text"
    },
    {
        Header: "Ciclo",
        accessor: "ciclo",
        filter: false,
        type: "text"
    },
    {
        Header: "Família",
        accessor: "familia",
        disableSortBy: true,
        filter: false,
        type: "text",
    },
    {
        Header: "Estoque",
        accessor: "quantidade",
        filter: false,
        type: "text"
    },
    {
        Header: " ",
        type: "jsx",
        accessor: "button",
        jsx: DeleteIconColumn,
        filter: false
    },
];