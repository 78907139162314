import { useQuery } from '@tanstack/react-query';
import { getSalesZoneById } from 'services/api/requests/salesZone';

export const useGetSalesZoneById = (id: Number) => {
	return useQuery(['salesZoneById', id], getSalesZoneById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
