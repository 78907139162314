import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GenericForm, { IFormRows } from './GenericForm';
import {
	defaultFormStyle,
	defaultInputStyle,
	defaultModalFormStyle,
	formFlexProps,
	rowFlexProps,
} from '../../utils/forms/defaultsProps';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import {
	validateByRegex,
	validateMax,
	validateRequired,
} from '../../utils/forms/validations';
import { renderClient } from '../../utils/forms/autocomplete';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useCreateRelated } from '../../services/queryClient/wrapperHooks/useCreateRelated';
import { useEditRelatedCustomer } from '../../services/queryClient/wrapperHooks/useEditRelatedCustomer';
import { useCreateRelatedCustomer } from '../../services/queryClient/wrapperHooks/useCreateRelatedCustomer';

interface IFormCadastrarEditarClienteRelacionamento {
	initialState: any;
	initialStateModal: any;
	customerId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarClienteRelacionamento = ({
	initialState,
	initialStateModal,
	customerId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarClienteRelacionamento) => {
	const history = useHistory();

	const isEditing = !!customerId;

	const { isOpen, onClose, onOpen } = useDisclosure();

	const [formProps, setFormProps] = useState<any>();

	const [isAutoRelationship, setAutoRelationship] = useState<boolean>();

	const [customer, setCustomer] = useState<any>();
	const [newCustomer, setNewCustomer] = useState<any>();
	const [autocompleteData, setAutocompleteData] = useState<any>({});
	const [documentType, setDocumentType] = useState<any>('');

	const [payload, setPayload] = useState<any>();

	const { mutate: createNewCustomerRelationship } = useCreateRelated(
		newCustomer,
		formProps,
		setAutocompleteData,
	);
	const { mutate: createCustomerRelationShip } = useCreateRelatedCustomer(
		payload,
		customer?.id,
	);
	const { mutate: updateCustomerRelationship } = useEditRelatedCustomer(
		payload,
		customer?.id,
	);

	const [formInitialModalState, setFormInitialModalState] = useState<any>();

	useEffect(() => {
		setFormInitialModalState(initialStateModal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialStateModal]);

	useEffect(() => {
		if (autocompleteAdditionalData) {
			setAutocompleteData({
				customer: autocompleteAdditionalData?.customer,
				relationship: autocompleteAdditionalData?.relationship,
			});

			setCustomer(autocompleteAdditionalData?.customer);

			setAutoRelationship(
				autocompleteAdditionalData?.customer?.id ===
					autocompleteAdditionalData?.relationship?.id,
			);
		}
	}, [autocompleteAdditionalData]);

	const goToCustomerRelationship = useCallback(
		() => history.goBack(),
		[history],
	);

	const handleOnChangeCustomer = useCallback((value) => {
		setCustomer(value);
	}, []);

	const handleOnChangeAutoRelationShip = useCallback((event, form) => {
		if (!form) {
			setFormProps(form);
		}

		const { getValues, setValue } = form || {};
		const autoRelationShip = getValues()?.autoRelationShip;

		setAutoRelationship(autoRelationShip);

		if (autoRelationShip) {
			setValue('cliente_relacionado_id', getValues()?.id);
		}
	}, []);

	const handleOnChangeDocumentType = (event, form) => {
		setDocumentType(event?.target?.value);

		form.reset({
			razao_social: form.getValues()?.razao_social,
			tipo_documento: event?.target?.value,
			documento: form.getValues()?.documento,
		});
	};

	const renderRelationship = useCallback(() => {
		if (isAutoRelationship && customer) {
			return renderClient(customer);
		}

		return autocompleteData?.relationship
			? renderClient(autocompleteData?.relationship)
			: '';
	}, [autocompleteData, customer, isAutoRelationship]);

	const renderSelectDocumentsType = useMemo(() => {
		const documentsType = [
			{
				id: 1,
				nome: 'CNPJ',
			},
			{
				id: 2,
				nome: 'CPF',
			},
			{
				id: 3,
				nome: 'ID Estrangeiro',
			},
		];

		return documentsType?.map((document) => (
			<option
				value={document?.nome}
				key={document?.id}
			>
				{document?.nome}
			</option>
		));
	}, []);

	const handleOnClickCreateCustomerRelationship = useCallback(
		(data, form) => {
			if (!formProps) {
				setFormProps(form);
			}

			onOpen();
		},
		[formProps, onOpen],
	);

	const validateDocumentType = () => {
		if (documentType === 'CNPJ') {
			return validateByRegex(
				/^(\d{14})$/,
				'CNPJ inválido, deve conter 14 dígitos',
			);
		}

		if (documentType === 'CPF') {
			return validateByRegex(
				/^(\d{11})$/,
				'CPF inválido, deve conter 11 dígitos',
			);
		}

		if (documentType === 'ID Estrangeiro') {
			return undefined;
		}
	};

	const renderRowsCreateCustomerRelationship =
		useCallback((): IFormRows[] => {
			const documentValidation = validateDocumentType();
			return [
				{
					type: 'common',
					rowFlexProps,
					columns: [
						{
							...makeGenericFormInputStructure({
								type: 'select',
								name: 'tipo_documento',
								label: 'Tipo de documento',
								showRequiredOnLabel: true,
								validate: {
									...validateRequired(),
								},
								inputProps: {
									'data-test':
										'select-tipo_de_documento-relacionamento-modal-cliente_relacionamento',
									...defaultInputStyle,
									selectOptions: renderSelectDocumentsType,
									onChange: handleOnChangeDocumentType,
								},
							}),
						},
					],
				},
				{
					type: 'common',
					rowFlexProps,
					columns: [
						{
							...makeGenericFormInputStructure({
								type: 'text',
								name: 'documento',
								label:
									documentType === 'CNPJ' ||
									documentType === 'CPF'
										? 'Documento (somente números)'
										: 'Documento',
								showRequiredOnLabel: true,
								validate: {
									...validateRequired(),
									...documentValidation,
								},
								inputProps: {
									'data-test':
										'input-documento-relacionamento-modal-cliente_relacionamento',
									...defaultInputStyle,
									maxLength: 20,
								},
							}),
						},
					],
				},
				{
					type: 'common',
					rowFlexProps,
					columns: [
						{
							...makeGenericFormInputStructure({
								type: 'text',
								name: 'razao_social',
								label:
									documentType === 'CNPJ' ||
									documentType === ''
										? 'Razão Social'
										: 'Nome',
								showRequiredOnLabel: true,
								validate: {
									...validateRequired(),
								},
								inputProps: {
									'data-test':
										'input-razao_social-relacionamento-modal-cliente_relacionamento',
									...defaultInputStyle,
								},
							}),
						},
					],
				},
			];
		}, [
			documentType,
			handleOnChangeDocumentType,
			renderSelectDocumentsType,
			validateDocumentType,
		]);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'id',
							label: 'Razão Social',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-razao_social-cadastrar_editar-page-cliente_relacionamento',
								...defaultInputStyle,
								searchType: 'cliente',
								renderInputLabel: renderClient,
								autocomplete: 'off',
								initialLabel: autocompleteData?.customer
									? renderClient(autocompleteData?.customer)
									: '',
								onChange: handleOnChangeCustomer,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'switch',
							name: 'autoRelationShip',
							label: 'Relacionar cliente com ele mesmo?',
							inputProps: {
								'data-test':
									'switch-relacionar_cliente_com_ele_mesmo-cadastrar_editar-page-cliente_relacionamento',
								colorScheme: 'green',
								onChange: handleOnChangeAutoRelationShip,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'cliente_relacionado_id',
							label: 'Relacionamento',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: () => isAutoRelationship,
							inputProps: {
								'data-test':
									'autocomplete-relacionamento-cadastrar_editar-page-cliente_relacionamento',
								...defaultInputStyle,
								searchType: 'cliente',
								renderInputLabel: renderClient,
								autocomplete: 'off',
								initialLabel: renderRelationship,
								hasButton: true,
							},
							button: {
								onClick:
									handleOnClickCreateCustomerRelationship,
								tooltip: 'Adicionar relacionamento',
								buttonProps: {
									'data-test':
										'button-adicionar_relacionamento-cliente_relacionamento',
									bg: 'easyBLUE.300',
									color: 'white',
								},
								buttonFlexProps: {
									gap: 5,
									justify: 'space-between',
								},
							},
						}),
					},
				],
			},
		];
	}, [
		autocompleteData?.customer,
		handleOnChangeAutoRelationShip,
		handleOnChangeCustomer,
		handleOnClickCreateCustomerRelationship,
		isAutoRelationship,
		renderRelationship,
	]);

	const onSubmitCreateCustomerRelationship = useCallback(
		(values) => {
			const body = {
				razao_social: values?.razao_social,
				relacionado: true,
				status: true,
				tipo_documento: values?.tipo_documento,
				documento: values?.documento,
			};

			setNewCustomer(body);

			createNewCustomerRelationship();

			onClose();
		},
		[createNewCustomerRelationship, onClose],
	);

	const renderModalCreate = useCallback(() => {
		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				onEsc={onClose}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						Cadastrar Relacionamento
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							Preencha todos os dados para cadastrar um
							relacionamento.
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<GenericForm
							dataTestProps={{
								cancel: 'button-cancelar-modal-cliente_relacionamento',
								save: 'button-salvar-modal-cliente_relacionamento',
							}}
							initialState={formInitialModalState}
							formFlexProps={formFlexProps}
							rows={renderRowsCreateCustomerRelationship()}
							style={defaultModalFormStyle}
							onSubmit={onSubmitCreateCustomerRelationship}
							onCancel={onClose}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}, [
		onSubmitCreateCustomerRelationship,
		isOpen,
		onClose,
		renderRowsCreateCustomerRelationship,
		formInitialModalState,
	]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				cliente_relacionado_id: values?.cliente_relacionado_id,
			};

			setPayload(body);

			if (isEditing) updateCustomerRelationship();
			else createCustomerRelationShip();
		},
		[createCustomerRelationShip, isEditing, updateCustomerRelationship],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-cliente_relacionamento',
						save: 'button-salvar-page-cliente_relacionamento',
					}}
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToCustomerRelationship}
				/>

				{renderModalCreate()}
			</>
		);
	}, [
		goToCustomerRelationship,
		initialState,
		onSubmit,
		renderModalCreate,
		renderRows,
	]);

	return renderForm();
};

export default FormCadastrarEditarClienteRelacionamento;
