import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataCidades = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		// ■ order: 3
		Header: 'UF',
		accessor: 'uf',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		// ■ order: 2
		Header: 'Município',
		accessor: 'municipio',
		type: 'autocomplete',
		filter: 'includesIgnoreCase',
	},
	{
		// ■ order: 5
		// ■ exibir o nome da microrregião, não o ID
		Header: 'Microrregião',
		accessor: 'microrregiao',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		// ■ order: 6
		// ■ exibir o nome da mesorregião, não o ID
		Header: 'Mesorregião',
		accessor: 'mesorregiao',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		// ■ order: 7
		// ■ exibir o nome da microrregião, não o ID
		Header: 'Zona de Venda',
		accessor: 'zonaVenda',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];
