import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { createStockOrientation } from 'services/api/requests/stock';

export const useCreateStockOrientation = (body, id) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => createStockOrientation(body, id), {
		onSuccess: sucess => {
			queryClient.invalidateQueries(['estoque-desejado-list']);

			toast({
				title: 'Cadastro com sucesso!',
				description: 'Orientação de estoque criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			toast({
				title: 'Erro ao criar orientação de estoque!',
				description: error.response[0].details.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
