import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getRecalculateIROG } from 'services/api/requests/IROG';

export const useGetRecalculateIROG = () => {
	const queryClient = useQueryClient();
	const toast = useToast();
	return useQuery(['recalculateIROG'], getRecalculateIROG, {
		refetchOnWindowFocus: false,
		enabled: false,
		onSuccess: sucess => {
			queryClient.invalidateQueries(['production-dashboard-data']);
			toast({
				title: 'Recálculo realizado com sucesso!',
				description:
					'Os indicadores de IROG, disponibilidade, performance e qualidade foram recalculados com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: error => {
			toast({
				title: 'Erro ao recalcular!',
				description:
					'Os indicadores de IROG, disponibilidade, performance e qualidade não foram recalculados.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
