import { useQuery } from '@tanstack/react-query';
import { getDownloadTemplatePortolio } from 'services/api/requests/uploads/uploads';

export const useDownloadTemplatePortfolio = () => {
	return useQuery(
		['download-template-portfolio'],
		getDownloadTemplatePortolio,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		},
	);
};
