import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import AbaControleAcuracidade from './abaControleAcuracidade';
import AbaAnaliseGrafica from './abaAnaliseGrafica';
import { useMemo } from 'react';
import { useAcuracidade } from './context';

const AbasAcuracidade = () => {
	const { selectedTab, setSelectedTab } = useAcuracidade();

	const defaultStyleProps = useMemo(
		() => ({
			_selected: {
				color: '#0296f2',
				borderBottom: '1px solid #0296f2',
			},
			_hover: {
				backgroundColor: '#e1e8ef',
			},
			_focus: {
				outline: '0 !important',
			},
			color: 'easyBLUE.300',
		}),
		[],
	);

	return (
		<Tabs
			variant='line'
			size='sm'
			align='start'
			w='100%'
			defaultIndex={selectedTab === 'controle_acuracidade' ? 0 : 1}
		>
			<TabList>
				<Tab
					panelId='controle_acuracidade'
					onClick={() => setSelectedTab('controle_acuracidade')}
					tabIndex={0}
					{...defaultStyleProps}
				>
					Controle Acuracidade
				</Tab>
				<Tab
					panelId='analise_grafica'
					onClick={() => setSelectedTab('analise_grafica')}
					tabIndex={1}
					{...defaultStyleProps}
				>
					Análise Gráfica
				</Tab>
			</TabList>

			<TabPanels>
				<TabPanel>
					<AbaControleAcuracidade />
				</TabPanel>
				<TabPanel>
					<AbaAnaliseGrafica />
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default AbasAcuracidade;
