import { Flex } from '@chakra-ui/react';
import FormBotSettings from 'components/forms/FormBotSettings';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';

export default function BotSettings() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpConfigurarProcessos: SidebarHelp = {
		title: 'Configurações do Robô',
		firstParagraph:
			'Nesta tela é possível relacionar e visualizar os parámetros de importação automatizada. Adapte a planilha de saída de seu sistema para os parámetros de cada processo e automatice a importação.',
		secondParagraph:
			'Para configurar um processo, selecione um “Processo de Automação” para carregar as propriedades necesárias que compõem o processo de automação. Uma vez listada as colunas, Selecione uma “Coluna do Processo” para relacionar com a coluna da sua planilha. Para visualizar as colunas da sua planilha, precisa “Importar planilha Modelo”, escolhendo seu arquivo ',
		thirdParagraph:
			'Caso for necessário pode adicionar tratamentos nos campos para mudar antes de o processo ser automatizado. Logo só precisa clicar em “Vincular Colunas” para ir ajustando o De - Para. Quando preencher os dados, e só clicar em “Salvar” para persistir os dados.',
		firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
		secondLink: 'https://calendly.com/suporte-easy360',
	};

	useEffect(() => {
		setDataSidebar(helpConfigurarProcessos);
	}, []);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			{<FormBotSettings />}
		</Flex>
	);
}
