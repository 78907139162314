import api from '../api';

export const createProductModel = async (body) => {
	const response = await api.post('/modeloProduto', body);
	return response.data;
};

export const getProductModelByFiltersAndLimit = async (
	busca?: string,
	limit?: number,
	orderBy?: string,
	sort?: string,
	familia_id?: number,
	sub_familia_id?: number,
	produto_status?: boolean,
) => {
	const response = await api.get('/modeloProduto/filtroModelo', {
		params: {
			busca,
			limit,
			orderBy,
			sort,
			familia_id,
			sub_familia_id,
			produto_status,
		},
	});
	return response.data;
};

export const getProductModelByFiltersAndLimitPage = async (payload) => {
	const response = await api.get('/modeloProduto/filtroModelo/paginado', {
		params: { ...payload },
	});
	return response.data;
};

export const updateProductModel = async (reqBody, id) => {
	const response = await api.patch(`/modeloProduto?id=${id}`, {
		...reqBody,
	});
	return response.data;
};

export const deleteProductModel = async (id: string | number) => {
	const response = await api.delete(`/modeloProduto?id=${id}`);
	return response.data;
};
