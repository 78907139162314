import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { getOpportunityById } from 'services/api/requests/opportunity/opportunity';

export const useGetOpportunityById = (setForm: any, id: number) => {
	return useQuery(['opportunityById', id], getOpportunityById, {
		onSuccess: (data) => {
			setForm({
				id: data.id,
				competencia: moment(data.data, 'YYYY-MM-DD').format(
					'YYYY-MM-DD',
				),
				cliente: data.cliente.id,
				cnpjCpf: data.cliente.cnpj,
				familia: data.produto.familia_id,
				produto: data.produto.id,
				codigoProduto: data.produto.codigo_produto,
				quantidade: data.quantidade,
				membro: data.membro_id,
				receitaBruta: data.receita_bruta.toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				}),
			});
		},
		onError: (error) => {},
		refetchOnWindowFocus: false,
	});
};

export const useGetOpportunityWithId = (id: Number) => {
	return useQuery(['opportunityWithId', id], getOpportunityById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
