import { useQuery } from '@tanstack/react-query';
import { getDownloadTemplateProducts } from 'services/api/requests/uploads/uploads';

export const useDownloadTemplateProduct = () => {
	return useQuery(
		['download-template-products'],
		getDownloadTemplateProducts,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		},
	);
};
