import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

export const checkExistsRestrictedPlan = async ({ queryKey }) => {
	const [, cycleId] = queryKey;

	const response = await api.get(
		'/planoRestrito/existsRestrictedPlanByCycle',
		{
			params: { ciclo_id: cycleId },
		},
	);
	return response.data;
};

export const updateRestrictedPlanByCycle = async (body) => {
	const url = `/planoRestrito`;
	const response = await api.post(url, body);
	return response.data;
};

export const recalculateRestrictPlan = async (cycleId) => {
	const url = `/planoRestrito/recalcular`;
	const response = await api.post(url, { ciclo_id: cycleId });
	return response.data;
};

export const exportRestrictedPlan = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id] = queryKey;
	const response = await api.get(
		`/planoRestrito/exportCSV?ciclo_id=${ciclo_id}`,
	);
	return response.data;
};

export const getFamiliesRestrictedPlanByCycle = async (payload) => {
	const response = await api.get(`/planoRestrito/familias`, {
		params: { ...payload },
	});
	return response.data;
};

export const getProductsRestrictedPlanByCycle = async (payload) => {
	const response = await api.get(`/planoRestrito/produtos`, {
		params: { ...payload },
	});
	return response.data;
};
