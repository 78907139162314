import api from "../api";

export const createAta = async (body, create) => {
    if(create) {
        const response = await api.post("/ata", body);
        return response.data;
    }

    const response = await api.post("/ata/atualizaAta", body);
    return response.data;
}

