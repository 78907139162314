import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { uploadStock } from 'services/api/requests/uploads/uploads';

export const useUploadActualStock = (
	setSucessUpload,
	openTableModal,
	isOpen,
	onClose,
) => {
	const toast = useToast();

	return useMutation((data) => uploadStock(data), {
		onSuccess: (success) => {
			if (
				success?.hasOwnProperty('quantidade_erros') &&
				success?.quantidade_erros > 0
			) {
				openTableModal();
				setSucessUpload(false);
				if (!isOpen) {
					toast({
						title: 'Arquivo enviado com erros!',
						description:
							'O Arquivo enviado possui erros. Verifique a tabela de erros e corrija-os.',
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				}
			} else {
				setSucessUpload(true);
				onClose();
			}
		},
		onError: (error: any) => {
			if (error.response?.data?.length === 1) {
				const errorMessage = error.response.data[0].message;
				toast({
					title: 'Erro ao importar arquivo.',
					description: `${errorMessage}`,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else if (error.response?.data?.length > 1) {
				error.response.data.forEach((error, index) => {
					const errorMessage = error.response.data[index].message;
					toast({
						title: 'Erro ao importar arquivo.',
						description: `${errorMessage}`,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				});
			}
		},
	});
};
