import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';
import api from '../api';

export const fetchSalesByFilters = async (body) => {
	console.log(body.limit, PAGINATION_SIZE_LIST[0]);

	const requestBody = {
		paginacao: {
			page: body.page,
			size: body.limit || PAGINATION_SIZE_LIST[0],
			order: [],
		},
		filtros: body.filters,
	};

	const response = await api.post('/venda/view', requestBody);
	return response.data;
};

export const createSale = async (body) => {
	const response = await api.post('/venda', body);
	return response.data;
};

export const deleteSale = async (id: number) => {
	const response = await api.delete(`venda?id=${id}`);
	return response.data;
};

export const deleteSaleByFilters = async (filters: any) => {
	const response = await api.post('venda/deletarPorFiltros', filters);
	return response.data;
};
