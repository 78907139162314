import {
	ButtonGroup,
	Flex,
	FormControl,
	Text,
	IconButton,
	Select,
	useDisclosure,
	List,
	ListItem,
	ListIcon,
	Button,
	Circle,
	CircularProgress,
} from '@chakra-ui/react';
import {
	IconAlertTriangle,
	IconCheck,
	IconCirclePlus,
	IconCircleMinus,
	IconDeviceDesktopAnalytics,
	IconFilter,
} from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import BarChart from 'components/chart/BarChart';
import Speedometer from 'components/chart/Speedometer';
import AtaModal from 'components/modal/AtaModal';
import AumentarCapacidadeModal from 'components/modal/AumentarCapacidadeModal';
import { useState, useEffect } from 'react';

import { speedometerChartOptions, barChartOptions } from './variables/charts';
import MultiSelect, {
	Option,
	Styles,
} from 'components/multiSelect/Multiselect';
import { useForm } from 'services/hooks/useForm';
import { MultiValue } from 'react-select';
import { useResourceGroupList } from 'services/queryClient/wrapperHooks/dashboardCapacidade/useResourceGroupList';
import { useCycleList } from 'services/queryClient/wrapperHooks/useCycleList';
import cycleYearOptions from 'utils/HandleFilters/cycleYearOptions';
import cycleMonthOptions from 'utils/HandleFilters/cycleMonthOptions';
import cycleByYear from 'utils/HandleFilters/cycleByYear';
import { Cycle } from 'models/cycle.model';
import { MonthOption } from 'models/option.model';
import { useCapacityDashboard } from 'services/queryClient/wrapperHooks/dashboardCapacidade/useCapacityDashboard';
import { Result } from 'models/capacity-dashboard.model';
import { GeneralResult } from './components/GeneralResult';
import formatChartData, { BarChartInterface } from './formatChartData';
import EditRestrictedPlanModal from './modals/EditRestrictedPlanModal';

const multiSelectStyles = {
	container: {
		maxWidth: '100%',
	},
	valueContainer: {
		flexWrap: 'nowrap',
	},
	multiValue: {
		minWidth: 'unset',
	},
} as Styles;

interface FilterOptions {
	yearOptions: Option[];
	cycleOptions: Cycle[];
	monthOptions: MonthOption[];
}

const defaultFilterOptions = {
	yearOptions: [],
	cycleOptions: [],
	monthOptions: [],
};

export default function DashboardCapacidade() {
	const [result, setResult] = useState(false);
	const [filterOptions, setFilterOptions] =
		useState<FilterOptions>(defaultFilterOptions);
	const [firstRequest, setFirstRequest] = useState(false);
	const [chartData, setChartData] = useState<BarChartInterface>({
		barChartData: [],
		categories: [],
	});

	const [ignorarCapacidadeOciosa, setIgnorarCapacidadeOciosa] = useState(
		Boolean(localStorage.getItem('dashboardCapacidade') || false),
	);

	const currentDate = new Date();

	const { data: resourceGrouplist, isLoading: isResourceLoading } =
		useResourceGroupList({ possui_recurso: true });
	const { data: cycleData } = useCycleList();

	const { form, onChange, changeFields } = useForm({
		recursos: [],
		meses: [],
		ano: currentDate.getFullYear(),
	});

	const {
		data: capacityDashboard,
		mutate,
		isLoading,
	} = useCapacityDashboard({
		...form,
		ignoreCapacityExcess: ignorarCapacidadeOciosa,
	});

	const [dataVelocimeter, setDataVelocimeter] = useState(0);

	useEffect(() => {
		mutate();
	}, [ignorarCapacidadeOciosa]);

	useEffect(() => {
		if (capacityDashboard && form) {
			const data =
				(capacityDashboard as any)?.indicadoresGerais?.geral
					?.percentual || 0;
			setDataVelocimeter(data);
		} else {
			setDataVelocimeter(0);
		}
	}, [capacityDashboard, form]);

	const [callDashboardAfterSave, setCallDashboardAfterSave] = useState(false);

	useEffect(() => {
		if (!isLoading && callDashboardAfterSave) {
			setCallDashboardAfterSave(false);
		}
	}, [isLoading]);

	useEffect(() => {
		mutate();
	}, [callDashboardAfterSave]);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenAta,
		onOpen: onOpenAta,
		onClose: onCloseAta,
	} = useDisclosure();
	const {
		isOpen: isOpenPlano,
		onOpen: onOpenPlano,
		onClose: onClosePlano,
	} = useDisclosure();

	const [headerModal, setHeaderModal] = useState('');

	const onChangeSelected = (key: string, value: MultiValue<Option>) => {
		changeFields({ ...form, [key]: value });
	};

	const resourceGroupOptions = resourceGrouplist?.map(
		(resource) =>
			({
				label: resource.nome_grupo_recurso,
				value: resource.id,
			} as Option),
	);

	useEffect(() => {
		if (cycleData?.length) {
			const yearOptions = cycleYearOptions(cycleData);
			let monthOptions = [] as MonthOption[];
			let cycleOptions = [] as Cycle[];

			if (form?.ano) {
				cycleOptions = cycleByYear(Number(form?.ano), cycleData);

				if (form?.ciclo_id) {
					monthOptions = cycleMonthOptions(
						Number(form?.ano),
						Number(form?.ciclo_id),
						cycleData,
					);
					changeFields({ ...form, meses: [] });
				} else if (
					Number(form?.ano) === currentDate.getFullYear() &&
					!firstRequest
				) {
					const cycle = cycleOptions.find(
						(cycle) => cycle.ciclo === currentDate.getMonth() + 1,
					);
					if (cycle?.id) {
						changeFields({ ...form, ciclo_id: cycle.id });
					}
				} else {
					changeFields({ ...form, meses: [] });
				}
			} else {
				changeFields({ ...form, ciclo_id: undefined, meses: [] });
			}

			setFilterOptions({
				yearOptions,
				monthOptions,
				cycleOptions,
			});
		}
	}, [cycleData, form?.ano, form?.ciclo_id]);

	useEffect(() => {
		if (!!form?.ciclo_id && !firstRequest) {
			mutate();
			setFirstRequest(true);
		}
	}, [form?.ciclo_id]);

	useEffect(() => {
		if (capacityDashboard?.demandaCapacidade.length) {
			setChartData(formatChartData(capacityDashboard.demandaCapacidade));
		}
	}, [capacityDashboard]);

	const onSubmit = (event) => {
		event.preventDefault();
		mutate();
	};

	const ResultListItem = (result: Result, index: number) => {
		const isSuccess = result.resultado >= 0;
		const description = `${
			result.nome_grupo_recurso
		} ${result.resultado.toLocaleString('pt-BR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		})} h/mes`;

		return (
			<ListItem
				key={`${result.nome_grupo_recurso}-${index}`}
				mr={'10px'}
				backgroundColor={!isSuccess ? '#0E1114' : ''}
				borderRadius={!isSuccess ? '6px' : ''}
				padding={!isSuccess ? '4px' : ''}
			>
				<ListIcon
					size={'20px'}
					as={isSuccess ? IconCheck : IconAlertTriangle}
					color={isSuccess ? 'green.500' : 'yellow.300'}
				/>
				{description}
			</ListItem>
		);
	};

	const resultByResourceModal = capacityDashboard?.resultados
		.filter((item) => {
			return item.resultado < 0;
		})
		.slice(0, 3);

	const getCycleSelect = () => {
		const cycleSelect = cycleData?.filter((item) => {
			return item.id == form?.ciclo_id;
		});

		if (cycleSelect) {
			return cycleSelect[0]?.ciclo;
		}
	};

	const checkReopenModalFromAction = (reOpenModal = false) => {
		onClosePlano();

		if (reOpenModal) setTimeout(() => onOpenPlano(), 500);
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card
				bgGradient={
					'radial-gradient(100% 100.05% at 0% 0%, #8EA1BE 0%, #293039 63.54%, #5E6E85 100%)'
				}
				minHeight={'80vh'}
			>
				<Flex
					rowGap={6}
					columnGap={4}
					mb={12}
					alignItems='center'
					justifyContent='space-between'
				>
					<Flex
						gap={4}
						alignItems='center'
						justifyContent='flex-start'
						flexGrow={1}
						as='form'
						onSubmit={onSubmit}
					>
						<FormControl
							minWidth='150px'
							width={'40%'}
							maxWidth={800}
							flexGrow={1}
						>
							<MultiSelect
								options={resourceGroupOptions || []}
								value={form?.recursos.map((item) => ({
									label: item.label,
									value: item.value,
								}))}
								onChange={(value) =>
									onChangeSelected('recursos', value)
								}
								id='select-filtrar-recursos'
								size={'medium'}
								placeholder='Grupo de recursos'
								notHideSelectedOptions
								selectAllTitle={'Todos os Grupos'}
								isLoading={isResourceLoading}
								styles={multiSelectStyles}
							/>
						</FormControl>

						<FormControl maxWidth={100}>
							<Select
								data-test='select-filtrar-ano'
								placeholder='Ano'
								name={'ano'}
								value={form?.ano}
								onChange={(e) => onChange(e)}
								backgroundColor={'#fff'}
								h='44px'
								borderRadius={10}
								required
							>
								{filterOptions.yearOptions.map((year) => (
									<option
										key={year.value}
										value={year.value}
									>
										{year.label}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl maxWidth={100}>
							<Select
								data-test='select-filtrar-ciclo'
								placeholder='Ciclo'
								name={'ciclo_id'}
								value={form?.ciclo_id}
								onChange={(e) => onChange(e)}
								backgroundColor={'#fff'}
								h='44px'
								borderRadius={10}
								required
							>
								{filterOptions.cycleOptions.map((cycle) => (
									<option
										key={cycle.id}
										value={cycle.id}
									>
										{cycle.ciclo}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl
							minWidth='100px'
							maxWidth={200}
						>
							<MultiSelect
								options={filterOptions.monthOptions}
								value={form?.meses.map((item) => ({
									label: item.label,
									value: item.value,
								}))}
								onChange={(value) =>
									onChangeSelected('meses', value)
								}
								id='select-filtrar-mes'
								size={'medium'}
								placeholder='Meses'
								notHideSelectedOptions
								selectAllTitle={'Todos os Meses'}
								styles={multiSelectStyles}
							/>
						</FormControl>

						<IconButton
							data-test='button-filtrar-dashboard_de_capacidade'
							aria-label='filtrar'
							icon={<IconFilter />}
							type='submit'
						/>
					</Flex>

					<ButtonGroup
						spacing={'20px'}
						marginLeft='auto'
					>
						<ButtonComponent
							data-test='button-editar_plano-page-dashboard_capacidade'
							type={'primary'}
							title={'Editar plano'}
							onClick={(e) => {
								onOpenPlano();
								setHeaderModal('editar');
							}}
						/>
						<ButtonComponent
							data-test='button-registro_de_ata'
							type={'secondary'}
							title={'ATA'}
							onClick={onOpenAta}
						/>
					</ButtonGroup>
				</Flex>
				{!isLoading ? (
					<>
						<AtaModal
							isOpen={isOpenAta}
							onClose={onCloseAta}
							provider={'DASHBOARD_CAPACIDADE'}
							monthValidation={getCycleSelect()}
							data={capacityDashboard?.ata}
							getUpdatedData={mutate}
						/>

						<AumentarCapacidadeModal
							isOpen={isOpen}
							onClose={onClose}
							resultByResource={resultByResourceModal}
						/>

						<EditRestrictedPlanModal
							isOpen={isOpenPlano}
							onClose={onClosePlano}
							cycleId={form?.ciclo_id}
							CycleData={cycleData}
							checkReopenModalFromAction={
								checkReopenModalFromAction
							}
						/>
						<Flex>
							<Flex
								w={'80%'}
								flexDirection={'column'}
								gap={6}
							>
								<Flex
									w={'100%'}
									gap={12}
									justifyContent={'center'}
								>
									<Flex
										flexDirection={'column'}
										alignItems={'center'}
									>
										{capacityDashboard && (
											<Speedometer
												speedometerData={[
													dataVelocimeter,
												]}
												speedometerOptions={speedometerChartOptions(
													dataVelocimeter,
												)}
											/>
										)}
										<Flex
											flexDirection={'column'}
											mt={4}
										>
											<Flex
												alignItems='center'
												gap={4}
												padding={'6px'}
											>
												<Circle
													size='12px'
													bg='gray.50'
												/>
												<Text color={'gray.50'}>
													Dias úteis
												</Text>
												{capacityDashboard
													?.indicadoresGerais
													?.diasUteis != null ? (
													<Text
														color={'gray.50'}
														fontSize={'20px'}
														data-test='indicadores-dias_uteis'
													>
														{
															capacityDashboard
																?.indicadoresGerais
																?.diasUteis
														}
													</Text>
												) : (
													<Text
														color={'gray.50'}
														fontSize={'20px'}
													>
														-
													</Text>
												)}
											</Flex>
											<Flex
												alignItems='center'
												gap={4}
												padding={'6px'}
											>
												<Circle
													size='12px'
													bg='#56CCF2'
												/>
												<Text color={'gray.50'}>
													Capacidade
												</Text>
												{capacityDashboard
													?.indicadoresGerais
													?.capacidadeTotal !=
												null ? (
													<>
														<Text
															color={'gray.50'}
															fontSize={'20px'}
															data-test='indicadores-capacidade'
														>
															{capacityDashboard?.indicadoresGerais?.capacidadeTotal?.toLocaleString(
																'pt-BR',
																{
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																},
															)}
														</Text>
														<Text color={'gray.50'}>
															h/mês
														</Text>
													</>
												) : (
													<Text
														color={'gray.50'}
														fontSize={'20px'}
													>
														-
													</Text>
												)}
											</Flex>
											<Flex
												alignItems='center'
												gap={4}
												padding={'6px'}
											>
												<Circle
													size='12px'
													bg='#FC6574'
												/>
												<Text color={'gray.50'}>
													Demanda
												</Text>
												{capacityDashboard
													?.indicadoresGerais
													?.demandaTotal != null ? (
													<>
														<Text
															color={'gray.50'}
															fontSize={'20px'}
															data-test='indicadores-demanda'
														>
															{Number(
																capacityDashboard
																	?.indicadoresGerais
																	?.demandaTotal,
															).toLocaleString(
																'pt-BR',
																{
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																},
															)}
														</Text>
														<Text color={'gray.50'}>
															h/mês
														</Text>
													</>
												) : (
													<Text
														color={'gray.50'}
														fontSize={'20px'}
													>
														-
													</Text>
												)}
											</Flex>
										</Flex>
									</Flex>
									<Flex
										flexDirection={'column'}
										gap={6}
									>
										<GeneralResult
											generalResult={
												capacityDashboard?.indicadoresGerais
											}
											ignorarCapacidadeOciosa={
												ignorarCapacidadeOciosa
											}
											setIgnorarCapacidadeOciosa={
												setIgnorarCapacidadeOciosa
											}
										/>

										{!result ? (
											<>
												<Card
													bgColor={'#0E1114'}
													width={'350px'}
												>
													<Text
														color={'#fff'}
														pb={4}
													>
														Ações recomendadas
													</Text>
													<ButtonGroup
														spacing={'20px'}
													>
														<Button
															leftIcon={
																<IconCirclePlus color='#2F94CD' />
															}
															onClick={onOpen}
															colorScheme='gray'
														>
															Capacidade
														</Button>
														<Button
															leftIcon={
																<IconCircleMinus color='#E86170' />
															}
															onClick={(e) => {
																onOpenPlano();
																setHeaderModal(
																	'demanda',
																);
															}}
															colorScheme='gray'
														>
															Demanda
														</Button>
													</ButtonGroup>
												</Card>
											</>
										) : (
											<>
												<Card
													bgColor={'#0E1114'}
													width={'350px'}
												>
													<Text
														color={'#fff'}
														pb={4}
													>
														Ações recomendadas
													</Text>
													<ButtonGroup
														spacing={'20px'}
													>
														<Button
															w={'100%'}
															onClick={(e) =>
																setResult(false)
															}
															leftIcon={
																<IconDeviceDesktopAnalytics color='#324864' />
															}
															colorScheme='gray'
														>
															Dashboard Demandas
														</Button>
													</ButtonGroup>
												</Card>
											</>
										)}
									</Flex>
								</Flex>

								{chartData.barChartData.length > 0 && (
									<BarChart
										chartData={chartData.barChartData}
										chartOptions={barChartOptions(
											chartData.categories,
										)}
									/>
								)}
							</Flex>
							{capacityDashboard?.resultados &&
								capacityDashboard?.resultados?.length > 0 && (
									<Flex
										w={'20%'}
										height={'80vh'}
									>
										<Card bgColor={'rgba(0, 0, 0, 0.3)'}>
											<Text
												color={'#fff'}
												mb={4}
											>
												Resultado por Grupo de Recurso
											</Text>
											<List
												spacing={3}
												color={'#fff'}
												overflow={'auto'}
												css={{
													'&::-webkit-scrollbar': {
														width: '4px',
													},
													'&::-webkit-scrollbar-track':
														{
															width: '6px',
														},
													'&::-webkit-scrollbar-thumb':
														{
															background:
																'#8a9db9',
															borderRadius:
																'24px',
														},
												}}
											>
												{capacityDashboard?.resultados?.map(
													(result, index) =>
														ResultListItem(
															result,
															index,
														),
												)}
											</List>
										</Card>
									</Flex>
								)}
						</Flex>
					</>
				) : (
					<CircularProgress
						alignSelf={'center'}
						isIndeterminate
						color='red.600'
					/>
				)}
			</Card>
		</Flex>
	);
}
