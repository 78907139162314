export type ActionDrop = (
  evt: React.DragEvent<HTMLDivElement>,
  idsFatherTo: string[] | false,
  idsFatherFrom: string[] | false,
  produto: any,
) => void;
export const useDragAndDrop = (actionDrop: ActionDrop) => {
  const onDragStart = (
    evt: React.DragEvent<HTMLDivElement>,
    idsFathers: string[] | false,
    produto: any
  ) => {
    const element = evt.currentTarget;
    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain",
      JSON.stringify([
        idsFathers,
        produto
      ]
      ));
    evt.dataTransfer.effectAllowed = "move";
  };
  const onDragEnd = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.currentTarget.classList.remove("dragged");
  };
  const onDragEnter = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    const element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };
  const onDragLeave = (evt: React.DragEvent<HTMLDivElement>) => {
    // const currentTarget = evt.currentTarget;
    // const newTarget = evt.relatedTarget;
    // if (newTarget?.parentNode === currentTarget || newTarget === currentTarget)
    //   return;
    evt.preventDefault();
    const element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };
  const onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };
  const onDrop = (
    evt: React.DragEvent<HTMLDivElement>,
    idsFratherTo: string[] | false,
  ) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = evt.dataTransfer.getData("text/plain");
    const [idsFratherFrom, produto] = JSON.parse(data)
    actionDrop(evt, idsFratherTo, idsFratherFrom, produto);
  };
  return {
    onDragEnd,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDragStart,
    onDrop
  };
};
