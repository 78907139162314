import { useQuery } from '@tanstack/react-query';
import { FiltersByDependency } from 'components/filter/models/TableFilterModels';
import {
	fetchByFiltersDependency,
	fetchByFiltersDependencyFromExternalAPI,
} from 'services/api/requests/filters/filters';

export const useGetFiltersByDependency = (variables: FiltersByDependency) => {
	const {
		column,
		search,
		size,
		page,
		module,
		getFromExternalAPI = false,
	} = variables;
	return useQuery(
		[`${module}-list-dependency`, variables],
		() => {
			const requestBody = {
				nome_coluna: column,
				pesquisa: search,
				size: size,
				page: page,
			};

			return getFromExternalAPI
				? fetchByFiltersDependencyFromExternalAPI(requestBody)
				: fetchByFiltersDependency(requestBody, module);
		},

		{
			onError: (error) => {
				console.log('error', error);
			},
			refetchOnWindowFocus: false,
			retry: false,
			enabled: variables.module ? true : false,
			// cacheTime: 0, (desabilitando o cache, se necessário)
		},
	);
};
