import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

const ContainerBodyTableFlex = styled.div`
	width: 100%;
	overflow-x: auto;
	display: flex;
	justify-content: start;
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 2px grey;
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: ${(props) =>
			props.color === 'secondary' ? 'rgb(194, 201, 225)' : '#FC8181'};
		border-radius: 10px;
	}
	::-webkit-scrollbar-thumb:hover {
		background: ${(props) =>
			props.color === 'secondary' ? 'rgb(177, 187, 221)' : '#b30000'};
	}
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
`;
interface ScroolBarProps {
	children: JSX.Element | JSX.Element[];
	color?: 'primary' | 'secondary';
}
export const ScrollBar = ({ children, color }: ScroolBarProps) => {
	return (
		<ContainerBodyTableFlex color={color}>
			<Flex
				position={'relative'}
				flexGrow={1}
			>
				{children}
			</Flex>
		</ContainerBodyTableFlex>
	);
};
