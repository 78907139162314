import {
	defaultInputStyle,
	defaultFormStyle,
	columnFlexProps,
} from '../../utils/forms/defaultsProps';
import { formControlProps } from '../../utils/forms/defaultsProps';
import { Avatar, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useState } from 'react';
import { useUpdateUserAccount } from '../../services/queryClient/wrapperHooks/authentication/useUpdateUserAccount';
import { useHistory } from 'react-router-dom';
import { validateRequired } from '../../utils/forms/validations';
import { CreateMemberModal } from 'components/modal/CreateMemberModal';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useMembersList } from 'services/queryClient/wrapperHooks/useMemberList';
import { useGetAddressByCep } from 'services/queryClient/wrapperHooks/externalServices/useGetAddressByCep';
import { AccountCredentialsModal } from 'components/modal/AccountCredentialsModal';

interface IFormGerenciarPerfil {
	initialState: any;
}

const FormGerenciarPerfil = ({ initialState }: IFormGerenciarPerfil) => {
	const history = useHistory();

	const {
		userAttributes,
		setUserAttributes,
		permissionsPage,
		userInfos,
		setUserInfos,
	} = useGlobalContext();

	const [payload, setPayload] = useState<any>();
	const [formProps, setFormProps] = useState<any>();
	const [dadosMembro, setDadosMembro] = useState<{
		nome: string;
		email: string;
	}>({ nome: '', email: '' });

	const [titleModalCreateMember, setTitleModalCreateMember] =
		useState<string>();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenModalCreate,
		onOpen: onOpenModalCreate,
		onClose: onCloseModalCreate,
	} = useDisclosure();

	const { mutate: updateUserAccount, isLoading: isLoadingUpdateUserAccount } =
		useUpdateUserAccount(setUserAttributes, setUserInfos);

	const goToProdutos = useCallback(() => {
		history.goBack();
	}, [history]);

	const membersFilters = [
		{
			nome_coluna: 'possui_usuario',
			tipo_valor: 'boolean',
			valores: false,
			operacao: 'equal',
		},
		{
			nome_coluna: 'usuario_id',
			tipo_valor: 'number',
			valores: userInfos?.id ? userInfos.id : [],
			operacao: 'equal',
			prioritario: true,
		},
	];

	const { data: MembersData, isLoading: isLoadingMemberData } =
		useMembersList(
			{
				page: 0,
				limit: 100,
				filters: membersFilters,
			},
			!!userInfos?.id,
		);

	const renderSelectMember = useCallback(() => {
		if (!MembersData?.dados?.length) return [];

		return MembersData?.dados?.map((member) => (
			<option
				value={member?.id}
				key={member?.id}
			>
				{member?.nome_membro}
			</option>
		));
	}, [MembersData?.dados]);

	const handleOnAddMember = useCallback(
		(_, formProperties) => {
			if (!formProps) {
				setFormProps(formProperties);
			}
			const { getValues } = formProperties;
			setDadosMembro({
				nome: getValues('nome'),
				email: getValues('email'),
			});

			setTitleModalCreateMember('membro');
			setTimeout(() => {
				onOpenModalCreate();
			}, 150);
		},
		[formProps, onOpenModalCreate],
	);

	const [cep, setCep] = useState<string>('');

	const { data: DataCEP, refetch: RefetchCEP } = useGetAddressByCep(
		cep,
		undefined,
		undefined,
		true,
		formProps,
	);

	const handleOnChangeCEP = useCallback(
		(event, _formProps) => {
			if (!formProps) {
				setFormProps(_formProps);
			}
			const value = event.target.value || '';
			if (value.replace(/(\d{5})(\d)/, '$1-$2').length === 9) {
				setCep(value);
			}
		},
		[formProps],
	);

	const renderAddonContentTel = useCallback(() => {
		return (
			<Text
				display={'flex'}
				onClick={() => {}}
				borderLeftRadius={10}
				borderRightRadius={0}
				border={'1px solid #e2e8f0'}
				variant='ghost'
				h={10}
				w={14}
				textAlign='center'
				justifyContent={'center'}
				alignItems={'center'}
				fontSize={'sm'}
			>
				+55
			</Text>
		);
	}, []);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome',
							label: 'Nome Completo',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: João da Silva',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'empresa',
							label: 'Empresa',
							showRequiredOnLabel: true,
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Easy360 LTDA',
								defaultValue: userAttributes?.[
									'custom:razaoSocial'
								]
									? userAttributes?.['custom:razaoSocial']
									: 'Easy360',
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'telefone',
							label: 'Telefone Celular',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: (41) 99999-9999',
								'data-mask': '(00) 00000-0000',
								addonInputType: 'tel',
								addonPosition: 'left',
								addonContent: renderAddonContentTel(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'email',
							name: 'email',
							label: 'E-mail',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: joaodasilva@easy360.ind.br',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'cep',
							label: 'CEP - Endereço Comercial',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: 80215-090',
								'data-mask': '00000-000',
								pattern: '[0-9]{5}-[0-9]{3}',
								maxLength: 9,
								onChange: handleOnChangeCEP,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'bairro',
							label: 'Bairro',
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Jardim Botânico',
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						subColumns: [
							{
								columnFlexProps: {
									flexDirection: 'row',
									w: '100%',
									mr: '30px',
								},
								inputs: [
									{
										formControlProps,
										input: {
											type: 'text',
											name: 'rua',
											label: 'Rua',
											inputProps: {
												...defaultInputStyle,
												placeholder:
													'ex.: Av. Comendador Franco',
											},
											disabled: () => true,
										},
									},
								],
							},
						],
					},
					{
						subColumns: [
							{
								columnFlexProps: {
									flexDirection: 'row',
									w: '100%',
								},
								inputs: [
									{
										formControlProps: {
											pt: '10px',
											pr: '15px',
										},
										input: {
											type: 'number',
											name: 'numero',
											label: 'Nº',
											inputProps: {
												...defaultInputStyle,
												placeholder: 'ex.: 1341',
											},
										},
									},
									{
										formControlProps: {
											pt: '10px',
											pl: '15px',
										},
										input: {
											type: 'text',
											name: 'complemento',
											label: 'Complemento',
											inputProps: {
												...defaultInputStyle,
												placeholder:
													'ex.: Habitat Senai',
											},
										},
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'membro_id',
							label: 'Associar a um Membro',
							inputProps: {
								...defaultInputStyle,
								selectOptions: renderSelectMember(),
							},
							button: {
								onClick: handleOnAddMember,
								disabled: () =>
									!dadosMembro?.nome?.trim() ||
									!dadosMembro?.email?.trim(),
								tooltip: 'Criar um novo membro',
								buttonProps: {
									bg: 'easyBLUE.300',
									color: 'white',
								},
								buttonFlexProps: {
									gap: 5,
									justify: 'space-between',
								},
							},
						}),
					},
				],
			},
		];
	}, [
		dadosMembro?.email,
		dadosMembro?.nome,
		handleOnAddMember,
		handleOnChangeCEP,
		renderAddonContentTel,
		renderSelectMember,
		userAttributes,
	]);

	const renderModalCreate = useCallback(() => {
		return (
			<CreateMemberModal
				isOpenModalCreate={isOpenModalCreate}
				onCloseModalCreate={onCloseModalCreate}
				form={'form'}
				onChange={() => {}}
				dadosMembro={dadosMembro}
			/>
		);
	}, [dadosMembro, isOpenModalCreate, onCloseModalCreate]);

	const renderAccountCredentialsModal = useCallback(() => {
		return (
			<AccountCredentialsModal
				isOpen={isOpen}
				onClose={onClose}
				body={payload}
				setUserAttributes={setUserAttributes}
				setUserInfos={setUserInfos}
			/>
		);
	}, [isOpen, onClose, payload, setUserAttributes, setUserInfos]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				nome: values?.nome || null,
				telefone: values?.telefone?.replace(/\D/g, '') || null,
				email: values?.email || null,
				cep: values?.cep?.replace(/\D/g, '') || null,
				bairro: values?.bairro || null,
				rua: values?.rua || null,
				numero: values?.numero || null,
				complemento: values?.complemento || null,
				endereco:
					values?.rua && values?.bairro
						? `${values?.rua}, ${values?.bairro}`
						: null,
				membro_id: values?.membro_id || null,
			};

			if (values?.email === userAttributes?.email) {
				updateUserAccount(body);
			} else {
				setPayload(body);
				onOpen();
			}
		},
		[onOpen, updateUserAccount, userAttributes?.email],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<Flex
					flexDirection={'row'}
					alignItems={'center'}
					padding={'1em 1em .5em .5em'}
				>
					<Avatar
						h='96px'
						w='96px'
						name={
							userAttributes?.name
								? userAttributes?.name
								: 'Admin Easy360'
						}
						border={'6px solid #F3F3F3'}
						cursor={'pointer'}
						size={'lg'}
						background={
							'linear-gradient(135.81deg, #F0243E 15.92%, #314866 109.14%)'
						}
						color={'white'}
					/>
					<Flex
						flexDirection={'column'}
						padding={'1em'}
					>
						<Text
							pt={'1em'}
							w='100%'
							fontSize='md'
							fontWeight='700'
							color={'easyBLUE.300'}
						>
							{userAttributes?.name
								? userAttributes?.name
								: 'Admin Easy360'}
						</Text>
						<Text
							w='100%'
							mt={'.5em'}
							fontSize='sm'
							color={'#8F9BBA'}
						>
							{userAttributes?.email
								? userAttributes?.email
								: 'admin@easy360.ind.br'}
						</Text>
					</Flex>
				</Flex>

				<GenericForm
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToProdutos}
					loadingSubmit={isLoadingUpdateUserAccount}
				/>

				{renderModalCreate()}
				{renderAccountCredentialsModal()}
			</>
		);
	}, [
		goToProdutos,
		initialState,
		isLoadingUpdateUserAccount,
		onSubmit,
		renderAccountCredentialsModal,
		renderModalCreate,
		renderRows,
		userAttributes?.email,
		userAttributes?.name,
	]);

	return renderForm();
};

export default FormGerenciarPerfil;
