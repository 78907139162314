import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSubFamily } from 'services/api/requests/requests';
import { useToast } from '@chakra-ui/react';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useCreateSubFamily = (
	form?,
	setForm?,
	inputName?: string,
	formProps?,
	setId?,
	setAutocompleteData?,
) => {
	const queryClient = useQueryClient();
	const toast = useToast();
	return useMutation(createSubFamily, {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['family-list']);
			queryClient.invalidateQueries(['family-list-with-products']);
			toast({
				title: 'Criado com sucesso!',
				description: 'Subfamilia criada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});

			queryClient.invalidateQueries(['subfamily-list']);
			if (form && setForm) {
				setForm({
					...form,
					subfamilia: success.id,
				});
			}

			if (inputName && formProps) {
				const { setValue } = formProps;

				setValue(inputName, String(success.id));

				if (setId) {
					setId(success.id);
				}

				if (setAutocompleteData)
					setAutocompleteData((prev) => ({
						...prev,
						sub_family: success,
					}));
			}
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Subfamília!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
