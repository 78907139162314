export const columnsDataOpportunityException = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Cód.',
		accessor: 'codigo',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Código da Oportunidade',
	},
	{
		Header: 'Seq.',
		accessor: 'sequencia_codigo',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Sequência do Código da Oportunidade',
	},
	{
		Header: 'Competência',
		accessor: 'competencia',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Ciclo/Ano',
		accessor: 'cicloAno',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cliente',
		accessor: 'cliente',
		type: 'autocomplete',
		optional: false,
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'CNPJ/CPF/ID Estrangeiro',
		accessor: 'cnpjCpfId',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Família',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Produto',
		accessor: 'produto',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigoProduto',
		type: 'disabledCodigoProduto',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Membro',
		accessor: 'membro',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Exceção',
		accessor: 'excecao',
		type: 'badge-yes-no',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
];
