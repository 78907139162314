import { useQuery } from '@tanstack/react-query';
import { getSalesHistory } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface UseSalesHistory {
	cycleId?: string | number;
	familyId?: string;
	salesChannelId?: string;
	salesZoneId?: string;
	clientId?: string;
	historicalBasis?: string;
}
const keys = [
	'cycleId',
	'familyId',
	'salesChannelId',
	'salesZoneId',
	'clientId',
	'historicalBasis',
];
const getKeysValuesSalesHistory = (query: UseSalesHistory, filter = true) => {
	const key = [
		'sales-history',
		...keys.map((key) => (query[key] ? String(query[key]) : '')),
	];
	if (filter) {
		return key.filter((key) => key !== '');
	}
	return key;
};
export const useSalesHistory = (query: UseSalesHistory) => {
	return useQuery(getKeysValuesSalesHistory(query, false), getSalesHistory, {
		refetchOnWindowFocus: false,
	});
};
