import {
	Heading,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';
import { useCallback, useEffect, useState } from 'react';
import { makeGenericFormInputStructure } from '../../../../../utils/forms/defaultInputs';
import { renderFamily, renderSubFamily } from 'utils/forms/autocomplete';
import {
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from '../../../../../utils/forms/defaultsProps';
import {
	validateMin,
	validateRequired,
} from '../../../../../utils/forms/validations';
import { useCreateFamily } from 'services/queryClient/wrapperHooks/useCreateFamily';
import { useCreateSubFamily } from 'services/queryClient/wrapperHooks/useCreateSubFamily';
import { useCreateProductModel } from 'services/queryClient/wrapperHooks/useCreateProductModel';
import { useUpdateFamily } from 'services/queryClient/wrapperHooks/useUpdateFamily';
import { useUpdateSubFamily } from 'services/queryClient/wrapperHooks/useUpdateSubFamily';
import { useUpdateProductModel } from 'services/queryClient/wrapperHooks/useUpdateProductModel';

interface IModalCadastrarEditarFamiliaProps {
	type: 'family' | 'subFamily' | 'productModel';
	isOpen: boolean;
	onClose: () => void;
	autocompleteAdditionalData?: any;
}

const ModalCadastrarEditarFamilia = (
	props: IModalCadastrarEditarFamiliaProps,
) => {
	const { type, isOpen, onClose, autocompleteAdditionalData } = props;

	const [formInitialState, setFormInitialState] = useState({});
	const [payload, setPayload] = useState({});

	const [familyId, setFamilyId] = useState<number | null>(null);
	const [subFamilyId, setSubFamilyId] = useState<number | null>(null);
	const [productModelId, setProductModelId] = useState<number | null>(null);

	const { mutate: createFamily } = useCreateFamily();
	const { mutate: createSubFamily } = useCreateSubFamily();
	const { mutate: createProductModel } = useCreateProductModel();

	const { mutate: updateFamily } = useUpdateFamily(payload, familyId);
	const { mutate: updateSubFamily } = useUpdateSubFamily(
		payload,
		subFamilyId,
	);
	const { mutate: updateProductModel } = useUpdateProductModel(
		payload,
		productModelId,
	);

	function getModalTitle() {
		if (type === 'family') {
			if (familyId) return 'Editar Família';
			return 'Cadastrar Família';
		}
		if (type === 'subFamily') {
			if (subFamilyId) return 'Editar Subfamília';
			return 'Cadastrar Subfamília';
		}
		if (type === 'productModel') {
			if (productModelId) return 'Editar Modelo de Produto';
			return 'Cadastrar Modelo de Produto';
		}
	}

	const handleOnChangeFamily = useCallback((value: any) => {
		if (value) {
			setFamilyId(value?.id);
		} else {
			setFamilyId(null);
		}
	}, []);

	const handleOnChangeSubFamily = useCallback((value: any) => {
		if (value) {
			setSubFamilyId(value?.id);
		} else {
			setSubFamilyId(null);
		}
	}, []);

	const shouldClearAutocompleteSubFamily = useCallback(() => {
		if (!familyId) return false;

		return autocompleteAdditionalData?.subFamily?.familia_id !== familyId;
	}, [familyId, autocompleteAdditionalData?.subFamily?.familia_id]);

	const buildFamilyFormInput = useCallback((): IFormRows => {
		if (type !== 'family')
			return {
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: () =>
								!!autocompleteAdditionalData?.family?.id,
							inputProps: {
								'data-test': `autocomplete-familia-${
									type === 'subFamily'
										? 'subfamilia'
										: 'modelo_de_produto'
								}-modal-familia`,
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
								onChange: handleOnChangeFamily,
							},
						}),
					},
				],
			};

		return {
			type: 'common',
			rowFlexProps,
			columns: [
				{
					...makeGenericFormInputStructure({
						type: 'text',
						name: 'nome_familia',
						label: 'Nome da Família',
						showRequiredOnLabel: true,
						validate: {
							...validateRequired(),
							...validateMin(),
						},
						inputProps: {
							'data-test':
								'input-nome_da_familia-familia-modal-familia',
							...defaultInputStyle,
						},
					}),
				},
			],
		};
	}, [autocompleteAdditionalData?.family, handleOnChangeFamily, type]);

	const buildSubFamilyFormInput = useCallback((): IFormRows => {
		if (type !== 'subFamily')
			return {
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'sub_familia_id',
							label: 'Subfamília',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: () =>
								!!autocompleteAdditionalData?.subFamily?.id,
							inputProps: {
								'data-test':
									'autocomplete-subfamilia-modelo_de_produto-modal-familia',
								...defaultInputStyle,
								searchType: 'sub_familia',
								renderInputLabel: renderSubFamily,
								autocomplete: 'off',
								initialLabel:
									autocompleteAdditionalData?.subFamily
										? renderSubFamily(
												autocompleteAdditionalData?.subFamily,
										  )
										: '',
								onChange: handleOnChangeSubFamily,
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteSubFamily,
							},
						}),
					},
				],
			};

		return {
			type: 'common',
			rowFlexProps,
			columns: [
				{
					...makeGenericFormInputStructure({
						type: 'text',
						name: 'nome_sub_familia',
						label: 'Nome da Subfamília',
						showRequiredOnLabel: true,
						validate: {
							...validateRequired(),
							...validateMin(),
						},
						inputProps: {
							'data-test':
								'input-nome_da_subfamilia-subfamilia-modal-familia',
							...defaultInputStyle,
						},
						disabled: () => !familyId,
					}),
				},
			],
		};
	}, [
		autocompleteAdditionalData?.subFamily,
		handleOnChangeSubFamily,
		shouldClearAutocompleteSubFamily,
		familyId,
		type,
	]);

	const buildProductModelFormInput = useCallback((): IFormRows => {
		return {
			type: 'common',
			rowFlexProps,
			columns: [
				{
					...makeGenericFormInputStructure({
						type: 'text',
						name: 'nome_modelo',
						label: 'Nome do Modelo',
						showRequiredOnLabel: true,
						validate: {
							...validateRequired(),
							...validateMin(),
						},
						inputProps: {
							'data-test':
								'input-nome_do_modelo-modelo_de_produto-modal-familia',
							...defaultInputStyle,
							placeholder: 'Nome do Modelo',
						},
						disabled: () => !subFamilyId,
					}),
				},
			],
		};
	}, [subFamilyId]);

	const renderFormRows = useCallback((): IFormRows[] => {
		const formRows: IFormRows[] = [];

		formRows.push(buildFamilyFormInput());

		if (type === 'subFamily') formRows.push(buildSubFamilyFormInput());

		if (type === 'productModel') {
			formRows.push(buildSubFamilyFormInput());
			formRows.push(buildProductModelFormInput());
		}

		return formRows;
	}, [
		buildFamilyFormInput,
		buildProductModelFormInput,
		buildSubFamilyFormInput,
		type,
	]);

	const onSubmit = useCallback(
		(values) => {
			if (type === 'family') {
				if (familyId) {
					setPayload({
						nome: values?.nome_familia,
					});
					updateFamily();
				} else {
					createFamily(values);
				}
			}

			if (type === 'subFamily') {
				if (subFamilyId) {
					setPayload({
						familia: values?.familia_id,
						nome: values?.nome_sub_familia,
					});
					updateSubFamily();
				} else {
					createSubFamily(values);
				}
			}

			if (type === 'productModel') {
				if (productModelId) {
					setPayload(values);
					updateProductModel();
				} else {
					createProductModel(values);
				}
			}

			onClose();
		},
		[
			createFamily,
			createSubFamily,
			createProductModel,
			familyId,
			onClose,
			productModelId,
			subFamilyId,
			type,
			updateFamily,
			updateSubFamily,
			updateProductModel,
		],
	);

	useEffect(() => {
		setFamilyId(autocompleteAdditionalData?.family?.id || null);
		setSubFamilyId(autocompleteAdditionalData?.subFamily?.id || null);
		setProductModelId(autocompleteAdditionalData?.productModel?.id || null);

		switch (type) {
			case 'family':
				setFormInitialState({
					nome_familia:
						autocompleteAdditionalData?.family?.nome_familia || '',
				});
				break;
			case 'subFamily':
				setFormInitialState({
					familia_id: autocompleteAdditionalData?.family?.id || null,
					nome_sub_familia:
						autocompleteAdditionalData?.subFamily
							?.nome_sub_familia || '',
				});
				break;
			case 'productModel':
				setFormInitialState({
					familia_id: autocompleteAdditionalData?.family?.id || null,
					sub_familia_id:
						autocompleteAdditionalData?.subFamily?.id || null,
					nome_modelo:
						autocompleteAdditionalData?.productModel?.nome_modelo ||
						null,
				});
				break;
		}
	}, [
		autocompleteAdditionalData?.family?.id,
		autocompleteAdditionalData?.family?.nome_familia,
		autocompleteAdditionalData?.productModel?.id,
		autocompleteAdditionalData?.productModel?.nome_modelo,
		autocompleteAdditionalData?.subFamily?.id,
		autocompleteAdditionalData?.subFamily?.nome_sub_familia,
		type,
	]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader>
					<Heading
						color='gray.700'
						mb={2}
						size='md'
					>
						{getModalTitle()}
					</Heading>
				</ModalHeader>

				<ModalBody>
					<GenericForm
						dataTestProps={{
							cancel: 'button-cancelar-modal-familia',
							save: 'button-salvar-modal-familia',
						}}
						initialState={formInitialState}
						formFlexProps={formFlexProps}
						rows={renderFormRows()}
						style={defaultFormStyle}
						onSubmit={onSubmit}
						onCancel={onClose}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ModalCadastrarEditarFamilia;
