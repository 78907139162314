import { Checkbox, Flex, useDisclosure } from '@chakra-ui/react';
import { CollaborationConfigModal } from 'components/modal/CollaborationConfigModal';
import { TooltipHelper } from 'components/tooltip/TooltipHelper';
import { Filter, KeyFilter } from '../../context';

interface CheckboxGrouping {
	'data-test': string;
	name: string;
	tooltip: string;
}
const configBoxGrouping: Partial<Record<KeyFilter, CheckboxGrouping>> = {
	canal_venda: {
		'data-test': 'checkbox-agrupar_todos_os_canais-page-colaboracao',
		name: 'Agrupar todos os canais',
		tooltip:
			'O agrupamento de todos os canais de venda permite realizar a colaboração por família de produto sem entrar no nível de canal.',
	},
	zona_venda: {
		'data-test': 'checkbox-agrupar_todas_as_zonas-page-colaboracao',
		name: 'Agrupar todas as zonas',
		tooltip:
			'O agrupamento de todas as zonas de venda permite realizar a colaboração por família de produto e canal de venda sem entrar no nível de zona.',
	},
};
interface CheckBoxGroupingProps {
	filter: Filter;
}
const CheckBoxGrouping = ({ filter }: CheckBoxGroupingProps): JSX.Element => {
	const config = configBoxGrouping[filter.key];
	const { isOpen, onClose, onOpen } = useDisclosure();

	if (config) {
		return (
			<Flex
				alignItems='center'
				justifyContent='space-between'
				mt={2}
			>
				<Checkbox
					data-test={config['data-test']}
					size='sm'
					fontWeight={500}
					onChange={() => onOpen()}
					isChecked={filter?.grouping}
					isDisabled={filter?.disableGrouping}
				>
					{config.name}
				</Checkbox>
				{TooltipHelper(config.tooltip)}
				<CollaborationConfigModal
					isOpen={isOpen}
					onClose={onClose}
					typeOfModal={filter?.key}
					isCheckboxChecked={!!filter?.grouping}
				/>
			</Flex>
		);
	}
	return <></>;
};
export default CheckBoxGrouping;
