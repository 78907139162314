import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataCarteiraForm = [
	{
		Header: 'Carteira de Pedido é Ruim?',
		accessor: 'ruim',
		type: 'toggle',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Competência',
		accessor: 'competencia',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cliente',
		accessor: 'cliente',
		type: 'autocomplete',
		createable: true,
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Familia',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigoProduto',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
];

export const columnsDataCarteira = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Cód.',
		accessor: 'codigoCarteiraPedido',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Código da Carteira de Pedido',
	},
	{
		Header: 'Seq.',
		accessor: 'sequenciaCodigoCarteiraPedido',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Sequência do Código da Carteira de Pedido',
	},
	{
		Header: 'Competência',
		accessor: 'competencia',
		type: 'date',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cliente',
		accessor: 'cliente',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'CNPJ/CPF/ID Estrangeiro',
		accessor: 'cnpjCpfId',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Familia',
		accessor: 'familia',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigoProduto',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Ruim',
		accessor: 'ruim',
		filter: false,
		type: 'toggle-actionable',
		helper: true,
		helperText:
			'Uma carteira boa de pedidos consiste em pedidos que serão faturados no mês. Enquanto isso, uma carteira ruim de pedidos envolve pedidos que possuem falta de crédito ou indisponibilidade de MP.',
	},
	{
		Header: 'Faturado',
		accessor: 'faturado',
		filter: false,
		type: 'toggleAndEditModal',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		filter: false,
		jsx: RowEditAndDelete,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'PRODUTO',
		accessor: 'produto',
	},
	{
		Header: 'FAMÍLIA',
		accessor: 'familia',
	},
	{
		Header: 'SUBFAMÍLIA',
		accessor: 'subfamilia',
	},
	{
		Header: 'MODELO',
		accessor: 'modelo',
	},
	{
		Header: 'COD.PRODUTO',
		accessor: 'codproduto',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const columnsDataPortfolio = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'Razão Social',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
	{
		Header: 'CNPJ/CPF',
		accessor: 'cnpjCpf',
		type: 'text',
		body: 'cnpj',
	},
];
