import { Box } from "@chakra-ui/react";

// Custom Chakra theme
export default function Register(props) {
  const { children } = props;
  return (
    <>
      {children}
    </>
  );
}
