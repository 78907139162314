// Chakra imports
import {
  Box, Divider, Flex, Icon, SimpleGrid, Text, useColorModeValue
} from "@chakra-ui/react";
import { IconCheck, IconX } from "@tabler/icons";
// Custom components

// Assets

export default function Tasks(props) {
  const { dataPermissions, ...rest } = props;

  const permissionsComponent = dataPermissions?.map((item) => {
    return (
      <>
        <SimpleGrid columns={{ base: 2, md: 2 }} spacing={72} h={"60px"} alignContent={"center"}>
          <Text
            fontWeight='500'
            color={"easyBLUE.300"}
            fontSize='md'
            textAlign='start'
          >
            {item.descricao}
          </Text>
          <Flex>
            <Flex
              mr={5}
            >

              {/* <Checkbox defaultChecked me='16px' /> */}
              <Icon
                as={item.visualizar ? IconCheck : IconX}
                color={item.visualizar ? "green.300" : "red.300"}
                boxSize={6}
              />
              <Text
                fontWeight='500'
                color={"easyBLUE.300"}
                fontSize='md'
                textAlign='start'
              >
                Visualizar
              </Text>
            </Flex>
            <Flex>
              {/* <Checkbox ml={12} defaultChecked me='16px' /> */}
              <Icon
                as={item.editar ? IconCheck : IconX}
                color={item.editar ? "green.300" : "red.300"}
                boxSize={6}
              />
              <Text
                fontWeight='500'
                color={"easyBLUE.300"}
                fontSize='md'
                textAlign='start'
              >
                Editar
              </Text>
            </Flex>
          </Flex>
        </SimpleGrid>
        <Divider />
      </>
    )
  })

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  return (
    <Box 
    overflow="auto"
    maxHeight={"sm"}
    {...rest}
    >
      <SimpleGrid
        columns={{ base: 2, md: 2 }}
        spacing={72}
        h={"40px"}
        alignContent={"center"}
      >
        <Text color={textColor} fontSize='sm' fontWeight='500'>
          FUNÇÃO
        </Text>
        <Text color={textColor} fontSize='sm' fontWeight='500'>
          {/* VISUALIZAR | EDITAR */}
          PERMISSÕES
        </Text>
      </SimpleGrid>
      <Divider />
      {dataPermissions && permissionsComponent}
    </Box>
  );
}
