import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getCollaborationConsensusHistory } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export interface UseCollaborationConsensuHistory {
	page: string;
	cycleId: string;
	familyId: string;
	salesChannelId?: string;
	salesZoneId?: string;
	companyAreaId?: string | null;
	aplicarCicloAnterior?: boolean | string;
}
export const useCollaborationConsensuHistory = ({
	cycleId,
	familyId,
	page,
	aplicarCicloAnterior,
	companyAreaId,
	salesChannelId,
	salesZoneId,
}: UseCollaborationConsensuHistory) => {
	const toast = useToast();

	return useQuery(
		[
			'collaboration-consensu-history',
			page,
			salesChannelId || '',
			familyId,
			cycleId,
			companyAreaId || '',
			String(aplicarCicloAnterior),
			salesZoneId || '',
		],
		getCollaborationConsensusHistory,
		{
			refetchOnWindowFocus: false,
			onError: (error: any) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao buscar os dados históricos!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
		},
	);
};
