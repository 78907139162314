import { useQuery } from '@tanstack/react-query';
import { getResourceById } from 'services/api/requests/resources';

export const useGetResourceWithId = (id: Number) => {
	return useQuery(['resourceWithId', id], getResourceById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
