import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useDisclosure,
} from '@chakra-ui/react';
import { IconMessage } from '@tabler/icons';

interface PropsModalAtaArea {
	atas: Array<{
		anotacao: string;
		nome_canal_venda?: string;
		nome_zona_venda?: string;
		id: number;
	}>;
}

export default function ModalAtaArea(props: PropsModalAtaArea) {
	const { atas } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<div>
			<Flex>
				<Button onClick={onOpen}>
					<Icon
						color='secondaryGray.500'
						as={IconMessage}
						w='20px'
						h='20px'
					/>
				</Button>
			</Flex>

			<Modal
				size={'xl'}
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader
						fontSize={'24px'}
						color={'easyBLUE.300'}
					>
						Ata de Consenso
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody>
						<Text
							mb='10px'
							color={'black'}
							fontSize={'14px'}
							fontWeight={'500'}
						>
							Comentário
						</Text>
						<Textarea
							value={atas?.reduce?.((text, ata) => {
								const title = `${ata?.nome_canal_venda || ''}${
									ata?.nome_zona_venda
										? ` - ${ata?.nome_zona_venda}`
										: ''
								}`;
								const comment = `${title ? title + ': ' : ''}${
									ata?.anotacao
								}\n\n`;
								return text + comment;
							}, '')}
							rows={10}
							readOnly={true}
							placeholder='Escreva aqui suas observações sobre este consenso'
						></Textarea>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	);
}
