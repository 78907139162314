import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarForecast from 'components/forms/FormCadastrarEditarForecast';
import moment from 'moment';

export default function CadastrarForecast() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastrarForecast = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Forecast',
			firstParagraph: `Para cadastrar um novo forecast, selecione a Competência desejada e entre com os dados do Canal de Venda e Cliente.`,
			secondParagraph: `Para cadastrar um forecast, cadastre os canais de venda previamente em Cadastros > Comercial > Hierarquia.`,
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarForecast);
	}, [helpCadastrarForecast, setDataSidebar]);

	const formInitialState = {
		data: moment().format('YYYY-MM-DD'),
		cliente_id: null,
		canal_venda_id: null,
		receita_bruta: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarForecast initialState={formInitialState} />
			</Card>
		</Flex>
	);
}
