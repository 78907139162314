import { useQuery } from "@tanstack/react-query";
import { getProjectionByDate } from "services/api/requests/projection";

export const useGetProjectionByDate = (mes: number, ano: number) => {
    return useQuery(["projectionByDate", mes, ano], getProjectionByDate, {
        onError: (error) => {
            console.log(error)
        },
        refetchOnWindowFocus: false,
    })
}
