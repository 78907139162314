import { useEffect, useState } from 'react';
import {
	Box,
	ButtonGroup,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { IconRefreshAlert } from '@tabler/icons';
import { useGetApportionInconsistencies } from 'services/queryClient/wrapperHooks/apportionment/useGetApportionInconsistencies';
import { useUpdateApportionInconsistencies } from 'services/queryClient/wrapperHooks/apportionment/useUpdateApportionInconsistencies';
interface Cycle {
	ano: number;
	ciclo: number;
	id: number;
}
interface RateioInconsistencyUpdateModalProps {
	cycles?: Cycle[];
	cycleId?: number | string;
	onSuccessCallback?: any;
}

const RateioInconsistencyUpdateModal = (
	props: RateioInconsistencyUpdateModalProps,
) => {
	const { cycleId, cycles, onSuccessCallback = () => {} } = props;
	const [cycle, setCycle] = useState<Cycle | undefined>();
	const { data: apportionInconsistencies, refetch } =
		useGetApportionInconsistencies(cycle?.id);

	const { mutateAsync } = useUpdateApportionInconsistencies(cycle?.id);
	const { isOpen, onClose, onOpen } = useDisclosure();
	useEffect(() => {
		if (cycleId && cycles) {
			const ciclo = cycles.find((c) => c.id === cycleId);
			setCycle(ciclo);
		}
	}, [cycleId, cycles]);
	useEffect(() => {
		if (
			apportionInconsistencies?.quantityProductsChangedFamilies ||
			apportionInconsistencies?.quantityInactivatedProducts
		) {
			onOpen();
		} else {
			onClose();
		}
	}, [apportionInconsistencies]);
	useEffect(() => {
		const yearCurrente = new Date().getFullYear();
		const cicloCurrente = new Date().getMonth() + 1;
		if (
			cycle &&
			cycle.ano === yearCurrente &&
			cycle.ciclo === cicloCurrente
		) {
			refetch();
		} else {
			onClose();
		}
	}, [cycle]);

	const handleOnConfirm = async () => {
		await mutateAsync(cycleId as number);
		onSuccessCallback();
		onClose();
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size='2xl'
				isCentered
			>
				<ModalOverlay />
				<ModalContent borderRadius='3xl'>
					<ModalHeader>
						<Icon
							as={IconRefreshAlert}
							color='easyRED.300'
							boxSize={14}
						/>
						<Text
							fontWeight={700}
							color='easyBLUE.300'
							fontSize='2xl'
						>
							Rateio Alterado
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box mb={6}>
							<Text
								color='secondaryGray.500'
								fontWeight={400}
								fontSize='md'
							>
								{!!apportionInconsistencies?.quantityInactivatedProducts &&
									!!apportionInconsistencies?.quantityProductsChangedFamilies &&
									'Notamos que um ou mais produtos tiveram suas famílias alteradas ou foram removidos do rateio. '}
								{!!apportionInconsistencies?.quantityInactivatedProducts &&
									!apportionInconsistencies?.quantityProductsChangedFamilies &&
									'Notamos que um ou mais produtos foram removidos do rateios. '}
								{!apportionInconsistencies?.quantityInactivatedProducts &&
									!!apportionInconsistencies?.quantityProductsChangedFamilies &&
									'Notamos que um ou mais produtos tiveram suas famílias alteradas. '}
								<br />
								Deseja atualizar o rateio e reprocessar o plano
								irrestrito do ciclo atual?
								<br />
								<br />
								{!!apportionInconsistencies?.quantityInactivatedProducts &&
									!!apportionInconsistencies?.quantityProductsChangedFamilies &&
									'Caso seja confirmado, os valores de rateio dos produtos com famílias alteradas serão transferidos para a nova família. Os valores dos produtos que foram removidos do rateio terão seus valores removidos do plano irrestrito.'}
								{!!apportionInconsistencies?.quantityInactivatedProducts &&
									!apportionInconsistencies?.quantityProductsChangedFamilies &&
									'Caso seja confirmado, os valores de rateio e do plano irrestrito desses produtos serão removidos.	'}
								{!apportionInconsistencies?.quantityInactivatedProducts &&
									!!apportionInconsistencies?.quantityProductsChangedFamilies &&
									'Caso seja confirmado, os valores de rateio desses produtos serão transferidos para a nova família.	'}
								<br />
								<br />
								Os valores do plano restrito precisaram ser
								reprocessados, caso seja desejado que as
								alterações sejam refletidas.
							</Text>
						</Box>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<ButtonComponent
								data-test='button-cancelar-modal-rateio'
								type='ghost'
								title='Cancelar'
								onClick={onClose}
							/>
							<ButtonComponent
								data-test='button-confirmar-modal-rateio'
								type='secondary'
								title='Confirmar'
								onClick={handleOnConfirm}
							/>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default RateioInconsistencyUpdateModal;
