import { useQuery } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import {
	getStatisticMutate,
	getStatistic,
} from 'services/api/requests/colabAndConsensus/collaborationConsensus';
export interface IUseIStatistic {
	familyId: string;
	productId?: string;
	cycleId: string;
	typeStatistic?: string;
	andStatisticInterval?: Record<string, string | number> | string;
	salesChannelId?: string;
	salesZoneId?: string;
	clientId?: string;
}
export const useStatisticMutation = () => {
	return useMutation(getStatisticMutate);
};

export const useStatistic = ({
	cycleId,
	familyId,
	productId,
	typeStatistic,
	salesChannelId,
	salesZoneId,
	clientId,
	andStatisticInterval = {},
}: IUseIStatistic) => {
	return useQuery(
		[
			'statistic',
			familyId || '',
			productId || '',
			cycleId || '',
			typeStatistic || '',
			salesChannelId || '',
			salesZoneId || '',
			clientId || '',
			JSON.stringify(andStatisticInterval) || '',
		],
		getStatistic,
		{
			refetchOnWindowFocus: false,
			retry: false,
		},
	);
};
