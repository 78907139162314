import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarOportunidade from 'components/forms/FormCadastrarEditarOportunidade';
import moment from 'moment';

export default function CadastrarOportunidades() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastroOportunidades = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Oportunidades',
			firstParagraph:
				'Para cadastrar uma nova oportunidade, selecione a Competência desejada, o Cliente, Família e Produto, Quantidade e Membro responsável pela oportunidade.',
			secondParagraph:
				'Cadastre previamente os clientes através da importação de vendas em Cadastros > Comercial > Vendas; os membros em Cadastros > Comercial > Membros; e os produtos em Cadastros > Produto.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroOportunidades);
	}, [helpCadastroOportunidades, setDataSidebar]);

	const formInitialState = {
		data: moment().format('YYYY-MM-DD'),
		ciclo_id: null,
		cliente_id: null,
		familia_id: null,
		produto_id: null,
		quantidade: null,
		receita_bruta: null,
		canal_venda_id: null,
		membro_id: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarOportunidade
					initialState={formInitialState}
				/>
			</Card>
		</Flex>
	);
}
