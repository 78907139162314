import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { recalculateRestrictPlan } from 'services/api/requests/restrictedPlan';

export const useRecalculateRestrictPlan = (onUpdateData?) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(recalculateRestrictPlan, {
		onSuccess: () => {
			queryClient.invalidateQueries(['restrictedPlan-listById']);

			queryClient.invalidateQueries({
				predicate: (query: any) =>
					query.queryKey[0].includes('resctricted-plan'),
			});

			onUpdateData?.();

			toast({
				title: 'Plano restrito recalculado com sucesso!',
				description:
					'O plano restrito foi retornado ao padrão mais recente do plano irrestrito deste ciclo!',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			toast({
				title: 'Erro ao recalcular',
				description: error.response.data.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
