import { useMutation } from '@tanstack/react-query';
import { IAutocomplete } from '../../../utils/forms/autocomplete';
import { getResourceGroupsByFiltersAndLimit } from '../../api/requests/resource-group';

export const useResourceGroupListAsMutate = ({
	busca,
	limit,
	orderBy,
	sort,
}: IAutocomplete) => {
	const _order = orderBy || 'nome_grupo_recurso';
	const _sort = sort || 'ASC';
	return useMutation(() =>
		getResourceGroupsByFiltersAndLimit(busca, limit, _order, _sort),
	);
};
