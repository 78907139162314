import SidebarHelp from '../sidebar/sidebarHelp/SidebarHelp';
//Horizon UI
import {
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Link,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

//Tabler Icons

//Horizon UI Drawer component
export function DrawerHelp(props) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();
	const setSidebarHelpState = props.setSidebarHelpState;
	const abrir = () => {
		setSidebarHelpState('opened');
		onOpen();
	};

	const fechar = () => {
		setTimeout(() => {
			setSidebarHelpState('closed');
		}, 100);

		onClose();
	};

	return (
		<>
			<Link
				ref={btnRef}
				me='40px'
				color='easyBLUE.200'
				_hover={{ color: 'easyBLUE.400' }}
				onClick={abrir}
			>
				Precisa de ajuda?
			</Link>

			<Drawer
				isOpen={isOpen}
				placement='right'
				onClose={fechar}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent
					backgroundColor={'transparent'}
					boxShadow={'none'}
				>
					<SidebarHelp
						display='block'
						onClose={fechar}
					/>
				</DrawerContent>
			</Drawer>
		</>
	);
}
