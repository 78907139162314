export const forcaVendaDataDelete = {
	provider: 'salesForce',
	toastSuccessTitle: 'Excluído com sucesso!',
	toastSuccessDescription: 'A força de venda foi excluída com sucesso.',
	toastErrorTitle: 'Erro ao excluir força de venda!',
	title: 'Deseja realmente excluir esta força de venda?',
	text: [
		'Você está prestes a excluir esta força de venda da sua base de cadastros. Todas as hierarquias comerciais atreladas a força de venda serão desvinculadas.',
		'',
		'Você tem certeza que deseja excluir?',
	],
	invalidate: 'forcaVenda-list',
};

export const forcaVendaDeleteMessage = [
	'Você está prestes a excluir esta(s) força(s) de venda(s) da sua base de cadastros. Todas as hierarquias comerciais atreladas a(s) força(s) de venda(s) serão desvinculadas.',
	'',
];

export const forcaVendaDataDeleteAll = {
	provider: 'salesForce',
	toastSuccessTitle: 'Registros excluídos com sucesso!',
	toastSuccessDescription: 'As forças de vendas foram excluídas com sucesso',
	toastErrorTitle: 'Erro ao excluir forças de vendas!',
	invalidate: 'forcaVenda-list',
};
