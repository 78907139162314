import { useQuery } from '@tanstack/react-query';
import { getUserById } from 'services/api/requests/authentication';

export const useGetUsersById = (id: number, setSelectedUserTypes: any) => {
	return useQuery(['userById', id], getUserById, {
		onSuccess: (data) => {
			setSelectedUserTypes(
				data.perfil?.map((type) => {
					return {
						value: type.grupo_usuarios?.id,
						label: type.grupo_usuarios?.descricao,
					};
				}),
			);
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
