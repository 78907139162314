// Chakra imports
import { Flex, Box, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import { getLineChartOptionsSalesCadence } from './../variables/charts';

// Assets
import LineChart from 'components/chart/LineChart';
import { useEffect, useState } from 'react';

export interface SalesCadenceProps {
	[key: string]: any;
	dataSalesDashboard: any;
}
export interface LineChartDataSalesCadence {
	name: string;
	data: string[];
}

export interface CadenciaVendasEstatisticaDto {
	cadencia_venda_dia: number;
	percentualVendaMesAtual: number;
	percentualVendaMesAnterior: number;
	percentualVendaAnoAnterior: number;
	dia: string;
}

export default function SalesCadence(props: SalesCadenceProps) {
	const { dataSalesDashboard, ...rest } = props;
	// Chakra Color Mode
	const [lineChartDataSalesCadence, setLineChartDataSalesCadence] = useState<
		LineChartDataSalesCadence[]
	>([]);
	const [lineChartOptionsSalesCadence, setLineChartOptionsSalesCadence] =
		useState(getLineChartOptionsSalesCadence([]));

	function convertNullToString(value) {
		return value !== null ? value.toString() : '0';
	}
	useEffect(() => {
		if (dataSalesDashboard) {
			const cadence: LineChartDataSalesCadence = {
				name: 'Cadência',
				data: [],
			};
			const currentMonth: LineChartDataSalesCadence = {
				name: 'Mês atual',
				data: [],
			};
			const previouesYear: LineChartDataSalesCadence = {
				name: 'Ano anterior',
				data: [],
			};
			const previouesMonth: LineChartDataSalesCadence = {
				name: 'Mês anterior',
				data: [],
			};

			const categories: string[] = [];
			dataSalesDashboard?.forEach(
				(
					cadenciaVenda: CadenciaVendasEstatisticaDto,
					index: number,
				) => {
					cadence.data.push(
						cadenciaVenda.cadencia_venda_dia.toFixed(2).toString(),
					);
					currentMonth.data.push(
						convertNullToString(
							cadenciaVenda.percentualVendaMesAtual,
						),
					);
					previouesYear.data.push(
						convertNullToString(
							cadenciaVenda.percentualVendaAnoAnterior,
						),
					);
					previouesMonth.data.push(
						convertNullToString(
							cadenciaVenda.percentualVendaMesAnterior,
						),
					);
					categories.push(`${index + 1} `);
				},
			);
			setLineChartDataSalesCadence([
				currentMonth,
				cadence,
				previouesYear,
				previouesMonth,
			]);
			const lineChartOptionsSalesCadence =
				getLineChartOptionsSalesCadence(categories);
			setLineChartOptionsSalesCadence(lineChartOptionsSalesCadence);
		}
	}, [dataSalesDashboard]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Card
			justifyContent='center'
			align='center'
			direction='column'
			w='100%'
			mb={{ base: '20px', lg: '0px' }}
			{...rest}
		>
			<Flex
				justify='space-between'
				px='20px'
				pt='5px'
			>
				<Flex align='center'>
					<Flex
						flexDirection='column'
						me='20px'
					>
						<Text
							color={textColor}
							fontSize='28px'
							fontWeight='700'
							lineHeight='100%'
						>
							Cadência de Vendas
						</Text>
					</Flex>
				</Flex>
			</Flex>
			<Box
				minH='260px'
				mt='auto'
			>
				<LineChart
					chartData={lineChartDataSalesCadence}
					chartOptions={lineChartOptionsSalesCadence}
				/>
			</Box>
		</Card>
	);
}
