import { RowEditAndDeleteModal } from 'components/icons/RowEditAndDeleteModal';
import { DeleteIconColumn } from './DeleteIcon';

export const columnsDataRoteiro = [
	{
		Header: 'Descrição da atividade',
		accessor: 'descricao_da_atividade',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Grupo de Recurso',
		accessor: 'grupo_de_recurso',
		filter: false,
		type: 'text',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDeleteModal,
		filter: false,
	},
];

export const columnsDataCreateRoteiro = [
	{
		Header: 'Código do produto',
		type: 'text',
		accessor: 'codigo_produto',
		filter: false,
	},
	{
		Header: 'Atividade',
		accessor: 'atividade',
		disableSortBy: true,
		filter: false,
		type: 'text',
	},
	{
		Header: 'Grupo de Recurso',
		accessor: 'nome_grupo_recurso',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Ordem do roteiro',
		type: 'text',
		accessor: 'ordem_roteiro',
		filter: false,
	},
	{
		Header: 'Quantidade',
		type: 'text',
		accessor: 'quantidade',
		// jsx: InputColumn,
		filter: false,
	},
	{
		Header: 'Tempo do Colaborador',
		type: 'text',
		accessor: 'tempo_colaborador',
		// jsx: InputColumn,
		filter: false,
	},
	{
		Header: 'Tempo de Máquina',
		type: 'text',
		accessor: 'tempo_maquina',
		// jsx: InputColumn,
		filter: false,
	},
	{
		Header: 'Observações',
		type: 'text',
		accessor: 'observacao',
		// jsx: InputColumn,
		filter: false,
	},
];
