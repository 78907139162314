import { Switch } from '@chakra-ui/react';
import { useEditProduct } from 'services/queryClient/wrapperHooks/useEditProduct';
interface ToggleActionableProps {
	dataTest?: string;
	id: string | number;
	checked: boolean;
	disabled: boolean;
}
export function ToggleActionable(props: ToggleActionableProps): JSX.Element {
	const { dataTest, id, checked, disabled } = props;

	const body = {
		participa_rateio: !checked,
	};

	const { mutate } = useEditProduct(body, id[0], 'toggle');

	const updateItem = () => {
		mutate();
	};

	return (
		<Switch
			data-test={dataTest}
			colorScheme='red'
			size='sm'
			isChecked={checked}
			onChange={updateItem}
			disabled={disabled}
		/>
	);
}
