import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { createAverageTicket } from 'services/api/requests/ticketMedio';

export const useCreateAverageTicket = (body) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => createAverageTicket(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['ticketMedio-list']);
			queryClient.invalidateQueries(['sales-chanel-avarege-ticket-list']);
			history.goBack();
			toast({
				title: 'Registrado com sucesso!',
				description: 'Preço Médio registrado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			toast({
				title: 'Erro ao registrar Preço Médio!',
				description: error.response.data[0].message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
