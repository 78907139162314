import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const Speedometer = (props) => {
    const [speedometerData, setSpeedometerData] = useState([])
    const [speedometerOptions, setSpeedometerOptions] = useState({})

    useEffect(() => {
        setSpeedometerData(props.speedometerData)
        setSpeedometerOptions(props.speedometerOptions)
    }, [props])

    return (
        <Chart
            options={speedometerOptions}
            series={speedometerData}
            type="radialBar"
            width="100%"
            height="350"
        />
    )
}

export default Speedometer;
