import {
	ButtonGroup,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import GenericTreeViewInfiniteScroll from 'components/treeViewInfinityScroll/GenericTreeViewInfiniteScroll';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	getApportionmentFamilyByCycle,
	getApportionmentProductByCycle,
	getApportionmentSalesChannelByCycle,
} from 'services/api/requests/apportionment';
import { useApplyConsensusApportionment } from 'services/queryClient/wrapperHooks/apportionment/useApplyConsensusApportionment';
import { useUpdateApportionmentByFamilyAndMonth } from 'services/queryClient/wrapperHooks/apportionment/useUpdateApportionmentByFamilyAndMonth';
import { numberToLocaleString } from 'utils/functions/number';
import { currencyToNumber } from 'utils/functions/numberToFormateString';
import {
	ajustColumnMonthsFromCicle,
	buildFooterFromCycle,
	buildFooterFromCycleAndStaticData,
	FAMILY_APPORTIONMENT_MAX_VALUE,
	filterDateInsideCycle,
	getColumnMonthChanges,
	getColumnWidthFromCycle,
	getEditedValuesFromCicle,
	getNewTotalColumnValue,
	getNewTotalGeneralValue,
	getTotalGeralOriginal,
	getTotalIndex,
	MAX_DECIMAL_DIGITS,
	renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan,
	resetForm,
	transformDataToFormList,
	updateTotalProductRow,
	valueOutsideTolerance,
} from 'utils/functions/treeview';

const RateioTreeviewComponent = (props) => {
	const {
		filters,
		cycle: currentCycle,
		recalculateCallback,
		setSharedTreeviewProps,
	} = props;

	const { ...formProps } = useForm({});

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		watch,
		setValue,
		getValues,
	} = formProps;

	const toast = useToast();

	const [hasApportionment, setHasApportionment] = useState(false);
	const [totalFamilyInvalid, setTotalFamilyInvalid] = useState(false);
	const [totalFamilyWithinTolerance, setTotalFamilyWithinTolerance] =
		useState(false);

	const [canShow, setCanShow] = useState(false);

	const [formValues, setFormValues] = useState({});

	const renderizedObjectIds = useRef<Map<string, Set<number | string>>>(
		new Map(),
	);

	const mapFieldArrayForm = useRef<Map<string, any>>(new Map());

	const apportionmentWithError = useRef<any>(new Set());

	const {
		isOpen: isOpenInvalidAlert,
		onClose: onCloseInvalidAlert,
		onOpen: onOpenInvalidAlert,
	} = useDisclosure();

	const {
		mutateAsync: ApportionmentUpdate,
		isLoading: isLoadingApportionmentUpdate,
	} = useUpdateApportionmentByFamilyAndMonth();

	const { mutate: mutateApplyConsensusApportioment } =
		useApplyConsensusApportionment();

	const columnWidth = useMemo(
		() => getColumnWidthFromCycle(currentCycle, true, false),
		[currentCycle],
	);

	const buildFooterForFamily = useCallback(
		({ data, formListName, append, remove, deeper }) => {
			/**
			 * Primeiro adiciona o total da família no formulário
			 */
			buildFooterFromCycle({
				data,
				levelListName: formListName,
				append,
				remove,
				getValues,
				keyArrayName: 'totais',
				keyDataId: 'familia_id',
				keyDataMonthName: 'mes_ano',
				keyDataValueName: 'total',
				keyTotalName: 'descricao',
				isAlertCheckOnFirstRender: true,
				alertComparationOperator: 'NOT_BETWEEN',
				cycle: currentCycle,
				isRenderPreviousDataMonth: true,
			});

			/**
			 * Retorna a linha renderiza do total por família
			 */
			return buildFooterFromCycleAndStaticData({
				formListName,
				getValues,
				formProps,
				errors,
				columnWidth,
				deeper,
				isRenderPreviousDataMonth: true,
				additionalSpace: 20,
				suffix: ' %',
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[columnWidth, currentCycle],
	);

	const renderFamilyRow = useCallback(
		({ row, index, formListName }) => {
			return renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan(
				{
					row,
					index,
					formListName,
					formProps,
					errors,
					columnWidth,
					isRenderPreviousDataMonth: true,
					canRenderTotalColumn: false,
					suffix: ' %',
					cycle: currentCycle,
					containerDescriptionPercentualSize: '17%',
				},
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[columnWidth],
	);

	const buildFooterForSalesChannel = useCallback(
		({ data, formListName, append, remove, deeper }) => {
			/**
			 * Adiciona o total do canal de vendas no formulário
			 */
			buildFooterFromCycle({
				data,
				levelListName: formListName,
				append,
				remove,
				getValues,
				keyArrayName: 'totais',
				keyDataId: 'canal_venda_id',
				keyDataMonthName: 'mes_ano',
				keyDataValueName: 'total',
				keyTotalName: 'descricao',
				isAlertCheckOnFirstRender: true,
				alertComparationValue: FAMILY_APPORTIONMENT_MAX_VALUE,
				cycle: currentCycle,
				isRenderPreviousDataMonth: true,
			});

			/**
			 * Retorna a linha renderiza do total por canal de vendas
			 */
			return buildFooterFromCycleAndStaticData({
				formListName,
				getValues,
				formProps,
				errors,
				columnWidth,
				deeper,
				isRenderPreviousDataMonth: true,
				additionalSpace: 20,
				suffix: ' %',
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[columnWidth, currentCycle],
	);

	const renderSalesChannelRow = useCallback(
		({ row, index, formListName }) => {
			const popoverAlertHeader = (
				<Text color='easyBLUE.300'>Atenção</Text>
			);

			const popoverAlertBody = (
				<Flex
					direction='column'
					gap={3}
				>
					<Text color='easyBLUE.300'>
						O total da <b>família</b> deve ser 100%!
					</Text>
				</Flex>
			);

			return renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan(
				{
					row,
					index,
					formListName,
					formProps,
					errors,
					columnWidth,
					isRenderPreviousDataMonth: true,
					canRenderTotalColumn: false,
					popoverAlertProps: {
						header: popoverAlertHeader,
						body: popoverAlertBody,
					},
					suffix: ' %',
					containerDescriptionPercentualSize: '90%',
					cycle: currentCycle,
				},
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[columnWidth, currentCycle],
	);

	const renderProductRow = useCallback(
		({ row, index, formListName }) => {
			const popoverAlertHeader = (
				<Text color='easyBLUE.300'>Atenção</Text>
			);

			const popoverAlertBody = (
				<Flex
					direction='column'
					gap={3}
				>
					<Text color='easyBLUE.300'>
						Verifique se o total do <b>canal de vendas</b> é menor
						ou igual a 100%!
					</Text>
					<Text color='easyBLUE.300'>
						Verifique se o total da <b>família</b> é igual a 100%!
					</Text>
				</Flex>
			);

			return renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan(
				{
					row,
					index,
					formListName,
					formProps,
					errors,
					columnWidth,
					isRenderPreviousDataMonth: true,
					canRenderTotalColumn: false,
					popoverAlertProps: {
						header: popoverAlertHeader,
						body: popoverAlertBody,
					},
					suffix: ' %',
				},
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[columnWidth],
	);

	const adjustTotalToCycleSize = useCallback(
		({ arrayData, keyColumnValue }): any[] => {
			return ajustColumnMonthsFromCicle({
				arrayData,
				keyColumnValue,
				cycleStartDate: currentCycle?.data_inicio,
				cycleLength: currentCycle?.duracao,
				isRenderPreviousDataMonth: false,
			});
		},
		[currentCycle?.data_inicio, currentCycle?.duracao],
	);

	const getSharedDataChildren = useCallback(
		({ data, parentId }) => {
			if (!data || !parentId) return {};

			const parent = data?.pages
				?.reduce((acc, el) => {
					return [...acc, ...(el?.rows || [])];
				}, [])
				?.find((el) => el?.id === parentId);

			if (!parent) return {};

			let descricao = 'Total família';

			if (parent?.nome_familia) descricao = 'Total da família';
			else if (parent?.nome_canal_venda) descricao = 'Total do canal';

			const totais = adjustTotalToCycleSize({
				arrayData: parent?.totais,
				keyColumnValue: 'total',
			});

			return {
				props: {
					id: parentId,
					totais: totais,
					total: totais?.reduce(
						(acc, el) => (acc += Number(el) || 0),
						0,
					),
					descricao: descricao || 'TOTAL',
				},
			};
		},
		[adjustTotalToCycleSize],
	);

	const buildExtraData = useCallback(
		({ el, keyArrayName, keyDataMonthName }) => {
			return el[keyArrayName]
				?.sort((a, b) => {
					const mesA = moment(a[keyDataMonthName]).utc();
					const mesB = moment(b[keyDataMonthName]).utc();

					return mesA?.isBefore?.(mesB) ? -1 : 0;
				})
				?.reduce((acc, r) => {
					acc[
						`${el?.id}_${
							moment(r[keyDataMonthName]).utc().month() + 1
						}`
					] = r?.rateio_id || null;

					return acc;
				}, {});
		},
		[],
	);

	const buildAlerts = useCallback(
		({ checkFamilyLevel, elementId, totais }) => {
			if (checkFamilyLevel) {
				return (
					totais?.reduce?.((acc, el) => {
						if (
							valueOutsideTolerance({
								value: Number(el?.total),
							})
						) {
							const month = moment(el?.mes_ano).utc().month() + 1;
							acc[`${elementId}_${month}_TOTAL`] = true;
						}

						return acc;
					}, {}) || {}
				);
			} else {
				return (
					totais?.reduce?.((acc, el) => {
						if (
							Number(el?.total) > FAMILY_APPORTIONMENT_MAX_VALUE
						) {
							const month = moment(el?.mes_ano).utc().month() + 1;
							acc[`${elementId}_${month}_TOTAL`] = true;
						}

						return acc;
					}, {}) || {}
				);
			}
		},
		[],
	);

	const treeview = useMemo(
		() => ({
			deeper: 0,
			parentFilterParams: {
				ciclo_id: currentCycle?.id,
				familia_id: filters?.family || undefined,
				produto_id: filters?.product || undefined,
			},
			isFormList: true,
			row: {
				filterKeyName: 'familia_id',
				renderRow: renderFamilyRow,
				infinityQueriesFunctions: [
					{
						getData: (params) => {
							return getApportionmentFamilyByCycle({
								...params,
							});
						},
						appendData: ({ data }) => {
							const result = data?.pages?.reduce?.(
								(acc, page) => [...acc, ...(page?.rows || [])],
								[],
							);

							const hasInvalidFamily = data?.pages
								?.reduce?.(
									(acc, page) => [
										...acc,
										page?.existeFamiliaInvalida,
									],
									[],
								)
								?.some((el) => !!el);

							const hasFamilyInsideTolerance = data?.pages
								?.reduce?.(
									(acc, page) => [
										...acc,
										page?.existeFamiliaDentroTolerancia,
									],
									[],
								)
								?.some((el) => !!el);

							setHasApportionment(!!result?.length);
							setTotalFamilyInvalid(hasInvalidFamily);
							setTotalFamilyWithinTolerance(
								hasFamilyInsideTolerance,
							);

							return result;
						},
						queryKeyName: 'apportionment-family',
						getNextPage: (lastPage, allPages, limit) => {
							return lastPage?.rows?.length === limit
								? allPages?.length
								: undefined;
						},
					},
				],
			},
			children: [
				{
					deeper: 1,
					isFormList: true,
					hasFooter: true,
					row: {
						filterKeyName: 'canal_venda_id',
						renderRow: renderSalesChannelRow,
						infinityQueriesFunctions: [
							{
								getData: (params) => {
									return getApportionmentSalesChannelByCycle({
										...params,
									});
								},
								appendData: ({ data }) => {
									return data?.pages?.reduce?.(
										(acc, page) => [
											...acc,
											...(page?.rows || []),
										],
										[],
									);
								},
								queryKeyName:
									'apportionment-family-sales-channel',
								getNextPage: (lastPage, allPages, limit) => {
									return lastPage?.rows?.length === limit
										? allPages?.length
										: undefined;
								},
							},
						],
					},
					children: [
						{
							deeper: 2,
							isFormList: true,
							hasHeader: true,
							hasFooter: true,
							showScrollBar: true,
							row: {
								renderRow: renderProductRow,
								infinityQueriesFunctions: [
									{
										getData: (params) => {
											return getApportionmentProductByCycle(
												{
													...params,
												},
											);
										},
										appendData: ({ data }) => {
											return data?.pages?.reduce?.(
												(acc, page) => [
													...acc,
													...(page?.rows || []),
												],
												[],
											);
										},
										queryKeyName:
											'apportionment-family-sales-channel-product',
										getNextPage: (
											lastPage,
											allPages,
											limit,
										) => {
											return lastPage?.rows?.length ===
												limit
												? allPages?.length
												: undefined;
										},
									},
								],
							},
						},
					],
				},
			],
		}),
		[
			currentCycle?.id,
			renderProductRow,
			renderFamilyRow,
			renderSalesChannelRow,
			filters,
		],
	);

	const formParams = useMemo(() => {
		function getTotais(arrayData) {
			return adjustTotalToCycleSize({
				arrayData,
				keyColumnValue: 'total',
			})?.filter((el) =>
				filterDateInsideCycle({
					date: el?.mes_ano,
					cycle: currentCycle,
				}),
			);
		}

		return {
			formListName: 'apportionment',
			formProps,
			renderizedObjectIds: renderizedObjectIds,
			mapFieldArrayForm,
			manageData: [
				{
					deeper: 0,
					getSharedDataChildren,
					transformDataToFormList: ({
						data,
						append,
						formListName,
					}) => {
						data?.forEach((el) => {
							const alert = buildAlerts({
								checkFamilyLevel: true,
								elementId: el?.id,
								totais: getTotais(el?.totais),
							});

							if (Object.keys(alert || {})?.length)
								apportionmentWithError?.current?.add(
									`${formListName}_${el?.id}`,
								);

							append({
								_id: el?.id,
								familia_id: el?.id,
								descricao: el?.nome_familia,
								isTotalRow: false,
								hasHeader: true,
								alert,
							});
						});
					},
				},
				{
					deeper: 1,
					renderFooter: buildFooterForFamily,
					getSharedDataChildren,
					transformDataToFormList: ({
						data,
						append,
						formListName,
					}) => {
						data?.forEach((el) => {
							const alert = buildAlerts({
								checkFamilyLevel: false,
								elementId: el?.id,
								totais: getTotais(el?.totais),
							});

							if (Object.keys(alert || {})?.length)
								apportionmentWithError?.current?.add(
									`${formListName}_${el?.id}`,
								);

							append({
								_id: el?.id,
								canal_venda_id: el?.id,
								descricao: el?.nome_canal_venda,
								isTotalRow: false,
								alert,
							});
						});
					},
				},
				{
					deeper: 2,
					renderFooter: buildFooterForSalesChannel,
					transformDataToFormList: ({ data, append }) => {
						transformDataToFormList({
							data,
							append,
							keyArrayName: 'rateios',
							keyDataId: 'produto_id',
							keyDataMonthName: 'mes_ano',
							keyDataValueName: 'rateio',
							buildDescritption: (el) =>
								`${el?.codigo_produto} - ${el?.nome_produto}`,
							cycle: currentCycle,
							isRenderPreviousDataMonth: true,
							isRenderExtraData: true,
							buildExtraData,
						});
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		getSharedDataChildren,
		buildFooterForFamily,
		buildFooterForSalesChannel,
		buildAlerts,
		getValues,
		currentCycle,
		buildExtraData,
		adjustTotalToCycleSize,
	]);

	const clearForm = useCallback(() => {
		resetForm(getValues, setValue);
		renderizedObjectIds?.current?.clear();
	}, [getValues, setValue]);

	const clearRenderizedRows = useCallback(() => {
		/**
		 * Limpa dos ids renderizados todos os elementos cujo level seja maior que 0
		 * canal de vendas e produtos
		 */
		(renderizedObjectIds.current?.keys() as any)?.forEach((key) => {
			if (key === 'apportionment') return;

			renderizedObjectIds?.current?.get(key)?.clear();
		});

		/**
		 * Atualiza form limpando as listas inferiores para apagar os dados editados
		 */
		Object.keys(getValues() || {}).forEach((key) => {
			if (key !== 'apportionment') setValue(key, []);
		});

		/**
		 * Atualiza a linha da família (apenas para forçar o rerender da linha e remover os itens filhos)
		 */
		getValues()?.apportionment?.forEach((el, index) => {
			const { update } =
				mapFieldArrayForm?.current?.get('apportionment') || {};

			update?.(index, {
				...el,
			});
		});
	}, [getValues, setValue]);

	const hadleOnCancel = useCallback(() => {
		clearRenderizedRows();

		toast({
			title: 'Cancelado!',
			description:
				'As alterações foram canceladas e os dados foram restaurados.',
			status: 'info',
			position: 'bottom-left',
			duration: 8000,
			isClosable: true,
		});
	}, [clearRenderizedRows, toast]);

	const updateData = useCallback(
		async (values) => {
			if (isOpenInvalidAlert) onCloseInvalidAlert();

			const result = Object.keys(values || {})
				?.map((key) => {
					const [, familiaId, canalVendaId] = key.split('_');

					if (!familiaId || !canalVendaId) return null;

					return values[key]
						?.filter((el) => !el?.isTotalRow && el?.edited)
						?.map((el) => {
							const _values = getEditedValuesFromCicle({
								el,
								currentCycle,
								keyValueName: 'rateio',
							});

							if (el?.extraData) {
								/**
								 * Retorna os valores editados com os ids dos rateios
								 */
								return _values?.map((element) => {
									const _key =
										Object.keys(el?.extraData || {}).find(
											(k) => {
												const _month = `_${
													moment(element?.mes_ano)
														.utc()
														.month() + 1
												}`;

												return k.endsWith(_month);
											},
										) || '';

									if (key)
										return {
											...element,
											id: el?.extraData?.[_key] || null,
										};

									return element;
								});
							}

							return _values;
						})
						?.reduce((acc, el) => {
							if (!acc[`${familiaId}_${canalVendaId}`])
								acc[`${familiaId}_${canalVendaId}`] = {
									rateios: [],
								};

							el?.forEach((element) => {
								acc[
									`${familiaId}_${canalVendaId}`
								].rateios.push({
									...element,
									familia_id: Number(familiaId),
									canal_venda_id: Number(canalVendaId),
								});
							});

							return acc;
						}, {}) as any;
				})
				?.filter((el) => !!el)
				?.reduce((acc, el: any) => {
					const rateios =
						Object.keys(el)?.map((key) => el[key]?.rateios || []) ||
						[];

					rateios.forEach((r) => acc.push(...r));

					return acc;
				}, []);

			if (!result?.length) {
				toast({
					title: 'Nenhuma alteração!',
					description:
						'Nenhuma alteração foi realizada nos dados do rateio.',
					status: 'info',
					position: 'bottom-left',
					duration: 8000,
					isClosable: true,
				});

				return;
			}

			await ApportionmentUpdate(result);
			mutateApplyConsensusApportioment({ ciclo_id: currentCycle?.id });

			clearForm();
		},
		[
			isOpenInvalidAlert,
			onCloseInvalidAlert,
			ApportionmentUpdate,
			mutateApplyConsensusApportioment,
			currentCycle,
			clearForm,
			toast,
		],
	);

	const updateTreeviewAlerts = useCallback(
		({
			value,
			novoValorTotalColunaCanalVenda,
			rowKeyFamily,
			familia_canal_vendas,
			indexTotalCanalVenda,
			indexTotalFamilia,
			colunaMesAlterado,
			familiaId,
			canalVendaId,
		}) => {
			function setAlertsTotalRow({
				formListName,
				index,
				entityId,
				alertValue,
			}) {
				setValue(`${formListName}.${index}.alert`, {
					...value[formListName]?.[index]?.alert,
					[`${entityId}_${colunaMesAlterado}_TOTAL`]: alertValue,
				});
			}

			function setAlertsEntityRow({
				formListName,
				entityIdValue,
				entityIdName,
			}) {
				const index = value[formListName]?.findIndex(
					(el) => el?.[entityIdName] === entityIdValue,
				);

				if (index !== -1) {
					value[formListName]?.[index]?.forEach((el) => {
						setValue(`${formListName}.${index}.alert`, {
							...el?.alert,
							[`${entityIdValue}_TOTAL`]: true,
						});
					});
				}
			}

			function reRenderTotalRow({ formListName, index }) {
				if (index !== -1) {
					const { update } =
						mapFieldArrayForm?.current?.get(formListName) || {};

					update?.(index, {
						...value?.[formListName]?.[index],
					});
				}
			}

			/**
			 * Se o total da coluna por canal de vendas for maior que 100%
			 * deve incluir erro na linha do canal de vendas e na linha da família
			 * assim como na linha do total por canal de vendas e por família
			 */
			if (
				novoValorTotalColunaCanalVenda > FAMILY_APPORTIONMENT_MAX_VALUE
			) {
				apportionmentWithError?.current?.add(familia_canal_vendas);
				apportionmentWithError?.current?.add(rowKeyFamily);

				setAlertsTotalRow({
					formListName: familia_canal_vendas,
					index: indexTotalCanalVenda,
					entityId: canalVendaId,
					alertValue: true,
				});

				setAlertsTotalRow({
					formListName: rowKeyFamily,
					index: indexTotalFamilia,
					entityId: familiaId,
					alertValue: true,
				});

				setAlertsEntityRow({
					formListName: familia_canal_vendas,
					entityIdValue: canalVendaId,
					entityIdName: 'canal_venda_id',
				});

				setAlertsEntityRow({
					formListName: rowKeyFamily,
					entityIdValue: familiaId,
					entityIdName: 'familia_id',
				});
			} else {
				const currentTotalSalesChannelValues =
					value[familia_canal_vendas]?.[indexTotalCanalVenda];

				const isSalesChannelValid = Object.keys(
					currentTotalSalesChannelValues || {},
				)
					?.filter(
						(key) =>
							key.startsWith(
								currentTotalSalesChannelValues?.canal_venda_id,
							) &&
							key !==
								`${currentTotalSalesChannelValues?.canal_venda_id}_TOTAL`,
					)
					?.every(
						(key) =>
							currencyToNumber(
								currentTotalSalesChannelValues?.[key],
							) <= FAMILY_APPORTIONMENT_MAX_VALUE,
					);

				/**
				 * Caso não existam valores maiores que 100% na linha do canal de vendas
				 * deve-se remover o erro da linha do canal de vendas e do total do canal de vendas
				 */
				if (isSalesChannelValid) {
					apportionmentWithError?.current?.delete(
						familia_canal_vendas,
					);

					setValue(
						`${familia_canal_vendas}.${indexTotalCanalVenda}`,
						{
							...value[familia_canal_vendas]?.[
								indexTotalCanalVenda
							],
							alert: {},
						},
					);
				} else {
					/**
					 * Caso existam valores maiores que 100% na linha do canal de vendas atualiza apenas o alert
					 * da coluna que foi alterada. Altera o status para false pois o novo valor da coluna é <= 100%
					 */
					setAlertsTotalRow({
						formListName: familia_canal_vendas,
						index: indexTotalCanalVenda,
						entityId: canalVendaId,
						alertValue: false,
					});
				}
			}

			const currentTotalFamilyValues =
				value[rowKeyFamily]?.[indexTotalFamilia];

			const familyMonthKeys = Object.keys(
				currentTotalFamilyValues || {},
			)?.filter(
				(key) =>
					key.startsWith(currentTotalFamilyValues?.familia_id) &&
					key !== `${currentTotalFamilyValues?.familia_id}_TOTAL`,
			);

			const isFamilyValid = familyMonthKeys?.every(
				(key) =>
					!valueOutsideTolerance({
						value: currencyToNumber(
							currentTotalFamilyValues?.[key],
						),
					}),
			);

			/**
			 * Se não existem erros no canal de vendas e o total da família é 100%
			 * deve-se remover o erro da linha da família e do total da família
			 */
			if (isFamilyValid) {
				apportionmentWithError?.current?.delete(rowKeyFamily);

				setValue(`${rowKeyFamily}.${indexTotalFamilia}`, {
					...value[rowKeyFamily]?.[indexTotalFamilia],
					alert: {},
				});

				const rowFamilyIndex = value[rowKeyFamily]?.findIndex(
					(el) => el?.familia_id === familiaId,
				);

				if (rowFamilyIndex !== -1) {
					setValue(`${rowKeyFamily}.${rowFamilyIndex}.alert`, {});
				}
			} else {
				familyMonthKeys?.forEach((key) => {
					setValue(`${rowKeyFamily}.${indexTotalFamilia}.alert`, {
						...value[rowKeyFamily]?.[indexTotalFamilia]?.alert,
						[key]: valueOutsideTolerance({
							value: currencyToNumber(
								currentTotalFamilyValues?.[key],
							),
						}),
					});
				});
			}

			reRenderTotalRow({
				formListName: familia_canal_vendas,
				index: indexTotalCanalVenda,
			});

			reRenderTotalRow({
				formListName: rowKeyFamily,
				index: indexTotalFamilia,
			});
		},
		[setValue],
	);

	const calculateDiferrenceAfterChangeInput = useCallback(
		({ value, familia_canal_vendas, index, produtoId_mes }) => {
			const rowForm = value[familia_canal_vendas]?.[index];

			const valorProdutoMesAtualizado =
				typeof rowForm[produtoId_mes] === 'number'
					? rowForm[produtoId_mes]
					: currencyToNumber(rowForm[produtoId_mes] || 0);

			const valorProdutoMesOriginal = currencyToNumber(
				rowForm?.original[produtoId_mes],
			);

			const isAdd = valorProdutoMesAtualizado > valorProdutoMesOriginal;

			let diferencaValorMes = 0;

			if (isAdd) {
				diferencaValorMes =
					valorProdutoMesAtualizado - valorProdutoMesOriginal;
			} else {
				diferencaValorMes =
					(valorProdutoMesOriginal - valorProdutoMesAtualizado) * -1;
			}

			return diferencaValorMes;
		},
		[],
	);

	const updateTotalSalesChannelAndFamily = useCallback(
		(value, name) => {
			const [familia_canal_vendas, index, produtoId_mes] =
				name?.split?.('.') || '';

			if (!familia_canal_vendas || !index || !produtoId_mes) return;

			const diferencaValorMes = calculateDiferrenceAfterChangeInput({
				value,
				familia_canal_vendas,
				index,
				produtoId_mes,
			});

			const [, familiaId, canalVendaId] = familia_canal_vendas.split('_');

			const rowKeyFamily = `apportionment_${familiaId}`;

			const colunaMesAlterado = produtoId_mes.split('_')[1];

			/**
			 * Atualiza o total da coluna por canal de vendas
			 */
			const indexTotalCanalVenda = getTotalIndex({
				value,
				formListName: familia_canal_vendas,
			});

			setValue(
				`${familia_canal_vendas}.${indexTotalCanalVenda}.alteracao.${colunaMesAlterado}.${produtoId_mes}`,
				diferencaValorMes,
			);

			const totalGeralCanalVendaOriginalColuna = getTotalGeralOriginal({
				value,
				formListName: familia_canal_vendas,
				index: indexTotalCanalVenda,
				key: `${canalVendaId}_${colunaMesAlterado}_TOTAL`,
			});

			const diferencaValorAlteradoColunaCanalVenda =
				getColumnMonthChanges({
					value,
					formListName: familia_canal_vendas,
					index: indexTotalCanalVenda,
					keyMonthChanged: colunaMesAlterado,
				});

			const novoValorTotalColunaCanalVenda = getNewTotalColumnValue({
				columnMonthChanges: diferencaValorAlteradoColunaCanalVenda,
				keyChangedMonth: produtoId_mes,
				difference: diferencaValorMes,
				originalValue: totalGeralCanalVendaOriginalColuna,
			});

			setValue(
				`${familia_canal_vendas}.${indexTotalCanalVenda}.${canalVendaId}_${colunaMesAlterado}_TOTAL`,
				numberToLocaleString({
					value: novoValorTotalColunaCanalVenda,
					maximumFractionDigits: MAX_DECIMAL_DIGITS,
				}),
			);

			const novoValorTotalGeralCanalVenda = getNewTotalGeneralValue({
				value,
				formListName: familia_canal_vendas,
				index: indexTotalCanalVenda,
				entityId: canalVendaId,
			});

			setValue(
				`${familia_canal_vendas}.${indexTotalCanalVenda}.${canalVendaId}_TOTAL`,
				numberToLocaleString({
					value: novoValorTotalGeralCanalVenda,
					maximumFractionDigits: MAX_DECIMAL_DIGITS,
				}),
			);

			/**
			 * Atualiza o total da coluna por família
			 */

			const keyCanalVendaProdutoAlterado = `${canalVendaId}_${produtoId_mes}`;

			const indexTotalFamilia = getTotalIndex({
				value,
				formListName: rowKeyFamily,
			});

			setValue(
				`${rowKeyFamily}.${indexTotalFamilia}.alteracao.${colunaMesAlterado}.${keyCanalVendaProdutoAlterado}`,
				diferencaValorMes,
			);

			const totalGeralFamiliaOriginalColuna = getTotalGeralOriginal({
				value,
				formListName: rowKeyFamily,
				index: indexTotalFamilia,
				key: `${familiaId}_${colunaMesAlterado}_TOTAL`,
			});

			const diferencaValorAlteradoColunaFamilia = getColumnMonthChanges({
				value,
				formListName: rowKeyFamily,
				index: indexTotalFamilia,
				keyMonthChanged: colunaMesAlterado,
			});

			const novoValorTotalColunaFamilia = getNewTotalColumnValue({
				columnMonthChanges: diferencaValorAlteradoColunaFamilia,
				keyChangedMonth: keyCanalVendaProdutoAlterado,
				difference: diferencaValorMes,
				originalValue: totalGeralFamiliaOriginalColuna,
			});

			setValue(
				`${rowKeyFamily}.${indexTotalFamilia}.${familiaId}_${colunaMesAlterado}_TOTAL`,
				numberToLocaleString({
					value: novoValorTotalColunaFamilia,
					maximumFractionDigits: MAX_DECIMAL_DIGITS,
				}),
			);

			const novoValorTotalGeralFamilia = getNewTotalGeneralValue({
				value,
				formListName: rowKeyFamily,
				index: indexTotalFamilia,
				entityId: familiaId,
			});

			setValue(
				`${rowKeyFamily}.${indexTotalFamilia}.${familiaId}_TOTAL`,
				numberToLocaleString({
					value: novoValorTotalGeralFamilia,
					maximumFractionDigits: MAX_DECIMAL_DIGITS,
				}),
			);

			if (valueOutsideTolerance({ value: novoValorTotalColunaFamilia })) {
				apportionmentWithError?.current?.add(rowKeyFamily);
			} else {
				const rowTotalFamilyValues =
					value[rowKeyFamily]?.[indexTotalFamilia];

				const isFamilyValid = Object.keys(rowTotalFamilyValues || {})
					?.filter(
						(key) =>
							key.startsWith(rowTotalFamilyValues?.familia_id) &&
							key !== `${rowTotalFamilyValues?.familia_id}_TOTAL`,
					)
					?.every(
						(key) =>
							!valueOutsideTolerance({
								value: currencyToNumber(
									rowTotalFamilyValues?.[key],
								),
							}),
					);

				if (isFamilyValid) {
					apportionmentWithError?.current?.delete(rowKeyFamily);

					setValue(`${rowKeyFamily}.${indexTotalFamilia}.alert`, {});

					apportionmentWithError?.current
						?.keys()
						?.filter(
							(key) =>
								key.startsWith(rowKeyFamily) &&
								key !== rowKeyFamily,
						)
						?.forEach((key) => {
							apportionmentWithError.current.delete(key);

							value[key]?.forEach((_, index) => {
								setValue(`${key}.${index}.alert`, {});
							});
						});
				}
			}

			updateTreeviewAlerts({
				value,
				novoValorTotalColunaCanalVenda,
				rowKeyFamily,
				familia_canal_vendas,
				indexTotalCanalVenda,
				indexTotalFamilia,
				colunaMesAlterado,
				familiaId,
				canalVendaId,
			});
		},
		[calculateDiferrenceAfterChangeInput, setValue, updateTreeviewAlerts],
	);

	const markRowsAsEdited = useCallback(
		(name) => {
			const [formListName, index, produto] = name?.split?.('.') || '';

			if (!formListName || !index || !produto) return;

			setValue(`${formListName}.${index}.edited`, true);
		},
		[setValue],
	);

	function renderButtons() {
		return (
			<Flex
				justifyContent='end'
				mt='20px'
			>
				<ButtonGroup spacing='20px'>
					<ButtonComponent
						data-test='button-cancelar-page-rateio'
						type='ghost'
						title='Cancelar'
						isDisabled={
							isSubmitting || isLoadingApportionmentUpdate
						}
						onClick={hadleOnCancel}
					/>
					<ButtonComponent
						data-test='button-recalcular-page-rateio'
						type='secondary'
						title='Recalcular'
						onClick={recalculateCallback}
						isDisabled={
							isSubmitting || isLoadingApportionmentUpdate
						}
					/>
					{Object.keys(errors || {}).length ? (
						<TooltipStandard label='Existem campos que não foram preenchidos, corrigir!'>
							<span>
								<ButtonComponent
									data-test='button-salvar-page-rateio'
									type='primary'
									action='submit'
									title='Salvar'
									isDisabled={
										!!Object.keys(errors || {}).length ||
										isSubmitting ||
										isLoadingApportionmentUpdate
									}
								/>
							</span>
						</TooltipStandard>
					) : (
						<ButtonComponent
							data-test='button-salvar-page-rateio'
							type='primary'
							action='submit'
							title='Salvar'
							isDisabled={
								isSubmitting || isLoadingApportionmentUpdate
							}
						/>
					)}
				</ButtonGroup>
			</Flex>
		);
	}

	const modalInvalidApporionment = useCallback(() => {
		return (
			<Modal
				isOpen={isOpenInvalidAlert}
				onClose={onCloseInvalidAlert}
				onEsc={onCloseInvalidAlert}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						<Text
							color='easyRED.300'
							fontWeight='bold'
						>
							Atenção!
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color='easyBLUE.300'>
							Um ou mais famílias estão com a soma dos canais{' '}
							<b>superior</b> ou <b>inferior</b> à <b>100%</b>.
						</Text>
						<br />
						<Text color='easyBLUE.300'>Deseja continuar?</Text>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<ButtonComponent
								data-test='button-cancelar-editar_plano-modal-dashboard_capacidade'
								type='ghost'
								title='Cancelar'
								onClick={onCloseInvalidAlert}
							/>
							<ButtonComponent
								data-test='button-gerar_plano-editar_plano-modal-dashboard_capacidade'
								type='primary'
								title='Continuar'
								onClick={() => updateData(formValues)}
							/>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}, [formValues, isOpenInvalidAlert, onCloseInvalidAlert, updateData]);

	const onSubmit = useCallback(
		(values) => {
			setFormValues(values);

			if (apportionmentWithError?.current?.size) onOpenInvalidAlert();
			else updateData(values);
		},
		[onOpenInvalidAlert, updateData],
	);

	function hasApportionmentData() {
		if (!hasApportionment)
			return (
				<AlertComponent
					title='Nenhum rateio encontrado'
					description={
						'Selecione ano, ciclo e por fim clique no botão de Recalcular caso o rateio não tenha sido calculado anteriormente.'
					}
					status='warning'
					hasCloseButton
				/>
			);
		return null;
	}

	function hasFamilyWithoutProducts() {
		if (!hasApportionment && filters?.family)
			return (
				<AlertComponent
					title='Atenção'
					description={
						'A família selecionada não possui produtos para o rateio ou não há vendas registradas para os produtos dessa família no ciclo escolhido. Você pode editar os produtos que participam do rateio na tela de Produtos ou aumentar o histórico de vendas nas configurações da Easy360!'
					}
					status='warning'
					hasCloseButton
				/>
			);

		return null;
	}

	function hasFamilyWithInvalidTotal() {
		if (hasApportionment && totalFamilyInvalid)
			return (
				<AlertComponent
					title='Atenção'
					description={
						'Revise a distribuição do seu rateio, pois observamos algumas alterações. Isso pode ser resultado de uma possível modificação recente na hierarquia comercial ou de produtos.'
					}
					status='warning'
					hasCloseButton
				/>
			);

		return null;
	}

	function hasFamilyOutsideTolerance() {
		if (hasApportionment && totalFamilyWithinTolerance)
			return (
				<AlertComponent
					title='Margem de Tolerância!'
					description={
						'O rateio possui uma margem de 0,03 acima ou abaixo de 100%. Algumas famílias estão com o total do rateio dentro dessa margem. Embora seja aceitável, isso pode impactar a distribuição dos produtos. Por favor, verifique!'
					}
					status='warning'
					hasCloseButton
				/>
			);

		return null;
	}

	useEffect(() => {
		if (!canShow) setTimeout(() => setCanShow(true), 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (
				name?.includes('TOTAL') ||
				name?.includes('edited') ||
				name?.includes('alteracao') ||
				name?.includes('alert')
			)
				return;

			updateTotalProductRow({ value, name, setValue });

			updateTotalSalesChannelAndFamily(value, name);

			markRowsAsEdited(name);
		});
		return () => subscription.unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch]);

	useEffect(() => {
		if (!!getValues && !!setValue) {
			setSharedTreeviewProps({
				hasApportionment,
			});
		}
	}, [getValues, setSharedTreeviewProps, setValue, hasApportionment]);

	return canShow ? (
		<>
			{hasApportionmentData()}

			{hasFamilyWithoutProducts()}

			{hasFamilyWithInvalidTotal()}

			{hasFamilyOutsideTolerance()}

			<form onSubmit={handleSubmit(onSubmit)}>
				<GenericTreeViewInfiniteScroll
					treeview={treeview}
					formParams={formParams}
				/>
				{renderButtons()}
			</form>
			{modalInvalidApporionment()}
		</>
	) : null;
};

const RateioTreeview = memo(RateioTreeviewComponent);

export default RateioTreeview;
