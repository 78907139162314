// Chakra imports
import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	ButtonGroup,
} from '@chakra-ui/react';
import { columnsDataHierarquiaComercial } from './variables/columnsDataHierarquiaComercial';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { useGlobalContext } from 'contexts/GlobalContext';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { IconDotsVertical } from '@tabler/icons';
import CheckTableModal from 'components/modal/CheckTableModal';
import { buildCheckTableModalProps } from './functions/util';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import { useDownloadTemplateHierarchy } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateHierarchy';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import { useExportCommercialHierarchy } from 'services/queryClient/wrapperHooks/useExportCommercialHierarchy';
import { useUploadCommercialHierarchy } from 'services/queryClient/wrapperHooks/uploads/useUploadCommercialHierarchy';
import { formatStringoToDateAndTime } from 'utils/functions/dateUtils';

interface Hierarchy {
	id: number;
	nome_mercado: string;
	nome_canal_venda: string;
	nome_forca_venda: string;
	nome_zona_venda: string;
	nome_membro: string;
	cycleId: string | number;
	data_registro: string;
}

export default function CadastroMercados() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const {
		isOpen: isOpenModalList,
		onOpen: onOpenModalList,
		onClose: onCloseModalList,
	} = useDisclosure();

	const [selectedModuleProps, setSelectedModuleProps] = useState<any>({});

	const tableColumns = useMemo(() => columnsDataHierarquiaComercial, []);
	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpMarket: SidebarHelp = useMemo(
		() => ({
			title: 'Hierarquia',
			firstParagraph:
				'Nesta tela é possível visualizar a Hierarquia Comercial, refletindo a organização comercial do seu negócio, contendo todos os níveis de iteração. A hierarquia, do nível macro ao micro, é composta por Mercado > Canal de Venda > Força de Venda > Zona de Venda > Membro.',
			secondParagraph:
				'O membro se refere ao responsável pela venda, garantindo a alocação correta da receita. Acesse o botão Cadastrar Hierarquia para adicionar novas hierarquias comerciais.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpMarket);
	}, [helpMarket, setDataSidebar]);

	const goToCadastroHierarquia = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/hierarquias/criar`);
	}, [history, pathCompanyName]);

	const dataDelete = {
		provider: 'hierarchy',
		toastSuccessTitle: 'Excluido com sucesso!',
		toastSuccessDescription: 'A hierarquia foi excluida com sucesso.',
		toastErrorTitle: 'Erro ao excluir hierarquia!',
		title: 'Deseja realmente excluir esta hierarquia?',
		text: [
			'Você está prestes a excluir esta hierarquia da sua base de cadastros.',
			'',
			'Você tem certeza que deseja excluir?',
		],
		invalidate: 'hierarquiaComercial-list',
	};

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estas hierarquias da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'hierarchy',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'As hierarquias foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir hierarquias!',
			invalidate: 'hierarquiaComercial-list',
		}),
		[],
	);

	const renderRow = useCallback((hierarchy: Hierarchy) => {
		return {
			id: hierarchy.id,
			nomeMercado: hierarchy.nome_mercado,
			canalVenda: hierarchy.nome_canal_venda,
			forcaVenda: hierarchy.nome_forca_venda,
			zonaVenda: hierarchy.nome_zona_venda,
			membro: hierarchy.nome_membro,
			dataRegistro: formatStringoToDateAndTime(hierarchy?.data_registro),
		};
	}, []);

	const handleOnOpenModalList = useCallback(
		(module) => {
			setSelectedModuleProps(buildCheckTableModalProps(module));

			setTimeout(() => onOpenModalList(), 300);
		},
		[onOpenModalList],
	);

	const renderMenuListOptions = useCallback(() => {
		return (
			<Menu>
				<MenuButton
					as={Button}
					rightIcon={
						<Flex mr='6px'>
							<IconDotsVertical />
						</Flex>
					}
					size='large'
					fontWeight='100'
					px='6px'
					py='2px'
					border='1px solid'
					borderRadius='100px'
					borderColor='easyBrandMenu.200'
					fontSize='16px'
					color='easyBLUE.300'
					_hover={{ bg: '#FAFCFE' }}
					_focus={{ bg: 'white' }}
					_active={{ bg: '#E2E8F0', boxShadow: 'none' }}
				/>
				<MenuList>
					<MenuItem onClick={() => handleOnOpenModalList('mercado')}>
						<Text color='easyBLUE.300'>Ver mercados</Text>
					</MenuItem>
					<MenuItem
						onClick={() => handleOnOpenModalList('canalVenda')}
					>
						<Text color='easyBLUE.300'>Ver canais de venda</Text>
					</MenuItem>
					<MenuItem
						onClick={() => handleOnOpenModalList('forcaVenda')}
					>
						<Text color='easyBLUE.300'>Ver forças de venda</Text>
					</MenuItem>
					<MenuItem
						onClick={() => handleOnOpenModalList('zonaVenda')}
					>
						<Text color='easyBLUE.300'>Ver zonas de venda</Text>
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}, [handleOnOpenModalList]);

	const renderButtons = useCallback((): JSX.Element => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				gap={2}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-importar-hierarquia'
						type={'ghost'}
						title={'Importar Hierarquias'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Dados de Hierarquias'}
						module={'hierarquiaComercial'}
						useDownloadTemplate={useDownloadTemplateHierarchy}
						stringInvalidQuery='hierarquia-comercial'
						useUpload={useUploadCommercialHierarchy}
						showLineRestricts={true}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test='button-cadastrar-hierarquia'
						type={'primary'}
						title={'Cadastrar Hierarquias'}
						onClick={goToCadastroHierarquia}
					/>
					<TooltipStandard
						label='Exportar Hierarquias para CSV'
						shouldWrapChildren={true}
					>
						<ButtonExportGenericCSV
							useExport={useExportCommercialHierarchy}
							cycleId={'hierarquia-comercial'}
							module={'hierarquia-comercial'}
							errorDescription='Não há dados para exportar!'
						/>
					</TooltipStandard>
					{renderMenuListOptions()}
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroHierarquia,
		renderMenuListOptions,
		isOpen,
		onClose,
		tableColumns,
		hasBaseData,
	]);

	const renderTable = (): JSX.Element => {
		return (
			<CheckTable
				dataTest='button-filtrar-hierarquia'
				module='hierarquiaComercial'
				linkEditForm={`/${pathCompanyName}/cadastros/hierarquia/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				filterModule='hierarquiaComercial'
				renderRow={renderRow}
				deleteMessage={deleteMessage}
				bulkDeleteProps={dataDeleteAll}
				showGenericSearchInput={false}
				setHasBaseData={setHasBaseData}
				tableTest='tabela-hierarquia'
			/>
		);
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
			<CheckTableModal
				filterModule={selectedModuleProps?.filterModule}
				columnsData={selectedModuleProps?.columnsData}
				renderRow={selectedModuleProps?.renderRow}
				isOpen={isOpenModalList}
				onClose={onCloseModalList}
				modalTitle={selectedModuleProps?.modalTitle}
				simpleDelete={selectedModuleProps?.simpleDelete}
				deleteMany={selectedModuleProps?.deleteMany}
				deleteAll={selectedModuleProps?.deleteAll}
				queriesToInvalidadeOnRemove={['hierarquiaComercial-list']}
				queriesToInvalidadeOnEdit={['hierarquiaComercial-list']}
			/>
		</Flex>
	);
}
