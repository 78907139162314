import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { createProductionRoadmap } from 'services/api/requests/roadmap';

export const useCreateProductionRoadmap = (body: any) => {
	const toast = useToast();
	const queryClient = useQueryClient();

	function getCustomError(error) {
		if (error?.path?.includes?.('/'))
			return error?.message ?? 'Ocorreu um erro!';

		switch (error?.path) {
			case 'tempo_maquina':
				return 'O tempo de máquina deve ser informado em todas as atividades!';
			case 'tempo_mao_obra':
				return 'O tempo do coladorador deve ser informado em todas as atividades!';
			case 'quantidade_produto':
				return 'A quantidade deve ser informada em todas as atividades!';
			default:
				return 'Ocorreu um erro!';
		}
	}

	return useMutation(() => createProductionRoadmap(body), {
		onSuccess: (success) => {
			queryClient.removeQueries({
				predicate: (query) =>
					query.queryKey[0] ===
					'roteiro-producao-autocomplete-infinity-scroll',
			});

			queryClient.invalidateQueries([
				'roadmap-production-products-exists',
			]);

			toast({
				title: 'Cadastro com sucesso!',
				description: body?.atividades?.length
					? 'Roteiro criado com sucesso.'
					: 'Roteiro excluído com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			if (error?.response?.data && Array.isArray(error.response.data)) {
				error.response.data.forEach((e) => {
					toast({
						title: 'Erro ao cadastrar roteiro!',
						description: getCustomError(e),
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				});
			} else {
				toast({
					title: 'Erro ao cadastrar roteiro!',
					description:
						error.response.data.message[0].constraints.isNotEmpty,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
