import {
	defaultInputStyle,
	defaultFormStyle,
	columnFlexProps,
} from '../../utils/forms/defaultsProps';
import { formControlProps } from '../../utils/forms/defaultsProps';
import { Button, Icon, Text } from '@chakra-ui/react';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	validateRequired,
	validateMin,
	validateByRegex,
	validateCNPJ,
} from '../../utils/forms/validations';
import { useGetModality } from 'services/queryClient/wrapperHooks/authentication/useGetModality';
import { useGetAddressByCep } from 'services/queryClient/wrapperHooks/externalServices/useGetAddressByCep';
import { IconEye, IconEyeOff } from '@tabler/icons';
import { useSignUpAdmin } from 'services/queryClient/wrapperHooks/authentication/useSignUpAdmin';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { removePhoneMask } from 'utils/functions/removePhoneMask';

interface IFormRegistrarUsuario {
	initialState: any;
	memberId?: number;
	hasStock?: boolean;
	autocompleteAdditionalData?: any;
}

interface PasswordShow {
	passwordShow: boolean;
	confirmPasswordShow: boolean;
}

interface UserAttributesBody {
	Name: string;
	Value: string;
}

interface RegisterAdminBody {
	email: string;
	password: string;
	userGroups: [];
	dbUserData: {
		nome: string;
		telefone: string;
		email: string;
	};
	dbEmpresaData: {
		cnpj: string;
		razao_social: string;
		telefone: string;
		email: string;
		nome_fantasia: string;
		cep: string;
		uf: string;
		rua: string;
		bairro: string;
		cidade: string;
		numero: string;
		complemento: string;
	};
	dbContratoData: {
		modalidade_id: string;
		duracao_dias: string;
	};
	dbUserAttributes: UserAttributesBody[];
}

const FormRegistrarUsuario = ({ initialState }: IFormRegistrarUsuario) => {
	const history = useHistory();

	const [payload, setPayload] = useState<any>();
	const [formProps, setFormProps] = useState<any>();
	const [cep, setCep] = useState<string>('');
	const [passwordError, setPasswordError] = useState<boolean>(false);
	const [formInitialState, setFormInitialState] = useState<any>(initialState);

	const clearFields = useCallback(() => {
		setFormInitialState('');
	}, []);

	useEffect(() => {
		setFormInitialState(initialState);
	}, [initialState]);

	const [showPassword, setShowPassword] = useState<PasswordShow>({
		passwordShow: false,
		confirmPasswordShow: false,
	});

	const { data: ModalityData } = useGetModality();

	const { data: DataCEP, refetch: RefetchCEP } = useGetAddressByCep(
		cep,
		undefined,
		undefined,
		true,
		formProps,
	);

	const renderSelectOptionPlan = useMemo(() => {
		if (!ModalityData?.length) return [];

		return ModalityData?.map((plan) => (
			<option
				value={plan?.id}
				key={plan?.id}
			>
				{plan.modalidade[0].toUpperCase() + plan.modalidade.slice(1)}
			</option>
		));
	}, [ModalityData]);

	const handleOnChangeCEP = useCallback(
		(event, _formProps) => {
			if (!formProps) {
				setFormProps(_formProps);
			}
			const value = event.target.value || '';
			if (value.replace(/(\d{5})(\d)/, '$1-$2').length === 9) {
				setCep(value);
			}
		},
		[formProps],
	);

	const { mutate: signUpAdmin, isLoading: isLoadingSignUpAdmin } =
		useSignUpAdmin(payload);

	const handleClickShowPassword = useCallback(
		(input: 'passwordShow' | 'confirmPasswordShow') => {
			setShowPassword((prevState) => ({
				...prevState,
				[input]: !prevState[input],
			}));
		},
		[],
	);

	const renderAddonContentTel = useCallback(() => {
		return (
			<Text
				display={'flex'}
				onClick={() => {}}
				borderLeftRadius={10}
				borderRightRadius={0}
				border={'1px solid #e2e8f0'}
				variant='ghost'
				h={10}
				w={14}
				textAlign='center'
				justifyContent={'center'}
				alignItems={'center'}
				fontSize={'sm'}
			>
				+55
			</Text>
		);
	}, []);

	const renderAddonContentPassword = useCallback(
		(input: 'passwordShow' | 'confirmPasswordShow') => {
			return (
				<Button
					onClick={() => handleClickShowPassword(input)}
					borderLeftRadius={0}
					borderRightRadius={10}
					border={'1px solid #e2e8f0'}
					variant='ghost'
				>
					{showPassword[input] ? (
						<Icon as={IconEyeOff} />
					) : (
						<Icon as={IconEye} />
					)}
				</Button>
			);
		},
		[handleClickShowPassword, showPassword],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'plano_de_assinatura',
							label: 'Defina o plano de assinatura do usuário',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								selectOptions: renderSelectOptionPlan,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'duracao_do_contrato',
							label: 'Duração do contrato (dias)',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: 365',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'cnpj',
							label: 'Qual o CNPJ?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								validate: (value) => validateCNPJ(value),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: 47.047.459/0001-04',
								'data-mask': '00.000.000/0000-00',
								maxLength: 18,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'razao_social',
							label: 'Qual a razão social?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Easy360 LTDA',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_fantasia',
							label: 'Qual o nome fantasia da empresa?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Easy360',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'telefone',
							label: 'Qual o telefone do usuário/empresa?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: (41) 99999-9999',
								'data-mask': '(00) 00000-0000',
								addonInputType: 'tel',
								addonPosition: 'left',
								addonContent: renderAddonContentTel(),
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'cep',
							label: 'CEP - Endereço Comercial',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							labelTooltip:
								'Por favor, certifique-se de ter entrado com o endereço comercial da sua indústria corretamente. Essa informação influência nas funcionalidades da easy360 (ex. buscar automaticamente os feriados).',
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: 80215-090',
								'data-mask': '00000-000',
								pattern: '[0-9]{5}-[0-9]{3}',
								maxLength: 9,
								onChange: handleOnChangeCEP,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'estado',
							label: 'Estado',
							inputProps: {
								...defaultInputStyle,
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'cidade',
							label: 'Cidade',
							inputProps: {
								...defaultInputStyle,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
							disabled: () => true,
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'bairro',
							label: 'Bairro',
							inputProps: {
								...defaultInputStyle,
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						subColumns: [
							{
								columnFlexProps: {
									flexDirection: 'row',
									w: '100%',
									mr: '30px',
								},
								inputs: [
									{
										formControlProps,
										input: {
											type: 'text',
											name: 'rua',
											label: 'Rua',
											inputProps: {
												...defaultInputStyle,
											},
											disabled: () => true,
										},
									},
								],
							},
						],
					},
					{
						subColumns: [
							{
								columnFlexProps: {
									flexDirection: 'row',
									w: '100%',
								},
								inputs: [
									{
										formControlProps: {
											pt: '10px',
											pr: '15px',
										},
										input: {
											type: 'number',
											name: 'numero',
											label: 'Nº',
											showRequiredOnLabel: true,
											validate: {
												...validateRequired(),
											},
											inputProps: {
												...defaultInputStyle,
												placeholder: 'ex.: 1341',
											},
										},
									},
									{
										formControlProps: {
											pt: '10px',
											pl: '15px',
										},
										input: {
											type: 'text',
											name: 'complemento',
											label: 'Complemento',
											inputProps: {
												...defaultInputStyle,
												placeholder:
													'ex.: Habitat Senai',
											},
										},
									},
								],
							},
						],
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_do_usuario',
							label: 'Qual o nome completo do usuário?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: João da Silva',
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'email',
							name: 'email',
							label: 'Qual o email?',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: joaodasilva@easy360.ind.br',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'senha',
							label: 'Digite a senha',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateByRegex(
									/(?=^.{8,30}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
									'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
								),
								...validateMin(8),
							},
							labelTooltip:
								'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Ab12345@',
								maxLength: 30,
								addonInputType: showPassword.passwordShow
									? 'text'
									: 'password',
								addonPosition: 'right',
								addonContent:
									renderAddonContentPassword('passwordShow'),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'confirmacao_de_senha',
							label: 'Digite a senha novamente',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateMin(8),
								...validateByRegex(
									/(?=^.{8,30}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
									'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
								),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Ab12345@',
								maxLength: 30,
								addonInputType: showPassword.confirmPasswordShow
									? 'text'
									: 'password',
								addonPosition: 'right',
								addonContent: renderAddonContentPassword(
									'confirmPasswordShow',
								),
							},
						}),
					},
				],
			},
		];
	}, [
		handleOnChangeCEP,
		renderAddonContentPassword,
		renderAddonContentTel,
		renderSelectOptionPlan,
		showPassword.confirmPasswordShow,
		showPassword.passwordShow,
	]);

	const onSubmit = useCallback(
		(values) => {
			const body: RegisterAdminBody = {
				email: values?.email || null,
				password: values?.senha || null,
				userGroups: [],
				dbUserData: {
					nome: values?.nome_do_usuario || null,
					telefone: removePhoneMask(values?.telefone || null),
					email: values?.email || null,
				},
				dbEmpresaData: {
					cnpj: values?.cnpj.replace(/\D/g, '') || null,
					razao_social: values?.razao_social || null,
					telefone: removePhoneMask(values?.telefone || null),
					email: values?.email || null,
					nome_fantasia: values?.nome_fantasia || null,
					cep: values?.cep.replace('-', '') || null,
					uf: values?.estado || null,
					rua: values?.rua || null,
					bairro: values?.bairro || null,
					cidade: values?.cidade || null,
					numero: values?.numero || null,
					complemento: values?.complemento || null,
				},
				dbContratoData: {
					modalidade_id: values?.plano_de_assinatura || null,
					duracao_dias: values?.duracao_do_contrato || null,
				},
				dbUserAttributes: [
					{
						Name: 'custom:planoAssinatura',
						Value: values?.plano_de_assinatura,
					},
					{
						Name: 'custom:razaoSocial',
						Value: values?.razao_social,
					},
					{
						Name: 'custom:CNPJ',
						Value: values?.cnpj,
					},
					{
						Name: 'name',
						Value: values?.nome_do_usuario,
					},
					{
						Name: 'email',
						Value: values?.email,
					},
					{
						Name: 'custom:nomeFantasia',
						Value: values?.nome_fantasia,
					},
				],
			};

			setPayload(body);

			if (values.senha !== values.confirmacao_de_senha) {
				setPasswordError(true);
			} else {
				setPasswordError(false);
				signUpAdmin(body);
			}
		},
		[signUpAdmin],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					cancelFormReset={true}
					loadingSubmit={isLoadingSignUpAdmin}
				/>
				{passwordError && (
					<AlertComponent
						title={'As senhas não correspondem!'}
						description={
							'Por favor, digite a mesma senha nos dois campos!'
						}
						status={'error'}
						hasCloseButton
						actionClose={() => setPasswordError(false)}
					/>
				)}
			</>
		);
	}, [
		formInitialState,
		renderRows,
		onSubmit,
		clearFields,
		isLoadingSignUpAdmin,
		passwordError,
	]);

	return renderForm();
};

export default FormRegistrarUsuario;
