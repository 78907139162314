import { GroupBase, StylesConfig } from 'react-select';
import { globalStyles } from 'theme/styles';
import { Styles, Size } from './Multiselect';

export const getStylesConfig = <T>(
	size: Size,
	stylesProp?: Partial<Styles>,
): any => {
	const color = globalStyles.colors.secondaryGray[300];
	const colorRemove = globalStyles.colors.secondaryGray[400];
	const sizes = {
		medium: '44px',
		large: '60px',
		auto: 'auto',
	};
	return {
		option: (styles) => ({
			...styles,
			cursor: 'pointer',
			fontSize: '16px',
			fontWeight: 400,
		}),
		multiValue: (styles) => ({
			...styles,
			backgroundColor: color,
			borderRadius: '8px',
			minWidth: stylesProp?.multiValue?.minWidth,
		}),
		multiValueRemove: (styles) => ({
			...styles,
			':hover': {
				backgroundColor: colorRemove,
			},
			borderRadius: '8px',
		}),
		clearIndicator: (styles) => ({
			...styles,
			cursor: 'pointer',
		}),
		control: (styles) => ({
			...styles,
			cursor: 'pointer',
			borderRadius: '10px',
			height: sizes[size],
			borderColor: 'inherit',
		}),
		container: (styles) => ({
			...styles,
			cursor: 'pointer',
			borderRadius: '10px',
			fontSize: '16px',
			fontWeight: 400,
			maxWidth: stylesProp?.container?.maxWidth,
		}),
		indicatorsContainer: (styles) => ({
			...styles,
			cursor: 'pointer',
		}),
		valueContainer: (styles) => ({
			...styles,
			cursor: 'pointer',
			paddingLeft: '16px',
			flexWrap: stylesProp?.valueContainer?.flexWrap,
			height: 'auto',
		}),
		placeholder: (styles) => ({
			...styles,
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		}),
	};
};

export const getStylesConfigSingle = <T>(size: Size): any => {
	const color = globalStyles.colors.secondaryGray[300];
	const colorRemove = globalStyles.colors.secondaryGray[400];
	const sizes = {
		medium: '44px',
		large: '60px',
		auto: 'auto',
	};
	return {
		option: (styles) => ({
			...styles,
			cursor: 'pointer',
			width: '100%',
			fontSize: '16px',
			fontWeight: 400,
		}),
		control: (styles) => ({
			...styles,
			cursor: 'pointer',
			borderRadius: '10px',
			height: sizes[size],
			borderColor: 'inherit',
			width: '100%',
		}),
		container: (styles) => ({
			...styles,
			cursor: 'pointer',
			borderRadius: '10px',
			width: '100%',
			fontSize: '16px',
			fontWeight: 400,
		}),
		indicatorsContainer: (styles) => ({
			...styles,
			cursor: 'pointer',
		}),
		valueContainer: (styles) => ({
			...styles,
			cursor: 'pointer',
			paddingLeft: '16px',
		}),
	};
};
