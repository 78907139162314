import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataPrecoMedio } from './variables/columnsDataPrecoMedio';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { RecalculatePrecoMedioModal } from 'components/modal/RecalculatePrecoMedioModal';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { AlertComponent } from 'components/alerts/AlertComponent';

interface AverageTicket {
	id: number;
	nome_canal_venda: string;
	nome_familia: string;
	nome_produto: string;
	codigo_produto: string;
	ticket_medio_calculado: number;
	ticket_medio_editado: number;
	recalcular: boolean;
}

export default function CadastroPrecoMedio() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataPrecoMedio, []);

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const [hasNegativeValue, setHasNegativeValue] = useState(false);

	const helpAverageTicket: SidebarHelp = useMemo(
		() => ({
			title: 'Preço Médio',
			firstParagraph: `O preço médio se refere ao valor médio de vendas de um determinado produto. Para cada canal de venda é possível ter um preço médio diferente. Observação: Este valor influência nos cálculos de previsão de receita bruta da easy360.`,
			secondParagraph: `Caso deseje alterar o preço médio de algum produto, clique no botão da coluna "Recalcular" do produto desejado, assim você poderá entrar com o valor manualmente. Caso contrário, você seguirá com os valores calculados automaticamente pela easy360.`,
			thirdParagraph: `A easy360 usa como padrão o histórico de 6 meses de vendas para calcular o preço médio, você pode alterar essa configuração acessando Configurações > Configurações da Easy360. Observação: somente o administrador da conta possui permissão para alterar essa configuração.`,
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const goToCadastroPrecoMedio = useCallback(() => {
		history.push(`/${pathCompanyName}/demanda/ticketMedio/criar`);
	}, [history, pathCompanyName]);

	const textHelper =
		'Ao habilitar esta opção irá recalcular automaticamente o preço médio sempre que importar novas vendas. Caso deseje editar o valor do preço médio, acesse a tela de edição.';

	const renderRow = useCallback((averageTicket: AverageTicket) => {
		return {
			id: averageTicket.id,
			canalVenda: averageTicket?.nome_canal_venda,
			familia: averageTicket?.nome_familia,
			produto: averageTicket?.nome_produto,
			codProduto: averageTicket?.codigo_produto,
			ticketMedio: averageTicket.recalcular
				? averageTicket?.ticket_medio_calculado?.toLocaleString(
						'pt-BR',
						{
							style: 'currency',
							currency: 'BRL',
						},
				  )
				: averageTicket?.ticket_medio_editado?.toLocaleString('pt-BR', {
						style: 'currency',
						currency: 'BRL',
				  }),
			recalcular: averageTicket.recalcular,
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-recalcular-page-preco_medio'
						type={'secondary'}
						title={'Recalcular'}
						onClick={onOpen}
					/>
					<ButtonComponent
						data-test='button-cadastrar-preco_medio'
						type={'primary'}
						title={'Cadastrar Preço Médio'}
						onClick={goToCadastroPrecoMedio}
					/>
				</ButtonGroup>
				<RecalculatePrecoMedioModal
					isOpen={isOpen}
					onClose={onClose}
				/>
			</Flex>
		);
	}, [goToCadastroPrecoMedio, isOpen, onClose, onOpen]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-preco_medio'
				module={'ticketMedio'}
				textHelper={textHelper}
				linkEditForm={`/${pathCompanyName}/demanda/editar/ticketMedio`}
				columnsData={tableColumns}
				showGenericSearchInput={false}
				filterModule='ticketMedio'
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
				setHasNegativeValue={setHasNegativeValue}
				ml='20px'
			/>
		);
	}

	function renderAlerts(
		action: boolean,
		status: 'error' | 'warning',
		description: string,
		hasCloseButton?: boolean,
	) {
		return (
			action && (
				<AlertComponent
					title='Atenção!'
					status={status}
					description={description}
					hasCloseButton={hasCloseButton}
				/>
			)
		);
	}

	useEffect(() => {
		setDataSidebar(helpAverageTicket);
	}, [helpAverageTicket, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderAlerts(
					hasNegativeValue,
					'warning',
					'Notamos que existe algum preço médio negativo na sua base, isso indica um grande volume de devoluções de vendas!',
					true,
				)}
				{renderButtons()}
				{renderTable()}
				{renderAlerts(
					!hasBaseData,
					'error',
					'Por favor, clique em Recalcular para obter os valores de preço médio.',
				)}
			</Card>
		</Flex>
	);
}
