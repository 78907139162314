import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editCollaborationConfig } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useEditCollaborationConfig = () => {
	const queryClient = useQueryClient();

	const toast = useToast();

	return useMutation(editCollaborationConfig, {
		onSuccess: () => {
			queryClient.resetQueries();
			toast({
				title: 'Configuração de colaboração atualizada com sucesso!',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao atualizar configuração de colaboração',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
