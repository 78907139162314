import { Box } from "@chakra-ui/react";

// Custom Chakra theme
export default function Blank(props) {
  const { children } = props;
  return ( <Box display='flex'>
      {children}
    </Box>
  );
}
