import {
	ButtonGroup,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Switch,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { IconRefreshAlert } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import MoneyField from 'components/fields/MoneyField';
import React, { useEffect, useState } from 'react';
import { useForm } from 'services/hooks/useForm';
import { useEditTicketMedio } from 'services/queryClient/wrapperHooks/averageTicket/useEditTicketMedio';
import { useEditTicketMedioRecalculate } from 'services/queryClient/wrapperHooks/averageTicket/useEditTicketMedioRecalculate';
import { useGetTicketMedioById } from 'services/queryClient/wrapperHooks/averageTicket/useGetTicketMedioById';
import { currencyToNumber } from 'utils/functions/currencyToNumber';

export function EditModal(props) {
	const { title, text, firstLabel, secondLabel, id, checked } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenModalNewTicketMedio,
		onOpen: onOpenModalNewTicketMedio,
		onClose: onCloseModalNewTicketMedio,
	} = useDisclosure();

	const [isToggleChecked, setIsToggleChecked] = useState(checked);

	useEffect(() => {
		setIsToggleChecked(checked);
	}, [id, checked]);

	const { form, onChange, setForm } = useForm({
		id: '',
		recalcular: '',
		produto: '',
		codProduto: '',
		ticketMedio: '',
		canalVenda: '',
		familia: '',
	});

	const body = {
		recalcular: false,
		ticket_medio_editado: currencyToNumber(form.ticketMedio),
	};

	const bodyRecalculate = {
		recalcular: true,
		intervaloTempo: 6,
		historico_completo: false,
	};

	const { mutate } = useEditTicketMedio(body, id);
	const { mutate: Recalculate } = useEditTicketMedioRecalculate(
		bodyRecalculate,
		id,
	);

	const editTicketMedio = event => {
		event.preventDefault();
		mutate();
		onClose();
	};

	const { refetch } = useGetTicketMedioById(setForm, id, 'modal');

	const handleIsToggleChecked = () => {
		if (isToggleChecked === true) {
			setIsToggleChecked(!isToggleChecked);
			refetch();
			isToggleChecked && onOpen();
		} else {
			setIsToggleChecked(!isToggleChecked);
			!isToggleChecked && onOpenModalNewTicketMedio();
		}
	};

	const onClickCancel = () => {
		onClose();
		setIsToggleChecked(true);
	};

	const recalculateTicketMedio = () => {
		Recalculate();
		onCloseModalNewTicketMedio();
	};

	const onClickCancelRecalculate = () => {
		onCloseModalNewTicketMedio();
		setIsToggleChecked(false);
	};

	const initialRef = React.useRef(null);
	return (
		<>
			<Switch
				colorScheme='red'
				size='sm'
				isChecked={isToggleChecked}
				onChange={handleIsToggleChecked}
			/>
			<Modal
				initialFocusRef={initialRef}
				isOpen={isOpen}
				onClose={onClose}
				size='xl'
				isCentered
				closeOnOverlayClick={false}
				closeOnEsc={false}
			>
				<ModalOverlay />
				<form onSubmit={editTicketMedio}>
					<ModalContent
						borderRadius={20}
						pt='20px'
					>
						<ModalBody pb={6}>
							<Heading
								color='gray.700'
								mb={2}
								size='md'
							>
								{title}
							</Heading>
							<Text color='gray.500'>{text}</Text>
							<FormControl>
								<FormLabel mt={4}>{firstLabel}</FormLabel>
								<Input
									disabled
									value={form.produto}
								/>
							</FormControl>
							<FormControl mt={4}>
								<FormLabel>{secondLabel} </FormLabel>
								<MoneyField
									maskOptions={''}
									ref={initialRef}
									name={'ticketMedio'}
									value={form.ticketMedio}
									onChange={e => {
										onChange(e);
									}}
									allowNegative={true}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<ButtonGroup>
								<ButtonComponent
									type={'ghost'}
									title={'Cancelar'}
									onClick={onClickCancel}
								/>
								<ButtonComponent
									type={'primary'}
									title={'Salvar'}
									action={'submit'}
								/>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal>
			{/* Modal para o caso de recalcular ticket médio do produto */}
			<Modal
				isOpen={isOpenModalNewTicketMedio}
				onClose={onCloseModalNewTicketMedio}
				size='xl'
				isCentered
				closeOnOverlayClick={false}
				closeOnEsc={false}
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						<Icon
							as={IconRefreshAlert}
							color={'easyRED.300'}
							boxSize={14}
						/>
						<Text
							fontWeight={700}
							color={'easyBLUE.300'}
							fontSize={'2xl'}
						>
							Deseja realmente recalcular o preço médio deste
							produto neste canal de venda?
						</Text>
					</ModalHeader>
					<ModalBody>
						<Text color='gray.500'>
							Você está prestes a recalcular o valor do preço
							médio deste produto neste canal de venda. O cálculo
							irá considerar o período de histórico de vendas
							definido nas configurações gerais da Easy360. Caso o
							produto não possuir vendas neste canal de venda no
							período de tempo informado, será removido o valor de
							preço médio. No entanto, não se preocupe, você
							poderá cadastrar o preço médio do produto novamente,
							caso seja necessário. Deseja prosseguir com o
							recálculo?
						</Text>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<ButtonComponent
								type={'ghost'}
								title={'Cancelar'}
								onClick={onClickCancelRecalculate}
							/>
							<ButtonComponent
								type={'secondary'}
								title={'Recalcular Preço Médio'}
								onClick={recalculateTicketMedio}
							/>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
