import { ChangeEvent, useState } from 'react';
import { currencyToNumber } from 'utils/functions/currencyToNumber';

type TypeOnChange =
	| 'toggle'
	| 'badge'
	| 'toggle-actionable'
	| 'rateio'
	| 'plano-restrito'
	| 'meta-producao'
	| 'text';
const handleToggle = (
	form: any,
	setForm: React.Dispatch<any>,
	name: string,
	checked: boolean,
) => {
	return setForm({ ...form, [name]: checked });
};
const handleRateio = (
	form: any,
	setForm: React.Dispatch<any>,
	isChildrenOfFamily: string,
	isChildrenOfChannel: string,
	indexItem: string,
	month: string,
	value: string,
) => {
	const updatedFamily = {
		...form[isChildrenOfFamily][isChildrenOfChannel],
		[indexItem]: {
			...form[isChildrenOfFamily][isChildrenOfChannel][indexItem],
			[month]: {
				...form[isChildrenOfFamily][isChildrenOfChannel][indexItem][
					month
				],
				rateio: value,
			},
		},
	};

	const updatedChannels = {
		...form[isChildrenOfFamily],
		[isChildrenOfChannel]: updatedFamily,
	};

	// Total por canal
	let newTotalPerChannel = 0;
	const familyEntriesArray: any = Object.entries(updatedFamily);
	for (const [key, value] of familyEntriesArray) {
		if (!key.includes('TOTAL')) {
			let lastValue = Number(value[month]?.rateio);
			newTotalPerChannel += lastValue;
		}
	}

	updatedFamily[`${isChildrenOfChannel}-TOTAL`][month] = newTotalPerChannel;

	// Total por família
	let newTotalPerFamilyAndMonth = 0;
	const channelEntriesArray: any = Object.entries(updatedChannels);
	for (const [key, value] of channelEntriesArray) {
		if (!key.includes('TOTAL')) {
			for (const [monthKey, monthValue] of Object.entries(value)) {
				if (!monthKey.includes('TOTAL')) {
					let lastValue = Number((monthValue as any)[month]?.rateio);
					newTotalPerFamilyAndMonth += lastValue;
				}
			}
		}
	}

	updatedChannels[`${isChildrenOfFamily}-TOTAL`][month] =
		newTotalPerFamilyAndMonth;

	// Atualiza formulário com os novos valores
	setForm({
		...form,
		[isChildrenOfFamily]: updatedChannels,
	});
};
const handlePlanoRestrito = (
	form: any,
	setForm: React.Dispatch<any>,
	isChildrenOfFamily: string,
	indexItem: string,
	month: string,
	value: string,
) => {
	const family = {
		...form[isChildrenOfFamily],
		[indexItem]: {
			...form[isChildrenOfFamily][indexItem],
			[month]: value,
		},
	};

	// Atualiza o valor mensal da familia
	let total = 0;
	const arrayMoths: any = Object.entries(family);
	for (const [key, value] of arrayMoths) {
		if (!key.includes('TOTAL')) {
			let lastValue = value[month];
			total += lastValue ? currencyToNumber(lastValue) : 0;
		}
	}
	family[`${isChildrenOfFamily}-TOTAL`][month] = total;

	// Atualiza o valor total do produto da familia
	let totalProduct = 0;
	const product = Object.entries(family[indexItem]) as [string, string][];
	for (const [key, value] of product) {
		if (!key.includes('Total')) {
			totalProduct += value ? currencyToNumber(value) : 0;
		}
	}
	family[indexItem][`Total`] = totalProduct;

	// Atualiza o valor total da familia
	let totalProductsByFamily = 0;
	const productsByFamily = Object.entries(
		family[`${isChildrenOfFamily}-TOTAL`],
	) as [string, string][];
	for (const [key, value] of productsByFamily) {
		if (!key.includes('Total')) {
			totalProductsByFamily += value ? currencyToNumber(value) : 0;
		}
	}
	family[`${isChildrenOfFamily}-TOTAL`]['ProductsTotalByFamily'] =
		totalProductsByFamily;

	setForm({ ...form, [isChildrenOfFamily]: family });
};
const handleMetaProducao = (
	form: any,
	setForm: React.Dispatch<any>,
	isChildrenOfChannel: string,
	item: string,
	name: string,
	value: string,
) => {
	const family = {
		...form[isChildrenOfChannel],
		[item]: { ...form[isChildrenOfChannel][item], [name]: value },
	};

	const qualidade = Number(family[item]['QUALIDADE']) / 100;
	const performance = Number(family[item]['PERFORMANCE']) / 100;
	const disponibilidade = Number(family[item]['DISPONIBILIDADE']) / 100;

	const irog = (disponibilidade * performance * qualidade * 100).toFixed(2);

	family[item]['IROG'] = `${irog}%`;

	setForm({ ...form, [isChildrenOfChannel]: family });
};

export const useForm = (initialState) => {
	const [form, setForm] = useState(initialState);
	const onChangeValue = (
		target: {
			name: string;
			value: any;
			checked?: boolean;
		},
		type?: TypeOnChange,
		month?: string,
		indexItem?: string,
		isChildrenOfChannel?: string,
		isChildrenOfFamily?: string,
		item?: any,
	) => {
		const { name, value, checked } = target;
		switch (type) {
			case 'toggle':
			case 'badge':
			case 'toggle-actionable':
				handleToggle(form, setForm, name, Boolean(checked));
				break;
			case 'rateio':
				handleRateio(
					form,
					setForm,
					isChildrenOfFamily as string,
					isChildrenOfChannel as string,
					indexItem as string,
					month as string,
					value,
				);
				break;
			case 'plano-restrito':
				handlePlanoRestrito(
					form,
					setForm,
					isChildrenOfFamily as string,
					indexItem as string,
					month as string,
					value,
				);
				break;
			case 'meta-producao':
				handleMetaProducao(
					form,
					setForm,
					isChildrenOfChannel as string,
					item,
					name,
					value,
				);
				break;
			default:
				setForm({ ...form, [name]: value });
		}
	};
	const onChange = (
		event: ChangeEvent<any>,
		type?: TypeOnChange,
		month?: string,
		indexItem?: string,
		isChildrenOfChannel?: string,
		isChildrenOfFamily?: string,
		item?: any,
	) => {
		event.preventDefault();
		onChangeValue(
			event.target,
			type,
			month,
			indexItem,
			isChildrenOfChannel,
			isChildrenOfFamily,
			item,
		);
	};

	const onChangeEdit = (event, module, input?) => {
		event.preventDefault();
		const { name, value } = event.target;

		if (module === 'membro') {
			setForm({
				...form,
				[name]: value,
				forcaVenda: '',
				zonaVenda: '',
				areaEmpresa: '',
			});
		} else if (module === 'cidade') {
			setForm({
				...form,
				[name]: value,
				cidadeNome: '',
				microrregiao: '',
				mesorregiao: '',
			});
		} else if (module === 'oportunidade') {
			setForm({ ...form, [name]: value, produto: '', codigoProduto: '' });
		} else if (module === 'mercado') {
			setForm({
				...form,
				[name]: value,
				canalVenda: '',
				forcaVenda: '',
				zonaVenda: '',
				membro: '',
			});
		} else if (module === 'mercadoCanalVenda') {
			setForm({
				...form,
				[name]: value,
				forcaVenda: '',
				zonaVenda: '',
				membro: '',
			});
		} else if (module === 'mercadoForcaVenda') {
			setForm({ ...form, [name]: value, zonaVenda: '', membro: '' });
		} else if (module === 'membro') {
			setForm({ ...form, [name]: value, forcaVenda: '', zonaVenda: '' });
		} else if (module === 'membroForcaVenda') {
			setForm({ ...form, [name]: value, zonaVenda: '' });
		} else if (module === 'vendas') {
			if (name === 'familia')
				setForm({ ...form, [name]: value, codProduto: '' });
			else setForm({ ...form, [name]: value, zonaVenda: '' });
		} else if (module === 'produtos') {
			setForm({ ...form, [name]: value, subfamilia: '' });
		} else if (module === 'carteiraBoa') {
			setForm({ ...form, [name]: value, codigoProduto: '' });
		} else if (module === 'horaHora') {
			if (input === 'produto_id') {
				setForm({
					...form,
					[name]: value,
					atividade_roteiro_id: '',
					recurso_id: '',
				});
			} else if (input === 'atividade_roteiro_id') {
				setForm({
					...form,
					[name]: value,
					recurso_id: '',
				});
			}
		} else if (module === 'recurso') {
			if (name === 'maquina') {
				const isNotMaquina = value === 'false';

				if (isNotMaquina) {
					setForm({
						...form,
						[name]: value,
						gargalo: 'false',
					});
				} else {
					setForm({
						...form,
						[name]: value,
					});
				}
			}
		}
	};

	const cleanFields = () => {
		setForm(initialState);
	};

	const changeFields = (newForn) => {
		setForm({ ...newForn });
	};

	return {
		form,
		onChange,
		onChangeValue,
		cleanFields,
		setForm,
		onChangeEdit,
		changeFields,
	};
};
