import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { ButtonGroup, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataStopTypology } from './variables/columnsData';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';

interface StopTypology {
	id: number;
	tipologia_parada: string;
	programada: boolean;
	tempo_de_parada: number;
}

export default function CadastroParadas() {
	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataStopTypology, []);

	const helpTipologiaParadas: SidebarHelp = useMemo(
		() => ({
			title: 'Paradas',
			firstParagraph:
				'Nesta tela é possível cadastrar e visualizar todas as Paradas Programadas e Não Programadas da sua indústria. A parada programada indica que a produção parou por algum motivo previsto e planejado, por exemplo: refeição ou limpeza. Em contrapartida, uma parada não programada são interrupções imprevistas na linha de produção, por exemplo: falta de matéria prima ou falta de mão de obra.',
			secondParagraph:
				'Acesse o botão “Cadastrar Parada” para adicionar uma nova parada.',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'topology',
			invalidate: 'tipologiaParadas-list',
			toastSuccessTitle: 'Excluida com sucesso!',
			toastSuccessDescription: 'A parada foi excluida com sucesso.',
			toastErrorTitle: 'Erro ao excluir parada!',
			title: 'Deseja realmente excluir esta Parada?',
			text: [
				'Você está prestes a excluir esta parada da sua base de cadastros. Excluir esta parada resultará na remoção dos seguintes itens vinculados a ela:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
		}),
		[],
	);
	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir esta(s) parada(s) da sua base de cadastros. Excluir estas parada(s) resultará na remoção dos seguintes itens vinculados a ela:',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'stopTypology',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'As paradas foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir paradas!',
			invalidate: 'tipologiaParadas-list',
		}),
		[],
	);

	const goToCreateStopTypology = useCallback(() => {
		history.push(
			`/${pathCompanyName}/cadastros/producao/tipologia/cadastrar`,
		);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((stopTypology: StopTypology) => {
		return {
			id: stopTypology?.id,
			tipologiaParada: stopTypology?.tipologia_parada,
			programada: stopTypology?.programada ? 'SIM' : 'NÃO',
			tempoDeParada:
				stopTypology?.tempo_de_parada != null
					? moment
							.utc(
								moment
									.duration(
										stopTypology?.tempo_de_parada,
										'seconds',
									)
									.asMilliseconds(),
							)
							.format('HH:mm:ss')
					: '',
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test={'button-cadastrar-paradas'}
						type={'primary'}
						title={'Cadastrar'}
						onClick={goToCreateStopTypology}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [goToCreateStopTypology]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-paradas'
				module={'tipologiaParadas'}
				linkEditForm={`/${pathCompanyName}/cadastros/producao/tipologia/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='tipologiaParadas'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				textHelper='Paradas programadas foram previstas e mapeadas.'
				tableTest='tabela-paradas'
			/>
		);
	}

	useEffect(() => {
		setDataSidebar(helpTipologiaParadas);
	}, [helpTipologiaParadas, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card pl='0em'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
