import { useQuery } from '@tanstack/react-query';
import { fetchMembersByFilters } from 'services/api/requests/member';

export const useMembersList = (variables, enabled?) => {
	return useQuery(
		['membro-list', variables],
		() => {
			const requestBody = {
				page: variables.page,
				limit: variables.limit,
				filters: variables.filters,
			};

			return fetchMembersByFilters(requestBody);
		},
		{
			onError: (error) => {
				console.log(error);
			},
			refetchOnWindowFocus: false,
			enabled: enabled ?? true,
		},
	);
};
