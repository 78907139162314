import { useMutation, useQuery } from '@tanstack/react-query';
import {
	getHierarchyById,
	getHierarchyByIdAsMutate,
} from 'services/api/requests/commercial';

export const useGetHierarchyById = (setForm: any, id: Number) => {
	return useQuery(['hierarchyById', id], getHierarchyById, {
		onSuccess: (data) => {
			setForm({
				id: data.empresa_id,
				ativo: true,
				nomeMercado: data.mercado?.id,
				canalVenda: data.canal_venda?.id,
				forcaVenda: data.forca_venda?.id,
				zonaVenda: data.zona_venda?.id,
				membro: data.membro?.id,
			});
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};

export const useGetHierarchyWithId = (id: number) => {
	return useQuery(['hierarchyWithId', id], getHierarchyById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
		retry: false,
	});
};

export const useGetHierarchyWithIdAsMutate = (id: number) => {
	return useMutation(() => getHierarchyByIdAsMutate(id), {
		onSuccess: (success) => {},
		onError: (error: any, variables, context) => {
			console.log(error);
		},
	});
};
