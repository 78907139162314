import { useQuery } from '@tanstack/react-query';
import { getProducts } from 'services/api/requests/requests';
interface IProductDemandList {
	familyId?: number | string;
	status?: boolean;
	productId?: number | string;
	page?: number;
	size?: number;
}
export const useProductDemandList = ({
	familyId,
	status,
	productId,
	page = 0,
	size,
}: IProductDemandList) => {
	return useQuery(
		['produto-list', familyId, productId, status, page, size].map(
			(key) => key || '',
		),
		getProducts,
		{
			refetchOnWindowFocus: false,
		},
	);
};
