import { useQuery } from '@tanstack/react-query';
import { getAddresByCep } from 'services/api/requests/externalServices/viaCep';
import { useToast } from '@chakra-ui/react';

export const useGetAddressByCep = (
	cep: string,
	form: any,
	setForm: any,
	enabled: boolean = false,
	formProps?: any,
) => {
	const toasts = useToast();
	return useQuery(['addressByCep', cep], getAddresByCep, {
		onSuccess: (data) => {
			if (data.erro) {
				toasts({
					title: 'Erro ao buscar endereço',
					description: 'Verifique se o CEP está correto',
					status: 'error',
					duration: 8000,
					isClosable: true,
					position: 'bottom-left',
				});
				setForm?.({
					...form,
					cep: '',
					rua: '',
					complemento: '',
					bairro: '',
					cidade: '',
					uf: '',
				});
			} else {
				setForm?.({
					...form,
					rua: data.logradouro,
					bairro: data.bairro,
					cidade: data.localidade,
					uf: data.uf,
				});
				const { setValue } = formProps || {};
				setValue?.('rua', data.logradouro);
				setValue?.('bairro', data.bairro);
				setValue?.('cidade', data.localidade);
				setValue?.('estado', data.uf);
			}
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
		enabled,
	});
};
