import { useMutation } from '@tanstack/react-query';
import { getActivityById } from 'services/api/requests/roadmap';

export const useGetActivityById = (setForm, id) => {
	return useMutation(() => getActivityById(id), {
		onSuccess: (data) => {
			setForm({
				descricao: data.descricao,
				grupo_recurso_id: data.grupo_recurso_id,
				id: data.id,
			});
		},
		onError: (error: any) => {
			console.log(error.response.data.message);
		},
	});
};
