export const setLocalStorageCrm = (value: 'true' | 'false'): void => {
	window.localStorage.setItem(
		'adminNotAssociatedMemberCrmModalMessage',
		value,
	);
};

export const getLocalStorageCrm = (): 'true' | 'false' | null => {
	const localStorageCrmManager = localStorage.getItem(
		'adminNotAssociatedMemberCrmModalMessage',
	);
	if (localStorageCrmManager) {
		return JSON.parse(localStorageCrmManager);
	}
	return null;
};

export const removeLocalStorageCrm = (): void => {
	window.localStorage.removeItem('adminNotAssociatedMemberCrmModalMessage');
};
