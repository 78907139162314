import { SelectColumnFilter } from 'components/dataTables/FilterTypes.js/SelectColumnFilter';
import { RowEdit } from 'components/icons/RowEdit';
import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataPrecoMedio = [
	// {
	// 	Header: ' ',
	// 	accessor: 'CheckBox',
	// 	disableSortBy: true,
	// 	type: 'checkBox',
	// 	filter: false,
	// },
	{
		Header: 'Produto',
		accessor: 'produto',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codProduto',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Família',
		accessor: 'familia',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Recalcular',
		accessor: 'recalcular',
		type: 'toggleAndEditModal',
		filter: false,
		helper: true,
		optional: true,
		isModal: true,
	},
	{
		Header: 'Preço Médio',
		accessor: 'ticketMedio',
		type: 'money',
		isDisabled: false,
		filter: 'includesIgnoreCase',
		onlyEdit: true,
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEdit,
		filter: false,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'PRODUTO',
		accessor: 'produto',
	},
	{
		Header: 'FAMÍLIA',
		accessor: 'familia',
	},
	{
		Header: 'SUBFAMÍLIA',
		accessor: 'subfamilia',
	},
	{
		Header: 'MODELO',
		accessor: 'modelo',
	},
	{
		Header: 'COD.PRODUTO',
		accessor: 'codproduto',
	},
];
// ■ SelectColumnFilter

export const columnsDataOportunidades = [
	{
		Header: 'Família',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Produto',
		accessor: 'produto',
		type: 'relation',
		optional: true,
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigoProduto',
		type: 'disabledCodigoProduto',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Canal Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Preço Médio',
		accessor: 'precoMedio',
		type: 'money',
		optional: true,
		filter: 'equals',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		filter: false,
		jsx: RowEditAndDelete,
	},
];

export const columnsDataOpportunities = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'Razão Social',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
	{
		Header: 'CNPJ/CPF',
		accessor: 'cnpjCpf',
		type: 'text',
		body: 'cnpj',
	},
];
