import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	useColorModeValue,
	ButtonGroup,
} from '@chakra-ui/react';
import { IconAlertCircle, IconAlertTriangle, IconX } from '@tabler/icons';
import { useState } from 'react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
interface AlertComponentProps {
	title?: string;
	description?: string | JSX.Element;
	status?: 'info' | 'warning' | 'success' | 'error';
	noIcon?: boolean;
	hasCloseButton?: boolean;
	actionClose?: Function;
	[key: string]: any;
}
export const AlertComponent = (props: AlertComponentProps) => {
	const { title, description, status, noIcon, hasCloseButton, actionClose } =
		props;
	const [isOpen, setIsOpen] = useState<boolean>(
		hasCloseButton === undefined || hasCloseButton === true,
	);

	const ArqBg = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

	const actionCloseAlert = () => {
		setIsOpen(false);
		actionClose && actionClose();
	};

	return isOpen ? (
		<Alert
			mb={'10px'}
			status={status}
			backgroundColor={status === undefined ? ArqBg : ''}
			h={'60px'}
			borderRadius='16px'
			w={status !== undefined ? '100%' : '40%'}
			justifyContent={status !== undefined ? 'center' : ''}
		>
			{!noIcon &&
				(status === 'error' ? (
					<AlertIcon
						as={IconAlertCircle}
						color={'easyRED.400'}
						boxSize={'24px'}
					/>
				) : status === 'warning' ? (
					<AlertIcon
						as={IconAlertTriangle}
						color={'orange.500'}
						boxSize={'24px'}
					/>
				) : (
					<AlertIcon
						as={IconAlertCircle}
						color={'easyBLUE.300'}
						boxSize={'24px'}
					/>
				))}
			<AlertTitle
				color={
					status === 'error'
						? 'easyRED.400'
						: status === 'warning'
						? 'orange.700'
						: 'easyBLUE.300'
				}
				mr={'12px'}
			>
				{title}
			</AlertTitle>
			<AlertDescription
				color={
					status === 'error'
						? 'easyRED.400'
						: status === 'success'
						? 'green.500'
						: status === 'warning'
						? 'orange.700'
						: 'easyBLUE.300'
				}
			>
				{description}
			</AlertDescription>
			{hasCloseButton && (
				<ButtonGroup marginLeft={'auto'}>
					<ButtonComponent
						type={'icon'}
						colorType='warning'
						iconType={<IconX size='20px' />}
						size={4}
						onClick={actionCloseAlert}
					/>
				</ButtonGroup>
			)}
		</Alert>
	) : (
		<></>
	);
};
