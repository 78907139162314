import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';
import api from '../api';

export interface ForecastBody {
	data: string;
	canal_venda_id: number;
	cliente_id: number;
	receita_bruta: number;
}

export const getForecast = async () => {
	const url = '/forecastdemanda';
	const response = await api.get(url);
	return response.data;
};

export const getForecastById = async ({ queryKey }) => {
	const [_key, id] = queryKey;
	const url = `/forecastdemanda/forecastdemandaById?empresa_id=1&forecast_demanda_id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const fetchForecastByFilters = async (body) => {
	const url = '/forecastDemanda/view';
	const requestBody = {
		paginacao: {
			page: body.page,
			size: body.limit || PAGINATION_SIZE_LIST[0],
			order: [],
		},
		filtros: body.filters,
	};
	const response = await api.post(url, requestBody);
	return response.data;
};

export const createForecast = async (body: ForecastBody) => {
	const url = '/forecastdemanda';
	const response = await api.post(url, body);
	return response.data;
};

export const editForecast = async (body: ForecastBody, id: number) => {
	const url = `/forecastdemanda?empresa_id=1&forecast_demanda_id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deleteForecast = async (id: number) => {
	const url = `/forecastdemanda?forecast_demanda_id=${id}`;
	const response = await api.delete(url);
	return response.data;
};

export const deleteForecastByFilters = async (filters: any) => {
	const response = await api.post(
		'forecastDemanda/deletarPorFiltros',
		filters,
	);
	return response.data;
};
