import { Box, Table, Text, useColorModeValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import { usePagination, useTable } from 'react-table';
import Card from 'components/card/Card';
interface ColumnsTableFileUploadProps {
	totalValue: number;
	data: any;
	columnsData: any[];
}
export function ColumnsTableFileUpload(props: ColumnsTableFileUploadProps) {
	const { columnsData, data, totalValue } = props;

	const columns = useMemo(() => columnsData, [columnsData]);

	const tableInstance = useTable(
		{
			columns,
			data,
			autoResetPage: true,
		},
		usePagination,
	);

	const { getTableProps, rows, initialState } = tableInstance;

	// Quantidade de itens por página
	initialState.pageSize = 100;

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bg = useColorModeValue('secondaryGray.300', 'secondaryGray.400');

	const errors = rows
		?.filter((row) => {
			return row.original.erros.length > 0;
		})
		.map((row) => {
			return row.original;
		});

	const errosExibition = errors?.map((error) => {
		return {
			linha: error.conteudo?.numero_linha,
			erros: error.erros.map((erro) => {
				return {
					campo: erro.campo,
					erro: Object.keys(erro.erro).map((key) => {
						return erro.erro[key];
					}),
				};
			}),
		};
	});

	const showErrors = errosExibition?.map((error) => {
		return (
			<Box
				key={error.linha}
				w='100%'
				h='100%'
				p='4'
				borderRadius='16px'
				bg='white'
				boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
				mt='4'
			>
				<Text
					fontSize='16px'
					fontWeight='700'
					color='easyBLUE.300'
					mb='4'
				>
					Linha {error.linha}
				</Text>
				{error.erros.map((erro) => {
					return (
						<Box
							key={erro.campo}
							w='100%'
							h='100%'
							p='4'
							borderRadius='16px'
							bg='white'
							boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
							mt={'4px'}
						>
							<Text
								fontSize='16px'
								fontWeight='400'
								color='easyBLUE.300'
								mb='4'
							>
								<Text
									as={'span'}
									fontWeight='500'
									color='easyBLUE.300'
								>
									Coluna:{' '}
								</Text>
								{erro.campo}
							</Text>
							{erro.erro.map((error) => {
								return (
									<Text
										key={error}
										fontSize='16px'
										fontWeight='400'
										color='easyBLUE.300'
									>
										<Text
											as={'span'}
											fontWeight='500'
											color='easyRED.300'
										>
											Erro:{' '}
										</Text>
										{error}
									</Text>
								);
							})}
						</Box>
					);
				})}
			</Box>
		);
	});
	// Precisa refatorar o código!!!

	return (
		<Card
			direction='column'
			w='100%'
			px='0px'
			pl={'10px'}
			overflowX={{ sm: 'scroll', lg: 'hidden' }}
			padding={'0px'}
			backgroundColor={bg}
		>
			<Table
				{...getTableProps()}
				variant='simple'
				color='gray.500'
				mb='24px'
				borderRadius={'3xl'}
			>
				{errors.length > 300 ? (
					<Box
						mt='4'
						mb='4'
					>
						<Text
							color={textColor}
							fontSize='xl'
							fontWeight='bold'
						>
							Foram encontrados{' '}
							<Text
								as={'span'}
								color={'easyRED.300'}
							>
								{totalValue} erros{' '}
							</Text>
							em seu arquivo.
						</Text>
						<Text
							color={textColor}
							fontSize='xl'
							fontWeight='bold'
						>
							Por favor corrija localmente em sua máquina esses
							erros e faça o upload novamente do arquivo!{' '}
						</Text>
					</Box>
				) : (
					showErrors
				)}
			</Table>
		</Card>
	);
}
