import {
	ButtonGroup,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Switch,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import React, { useEffect, useState } from 'react';
import { useEditPortfolio } from 'services/queryClient/wrapperHooks/portfolio/useEditPortfolio';
import { useEditPortfolioState } from 'services/queryClient/wrapperHooks/portfolio/useEditPortfolioState';

export function EditModalCarteira(props) {
	const { title, text, firstLabel, id, checked } = props;
	// const { isOpen, onOpen, onClose } = useDisclosure();

	const [isToggleChecked, setIsToggleChecked] = useState(checked);

	useEffect(() => {
		setIsToggleChecked(checked);
	}, [id, checked]);

	// Comentando logica que edita a NF, iremos analisar o uso futuramente
	// const [notaFiscal, setNotaFiscal] = useState('');

	// const body = {
	// 	faturado: true,
	// nf: notaFiscal,
	// };

	// const bodyRecalculate = {
	// 	faturado: false,
	// };

	// const { mutate: Recalculate, isError } = useEditPortfolioState(
	// 	bodyRecalculate,
	// 	id,
	// );

	// const editNotaFiscal = (event) => {
	// 	event.preventDefault();
	// 	mutate();
	// 	onClose();
	// };

	// const handleIsToggleChecked = () => {
	// 	if (isToggleChecked === false) {
	// 		setIsToggleChecked(!isToggleChecked);
	// 		!isToggleChecked && onOpen();
	// 	} else {
	// 		setIsToggleChecked(!isToggleChecked);
	// 		Recalculate();
	// 	}
	// };

	// const onClickCancel = () => {
	// 	onClose();
	// 	setIsToggleChecked(false);
	// };

	const body: {
		faturado: boolean;
	} = {
		faturado: !checked,
	};

	const { mutate, isError } = useEditPortfolio(body, id, 'editModal');

	const updateItem = (): void => {
		mutate();
	};

	useEffect(() => {
		if (isError) {
			setIsToggleChecked(checked);
		}
	}, [isError]);

	const initialRef = React.useRef(null);
	return (
		<>
			<Switch
				colorScheme='green'
				size='sm'
				isChecked={isToggleChecked}
				onChange={updateItem}
			/>

			{/* Comentando modal de edição de NF, iremos analisar o uso futuramente */}
			{/* <Modal
				initialFocusRef={initialRef}
				isOpen={isOpen}
				onClose={onClose}
				size='xl'
				isCentered
				closeOnOverlayClick={false}
			>
				<ModalOverlay />
				<form onSubmit={editNotaFiscal}>
					<ModalContent
						borderRadius={20}
						pt='20px'
					>
						<ModalBody pb={6}>
							<Heading
								color='gray.700'
								mb={2}
								size='md'
							>
								{title}
							</Heading>
							<Text color='gray.500'>{text}</Text>
							<FormControl>
								<FormLabel pt={4}>{firstLabel}</FormLabel>
								<Input
									type={'number'}
									onChange={(e) =>
										setNotaFiscal(e.target.value)
									}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<ButtonGroup>
								<ButtonComponent
									type={'ghost'}
									title={'Cancelar'}
									onClick={onClickCancel}
								/>
								<ButtonComponent
									type={'primary'}
									title={'Salvar'}
									action={'submit'}
								/>
							</ButtonGroup>
						</ModalFooter>
					</ModalContent>
				</form>
			</Modal> */}
		</>
	);
}
