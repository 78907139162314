import io from 'socket.io-client';
import { environmentVars } from './variables/environment';

const apiWebSocketBitec = io(environmentVars.biTec.url ?? '');

const addWebSocketListener = (
	customerId: string,
	handler: (data: any) => void,
) => {
	try {
		const bistoreResult = (data: any) => {
			handler(data);
		};

		apiWebSocketBitec.on(`bibotStatusChanged:${customerId}`, bistoreResult);
	} catch (error) {
		console.error('Erro ao iniciar o websocket:', error);
	}
};

const removeWebSocketListener = (
	customerId: string,
	handler: (data: any) => void,
) => {
	try {
		apiWebSocketBitec.off(`bibotStatusChanged:${customerId}`, handler);
	} catch (error) {
		console.error('Erro ao finalizar o websocket:', error);
	}
};

export { addWebSocketListener, removeWebSocketListener };
export default apiWebSocketBitec;
