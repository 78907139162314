import { QueryFunctionContext } from '@tanstack/react-query';
import { UseCollaborationConsensuHistory } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationConsensuHistory';
import { IUseIStatistic } from 'services/queryClient/wrapperHooks/useStatistic';
import api from '../../api';

export const exportCollaborationConsensus = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, page, ciclo_id, area_empresa_id] = queryKey;
	const response = await api.get(
		`/${page}/exportCSV?ciclo_id=${ciclo_id}&area_empresa_id=${area_empresa_id}`,
	);
	return response.data;
};

export const getCollaborationConsensusByFamily = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [
		,
		page,
		ciclo_id,
		familia_id,
		area_empresa_id,
		canal_venda_id,
		zona_venda_id,
	] = queryKey;
	const config = {
		params: {
			ciclo_id,
			canal_venda_id,
			familia_id,
			area_empresa_id,
			zona_venda_id,
		},
	};
	const { data } = await api.get(`/${page}`, config);
	return data;
};

export const getCollaborationConsensusExceptionByFamily = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [
		,
		page,
		ciclo_id,
		canal_venda_id,
		familia_id,
		area_empresa_id,
		zona_venda_id,
	] = queryKey;
	const response = await api.get(`/${page}/excecao`, {
		params: {
			ciclo_id,
			canal_venda_id,
			familia_id,
			area_empresa_id,
			zona_venda_id,
		},
	});
	return response.data;
};

export const getCollaborationExceptionsByFamilyId = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id, familia_id] = queryKey;
	const response = await api.get(
		`/colaboracao/colaboracaoExcecaoByFamiliaId?ciclo_id=${ciclo_id}&familia_id=${familia_id}`,
	);
	return response.data;
};
//Historico
export const getSalesHistory = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [
		,
		ciclo_id,
		familia_id,
		canal_venda_id,
		zona_venda_id,
		cliente_id,
		base_historica,
	] = queryKey;

	const response = await api.get('/venda/historico', {
		params: {
			canal_venda_id,
			familia_id,
			ciclo_id,
			base_historica: base_historica || '72',
			...(cliente_id
				? {
						cliente_id,
						apenas_clientes_que_possuem_relacionamento: true,
				  }
				: undefined),
			zona_venda_id,
		},
	});
	return response.data;
};
interface IGetCollaborationConsensusHistory {
	ano: number;
	ciclo: number;
	consenso: {
		mes_ano: string;
		consenso: number;
	}[];
	data_final: string;
	data_inicio: string;
	duracao: number;
	empresa_id: number;
	id: number;
	configuracaoColaboracao?: any;
}
export const getCollaborationConsensusHistory = async ({
	queryKey,
}: QueryFunctionContext<string[], any>): Promise<
	IGetCollaborationConsensusHistory[]
> => {
	const [
		,
		page,
		salesChannelId,
		familyId,
		cycleId,
		companyAreaId,
		aplicarCicloAnterior,
		salesZoneId,
	] = queryKey;
	return await getCollaborationConsensusHistoryMutate({
		cycleId,
		familyId,
		page,
		aplicarCicloAnterior,
		companyAreaId,
		salesChannelId,
		salesZoneId,
	});
};
export const getCollaborationConsensusHistoryMutate = async ({
	cycleId,
	familyId,
	page,
	aplicarCicloAnterior,
	companyAreaId,
	salesChannelId,
	salesZoneId,
}: UseCollaborationConsensuHistory): Promise<any> => {
	const response = await api.get(`/${page}/historico`, {
		params: {
			canal_venda_id: salesChannelId,
			familia_id: familyId,
			ciclo_id: cycleId,
			area_empresa_id: companyAreaId,
			aplicar_ciclo_anterior:
				typeof aplicarCicloAnterior === 'string'
					? aplicarCicloAnterior === 'true'
					: aplicarCicloAnterior,
			zona_venda_id: salesZoneId,
		},
	});
	return response.data;
};

export const getStatistic = async ({
	queryKey,
}: QueryFunctionContext<string[], any>): Promise<any> => {
	const [
		,
		familyId,
		productId,
		cycleId,
		typeStatistic,
		salesChannelId,
		salesZoneId,
		clientId,
		andStatisticInterval,
	] = queryKey;
	return getStatisticMutate({
		andStatisticInterval,
		cycleId,
		familyId,
		typeStatistic,
		clientId,
		productId,
		salesChannelId,
		salesZoneId,
	});
};
export const getStatisticMutate = async ({
	andStatisticInterval = {},
	cycleId,
	familyId,
	clientId,
	productId,
	salesChannelId,
	salesZoneId,
	typeStatistic,
}: IUseIStatistic): Promise<any> => {
	let paramsAdicionais: Record<string, any> = clientId
		? {
				cliente_id: clientId,
				apenas_clientes_que_possuem_relacionamento: true,
		  }
		: {};
	if (typeStatistic === 'mediaMovelQuantidade') {
		paramsAdicionais.mediaMovelQuantidadeIntervalo = 2;
	}

	const response = await api.get(`/venda/estatisticaVendasCiclo`, {
		params: {
			canal_venda_id: salesChannelId,
			zona_venda_id: salesZoneId,
			familia_id: familyId,
			produto_id: productId,
			ciclo_id: cycleId,
			...(typeStatistic ? { [typeStatistic]: true } : null),
			intervaloConfiancaQuantidade: true,
			...(typeof andStatisticInterval === 'string'
				? JSON.parse(andStatisticInterval)
				: andStatisticInterval),
		},
	});
	return response.data;
};

export const getStatisticIA = async (params): Promise<any> => {
	const {
		familyId: familia_id,
		cycleId: ciclo_id,
		salesChannelId: canal_venda_id,
		salesZoneId: zona_venda_id,
	} = params;

	const response = await api.get('/core/ai-prediction/prediction-result', {
		params: {
			ciclo_id,
			familia_id,
			...(canal_venda_id ? { canal_venda_id } : {}),
			...(zona_venda_id ? { zona_venda_id } : {}),
		},
	});
	return response.data;
};

export const getStatisticWithSimpleAverageAndHistoric = async ({
	queryKey,
}: QueryFunctionContext<any[], any>) => {
	const [
		,
		canal_venda_id,
		familia_id,
		ciclo_id,
		typeStatistic,
		desvioPadraoSigma,
		sigma,
		clienteId,
		zona_venda_id,
		apenas_clientes_que_possuem_relacionamento,
	] = queryKey;

	const othersParams: any = {};

	if (sigma?.includes?.('=')) {
		const [key, value] = sigma.split('=');
		othersParams[key] = value;
	}

	if (typeStatistic !== 'mediaSimplesQuantidade')
		othersParams.mediaSimplesQuantidade = true;

	const response = await api.get('/venda/estatisticaVendasCiclo', {
		params: {
			canal_venda_id,
			familia_id,
			ciclo_id,
			cliente_id: clienteId,
			[typeStatistic]: true,
			intervaloConfiancaQuantidade: true,
			desvioPadraoQuantidade: true,
			desvioPadraoSigma,
			...othersParams,
			zona_venda_id,
			apenas_clientes_que_possuem_relacionamento,
		},
	});
	return response.data;
};

export const getStatisticProduct = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [
		,
		canal_venda_id,
		product_id,
		ciclo_id,
		typeEstatistica,
		andEstatisticInterval,
	] = queryKey;
	const response = await api.get(
		`/venda/estatisticaVendasCiclo?canal_venda_id=${canal_venda_id}&produto_id=${product_id}&ciclo_id=${ciclo_id}&${typeEstatistica}=true&intervaloConfiancaQuantidade=true${andEstatisticInterval}`,
	);
	return response.data;
};
//Colaboração
export const createCollaboration = async (body) => {
	const response = await api.post('/colaboracao', body);
	return response.data;
};

//Colaboração Exceção
export const createCollaborationException = async (body) => {
	const response = await api.post('/colaboracao/excecao', body);
	return response.data;
};

export interface BodyCreateConsensus {
	ciclo_id?: string | number;
	familia_id: string;
	consensos: {
		mes_ano: string;
		quantidade: number;
	}[];
}
export const createConsensus = async (body: BodyCreateConsensus) => {
	const response = await api.post('/consenso', body);
	return response.data;
};

export const createException = async (body) => {
	const response = await api.post('/consenso/excecao', body);
	return response.data;
};

export const deleteExeption = async ({
	page,
	companyAreaId,
	salesChannelId,
	cycleId,
	productId,
	salesZoneId,
}: {
	page: string;
	cycleId?: string;
	salesChannelId?: string;
	productId?: string;
	companyAreaId?: string;
	salesZoneId?: string;
}) => {
	const response = await api.delete(
		`/${page}/excecao?canal_venda_id=${salesChannelId}&zona_venda_id=${salesZoneId}&produto_id=${productId}&ciclo_id=${cycleId}&area_empresa_id=${
			companyAreaId || ''
		}`,
	);

	return response.data;
};
interface IGetOpportunityByCycleList {
	mesesComExcecao: string[];
	oportunidades: Array<{
		listByMes: Record<string, number> | null;
		listByClient: Array<{
			cliente: {
				id: number;
				razao_social: string;
			};
			data: string;
			quantidade: number;
		}> | null;
		ano: number;
		ciclo: number;
	}>;
}
export const getOpportunityByCycleList = async ({
	queryKey,
}: QueryFunctionContext<
	string[],
	any
>): Promise<IGetOpportunityByCycleList> => {
	const [, ciclo_id, familia_id, canal_venda_id, zona_venda_id] = queryKey;
	const response = await api.get(`/oportunidade/oportunidadeByCiclo`, {
		params: {
			ciclo_id,
			canal_venda_id,
			familia_id,
			zona_venda_id,
		},
	});
	return response.data;
};

export const getAverageTicketByFamily = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, canal_venda_id, familia_id] = queryKey;
	const response = await api.get(
		`/ticketMedio/ticketmedioByCanalFamiliaProduto`,
		{
			params: {
				canal_venda_id,
				familia_id,
			},
		},
	);
	return response.data;
};
export const getAverageTicketByProduct = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, canal_venda_id, produto_id] = queryKey;
	const response = await api.get(
		`/ticketMedio/ticketmedioByCanalFamiliaProduto`,
		{
			params: {
				canal_venda_id,
				produto_id,
			},
		},
	);
	return response.data;
};

export const getCollaborationsByFamily = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id, familia_id] = queryKey;
	// Preparar url para requisição quando iplementarmos multiselect de canal de venda na tela de consenso
	const response = await api.get(`/colaboracao/colaboracaoByFamiliaId`, {
		params: {
			ciclo_id,
			familia_id,
		},
	});
	return response.data;
};

export const postAtaColaboracao = async (body) => {
	const response = await api.post('/colaboracao/ata', body);
	return response.data;
};

export const getAtaColaboracaoList = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [
		,
		ciclo_id,
		canal_venda_id,
		familia_id,
		area_empresa_id,
		zona_venda_id,
	] = queryKey;

	const response = await api.get(`/colaboracao/ata`, {
		params: {
			ciclo_id,
			canal_venda_id,
			familia_id,
			area_empresa_id,
			zona_venda_id,
		},
	});
	return response.data;
};

export const postAtaConsenso = async (body) => {
	const response = await api.post('/consenso/ata', body);
	return response.data;
};

export const getAtaConsensoList = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id, familia_id] = queryKey;

	const response = await api.get(
		`/consenso/ata?ciclo_id=${ciclo_id}&familia_id=${familia_id}`,
	);
	return response.data;
};

export const postFinalizar = async (page: string, body) => {
	await api.post(`/${page}/finalizar`, body);
};

export const editOpportunityConsensusException = async (body) => {
	const { ids, ciclo_id, mes_ano, familia_id, oportunidade, todos } = body;

	const requestBody = {
		ids,
		todos,
		editar: {
			oportunidade,
			mes_ano,
			familia_id,
			ciclo_id,
		},
	};

	const url = `/consenso/excecao/oportunidade`;
	const response = await api.post(url, requestBody);
	return response.data;
};

export const applyConsensusApportionment = async (body: {
	ciclo_id: number | string;
}) => {
	const response = await api.post('/consenso/aplicarRateioConsenso', body);

	return response.data;
};

export const collaborationConfig = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, cicloId, areaEmpresaId] = queryKey;
	if (!cicloId || !areaEmpresaId) return null;
	const response = await api.get(`/colaboracao/configuracao?`, {
		params: {
			ciclo_id: cicloId,
			area_empresa_id: areaEmpresaId,
		},
	});

	return response.data;
};

export const editCollaborationConfig = async (body: {
	ciclo_id: number;
	area_empresa_id: number;
	agrupar_canal_venda?: boolean;
	agrupar_zona_venda?: boolean;
}) => {
	const url = `/colaboracao/configuracao`;
	const response = await api.patch(url, body);
	return response.data;
};

export const getSumForSalesChannel = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, cicloId, areaEmpresaId, familiaId, canalVendaId] = queryKey;

	const response = await api.get(
		`/colaboracao/sumPorCanalVenda?ciclo_id=${cicloId}&area_empresa_id=${areaEmpresaId}&familia_id=${familiaId}&canal_venda_id=${canalVendaId}`,
	);

	return response.data;
};
export const colabFiltersCanalArea = async ({ queryKey }) => {
	const [, ciclo_id, area_empresa_id, canal_venda_id] = queryKey;
	if (!ciclo_id || !area_empresa_id) {
		return null;
	}
	const response = await api.get(`/colaboracao/filtros`, {
		params: {
			ciclo_id,
			area_empresa_id,
			canal_venda_id,
		},
	});
	return response.data;
};

export const colabFiltersFamilia = async ({ queryKey }) => {
	const [, ciclo_id, area_empresa_id, canal_venda_id, zona_venda_id] =
		queryKey;
	if (!ciclo_id) {
		return null;
	}
	const response = await api.get(`/colaboracao/filtros/familias`, {
		params: {
			ciclo_id,
			canal_venda_id,
			zona_venda_id,
			area_empresa_id,
		},
	});
	return response.data;
};
export const colabFiltersCiclo = async () => {
	const response = await api.get(`/ciclo/filtros/demanda`);
	return response.data;
};
