import api from '../api';

export const getSalesChannelById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get('canalVenda/canalVendaById', {
		params: { id },
	});
	return response.data;
};

export const updateSalesChannel = async (id: number, body) => {
	const response = await api.patch('canalVenda', body, {
		params: { id },
	});
	return response.data;
};

export const deleteSalesChannelByFilters = async (filters: any) => {
	const response = await api.post('canalVenda/deletarPorFiltros', filters);
	return response.data;
};

export const deleteSalesChannel = async (id: number) => {
	const response = await api.delete('canalVenda', { params: { id } });
	return response.data;
};
