import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataForecast = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Cód.',
		accessor: 'codigoForecast',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Código do Forecast',
	},
	{
		Header: 'Seq.',
		accessor: 'sequenciaCodigoForecast',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Sequência do Código do Forecast',
	},
	{
		Header: 'Competência',
		accessor: 'competencia',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cliente',
		accessor: 'cliente',
		type: 'autocomplete',
		optional: true,
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		filter: false,
		jsx: RowEditAndDelete,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'PRODUTO',
		accessor: 'produto',
	},
	{
		Header: 'FAMÍLIA',
		accessor: 'familia',
	},
	{
		Header: 'SUBFAMÍLIA',
		accessor: 'subfamilia',
	},
	{
		Header: 'MODELO',
		accessor: 'modelo',
	},
	{
		Header: 'COD.PRODUTO',
		accessor: 'codproduto',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const columnsDataPrevisao = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'Razão Social',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
	{
		Header: 'CNPJ/CPF',
		accessor: 'cnpjCpf',
		type: 'text',
		body: 'cnpj',
	},
];
