interface Toast {
	title: string;
	description: string;
	status: 'error' | 'success' | 'warning' | 'info';
	duration: number;
	isClosable: boolean;
}
interface ErrorMapping {
	message: string;
	redirectUrl: string;
	toast: Toast;
}
interface ErrorMappings {
	[statusCode: number]: ErrorMapping;
}
interface ErrorAction {
	message: string;
	redirectUrl: string;
	toast: Toast;
}
export const errorMappings: ErrorMappings = {
	401: {
		message: 'Unauthorized',
		redirectUrl: '/auth/login',
		toast: {
			title: 'Sessão expirada',
			description:
				'Faça login novamente para continuar usando a Easy360.',
			status: 'error',
			duration: 8000,
			isClosable: true,
		},
	},
	400: {
		message: 'Usuário não encontrado no banco!',
		redirectUrl: '/auth/login',
		toast: {
			title: 'Usuário não encontrado',
			description:
				'Faça login novamente para continuar usando a Easy360.',
			status: 'error',
			duration: 8000,
			isClosable: true,
		},
	},
	// Adicione outros mapeamentos de erros, se necessário
};
const getErrorAction = (
	statusCode: number,
	errorMessage: string,
): ErrorAction | null => {
	return errorMappings[statusCode]?.message === errorMessage
		? errorMappings[statusCode]
		: null;
};

export default getErrorAction;
