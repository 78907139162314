import { useQuery } from '@tanstack/react-query';
import { getOpportunityByCycleList } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface UseOpportunityByCycleList {
	cycleId: string;
	salesChannelId?: string;
	familyId: string;
	salesZoneId?: string;
}
export const useOpportunityByCycleList = ({
	cycleId,
	familyId,
	salesChannelId,
	salesZoneId,
}: UseOpportunityByCycleList) => {
	return useQuery(
		[
			'oportunidade',
			cycleId,
			familyId,
			salesChannelId || '',
			salesZoneId || '',
		],
		getOpportunityByCycleList,
		{
			refetchOnWindowFocus: false,
		},
	);
};
