import { Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import moment from 'moment';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { IconTrash } from '@tabler/icons';

import { useForm } from 'services/hooks/useForm';

import { useEffect } from 'react';
import { useTimeFormContext } from 'contexts/TimeFormContext';
import { secondsToHours } from './FormLancamentoHoraHora';

export default function FormAdicionarParada(props) {
	const { typologies, values, removeItem, updateItem, programada } = props;
	const { form, onChange, changeFields, cleanFields } = useForm(values);

	const hourMinutePattern = 'HH:mm';

	const {
		states: { resetBreakForm },
		setters: { setResetBreakForm },
	} = useTimeFormContext();

	useEffect(() => {
		if (form?.hora_inicio && form?.hora_fim) {
			const initialHour = moment(form?.hora_inicio, hourMinutePattern);
			const finalHour = moment(form?.hora_fim, hourMinutePattern);

			if (initialHour && finalHour) {
				// validando se data final deve ser alterada para o próximo dia
				// para o caso de 3 turnos.
				// Ex: inicial: 23:00 final 00:00 (próximo dia)
				if (initialHour.isAfter(finalHour)) finalHour.add({ days: 1 });

				const duration = moment
					.duration(finalHour.diff(initialHour))
					.asSeconds();

				if (duration > 0) {
					changeFields({
						...form,
						tempo_de_parada: secondsToHours(duration),
					});
				} else {
					changeFields({ ...form, tempo_de_parada: undefined });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.hora_inicio, form?.hora_fim]);

	useEffect(() => {
		updateItem(values?.key, form);
	}, [form, values?.key]);

	useEffect(() => {
		if (resetBreakForm) {
			cleanFields();
			setResetBreakForm(false);
		}
	}, [resetBreakForm]);

	return (
		<>
			{programada ? (
				<Flex
					direction='column'
					width={'100%'}
					mt='12px'
					mb='12px'
					key={values?.key}
				>
					<Flex
						gap={4}
						alignItems='center'
					>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Tipologia de Parada</FormLabel>
							<Input
								name={'tipologia_parada'}
								onChange={(e) => onChange(e)}
								h='44px'
								borderRadius={10}
								backgroundColor='secondaryGray.300'
								value={form?.tipologia_parada}
								disabled
							></Input>
						</FormControl>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Tempo de Parada</FormLabel>
							<Input
								value={form?.tempo_de_parada || ''}
								name='tempo_de_parada'
								type='time'
								h='44px'
								borderRadius={10}
								backgroundColor='secondaryGray.300'
								disabled
							/>
						</FormControl>
					</Flex>
				</Flex>
			) : (
				<Flex
					direction='column'
					width={'100%'}
					mt='12px'
					mb='12px'
					key={values?.key}
				>
					<Flex
						gap={4}
						alignItems='center'
					>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Tipologia de Parada</FormLabel>
							<Select
								data-test={
									'select-tipologia_de_parada-adicionar_parada-additional-lancamento_diario'
								}
								placeholder='Selecione'
								name={'tipologia_parada_id'}
								onChange={(e) => onChange(e)}
								h='44px'
								borderRadius={10}
								backgroundColor='secondaryGray.300'
								value={form?.tipologia_parada_id}
							>
								{typologies
									?.filter((option) => {
										return !option?.programada;
									})
									.map((option) => (
										<option
											key={option.id}
											value={option.id}
										>
											{option.tipologia_parada}
										</option>
									))}
							</Select>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
						>
							<FormLabel mb='0'>Observação</FormLabel>
							<Input
								data-test={
									'input-observacao-adicionar_parada-additional-lancamento_diario'
								}
								name='ata'
								h='44px'
								borderRadius={10}
								backgroundColor='secondaryGray.300'
								value={form?.ata}
								onChange={(e) => onChange(e)}
							/>
						</FormControl>

						<ButtonComponent
							type={'icon'}
							iconType={<IconTrash size='20px' />}
							onClick={removeItem}
						/>
					</Flex>

					<Flex gap={4}>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Hora Inicial</FormLabel>
							<Input
								data-test={
									'input-hora_inicial-adicionar_parada-additional-lancamento_diario'
								}
								name='hora_inicio'
								h='44px'
								borderRadius={10}
								type='time'
								onChange={(e) => onChange(e)}
								backgroundColor='secondaryGray.300'
								value={form?.hora_inicio}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Hora Final</FormLabel>
							<Input
								data-test={
									'input-hora_final-adicionar_parada-additional-lancamento_diario'
								}
								name='hora_fim'
								h='44px'
								borderRadius={10}
								type='time'
								onChange={(e) => onChange(e)}
								backgroundColor='secondaryGray.300'
								value={form?.hora_fim}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Tempo de Parada</FormLabel>
							<Input
								data-test={
									'input-tempo_de_parada-adicionar_parada-additional-lancamento_diario'
								}
								value={form?.tempo_de_parada || ''}
								name='tempo_de_parada'
								h='44px'
								borderRadius={10}
								backgroundColor='secondaryGray.300'
								disabled
							/>
						</FormControl>
					</Flex>
				</Flex>
			)}
		</>
	);
}
