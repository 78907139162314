import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
} from '@chakra-ui/react';

import Table, { generateRowsMonths, Row } from 'components/dataTables/Table';

import Multiselect from 'components/multiSelect/Multiselect';
import { useEffect, useState } from 'react';

import moment from 'moment';
import { monthsConvert } from '../../pages/admin/cadastros/capacidade/diasUteis/variables/months';
import { useQuery } from '@tanstack/react-query';
import { getWorkingDaysPast } from 'services/api/requests/workingDays';

export const WorkingDaysModal = (props) => {
	const { isOpen, onClose, tamanhoCiclo } = props;

	const columnFirtDataColaboracaoCadastrar = {
		Header: 'Ano',
		accessor: 'titleColumn',
		type: 'text',
	};

	const { data: dataWorkingDays, isFetched } = useQuery(
		['working-days-list-past'],
		getWorkingDaysPast,
		{
			refetchOnWindowFocus: false,
		},
	);

	const [data, setData] = useState(
		dataWorkingDays?.sort((current, next) => {
			return current.ano - next.ano;
		}),
	);
	const [dataView, setDataView] = useState<any>();

	useEffect(() => {
		setData(
			dataWorkingDays?.sort((current, next) => {
				return current.ano - next.ano;
			}),
		);
	}, [dataWorkingDays]);

	useEffect(() => {
		if (data) {
			data?.forEach((element) => {
				yearsList.push({
					value: element.ano,
					label: String(element.ano),
				});
			});
			setDataView(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const yearsList: [{}] = [{}];

	data?.forEach((element) => {
		yearsList.push({ value: element.ano, label: String(element.ano) });
	});

	const options: any[] = [];

	for (let year of yearsList) {
		options.push(year);
	}

	options.shift();

	const onChangeAge = (e) => {
		const filterAges: any[] = [];
		for (let a of e) {
			filterAges.push(a.value);
		}

		const dataFilter = data.filter((ano) => {
			return filterAges.includes(ano.ano);
		});
		setDataView(dataFilter);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'6xl'}
			isCentered
			scrollBehavior={'inside'}
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
				minH={'300px'}
			>
				<ModalCloseButton />
				<ModalBody>
					{isFetched && (
						<>
							<Heading
								color='gray.700'
								mb={2}
								size='md'
							>
								<Flex
									mb='8'
									w={'fit-content'}
								>
									<FormControl
										mr='5px'
										className='filter-bar'
									>
										<FormLabel>Ano</FormLabel>
										<Multiselect
											onChange={onChangeAge}
											defaultValue={options[0]}
											options={options}
											size={'medium'}
											placeholder='Selecione'
										/>
									</FormControl>
								</Flex>
							</Heading>
							<Accordion
								defaultIndex={[0]}
								allowMultiple
							>
								{dataView?.map((op) => {
									const feriados: Row = {
										type: 'text',
										titleColumn: 'Feriados',
										1: op[1].feriados,
										2: op[2].feriados,
										3: op[3].feriados,
										4: op[4].feriados,
										5: op[5].feriados,
										6: op[6].feriados,
										7: op[7].feriados,
										8: op[8].feriados,
										9: op[9].feriados,
										10: op[10].feriados,
										11: op[11].feriados,
										12: op[12].feriados,
									};

									const diasUteis: Row = {
										type: 'text',
										titleColumn: 'Dias Úteis',
										1: op[1].dias_uteis,
										2: op[2].dias_uteis,
										3: op[3].dias_uteis,
										4: op[4].dias_uteis,
										5: op[5].dias_uteis,
										6: op[6].dias_uteis,
										7: op[7].dias_uteis,
										8: op[8].dias_uteis,
										9: op[9].dias_uteis,
										10: op[10].dias_uteis,
										11: op[11].dias_uteis,
										12: op[12].dias_uteis,
									};

									const colaboradores: Row = {
										type: 'text',
										titleColumn: 'Colaboradores',
										1: op[1].colaboradores,
										2: op[2].colaboradores,
										3: op[3].colaboradores,
										4: op[4].colaboradores,
										5: op[5].colaboradores,
										6: op[6].colaboradores,
										7: op[7].colaboradores,
										8: op[8].colaboradores,
										9: op[9].colaboradores,
										10: op[10].colaboradores,
										11: op[11].colaboradores,
										12: op[12].colaboradores,
									};

									const formatDate = (date) => {
										const mes = moment(date).month() + 1;
										return monthsConvert[mes];
									};

									const mes: Row = {
										type: 'text',
										titleColumn: 'Mês',
										1: formatDate(op[1].mes_ano),
										2: formatDate(op[2].mes_ano),
										3: formatDate(op[3].mes_ano),
										4: formatDate(op[4].mes_ano),
										5: formatDate(op[5].mes_ano),
										6: formatDate(op[6].mes_ano),
										7: formatDate(op[7].mes_ano),
										8: formatDate(op[8].mes_ano),
										9: formatDate(op[9].mes_ano),
										10: formatDate(op[10].mes_ano),
										11: formatDate(op[11].mes_ano),
										12: formatDate(op[12].mes_ano),
									};

									const collumnsMounths = generateRowsMonths(
										String(op.ano),
										12,
										tamanhoCiclo,
										false,
									);
									const columnsData = [
										columnFirtDataColaboracaoCadastrar,
										...collumnsMounths,
									];

									return (
										<AccordionItem>
											<h2>
												<AccordionButton>
													<Box
														flex='1'
														textAlign='left'
													>
														{op.ano}
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												<Table
													name={'Colaboração'}
													columnsData={columnsData}
													tableData={[
														mes,
														feriados,
														diasUteis,
														colaboradores,
													]}
												/>
											</AccordionPanel>
										</AccordionItem>
									);
								})}
							</Accordion>
						</>
					)}
				</ModalBody>

				<ModalFooter></ModalFooter>
			</ModalContent>
		</Modal>
	);
};
