import api from "../../api";

export const getDemandDashboardData = async ({ queryKey }) => {
    const [_key, params] = queryKey
    const {cicloId, canalVendaId, familiaId} = params
    let url
    if(cicloId && canalVendaId && familiaId) {
        url = `/dashboardDemanda/dadosDashboard?ciclo_id=${cicloId}&canal_venda_id=${canalVendaId}&familia_id=${familiaId}`
    } else if(cicloId && canalVendaId) {
        url = `/dashboardDemanda/dadosDashboard?ciclo_id=${cicloId}&canal_venda_id=${canalVendaId}`
    } else if(cicloId && familiaId) {
        url = `/dashboardDemanda/dadosDashboard?ciclo_id=${cicloId}&familia_id=${familiaId}`
    } else {
        url = `/dashboardDemanda/dadosDashboard?ciclo_id=${cicloId}`
    }
    const response = await api.get(url)
    return response.data
}
