import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarHierarquia from 'components/forms/FormCadastrarEditarHierarquia';

export default function CadastrarMercados() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const [formInitialState] = useState<any>({
		mercado: '',
		canal_venda: '',
		forca_venda: '',
		zona_venda: '',
		membro: '',
	});

	const helpCadastrarMercados = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Hierarquia',
			firstParagraph:
				'Para cadastrar uma nova hierarquia comercial, entre pelo menos com um Canal de Venda, Zona de Venda e Membro. Os demais campos são opcionais.',
			secondParagraph:
				'Tenha certeza de ter cadastrado previamente os membros em Cadastros > Comercial > Membros.',
			thirdParagraph:
				'Observação: cada membro pode possuir apenas uma zona de venda dentro do seu mercado de atuação. Mas, o mesmo membro pode pertencer a mais de um mercado de atuação!',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarMercados);
	}, [helpCadastrarMercados, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarHierarquia
					initialState={formInitialState}
				/>
			</Card>
		</Flex>
	);
}
