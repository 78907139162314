import { getMemberById } from 'services/api/requests/commercial';
import { useQuery } from '@tanstack/react-query';

export const useGetMemberById = (id: number) => {
	return useQuery(['memberById', id], getMemberById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
