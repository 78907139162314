import { Redirect, Route, Switch } from "react-router-dom";
// import routes from "../../routes";
import "mapbox-gl/dist/mapbox-gl.css";

import { useGlobalContext } from "contexts/GlobalContext";
import { useEffect, useState } from "react";
import Blank from "./types/Blank";
import { Flex, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import CollaborationConsenting from "./types/CollaborationConsenting";
import Default from "./types/Default";
import Plans from "./types/Plans";
import Auth from "./types/Auth";
import Register from "./types/Register";


// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;

  const { routes, companyName, pathCompanyName } = useGlobalContext()
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (routes[i].name.includes("Editar") && window.location.href.includes(routes[i].layout + routes[i].editPath)) {
          return routes[i]
        } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i];
        }
      }
    }
    // console.log(activeRoute)
    return activeRoute;
  };
  const TemplatesPage = {
    default: Default,
    collaborationConsenting: CollaborationConsenting,
    blank: Blank,
    plans: Plans,
    auth: Auth,
    register: Register,
  }
  const [templateKey, setTeplateKey] = useState("default")
  const route = getActiveRoute(routes)
  useEffect(() => {
    // alert(route.templatePage || "default")
    setTeplateKey(route.templatePage || "default")
  }, [route])

  const getRoutes = (routes) => {
    // const route = getActiveRoute(routes)

    return routes.map((prop, key) => {
      // const templatePageKey = prop.templatePage || "default"
      // const Template = TemplatesPage[templatePageKey]
      if (prop.layout === `/${pathCompanyName}`) {
        // 🔒 Retorno de rota para usuários autenticados e com permissões de acesso, caso queira validar permissões de acesso descomente o código abaixo e comente o return do código acima
        
        if (prop.visualization) {
          return (<Route
            route={prop.route}
            path={prop.layout + prop.path}
            component={
              prop.component
            }
            exact={true}
            key={key}
          />
          )
        } else {
          return (<Route
            path={prop.layout + prop.path}
            component={
              () => {
                return (
                  <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
                    <Card px='0px'>
                      <Flex flexDirection={'row'} justifyContent={'center'} mt={'20px'} me={'20px'}>
                        <Text>
                          Você não tem permissão para acessar esta página.
                        </Text>
                      </Flex>
                    </Card>
                  </Flex>
                )
              }
            }
            exact={true}
            key={key}
          />
          )
        }
        // prop.path.includes('col') &&  console.log("prop", prop, template, templateKey) 
        // console.log(template === templatePageKey, template, templatePageKey)
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const Template = TemplatesPage[templateKey]
  return (
    <>
      <Switch>
        <Template route={route}>
          {getRoutes(routes)}
        </Template>
        {/* {getSwitchRoutes(routes)} */}
        <Redirect from={`/${pathCompanyName}`} to={`/${pathCompanyName}/cadastros/produto/produtos`} />
      </Switch>
    </>
  );
}