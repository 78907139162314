export const zonaVendaDataDelete = {
	provider: 'salesZone',
	toastSuccessTitle: 'Excluído com sucesso!',
	toastSuccessDescription: 'A zona de venda foi excluída com sucesso.',
	toastErrorTitle: 'Erro ao excluir zona de venda!',
	title: 'Deseja realmente excluir esta zona de venda?',
	text: [
		'Você está prestes a excluir esta zona de venda da sua base de cadastros. Caso exista associação de cidade ou membro, esta será desassociada. Excluir esta zona de venda resultará na remoção dos seguintes itens vinculados a ela:',
		'REPLACE_ME',
		'Você tem certeza que deseja excluir?',
	],
	invalidate: 'zonaVenda-list',
};

export const zonaVendaDeleteMessage = [
	'Você está prestes a excluir esta(s) zona(s) de venda(s) da sua base de cadastros. Caso exista associação de cidade ou membro, esta será desassociada. Excluir estas zona(s) de venda(s) resultará na remoção dos seguintes itens vinculados a ela(s):',
	'REPLACE_ME',
];

export const zonaVendaDataDeleteAll = {
	provider: 'salesZone',
	toastSuccessTitle: 'Registros excluídos com sucesso!',
	toastSuccessDescription: 'As zonas de vendas foram excluídas com sucesso',
	toastErrorTitle: 'Erro ao excluir zonas de vendas!',
	invalidate: 'zonaVenda-list',
};
