import RegistrarUsuario from 'pages/auth/signIn/RegistrarUsuario';
import AutenticacaoPage from 'pages/auth/autenticacao/index';

const routesAuth = [
	// rota para tela alternativa de criação de conta na easy360.
	{
		name: 'Registrar',
		layout: '/auth',
		path: '/cadastros/usuarios/registrar',
		exact: false,
		component: RegistrarUsuario,
		notShowItemMenu: true,
	},
	{
		name: 'Autenticação',
		path: '/login',
		layout: '/auth',
		component: AutenticacaoPage,
		// exact: false,
		// notShowItemMenu: true
	},
];

export default routesAuth;
