import { Cycle } from 'models/cycle.model';
import { MonthOption } from 'models/option.model';
import moment from 'moment';
import 'moment/locale/pt';

moment.updateLocale('pt', {
	months: [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	],
});

export default function cycleMonthOptions(
	selectedYear: number,
	cycle_id: number,
	cycles: Cycle[],
): MonthOption[] {
	const cycle = cycles.find((cycle) => cycle.id === cycle_id);
	if (cycle) {
		const type = 'months';
		let months = [] as MonthOption[];
		for (let i = 0; i <= cycle.duracao - 1; i++) {
			const date = moment(cycle.data_inicio)
				.startOf('month')
				.add(i, type);
			months.push({
				label: date.locale('pt').locale('pt').format('MMMM'),
				value: date.format('YYYY-MM-DD'),
				month_number: Number(date.format('M')),
			} as MonthOption);
		}
		return months;
	}
	return [];
}
