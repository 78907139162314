import { useQuery } from '@tanstack/react-query';
import { getFilterDemandFamily } from 'services/api/requests/colabAndConsensus/colabAndConsensusFilters';

export interface UseGetSelectedFilterDemandFamily {
	pageName: string;
	cycleId?: number | string;
	companyAreaId?: number | string;
	salesChannelId?: number | string;
	salesZoneId?: number | string;
	size?: number;
	page?: number;
}
const keys = [
	'pageName',
	'cycleId',
	'companyAreaId',
	'salesChannelId',
	'salesZoneId',
	'size',
	'page',
];
export const getKeysValuesSelectedFilterDemandFamily = (
	query: UseGetSelectedFilterDemandFamily,
	filter = true,
) => {
	const key = [
		'collaboration-consensus-by-familia',
		...keys.map((key) => (query[key] ? String(query[key]) : '')),
	];
	if (filter) {
		return key.filter((key) => key !== '');
	}
	return key;
};
export const useGetFilterDemandFamily = (
	query: UseGetSelectedFilterDemandFamily,
) => {
	return useQuery(
		getKeysValuesSelectedFilterDemandFamily(query, false),
		getFilterDemandFamily,
		{
			refetchOnWindowFocus: false,
		},
	);
};
