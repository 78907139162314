import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import {
	IconCaretDown,
	IconCaretRight,
	IconCheck,
	IconChecks,
} from '@tabler/icons';
import { PropsContainerTreeView } from 'components/treeView/TreeView';
import { FilterOption, useContextColaboracaoConsenso } from '../../context';
import { ItemProduct } from './ItemProduct';
export interface PropsItemFamily {
	setOpen: (id: string) => void;
	setFamilyId: (id: string) => void;
	original: FilterOption;
}

export function ItemFamily(props: PropsContainerTreeView<PropsItemFamily>) {
	const { extra, idElement, original, data, isOpen, id } = props;
	const { setOpen, setFamilyId } = extra as PropsItemFamily;
	const { familyId } = useContextColaboracaoConsenso();

	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const buttonBgColor = useColorModeValue('easyBrandMenu.100', 'navy.800');
	return (
		<Box
			backgroundColor={
				+idElement === +familyId ? 'secondaryGray.300' : buttonBgColor
			}
		>
			<Flex
				borderBottom={'1px solid'}
				borderColor={borderColor}
				paddingY={2}
				alignItems={'center'}
				fontSize={'16px'}
				fontWeight={700}
				color={'easyBLUE.300'}
				flexGrow={1}
			>
				<Flex w={'100%'}>
					<Flex>
						<Icon
							ml='12px'
							mr={3.5}
							onClick={() => setOpen(String(id))}
							as={isOpen ? IconCaretDown : IconCaretRight}
							cursor='pointer'
							_hover={{
								color: 'easyBLUE.300',
							}}
							boxSize={5}
							color={'secondaryGray.600'}
						/>
					</Flex>
					<Text
						flexGrow={1}
						onClick={() => {
							setFamilyId(original.value);
						}}
						cursor='pointer'
					>
						{data}
					</Text>
					<Icon
						ml='12px'
						mr={3.5}
						as={original?.manual_filling ? IconChecks : IconCheck}
						cursor='pointer'
						boxSize={5}
						color={
							original?.check ? 'green.500' : 'secondaryGray.100'
						}
					/>
				</Flex>
			</Flex>

			{isOpen && <ItemProduct familyId={idElement} />}
		</Box>
	);
}
