
import api from "../api";

export const getProductionTarget = async (age, month) => {
    const response = await api.get(`metaProducao/metaProducaoByMonthYear?month=${month}&year=${age}`);
    return response.data;
}

export const editProductionTarget = async (body) => {
    const response = await api.post(`metaProducao/criaAtualizaMetasProducao`, body);
    return response.data;
}