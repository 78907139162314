import { useQuery } from '@tanstack/react-query';
import { getStatisticWithSimpleAverageAndHistoric } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
interface IStatisticWithSimpleAverageAndHistoric {
	salesChannelId?: string;
	familyId?: string;
	cycleId: string;
	typeStatistic: string;
	sigmaStandardDeviation: string;
	sigma: string;
	clientId: string;
	salesZoneId: string;
	onlyCustomerWithRelationship?: boolean;
}
export const useStatisticWithSimpleAverageAndHistoric = ({
	clientId,
	salesZoneId,
	sigma,
	sigmaStandardDeviation,
	typeStatistic,
	cycleId,
	familyId,
	salesChannelId,
	onlyCustomerWithRelationship,
}: IStatisticWithSimpleAverageAndHistoric) => {
	return useQuery(
		[
			'statistic',
			salesChannelId ?? '',
			familyId ?? '',
			cycleId ?? '',
			typeStatistic,
			sigmaStandardDeviation,
			sigma,
			clientId,
			salesZoneId ?? '',
			onlyCustomerWithRelationship,
		],
		getStatisticWithSimpleAverageAndHistoric,
		{
			refetchOnWindowFocus: false,
			retry: false,
		},
	);
};
