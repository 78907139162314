import {
	Box,
	Button,
	Flex,
	FormControl,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Switch,
	Text,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import { IconCloudUpload } from '@tabler/icons';
import Dropzone from 'components/dropzone/Dropzone';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdDownload } from 'react-icons/md';
import { useGetLineRestrictions } from 'services/queryClient/wrapperHooks/useGetLineRestrictions';
import { FileUploadErrorsModal } from './FileUploadErrorsModal';
import { LoadingUpload } from './LoadingUpload';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { InputFormLabel } from 'components/fields/InputFormLabel';

interface FileUploadModalProps {
	headerText: string;
	module: string;
	isOpen: boolean;
	onClose: () => void;
	useDownloadTemplate: () => UseQueryResult<any, unknown>;
	useUpload: (
		setSucessUpload: any,
		openTableModal: any,
		isOpen: any,
		onClose: any,
	) => UseMutationResult<any, unknown, void, unknown>;
	stringInvalidQuery:
		| 'orcamento-list'
		| 'carteiraPedido-list'
		| 'estoque-list'
		| 'venda-list'
		| 'produto-list'
		| 'oportunidade-list'
		| 'forecastDemanda-list'
		| 'hourly'
		| 'production-roadmap-products-list'
		| 'apportionment-listById'
		| 'tipologiaParadas-list'
		| 'cliente-relacionado'
		| 'hierarquia-comercial';
	columnsData: any[];
	showLineRestricts?: boolean;
	hasBaseData?: boolean;
	setSucessImport?: React.Dispatch<React.SetStateAction<boolean>>;
	handleOnUploadSuccess?: any;
}
export const FileUploadModal = (props: FileUploadModalProps) => {
	const {
		isOpen,
		onClose,
		headerText,
		module,
		useDownloadTemplate,
		useUpload,
		stringInvalidQuery,
		showLineRestricts,
		columnsData,
		hasBaseData,
		setSucessImport,
		handleOnUploadSuccess,
	} = props;

	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [sucessUpload, setSucessUpload] = useState(false);
	const [nameFile, setNameFile] = useState('');
	// Estado para controlar a sobrescrita de dados
	const [overwriteExistingData, setOverwriteExistingData] =
		useState<boolean>(false);

	const loadingModal = useDisclosure();
	const {
		isOpen: IsOpenTableModal,
		onClose: onCloseTableModal,
		onOpen: onOpenTableModal,
	} = useDisclosure();

	const easyRED = useColorModeValue('easyRED.300', 'easyRED.200');
	const easyBLUE = useColorModeValue('easyBLUE.300', 'easyBLUE.200');
	const { refetch, isFetching } = useDownloadTemplate();

	// Verificar de passar essa função para o componente index, ao inves de deixar dentro do modal
	let downloadAction = async (event) => {
		event.preventDefault();
		await refetch().then((res) => {
			console.log(res);
			const url = res.data.link;
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', res.data.nome_arquivo);
			document.body.appendChild(link);
			link.click();

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		});
	};

	const {
		mutateAsync,
		data: uploadData,
		isSuccess,
	} = useUpload(setSucessUpload, onOpenTableModal, isOpen, onClose);
	const { data: lineRestrictions } = useGetLineRestrictions();

	// Função para controlar o estado de sobrescrita de dados
	const handleOverwriteExistingData = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		setOverwriteExistingData(event.target.checked);
	};

	useEffect(() => {
		if (setSucessImport && sucessUpload) {
			setSucessImport(true);
		}
	}, [setSucessImport, sucessUpload]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={'3xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={'30px'}
					aria-modal='false'
				>
					<ModalHeader
						textColor={easyBLUE}
						fontSize={'24px'}
						fontWeight='700'
					>
						{headerText}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{hasBaseData && (
							<FormControl
								mr='5px'
								mb='20px'
							>
								<Flex
									alignItems='center'
									gap={2}
								>
									<Switch
										data-test='switch-sobrescrever_os_dados_existentes-importar-modal'
										colorScheme='green'
										size='sm'
										onChange={(e) =>
											handleOverwriteExistingData(e)
										}
										isChecked={overwriteExistingData}
										name={'overwriteExistingData'}
									/>
									<InputFormLabel
										label='Sobrescrever os dados existentes.'
										labelTooltip='Todos os dados que tiverem informações diferentes das existentes serão sobrescritos e atualizados de acordo com os novos dados enviados no arquivo.'
									/>
								</Flex>
							</FormControl>
						)}
						<Dropzone
							uploadPercentage={uploadPercentage}
							openLoading={loadingModal.onOpen}
							mutateAsync={mutateAsync}
							stringInvalidQuery={stringInvalidQuery}
							onClose={onClose}
							onCloseDropzone={loadingModal.onClose}
							isOpen={isOpen}
							onOpenTableModal={onOpenTableModal}
							sucessUpload={sucessUpload}
							setUploadPercentage={setUploadPercentage}
							setNameFile={setNameFile}
							overwriteExistingData={overwriteExistingData}
							setOverwriteExistingData={setOverwriteExistingData}
							setSucessUpload={setSucessUpload}
							handleOnUploadSuccess={handleOnUploadSuccess}
							content={
								<Box>
									<Icon
										as={IconCloudUpload}
										w='80px'
										h='80px'
										color={easyRED}
										mb={'30'}
									/>
									<Flex
										justify='center'
										mx='auto'
										mb='12px'
									>
										<Text
											fontSize='20px'
											fontWeight='700'
											color={easyBLUE}
										>
											Arraste seu arquivo ou{' '}
											<Text
												as={'span'}
												color={easyRED}
											>
												clique para selecionar
											</Text>
										</Text>
									</Flex>
									<Text
										fontSize='14px'
										fontWeight='500'
										color='secondaryGray.500'
									>
										Você pode baixar um{' '}
										<Text
											as={'span'}
											color={easyBLUE}
										>
											modelo de arquivo para importação no
											botão abaixo
										</Text>
									</Text>
									{showLineRestricts && (
										<Flex flexDirection={'column'}>
											<Text
												fontSize='12px'
												fontWeight='500'
												color='secondaryGray.500'
											>
												Quantidade de importações total:{' '}
												<Text
													as={'span'}
													color={easyBLUE}
												>
													{
														lineRestrictions?.quantidadeLinhasImportacaoArquivosTotal
													}
												</Text>
											</Text>
											<Text
												fontSize='12px'
												fontWeight='500'
												color='secondaryGray.500'
											>
												Quantidade de importações do
												dia:{' '}
												<Text
													as={'span'}
													color={easyBLUE}
												>
													{
														lineRestrictions?.quantidadeLinhasImportacaoArquivosPorDia
													}
												</Text>
											</Text>
										</Flex>
									)}
								</Box>
							}
						/>
					</ModalBody>
					<ModalFooter>
						{isFetching ? (
							<Button
								borderColor={'easyBLUE.150'}
								variant={'outline'}
								mr={'5px'}
								isLoading
								loadingText='Fazendo download'
								borderRadius={'100px'}
								textColor={easyBLUE}
							>
								Fazendo download
							</Button>
						) : (
							<Button
								borderColor={'easyBLUE.150'}
								variant={'outline'}
								mr={'5px'}
								leftIcon={<MdDownload />}
								onClick={downloadAction}
								borderRadius={'100px'}
								textColor={easyBLUE}
							>
								Baixar Template
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
			<>
				{uploadPercentage > 0 && (
					<LoadingUpload
						uploadPercentage={uploadPercentage}
						isOpen={loadingModal.isOpen}
						onClose={loadingModal.onClose}
						headerText={headerText}
						module={module}
					/>
				)}
				{!sucessUpload && isSuccess && (
					<FileUploadErrorsModal
						columnsData={columnsData}
						isOpen={IsOpenTableModal}
						onClose={onCloseTableModal}
						headerText={headerText}
						uploadData={uploadData}
						nameFile={nameFile}
					/>
				)}
			</>
		</>
	);
};
