import { calculateIrogMetrics } from 'services/api/requests/resources';
import { useMutation } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';

export const useCalculateIrogMetrics = (
	body,
	setDataChart,
	setIrogDataChart,
	setShowCharts,
	setDataChartAfterCalculate,
) => {
	const toast = useToast();

	return useMutation(() => calculateIrogMetrics(body), {
		onSuccess: (success) => {
			setDataChart([
				Number(success?.disponibilidade?.toFixed(2)),
				Number(success?.desempenho?.toFixed(2)),
				Number(success?.qualidade?.toFixed(2)),
			]);
			setIrogDataChart(Number(success?.irog?.toFixed(2)));
			setShowCharts(true);
			setDataChartAfterCalculate(success);
		},
		onError: (error: any, variables, context) => {
			toast({
				title: 'Erro ao calcular irog!',
				description:
					error.response.data[0].details.constraints.isNotEmpty,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
