// Chakra imports
import { Box, Flex, Portal, useDisclosure } from "@chakra-ui/react";
// Layout components
import ButtonCalendly from "components/calendly/ButtonCalendly";
import { useState } from "react";
import Navbar from "../../../components/navbar/NavbarAdmin";

import "mapbox-gl/dist/mapbox-gl.css";


import { NavbarContext } from "contexts/NavbarContext";
import SidebarHelpProvider from "contexts/providers/SidebarHelpProvider";
import "mapbox-gl/dist/mapbox-gl.css";
// Custom Chakra theme
export default function CollaborationConsenting(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [sidebarHelpState, setSidebarHelpState] = useState('closed')

  // document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  const route = props.route
  const goBack = route.layout + route.path.split("/").slice(0, -1).join("/")
  return (<Flex flexDirection={"column"}>
    <NavbarContext.Provider value={{ sidebarHelpState, setSidebarHelpState }}>
      <SidebarHelpProvider>
        <Box
        //>>>DESCOMENTAR 
        // display={'flex'}
        >
          {/* <Box > */}
          {/* </Box> */}
          {/* TABELA */}
          <Box
            minHeight='100vh'
            height='100%'
            overflow='auto'
            position='relative'
            w={`calc(100% - ${sidebarHelpState === "opened" ? "290px" : "0px"})`}
            // // position='fixed'
            maxHeight='100%'
            // //IMPLEMENTAÇÃO DO HELP-SIDEBAR
            transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
            transitionDuration='.2s, .2s, .35s'
            transitionProperty='top, bottom, width'
            transitionTimingFunction='linear, linear, ease'>
            <Portal>
              <Box>
                <Navbar
                  sidebarHelpState={sidebarHelpState}
                  setSidebarHelpState={setSidebarHelpState}
                  onOpen={onOpen}
                  logoText={"Horizon UI Dashboard PRO"}
                  brandText={route.name}
                  // secondary={getActiveNavbar(routes)}
                  goBack={goBack}
                  fixed={fixed}
                  activeRoute={route}
                  {...rest}
                />
              </Box>
              <ButtonCalendly calendlyLink="https://calendly.com/suporte-easy360"></ButtonCalendly>

            </Portal>
            <Box
              mx='auto'
              p={{ base: "20px", md: "30px" }}
              pe='20px'
              minH='100vh'
              pt='50px'
              marginTop={"50px"}
            >
              {props.children}
            </Box>
            {/* {goBack} */}
          </Box>
        </Box>
      </SidebarHelpProvider>
    </NavbarContext.Provider>
  </Flex>
  );
}
