import api from '../../api';
export const getProductionDashboardOptionsFilter = async (params?) => {
	const {
		queryKey: [, othersParams],
	} = params;
	const response = await api.post('/dashboardProducao/filtros', {
		anos: othersParams['anos'],
		meses: othersParams['meses'],
		dias: othersParams['dias'],
		turnos: othersParams['turnos'],
		tipoParada: othersParams['tipoParada'],
		ids_recursos: othersParams['recursos'],
		ids_familias: othersParams['familias'],
	});
	return response.data;
};
