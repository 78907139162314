import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataCidades } from './variables/columnsDataCidades';
import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';

interface Cities {
	id: number;
	uf: string;
	nome_cidade: string;
	nome_microrregiao: string;
	nome_mesorregiao: string;
	nome_zona_venda: string;
}

export default function CadastroCidades() {
	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataCidades, []);

	const helpCities: SidebarHelp = useMemo(
		() => ({
			title: 'Cidades',
			firstParagraph:
				'Nesta tela é possível visualizar a Hierarquia de Cidades, refletindo as indicações de atendimento (conforme estrutura comercial definida) por municípios, delimitando a atuação geográfica.',
			secondParagraph:
				'Conforme hierarquia comercial definida, deve-se associar as Zonas de Venda com a hierarquia de cidades, delimitando a atuação geográfica do comercial. Acesse o botão “Associar Zona de Venda” para definir estruturas de cidade com zonas de venda.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const dataDelete = {
		provider: 'city',
		toastSuccessTitle: 'Excluida com sucesso!',
		toastSuccessDescription: 'A cidade foi excluida com sucesso.',
		toastErrorTitle: 'Erro ao excluir cidade!',
		title: 'Deseja realmente excluir esta cidade?',
		text: [
			'Você está prestes a excluir esta associação de cidade da sua base de cadastros.',
			'',
			'Você tem certeza que deseja excluir?',
		],
		invalidate: 'zonaVenda-list',
	};

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estas associações de cidade da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'city',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'As cidades foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir cidades!',
			invalidate: 'zonaVenda-list',
		}),
		[],
	);

	const goToCadastroCidades = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/cidades/criar`);
	}, [history, pathCompanyName]);

	useEffect(() => {
		setDataSidebar(helpCities);
	}, [helpCities, setDataSidebar]);

	const renderRow = useCallback((city: Cities) => {
		return {
			id: city?.id,
			uf: city?.uf,
			municipio: city?.nome_cidade,
			microrregiao: city?.nome_microrregiao,
			mesorregiao: city?.nome_mesorregiao,
			zonaVenda: city?.nome_zona_venda,
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonComponent
					data-test='button-cadastrar-cidades'
					type={'primary'}
					title={'Associar Zona de Venda'}
					onClick={goToCadastroCidades}
				/>
			</Flex>
		);
	}, [goToCadastroCidades]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-cidades'
				module='zonaVenda'
				linkEditForm={`/${pathCompanyName}/cadastros/cidade/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='zonaVenda/zonaVendaCidade'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				tableTest='tabela-cidades'
			/>
		);
	}

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
