import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { ColorProps, useColorModeValue } from '@chakra-ui/system';
import ModalAtaArea from '../ModalAtaArea';
import { currencyToNumber } from '../../../../../../utils/functions/currencyToNumber';

type TagInformation = {
	color: ColorProps['color'];
	text: string;
};
type TypesTagInformation =
	| 'uniform'
	| 'optimistic'
	| 'moderate'
	| 'pessimistic'
	| 'invalid';
const tagsInforms: Record<TypesTagInformation, TagInformation> = {
	uniform: {
		color: '#505050',
		text: 'Uniforme',
	},
	optimistic: {
		color: '#01B574',
		text: 'Otimista',
	},
	moderate: {
		color: '#BB9200',
		text: 'Moderado',
	},
	pessimistic: {
		color: '#B01A2E',
		text: 'Pessimista',
	},
	invalid: {
		color: '#505050',
		text: 'Sem colaboração',
	},
};
const getTag = (
	value: number,
	extremesValues?: ExtremesValues,
): TypesTagInformation => {
	if (!extremesValues) return 'invalid';
	const { max, min, secondMax } = extremesValues;
	if (isNaN(value) || value === 0) {
		return 'invalid';
	}
	if (secondMax && secondMax === value) return 'uniform';
	if (value === max) {
		return 'optimistic';
	}
	if (value === min) {
		return 'pessimistic';
	}
	return 'moderate';
};
interface ExtremesValues {
	max: number;
	min: number;
	secondMax: number;
}
export const CellArea = (
	{ cell, row, column },
	ComponentRow,
	extremesValues?: ExtremesValues,
): JSX.Element => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const exeptions = ['Previsão de Receita', undefined];
	const tagInforms =
		tagsInforms[
			getTag(currencyToNumber(String(row.total)), extremesValues)
		];

	return (
		<Text
			paddingLeft='8px'
			marginRight={0}
			alignItems='center'
			display={'flex'}
			gap='4px'
			color={textColor}
			fontSize='sm'
			fontWeight='700'
		>
			{exeptions.includes(cell) ? (
				ComponentRow
			) : (
				<>
					{
						<Flex
							gap={'3px'}
							alignItems={'center'}
						>
							<Flex
								gap={'3px'}
								alignItems={'center'}
							>
								<Text
									display={'flex'}
									align='center'
									justifyContent={'center'}
									paddingY={'3px'}
									paddingX='4px'
									backgroundColor={tagInforms.color + '1A'}
									borderRadius='10px'
									fontSize={'12px'}
									minWidth='75px'
									color={tagInforms.color}
								>
									<Tooltip
										label={
											cell == 'Comercial'
												? 'Essa informação é em relação aos valores da colaboração de Comercial.'
												: cell == 'Marketing'
												? 'Essa informação é em relação aos valores da colaboração de Marketing.'
												: 'Essa informação é em relação aos valores da colaboração de Planejamento.'
										}
										placement='top'
									>
										{tagInforms.text}
									</Tooltip>
								</Text>
								{cell}
							</Flex>
							{row.atas.length > 0 && (
								<ModalAtaArea atas={row.atas}></ModalAtaArea>
							)}
						</Flex>
					}
				</>
			)}
		</Text>
	);
};
