export const mercadoDataDelete = {
	provider: 'market',
	toastSuccessTitle: 'Excluído com sucesso!',
	toastSuccessDescription: 'O mercado foi excluído com sucesso.',
	toastErrorTitle: 'Erro ao excluir mercado!',
	title: 'Deseja realmente excluir este mercado?',
	text: [
		'Você está prestes a excluir este mercado da sua base de cadastros. Todas as hierarquias comerciais atreladas ao mercado permanecerão, porém não terão mais mercados associados.',
		'',
		'Você tem certeza que deseja excluir?',
	],
	invalidate: 'mercado-list',
};

export const mercadoDeleteMessage = [
	'Você está prestes a excluir este(s) mercado(s) da sua base de cadastros. Todas as hierarquias comerciais atreladas ao(s) mercado(s) permanecerão, porém não terão mais mercados associados.',
	'',
];

export const mercadoDataDeleteAll = {
	provider: 'market',
	toastSuccessTitle: 'Registros excluídos com sucesso!',
	toastSuccessDescription: 'Os mercados foram excluídos com sucesso',
	toastErrorTitle: 'Erro ao excluir mercados!',
	invalidate: 'mercado-list',
};
