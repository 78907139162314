import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataVendas = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'NF',
		accessor: 'nf',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Seq.',
		accessor: 'sequenciaNF',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Sequência da Nota Fiscal',
	},
	{
		Header: 'Data da NF',
		accessor: 'dataVenda',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Zona de Venda',
		accessor: 'zonaVenda',
		type: 'relation',
		dependsOnCreateable: true,
		dependesOn: 'zonaVenda',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Razão Social',
		accessor: 'razaoSocial',
		type: 'autocomplete',
		// descomentar linha do optional abaixo caso seja necessário identificar o campo Razão Social como um campo de preenchimento obrigatório
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Família',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codProduto',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		// filter: false,
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataVendasColumns = [
	{
		Header: 'Data da NF',
		accessor: 'dataVenda',
		type: 'date',
	},
	{
		Header: 'NF',
		accessor: 'nf',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		dependsOnCreateable: true,
		dependesOn: 'zonaVenda',
	},
	{
		Header: 'Zona de Venda',
		accessor: 'zonaVenda',
		type: 'relation',
		createable: true,
	},
	{
		Header: 'Razão Social',
		accessor: 'clienteId',
		type: 'relation',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'produtoId',
		type: 'relation',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		filter: false,
		type: 'text',
	},
];

export const columnsDataZonaVenda = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'NOME',
		accessor: 'nome',
		type: 'text',
		body: 'nome_zona_venda',
	},
];

export const columnsDataCanalVenda = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'NOME',
		accessor: 'nome',
		type: 'text',
		body: 'nome_canal_venda',
	},
	{
		Header: 'ZONA VENDA',
		accessor: 'zonaVenda',
		type: 'relation',
		body: 'zona_venda_id',
	},
];
