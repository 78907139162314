export function currencyToNumber(currency: string): number {
	if (typeof currency === 'number') return currency;

	const numberString = currency?.replace(/[^\d,-]/g, '')?.replace(',', '.');
	return parseFloat(numberString);
}

export function numberToFormattedString(
	number: number,
	decimalPrecision: number,
) {
	number = Number.isNaN(Number(number)) ? 0 : Number(number);
	const formattedString = number.toLocaleString('pt-BR', {
		minimumFractionDigits: 0,
		maximumFractionDigits: decimalPrecision,
	});
	return formattedString;
}
