// Chakra imports
import { Flex } from '@chakra-ui/react';
import { DeleteAccountModal } from 'components/modal/DeleteAccountModal';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEditParameterizationCompany } from 'services/queryClient/wrapperHooks/companyConfigs/useEditParameterizationCompany';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';
import {
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from 'utils/forms/defaultsProps';
import { makeGenericFormInputStructure } from 'utils/forms/defaultInputs';
import { validateRequired } from 'utils/forms/validations';

interface IFormConfiguracoesEasy {
	initialState: any;
}

interface MonthOptions {
	label: string;
	value: number;
}

export default function FormConfiguracoesEasy({
	initialState,
}: IFormConfiguracoesEasy) {
	const history = useHistory();

	const [formInitialState, setFormInitialState] = useState<any>();

	const [payload, setPayload] = useState<any>();

	useEffect(() => {
		setFormInitialState(initialState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialState]);

	let goToParameterizationCompany = (): void => {
		history.goBack();
	};

	const { mutate: editParamaterization } = useEditParameterizationCompany();

	const onSubmit = useCallback(
		(values) => {
			const body = {
				cnae_primario: values?.cnae?.replace(/[^0-9]/g, ''),
				tamanho_ciclo: +values?.cycleSize,
				tamanho_rateio_ticket: +values?.salesHistory,
				precisao_decimal: +values?.decimalPrecision,
			};

			setPayload(body);

			editParamaterization(body);
		},
		[editParamaterization],
	);

	const monthOptions: MonthOptions[] = [
		{ label: '3 meses', value: 3 },
		{ label: '6 meses', value: 6 },
		{ label: '9 meses', value: 9 },
		{ label: '12 meses', value: 12 },
	];

	const cycleSizeOptions = useMemo(() => {
		return monthOptions
			.filter((option: MonthOptions) => {
				return option.value >= 6;
			})
			.map((option: MonthOptions) => {
				return <option value={option.value}>{option.label}</option>;
			});
	}, []);

	const salesHistoryOptions = useMemo(() => {
		return monthOptions.map((option: MonthOptions) => {
			return <option value={option.value}>{option.label}</option>;
		});
	}, []);

	const decimalPrecisionOptions = useMemo(() => {
		const options = [
			{ label: 'Inteiro', value: 0 },
			{ label: 'Decimal', value: 2 },
		];
		return options.map((option: { label: string; value: number }) => {
			return <option value={option.value}>{option.label}</option>;
		});
	}, []);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'cnae',
							label: 'Qual o CNAE primário da sua indústria?',
							labelTooltip:
								'Informe o CNAE primário de atuação da sua indústria. Por exemplo, 1830-0/03',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex. 1830-0/03',
								'data-mask': '0000-0/00',
								maxLength: 9,
								'data-test': 'input-cnae',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'cycleSize',
							label: 'Qual o tamanho do ciclo que você deseja considerar nas previsões?',
							labelTooltip:
								'Informe o tamanho do ciclo de meses que deseja utilizar em suas previsões de demanda e capacidade. Por exemplo, ciclo de tamanho 6 analisará os próximos 6 meses. Por padrão, a easy360 segue o tamanho de 6 meses para atingir maior assertividade.',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								...defaultInputStyle,
								selectOptions: cycleSizeOptions,
								'data-test': 'select-ciclo',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'salesHistory',
							label: 'Qual a janela de tempo do histórico de vendas deseja considerar no cálculo do preço médio e rateio?',
							labelTooltip:
								'Informe a quantidade de meses do histórico de vendas que deseja considerar nos cálculos do rateio e do preço médio de venda de todos os produtos e famílias da sua indústria.',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								...defaultInputStyle,
								selectOptions: salesHistoryOptions,
								'data-test': 'select-historico',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'decimalPrecision',
							label: 'Qual tipo de dado numérico deseja informar no campo de quantidade de produto: número decimal ou inteiro?',
							labelTooltip:
								'Informe o tipo de dado numérico que deseja seguir em sua conta da easy360. Obs.: se seus produtos consideram unidade de medida em massa (kg), capacidade (litros), volume (m³), comprimento (metros), ou outro que considere número com casas decimais, opte por seguir o número decimal. Caso contrário, selecione a opção de número inteiro para volumes sem frações.',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								...defaultInputStyle,
								selectOptions: decimalPrecisionOptions,
								'data-test': 'select-precisao',
							},
						}),
					},
				],
			},
		];
	}, [
		cycleSizeOptions,
		decimalPrecisionOptions,
		rowFlexProps,
		salesHistoryOptions,
	]);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToParameterizationCompany}
					dataTestProps={{
						save: 'save-button',
						cancel: 'cancel-button',
					}}
				/>
				<Flex
					justifyContent='start'
					mt={'-70px'}
				>
					<DeleteAccountModal
						title={'Deseja realmente desativar esta conta?'}
						text={
							'Você está prestes a desativar a sua conta, o que resultará na perda do acesso a easy360 e pode ocasionar em multa caso seu contrario estiver em andamento. Ao desativar a conta, você será direcionado para um de nossos atendentes que irá te auxiliar da melhor forma neste processo. Você tem certeza que deseja desativar essa conta?'
						}
					/>
				</Flex>
			</>
		);
	}, [formInitialState, renderRows, onSubmit, goToParameterizationCompany]);

	return renderForm();
}
