import { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	HStack,
	Icon,
	Link,
	List,
	ListItem,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
// Assets
import { FaCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { globalStyles } from 'theme/styles';
import { HSeparator } from '../../separator/Separator';
import { CrmMemberAssociationModal } from 'components/modal/CrmMemberAssociationModal';
import { useGlobalContext } from 'contexts/GlobalContext';
import { getLocalStorageCrm } from 'utils/functions/localStorageCrmManager';

//-- Links/Itens do menu com ícones e texto
let activeColor = globalStyles.colors.easyBLUE[300];
let inactiveColor = globalStyles.colors.easyBLUE[200];
let accordionBtColor = globalStyles.colors.easyBLUE[150];
let activeIcon = globalStyles.colors.easyRED[300];
let inactiveIcon = globalStyles.colors.easyBLUE[300];
let disabledIcon = globalStyles.colors.easyBLUE[350];

const Item = styled.div`
	.textHeader {
		color: ${(props) =>
			props.activeRoute(props.route.path) ? activeColor : inactiveColor};
	}
	.icon {
		color: ${(props) =>
			props.activeRoute(props.route.path) ? activeIcon : inactiveIcon};
	}
	:hover {
		.textHeader {
			color: ${activeColor};
		}
		.icon {
			color: ${activeIcon};
		}
	}
	width: 100%;
`;

const ItemModule = styled.div`
	.textHeader {
		color: ${(props) =>
			props.activeRoute(props.route.path) ? activeColor : inactiveColor};
	}
	.icon {
		color: ${(props) =>
			props.activeRoute(props.route.path)
				? activeIcon
				: !props.route.permissions
				? inactiveColor
				: inactiveIcon};
	}
	:hover {
		.textHeader {
			color: ${(props) =>
				props.route.permissions ? activeColor : inactiveColor};
		}
		.icon {
			color: ${(props) =>
				props.route.permissions ? activeIcon : inactiveColor};
		}
	}
	width: 100%;
`;

export function SidebarLinks(props) {
	const { toggleSidebar, sidebarState, setToggleSidebar, sidebarHelpState } =
		props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { pathCompanyName, userInfos } = useGlobalContext();
	const hasAdminNotAssociatedMemberCrmModalMessage = getLocalStorageCrm();

	//   Chakra color mode
	let location = useLocation();

	const { routes } = props;
	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return location.pathname.includes(routeName);
	};

	// this function creates the links and collapses that appear in the sidebar (left menu)
	// based on the routes from routes.js

	const createLinks = (routes) => {
		return routes.map((route, key) => {
			/** Inicialmente testa de o collapse é true ou falso (essa informação vem de routes.js)
			 * if true, ou seja têm sub-itens, cria um accordion com sub-itens;
			 * senão, cria apenas um link de menu sem subitens (navitem)
			 */
			if (route.collapse && !route.iconLock && !route.permissions) {
				return (
					<Accordion
						allowToggle
						// defaultIndex={activeRoute(route.path) && [0]}
						key={key}
					>
						{/** Menu item */}
						<Item
							route={route}
							activeRoute={activeRoute}
						>
							<AccordionItem
								border='none'
								key={key}
							>
								{/** Ícone do item de menu */}
								<AccordionButton
									// Define o brackground como transparente ao passar o mouse sobre o elemento.
									// (Horizon UI traz, por padrão, cor atribuída ao background)
									// _hover={{
									//   background: 'transparent',
									// }}
									// onMouseEnter={handleEnterOnButton}
									// onMouseLeave={handleLeaveOnButton}
									_hover={{
										background: 'transparent',
									}}
									display={
										route.notShowItemMenu ? 'none' : 'flex'
									}
									// display={"none"}
									align='center'
									justify='center'
									_focus={{
										background: 'none',
									}}
									borderRadius='8px'
									w={{
										sm: '100%',
										xl: '100%',
										'2xl': '95%',
									}}
									// @ts-ignore
									px={route.icon ? null : '0px'}
									py='0px'
									bg={'transparent'}
									ms={0}
								>
									{route.icon ? (
										<Flex
											width='100%'
											alignItems={'center'}
											justifyContent='space-between'
										>
											{/** Componente para agrupar e alinhar componentes na horizontal */}
											<HStack
												mb='15px'
												spacing={
													activeRoute(route.path)
														? '22px'
														: '26px'
												}
											>
												<Flex
													w='100%'
													alignItems='center'
													justifyContent='center'
												>
													{/******* COMPONENTE DO MENU: ícones ***/}
													<Box
														me='8px'
														mt='3px'
														className='icon'
													>
														{sidebarState ===
															'closed' &&
														!activeRoute(
															route.path,
														) ? (
															<Box
																color={
																	disabledIcon
																}
															>
																{' '}
																{route.icon}
															</Box>
														) : (
															<Box>
																{route.icon}
															</Box>
														)}
													</Box>
													{/** Texto do item de menu */}
													{/******* COMPONENTE DO MENU: textos ***/}
													<Text
														me='auto'
														className='textHeader'
														fontWeight='500'
														fontSize='sm'
														display={
															sidebarState ===
															'opened'
																? 'flex'
																: 'none'
														}
													>
														{route.name}
													</Text>
												</Flex>
											</HStack>
											{/** ícone no final [ seta para baixo ( > )] do texto do menu que leva aos sub-itens de menu */}
											{/******* COMPONENTE DO MENU: Ícone do Apêndice ***/}
											<AccordionIcon
												ms='auto'
												mb='16px'
												color={accordionBtColor}
												transform={
													route.icon
														? null
														: 'translateX(-70%)'
												}
												display={
													sidebarState === 'opened'
														? 'flex'
														: 'none'
												}
												// display='none'
											/>
										</Flex>
									) : (
										/** Caso tiver, cria mais um nível de menu sub-sub-item  */
										<Flex
											pt='0px'
											pb='10px'
											alignItems='center'
											w='100%'
										>
											<HStack
												spacing={
													activeRoute(route.path)
														? '22px'
														: '26px'
												}
												ps='34px'
											>
												<Text
													me='auto'
													color={
														activeRoute(route.path)
															? activeColor
															: inactiveColor
													}
													fontWeight='500'
													fontSize='sm'
												>
													{route.name}
												</Text>
											</HStack>
											<AccordionIcon
												ms='auto'
												color={'easyBLUE.200'}
												// @ts-ignore
												transform={null}
											/>
										</Flex>
									)}
								</AccordionButton>
								<AccordionPanel
									// @ts-ignore
									pe={route.icon ? null : '0px'}
									py='0px'
									// @ts-ignore
									ps={route.icon ? null : '8px'}
								>
									{/* ■ uma das formas de recolher os itens do menu */}
									<List>
										{sidebarState === 'opened'
											? route.icon
												? createLinks(
														route.items,
												  ) /* for non-bullet accordion links [sub-itens de menu] */
												: createAccordionLinks(
														route.items,
												  ) /* for bullet accordion links [sub-sub-itens de menu] */
											: null}
									</List>
								</AccordionPanel>
							</AccordionItem>
						</Item>
						{/** Separador entre cada item de menu */}
						{/******* COMPONENTE DO MENU: separador entre itens do MENU ***/}
						{/* ■ remoção do HSeparator de itens não exibidos no menu*/}
						{route.notShowItemMenu === undefined ? (
							<HSeparator
								mb='20px'
								bg='#E9EDF7'
							/>
						) : null}
						{/* ■ remoção do HSeparator de itens não exibidos no menu e do último item do menu: Produção*/}
						{/* {route.notShowItemMenu === true ? null : route.name === 'Produção' ? null : <HSeparator mb='20px' bg="#E9EDF7" />} */}
					</Accordion>
				);
			} else {
				/** Se o item de menu não possui sub-menu, apenas cria um NavLink
				 */
				const hasPermissions = route.permissions;
				const hasAdminNotAssociatedMessage =
					hasAdminNotAssociatedMemberCrmModalMessage;
				const hasMembroId = userInfos?.membro_id;

				const handleOnClickModal = () => {
					if (
						hasPermissions &&
						!hasMembroId &&
						!hasAdminNotAssociatedMessage
					) {
						onOpen(); // Abrir modal
					}
				};

				const handleLinkHref = () => {
					if (
						hasPermissions &&
						(hasMembroId || hasAdminNotAssociatedMessage)
					) {
						return route.link; // Link normal
					}
					return null;
				};

				return (
					// Modulo sem sub-itens
					<>
						{route.collapse ? (
							<Link
								key={key}
								href={handleLinkHref()}
								isExternal
								onClick={handleOnClickModal}
							>
								<ItemModule
									route={route}
									activeRoute={activeRoute}
								>
									<Flex
										display={
											route.notShowItemMenu
												? 'none'
												: 'flex'
										}
										align='center'
										justifyContent='space-between'
										w='100%'
										ps='17px'
										mb='0px'
										_hover={{
											cursor:
												!route.permissions &&
												'not-allowed',
										}}
										// pointerEvents={route.iconLock && "none"}
									>
										<HStack
											mb='6px'
											spacing={
												activeRoute(route.path)
													? '22px'
													: '26px'
											}
										>
											{/* <Tooltip label={"Seu plano atual é gratuito, para ter o acesso completo de todos os módulos da plataforma contrate um plano superior."}> */}

											<Flex
												w='100%'
												alignItems='center'
												justifyContent='center'
											>
												<Box
													me='8px'
													mt='3px'
													className='icon'
												>
													{sidebarState ===
														'closed' &&
													!activeRoute(route.path) ? (
														<Box
															color={disabledIcon}
														>
															{' '}
															{route.permissions
																? route.icon
																: route.iconLock}
														</Box>
													) : (
														<Box>
															{route.permissions
																? route.icon
																: route.iconLock}
														</Box>
													)}
												</Box>
												<Text
													me='auto'
													className={'textHeader'}
													fontWeight='500'
													fontSize='sm'
													display={
														sidebarState ===
														'opened'
															? 'flex'
															: 'none'
													}
												>
													{route.name}
												</Text>
											</Flex>
											{/* </Tooltip> */}
										</HStack>
									</Flex>
								</ItemModule>
								{userInfos && !userInfos?.membro_id && (
									<CrmMemberAssociationModal
										isOpen={isOpen}
										onClose={onClose}
									/>
								)}
							</Link>
						) : (
							// Modulo com sub-itens mas sem sub-menu
							<NavLink
								to={
									route.iconLock === undefined &&
									route.layout + route.path
								}
								key={key}
							>
								<ListItem
									// @ts-ignore
									display={
										route.notShowItemMenu ? 'none' : 'flex'
									}
									ms={null}
								>
									<Flex
										ps='34px'
										alignItems='center'
										mb='8px'
									>
										<Text
											_hover={{
												color: activeColor,
											}}
											color={
												activeRoute(route.path)
													? activeIcon
													: inactiveColor
											}
											fontWeight='500'
											fontSize='sm'
										>
											{route.name}
										</Text>
									</Flex>
								</ListItem>
							</NavLink>
						)}
						{route.iconLock && (
							<HSeparator
								mb='20px'
								bg='#E9EDF7'
							/>
						)}
					</>
				);
			}
		});
	};

	/** Sub-itens de menu */
	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createAccordionLinks = (routes) => {
		return routes.map((route, key) => {
			return (
				<NavLink
					to={route.layout + route.path}
					key={key}
				>
					<ListItem
						ms='28px'
						display={route.notShowItemMenu ? 'none' : 'flex'}
						alignItems='center'
						mb='10px'
						key={key}
					>
						<Icon
							w='6px'
							h='6px'
							me='8px'
							as={FaCircle}
							color={activeIcon}
						/>
						<Text
							color={
								activeRoute(route.path)
									? activeColor
									: inactiveColor
							}
							fontWeight={
								activeRoute(route.path) ? 'bold' : 'normal'
							}
							fontSize='sm'
						>
							{route.name}
						</Text>
					</ListItem>
				</NavLink>
			);
		});
	};
	//  BRAND
	return createLinks(routes);
}

export default SidebarLinks;
