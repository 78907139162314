import { ButtonComponent } from '../buttons/ButtonComponent';
import {
	defaultTooltipProps,
	defaultInputStyle,
	defaultFormStyle,
	defaultModalFormStyle,
} from '../../utils/forms/defaultsProps';
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { IconStatusChange } from '@tabler/icons';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useEffect, useState } from 'react';
import { useCreateFamily } from '../../services/queryClient/wrapperHooks/useCreateFamily';
import { useCreateProduct } from '../../services/queryClient/wrapperHooks/useCreateProduct';
import { useCreateSubFamily } from '../../services/queryClient/wrapperHooks/useCreateSubFamily';
import { useEditProduct } from '../../services/queryClient/wrapperHooks/useEditProduct';
import { useHistory } from 'react-router-dom';
import { validateRequired, validateMin } from '../../utils/forms/validations';
import {
	renderFamily,
	renderProductModel,
	renderSubFamily,
} from 'utils/forms/autocomplete';
import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { useCreateProductModel } from 'services/queryClient/wrapperHooks/useCreateProductModel';

interface IFormCadastrarEditarProduto {
	initialState: any;
	productId?: number;
	hasStock?: boolean;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarProduto = ({
	initialState,
	productId = 0,
	hasStock,
	autocompleteAdditionalData,
}: IFormCadastrarEditarProduto) => {
	const history = useHistory();

	const [isInitialState, setIsInitialState] = useState<boolean>(true);
	const [familyId, setFamilyId] = useState<Number>();
	const [subFamilyId, setSubFamilyId] = useState<Number>();
	const [payload, setPayload] = useState<any>();
	const [formProps, setFormProps] = useState<any>();
	const [autocompleteData, setAutocompleteData] = useState<any>();

	const [titleModalCreate, setTitleModalCreate] = useState<string>();

	const {
		isOpen: isOpenModalDisableProduct,
		onOpen: onOpenModalDisableProduct,
		onClose: onCloseModalDisableProduct,
	} = useDisclosure();

	const {
		isOpen: isOpenModalCreate,
		onOpen: onOpenModalCreate,
		onClose: onCloseModalCreate,
	} = useDisclosure();

	const isEditing = !!productId;

	const { mutate: createProduct } = useCreateProduct(payload);
	const { mutate: updateProduct } = useEditProduct(payload, productId);

	const { mutate: createFamily } = useCreateFamily(
		undefined,
		undefined,
		'familia_id',
		formProps,
		setFamilyId,
		setAutocompleteData,
	);
	const { mutate: createSubFamily } = useCreateSubFamily(
		undefined,
		undefined,
		'sub_familia_id',
		formProps,
		setSubFamilyId,
		setAutocompleteData,
	);

	const { mutate: createProductModel } = useCreateProductModel(
		'modelo_produto_id',
		formProps,
		setAutocompleteData,
	);

	useEffect(() => {
		setFamilyId(initialState?.familia_id);
		setSubFamilyId(initialState?.sub_familia_id);
	}, [initialState]);

	useEffect(() => {
		setAutocompleteData({
			family: autocompleteAdditionalData?.family,
			sub_family: autocompleteAdditionalData?.sub_family,
			product_model: autocompleteAdditionalData?.product_model,
		});
	}, [autocompleteAdditionalData]);

	const goToProdutos = useCallback(() => {
		history.goBack();
	}, [history]);

	const handleOnChangeFamily = useCallback((value, formProps) => {
		setFamilyId(value?.id);

		if (!formProps) setFormProps(formProps);

		const { setValue } = formProps;

		setValue('sub_familia_id', '');
		setValue('modelo_produto_id', '');
	}, []);

	const handleOnChangeSubFamily = useCallback((value, formProps) => {
		setSubFamilyId(value?.id);

		if (!formProps) setFormProps(formProps);

		const { setValue } = formProps;
		setValue('modelo_produto_id', '');
	}, []);

	const handleOnAddFamily = useCallback(
		(_, formProperties) => {
			if (!formProps) {
				setFormProps(formProperties);
			}

			setTitleModalCreate('família');
			onOpenModalCreate();
		},
		[formProps, onOpenModalCreate],
	);

	const handleOnSubAddFamily = useCallback(
		(_, formProperties) => {
			if (!formProps) {
				setFormProps(formProperties);
			}

			setTitleModalCreate('subfamília');
			onOpenModalCreate();
		},
		[formProps, onOpenModalCreate],
	);

	const handleOnSubAddProductModel = useCallback(
		(_, formProperties) => {
			if (!formProps) {
				setFormProps(formProperties);
			}

			setTitleModalCreate('modelo');
			onOpenModalCreate();
		},
		[formProps, onOpenModalCreate],
	);

	const applyDisableStatus = useCallback(() => {
		if (formProps) {
			const { setValue } = formProps;

			setValue('rateio', false);
			setValue('status', false);
			onCloseModalDisableProduct();
		}
	}, [formProps, onCloseModalDisableProduct]);

	const cancelDisableStatus = useCallback(() => {
		if (formProps) {
			const { setValue } = formProps;

			setValue('rateio', initialState?.rateio);
			setValue('status', initialState?.status);

			onCloseModalDisableProduct();
		}
	}, [
		formProps,
		initialState?.rateio,
		initialState?.status,
		onCloseModalDisableProduct,
	]);

	const handleOnChangeStatus = useCallback(
		(event, formProps) => {
			if (!isEditing) return;

			const status = formProps?.getValues()?.status;

			if (isInitialState) {
				setFormProps(formProps);
				setIsInitialState(false);

				if (!status) onOpenModalDisableProduct();

				return;
			}

			if (status === false) onOpenModalDisableProduct();
		},
		[isEditing, isInitialState, onOpenModalDisableProduct],
	);

	const isDisabledSubFamily = useCallback(() => {
		return !familyId;
	}, [familyId]);

	const isDisabledProductModelFamily = useCallback(() => {
		return !familyId || !subFamilyId;
	}, [subFamilyId, familyId]);

	const shouldClearAutocompleteSubFamily = useCallback(() => {
		if (!familyId) return false;

		return familyId !== autocompleteData?.family?.id;
	}, [familyId, autocompleteData?.family?.id]);

	const shouldClearAutocompleteProductModel = useCallback(() => {
		if (!familyId || !subFamilyId) return false;

		return (
			familyId !== autocompleteData?.family?.id ||
			subFamilyId !== autocompleteData?.sub_family?.id
		);
	}, [
		familyId,
		subFamilyId,
		autocompleteData?.family?.id,
		autocompleteData?.sub_family?.id,
	]);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'switch',
							name: 'rateio',
							label: 'Rateio',
							inputProps: {
								'data-test':
									'switch-rateio-cadastrar_editar-page-produtos',
								colorScheme: 'red',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'switch',
							name: 'status',
							label: 'Status',
							disabled: () => hasStock || false,
							inputProps: {
								'data-test':
									'switch-status-cadastrar_editar-page-produtos',
								onChange: handleOnChangeStatus,
								colorScheme: 'red',
							},
							tooltip: hasStock
								? 'Produto não pode ser inativo porque possui estoque atual!'
								: null,
							tooltipProps: {
								...defaultTooltipProps,
								placement: 'bottom',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_produto',
							label: 'Nome do Produto',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateMin(),
							},
							inputProps: {
								'data-test':
									'input-nome_do_produto-cadastrar_editar-page-produtos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'codigo_produto',
							label: 'Cód. Produto',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateMin(),
							},
							inputProps: {
								'data-test':
									'input-cod_produto-cadastrar_editar-page-produtos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'custo',
							label: 'Custo',
							inputProps: {
								'data-test':
									'input-custo-cadastrar_editar-page-produtos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-produtos',
								...defaultInputStyle,
								searchType: 'familia',
								hasButton: true,
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteData?.family
									? renderFamily(autocompleteData?.family)
									: '',
								onChange: handleOnChangeFamily,
							},
							button: {
								onClick: handleOnAddFamily,
								tooltip: 'Adicionar família',
								buttonProps: {
									'data-test':
										'button-adicionar_familia-produtos',
									bg: 'easyBLUE.300',
									color: 'white',
								},
								buttonFlexProps: {
									gap: 5,
									justify: 'space-between',
								},
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'sub_familia_id',
							label: 'Subfamília',
							disabled: isDisabledSubFamily,
							inputProps: {
								'data-test':
									'autocomplete-subfamilia-cadastrar_editar-page-produtos',
								...defaultInputStyle,
								searchType: 'sub_familia',
								hasButton: true,
								renderInputLabel: renderSubFamily,
								autocomplete: 'off',
								initialLabel: autocompleteData?.sub_family
									? renderSubFamily(
											autocompleteData?.sub_family,
									  )
									: '',
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteSubFamily,
								onChange: handleOnChangeSubFamily,
							},
							button: {
								onClick: handleOnSubAddFamily,
								tooltip: 'Adicionar Subfamília',
								buttonProps: {
									'data-test':
										'button-adicionar_subfamilia-produtos',
									bg: 'easyBLUE.300',
									color: 'white',
								},
								buttonFlexProps: {
									gap: 5,
									justify: 'space-between',
								},
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'modelo_produto_id',
							label: 'Modelo',
							disabled: isDisabledProductModelFamily,
							inputProps: {
								...defaultInputStyle,
								'data-test':
									'autocomplete-modelo-cadastrar_editar-page-produtos',
								searchType: 'modelo_produto',
								hasButton: true,
								renderInputLabel: renderProductModel,
								autocomplete: 'off',
								initialLabel: autocompleteData?.product_model
									? renderProductModel(
											autocompleteData?.product_model,
									  )
									: '',
								extraRequestData: {
									familia_id: familyId,
									sub_familia_id: subFamilyId,
								},
								clearInput: shouldClearAutocompleteProductModel,
							},
							button: {
								onClick: handleOnSubAddProductModel,
								tooltip: 'Adicionar Modelo',
								buttonProps: {
									'data-test':
										'button-adicionar_modelo-produtos',
									bg: 'easyBLUE.300',
									color: 'white',
								},
								buttonFlexProps: {
									gap: 5,
									justify: 'space-between',
								},
							},
						}),
					},
				],
			},
		];
	}, [
		handleOnChangeStatus,
		hasStock,
		autocompleteData?.family,
		autocompleteData?.sub_family,
		autocompleteData?.product_model,
		handleOnChangeFamily,
		handleOnChangeSubFamily,
		handleOnAddFamily,
		isDisabledSubFamily,
		familyId,
		shouldClearAutocompleteSubFamily,
		handleOnSubAddFamily,
		isDisabledProductModelFamily,
		subFamilyId,
		shouldClearAutocompleteProductModel,
		handleOnSubAddProductModel,
	]);

	const renderRowsCreateFamily = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_familia',
							label: 'Nome',
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-nome-familia-modal-produtos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, []);

	const renderRowsCreateSubFamily = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_sub_familia',
							label: 'Nome',
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-nome-subfamilia-modal-produtos',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, []);

	const renderRowsCreateProductModel = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_modelo',
							label: 'Nome',
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								'data-test': 'input-nome-modelo-modal-produtos',
							},
						}),
					},
				],
			},
		];
	}, []);

	const renderModalDisableProduct = useCallback(() => {
		return (
			<Modal
				isOpen={isOpenModalDisableProduct}
				onClose={cancelDisableStatus}
				onEsc={cancelDisableStatus}
				size='3xl'
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						<IconStatusChange
							size={48}
							color='#f0243e'
						/>
						<Text
							fontWeight={700}
							color={'easyBLUE.300'}
							fontSize={'2xl'}
						>
							Deseja realmente inativar o produto selecionado?
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text color='gray.500'>
							<p style={{ textAlign: 'justify' }}>
								Atenção! Por favor, antes de inativar o produto
								tenha certeza que ele não possui produtos semi
								acabados vinculados a sua produção. Essa opção
								fará com que esse produto não participe mais das
								rotinas de vendas e operações. Mas, o histórico
								de vendas e apontamentos será mantido.
							</p>
						</Text>
						<br />
						<p>
							<strong>Deseja prosseguir?</strong>
						</p>
					</ModalBody>

					<ModalFooter>
						<Flex gap={1}>
							<ButtonComponent
								data-test='button-cancelar-modal-produtos'
								type='ghost'
								title='Cancelar'
								onClick={cancelDisableStatus}
							/>
							<ButtonComponent
								data-test='button-confirmar-modal-produtos'
								type='secondary'
								title='Confirmar'
								onClick={applyDisableStatus}
							/>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}, [applyDisableStatus, isOpenModalDisableProduct, cancelDisableStatus]);

	const handleOnCreateByModal = useCallback(
		(values) => {
			if (titleModalCreate === 'família') createFamily(values);
			else if (titleModalCreate === 'subfamília')
				createSubFamily({
					familia_id: familyId,
					...values,
				});
			else
				createProductModel({
					familia_id: familyId,
					sub_familia_id: subFamilyId,
					...values,
				});

			onCloseModalCreate();
		},
		[
			createFamily,
			createProductModel,
			createSubFamily,
			familyId,
			onCloseModalCreate,
			subFamilyId,
			titleModalCreate,
		],
	);

	const renderModalCreate = useCallback(() => {
		let createInitialState;

		if (titleModalCreate === 'família')
			createInitialState = { nome_familia: '' };
		else if (titleModalCreate === 'subfamília')
			createInitialState = { nome_sub_familia: '' };
		else createInitialState = { nome_modelo: '' };

		return (
			<Modal
				isOpen={isOpenModalCreate}
				onClose={onCloseModalCreate}
				onEsc={onCloseModalCreate}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						Cadastrar {titleModalCreate}
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							Preencha todos os dados para cadastrar{' '}
							{titleModalCreate}
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{titleModalCreate === 'família' && (
							<GenericForm
								dataTestProps={{
									cancel: 'button-cancelar-modal-produtos',
									save: 'button-salvar-modal-produtos',
								}}
								initialState={createInitialState}
								formFlexProps={formFlexProps}
								rows={renderRowsCreateFamily()}
								style={defaultModalFormStyle}
								onSubmit={handleOnCreateByModal}
								onCancel={onCloseModalCreate}
							/>
						)}
						{titleModalCreate === 'subfamília' && (
							<GenericForm
								dataTestProps={{
									cancel: 'button-cancelar-modal-produtos',
									save: 'button-salvar-modal-produtos',
								}}
								initialState={createInitialState}
								formFlexProps={formFlexProps}
								rows={renderRowsCreateSubFamily()}
								style={defaultModalFormStyle}
								onSubmit={handleOnCreateByModal}
								onCancel={onCloseModalCreate}
							/>
						)}
						{titleModalCreate === 'modelo' && (
							<GenericForm
								dataTestProps={{
									cancel: 'button-cancelar-modal-produtos',
									save: 'button-salvar-modal-produtos',
								}}
								initialState={createInitialState}
								formFlexProps={formFlexProps}
								rows={renderRowsCreateProductModel()}
								style={defaultModalFormStyle}
								onSubmit={handleOnCreateByModal}
								onCancel={onCloseModalCreate}
							/>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}, [
		titleModalCreate,
		isOpenModalCreate,
		onCloseModalCreate,
		renderRowsCreateFamily,
		handleOnCreateByModal,
		renderRowsCreateSubFamily,
		renderRowsCreateProductModel,
	]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				nome_produto: values?.nome_produto || null,
				codigo_produto: values?.codigo_produto || null,
				custo: values?.custo ? currencyToNumber(values?.custo) : null,
				familia_id: values?.familia_id
					? Number(values?.familia_id)
					: null,
				sub_familia_id: values?.sub_familia_id
					? Number(values?.sub_familia_id)
					: null,
				modelo_produto_id: values?.modelo_produto_id
					? Number(values?.modelo_produto_id)
					: null,
				status: values?.status || false,
				participa_ticket_medio: values?.rateio || false,
				participa_rateio: values?.rateio || false,
			};

			setPayload(body);

			if (isEditing) updateProduct();
			else createProduct();
		},
		[createProduct, isEditing, updateProduct],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-produtos',
						save: 'button-salvar-page-produtos',
					}}
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToProdutos}
				/>

				{renderModalDisableProduct()}

				{renderModalCreate()}
			</>
		);
	}, [
		goToProdutos,
		initialState,
		onSubmit,
		renderModalCreate,
		renderModalDisableProduct,
		renderRows,
	]);

	return renderForm();
};

export default FormCadastrarEditarProduto;
