import { useQuery } from '@tanstack/react-query';
import { getCityBySalesZone } from 'services/api/requests/commercial';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';
import { useToast } from '@chakra-ui/react';

export const useGetCitySalesZoneWithId = (id: Number) => {
	const toast = useToast();

	return useQuery(['citySalesZoneWithId', id], getCityBySalesZone, {
		onSuccess: (data) => {
			return data;
		},
		onError: (error) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Família!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
		refetchOnWindowFocus: false,
	});
};
