import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

export const getWorkingDays = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, year] = queryKey;
	if (year) {
		const response = await api.get(
			`/diaUtil/listDiasUteisByAno?anos=${year}&empresa_id=1`,
		);
		return response.data;
	} else {
		const response = await api.get(
			`/diaUtil/listDiasUteisByAno?empresa_id=1`,
		);
		return response.data;
	}
};

export const getWorkingDaysPast = async () => {
	const response = await api.get(
		`/diaUtil/listDiasUteisAnteriores?empresa_id=1`,
	);
	return response.data;
};

export const editWorkingDays = async (body: any) => {
	const response = await api.patch('diaUtil', body);
	return response.data;
};
