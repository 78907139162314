import { InputType } from 'components/inputs/GenericInput';
import { IFormColumn } from '../../components/forms/GenericForm';
import {
	columnFlexProps as defaultColumnFlexProps,
	formControlProps,
} from './defaultsProps';

export function makeGenericFormInputStructure({
	type,
	name,
	...others
}: {
	type: InputType;
	name: string;
	[key: string]: any;
}): IFormColumn {
	const { columnFlexProps, ...otherProps } = others;
	return {
		subColumns: [
			{
				columnFlexProps: columnFlexProps || defaultColumnFlexProps,
				inputs: [
					{
						formControlProps,
						input: {
							type,
							name,
							...otherProps,
						},
					},
				],
			},
		],
	};
}
