export const validationCNPJ = (cnpj: string): boolean => {
	let cleanedCNPJ: string = String(cnpj).replace(/[^\d]/g, '');

	// Verifica o tamanho do CNPJ
	if (cleanedCNPJ.length !== 14) {
		return false;
	}

	// Verifica se todos os dígitos são iguais
	if (/(\d)\1{13}/.test(cleanedCNPJ)) {
		return false;
	}

	const digits: string = cleanedCNPJ.substring(12);

	const calculateVerifierDigit = (start: number): number => {
		let sum: number = 0;
		let weight: number = start - 7;
		const numbers: string = cleanedCNPJ.substring(0, start);

		for (let i = start; i >= 1; i--) {
			sum += parseInt(numbers.charAt(start - i)) * weight--;
			if (weight < 2) weight = 9;
		}

		return sum % 11 < 2 ? 0 : 11 - (sum % 11);
	};

	// Calcular e verificar o primeiro dígito verificador
	const firstDigit: number = calculateVerifierDigit(12);
	if (parseInt(digits.charAt(0)) !== firstDigit) {
		return false;
	}

	// Calcular e verificar o segundo dígito verificador
	const secondDigit: number = calculateVerifierDigit(13);
	if (parseInt(digits.charAt(1)) !== secondDigit) {
		return false;
	}

	return true;
};
