import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

export const editProduct = async (body, id: number) => {
	const url = `/produto?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const getProductById = async ({ queryKey }) => {
	const [_key, id] = queryKey;
	const url = `/produto/produtoById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const getTotalProductWithoutFamily = async ({ queryKey }) => {
	const [, data_inicio, data_fim] = queryKey;
	const response = await api.get('/produto/totalProdutosSemFamiliaVendidos', {
		params: { data_inicio, data_fim },
	});
	return response.data;
};

export const deleteProduct = async (id: number) => {
	const response = await api.delete(`produto?id=${id}`);
	return response.data;
};

export const deleteProductByFilters = async (filters: any) => {
	const response = await api.post('produto/deletarPorFiltros', filters);
	return response.data;
};

export const exportProducts = async () => {
	const response = await api.get('/produto/exportCSV');
	return response.data;
};
