import { useQuery } from '@tanstack/react-query';
import { getAtaColaboracaoList } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

interface UseGetAtaCollaboration {
	cycleId?: string;
	salesChannelId?: string;
	familyId?: string;
	companyAreaId?: string;
	salesZoneId?: string;
}

export const useGetAtaCollaboration = ({
	cycleId = '',
	salesChannelId = '',
	familyId = '',
	companyAreaId = '',
	salesZoneId = '',
}: UseGetAtaCollaboration) => {
	return useQuery(
		[
			'ata-colab-list',
			cycleId,
			salesChannelId,
			familyId,
			companyAreaId,
			salesZoneId,
		],
		getAtaColaboracaoList,
		{
			refetchOnWindowFocus: false,
		},
	);
};
