import {
	defineBorderColorValue,
	defineInputValue,
} from './constants/inputsControl';
import { Input } from '@chakra-ui/react';
import { setInputsWithLocalstorage } from './constants/setInputsWithLocalstorage';
import { useCallback, useEffect } from 'react';
import './styles/InputDateTime.css';
import { InputsInterface } from './models/InputsModel';
import { getLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';
import moment from 'moment';

export const InputDateTime = ({ ...others }: InputsInterface) => {
	const {
		dataTest,
		column,
		handleOnChange,
		inputValue,
		module,
		name,
		setInputValue,
		validation,
	} = others;

	const localStorageValue = getLocalStorageFilter(module);
	useEffect(() => {
		setInputsWithLocalstorage({
			column,
			localStorageValue,
			name,
			setInputValue,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMaxDate = useCallback(() => {
		const today = moment();
		const maxDate = today.add(1, 'year').format('YYYY-MM-DD HH:mm');
		return maxDate;
	}, []);

	return (
		<Input
			data-test={dataTest}
			className='input-datetime'
			type={'datetime-local'}
			borderRadius={'10px'}
			borderColor={defineBorderColorValue({
				name,
				validation,
			})}
			focusBorderColor='#e2e8f0'
			h={'3em'}
			fontSize={'12px'}
			name={name}
			onChange={(event) => handleOnChange(event)}
			value={defineInputValue({
				name,
				inputValue,
			})}
			max={getMaxDate()}
		/>
	);
};
