import {
	defineBorderColorValue,
	defineInputValue,
} from './constants/inputsControl';
import { Input } from '@chakra-ui/react';
import { setInputsWithLocalstorage } from './constants/setInputsWithLocalstorage';
import { useCallback, useEffect } from 'react';
import './styles/InputDate.css';
import { InputsInterface } from './models/InputsModel';
import { getLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';
import moment from 'moment';

export const InputDate = ({ ...others }: InputsInterface) => {
	const {
		dataTest,
		column,
		handleOnChange,
		inputValue,
		module,
		name,
		setInputValue,
		validation,
	} = others;

	const localStorageValue = getLocalStorageFilter(module);
	useEffect(() => {
		setInputsWithLocalstorage({
			column,
			localStorageValue,
			name,
			setInputValue,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isRequiredInput = !inputValue[name] ? false : true;

	const getMaxDate = useCallback(() => {
		const today = moment();
		const maxDate = today.add(1, 'year').format('YYYY-MM-DD');
		return maxDate;
	}, []);

	return (
		<Input
			data-test={dataTest}
			className='input-date'
			type={'date'}
			borderRadius={'10px'}
			borderColor={defineBorderColorValue({
				name,
				validation,
			})}
			focusBorderColor='#e2e8f0'
			px={'0'}
			h={'3em'}
			fontSize={'12px'}
			name={name}
			onChange={(event) => handleOnChange(event)}
			value={defineInputValue({
				name,
				inputValue,
			})}
			max={getMaxDate()}
			style={{ marginRight: '14px' }}
			required={isRequiredInput}
		/>
	);
};
