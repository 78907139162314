export function mapErrorToToastProps(
	error: any,
	header: string,
	defaultDescription?: string,
): Array<any> {
	if (
		error?.response?.data &&
		Array.isArray(error.response.data) &&
		!!error.response.data.length
	) {
		return (
			error?.response?.data
				?.filter?.((e) => e?.path !== 'empresa_id')
				?.map?.((e) => {
					const isDetailsObject =
						typeof e?.details?.response === 'object';
					const details =
						isDetailsObject && e?.details?.response?.message[0];
					const message = e?.message;

					return {
						title: header,
						description:
							details ||
							message ||
							defaultDescription ||
							'Ocorreu um erro ao processar sua solicitação. Contate o seu mentor ou envie um e-mail para: contato@easy360.ind.br.',
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					};
				}) || []
		);
	} else {
		return [
			{
				title: 'Erro',
				description:
					'Ocorreu um erro ao processar sua solicitação. Contate o seu mentor ou envie um e-mail para: contato@easy360.ind.br.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			},
		];
	}
}
