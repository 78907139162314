import { currencyToNumber } from 'utils/functions/currencyToNumber';
import {
	defaultFormStyle,
	defaultInputStyle,
} from '../../utils/forms/defaultsProps';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { renderFamily, renderProduct } from 'utils/forms/autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateAverageTicket } from 'services/queryClient/wrapperHooks/averageTicket/useCreateAverageTicket';
import { useEditAverageTicket } from 'services/queryClient/wrapperHooks/averageTicket/useEditAverageTicket';
import { useHistory } from 'react-router-dom';
import { validateRequired } from '../../utils/forms/validations';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';

interface IFormCadastrarEditarPrecoMedio {
	initialState: any;
	averageTicketId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarPrecoMedio = ({
	initialState,
	averageTicketId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarPrecoMedio) => {
	const history = useHistory();

	const isEditing = !!averageTicketId;

	const { data: SalesChannelData, isLoading: isLoadingSalesChannelData } =
		useSalesChannelList();

	const [formInitialState, setFormInitialState] = useState<any>();

	const [familyId, setFamilyId] = useState<number>();
	const [payload, setPayload] = useState<any>();

	const { mutate: createAverageTicket } = useCreateAverageTicket(payload);
	const { mutate: updateAverageTicket } = useEditAverageTicket(
		payload,
		averageTicketId,
	);

	useEffect(() => {
		setFamilyId(initialState?.familia_id);
	}, [initialState]);

	useEffect(() => {
		setFormInitialState(initialState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialState]);

	const goToOpportunities = useCallback(() => {
		history.goBack();
	}, [history]);

	const salesChannelOptions = useMemo(() => {
		return (
			SalesChannelData?.map((channel) => {
				return (
					<option value={channel?.id}>
						{channel?.nome_canal_venda}
					</option>
				);
			}) || []
		);
	}, [SalesChannelData]);

	const handleOnChangeFamily = useCallback((value) => {
		setFamilyId(value?.id);
	}, []);

	const isDisabledProductAutocomplete = useCallback(() => {
		return !familyId;
	}, [familyId]);

	const shouldClearAutocompleteProduct = useCallback(() => {
		if (!familyId) return false;

		return familyId !== autocompleteAdditionalData?.product?.familia?.id;
	}, [familyId, autocompleteAdditionalData?.product?.familia?.id]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				produto_id: values?.produto_id,
				canal_venda_id: Number(values?.canal_venda_id),
				familia_id: values?.familia_id,
				recalcular: false,
				ticket_medio_editado: currencyToNumber(
					values?.ticket_medio_editado,
				),
			};

			setPayload(body);

			if (isEditing) updateAverageTicket();
			else createAverageTicket();
		},
		[createAverageTicket, isEditing, updateAverageTicket],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-preco_medio',
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
								onChange: handleOnChangeFamily,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'produto_id',
							label: 'Produto',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: isDisabledProductAutocomplete,
							inputProps: {
								'data-test':
									'autocomplete-produto-cadastrar_editar-page-preco_medio',
								...defaultInputStyle,
								searchType: 'produto',
								renderInputLabel: renderProduct,
								autocomplete: 'off',
								initialLabel:
									autocompleteAdditionalData?.product
										? renderProduct(
												autocompleteAdditionalData?.product,
										  )
										: '',
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteProduct,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda_id',
							label: 'Canal de venda',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-preco_medio',
								...defaultInputStyle,
								selectOptions: salesChannelOptions,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'ticket_medio_editado',
							label: 'Preço Médio',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-preco_medio-cadastrar_editar-page-preco_medio',
								...defaultInputStyle,
								allowNegativeValue: true,
							},
						}),
					},
				],
			},
		];
	}, [
		autocompleteAdditionalData,
		familyId,
		handleOnChangeFamily,
		isDisabledProductAutocomplete,
		salesChannelOptions,
		shouldClearAutocompleteProduct,
	]);

	const renderForm = useCallback(() => {
		if (isLoadingSalesChannelData) return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-preco_medio',
						save: 'button-salvar-page-preco_medio',
					}}
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToOpportunities}
				/>
			</>
		);
	}, [
		formInitialState,
		renderRows,
		onSubmit,
		goToOpportunities,
		isLoadingSalesChannelData,
	]);

	return renderForm();
};

export default FormCadastrarEditarPrecoMedio;
