import { Flex, Heading, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import MainFilter from './components/MainFilter/MainFilter';
import { MenuFamily } from './components/MenuFamily/MenuFamily';
import { MoreSection } from './components/MoreSection';
import { SectionColaboracaoConsenso } from './components/SectionColaboracaoConsenso';
import ProviderColaboracaoConsenso from './context';
interface PropsTemplateCadastrarColabCons {
	page: 'colaboracao' | 'consenso';
	SectionColabCons: () => JSX.Element;
}
export default function TemplateCadastrarColabCons({
	page,
	SectionColabCons,
}: PropsTemplateCadastrarColabCons) {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<ProviderColaboracaoConsenso page={page}>
			<Flex gap={10}>
				<Flex
					direction='column'
					width='stretch'
				>
					<SimpleGrid
						columns={{ sm: 1, md: 1, lg: 1, xl: 3 }}
						gap='20px'
						mb='20px'
					>
						<Flex>
							<Heading
								color={textColor}
								fontSize='xl'
								fontWeight='700'
								lineHeight='100%'
							></Heading>
						</Flex>
					</SimpleGrid>
					<MainFilter />
					<Flex gap={5}>
						<MenuFamily />
						<Flex
							flexGrow={1}
							flexDirection={'column'}
							gap='10px'
						>
							<SectionColaboracaoConsenso>
								<SectionColabCons />
							</SectionColaboracaoConsenso>
							<MoreSection />
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</ProviderColaboracaoConsenso>
	);
}
