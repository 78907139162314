import { Icon } from '@chakra-ui/icon';
import { Flex, Heading, HStack } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { IconRefresh } from '@tabler/icons';
import { useQueryClient } from '@tanstack/react-query';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import CheckTable from 'components/dataTables/CheckTable';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { TbClockShare } from 'react-icons/tb';
import { columnsDataRecentActivityLog } from './variables/columnsDataRecentActivityLog';
import { calculateTimeDifference } from 'utils/functions/dateUtils';

interface RecentActivityLogModalProps {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}

export const RecentActivityLogModal = (props: RecentActivityLogModalProps) => {
	const { isOpen, onClose } = props;

	const tableColumns = useMemo(() => columnsDataRecentActivityLog, []);

	const renderRow = useCallback((recentActivity) => {
		const formattedDate = moment.utc(recentActivity.data_hora).local();
		const timeDifference = calculateTimeDifference(formattedDate);

		return {
			id: recentActivity.id,
			usuario: recentActivity.nome_usuario,
			descricaoAtividade: recentActivity.descricao_acao,
			data: moment
				.utc(recentActivity.data_hora)
				.local()
				.format('DD/MM/YYYY HH:mm:ss'),
			tempoAtividade: timeDifference,
			acao: recentActivity.acao,
			status: recentActivity.status,
		};
	}, []);

	const renderTable = () => {
		return (
			<CheckTable
				module='log'
				columnsData={tableColumns}
				showGenericSearchInput={false}
				filterModule='log'
				renderRow={renderRow}
				pt='85px'
			/>
		);
	};

	const queryClient = useQueryClient();
	const iconRefreshInvalidationQuery = () => {
		queryClient.resetQueries(['log-list']);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			onEsc={onClose}
			size='4xl'
			isCentered
			scrollBehavior={'inside'}
		>
			<ModalOverlay />

			<ModalContent
				borderRadius={20}
				pt='20px'
				minHeight='406px'
			>
				<ModalCloseButton m={'40px 40px 0 0'} />
				<ModalHeader
					display='flex'
					flexDirection='column'
					gap={4}
					color='easyBLUE.300'
				>
					<Icon
						as={TbClockShare}
						color='easyRED.300'
						w={14}
						h={14}
					/>
					<HStack
						alignItems='center'
						spacing={4}
					>
						<Heading
							color='easyBLUE.300'
							mb={2}
							size='md'
						>
							Atividades Recentes
						</Heading>
						<ButtonComponent
							type='icon'
							iconType={<IconRefresh />}
							onClick={iconRefreshInvalidationQuery}
							iconSize={'xs'}
							border='none'
						/>
					</HStack>
				</ModalHeader>

				<ModalBody>{renderTable()}</ModalBody>
				<ModalFooter></ModalFooter>
			</ModalContent>
		</Modal>
	);
};
