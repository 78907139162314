import {
	Avatar,
	ButtonGroup,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaEthereum } from 'react-icons/fa';
import { IoIosSettings } from 'react-icons/io';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { DrawerHelp } from 'components/drawer/DrawerHelp';
import { UploadPhotoAvatar } from 'components/modal/UploadPhotoAvatar';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import api from 'services/api/api';
import { clearLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';
import { TbClockShare } from 'react-icons/tb';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { RecentActivityLogModal } from 'components/modal/RecentActivityLogModal';
import RobotEasy from 'components/robot/RobotEasy';
import { useState, useEffect } from 'react';
import AiPrediction from 'components/ai/AiPrediction';

export default function HeaderLinks(props) {
	const { secondary, setSidebarHelpState } = props;
	const { onOpen } = useDisclosure();
	const {
		isOpen: isOpenRecentActivityLogModal,
		onOpen: onOpenRecentActivityLogModal,
		onClose: onCloseRecentActivityLogModal,
	} = useDisclosure();

	const navbarIcon = useColorModeValue('gray.400', 'white');
	const [iconColor, setIconColor] = useState(navbarIcon);
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue(
		'#E6ECFA',
		'rgba(135, 140, 189, 0.3)',
	);
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
	);

	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();
	let goToUsuarios = () => {
		window.location.href = `/${pathCompanyName}/gerenciarPerfil`;
	};

	let goToPerfis = () => {
		history.push(`/${pathCompanyName}/gerenciamentoUsuarios`);
	};

	const goToConfiguracoes = () => {
		history.push(`/${pathCompanyName}/configuracoes`);
	};

	const { route, signOut } = useAuthenticator((context) => [
		context.route,
		context.signOut,
	]);

	const { userAttributes } = useGlobalContext();

	const handleSignOut = () => {
		signOut();
		api.defaults.headers.common['Authorization'] = null;
		clearLocalStorageFilter('venda');
		history.push('/auth/login');
		localStorage.setItem('logout', 'true');
	};
	useEffect(() => {
		const handleStorageChange = (event) => {
			if (event.key === 'logout' && event.newValue === 'true') {
				signOut();
				api.defaults.headers.common['Authorization'] = null;
				history.push('/auth/login');
			}
		};

		window.addEventListener('storage', handleStorageChange);
	}, [history, signOut]);

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems='center'
			flexDirection='row'
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			py='5px'
			px='10px'
			borderRadius='30px'
			boxShadow={shadow}
		>
			{/** Componente de busca do header da página */}

			<Flex
				bg={ethBg}
				display={secondary ? 'flex' : 'none'}
				borderRadius='30px'
				p='6px'
				align='center'
				me='6px'
				ms='auto'
			>
				<Flex
					align='center'
					justify='center'
					bg={ethBox}
					h='29px'
					w='29px'
					borderRadius='30px'
					me='7px'
				>
					<Icon
						color={ethColor}
						w='9px'
						h='14px'
						as={FaEthereum}
					/>
				</Flex>
				<Text
					w='max-content'
					color={ethColor}
					fontSize='sm'
					fontWeight='700'
					me='6px'
				>
					1,924
					<Text
						as='span'
						display={{ base: 'none', md: 'unset' }}
					>
						{' '}
						ETH
					</Text>
				</Text>
			</Flex>
			<Flex
				flexDirection='column'
				fontSize='12px'
				me='40px'
				ms='10px'
				my='10px'
			></Flex>
			<Menu>
				<DrawerHelp setSidebarHelpState={setSidebarHelpState} />
			</Menu>
			<AiPrediction />
			<Spacer mr={'10px'} />
			<RobotEasy />
			<Spacer mr={'10px'} />
			<TooltipStandard label={'Atividades recentes'}>
				<span
					style={{
						display: 'flex',
					}}
				>
					<Icon
						h='16px'
						w='16px'
						color={navbarIcon}
						_hover={{ color: '#314866', cursor: 'pointer' }}
						_active={{ color: '#02044A' }}
						as={TbClockShare}
						onClick={onOpenRecentActivityLogModal}
					/>
				</span>
			</TooltipStandard>
			<Spacer mr={'10px'} />
			<Menu>
				<TooltipStandard label={'Configurações'}>
					<MenuButton
						data-test='configuracoes'
						variant='no-hover'
						bg='transparent'
						pt='8px'
						minW='unset'
						minH='unset'
						h='18px'
						w='max-content'
						onMouseEnter={() => setIconColor('#314866')}
						onMouseLeave={() => setIconColor(navbarIcon)}
						onMouseDown={() => setIconColor('#02044A')}
						onMouseUp={() => setIconColor('#314866')}
					>
						<Icon
							h='18px'
							w='18px'
							color={iconColor}
							as={IoIosSettings}
						/>
					</MenuButton>
				</TooltipStandard>
				<MenuList
					boxShadow={shadow}
					p='0px'
					mt='21px'
					borderRadius='20px'
					bg={menuBg}
					border='none'
				>
					<Flex
						flexDirection='column'
						p='10px'
					>
						<MenuItem
							data-test='configuracoes-easy360'
							onClick={() => goToConfiguracoes()}
							color='easyBLUE.300'
							_hover={{ bg: 'none', color: 'easyBLUE.200' }}
							_focus={{ bg: 'none' }}
							borderRadius='8px'
							px='14px'
						>
							<Text fontSize='sm'>Configurações da Easy360</Text>
						</MenuItem>
						<MenuItem
							onClick={() => goToPerfis()}
							color='easyBLUE.300'
							_hover={{ bg: 'none', color: 'easyBLUE.200' }}
							_focus={{ bg: 'none' }}
							borderRadius='8px'
							px='14px'
						>
							<Text fontSize='sm'>Gerenciamento de Usuários</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
			<Spacer mr={'10px'} />
			<Menu>
				<MenuButton p='0px'>
					<Avatar
						h='40px'
						w='40px'
						name={
							userAttributes
								? userAttributes?.name
								: 'Admin Easy360'
						}
						background={
							'linear-gradient(135.81deg, #F0243E 15.92%, #314866 109.14%)'
						}
						color={'white'}
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='0px'
					mt='10px'
					borderRadius='20px'
					bg={menuBg}
					border='none'
				>
					<Flex
						w='100%'
						mb='0px'
						pt={'2em'}
						flexDirection={'column'}
						alignItems={'center'}
					>
						<UploadPhotoAvatar
							onOpen={onOpen}
							userAttributes={userAttributes}
						/>
						<Text
							textAlign={'center'}
							pt={'1em'}
							w='100%'
							borderColor={borderColor}
							fontSize='md'
							fontWeight='700'
							color={textColor}
						>
							{userAttributes
								? userAttributes?.name
								: 'Admin Easy360'}
						</Text>
						<Text
							textAlign={'center'}
							w='100%'
							mt={'-.5em'}
							borderColor={borderColor}
							fontSize='xs'
							color={'#8F9BBA'}
						>
							{userAttributes
								? userAttributes?.email
								: 'admin@easy360.ind.br'}
						</Text>
						<ButtonGroup
							mt={'1em'}
							spacing={'20px'}
						>
							<ButtonComponent
								type={'ghost'}
								title={'Gerenciar Perfil'}
								onClick={goToUsuarios}
							/>
						</ButtonGroup>
						<ButtonGroup
							mt={3}
							spacing={'20px'}
						>
							{route !== 'authenticated' ? (
								<ButtonComponent
									type={'ghost'}
									title={'Login'}
									onClick={() => history.push('/auth/login')}
								/>
							) : (
								<ButtonComponent
									type={'secondary'}
									title={'Logout'}
									onClick={handleSignOut}
								/>
							)}
						</ButtonGroup>
					</Flex>
					<Flex
						flexDirection='column'
						p='10px'
					></Flex>
				</MenuList>
			</Menu>
			<RecentActivityLogModal
				isOpen={isOpenRecentActivityLogModal}
				onOpen={onOpenRecentActivityLogModal}
				onClose={onCloseRecentActivityLogModal}
			/>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	secondary: PropTypes.bool,
	setSidebarHelpState: PropTypes.func,
};
