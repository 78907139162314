/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Input, Text } from '@chakra-ui/react';
import { getLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';
import { LoadingSpinner } from 'components/loading/Loading';
import { useEffect, useState } from 'react';
import { useGetFiltersByDependency } from 'services/queryClient/wrapperHooks/filters/useGetFiltersByDependency';
import { setInputsWithLocalstorage } from './constants/setInputsWithLocalstorage';
import './styles/InputText.css';

export const InputText = ({ ...others }) => {
	const {
		name,
		column,
		handleOnChange,
		module,
		handleCheck,
		inputValue,
		setInputValue,
		selectedOptions,
		setSelectedOptions,
		getFromExternalAPI = false,
	} = others;

	const [searchValue, setSearchValue] = useState(inputValue.textSearch);
	const [searchTimeout, setSearchTimeout] = useState<
		number | NodeJS.Timeout | null
	>(null);

	useEffect(() => {
		if (searchValue !== inputValue.textSearch) {
			if (searchTimeout) {
				clearTimeout(searchTimeout);
			}

			const timeoutId = setTimeout(() => {
				setSearchValue(inputValue.textSearch);
			}, 500);

			setSearchTimeout(timeoutId);
		}
	}, [searchValue, inputValue.textSearch]);
	const [size, setSize] = useState(10);
	const { data, isLoading, isError, refetch } = useGetFiltersByDependency({
		column,
		search: searchValue,
		size,
		page: 0,
		module,
		getFromExternalAPI,
	});

	const localStorageValue = getLocalStorageFilter(module);

	useEffect(() => {
		setInputsWithLocalstorage({
			column,
			localStorageValue,
			name,
			setInputValue,
			setSelectedOptions,
		});
	}, []);

	const handleOptionClick = (value: string): void => {
		const isSelected = (value) => {
			return selectedOptions.some((option) => value === option);
		};

		if (isSelected(value)) {
			setSelectedOptions(
				selectedOptions.filter((option) => option !== value),
			);
		} else {
			setSelectedOptions([...selectedOptions, value]);
		}
	};

	const addMoreResults = () => {
		setSize((prev) => prev + 10);
	};

	useEffect(() => {
		handleCheck(selectedOptions, name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	const renderListOptions = (): JSX.Element | void => {
		if (data?.dados.length > 0) {
			return (
				<div className='select'>
					{data.dados.map((option, index) => {
						const item = option.value;
						return (
							<Box
								margin={'5px'}
								key={item}
							>
								<label className='label'>
									<input
										className='checkbox-input'
										type='checkbox'
										value={item}
										checked={selectedOptions.some(
											(option) => option === item,
										)}
										onChange={() => handleOptionClick(item)}
									/>
									{option.value}
								</label>
							</Box>
						);
					})}
					{data?.dados.length < data?.total_linhas && (
						<button
							className='button-link'
							onClick={addMoreResults}
						>
							<u>Mais resultados...</u>
						</button>
					)}
				</div>
			);
		} else if (!data?.dados.length && inputValue.textSearch !== '') {
			return (
				<div className='select select-no-result'>
					<Text
						fontSize={'12px'}
						width={'100%'}
						alignSelf={'center'}
						p={'15px'}
					>
						Nenhum resultado encontrado para a pesquisa:
						<br />
						<span style={{ fontWeight: 'bold' }}>
							"{inputValue.textSearch}"
						</span>
						, verifique se o preenchimento do campo está correto.
					</Text>
				</div>
			);
		}
	};

	const selectAllOptions = (): void => {
		if (selectedOptions.length !== data?.dados.length) {
			const allOptions = data?.dados.map((option, index) => {
				const item = option.value;
				return item;
			});

			setSelectedOptions(allOptions);
		} else {
			setSelectedOptions([]);
		}
	};

	const renderBottomSelectAll = (): JSX.Element => {
		const button = (text) => {
			return (
				<button
					className='button-link button-link-static'
					onClick={selectAllOptions}
				>
					<u>
						{text} todos: {data?.dados.length} resultados
					</u>
				</button>
			);
		};
		if (selectedOptions.length === data?.dados.length) {
			return button('Remover');
		} else {
			return button('Selecionar');
		}
	};

	return (
		<Flex display={'column'}>
			<Input
				type={'text'}
				h={'3em'}
				mb={'5px'}
				borderRadius={'10px'}
				focusBorderColor='#e2e8f0'
				fontSize={'12px'}
				color={'blue.800'}
				placeholder={'Pesquisar por...'}
				autoComplete={'off'}
				name={name}
				onChange={(event) => handleOnChange(event)}
				value={inputValue.textSearch}
			/>

			{data?.dados.length > 0 && renderBottomSelectAll()}

			{isLoading ? (
				<Flex height={'100%'}>
					<LoadingSpinner />
				</Flex>
			) : (
				renderListOptions()
			)}
		</Flex>
	);
};
