import { useQuery } from '@tanstack/react-query';
import { getDemandDashboardData } from 'services/api/requests/dashboardDemanda/demandDashboardData';

export const useDemandDashboardData = (
	cicloId: number | string,
	canalVendaId?: number,
	familiaId?: number,
) => {
	return useQuery(
		['demandDashboardData', { cicloId, canalVendaId, familiaId }],
		getDemandDashboardData,
		{
			refetchOnWindowFocus: false,
			enabled: !!cicloId,
		},
	);
};
