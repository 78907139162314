import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { validationCNPJ } from 'utils/functions/validationCNPJ';

export function validateRequired(message?: string) {
	return {
		required: message || 'Campo obrigatório!',
	};
}

export function validateMinDecimal(
	value: string,
	min: number,
	message?: string,
) {
	const validationMessage = `O valor mínimo deve ser maior ou igual a ${min?.toLocaleString(
		'pt-BR',
	)}!`;
	const parsedValue = currencyToNumber(value);

	return parsedValue < min ? message || validationMessage : null;
}

export function validateMin(min: number = 1, message?: string) {
	const validationMessage = `O campo deve conter pelo menos ${min} caracteres!`;

	return {
		min: {
			value: min,
			message: message || validationMessage,
		},
	};
}

export function validateMax(max: number = 1, message?: string) {
	const validationMessage = `O campo não deve conter mais do ${max} caracteres!`;

	return {
		max: {
			value: max,
			message: message || validationMessage,
		},
	};
}

export function validateMaxDecimal(
	value: string,
	max: number,
	message?: string,
) {
	const validationMessage = `O valor máximo deve ser menor ou igual a ${max?.toLocaleString(
		'pt-BR',
	)}!`;
	const parsedValue = currencyToNumber(value);

	return parsedValue > max ? message || validationMessage : null;
}

export function validateByRegex(regex: RegExp, message: string) {
	return {
		pattern: {
			value: regex,
			message,
		},
	};
}

export function validateCNPJ(value: string) {
	const validate: boolean = validationCNPJ(value);

	if (!validate) {
		return 'CNPJ inválido, por favor, verifique o número digitado!';
	}
}
