import { useQuery } from '@tanstack/react-query';
import { getMarketById } from 'services/api/requests/market';

export const useGetMarketById = (id: Number) => {
	return useQuery(['marketById', id], getMarketById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
