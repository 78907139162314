import { useMutation } from '@tanstack/react-query';
import { IRequestSearchPagination } from 'pages/admin/demanda/templateCadastrarColabCons/components/MainFilter/InputSearch';
import { getProductsByFiltersAndLimit } from 'services/api/requests/requests';
export interface IProductListAsMutate {
	status?: boolean;
	familyId?: number;
	notProductIds?: number[];
	productId?: number;
	subFamilyId?: number;
	participa_rateio?: boolean;
}
export const useProductListAsMutate = (
	p: IProductListAsMutate & IRequestSearchPagination,
) => useMutation(() => getProductsByFiltersAndLimit(p));
