import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';
// ■ SelectColumnFilter
import { SelectColumnFilter } from 'components/dataTables/FilterTypes.js/SelectColumnFilter';

export const columnsDataRecursos = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Nome Recurso',
		accessor: 'nome_recurso',
		disableSortBy: true,
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Grupo de Recurso',
		accessor: 'grupo_de_recursos',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Disponibilidade em Minutos',
		accessor: 'disponibilidade_minutos_dia',
		type: 'text',
		filter: 'includesIgnoreCase',
		helper: true,
		helperText:
			'Valor em minutos para 1 recurso (máquina ou pessoa) por dia.',
	},
	{
		Header: 'IROG',
		accessor: 'irog',
		type: 'text',
		helper: true,
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];
