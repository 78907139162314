import { useLocation } from "react-router-dom";



export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const onChangeQuery = (history, cicloId: string, areaEmpresaId?: string) => {
  if (areaEmpresaId) {
    history.replace({ search: (new URLSearchParams({ cicloId, areaEmpresaId })).toString() });
  }
  else {
    history.replace({ search: (new URLSearchParams({ cicloId })).toString() });
  }
}