import { useQuery } from '@tanstack/react-query';
import { SelectedFilterBody } from '../../../components/filter/models/TableFilterModels';
import {
	fetchDataByFilters,
	fetchDataByFiltersAiPrediction,
} from '../../api/requests/checktable';
import { PAGINATION_SIZE_LIST } from '../../../utils/consts/pagination';
import { getOpportunityConsensusException } from 'services/api/requests/opportunity/opportunity';
import { useToast } from '@chakra-ui/react';

type QueryTypeModule =
	| 'venda'
	| 'oportunidade'
	| 'consenso'
	| 'colaboracao'
	| 'carteiraPedido'
	| 'ticketMedio'
	| 'ticketMedioFamilia'
	| 'estoque'
	| 'forecastDemanda'
	| 'orcamento'
	| 'produto'
	| 'hierarquiaComercial'
	| 'membro'
	| 'zonaVenda'
	| 'clienteRelacionado'
	| 'recurso'
	| 'tipologiaParadas'
	| 'ciclo'
	| 'bots'
	| 'aiPrediction';

interface IPaginationData {
	module: QueryTypeModule;
	page?: number;
	size?: number;
	filters?: SelectedFilterBody[] | any[];
	preFilteredRows?: any;
}

export const useGetCheckTableDataWithFilters = ({
	module,
	page,
	size,
	filters,
	preFilteredRows,
}: IPaginationData) => {
	const toast = useToast();

	return useQuery(
		[`${module}-list`, page, size, filters],
		() => {
			const requestBody = {
				paginacao: {
					page,
					size: size || PAGINATION_SIZE_LIST[0],
					order: [],
				},
				filtros: filters,
			};

			if (!preFilteredRows) {
				switch (module) {
					case 'aiPrediction':
						return fetchDataByFiltersAiPrediction({
							page: page !== undefined ? page + 1 : 1,
							page_size: size || PAGINATION_SIZE_LIST[0],
						});
					default:
						return fetchDataByFilters(module, requestBody);
				}
			} else {
				const mapPreFilteredExcpetion = {
					oportunidade: getOpportunityConsensusException,
				};

				const updatedRequestBody = {
					...requestBody,
					preFilteredRows,
				};

				return mapPreFilteredExcpetion[module](updatedRequestBody);
			}
		},

		{
			onError: (error: any) => {
				console.log(error);
				toast({
					title: 'Erro ao buscar dados!',
					description:
						'Ocorreu um erro ao buscar os dados para listagem.',
					status: 'error',
					duration: 8000,
					position: 'bottom-left',
					isClosable: true,
				});
			},
			refetchOnWindowFocus: false,
			retry: false,
			enabled: !!module,
		},
	);
};
