import { Alert, AlertIcon, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import ManualPaging, {
	IManualPaging,
} from 'components/dataTables/ManualPaging';
import { LoadingSpinner } from 'components/loading/Loading';
import { useEffect, useState } from 'react';
import { useProductDemandList } from 'services/queryClient/wrapperHooks/product/useProductDemandList';

interface PropsItemProduct {
	familyId: number;
}
export function ItemProduct({ familyId }: PropsItemProduct) {
	const [pagination, setPagination] = useState<IManualPaging>({
		page: 0,
		size: 10,
		totalPages: 0,
	});
	const { data: produtos } = useProductDemandList({
		familyId,
		page: pagination.page,
		size: pagination.size,
	});
	useEffect(() => {
		if (produtos?.dados) {
			setPagination((prev) => ({
				...prev,
				totalPages: Math.ceil(
					+produtos?.total_linhas / +pagination.size,
				),
			}));
		}
	}, [produtos]);
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	return (
		<>
			<Box
				paddingRight={4}
				fontSize={'16px'}
				fontWeight={700}
				color={'easyBLUE.300'}
				alignItems={'center'}
				borderBottom={'1px solid'}
				borderColor={borderColor}
			>
				{produtos?.dados.map((produto) => (
					<Flex
						borderBottom={'1px solid'}
						borderColor={borderColor}
						paddingY={3.5}
						alignItems={'center'}
						fontSize={'16px'}
						fontWeight={700}
						color={'easyBLUE.300'}
						flexGrow={1}
						paddingLeft={20}
					>
						<Text>
							{produto.codigo_produto ? (
								`${produto.codigo_produto} - ${produto.nome_produto}`
							) : (
								<>
									<Text
										as={'span'}
										color={'easyRED.300'}
									>
										Sem SKU
									</Text>
									{` - ${produto.nome_produto}`}
								</>
							)}
						</Text>
					</Flex>
				)) || <LoadingSpinner />}
				{
					Number(produtos?.dados?.length) === 0 &&
					<Alert status='warning'>
						<AlertIcon />
						Esta família não possui produtos
					</Alert>
				}
				{!!produtos?.total_linhas &&
					produtos?.total_linhas > pagination.size && (
						<ManualPaging
							pagination={pagination}
							setPagination={setPagination}
							totalItemsPageList={[25]}
							showGoToPage={false}
							showInputLinesPerPage={false}
							renderedInDarkMode={false}
						/>
					)}
			</Box>
		</>
	);
}
