// Chakra imports
import { Flex } from "@chakra-ui/react";


export default function Mercado() {
    return (
        <Flex
          direction={{ base: "column", xl: "row" }}
          pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <h2>Mapeamento de Mercado</h2>
        </Flex>
  );
}