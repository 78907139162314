import { useQuery } from "@tanstack/react-query"
import { getParameterization } from "services/api/requests/company"

export const useGetCompanyParameterization = (setIntervaloTempo: any) => {
    return useQuery(["companyParameterization"], getParameterization, {
        onSuccess: (data) => {
            setIntervaloTempo(data.tamanho_rateio_ticket)
        },
        onError: (error) => {
            console.log(error)
        },
        refetchOnWindowFocus: false,
    })
}