import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postAtaColaboracao } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

interface BodyCreateAta {
	anotacao: string;
	area_empresa_id: string;
	canal_venda_id: string;
	familia_id: string;
	ciclo_id: string;
}

export const useCreateAtaColab = (body?: BodyCreateAta) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(postAtaColaboracao, {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['ata-colab-list']);
			toast({
				title: 'Registrada com sucesso!',
				description: 'Ata regristada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			toast({
				title: 'Erro ao registrar ata!',
				description: error.response.data[0].message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
