// Chakra imports
import {
    Box,
    Button,
    Flex,
    Input, useColorModeValue
} from "@chakra-ui/react";
import { useCallback } from "react";
// Assets
import { useDropzone } from "react-dropzone";

function DropzoneUploadPhoto(props) {
    const { content, ...rest } = props

    // const { mutate,  data} = useUploadProducts(setUploadPercentage, setSucessUpload, onClose, openTableModal)
    const onDrop = useCallback(async (acceptedFiles) => {

        console.log(acceptedFiles)

        let data = new FormData()
        data.append('file', acceptedFiles[0])
        

    }, [])

    const {
        isDragActive,
        getRootProps,
        getInputProps,
        isDragReject,
        acceptedFiles,
        rejectedFiles,
    } = useDropzone({
        onDrop,
        // accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv',
        // minSize: 0,
        // maxSize,
    })

    const bg = useColorModeValue("secondaryGray.300", "secondaryGray.400")
    const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100")
    const easyBLUE = useColorModeValue("easyBLUE.300", "easyBLUE.200");

    // const listFiles = acceptedFiles.map((file) => (
    //     <ListItem key={file.path}>
    //         {file.path} - {file.size} bytes
    //     </ListItem>
    // ))

    return (
        <Box>
            <Flex
                align="center"
                justify="center"
                bg={bg}
                border="1px dashed"
                borderColor={borderColor}
                borderRadius="16px"
                w="100%"
                h="max-content"
                minH="100%"
                cursor="pointer"
                {...getRootProps({ className: "dropzone" })}
                {...rest}
            >
                <Input variant="main" {...getInputProps()} />
                <Button variant="no-effects" h={"320"}>
                    {!isDragActive && content}
                    {isDragActive && !isDragReject && "Solte seu arquivo aqui!"}
                    {/* {isDragReject && "Formato de arquivo inválido! Somente xlsx é permitido."} */}
                </Button>
            </Flex>
            {/* <Flex>
                <UnorderedList spacing={"3"}>{listFiles}</UnorderedList>
            </Flex> */}
        </Box>
    );
}

export default DropzoneUploadPhoto;
