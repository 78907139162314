import { Flex } from '@chakra-ui/react';
import { IconRefresh } from '@tabler/icons';
import { useQueryClient } from '@tanstack/react-query';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import CheckTable from 'components/dataTables/CheckTable';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { RowIcon } from '../icons/RowIcon';
import Card from 'components/card/Card';
import { calculateTimeDifference } from 'utils/functions/dateUtils';

interface IFormListLogs {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}

const FormBotProcessLogs = (props: IFormListLogs) => {
	const columnsDataLogs = [
		{
			Header: 'Processo',
			accessor: 'processo',
			type: 'text',
			optional: true,
			filter: 'includesIgnoreCase',
		},
		{
			Header: 'Descrição da Atividade',
			accessor: 'descricaoAtividade',
			disableSortBy: true,
			type: 'iconText',
			jsx: RowIcon,
			filter: false,
		},
		{
			Header: 'Data',
			accessor: 'data',
			type: 'date',
			optional: true,
			filter: 'includesIgnoreCase',
		},
		{
			Header: 'Tempo da Atividade',
			accessor: 'tempoAtividade',
			type: 'text',
			optional: true,
			filter: 'includesIgnoreCase',
		},
	];

	const tableColumns = useMemo(() => columnsDataLogs, []);

	const renderRow = useCallback((recentActivity) => {
		const formattedDate = moment.utc(recentActivity.data_hora).local();
		const timeDifference = calculateTimeDifference(formattedDate);

		return {
			id: recentActivity.id,
			usuario: recentActivity.nome_usuario,
			descricaoAtividade: recentActivity.descricao_acao,
			data: moment
				.utc(recentActivity.data_hora)
				.local()
				.format('DD/MM/YYYY HH:mm:ss'),
			tempoAtividade: timeDifference,
			acao: recentActivity.acao,
			status: recentActivity.status,
			processo: recentActivity.uid,
		};
	}, []);

	const renderTable = () => {
		return (
			<CheckTable
				tableTest='robot-logs'
				module='bots'
				columnsData={tableColumns}
				showGenericSearchInput={false}
				filterModule='log/bots'
				renderRow={renderRow}
				pt='85px'
				ml='-8px'
				mr='8px'
			/>
		);
	};

	const queryClient = useQueryClient();
	const iconRefreshInvalidationQuery = () => {
		queryClient.resetQueries(['log-list']);
	};

	return (
		<Card className='form-cadastro'>
			<Flex pl='5px'>
				<Flex mt='8px'>
					<ButtonComponent
						type='icon'
						iconType={<IconRefresh />}
						onClick={iconRefreshInvalidationQuery}
						iconSize={'xs'}
						border='none'
					/>
				</Flex>{' '}
				{renderTable()}
			</Flex>
		</Card>
	);
};

export default FormBotProcessLogs;
