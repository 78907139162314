import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { createCitySalesZone } from 'services/api/requests/commercial';

export const useCreateCitySalesZone = (body) => {
	const queryClient = useQueryClient();
	const toast = useToast();
	const history = useHistory();

	return useMutation(() => createCitySalesZone(body), {
		onSuccess: (sucess) => {
			console.log('sucesso!!');
			console.log(sucess);
			history.goBack();
			queryClient.invalidateQueries(['zonaVenda-list']);
			toast({
				title: 'Criado com sucesso!',
				description: 'Cidade criada e associada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			console.log(error.response.data.message);
			toast({
				title: 'Erro ao criar',
				description: error.response.data[0].message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
