import { Text } from '@chakra-ui/react';

export function renderDeleteMessageForSalesChannel(deleteMessage) {
	const [headerInfo, info, message, dependencies, confirm] = deleteMessage;

	const infoArray = info?.split?.(', ') || [];
	const dependenciesArray = dependencies?.split?.(', ') || [];

	const showInfo = !!infoArray?.length && infoArray?.some((el) => el !== '');

	const showDependencies =
		!!dependenciesArray?.length &&
		dependenciesArray?.some((el) => el !== '');

	return (
		<>
			<Text
				color='gray.500'
				fontSize='sm'
			>
				<p style={{ textAlign: 'justify' }}>{headerInfo}</p>
			</Text>
			{showInfo && (
				<>
					<br />
					<ul>
						{infoArray?.map((el, index) => (
							<Text
								color='gray.500'
								fontSize='sm'
								pl='25'
							>
								<li key={index}>{el}</li>
							</Text>
						))}
					</ul>
					<br />
				</>
			)}
			<Text
				color='gray.500'
				fontSize='sm'
			>
				<p style={{ textAlign: 'justify' }}>{message}</p>
			</Text>
			{showDependencies && (
				<>
					<br />
					<ul>
						{dependenciesArray?.map((el, index) => (
							<Text
								color='gray.500'
								fontSize='sm'
								pl='25'
							>
								<li key={index}>{el}</li>
							</Text>
						))}
					</ul>
				</>
			)}
			<br />
			{!!confirm ? (
				<Text
					color='gray.500'
					fontSize='sm'
				>
					<p style={{ textAlign: 'justify' }}>{confirm}</p>
				</Text>
			) : null}
		</>
	);
}

export function renderDeleteMessage(deleteMessage) {
	if (Array.isArray(deleteMessage)) {
		const [message, dependencies, confirm] = deleteMessage;
		const dependenciesArray = dependencies?.split?.(', ') || [];

		const showDependencies =
			!!dependenciesArray?.length &&
			dependenciesArray?.some((el) => el !== '');

		return (
			<>
				<Text
					color='gray.500'
					fontSize='sm'
				>
					<p style={{ textAlign: 'justify' }}>{message}</p>
				</Text>
				{showDependencies && (
					<>
						<br />
						<ul>
							{dependenciesArray?.map((el, index) => (
								<Text
									color='gray.500'
									fontSize='sm'
									pl='25'
								>
									<li key={index}>{el}</li>
								</Text>
							))}
						</ul>
					</>
				)}
				<br />
				{!!confirm ? (
					<Text
						color='gray.500'
						fontSize='sm'
					>
						<p style={{ textAlign: 'justify' }}>{confirm}</p>
					</Text>
				) : null}
			</>
		);
	}

	return (
		<Text
			color='gray.500'
			fontSize='sm'
		>
			<p style={{ textAlign: 'justify' }}>{deleteMessage}</p>
		</Text>
	);
}
