import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { OptionProps } from 'react-select';
import {
	Box,
	ButtonGroup,
	FormControl,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { IconRefreshAlert } from '@tabler/icons';
import { RateioTypeCalculation } from '../../services/queryClient/wrapperHooks/apportionment/useCalculateApportionment';
import { RecalculateApportionmentModal } from './RecalculateApportionmentModal';
import { RecalculateApportionmentPreviousCycleModal } from './RecalculateApportionmentByPreviousCycleModal';
import { FilterInputState } from 'pages/admin/demanda/rateio/rateio';

interface InputOption {
	value: number;
	children: number;
}

type InputItem = ReactElement<OptionProps<InputOption>>;

interface RecalculateApportionmentModalProps {
	isOpen: boolean;
	onClose: () => void;
	filterInput: FilterInputState;
	yearFilter: InputItem[];
	setFilterInput: Dispatch<SetStateAction<FilterInputState>>;
	cycleFilter: InputItem[];
	cycleData: any[];
	refetchData: any;
	onSuccessCallback?: any;
}

const RateioCalculationModal = (props: RecalculateApportionmentModalProps) => {
	const {
		isOpen,
		onClose,
		filterInput,
		yearFilter,
		setFilterInput,
		cycleFilter,
		cycleData,
		refetchData,
		onSuccessCallback = () => {},
	} = props;

	const {
		isOpen: isOpenRecalculateByHistory,
		onOpen: onOpenRecalculateByHistory,
		onClose: onCloseRecalculateByHistory,
	} = useDisclosure();

	const {
		isOpen: isOpenRecalculateByCycle,
		onOpen: onOpenRecalculateByCycle,
		onClose: onCloseRecalculateByCycle,
	} = useDisclosure();

	const [typeCalculation, setTypeCalculation] = useState<string>();

	function handleOnClick() {
		typeCalculation === RateioTypeCalculation.HISTORICO_COMPLETO
			? onOpenRecalculateByHistory()
			: onOpenRecalculateByCycle();
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size='2xl'
				isCentered
			>
				<ModalOverlay />
				<ModalContent borderRadius='3xl'>
					<ModalHeader>
						<Icon
							as={IconRefreshAlert}
							color='easyRED.300'
							boxSize={14}
						/>
						<Text
							fontWeight={700}
							color='easyBLUE.300'
							fontSize='2xl'
						>
							Deseja recalcular o rateio seguindo qual opção?
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box mb={6}>
							<Text
								color='secondaryGray.500'
								fontWeight={400}
								fontSize='md'
							>
								Note que você poderá seguir seu histórico de
								vendas, definindo o intervalo de tempo desejado,
								ou a distribuição de rateio aplicada no ciclo
								anterior.
								<br />
								<br />
								Selecione a opção desejada:
							</Text>
						</Box>
						<FormControl>
							<Select
								data-test='select-selecione_a_opcao_desejada-modal-rateio'
								placeholder='Selecione'
								borderRadius='16px'
								name='year'
								onChange={(e: any) =>
									setTypeCalculation(e?.target?.value)
								}
								value={typeCalculation}
								color='easyBLUE.300'
							>
								<option
									value={
										RateioTypeCalculation.HISTORICO_COMPLETO
									}
								>
									Histórico de Vendas
								</option>
								<option
									value={
										RateioTypeCalculation.RATEIO_ANTERIOR
									}
								>
									Ciclo Anterior
								</option>
							</Select>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<ButtonGroup>
							<ButtonComponent
								data-test={
									'button-cancelar-deseja_recalcular_o_rateio_seguindo_qual_opcao-modal-rateio'
								}
								type='ghost'
								title='Cancelar'
								onClick={onClose}
							/>
							<ButtonComponent
								data-test={
									'button-confirmar-deseja_recalcular_o_rateio_seguindo_qual_opcao-modal-rateio'
								}
								type='secondary'
								title='Confirmar'
								onClick={handleOnClick}
								isDisabled={!typeCalculation}
							/>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<RecalculateApportionmentModal
				isOpen={isOpenRecalculateByHistory}
				onClose={onCloseRecalculateByHistory}
				yearFilter={yearFilter}
				filterInput={filterInput}
				setFilterInput={setFilterInput}
				cycleFilter={cycleFilter}
				onClosePrevious={onClose}
				refetchData={refetchData}
				onSuccessCallback={onSuccessCallback}
			/>

			<RecalculateApportionmentPreviousCycleModal
				cycleData={cycleData}
				currentCycleId={+filterInput?.cycleId}
				isOpen={isOpenRecalculateByCycle}
				onClose={onCloseRecalculateByCycle}
				onClosePrevious={onClose}
				refetchData={refetchData}
				onSuccessCallback={onSuccessCallback}
			/>
		</>
	);
};

export default RateioCalculationModal;
