import { createContext, useContext } from 'react';
import {
	ConfigsCompany,
	ModulesPermissionsUser,
	PermissionsPagesUser,
	UserAttributes,
} from './models/globalContextModels';

interface GlobalContextProps {
	routes: any;
	pathCompanyName: string | undefined;
	userAttributes: any;
	permissionsPage: PermissionsPagesUser | [];
	modulesPermissions: ModulesPermissionsUser | [];
	setUserAttributes: React.Dispatch<React.SetStateAction<UserAttributes>>;
	userInfos: { [key: string]: string | number | boolean };
	setUserInfos: React.Dispatch<
		React.SetStateAction<{ [key: string]: string | number | boolean }>
	>;
	configsCompany: ConfigsCompany;
	refreshPermissions: () => void;
}
export const GlobalContext = createContext({} as GlobalContextProps);

export const useGlobalContext = () => useContext(GlobalContext);
