import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getUserAccount } from 'services/api/requests/authentication';

export const useGetUserAccount = () => {
	const toast = useToast();
	return useQuery(['getUserAccount'], getUserAccount, {
		onSuccess: () => {},
		onError: (error: any) => {
			if (error.response.data.length === 1) {
				const errorMessage = error.response.data[0].message;
				const pathNameAdjustment = error.response.data[0].path.replace(
					/_/g,
					' ',
				);
				toast({
					title: 'Erro ao atualizar usuário(a).',
					description: `${errorMessage} Campo: ${pathNameAdjustment}`,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else if (error.response.data.length > 1) {
				error.response.data.forEach((error, index) => {
					const errorMessage = error.response.data[index].message;
					const pathNameAdjustment = error.response.data[
						index
					].path.replace(/_/g, ' ');
					toast({
						title: 'Erro ao atualizar usuário(a).',
						description: `${errorMessage} Campo: ${pathNameAdjustment}`,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				});
			}
		},
		refetchOnWindowFocus: false,
	});
};
