import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { useCallback, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';

interface ICheckTableActionsEdit {
	// filters: any;
	massEditingActions: (filters: any) => {};
	allSelectedItens: boolean;
	getSelectedIds: () => number[];
	pageSize: number;
	totalRegisters: number;
	dataAction: any;
}

const CheckTableActionsEdit = ({
	// filters,
	massEditingActions,
	allSelectedItens,
	getSelectedIds,
	pageSize,
	totalRegisters,
	dataAction,
}: ICheckTableActionsEdit) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const [isSelectedAll, setSelectedAll] = useState<boolean>(false);
	const selectedIds = getSelectedIds();
	const [messageModal, setMessageModal] = useState<string>('');

	function openModal(value: string) {
		setMessageModal(value);
		onOpen();
	}

	function onAction() {
		const action = messageModal === 'add' ? true : false;
		let actionFilters = {};
		if (isSelectedAll)
			actionFilters = {
				todos: isSelectedAll,
				ids: [],
				action: action,
			};
		else
			actionFilters = {
				todos: isSelectedAll,
				ids: selectedIds,
				action: action,
			};

		massEditingActions(actionFilters);

		onClose();
	}

	const menuOptions = useCallback(() => {
		return (
			dataAction.menuOptions?.map((option) => {
				return (
					<MenuItem
						key={option.value}
						onClick={() => openModal(option.value)}
					>
						<Text color='easyBLUE.300'>{option.label}</Text>
					</MenuItem>
				);
			}) || []
		);
	}, [dataAction]);

	function renderButton() {
		return (
			<Menu>
				<MenuButton
					data-test={'menu_button-opcoes'}
					as={Button}
					rightIcon={<AddIcon boxSize={3} />}
					size='large'
					fontWeight='100'
					px='30px'
					py='8px'
					border='1px solid'
					borderRadius='100px'
					borderColor='easyBLUE.150'
					fontSize='16px'
					color='easyBLUE.300'
					_hover={{ bg: '#FAFCFE' }}
					_focus={{ bg: 'white' }}
					_active={{ bg: '#E2E8F0', boxShadow: 'none' }}
				>
					<Text>Opções</Text>
				</MenuButton>
				<MenuList>
					{!!selectedIds?.length || allSelectedItens ? (
						menuOptions()
					) : (
						<Text
							color='easyBLUE.300'
							as={MenuItem}
						>
							Por favor, selecione pelo menos um item!
						</Text>
					)}
				</MenuList>
			</Menu>
		);
	}

	const selectedItems = useCallback(() => {
		if (isSelectedAll) {
			return totalRegisters;
		} else if (!isSelectedAll && selectedIds?.length) {
			return selectedIds?.length;
		}
	}, [isSelectedAll, selectedIds, totalRegisters]);

	function renderAllSelected() {
		if (allSelectedItens) {
			return (
				<Flex
					alignItems='center'
					ml='20px'
				>
					<Text>
						Todos os {selectedItems()} registros estão selecionados.
					</Text>
					<ButtonComponent
						type='ghost'
						title={
							isSelectedAll
								? `Remover seleção dos ${totalRegisters} registros.`
								: `Selecionar todos os ${totalRegisters} registros.`
						}
						border='none'
						textColor='#0296f2'
						onClick={() => setSelectedAll((prev) => !prev)}
						px={2}
					/>
				</Flex>
			);
		}

		return null;
	}

	function renderModal() {
		const isAddMessage = messageModal === 'add';
		const header = isAddMessage
			? dataAction?.modalTitleAdd
			: dataAction?.modalTitleRemove;
		const body = isAddMessage
			? dataAction?.modalTextAdd
			: dataAction?.modalTextRemove;
		const buttonConfirm = dataAction?.buttonText
			? dataAction?.buttonText
			: 'Editar';
		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				onEsc={onClose}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader textColor={'easyBLUE.300'}>
						{header}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							{body} <br /> <br />
							{`Total de itens selecionados: ${
								isSelectedAll
									? totalRegisters
									: selectedIds?.length
							}`}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Flex gap={1}>
							<ButtonComponent
								data-test='button-cancelar-modal'
								type='ghost'
								title='Cancelar'
								onClick={onClose}
							/>
							<ButtonComponent
								data-test='button-sim-modal'
								type='primary'
								title={buttonConfirm}
								onClick={onAction}
							/>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	return (
		<Flex
			direction='row'
			ml='20px'
			mr='20px'
		>
			{renderButton()}
			{renderAllSelected()}
			{renderModal()}
		</Flex>
	);
};

export default CheckTableActionsEdit;
