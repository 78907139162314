import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import './chartsGrouped.css';

export const barChartOptions = (
	decimalPrecision,
	text = 'Desejado x Atual',
	customTooltip,
) => {
	const defaultTooltip = {
		shared: true,
		intersect: false,
		theme: 'dark',
		y: {
			formatter: function (y) {
				if (typeof y !== 'undefined') {
					return numberToFormattedString(y, decimalPrecision);
				}
				return y;
			},
		},
	};

	const tooltip = customTooltip || defaultTooltip;

	return {
		chart: {
			height: 350,
			type: 'bar',
			toolbar: {
				show: false,
			},
		},
		grid: {
			show: false,
		},
		title: {
			text,
			style: {
				color: '#fff',
				fontWeight: '500',
			},
		},
		noData: {
			text: 'Para o dashboard funcionar corretamente também é necessário cadastrar as paradas, recursos, bem como estoque atual!',
			align: 'center',
			verticalAlign: 'middle',
			offsetX: 0,
			offsetY: 0,
			style: {
				color: '#fff',
				fontSize: '14px',
			},
		},
		yaxis: {
			show: false,
		},
		plotOptions: {
			bar: {
				borderRadius: 4,
				columnWidth: '60%',
			},
		},
		colors: ['#56CCF2'],
		dataLabels: {
			enabled: true,
			formatter: function (y) {
				if (typeof y !== 'undefined') {
					return numberToFormattedString(y, decimalPrecision);
				}
				return y;
			},
		},
		xaxis: {
			type: 'text',
			labels: {
				style: {
					colors: [
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
						'#fff',
					],
				},
			},
		},
		tooltip,
		legend: {
			show: true,
			showForSingleSeries: true,
			customLegendItems: ['Atual', 'Desejado'],
			labels: {
				colors: '#fff',
				useSeriesColors: false,
			},
			markers: {
				fillColors: ['#56CCF2', '#FC6574'],
			},
		},
	};
};

export const lineChartOptions = (theme, title, days, target) => ({
	chart: {
		type: 'line',
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
		stacked: false,
		redrawOnParentResize: true,
		redrawOnWindowResize: true,
	},
	title: {
		text: title,
		style: {
			color: '#fff',
			fontWeight: '500',
		},
	},
	noData: {
		text: 'Para o dashboard funcionar corretamente também é necessário cadastrar as paradas, recursos, bem como estoque atual!',
		align: 'center',
		verticalAlign: 'middle',
		offsetX: 0,
		offsetY: 0,
		style: {
			color: '#fff',
			fontSize: '14px',
		},
	},
	annotations: {
		position: 'front',
		yaxis: [
			{
				y: target,
				borderColor: '#20bf6b',
				label: {
					borderColor: '#20bf6b',
					style: {
						color: '#fff',
						background: '#20bf6b',
						fontWeight: '600',
						fontSize: '11px',
					},
					text: `Meta (${target ? target + '%' : 0})`,
				},
			},
		],
	},
	fill: {
		type: 'gradient',
		opacity: 0.9,
		gradient: {
			shade: 'dark',
			type: 'horizontal',
			shadeIntensity: 0.2,
			gradientToColors: undefined,
			inverseColors: true,
			opacityFrom: 0.4,
			opacityTo: 0.8,
			stops: [0, 60, 90],
		},
	},
	colors: theme,
	labels: days,
	dataLabels: {
		enabled: true,
		formatter: function (val) {
			return val + '%';
		},
	},
	xaxis: {
		type: 'datetime',
		labels: {
			style: {
				colors: '#fff',
			},
		},
	},
	yaxis: {
		show: true,
		min: 0,
		max: 100,
		labels: {
			show: false,
		},
	},
	plotOptions: {
		line: {
			columnWidth: '60%',
		},
	},
	stroke: {
		show: true,
		width: 2,
		dashArray: 0,
	},
	tooltip: {
		shared: true,
		theme: 'dark',
		intersect: false,
		y: {
			formatter: (seriesName) => seriesName + '%',
		},
	},
	grid: {
		show: false,
	},
});

export const donutChartOptions = (
	decimalPrecision,
	labels,
	graphicColors,
	title,
) => {
	return {
		chart: {
			type: 'donut',
		},
		title: {
			text: title,
			style: {
				color: '#fff',
				fontWeight: '500',
			},
		},
		stroke: {
			width: 1,
			colors: 'transparent',
		},
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						position: 'bottom',
					},
				},
			},
		],
		dataLabels: {
			enabled: true,
			textAnchor: 'middle',
			style: {
				fontWeight: '400',
			},
			dropShadow: {
				enabled: false,
			},
		},
		legend: {
			position: 'right',
			labels: {
				colors: '#fff',
				useSeriesColors: false,
			},
			formatter: function (val, opts) {
				const hasStock = opts.w.globals.series[opts.seriesIndex];

				if (hasStock) {
					return val;
				}

				const noStockText = `<span style="color: #bbbb;">${val} (sem estoque)</span>`;
				return noStockText;
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
		labels: labels ? labels : [],
		colors: [
			function ({ value, seriesIndex, w }) {
				if (value === 0) {
					return '#E1E9F8';
				}

				const colors = [
					graphicColors.red,
					graphicColors.lightRed,
					graphicColors.lightBlue,
					graphicColors.turquoise,
					graphicColors.green,
					graphicColors.pastelGreen,
					graphicColors.yellow,
					graphicColors.purple,
					graphicColors.lightPurple,
				];

				return seriesIndex < colors.length
					? colors[seriesIndex]
					: '#E1E9F8';
			},
		],
		noData: {
			text: 'Para o dashboard funcionar corretamente é necessário cadastrar estoque atual!',
			align: 'center',
			verticalAlign: 'middle',
			offsetX: 0,
			offsetY: 0,
			style: {
				color: '#fff',
				fontSize: '14px',
			},
		},
		tooltip: {
			theme: 'dark',
			fillSeriesColor: false,
			y: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return numberToFormattedString(y, decimalPrecision);
					}
					return y;
				},
			},
		},
	};
};

export const groupedStackedBarChartOptions = (
	decimalPrecision,
	title,
	noDataText,
	categories,
	graphicColors,
	customTooltip,
) => {
	const standardTooltip = {
		shared: true,
		intersect: false,
		theme: 'dark',
	};

	return {
		chart: {
			height: 350,
			toolbar: {
				show: false,
			},
			stacked: true,
		},
		dataLabels: {
			formatter: function (y) {
				if (typeof y !== 'undefined') {
					return numberToFormattedString(y, decimalPrecision);
				}
				return y;
			},
			style: {
				fontSize: '10px',
			},
		},
		stroke: {
			width: 0,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '70%',
			},
		},
		grid: {
			show: false,
		},
		xaxis: {
			categories: categories,
			labels: {
				style: {
					colors: '#fff',
				},
				trim: true,
			},
		},
		fill: {
			opacity: 1,
		},
		colors: [
			graphicColors.green,
			graphicColors.lightBlue,
			graphicColors.gray,
		],
		yaxis: {
			show: false,
			labels: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return numberToFormattedString(y, decimalPrecision);
					}
					return y;
				},
			},
		},
		tooltip: customTooltip || standardTooltip,
		title: {
			text: title,
			style: {
				color: '#fff',
				fontWeight: '500',
			},
		},
		legend: {
			labels: {
				colors: '#fff',
				useSeriesColors: false,
			},
		},
		noData: {
			text: noDataText,
			offsetX: 0,
			offsetY: 0,
			style: {
				color: '#fff',
				fontSize: '14px',
			},
		},
	};
};

export const StackedCombinedBarChartOptions = (
	decimalPrecision,
	graphicColors,
) => {
	return {
		chart: {
			height: 350,
			toolbar: {
				show: false,
			},
			stacked: true,
		},
		title: {
			text: 'Estoque de segurança x Plano restrito x Estoque atual',
			style: {
				color: '#fff',
				fontWeight: '500',
			},
		},
		yaxis: {
			show: false,
		},
		grid: {
			show: false,
		},
		plotOptions: {
			bar: {
				// borderRadius: 4,
				columnWidth: '60%',
			},
		},
		colors: [
			graphicColors.green,
			graphicColors.lightBlue,
			graphicColors.lightRed,
		],
		dataLabels: {
			enabled: true,
			formatter: function (y) {
				if (typeof y !== 'undefined') {
					return numberToFormattedString(y, decimalPrecision);
				}
				return y;
			},
		},

		legend: {
			show: true,
			showForSingleSeries: true,
			customLegendItems: [
				'Estoque de segurança',
				'Plano restrito',
				'Estoque atual',
			],
			markers: {
				fillColors: ['#00E396', '#56CCF2', '#FC6574'],
			},
			labels: {
				colors: '#fff',
				useSeriesColors: false,
			},
		},
		tooltip: {
			shared: false,
			intersect: true,
			theme: 'dark',
			custom: ({ series, seriesIndex, dataPointIndex, w }) => {
				// console.log('Valores do tooltip:', {
				// 	series,
				// 	seriesIndex,
				// 	dataPointIndex,
				// 	w,
				// });
				const formattedNumber = (value) => {
					if (typeof value !== 'undefined') {
						return numberToFormattedString(value, decimalPrecision);
					}
					return value;
				};

				const seriesName = w.config.series[seriesIndex].name;
				const data = w.config.series[seriesIndex].data[dataPointIndex];
				const valueY = formattedNumber(data.y); // Valor
				const valueX = data.x; // Titulo
				const date = data.date;
				const goals = data.goals[0];
				const valueGoals =
					goals.value === null
						? 'Produto não tem estoque atual cadastrado.'
						: goals.value; // Valor da meta
				const nameGoals = goals.name; // Nome da meta
				const dateGoals = goals.date; // Data da meta,

				const customTooltipContent = `
			<div class="apexcharts-tooltip-title">${valueX}</div>
			<div class="apexcharts-custom-tooltip" style="--bullet-color: ${data.color}; 
			--horizontal-line-color: ${goals.color}">
				<div class="apexcharts-custom-tooltip">
					<div class="info-group">
						<div>
							<span class="bullet"></span>
							${seriesName}: <span>${valueY}</span>
						</div>
						<div>
							<span class="horizontal-line"></span>
							${nameGoals}: <span>${valueGoals}</span>
						</div>
					</div>
					<div class="date-group">
						<div>${'Data ' + seriesName}: <span>${date}</span></div>
						<div>${'Data ' + nameGoals}: <span>${dateGoals}</span></div>
					</div>
				</div>
			</div>
			`;

				return customTooltipContent;
			},
		},
		xaxis: {
			labels: {
				style: {
					colors: '#fff',
				},
				trim: true,
			},
		},
		noData: {
			text: 'Para o dashboard funcionar corretamente também é necessário cadastrar as paradas, recursos, bem como estoque atual!',
			offsetX: 0,
			offsetY: 0,
			style: {
				color: '#fff',
				fontSize: '14px',
			},
		},
	};
};
