import { Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import IconBox from 'components/icons/IconBox';
import MiniStatistics from 'components/card/MiniStatistics';
import { IconReportMoney, IconVersions } from '@tabler/icons';
import moment from 'moment';
import { useCallback } from 'react';

interface MiniStatisticsCardsProps {
	data: any;
	month: string;
	day: string;
}

const MiniStatisticsCards = (props: MiniStatisticsCardsProps) => {
	const { data, month, day } = props;

	const intValue = (value: number): number => {
		return Math.trunc(value);
	};

	const faturadoBoaMeta = data
		? intValue(
				data?.faturadoMaisCarteiraBoa - data?.meta || 0,
		  ).toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
		  })
		: '';
	const faturadoMeta = data
		? intValue(data?.faturadoMesAtual - data?.meta || 0).toLocaleString(
				'pt-BR',
				{
					style: 'currency',
					currency: 'BRL',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
		  )
		: '';
	const actualDay = +moment().format('D');
	const actualMonth = +moment().format('M');

	const formatPercentage = (value: number) => {
		if (value === null || value === undefined) {
			return '';
		}

		let formattedValue;
		if (value !== Math.floor(value)) {
			formattedValue = `${value.toFixed(2).replace('.', ',')}%`;
		} else {
			formattedValue = `${value.toString().replace('.', ',')}%`;
		}
		return formattedValue;
	};

	const getCadenciaVendasDoDia = useCallback((data, actualDay) => {
		const cadenciaDoDia = data?.cadenciaVendasEstatisticas
			? data.cadenciaVendasEstatisticas[actualDay - 1]?.cadencia_venda_dia
			: 0;

		return formatPercentage(cadenciaDoDia);
	}, []);

	const cadenciaDoDia = getCadenciaVendasDoDia(data, actualDay);
	const candenciaDoDiaDiferenteMes = getCadenciaVendasDoDia(data, day);

	const isSameMonth = +month === actualMonth;
	const showCadencia = isSameMonth
		? cadenciaDoDia
		: candenciaDoDiaDiferenteMes;

	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const returnDayOfMonth = useCallback(() => {
		return data && isSameMonth
			? moment().format('DD/MM')
			: data && !isSameMonth
			? moment(`${month}-${day}`, 'MM-DD').format('DD/MM')
			: '';
	}, [data, isSameMonth]);

	const dayOfMonth = returnDayOfMonth();

	return (
		<SimpleGrid
			columns={1}
			spacingY={2}
			width={{ base: '100%', md: '100%', xl: '100%' }}
		>
			<MiniStatistics
				startContent={
					<IconBox
						w='56px'
						h='56px'
						s
						bg={boxBg}
						icon={
							<Icon
								w='32px'
								h='32px'
								as={IconReportMoney}
								color={'easyRED.300'}
							/>
						}
					/>
				}
				name='Dif. R$ :: Faturado + Boa [-] Meta'
				value={faturadoBoaMeta}
			/>
			<MiniStatistics
				startContent={
					<IconBox
						w='56px'
						h='56px'
						bg={boxBg}
						icon={
							<Icon
								w='32px'
								h='32px'
								as={IconReportMoney}
								color={'easyRED.300'}
							/>
						}
					/>
				}
				name='Dif. R$ :: Faturado [-] Meta'
				value={faturadoMeta}
			/>
			<MiniStatistics
				startContent={
					<IconBox
						w='56px'
						h='56px'
						bg={boxBg}
						icon={
							<Icon
								w='32px'
								h='32px'
								as={IconVersions}
								color={'easyRED.300'}
							/>
						}
					/>
				}
				name={`Cadência do dia`}
				data={dayOfMonth}
				value={showCadencia}
			/>
		</SimpleGrid>
	);
};

export default MiniStatisticsCards;
