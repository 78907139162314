import api from 'services/api/api';

export const getParameterizationCompanyById = async () => {
	const url = `/empresa/parametrizacao`;
	const response = await api.get(url);
	return response.data;
};

export const editParameterizationCompany = async (body) => {
	const url = `/empresa/parametrizacao`;
	const response = await api.patch(url, body);
	return response.data;
};
