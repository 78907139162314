export const sxMenuFilter = {
	'&::-webkit-scrollbar': {
		width: '8px',
		height: '8px',
	},
	'&::-webkit-scrollbar-track': {
		// boxShadow: 'inset 0 0 2px grey',
		// borderRadius: '10px',
		// backgroundColor: 'rgb(235, 236, 240)',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: 'rgb(194, 201, 225)',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb:hover': {
		backgroundColor: 'rgb(177, 187, 221)',
	},
};
