import { IManualPaging } from 'components/dataTables/ManualPaging';
import { createContext, useContext, useState } from 'react';

interface IContext {
	selectedTab: any;
	setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
	selectedProducts: any;
	setSelectedProducts: React.Dispatch<React.SetStateAction<any>>;
	selectProductOptions: number[];
	setSelectProductOptions: React.Dispatch<React.SetStateAction<number[]>>;
	productsPagination: IManualPaging;
	setProductsPagination: React.Dispatch<React.SetStateAction<IManualPaging>>;
	selectedFamilies: any;
	setSelectedFamilies: React.Dispatch<React.SetStateAction<any>>;
	selectFamilyOptions: number[];
	setSelectFamilyOptions: React.Dispatch<React.SetStateAction<number[]>>;
	familiesPagination: IManualPaging;
	setFamiliesPagination: React.Dispatch<React.SetStateAction<IManualPaging>>;
	selectedProjectionStock: any;
	setSelectedProjectionStock: React.Dispatch<React.SetStateAction<any>>;
	selectProjectionStockOptions: number[];
	setSelectProjectionStockOptions: React.Dispatch<
		React.SetStateAction<number[]>
	>;
	projectionStockPagination: IManualPaging;
	setProjectionStockPagination: React.Dispatch<
		React.SetStateAction<IManualPaging>
	>;
}

export const ContextDashboardProducao = createContext<IContext>({} as IContext);

export default function Provider({ children }) {
	const [selectedTab, setSelectedTab] = useState<string>('produtos');

	const [selectedProducts, setSelectedProducts] = useState<any>([]);

	const [selectProductOptions, setSelectProductOptions] = useState<number[]>(
		[],
	);

	const [productsPagination, setProductsPagination] = useState<IManualPaging>(
		{
			page: 0,
			size: 9,
			totalPages: 0,
		},
	);

	const [selectedFamilies, setSelectedFamilies] = useState<any>([]);

	const [selectFamilyOptions, setSelectFamilyOptions] = useState<number[]>(
		[],
	);

	const [familiesPagination, setFamiliesPagination] = useState<IManualPaging>(
		{
			page: 0,
			size: 9,
			totalPages: 0,
		},
	);

	const [selectedProjectionStock, setSelectedProjectionStock] = useState<any>(
		[],
	);

	const [selectProjectionStockOptions, setSelectProjectionStockOptions] =
		useState<number[]>([]);

	const [projectionStockPagination, setProjectionStockPagination] =
		useState<IManualPaging>({
			page: 0,
			size: 9,
			totalPages: 0,
			otherParams: { participa_rateio: true },
		});

	return (
		<ContextDashboardProducao.Provider
			value={{
				selectedTab,
				setSelectedTab,
				selectedProducts,
				setSelectedProducts,
				selectProductOptions,
				setSelectProductOptions,
				productsPagination,
				setProductsPagination,
				selectedFamilies,
				setSelectedFamilies,
				selectFamilyOptions,
				setSelectFamilyOptions,
				familiesPagination,
				setFamiliesPagination,
				selectedProjectionStock,
				setSelectedProjectionStock,
				selectProjectionStockOptions,
				setSelectProjectionStockOptions,
				projectionStockPagination,
				setProjectionStockPagination,
			}}
		>
			{children}
		</ContextDashboardProducao.Provider>
	);
}

export const useDashboardProducaoContext = () => {
	return useContext(ContextDashboardProducao);
};
