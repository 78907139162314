import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataOportunidades = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Cód.',
		accessor: 'codigoOportunidade',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Código da Oportunidade',
	},
	{
		Header: 'Seq.',
		accessor: 'sequenciaCodigoOportunidade',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Sequência do Código da Oportunidade',
	},
	{
		Header: 'Competência',
		accessor: 'competencia',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Ciclo/Ano',
		accessor: 'cicloAno',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cliente',
		accessor: 'cliente',
		type: 'autocomplete',
		optional: false,
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'CNPJ/CPF/ID Estrangeiro',
		accessor: 'cnpjCpfId',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Família',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Produto',
		accessor: 'produto',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigoProduto',
		type: 'disabledCodigoProduto',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		type: 'number',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Membro',
		accessor: 'membro',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		filter: false,
		jsx: RowEditAndDelete,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'PRODUTO',
		accessor: 'produto',
	},
	{
		Header: 'FAMÍLIA',
		accessor: 'familia',
	},
	{
		Header: 'SUBFAMÍLIA',
		accessor: 'subfamilia',
	},
	{
		Header: 'MODELO',
		accessor: 'modelo',
	},
	{
		Header: 'COD.PRODUTO',
		accessor: 'codproduto',
	},
];

export const columnsDataOpportunities = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'Razão Social',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
	{
		Header: 'CNPJ/CPF',
		accessor: 'cnpjCpf',
		type: 'text',
		body: 'cnpj',
	},
];
