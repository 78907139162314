import { IEnvironmentEntity } from '../environment';
export interface IOptionCognitoConfig {
	aws_project_region?: string;
	aws_cognito_region?: string;
	aws_user_pools_id?: string;
	aws_user_pools_web_client_id?: string;
	oauth: {};
}
const awsHomologacao: IOptionCognitoConfig = {
	aws_project_region: process.env.REACT_APP_FRONTEND_COGNITO_REGION,
	aws_cognito_region: process.env.REACT_APP_FRONTEND_COGNITO_REGION,
	aws_user_pools_id:
		process.env.REACT_APP_FRONTEND_COGNITO_USER_POOL_ID_STAGING,
	aws_user_pools_web_client_id:
		process.env.REACT_APP_FRONTEND_COGNITO_CLIENT_ID_STAGING,
	oauth: {},
};
const awsProduction: IOptionCognitoConfig = {
	aws_project_region: process.env.REACT_APP_FRONTEND_COGNITO_REGION,
	aws_cognito_region: process.env.REACT_APP_FRONTEND_COGNITO_REGION,
	aws_user_pools_id:
		process.env.REACT_APP_FRONTEND_COGNITO_USER_POOL_ID_PRODUCTION,
	aws_user_pools_web_client_id:
		process.env.REACT_APP_FRONTEND_COGNITO_CLIENT_ID_PRODUCTION,
	oauth: {},
};
export const aws: IEnvironmentEntity = {
	development: awsHomologacao,
	staging: awsHomologacao,
	production: awsProduction,
};
