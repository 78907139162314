import { useMutation } from '@tanstack/react-query';
import { getFamiliesByFiltersAndLimit } from 'services/api/requests/requests';
import { IAutocomplete } from '../../../utils/forms/autocomplete';

export const useFamilyListWithoutProductsAsMutate = ({
	produtos_status,
	produtos_participa_rateio,
	busca,
	limit,
	orderBy = 'nome_familia',
	sort = 'ASC',
}: IAutocomplete) => {
	return useMutation(() =>
		getFamiliesByFiltersAndLimit(
			produtos_status,
			produtos_participa_rateio,
			busca,
			limit,
			orderBy,
			sort,
		),
	);
};
