import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Progress,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

export const LoadingUpload = (props) => {
	const { isOpen, onClose, headerText, uploadPercentage } = props;

	const easyRED = useColorModeValue('easyRED.300', 'easyRED.200');
	const easyBLUE = useColorModeValue('easyBLUE.300', 'easyBLUE.200');
	const bg = useColorModeValue('secondaryGray.300', 'secondaryGray.400');
	const borderColor = useColorModeValue(
		'secondaryGray.400',
		'whiteAlpha.100',
	);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={'3xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={'30px'}
					h={'480px'}
				>
					<ModalHeader
						textColor={easyBLUE}
						fontSize={'24px'}
						fontWeight='700'
					>
						{headerText}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box
							h={'320px'}
							backgroundColor={bg}
							border='1px dashed'
							borderColor={borderColor}
							borderRadius='16px'
							w='100%'
							minH='100%'
							display='flex'
							flexDir={'column'}
							alignItems='center'
							justifyContent='center'
						>
							<Text
								align='center'
								color='secondaryGray.500'
								fontSize='16px'
								fontWeight='700'
							>
								Take it easy. O seu arquivo está sendo
								processado.{''}
								<Text
									color='secondaryGray.500'
									fontSize='16px'
									fontWeight='500'
								>
									Você pode fechar esta janela e continuar
									utilizando a Easy360.
									<Text
										color='secondaryGray.500'
										fontSize='16px'
										fontWeight='500'
									>
										Avisaremos quando sua importação estiver
										pronta para revisão.
									</Text>
								</Text>
							</Text>
							<Progress
								mt={'20px'}
								value={uploadPercentage}
								height='27px'
								width='80%'
								sx={{
									'& > div': {
										background: easyRED,
									},
								}}
							/>
						</Box>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
