import { Flex, Icon, Stack } from '@chakra-ui/react';
import { IconEdit, IconHandStop } from '@tabler/icons';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';

export default function CollunEditOrColab({ row: colab }) {
	const history = useHistory();
	const { cicloId } = colab;
	const { pathCompanyName } = useGlobalContext();
	const goToCadastroConsenso = () => {
		history.push(
			`/${pathCompanyName}/demanda/consenso/criar?cicloId=${cicloId}`,
		);
	};
	return (
		<Flex
			cursor='pointer'
			h='max-content'
			w='max-content'
		>
			<Stack
				direction='row'
				alignSelf={{ base: 'unset', md: 'flex-end' }}
				spacing='4px'
			>
				{colab.status === 'Completa' ? (
					<Icon
						data-test='link-editar'
						color='secondaryGray.500'
						as={IconEdit}
						w='20px'
						h='20px'
						onClick={goToCadastroConsenso}
					/>
				) : (
					<Icon
						data-test='link-editar'
						color='red.500'
						as={IconHandStop}
						w='20px'
						h='20px'
						onClick={goToCadastroConsenso}
					/>
				)}
			</Stack>
		</Flex>
	);
}
