import {
	defaultFormStyle,
	defaultInputStyle,
	defaultModalFormStyle,
} from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validateRequired } from '../../utils/forms/validations';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useGetFilterCommercialHierarchy } from 'services/queryClient/wrapperHooks/useGetFilterCommercialHierarchy';
import { useCreateMarket } from 'services/queryClient/wrapperHooks/useCreateMarket';
import { useCreateSalesChannel } from 'services/queryClient/wrapperHooks/useCreateSalesChannel';
import { useCreateSalesForce } from 'services/queryClient/wrapperHooks/useCreateSalesForce';
import { useCreateSalesZone } from 'services/queryClient/wrapperHooks/useCreateSalesZone';
import { useCreateHierarchy } from 'services/queryClient/wrapperHooks/useCreateHierarchy';
import { useEditHierarchy } from 'services/queryClient/wrapperHooks/useEditHierarchy';
import { IconEye } from '@tabler/icons';
import CheckTableModal from 'components/modal/CheckTableModal';
import { buildCheckTableModalProps } from 'pages/admin/cadastros/comercial/mercado/functions/util';

interface IFormCadastrarEditarHierarquia {
	initialState: any;
	hierarchyId?: number;
	additionalData?: any;
	setCanRefetchData?: any;
}

const FormCadastrarEditarHierarquia = ({
	initialState,
	hierarchyId = 0,
	additionalData,
	setCanRefetchData = () => {},
}: IFormCadastrarEditarHierarquia) => {
	const history = useHistory();

	const [payload, setPayload] = useState<any>();
	const [selectedModuleProps, setSelectedModuleProps] = useState<any>();

	const {
		isOpen: isOpenModalCreate,
		onOpen: onOpenModalCreate,
		onClose: onCloseModalCreate,
	} = useDisclosure();

	const {
		isOpen: isOpenModalList,
		onOpen: onOpenModalList,
		onClose: onCloseModalList,
	} = useDisclosure();

	const [canalVendaId, setCanalVendaId] = useState<number>();
	const [formInitialState, setFormInitialState] = useState<any>();
	const [formProps, setFormProps] = useState<any>();
	const [modalProps, setModalProps] = useState<any>();
	const [selectOptions, setSelectOptions] = useState<any>();
	const [currentFilterModule, setCurrentFilterModule] = useState<string>('');

	const isEditing = !!hierarchyId;

	const {
		data: CommercialHierarchyData,
		isLoading: isLoadingCommercialHierarchyData,
	} = useGetFilterCommercialHierarchy(isEditing ? undefined : canalVendaId);

	const { mutate: createHierarchy } = useCreateHierarchy(payload);
	const { mutate: updateHierarchy } = useEditHierarchy(payload, hierarchyId);

	const { mutate: createMarket } = useCreateMarket(formProps);
	const { mutate: createSalesChannel } = useCreateSalesChannel(
		undefined,
		undefined,
		formProps,
		setCanalVendaId,
	);
	const { mutate: createSalesForce } = useCreateSalesForce(
		undefined,
		undefined,
		formProps,
	);
	const { mutate: createSalesZone } = useCreateSalesZone(
		undefined,
		undefined,
		formProps,
	);

	useEffect(() => {
		setFormInitialState(initialState);
		setCanalVendaId(initialState?.canal_venda);
	}, [initialState]);

	useEffect(() => {
		if (CommercialHierarchyData) {
			if (formProps) {
				const { getValues } = formProps;
				setFormInitialState({
					...getValues(),
					canal_venda: !!canalVendaId ? String(canalVendaId) : '',
				});
			}

			const options = {
				forcaVenda: !!CommercialHierarchyData?.forcaVenda?.length
					? [...CommercialHierarchyData?.forcaVenda]
					: [],
				zonaVenda: !!CommercialHierarchyData?.zonaVenda?.length
					? [...CommercialHierarchyData?.zonaVenda]
					: [],
			};

			/**
			 * Necessário pois durante a edição o backend não devolve no endpoint de filtros os valores
			 * para FORÇA DE VENDA e ZONA DE VENDA.
			 */
			if (additionalData?.forcaVenda)
				options?.forcaVenda?.splice(0, 0, additionalData.forcaVenda);

			if (additionalData?.zonaVenda)
				options?.zonaVenda?.splice(0, 0, additionalData.zonaVenda);

			setSelectOptions(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [CommercialHierarchyData, additionalData, canalVendaId]);

	const goToHierarchies = useCallback(() => {
		history.goBack();
	}, [history]);

	const renderSelectMercado = useCallback(() => {
		if (!CommercialHierarchyData) return [];

		return CommercialHierarchyData?.mercados?.map((market) => (
			<option
				value={market?.id}
				key={market?.id}
			>
				{market?.nome_mercado}
			</option>
		));
	}, [CommercialHierarchyData]);

	const renderSelectCanalVenda = useCallback(() => {
		if (!CommercialHierarchyData) return [];

		return CommercialHierarchyData?.canalVenda?.map((salesChannel) => (
			<option
				value={salesChannel?.id}
				key={salesChannel?.id}
			>
				{salesChannel?.nome_canal_venda}
			</option>
		));
	}, [CommercialHierarchyData]);

	const renderSelectForcaVenda = useCallback(() => {
		if (!selectOptions?.forcaVenda?.length) return [];

		return selectOptions?.forcaVenda?.map((salesForce) => (
			<option
				value={salesForce?.id}
				key={salesForce?.id}
			>
				{salesForce?.nome_forca_venda}
			</option>
		));
	}, [selectOptions?.forcaVenda]);

	const renderSelectZonaVenda = useCallback(() => {
		if (!selectOptions?.zonaVenda?.length) return [];

		return selectOptions?.zonaVenda?.map((salesZone) => (
			<option
				value={salesZone?.id}
				key={salesZone?.id}
			>
				{salesZone?.nome_zona_venda}
			</option>
		));
	}, [selectOptions?.zonaVenda]);

	const renderSelectMembro = useCallback(() => {
		if (!CommercialHierarchyData) return [];

		return CommercialHierarchyData?.membros?.map((members) => (
			<option
				value={members?.id}
				key={members?.id}
			>
				{members?.nome_membro}
			</option>
		));
	}, [CommercialHierarchyData]);

	const handleOnChangeChannel = useCallback(
		(e, form) => {
			if (!formProps) {
				setFormProps(form);
			}
			if (!!e?.target?.value) {
				setCanalVendaId(Number(e?.target?.value));
			} else {
				setCanalVendaId(0);
			}
		},
		[formProps],
	);

	const renderRowsCreate = useCallback((name, label): IFormRows[] => {
		const dataTest = {};

		switch (name) {
			case 'mercado':
				dataTest['data-test'] =
					'input-mercado-mercado-modal-hierarquia';
				break;
			case 'canal_venda':
				dataTest['data-test'] =
					'input-canal_de_venda-canal_de_venda-modal-hierarquia';
				break;
			case 'forca_venda':
				dataTest['data-test'] =
					'input-forca_de_venda-forca_de_venda-modal-hierarquia';
				break;
			case 'zona_venda':
				dataTest['data-test'] =
					'input-zona_de_venda-zona_de_venda-modal-hierarquia';
				break;
		}

		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name,
							label,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...dataTest,
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, []);

	const renderModalCreate = useCallback(() => {
		return (
			<Modal
				isOpen={isOpenModalCreate}
				onClose={onCloseModalCreate}
				onEsc={onCloseModalCreate}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						Cadastrar {modalProps?.title}
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							Preencha todos os dados para cadastrar{' '}
							{modalProps?.title}
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{!!modalProps && (
							<>
								<GenericForm
									dataTestProps={{
										cancel: 'button-cancelar-modal-hierarquia',
										save: 'button-salvar-modal-hierarquia',
									}}
									initialState={modalProps?.initialState}
									formFlexProps={formFlexProps}
									rows={modalProps?.rows}
									style={defaultModalFormStyle}
									onSubmit={modalProps?.onSubmit}
									onCancel={onCloseModalCreate}
								/>
							</>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		);
	}, [isOpenModalCreate, onCloseModalCreate, modalProps]);

	const onSubmitModalCreate = useCallback(
		(values, name) => {
			switch (name) {
				case 'mercado':
					createMarket({ nome_mercado: values?.mercado });
					onCloseModalCreate();
					break;
				case 'canal_venda':
					createSalesChannel({
						nome_canal_venda: values?.canal_venda,
					});
					onCloseModalCreate();
					break;
				case 'forca_venda':
					createSalesForce({
						nome_forca_venda: values?.forca_venda,
					});
					onCloseModalCreate();
					break;
				case 'zona_venda':
					createSalesZone({
						nome_zona_venda: values?.zona_venda,
						canal_venda_id: canalVendaId,
					});
					onCloseModalCreate();
					break;
			}
		},
		[
			canalVendaId,
			createMarket,
			createSalesChannel,
			createSalesForce,
			createSalesZone,
			onCloseModalCreate,
		],
	);

	const buildModalProps = useCallback(
		(name: string, label: string) => {
			const modalProps = {
				initialState: { [name]: '' },
				rows: renderRowsCreate(name, label),
				onSubmit: (values) => onSubmitModalCreate(values, name),
				title: label,
			};
			setModalProps(modalProps);
		},
		[onSubmitModalCreate, renderRowsCreate],
	);

	const onAdd = useCallback(
		(type: string, form: any) => {
			if (!formProps) {
				setFormProps(form);
			}
			switch (type) {
				case 'mercado':
					buildModalProps(type, 'Mercado');
					break;
				case 'canal_venda':
					buildModalProps(type, 'Canal de Venda');
					break;
				case 'forca_venda':
					buildModalProps(type, 'Força de Venda');
					break;
				case 'zona_venda':
					buildModalProps(type, 'Zona de Venda');
					break;
			}
		},
		[buildModalProps, formProps],
	);

	const handleOnOpenModalList = useCallback(
		(type) => {
			setSelectedModuleProps(buildCheckTableModalProps(type));

			setTimeout(() => onOpenModalList(), 300);
		},
		[onOpenModalList],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'mercado',
							label: 'Mercado',
							inputProps: {
								'data-test':
									'select-mercado-cadastrar_editar-page-hierarquia',
								key: 'mercado',
								selectOptions: renderSelectMercado(),
								...defaultInputStyle,
							},
							button: [
								{
									onClick: () =>
										handleOnOpenModalList('mercado'),
									icon: <IconEye />,
									tooltip: 'Ver todos os mercados',
									buttonProps: {
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
								{
									onClick: (_, form) => {
										onAdd('mercado', form);
										onOpenModalCreate();
									},
									tooltip: 'Adicionar mercado',
									buttonProps: {
										'data-test':
											'button-adicionar_mercado-hierarquia',
										bg: 'easyBLUE.300',
										color: 'white',
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
							],
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda',
							label: 'Canal de Venda',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-hierarquia',
								key: 'canalVenda',
								selectOptions: renderSelectCanalVenda(),
								...defaultInputStyle,
								onChange: handleOnChangeChannel,
							},
							button: [
								{
									onClick: () => {
										handleOnOpenModalList('canalVenda');
										setCurrentFilterModule('canalVenda');
									},
									icon: <IconEye />,
									tooltip: 'Ver todas os canais de venda',
									buttonProps: {
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
								{
									onClick: (_, form) => {
										onAdd('canal_venda', form);
										onOpenModalCreate();
									},
									tooltip: 'Adicionar canal de venda',
									buttonProps: {
										'data-test':
											'button-adicionar_canal_de_venda-hierarquia',
										bg: 'easyBLUE.300',
										color: 'white',
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
							],
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'forca_venda',
							label: 'Força de Venda',
							inputProps: {
								'data-test':
									'select-forca_de_venda-cadastrar_editar-page-hierarquia',
								key: 'forcaVenda',
								selectOptions: renderSelectForcaVenda(),
								...defaultInputStyle,
							},
							button: [
								{
									onClick: () =>
										handleOnOpenModalList('forcaVenda'),
									icon: <IconEye />,
									tooltip: 'Ver todas as forças de venda',
									buttonProps: {
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
								{
									onClick: (_, form) => {
										onAdd('forca_venda', form);
										onOpenModalCreate();
									},
									tooltip: 'Adicionar força de venda',
									buttonProps: {
										'data-test':
											'button-adicionar_forca_de_venda-hierarquia',
										bg: 'easyBLUE.300',
										color: 'white',
										disabled: !canalVendaId,
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
							],
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'zona_venda',
							label: 'Zona de Venda',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'select-zona_de_venda-cadastrar_editar-page-hierarquia',
								key: 'zonaVenda',
								selectOptions: renderSelectZonaVenda(),
								...defaultInputStyle,
							},
							button: [
								{
									onClick: () => {
										handleOnOpenModalList('zonaVenda');
										setCurrentFilterModule('zonaVenda');
									},
									icon: <IconEye />,
									tooltip: 'Ver todas as zonas de venda',
									buttonProps: {
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
								{
									onClick: (_, form) => {
										onAdd('zona_venda', form);
										onOpenModalCreate();
									},
									tooltip: 'Adicionar zona de venda',
									buttonProps: {
										'data-test':
											'button-adicionar_zona_de_venda-hierarquia',
										bg: 'easyBLUE.300',
										color: 'white',
										disabled: !canalVendaId,
										ml: '5px',
									},
									buttonFlexProps: {
										gap: 5,
										justify: 'space-between',
									},
								},
							],
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'membro',
							label: 'Membro',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'select-membro-cadastrar_editar-page-hierarquia',
								key: 'membro',
								selectOptions: renderSelectMembro(),
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, [
		canalVendaId,
		handleOnChangeChannel,
		handleOnOpenModalList,
		onAdd,
		onOpenModalCreate,
		renderSelectCanalVenda,
		renderSelectForcaVenda,
		renderSelectMembro,
		renderSelectMercado,
		renderSelectZonaVenda,
	]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				mercado_id: values?.mercado || null,
				canal_venda_id: values?.canal_venda,
				forca_venda_id: values?.forca_venda || null,
				zona_venda_id: values?.zona_venda,
				membro_id: values?.membro,
			};

			setPayload(body);

			if (isEditing) updateHierarchy();
			else createHierarchy();
		},
		[createHierarchy, isEditing, updateHierarchy],
	);

	const getQueriesToInvalidadeOnEditOrRemove = useMemo(() => {
		const queries = [
			['commercialHierarchy-list', isEditing ? undefined : canalVendaId],
		];

		if (isEditing) {
			queries.push(['hierarchyById', hierarchyId]);
			queries.push(['hierarchyWithId', hierarchyId]);
		}

		return queries;
	}, [canalVendaId, hierarchyId, isEditing]);

	const handleOnCloseModalList = useCallback(() => {
		onCloseModalList();

		if (
			currentFilterModule === 'zonaVenda' ||
			currentFilterModule === 'canalVenda'
		) {
			setCurrentFilterModule('');
			setTimeout(() => setCanRefetchData(true), 500);
		}
	}, [currentFilterModule, onCloseModalList, setCanRefetchData]);

	const renderForm = useCallback(() => {
		if (isLoadingCommercialHierarchyData) return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-hierarquia',
						save: 'button-salvar-page-hierarquia',
					}}
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToHierarchies}
				/>
				{renderModalCreate()}
				<CheckTableModal
					filterModule={selectedModuleProps?.filterModule}
					columnsData={selectedModuleProps?.columnsData}
					renderRow={selectedModuleProps?.renderRow}
					isOpen={isOpenModalList}
					onClose={handleOnCloseModalList}
					modalTitle={selectedModuleProps?.modalTitle}
					simpleDelete={selectedModuleProps?.simpleDelete}
					deleteMany={selectedModuleProps?.deleteMany}
					deleteAll={selectedModuleProps?.deleteAll}
					queriesToInvalidadeOnRemove={
						getQueriesToInvalidadeOnEditOrRemove
					}
					queriesToInvalidadeOnEdit={
						getQueriesToInvalidadeOnEditOrRemove
					}
				/>
			</>
		);
	}, [
		isLoadingCommercialHierarchyData,
		formInitialState,
		renderRows,
		onSubmit,
		goToHierarchies,
		renderModalCreate,
		selectedModuleProps,
		isOpenModalList,
		handleOnCloseModalList,
		getQueriesToInvalidadeOnEditOrRemove,
	]);

	return renderForm();
};

export default FormCadastrarEditarHierarquia;
