import { createContext, useContext } from 'react';
interface PropsSidebarHelp {
	states: any;
	setters: any;
}
export const SidebarHelpContext = createContext({} as PropsSidebarHelp);

export default function useSidebarHelp() {
	return useContext(SidebarHelpContext);
}
