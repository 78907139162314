import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSubFamily } from 'services/api/requests/requests';
import { useToast } from '@chakra-ui/react';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';
export const useUpdateSubFamily = (body, id) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => updateSubFamily(body, id), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['family-list']);
			queryClient.invalidateQueries(['family-list-with-products']);

			toast({
				title: 'Atualizado com sucesso!',
				description: 'Subfamilia atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao atualizar Subfamília!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
