import { useMemo } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface IMoneyField {
	inputMode?: string;
	maskOptions?: {
		prefix?: string;
		suffix?: string;
		includeThousandsSeparator?: boolean;
		thousandsSeparatorSymbol?: string;
		allowDecimal?: boolean;
		decimalSymbol?: string;
		decimalLimit?: number;
		requireDecimal?: boolean;
		allowNegative?: boolean;
		allowLeadingZeroes?: boolean;
		integerLimit?: number;
	};
	[key: string]: any;
}

const maskOptions = {
	prefix: 'R$ ',
	suffix: '',
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: '.',
	allowDecimal: true,
	decimalSymbol: ',',
	decimalLimit: 2, // how many digits allowed after the decimal
	integerLimit: 12, // limit length of integer numbers
	allowNegative: false,
	allowLeadingZeroes: false,
};

const MoneyField = ({ inputMode = 'numeric', ...others }: IMoneyField) => {
	const { maskConfig, ...inputProps } = others || {};

	const {
		value,
		onChange,
		dataTest,
		disabled = false,
		...styleProps
	} = inputProps || {};

	const currencyMask = createNumberMask({
		...maskOptions,
		...maskConfig,
	});

	const defaultStyle = useMemo(
		() => ({
			width: '100%',
			height: '44px',
			borderRadius: '10px',
			border: '1px solid #E2E8F0',
			padding: '0 1rem',
			fontSize: '1rem',
			color: '#4A5568',
			outline: 'none',
			transition: 'all 0.2s',
			'&:focus': {
				border: '1px solid #3182CE',
				boxShadow: '0 0 0 1px #3182CE',
			},
		}),
		[],
	);

	return (
		<MaskedInput
			data-test={dataTest}
			mask={currencyMask}
			defaultValue={value}
			value={value}
			onChange={onChange}
			style={{
				...defaultStyle,
				...styleProps,
			}}
			disabled={disabled}
		/>
	);
};

export default MoneyField;
