export const columnFirstDataDashVenda = [
	{
		Header: 'CANAL DE VENDAS',
		accessor: 'canalVenda',
		disableSortBy: true,
		type: 'text',
	},
];

export const columnFirstDataDashVendaFamilia = [
	{
		Header: 'FAMÍLIA',
		accessor: 'nomeFamilia',
		disableSortBy: true,
		type: 'text',
	},
];

export const columnsBodyDashVenda = [
	{
		Header: 'HISTÓRICO',
		accessor: 'historico',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'MÊS ANT.',
		accessor: 'mesAnterior',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'META',
		accessor: 'meta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'PLANO RESTRITO',
		accessor: 'planoRestrito',
		type: 'text',
		disableSortBy: true,
	},
	// Para o dash de vendas, ele foi removido e incluído um menu no header da tabela.
	{
		Header: 'FORECAST',
		accessor: 'forecast',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'OPORTUNIDADE',
		accessor: 'oportunidade',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FATURADO',
		accessor: 'faturado',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'BOA',
		accessor: 'boa',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOA',
		accessor: 'fatBoa',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'RUIM',
		accessor: 'ruim',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOA+RUIM',
		accessor: 'fatBoaRuim',
		type: 'text',
		disableSortBy: true,
	},
];

export const columnsBodyDashVendaFamilia = [
	{
		Header: 'HISTÓRICO',
		accessor: 'historico',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'MÊS ANT.',
		accessor: 'mesAnterior',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'META',
		accessor: 'meta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'PLANO RESTRITO',
		accessor: 'planoRestrito',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FATURADO',
		accessor: 'faturado',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'BOA',
		accessor: 'boa',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOA',
		accessor: 'fatBoa',
		type: 'text',
		disableSortBy: true,
	},
];

export const columnsEndDashVenda = [
	{
		Header: 'FATxMETA',
		accessor: 'fatMeta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOAxMETA',
		accessor: 'fatBoaMeta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FCSTxMETA',
		accessor: 'fcstMeta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOAxFCST',
		accessor: 'fatBoaFcst',
		type: 'text',
		disableSortBy: true,
	},
];

export const columnsEndDashVendaFamilia = [
	{
		Header: 'FATxHISTÓRICO',
		accessor: 'fatHistorico',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FATxMETA',
		accessor: 'fatMeta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FATxRESTRITO',
		accessor: 'fatRestrito',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOAxHISTÓRICO',
		accessor: 'fatBoaHistorico',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOAxMETA',
		accessor: 'fatBoaMeta',
		type: 'text',
		disableSortBy: true,
	},
	{
		Header: 'FAT+BOAxRESTRITO',
		accessor: 'fatBoaRestrito',
		type: 'text',
		disableSortBy: true,
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];
