import api from '../api';
export interface CompanyParameterization {
	cnae_primario: string;
	tamanho_ciclo: number;
	tamanho_rateio_ticket: number;
	precisao_decimal: number;
}
export const getParameterization =
	async (): Promise<CompanyParameterization> => {
		const url = `/empresa/parametrizacao`;
		const response = await api.get(url);
		return response.data;
	};
