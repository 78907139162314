import moment from 'moment';
import {
	Flex,
	FormControl,
	FormErrorMessage,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Text,
} from '@chakra-ui/react';
import { numberToLocaleString } from './number';
import { validateMin, validateRequired } from 'utils/forms/validations';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import RenderGenericInputForm from 'components/forms/RenderGenericInputForm';
import { currencyToNumber } from './numberToFormateString';
import { globalStyles } from 'theme/styles';
import { WarningTwoIcon } from '@chakra-ui/icons';

export const MAX_DECIMAL_DIGITS = 2;
export const FAMILY_APPORTIONMENT_MIN_VALUE = 99.97;
export const FAMILY_APPORTIONMENT_MAX_VALUE = 100.03009;

export const getColumnWidthFromCycle = (
	cycle,
	isRenderPreviousCycleMonth = false,
	canRenderTotalColumn = true,
) => {
	let totalColumns = cycle?.duracao || 1;

	if (canRenderTotalColumn) totalColumns++;

	if (isRenderPreviousCycleMonth) totalColumns++;

	return Math.floor(85 / totalColumns);
};

function buildHeader({
	cycle,
	isRenderPreviousCycleMonth = false,
	canRenderTotalColumn = true,
}) {
	const columnWidth = getColumnWidthFromCycle(
		cycle,
		isRenderPreviousCycleMonth,
		canRenderTotalColumn,
	);

	function build(label) {
		return (
			<Flex
				w={`${columnWidth}%`}
				minH='45px'
				alignItems='center'
				justifyContent='center'
			>
				<Text
					fontWeight='bold'
					color='easyBLUE.300'
				>
					{label}
				</Text>
			</Flex>
		);
	}

	const headers = Array.from({ length: cycle?.duracao })
		.fill(null)
		.map((h: any, index: number) => {
			const label = moment(cycle?.data_inicio)
				.utc()
				.add(index, 'month')
				.format('MMM/YY')
				?.toUpperCase();

			return build(label);
		});

	if (isRenderPreviousCycleMonth) {
		const label = moment(cycle?.data_inicio)
			.utc()
			.subtract(1, 'month')
			.format('MMM/YY')
			?.toUpperCase();

		const labelElement = build(label);

		headers.unshift(labelElement);
	}

	if (canRenderTotalColumn)
		headers.push(
			<Flex
				w={`${columnWidth}%`}
				minH='40px'
				alignItems='center'
				justifyContent='center'
			>
				<Text
					fontWeight='bold'
					color='easyBLUE.300'
				>
					TOTAL
				</Text>
			</Flex>,
		);

	return headers;
}

export const valueOutsideTolerance = ({
	value,
	min = FAMILY_APPORTIONMENT_MIN_VALUE,
	max = FAMILY_APPORTIONMENT_MAX_VALUE,
}) => {
	return value < min || value > max;
};

export const buildHeaderFromCycle = ({
	cycle,
	iconSpaceWidth = '40px',
	isRenderPreviousCycleMonth = false,
	canRenderTotalColumn = true,
}) => {
	const headers = buildHeader({
		cycle,
		isRenderPreviousCycleMonth,
		canRenderTotalColumn,
	});

	return (
		<Flex
			display='flex'
			flexDirection='row'
			gap={3}
			w='100%'
			borderBottom='1px solid #e2e8f0'
			bg='secondaryGray.300'
		>
			<Flex w={iconSpaceWidth} />
			<Flex w='15%' />
			{headers}
		</Flex>
	);
};

export const buildFooterFromCycle = ({
	data,
	levelListName,
	append,
	remove,
	getValues,
	configsCompany,
	cycle,
	keyArrayName,
	keyDataId = 'produto_id',
	keyDataMonthName,
	keyDataValueName,
	keyTotalName = 'descricao',
	isAlertCheckOnFirstRender = false,
	alertComparationOperator = 'GREATER_THAN',
	alertComparationValue = 100,
	alertComparationMinValue = FAMILY_APPORTIONMENT_MIN_VALUE,
	alertComparationMaxValue = FAMILY_APPORTIONMENT_MAX_VALUE,
	isRenderPreviousDataMonth = false,
}: {
	data: {
		props: {
			id: string | number;
			total: string;
			[key: string]: any;
		};
	};
	levelListName: string;
	append: any;
	remove: any;
	getValues: any;
	configsCompany?: any;
	cycle?: any;
	keyArrayName: string;
	keyDataId?: string;
	keyDataMonthName: string;
	keyDataValueName: string;
	keyTotalName?: string;
	isAlertCheckOnFirstRender?: boolean;
	alertComparationOperator?:
		| 'GREATER_THAN'
		| 'LESS_THAN'
		| 'NOT_EQUAL'
		| 'NOT_BETWEEN';
	alertComparationValue?: number;
	alertComparationMinValue?: number;
	alertComparationMaxValue?: number;
	isRenderPreviousDataMonth?: boolean;
}) => {
	if (
		!Object.keys(data || {})?.length ||
		!append ||
		!remove ||
		!levelListName
	)
		return;

	const levelRows = getValues(levelListName);

	const totalIndex = levelRows?.findIndex((el) => el?.isTotalRow === true);

	const elementId = levelListName?.replace(/[A-Za-z0-9_]+_/, '');

	/**
	 * Constrói os dados totais da família para checar se os dados originais com base
	 * no dados retornados no elemento pai *data* são diferentes dos atuais, caso sejam,
	 * remove a linha total para adicionar o total com os novos valores
	 */
	const currentTotalData =
		data?.props?.[keyArrayName]
			?.filter((el) => {
				if (!cycle) return true;

				return filterDateInsideCycle({
					date: el[keyDataMonthName],
					cycle,
				});
			})
			?.sort(sortByMonth)
			?.reduce((acc, el) => {
				const mes = moment(el[keyDataMonthName]).utc().month() + 1;

				const key = `${elementId}_${mes}_TOTAL`;

				return {
					...acc,
					[key]: numberToLocaleString({
						value: Number(el[keyDataValueName] || 0),
						maximumFractionDigits: configsCompany
							? configsCompany?.precisao_decimal
							: MAX_DECIMAL_DIGITS,
					}),
				};
			}, {}) || {};

	currentTotalData[`${elementId}_TOTAL`] = numberToLocaleString({
		value: Number(data?.props?.total) || 0,
		maximumFractionDigits: configsCompany
			? configsCompany?.precisao_decimal
			: MAX_DECIMAL_DIGITS,
	});

	const shouldUpdateTotalRow =
		JSON.stringify(levelRows?.[totalIndex]?.original) !==
		JSON.stringify(currentTotalData);

	if (totalIndex !== -1) {
		if (shouldUpdateTotalRow) remove(totalIndex);
		else return;
	}

	const alert = {};

	const totalKeys = data?.props?.[keyArrayName]
		?.filter((el) => {
			if (!cycle) return true;

			return filterDateInsideCycle({
				date: el[keyDataMonthName],
				cycle,
			});
		})
		?.sort(sortByMonth)
		?.reduce((acc, m) => {
			const date = moment(m[keyDataMonthName]).utc();

			const key = `${data?.props?.id}_${date.month() + 1}_TOTAL`;

			if (isAlertCheckOnFirstRender) {
				if (alertComparationOperator === 'GREATER_THAN') {
					alert[key] =
						Number(m[keyDataValueName] || 0) >
						alertComparationValue;
				} else if (alertComparationOperator === 'LESS_THAN') {
					alert[key] =
						Number(m[keyDataValueName] || 0) <
						alertComparationValue;
				} else if (alertComparationOperator === 'NOT_EQUAL') {
					alert[key] =
						Number(m[keyDataValueName] || 0) !==
						alertComparationValue;
				} else if (alertComparationOperator === 'NOT_BETWEEN') {
					alert[key] = valueOutsideTolerance({
						value: Number(m[keyDataValueName] || 0),
						min: alertComparationMinValue,
						max: alertComparationMaxValue,
					});
				}
			}

			return {
				...acc,
				[key]: {
					value: numberToLocaleString({
						value: Number(m[keyDataValueName] || 0),
						maximumFractionDigits: configsCompany
							? configsCompany?.precisao_decimal
							: MAX_DECIMAL_DIGITS,
					}),
					title: date.format('MMM/YY'),
				},
			};
		}, {});

	const totalGeral = {
		[`${data?.props?.id}_TOTAL`]: numberToLocaleString({
			value: Number(data?.props?.total) || 0,
			maximumFractionDigits: configsCompany
				? configsCompany?.precisao_decimal
				: MAX_DECIMAL_DIGITS,
		}),
	};

	const { inputs, titles } = Object.keys(totalKeys || {}).reduce(
		(acc, el) => {
			return {
				inputs: { ...acc.inputs, [el]: totalKeys[el]?.value },
				titles: { ...acc.titles, [el]: totalKeys[el]?.title },
			};
		},
		{} as any,
	);

	let previousCycleValue: string = '';

	if (isRenderPreviousDataMonth) {
		const previosMonthList = data?.props?.[keyArrayName]
			?.filter((el) => {
				if (!cycle) return true;

				return !filterDateInsideCycle({
					date: el[keyDataMonthName],
					cycle,
				});
			})
			?.sort(sortByMonth);

		if (previosMonthList?.length) {
			previousCycleValue = previosMonthList[0]?.[keyDataValueName]
				? `${numberToLocaleString({
						value: Number(previosMonthList[0]?.[keyDataValueName]),
						maximumFractionDigits: configsCompany
							? configsCompany?.precisao_decimal
							: MAX_DECIMAL_DIGITS,
				  })} %`
				: '-';
		} else previousCycleValue = '-';
	}

	append({
		isTotalRow: true,
		[keyDataId]: data?.props?.id,
		[keyTotalName]: data?.props?.descricao || 'TOTAL',
		...inputs,
		...totalGeral,
		original: {
			...inputs,
			...totalGeral,
		},
		titles,
		alteracao: {},
		alert: isAlertCheckOnFirstRender ? alert : {},
		...(isRenderPreviousDataMonth
			? { valorCicloAnterior: previousCycleValue }
			: {}),
	});
};

export const pushFooterOnFormFromCycleAndStaticData = (
	cycle,
	data: {
		totais: Array<{ mes_ano: string; total_mes: string }>;
		total_geral: string;
		[key: string]: any;
	},
	iconSpaceWidth = '40px',
) => {
	const columnWidth = getColumnWidthFromCycle(cycle);

	const footers = data?.totais?.map((el) => {
		return (
			<Flex
				w={`${columnWidth}%`}
				minH='45px'
				alignItems='center'
				justifyContent='center'
			>
				<Text
					fontWeight='bold'
					color='easyBLUE.300'
				>
					{el.total_mes}
				</Text>
			</Flex>
		);
	});

	footers?.push(
		<Flex
			w={`${columnWidth}%`}
			minH='40px'
			alignItems='center'
			justifyContent='center'
			bg='secondaryGray.300'
		>
			<Text
				fontWeight='bold'
				color='easyBLUE.300'
			>
				{data?.total_geral || 0}
			</Text>
		</Flex>,
	);

	return (
		<Flex
			display='flex'
			flexDirection='row'
			gap={3}
			w='100%'
			borderBottom='1px solid #e2e8f0'
			bg='secondaryGray.300'
			bottom='0'
			position='sticky'
		>
			<Flex w={iconSpaceWidth} />
			<Flex
				w='15%'
				display='flex'
				alignItems='center'
			>
				<Text
					fontWeight='bold'
					color='easyBLUE.300'
				>
					TOTAL
				</Text>
			</Flex>
			{footers}
		</Flex>
	);
};

export const buildFooterFromCycleAndStaticData = ({
	formListName,
	getValues,
	formProps,
	errors,
	configsCompany,
	columnWidth,
	deeper = 0,
	canRenderTotalColumn = false,
	isRenderPreviousDataMonth = false,
	additionalSpace = 0,
	prefix,
	suffix,
}: {
	formListName: string;
	getValues: any;
	formProps: any;
	errors: any;
	configsCompany?: any;
	columnWidth: number;
	deeper?: number;
	canRenderTotalColumn?: boolean;
	isRenderPreviousDataMonth?: boolean;
	additionalSpace?: number;
	prefix?: string;
	suffix?: string;
}) => {
	const indexTotal = getTotalIndex({
		value: getValues(),
		formListName,
	});

	const totalRow =
		renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan({
			row: getValues()?.[formListName]?.[indexTotal],
			index: indexTotal,
			formListName,
			formProps,
			errors,
			configsCompany,
			columnWidth,
			canRenderTotalColumn,
			isRenderPreviousDataMonth,
			additionalSpace,
			prefix,
			suffix,
		});

	const totalFormValues = getValues()?.[formListName]?.[indexTotal];

	const hasAlert =
		!!Object.keys(totalFormValues?.alert || {}).length &&
		Object.keys(totalFormValues?.alert || {})?.some(
			(key) => totalFormValues?.alert[key],
		);

	return (
		<Flex
			display='flex'
			flexDirection='row'
			gap={3}
			w='100%'
			borderBottom='1px solid #e2e8f0'
			bg={hasAlert ? 'easyRED.150' : 'secondaryGray.300'}
			_hover={{
				backgroundColor: '#f4f7fe !important',
				color: '#42adef !important',
			}}
			bottom='0'
			position='sticky'
			minH='40px'
			pt='5px'
			pb='5px'
		>
			<Flex w='40px' />
			{totalRow}
		</Flex>
	);
};

export const resetForm = (getValues, setValues) => {
	const keys = Object.keys(getValues?.() || {});

	keys.forEach((key) => {
		setValues?.(key, []);
	});
};

export const resetRenderizedObjectIds = (renderizedObjectIds) => {
	renderizedObjectIds.current = new Map<string, Set<number | string>>();
};

export const renderFamily = ({ row }) => {
	if (!row?.nome_familia) return 'Nome da família não informado';

	return (
		<Flex
			w='100%'
			minH='40px'
			color='easyBLUE.300'
			display='flex'
			flexDirection='column'
			justifyContent='center'
		>
			<Text
				fontWeight='bold'
				color='easyBLUE.300'
			>
				{row?.nome_familia}
			</Text>
		</Flex>
	);
};

export const renderSalesChannel = ({ row }) => {
	if (!row?.nome_canal_venda && !row?.descricao)
		return 'Nome do canal de venda não informado';

	return (
		<Flex
			w='100%'
			minH='40px'
			color='easyBLUE.300'
			display='flex'
			flexDirection='column'
			justifyContent='center'
		>
			<Text
				fontWeight='bold'
				color='easyBLUE.300'
			>
				{row?.nome_canal_venda || row?.descricao}
			</Text>
		</Flex>
	);
};

export const filterDateInsideCycle = ({
	date,
	cycle,
}: {
	date: string;
	cycle: any;
}) => {
	const dateMoment = moment(date, 'YYYY-MM-DD').utc();

	const cycleStart = moment(cycle?.data_inicio, 'YYYY-MM-DD')
		.utc()
		.subtract({ seconds: 1 });

	const cycleEnd = moment(cycle?.data_final, 'YYYY-MM-DD')
		.utc()
		.add({ seconds: 1 });

	return dateMoment.isBetween(cycleStart, cycleEnd);
};

export const transformDataToFormList = ({
	data,
	append,
	configsCompany,
	keyArrayName,
	keyDataId = 'produto_id',
	keyDataMonthName,
	keyDataValueName,
	buildDescritption,
	isRenderPreviousDataMonth = false,
	isRenderExtraData = false,
	buildExtraData = (...params) => {},
	cycle,
}: {
	data: any;
	append: any;
	configsCompany?: any;
	keyArrayName: string;
	keyDataId?: string;
	keyDataMonthName: string;
	keyDataValueName: string;
	buildDescritption: (...params) => string;
	isRenderPreviousDataMonth?: boolean;
	isRenderExtraData?: boolean;
	buildExtraData?: (...params) => any;
	cycle: any;
}) => {
	function filterByCycle(el) {
		if (!isRenderPreviousDataMonth) return true;

		return filterDateInsideCycle({
			date: el[keyDataMonthName],
			cycle,
		});
	}

	data?.forEach((el) => {
		let total = 0;

		const cycleLength = isRenderPreviousDataMonth
			? cycle?.duracao + 1
			: cycle?.duracao;

		const formattedArrayData = ajustColumnMonthsFromCicle({
			arrayData: el[keyArrayName],
			keyColumnValue: keyDataValueName,
			cycleStartDate: cycle?.data_inicio,
			cycleLength,
			isRenderPreviousDataMonth,
		});

		const months = formattedArrayData
			?.filter(filterByCycle)
			?.sort((a, b) => {
				const mesA = moment(a[keyDataMonthName]).utc();
				const mesB = moment(b[keyDataMonthName]).utc();

				return mesA?.isBefore?.(mesB) ? -1 : 0;
			})
			?.reduce((acc, r) => {
				total += Number(r[keyDataValueName]) || 0;
				const date = moment(r[keyDataMonthName]).utc();

				const key = `${el?.id}_${date.month() + 1}`;

				acc[key] = {
					value: numberToLocaleString({
						value: Number(r[keyDataValueName]),
						maximumFractionDigits: configsCompany
							? configsCompany?.precisao_decimal
							: MAX_DECIMAL_DIGITS,
					}),
					title: date.format('MMM/YY'),
				};

				return acc;
			}, {});

		const extraData = isRenderExtraData
			? buildExtraData?.({
					el,
					keyArrayName,
					keyDataMonthName,
			  })
			: {};

		let previousCycleValue: string = '';

		if (isRenderPreviousDataMonth) {
			const previosMonthList = formattedArrayData?.filter(
				(f) => !filterByCycle(f),
			);

			if (previosMonthList?.length) {
				previousCycleValue = previosMonthList[0]?.[keyDataValueName]
					? `${numberToLocaleString({
							value: Number(
								previosMonthList[0]?.[keyDataValueName],
							),
							maximumFractionDigits: configsCompany
								? configsCompany?.precisao_decimal
								: MAX_DECIMAL_DIGITS,
					  })} %`
					: '-';
			} else previousCycleValue = '-';
		}

		const { inputs, titles } = Object.keys(months || {}).reduce(
			(acc, el) => {
				return {
					inputs: { ...acc.inputs, [el]: months[el]?.value },
					titles: { ...acc.titles, [el]: months[el]?.title },
				};
			},
			{} as any,
		);

		append({
			_id: el?.id,
			isTotalRow: false,
			[keyDataId]: el?.id,
			descricao: buildDescritption?.(el) || 'Não informado',
			...inputs,
			[`${el?.id}_TOTAL`]: numberToLocaleString({
				value: total,
				maximumFractionDigits: configsCompany
					? configsCompany?.precisao_decimal
					: MAX_DECIMAL_DIGITS,
			}),
			original: { ...inputs },
			titles,
			edited: false,
			...(isRenderPreviousDataMonth
				? { valorCicloAnterior: previousCycleValue }
				: {}),
			...(isRenderExtraData ? { extraData } : {}),
		});
	});
};

const renderPropoverAlert = ({ hasAlert, popoverAlertProps }) => {
	return hasAlert && popoverAlertProps ? (
		<Popover>
			<PopoverTrigger>
				<WarningTwoIcon color='easyRED.400' />
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>{popoverAlertProps?.header}</PopoverHeader>
				<PopoverBody>{popoverAlertProps?.body}</PopoverBody>
			</PopoverContent>
		</Popover>
	) : null;
};

const renderPreviousCycleMonth = ({
	isRenderPreviousDataMonth,
	columnWidth,
	valorCicloAnterior,
}) => {
	return isRenderPreviousDataMonth ? (
		<Flex
			w={`${columnWidth}%`}
			display='flex'
			alignItems='center'
			justifyContent='center'
		>
			<Text
				fontWeight='bold'
				color='easyBLUE.300'
			>
				{valorCicloAnterior}
			</Text>
		</Flex>
	) : null;
};

export const buildRowForAportainmentAndUnrestrictePlanAndRestrictedPlan = ({
	index,
	row,
	columnWidth,
	formListName,
	formProps,
	errors,
	configsCompany,
	isRenderPreviousDataMonth = false,
	canRenderTotalColumn = true,
	popoverAlertProps,
	additionalSpace = 0,
	prefix,
	suffix,
	cycle,
	containerDescriptionPercentualSize = '15%',
}: {
	index: number;
	row: any;
	columnWidth: number;
	formListName: string;
	formProps: any;
	errors: any;
	configsCompany?: any;
	isRenderPreviousDataMonth: boolean;
	canRenderTotalColumn: boolean;
	popoverAlertProps?: any;
	additionalSpace?: number;
	prefix?: string;
	suffix?: string;
	cycle?: any;
	containerDescriptionPercentualSize?: string;
}) => {
	const validate = {
		...validateRequired(),
		...validateMin(0, 'Valor deve ser maior igual à 0'),
	};

	const {
		descricao,
		produto_id,
		familia_id,
		canal_venda_id,
		original,
		id,
		_id,
		labelOnly,
		alert = {},
		edited,
		alteracao,
		valorCicloAnterior,
		extraData,
		isTotalRow = false,
		hasHeader = false,
		titles,
		...inputValues
	} = row;

	const hasAlert =
		!!Object.keys(alert)?.length &&
		Object.keys(alert)?.some((key) => alert[key]);

	const descriptionStyle = {
		...(isTotalRow ? { fontWeight: 'bold' } : {}),
		...(hasAlert ? { color: 'easyRED.400' } : { color: 'easyBLUE.300' }),
	};

	const descriptionTooltip = (
		<TooltipStandard label={descricao}>
			<Text {...descriptionStyle}>{descricao}</Text>
		</TooltipStandard>
	);

	let columns: any[] = [];
	let valorCicloAnteriorElement: any = null;

	if (hasHeader) {
		columns = buildHeader({
			cycle,
			isRenderPreviousCycleMonth: isRenderPreviousDataMonth,
			canRenderTotalColumn,
		});
	} else {
		const keys = Object.keys(inputValues || {});

		const filteredKeys = canRenderTotalColumn
			? keys
			: keys?.slice(0, keys?.length - 1);

		columns = filteredKeys?.map((key) => {
			const totalStyle = {
				fontWeight: 'bold',
				border: 'none',
				disabled: true,
				...(hasAlert && !!alert[key]
					? { color: globalStyles.colors.easyRED[400] }
					: {}),
			};

			const alertStyle = {
				fontWeight: 'bold',
				color: 'white',
				backgroundColor: globalStyles.colors.easyRED[400],
			};

			const isInvalid =
				!!(errors[formListName] as any)?.length &&
				errors[formListName]?.[index];

			const input = (
				<RenderGenericInputForm
					type='money'
					name={`${formListName}.${index}.${key}`}
					formProps={formProps}
					disabled={() => key?.includes('TOTAL')}
					inputProps={{
						key: id,
						...(key?.includes('TOTAL') ? totalStyle : {}),
						...(hasAlert && !!alert[key] ? alertStyle : {}),
						defaultValue: inputValues[key],
						maskConfig: {
							prefix: ' ',
							decimalLimit: configsCompany
								? configsCompany?.precisao_decimal
								: MAX_DECIMAL_DIGITS,
							allowDecimal: configsCompany
								? configsCompany?.precisao_decimal > 0
								: true,
							...(prefix ? { prefix } : {}),
							...(suffix ? { suffix } : {}),
						},
					}}
					validate={validate}
				/>
			);

			const formControl = (
				<FormControl isInvalid={isInvalid}>
					{input}
					<FormErrorMessage>
						{isInvalid &&
							errors[formListName]?.[index]?.[key]?.message}
					</FormErrorMessage>
				</FormControl>
			);

			return (
				<Flex
					w={`${columnWidth}%`}
					title={isTotalRow ? '' : titles?.[key] || ''}
				>
					{isTotalRow ? (
						<TooltipStandard
							label={titles?.[key] || ''}
							placement='bottom'
						>
							<span>{formControl}</span>
						</TooltipStandard>
					) : (
						<> {formControl} </>
					)}
				</Flex>
			);
		});

		valorCicloAnteriorElement = renderPreviousCycleMonth({
			isRenderPreviousDataMonth,
			columnWidth,
			valorCicloAnterior,
		});
	}

	let popoverWarningComponent = renderPropoverAlert({
		hasAlert,
		popoverAlertProps,
	});

	return (
		<Flex
			display='flex'
			flexDirection='row'
			gap={3}
			minH='40px'
			alignItems='center'
		>
			<Flex
				w={containerDescriptionPercentualSize}
				display='flex'
				alignItems='center'
				fontWeight={hasHeader || !columns?.length ? 'bold' : 'normal'}
				gap={3}
			>
				{descriptionTooltip}
				{popoverWarningComponent}
			</Flex>
			{additionalSpace > 0 && <Flex w={`${additionalSpace}px`} />}
			{valorCicloAnteriorElement}
			{columns}
		</Flex>
	);
};

export const renderGenericRowForAportainmentAndUnrestrictePlanAndRestrictedPlan =
	({
		row,
		index,
		formListName,
		formProps,
		errors,
		configsCompany,
		columnWidth,
		keyDescription = 'descricao',
		isRenderPreviousDataMonth = false,
		canRenderTotalColumn = true,
		popoverAlertProps,
		additionalSpace = 0,
		prefix,
		suffix,
		cycle,
		containerDescriptionPercentualSize,
	}: {
		row: any;
		index: number;
		formListName: string;
		formProps: any;
		errors: any;
		configsCompany?: any;
		columnWidth: number;
		keyDescription?: string;
		isRenderPreviousDataMonth?: boolean;
		canRenderTotalColumn?: boolean;
		popoverAlertProps?: any;
		additionalSpace?: number;
		prefix?: string;
		suffix?: string;
		cycle?: any;
		containerDescriptionPercentualSize?: string;
	}) => {
		if (!row[keyDescription]) return 'Não informado';

		return (
			<Flex
				w='100%'
				display='flex'
				flexDirection='column'
			>
				{buildRowForAportainmentAndUnrestrictePlanAndRestrictedPlan({
					index,
					row,
					columnWidth,
					formListName,
					formProps,
					errors,
					configsCompany,
					isRenderPreviousDataMonth,
					canRenderTotalColumn,
					popoverAlertProps,
					additionalSpace,
					prefix,
					suffix,
					cycle,
					containerDescriptionPercentualSize,
				})}
			</Flex>
		);
	};

export const updateTotalProductRow = ({
	value,
	name,
	setValue,
	configsCompany,
}: {
	value: any;
	name: string | undefined;
	setValue: any;
	configsCompany?: any;
}) => {
	const [parent, index, product_month] =
		typeof name === 'string' ? name?.split?.('.') : [];

	if (!parent || !index || !product_month) return;

	const totalKey = product_month.replace(/_.*/, '_TOTAL');

	const element_id = product_month.replace(/_.*/, '');

	const inputValues = value[parent]?.[index];

	const total = Object.keys(inputValues || {})
		?.filter(
			(el) =>
				el?.startsWith(element_id) &&
				!el?.includes('TOTAL') &&
				!el?.includes('alteracao'),
		)
		?.reduce(
			(acc, key) => acc + currencyToNumber(inputValues[key] || 0),
			0,
		);

	setValue(
		`${parent}.${index}.${totalKey}`,
		numberToLocaleString({
			value: total,
			maximumFractionDigits: configsCompany
				? configsCompany?.precisao_decimal
				: MAX_DECIMAL_DIGITS,
		}),
	);
};

export const getTotalIndex = ({ value, formListName }) => {
	return value?.[formListName]?.findIndex?.((el) => el?.isTotalRow === true);
};

export const getTotalGeralOriginal = ({ value, formListName, index, key }) => {
	return currencyToNumber(value[formListName]?.[index]?.original?.[key]);
};

export const getColumnMonthChanges = ({
	value,
	formListName,
	index,
	keyMonthChanged,
}) => {
	return value[formListName]?.[index]?.alteracao?.[keyMonthChanged] || {};
};

export const getNewTotalColumnValue = ({
	columnMonthChanges,
	keyChangedMonth,
	difference,
	originalValue,
}) => {
	return (
		(Object.keys(columnMonthChanges || {})
			?.filter((el) => el !== keyChangedMonth)
			?.map((el) => columnMonthChanges[el] as number)
			?.reduce((acc: any, el: any) => (acc += el), 0) as number) +
		difference +
		originalValue
	);
};

export const getNewTotalGeneralValue = ({
	value,
	formListName,
	index,
	entityId,
}) => {
	return Object.keys(value[formListName]?.[index] || {})
		?.filter(
			(key) => key?.includes('_TOTAL') && key !== `${entityId}_TOTAL`,
		)
		?.map((key) => {
			const _value = value[formListName]?.[index]?.[key];
			return typeof _value === 'string'
				? currencyToNumber(_value)
				: _value;
		})
		?.reduce((acc, el) => acc + el, 0);
};

export const getEditedValuesFromCicle = ({
	el,
	currentCycle,
	keyValueName,
}) => {
	const produto_id = el?.produto_id;

	return Object.keys(el)
		?.filter((k) => k.startsWith(String(produto_id)))
		?.map((k) => {
			if (k.includes('TOTAL')) return null;

			const novoValorMes = currencyToNumber(el[k]);

			if (isNaN(novoValorMes)) return null;

			const valorOriginal = currencyToNumber(el?.original[k]);

			if (novoValorMes === valorOriginal) return null;

			const mes = Number(k.replace(`${produto_id}_`, ''));

			const mes_ano = moment()
				.utc()
				.month(mes - 1);

			// se o mês é menor ou igual ao ciclo atual significa que é o próximo ano
			if (mes <= currentCycle?.ciclo) {
				mes_ano.add(1, 'year');
			}

			return {
				produto_id,
				mes_ano: mes_ano?.startOf('month')?.format('YYYY-MM-DD'),
				[keyValueName]: novoValorMes,
				ciclo_id: currentCycle?.id,
			} as any;
		})
		?.filter((el) => el !== null)
		?.reduce((acc, el) => [...acc, el], []);
};

export const sortByMonth = (a, b) => {
	const dateA = moment(a?.mes_ano)?.utc();
	const dateB = moment(b?.mes_ano)?.utc();

	if (dateA.isBefore(dateB)) {
		return -1;
	}
	return 0;
};

export const ajustColumnMonthsFromCicle = ({
	arrayData,
	keyColumnValue,
	cycleStartDate,
	cycleLength,
	isRenderPreviousDataMonth = false,
}: {
	arrayData: any;
	keyColumnValue: string;
	cycleStartDate: string;
	cycleLength: number;
	isRenderPreviousDataMonth?: boolean;
}) => {
	if (arrayData?.length < cycleLength) {
		const startDate = isRenderPreviousDataMonth
			? moment(cycleStartDate)
					.utc()
					.subtract(1, 'month')
					.format('YYYY-MM-DD')
			: cycleStartDate;

		return Array.from({
			length: cycleLength,
		})
			?.map((_, index) => {
				const mes_ano = moment(startDate)
					.utc()
					.startOf('month')
					.add(index, 'month')
					.format('YYYY-MM-DD');

				const dataIndex = arrayData?.findIndex(
					(r) => r?.mes_ano === mes_ano,
				);

				if (dataIndex !== -1) {
					return arrayData[dataIndex];
				}

				return {
					[keyColumnValue]: '0',
					mes_ano,
				};
			})
			?.sort(sortByMonth);
	} else {
		return arrayData;
	}
};
