import api from '../api';

export const getActivities = async () => {
	const response = await api.get('/atividade');
	return response.data;
};

export const createActivity = async (body) => {
	const response = await api.post('/atividade', body);
	return response.data;
};

export const deleteActivity = async (id: number) => {
	const response = await api.delete(`atividade?id=${id}`);
	return response.data;
};

export const getActivityById = async (id: number) => {
	const response = await api.get(`atividade/atividadeById?id=${id}`);
	return response.data;
};

export const updateActivity = async (body) => {
	const response = await api.patch('/atividade', body);
	return response.data;
};

export const getProductionRoadmapProductsByFilters = async (params) => {
	const response = await api.get('/roteiroProducao/filtroRoteiroProducao', {
		params: {
			...params,
		},
	});
	return response.data;
};

export const existsProductionRoadmapProducts = async () => {
	const response = await api.get('/roteiroProducao/existeRoteiroProducao');
	return response.data;
};

export const createProductionRoadmap = async (body) => {
	const response = await api.post(
		'/roteiroProducao/createAtividadesRoteiro',
		body,
	);
	return response.data;
};
