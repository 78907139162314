// button with calendly link and chat icon
import { Button, ButtonProps } from '@chakra-ui/react';
import { TbMessageDots } from 'react-icons/tb';
// IconMessageDots


interface ButtonCalendlyProps extends ButtonProps {
    calendlyLink: string;
}



export default function ButtonCalendly({ calendlyLink, ...rest }: ButtonCalendlyProps) {

    const handleClick = () => {
        window.open(calendlyLink, '_blank');
    }

    return (
        <Button
            id="btn-calendly"
            position={'fixed'}
            bottom={'20px'}
            right={'20px'}
            height="60px"
            width="60px"
            borderRadius="50%"
            backgroundColor={"easyRED.300"}
            _hover={{
                backgroundColor: "easyRED.400"
            }}
            leftIcon={<TbMessageDots />}
            iconSpacing="0"
            color="white"
            fontSize="1.5rem"
            padding="0.8rem"
            onClick={handleClick}
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.49)"
            {...rest}
        />
    );
}
