import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRestrictedPlanByCycle } from 'services/api/requests/restrictedPlan';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useUpdateRestrictedPlanByCycle = (onUpdateData?) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(updateRestrictedPlanByCycle, {
		onSuccess: (success) => {
			queryClient.invalidateQueries({
				predicate: (query: any) =>
					query.queryKey[0].includes('resctricted-plan'),
			});

			onUpdateData?.();

			toast({
				title: 'Plano restrito atualizado!',
				description: 'O plano restrito foi atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			if (error?.response?.data?.message) {
				toast({
					title: 'Erro ao gerar plano restrito',
					description: error.response.data.message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao gerar plano restrito',
				);
				toastErrors?.forEach((element) => toast(element));
			}
		},
	});
};
