import api from '../../api';

export const deleteTime = async (id: number) => {
	const response = await api.delete(`/horaHora?id=${id}`);
	return response.data;
};

export const deleteBreak = async (id: number) => {
	const response = await api.delete(`/paradaHoraHora?id=${id}`);
	return response.data;
};
