import {
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Spinner,
	Tooltip,
	FormErrorMessage,
	useToast,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { ModalEstoqueDesejado } from './ModalEstoqueDesejado';

import { useFamilyListWithoutProducts } from 'services/queryClient/wrapperHooks/useFamilyList';
import { getStockOrientation } from 'services/api/requests/stock';
import { useQuery } from '@tanstack/react-query';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useCreateStockOrientation } from 'services/queryClient/wrapperHooks/useCreateStockOrientation';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { converterStockData } from '../../../../utils/functions/stockUtils';
import { Controller, useForm } from 'react-hook-form';
import { currencyToNumber } from '../../../../utils/functions/currencyToNumber';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import { useGlobalContext } from 'contexts/GlobalContext';
import ProductQuantityField from 'components/inputs/ProductQuantityField';
import { numberToLocaleString } from 'utils/functions/number';

const Styles = styled.div`
  width: 100%;

  table {
    border-collapse: collapse;
    text-align: center;
    vertical-align: middle;
    width: 100%
    border-collapse: collapse;
      border-radius: 6px;
      overflow: hidden;
  }

  caption {
    font-weight: bold;
    font-size: 24px;
    text-align: left;
    color: #333;
    margin-bottom: 16px;
  }

  thead {
    background-color: #E7ECF9;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  .customColor {
    background-color: #ABFFBF !important;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  thead th:nth-child(2), thead th:nth-child(3), thead th:nth-child(4), thead th:nth-child(5), thead th:nth-child(5), thead th:nth-child(6), thead th:nth-child(7) {
    background-color: #BCD7FF;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  thead th:nth-child(8) {
    background-color: #FFB6BF;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  thead th:nth-child(9), thead th:nth-child(10) {
    background-color: #ABFFBF;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  tbody tr td:nth-child(9), tbody tr td:nth-child(10) {
    background-color: #E1FBE7;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  tbody tr td:nth-child(8) {
    background-color: #FFDADF;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  tbody tr td:nth-child(1) {
    background-color: #f5f5f5;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  tbody tr td:nth-child(2), tbody tr td:nth-child(3), tbody tr td:nth-child(4), tbody tr td:nth-child(5), tbody tr td:nth-child(6), tbody tr td:nth-child(7) {
    background-color: #D4E5FF;
    color: #37393C;
    font-size: 0.875rem;
    letter-spacing: 2%;
  }

  th, td {
    padding: 8px;
    border: 2px solid white; 
  }

  tbody th {
    background-color: #36c;
    color: #fff;
    text-align: left;
  }
`;

const enumEstatisticas = [
	{ value: 'MEDIA_MOVEL', viewValue: 'Média móvel' },
	{ value: 'SAZONALIDADE', viewValue: 'Sazonalidade' },
	{ value: 'TENDENCIA_LINEAR', viewValue: 'Tendência linear' },
];

const formatEstatistica = (estatistica) => {
	const result = enumEstatisticas.filter((e) => e.value === estatistica);
	return result[0]?.viewValue;
};

function Table({ columns, data }) {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data,
		});

	return (
		<table
			variant='simple'
			{...getTableProps()}
		>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th
								className={
									column.render('Header') ===
									'Estatística de projeção'
										? 'customColor'
										: ''
								}
								{...column.getHeaderProps()}
							>
								{column.render('Header')}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => {
								if (cell.value === 'MEDIA_MOVEL') {
									return (
										<td {...cell.getCellProps()}>
											{'Média móvel'}
										</td>
									);
								}
								if (cell.value === 'TENDENCIA_LINEAR') {
									return (
										<td {...cell.getCellProps()}>
											{'Tendência linear'}
										</td>
									);
								}
								if (cell.value === 'SAZONALIDADE') {
									return (
										<td {...cell.getCellProps()}>
											{'Sazonalidade'}
										</td>
									);
								}
								return (
									<>
										{cell.column.Header === 'Receita' &&
										cell.value === null ? (
											<Tooltip
												label='Necessário calcular o preço médio dos produtos para obter a projeção de receita!'
												hasArrow
												placement='bottom'
												bg='gray.200'
												color='blue.800'
											>
												<td>-</td>
											</Tooltip>
										) : (
											<td>
												{cell.value
													? cell.render('Cell')
													: '-'}
											</td>
										)}
									</>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

function App() {
	const { data: FamilyData } = useFamilyListWithoutProducts(true);
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();
	const currentYear = moment().format('YYYY');
	const currentMonth = moment().format('M');

	const [cicloFilter, setCicloFilter] = useState(currentMonth);
	const [anoFilter, setAnoFilter] = useState(currentYear);
	const [familiaFilter, setFamiliaFilter] = useState<any>(undefined);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;
	const [idUpdate, setIdUpdate] = useState<any>();

	const [dataEstatistica, setDataEstatistica] = useState<any>([]);
	const [estatisticaListInput, setEstatisticaListInput] = useState<any>([]);

	const currentDate = moment().format('YYYY/MM/DD');

	const [messageAlert, setMessageAlert] = useState('');
	const [stockData, setStockData] = useState<any>([]);
	const [stockPayload, setStockPayload] = useState<any>();

	const toast = useToast();

	const ciclos = useMemo(
		() => [
			{ value: '1' },
			{ value: '2' },
			{ value: '3' },
			{ value: '4' },
			{ value: '5' },
			{ value: '6' },
			{ value: '7' },
			{ value: '8' },
			{ value: '9' },
			{ value: '10' },
			{ value: '11' },
			{ value: '12' },
		],
		[],
	);

	const stockInitialValues = useMemo(
		() => ({
			data: currentDate,
			familia_id: +familiaFilter,
			id: !!idUpdate ? +idUpdate : null,
			ciclo: +cicloFilter,
			ano: +anoFilter,
		}),
		[anoFilter, cicloFilter, currentDate, familiaFilter, idUpdate],
	);

	const [columns, setColumns] = useState<any>([]);

	const helpOrientacaoEstoque: SidebarHelp = useMemo(
		() => ({
			title: 'Orientação de Estoque',
			firstParagraph: `A Orientação de Estoque aponta uma sugestão de estoque para os próximos meses tomando como base o estoque sugerido para cada família de produtos. Essa orientação é com base em histórico de vendas e estatísticas. Através da orientação de estoque, a easy360 sugere o estoque desejado no tempo.`,
			secondParagraph: `Para cadastrar um estoque desejado, selecione Ano, Ciclo e Família de Produto. Após analisar o erro das estatísticas e o estoque sugerido conforme orientação da easy360, selecione o Tipo de Entrada que deseja seguir para cadastrar o estoque desejado daquela família, e entre com o valor do estoque.`,
			thirdParagraph: `Acesse “Verificar Estoque Desejado” para verificar o estoque cadastrado para cada família de produto naquele ciclo.`,
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const formInitialValues = useMemo(
		() => ({ tipoEntrada: 'MANUAL', quantidade: '' }),
		[],
	);

	const {
		handleSubmit,
		control,
		reset,
		getValues,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: { ...formInitialValues },
	});

	const { data, isFetched, isError, isLoading, refetch } = useQuery(
		['projecao-estoque-list', cicloFilter, anoFilter, familiaFilter],
		getStockOrientation,
		{
			refetchOnWindowFocus: false,
			onError: (err: any) => {
				if (!familiaFilter || !cicloFilter || !anoFilter)
					setMessageAlert(
						'Selecione ano, ciclo e família para visualizar os dados de orientação do estoque!',
					);
				else setMessageAlert(err.response?.data[0].message);
			},
			retry: false,
		},
	);
	useEffect(() => {
		const processedData = converterStockData(data?.data, precisao_decimal);
		setStockData(processedData);
	}, [data, precisao_decimal]);

	useEffect(() => {
		reset({ ...formInitialValues });
	}, [familiaFilter, reset, formInitialValues]);

	useEffect(() => {
		if (data?.data?.length) {
			const statisticColumns =
				data.data[0]?.MEDIA_MOVEL?.historicoErros
					?.map((h) => new Date(h?.mes))
					?.map((d) => moment(d)?.utc()?.format?.('MMM/YY'))
					.map((d, index) => ({
						Header: d,
						accessor: `historicoErros[${index}].erro`,
					})) || [];

			setColumns([
				{
					Header: 'Estatística',
					columns: [
						{
							Header: '',
							accessor: 'estatistica',
						},
					],
				},
				{
					Header: 'Comportamento histórico do erro',
					columns: statisticColumns,
				},
				{
					Header: 'Estatística de projeção',
					columns: [
						{
							Header: 'Erro',
							accessor: 'erro',
						},
						{
							Header: 'Estoque sugerido',
							accessor: 'estoqueSugerido',
						},
						{
							Header: 'Receita',
							accessor: 'receita',
						},
					],
				},
			]);
		}
	}, [data]);

	useEffect(() => {
		if (FamilyData && !familiaFilter) {
			setFamiliaFilter(FamilyData?.rows[0]?.id);
		}
	}, [FamilyData, familiaFilter]);

	useEffect(() => {
		setDataSidebar(helpOrientacaoEstoque);
	}, [helpOrientacaoEstoque, setDataSidebar]);

	useEffect(() => {
		if (isFetched && !isError && !!stockData?.length) {
			setDataEstatistica([
				stockData[0]['MEDIA_MOVEL'],
				stockData[0]['SAZONALIDADE'],
				stockData[0]['TENDENCIA_LINEAR'],
			]);
			setEstatisticaListInput(stockData[1].estatisticas);

			if (stockData[2]?.estoqueDesejado) {
				const qtde = currencyToNumber(
					numberToFormattedString(
						stockData[2]?.estoqueDesejado.quantidade,
						precisao_decimal,
					),
				);

				reset({
					tipoEntrada: stockData[2]?.estoqueDesejado['tipo_entrada'],
					quantidade: qtde?.toString() || '',
				});
				setIdUpdate(stockData[2]?.estoqueDesejado.id);
			} else {
				reset({ ...formInitialValues });
				setIdUpdate(null);
			}
		}
	}, [
		stockData,
		isError,
		isFetched,
		reset,
		formInitialValues,
		precisao_decimal,
	]);

	const onChangeEstatistica = useCallback(
		(e) => {
			const optionValue = e?.target?.value || '';

			if (optionValue !== '' && optionValue !== 'MANUAL') {
				reset((formValues) => {
					const sugestion = estatisticaListInput.filter(
						(r) => r.view === optionValue,
					);

					const newQtde = currencyToNumber(
						numberToFormattedString(
							sugestion[0]?.value || 0,
							precisao_decimal,
						),
					);
					return {
						...formValues,
						quantidade: newQtde?.toString() || '',
					};
				});
			} else if (
				stockData[2]?.estoqueDesejado &&
				stockData[2]?.estoqueDesejado['tipo_entrada'] === 'MANUAL'
			) {
				const qtde = currencyToNumber(
					numberToFormattedString(
						stockData[2]?.estoqueDesejado.quantidade,
						precisao_decimal,
					),
				);

				reset({
					tipoEntrada: 'MANUAL',
					quantidade: qtde?.toString() || '',
				});
			} else {
				reset((formValues) => ({
					...formValues,
					quantidade: '',
				}));
			}
		},
		[estatisticaListInput, precisao_decimal, reset, stockData],
	);

	const yearFiltersOptions = useMemo(() => {
		const previousYear = moment().subtract({ year: 1 }).year();
		const years = [<option value={previousYear}>{previousYear}</option>];

		Array(3)
			.fill(null)
			.forEach((_, index) => {
				const year = moment().add({ year: index }).year();
				years.push(
					<option
						selected={index === 0}
						value={year}
					>
						{year}
					</option>,
				);
			});
		return years;
	}, [currentYear]);

	const renderFilters = useCallback(() => {
		return (
			<Flex
				justifyContent='space-between'
				mt={'24px'}
				wrap='wrap'
				gap={4}
			>
				<Flex>
					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel mb='0'>Ano</FormLabel>
						<Select
							placeholder='Selecione'
							onChange={(e) => setAnoFilter(e.target.value)}
							h='44px'
							borderRadius={10}
						>
							{yearFiltersOptions}
						</Select>
					</FormControl>
					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel mb='0'>Ciclo</FormLabel>
						<Select
							placeholder='Selecione'
							onChange={(e) => setCicloFilter(e.target.value)}
							h='44px'
							borderRadius={10}
						>
							{ciclos.map((option) => (
								<option
									selected={option.value === cicloFilter}
									value={option.value}
								>
									{option.value}
								</option>
							))}
						</Select>
					</FormControl>
					<FormControl
						mr='5px'
						mb='20px'
					>
						<FormLabel mb='0'>Familia</FormLabel>
						<Select
							placeholder='Selecione'
							onChange={(e) => setFamiliaFilter(e.target.value)}
							h='44px'
							borderRadius={10}
						>
							{FamilyData?.rows?.map((option) => (
								<option
									selected={option.id === familiaFilter}
									value={option.id}
								>
									{option.nome_familia}
								</option>
							))}
						</Select>
					</FormControl>
				</Flex>
				<Flex mt={'24px'}>
					<ButtonComponent
						type={'primary'}
						title={'Verificar estoque desejado'}
						onClick={onOpen}
					/>
				</Flex>
			</Flex>
		);
	}, [
		FamilyData?.rows,
		cicloFilter,
		ciclos,
		familiaFilter,
		onOpen,
		yearFiltersOptions,
	]);

	const renderTable = useCallback(() => {
		if (isFetched && !isError)
			return (
				<Table
					columns={columns}
					data={dataEstatistica}
				/>
			);
		else if (isLoading) {
			return (
				<Flex
					w={'100%'}
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
				>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor='gray.200'
						color='blue.500'
						size='lg'
					/>
					<p>Carregando dados...</p>
				</Flex>
			);
		} else if (!isLoading && isError)
			return (
				<AlertComponent
					title='Atenção!'
					description={messageAlert}
					status={'warning'}
					hasCloseButton
				/>
			);
		return null;
	}, [columns, dataEstatistica, isError, isFetched, isLoading, messageAlert]);

	const renderStockOptions = useMemo(() => {
		const options = estatisticaListInput?.map((option) => (
			<option value={option.view}>
				{formatEstatistica(option.view)}
			</option>
		));
		options.push(<option value={'MANUAL'}>{'Manual'}</option>);

		return options;
	}, [estatisticaListInput]);

	const {
		mutate,
		isError: mutateIsError,
		failureCount,
	} = useCreateStockOrientation(stockPayload, +idUpdate);

	useEffect(() => {
		if (failureCount > 0) {
			toast({
				title: 'Erro!',
				description:
					'Ocorreu um erro ao enviar os dados de estoque sugerido!',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		}
	}, [mutateIsError, toast, failureCount]);

	const onSubmit = useCallback(
		(values) => {
			const { tipoEntrada: tipo_entrada, quantidade } = values;

			const convertedQtde = Number(quantidade);

			/**
			 * Se o valor for NaN, significa que o usuário digitou um valor com (vírgula).
			 * Ajuste necessário caso o usuário clique sobre um valor que já contenha decimais,
			 * pois a função currencyToNumber() estava removendo as casas decimais e considerando como valor inteiro.
			 */
			const qtde = isNaN(convertedQtde)
				? quantidade
				: numberToFormattedString(Number(quantidade), precisao_decimal);

			setStockPayload({
				...stockInitialValues,
				tipo_entrada,
				quantidade: currencyToNumber(qtde),
			});

			setTimeout(() => mutate(), 500);
			setTimeout(() => refetch(), 750);

			reset({ ...formInitialValues });
		},
		[
			precisao_decimal,
			stockInitialValues,
			reset,
			formInitialValues,
			mutate,
			refetch,
		],
	);

	const renderForm = useCallback(() => {
		return (
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex
					justifyContent='space-between'
					mt={'24px'}
					wrap='wrap'
					gap={4}
				>
					<Flex gap={4}>
						<FormControl isInvalid={!!errors?.tipoEntrada}>
							<FormLabel htmlFor='tipoEntrada'>
								Tipo de entrada
							</FormLabel>
							<Controller
								key='tipoEntrada'
								name='tipoEntrada'
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Campo obrigatório',
									},
								}}
								render={({ field }) => (
									<Select
										value={getValues()?.tipoEntrada}
										borderRadius={10}
										onChange={(e) => {
											field.onChange(e);
											onChangeEstatistica(e);
										}}
									>
										{renderStockOptions}
									</Select>
								)}
							/>
							<FormErrorMessage>
								{errors?.tipoEntrada &&
									errors?.tipoEntrada?.message}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!errors?.quantidade}>
							<FormLabel htmlFor='quantidade'>
								Estoque sugerido
							</FormLabel>
							<Controller
								name='quantidade'
								control={control}
								rules={{
									required: 'Campo obrigatório',
									min: {
										value: 0.001,
										message: 'O valor deve maior que 0.',
									},
								}}
								render={({ field }) => {
									return (
										<ProductQuantityField
											value={getValues()?.quantidade}
											borderRadius={10}
											onChange={field.onChange}
										/>
									);
								}}
							/>

							<FormErrorMessage>
								{errors?.quantidade &&
									errors?.quantidade?.message}
							</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex mt={'24px'}>
						<ButtonGroup
							spacing={'24px'}
							marginLeft='auto'
						>
							<ButtonComponent
								type={'primary'}
								title={'Salvar'}
								action={'submit'}
								isDisabled={isSubmitting}
							/>
						</ButtonGroup>
					</Flex>
				</Flex>
			</form>
		);
	}, [
		control,
		errors?.quantidade,
		errors?.tipoEntrada,
		handleSubmit,
		isSubmitting,
		onChangeEstatistica,
		onSubmit,
		renderStockOptions,
		getValues,
	]);

	return (
		<Flex
			flexDirection={'column'}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			{renderFilters()}
			<Styles>{renderTable()}</Styles>
			{renderForm()}
			<ModalEstoqueDesejado
				isOpenModal={isOpen}
				onCloseModal={onClose}
				ciclo={cicloFilter}
				ano={anoFilter}
			/>
		</Flex>
	);
}
export default App;
