import { Flex, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { IconEdit, IconTrash } from '@tabler/icons';
import { DeleteModal } from 'components/modal/DeleteModal';
import { EditActivity } from 'pages/admin/cadastros/capacidade/roteiros/modal/EditActivity';

export function RowEditAndDeleteModal(props) {
	const { linkBtnEdit, dataDelete, idDelete } = props;

	const {
		isOpen: isOpenDelete,
		onOpen: onOpenDelete,
		onClose: onCloseDelete,
	} = useDisclosure();
	const {
		isOpen: isOpenEditActivity,
		onOpen: onOpenEditActivity,
		onClose: onCloseEditActivity,
	} = useDisclosure();
	return (
		<Flex
			cursor='pointer'
			h='max-content'
			w='max-content'
		>
			<Stack
				direction='row'
				alignSelf={{ base: 'unset', md: 'flex-end' }}
				spacing='8px'
			>
				<Icon
					data-test={'link-editar'}
					color='secondaryGray.500'
					as={IconEdit}
					w='20px'
					h='20px'
					onClick={onOpenEditActivity}
				/>
				<Icon
					data-test={'link-excluir'}
					color='secondaryGray.500'
					as={IconTrash}
					w='20px'
					h='20px'
					onClick={onOpenDelete}
				/>

				<DeleteModal
					isOpen={isOpenDelete}
					onClose={onCloseDelete}
					idDelete={idDelete}
					dataDelete={dataDelete}
				/>
				{isOpenEditActivity && (
					<EditActivity
						isOpen={isOpenEditActivity}
						onClose={onCloseEditActivity}
						idEdit={idDelete}
					/>
				)}
			</Stack>
		</Flex>
	);
}
