import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetOpportunityWithId } from 'services/queryClient/wrapperHooks/opportunity/useGetOpportunityById';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';
import FormCadastrarEditarOportunidade from 'components/forms/FormCadastrarEditarOportunidade';
import { AlertComponent } from 'components/alerts/AlertComponent';

export default function EditarOportunidades() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: opportunityId } = useParams<any>();

	const { data: OpportunityData } = useGetOpportunityWithId(opportunityId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarOportunidades: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Oportunidades',
			firstParagraph:
				'Para editar uma oportunidade, selecione a Competência desejada, o Cliente, Família e Produto, Quantidade e Membro responsável pela oportunidade.',
			secondParagraph:
				'Cadastre previamente os clientes através da importação de vendas em Cadastros > Comercial > Vendas; os membros em Cadastros > Comercial > Membros; e os produtos em Cadastros > Produto.',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarOportunidades);
	}, [helpEditarOportunidades, setDataSidebar]);

	const [opportunityException, setOpportunityException] =
		useState<boolean>(false);

	useEffect(() => {
		if (OpportunityData) {
			setAutocompleteAditionalData({
				client: OpportunityData?.cliente,
				family: OpportunityData?.produto?.familia,
				product: OpportunityData?.produto,
			});

			setInitialState({
				codigo: OpportunityData?.codigo,
				sequencia_codigo: OpportunityData?.sequencia_codigo,
				data: OpportunityData?.data,
				ciclo_id: OpportunityData?.ciclo?.id,
				cliente_id: OpportunityData?.cliente?.id,
				familia_id: OpportunityData?.produto?.familia?.id,
				produto_id: OpportunityData?.produto?.id,
				quantidade: OpportunityData?.quantidade,
				receita_bruta: OpportunityData?.receita_bruta
					? OpportunityData?.receita_bruta
					: 0,
				canal_venda_id: OpportunityData?.canal_venda?.id,
				membro_id: OpportunityData?.membro?.id,
			});

			if (OpportunityData?.consenso_excecao) {
				setOpportunityException(true);
			}
		}
	}, [OpportunityData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{opportunityException && (
					<AlertComponent
						status='warning'
						title='Atenção!'
						description='Esta oportunidade possui exceção da demanda criada! Se você editar os campos de competência e família, a exceção será removida. A edição dos demais campos apenas atualizará a exceção!'
					/>
				)}
				<FormCadastrarEditarOportunidade
					initialState={initialState}
					opportunityId={opportunityId}
					autocompleteAdditionalData={autocompleteAdditionalData}
				/>
			</Card>
		</Flex>
	);
}
