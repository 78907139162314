import { createContext, useContext, useState } from 'react';
import { QuantityValues } from '../templateCadastrarColabCons/components/SectionExceptions';

const ContextConsenso = createContext<IContext>({} as IContext);
export interface ProductException {
	nome_produto: string | number;
	id: string | number;
	codigo_produto: string | number;
}

interface IContext {
	consensus: QuantityValues;
	exceptions: { [key: string]: QuantityValues };
	setConsensus: React.Dispatch<React.SetStateAction<QuantityValues>>;
	setExceptions: React.Dispatch<
		React.SetStateAction<{ [key: string]: QuantityValues }>
	>;
	productExceptions: Record<string, ProductException>;
	setProductExceptions: React.Dispatch<
		React.SetStateAction<Record<string, ProductException>>
	>;
}

export default function ProviderConsenso({ children }) {
	const [consensus, setConsensus] = useState<QuantityValues>({});

	const [exceptions, setExceptions] = useState<{
		[key: string]: QuantityValues;
	}>({});
	//Valores do remote servem para gurdar os valores do banco de maneira estatica para agizilar o cancelar
	const [productExceptions, setProductExceptions] = useState<
		Record<string, ProductException>
	>({});

	return (
		<ContextConsenso.Provider
			value={{
				consensus,
				setConsensus,
				exceptions,
				setExceptions,
				productExceptions,
				setProductExceptions,
			}}
		>
			{children}
		</ContextConsenso.Provider>
	);
}

export const useContextConsenso = () => {
	return useContext(ContextConsenso);
};
