import {
	Box,
	Button,
	Checkbox,
	Flex,
	Icon,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Spacer,
	Switch,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	useColorModeValue,
} from '@chakra-ui/react';
import { IconHelp } from '@tabler/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	MdChevronLeft,
	MdChevronRight,
	MdFirstPage,
	MdLastPage,
} from 'react-icons/md';

import { matchSorter } from 'match-sorter';

import Status from 'components/dataDisplay/Status';

import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from 'react-table';

// Custom components
import { EditModal } from 'components/modal/EditModal';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { ToggleActionable } from 'components/modal/ToggleActionable';
import { EditModalCarteira } from 'components/modal/EditModalCarteira';
import {
	getRoteiroInput,
	getRoteiroInputValue,
} from './checkTableUtils/roteiro';
import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';
import { LoadingSpinner } from '../loading/Loading';
import { ToggleActionableCarteira } from 'components/modal/ToggleActionableCarteira';
import CheckTableActions from './CheckTableActions';
import CheckTableActionsEdit from './CheckTableActionsEdit';
import { useGetCheckTableDataWithFilters } from '../../services/queryClient/wrapperHooks/useGetCheckTableDataWithFilters';
import { useDeleteModalByFilters } from '../../services/queryClient/wrapperHooks/useDeleteModalByFilters';
import { TableFilter } from '../filter/TableFilter';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { requestParseTime } from 'components/filter/utils/requestParseTime';
import { useQueryClient } from '@tanstack/react-query';

export default function CheckTable(props) {
	const {
		columnsData,
		tableData,
		textHelper,
		linkEditForm,
		dataDelete,
		noButton,
		isSearch,
		setDataToBody,
		setRoteiro,
		deleteActivityList,
		isPaginate,
		module,
		showGenericSearchInput = true,
		filterModule,
		setHasBaseData,
		renderRow = () => {},
		bulkDeleteProps = {},
		deleteMessage,
		massEditingActions,
		dataAction,
		setHasNegativeValue = () => {},
		totalRegisters,
		setData,
		dataTest,
		preFilteredRows,
		bulkRemoval = true,
		queriesToInvalidadeOnRemove = [],
		queriesToInvalidadeOnEdit = [],
		renderedInModal = false,
		resetQuery = false,
		tableTest,
		selectionLimit,
		setLimitReached,
		updateCheckedItems,
		showFilters = true,
		customWidth,
		...others
	} = props;

	const queryClient = useQueryClient();

	const initialPageSizeClient = window.localStorage.getItem(
		`${filterModule}-list-size`,
	);
	const initialPageClient = window.localStorage.getItem(
		`${filterModule}-list-page`,
	);

	const [pagination, setPagination] = useState({
		page: Number(initialPageClient || 0),
		size: Number(initialPageSizeClient || PAGINATION_SIZE_LIST[0]),
	});

	const [totalPages, setTotalPages] = useState(0);

	const [mapaFiltros, setMapaFiltros] = useState([]);

	const [filters, setFilters] = useState([]);
	const [filteredTable, setFilteredTable] = useState(false);
	const [isFilteredRowsTable, setFilteredRowsTable] = useState(false);

	const [removeSingleMessage, setRemoveSingleMessage] = useState(dataDelete);
	const [removeAllMessage, setRemoveAllMessage] = useState(deleteMessage);

	const [dependenciasAoRemover, setDependenciasAoRemover] = useState([]);

	const [bulkDelete, setBulkDelete] = useState(bulkDeleteProps);

	const { data: dataCheckTable, isLoading } = useGetCheckTableDataWithFilters(
		{
			module: filterModule,
			page: pagination.page,
			size: pagination.size,
			filters: requestParseTime(filters),
			preFilteredRows,
		},
	);

	const columns = useMemo(() => columnsData, [columnsData]);
	const memoizedTableData = useMemo(
		() =>
			!!filterModule
				? dataCheckTable?.dados?.map((d) => renderRow(d)) || []
				: tableData,
		[dataCheckTable?.dados, filterModule, renderRow, tableData],
	);

	const { mutate: deleteWithFilters } = useDeleteModalByFilters(bulkDelete);

	useEffect(() => {
		if (!!dependenciasAoRemover?.length) {
			setRemoveAllMessage(
				deleteMessage?.map((el) =>
					el?.includes('REPLACE_ME')
						? el?.replace(
								'REPLACE_ME',
								dependenciasAoRemover?.join(', '),
						  )
						: el,
				),
			);

			setRemoveSingleMessage((prev) => ({
				...prev,
				text: prev?.text?.map((el) =>
					el?.includes('REPLACE_ME')
						? el?.replace(
								'REPLACE_ME',
								dependenciasAoRemover?.join(', '),
						  )
						: el,
				),
			}));
		}
	}, [dependenciasAoRemover, dataDelete, deleteMessage]);

	useEffect(() => {
		if (!!filterModule) {
			if (!isLoading && !!dataCheckTable?.dados?.length) {
				setTotalPages(
					Math.ceil(dataCheckTable?.total_linhas / pagination.size),
				);
				setMapaFiltros(dataCheckTable?.mapaFiltros);

				setDependenciasAoRemover(dataCheckTable?.dependencias);
			}
			if (!!setHasBaseData) {
				setHasBaseData(!!dataCheckTable?.dados?.length);
			}
			if (filterModule === 'ticketMedio') {
				setHasNegativeValue(
					dataCheckTable?.dados?.some((el) =>
						el?.recalcular
							? currencyToNumber(el?.ticket_medio_calculado) < 0
							: currencyToNumber(el?.ticket_medio_editado) < 0,
					),
				);
			}
			// Ideia: Com o intuito de evitar a dependência do componente em relação às características específicas de telas (como a simulação de funcionalidades exclusivas de telas), nossa abordagem é torná-lo independente de telas específicas e, em vez disso, torná-lo genérico o suficiente para se adaptar a qualquer tela. Para isso, coletamos os dados provenientes da resposta da API e os repassamos para o componente pai. Dessa forma, é o componente pai que lidará com esses dados, caso seja necessário, em seu próprio contexto de tela.
			if (!!setData) {
				setData(dataCheckTable);
			}
		}
	}, [
		setHasNegativeValue,
		dataCheckTable?.dados?.length,
		dataCheckTable?.total_linhas,
		dataCheckTable?.mapaFiltros,
		isLoading,
		pagination.size,
		dataCheckTable?.dados,
		renderRow,
		filterModule,
		setHasBaseData,
		dataCheckTable?.dependencias,
	]);

	function fuzzyTextFilterFn(rows, id, filterValue) {
		return matchSorter(rows, filterValue, {
			keys: [(row) => row.values[id]],
		});
	}

	fuzzyTextFilterFn.autoRemove = (val) => !val;

	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[],
	);

	function DefaultColumnFilter({
		column: { filterValue, preFilteredRows, setFilter },
	}) {
		const count = preFilteredRows.length;

		return (
			<Input
				size='xs'
				height='28px'
				borderRadius='8px'
				value={filterValue || ''}
				onChange={(e) => {
					setFilteredRowsTable(!!e.target.value);
					setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
				placeholder={`Buscar nos ${count} registros...`}
			/>
		);
	}

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[],
	);

	const tableInstance = useTable(
		{
			columns,
			data: memoizedTableData,
			defaultColumn,
			filterTypes,
			autoResetPage: false,
			autoResetFilters: false,
			autoResetSortBy: false,
			// Iniciar ordenado por uma coluna
			// initialState: {
			//   sortBy: [
			//     {
			//       id: "codproduto",
			//       desc: false,
			//     },
			//   ],
			// },
			...(!!filterModule
				? {
						pageCount: totalPages,
						manualPagination: true,
				  }
				: undefined),
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
	);

	function getPageSize(size) {
		return size < PAGINATION_SIZE_LIST[0] ? PAGINATION_SIZE_LIST[0] : size;
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		initialState,
		setGlobalFilter,
		pageOptions,
		gotoPage,
		pageCount,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = tableInstance;

	initialState.pageIndex = pagination.page;
	initialState.pageSize = getPageSize(pagination.size);

	useEffect(() => {
		if (filteredTable) {
			gotoPage(0);
			setFilteredTable(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredTable, gotoPage, pageIndex, pageSize]);

	const [checkedItems, setCheckedItems] = useState([]);

	useEffect(() => {
		if (typeof updateCheckedItems === 'function') {
			updateCheckedItems(checkedItems);
		}
	}, [checkedItems]);

	const allChecked = useMemo(
		() =>
			!!checkedItems?.length
				? checkedItems?.every((el) => el?.checked)
				: false,
		[checkedItems],
	);

	const isIndeterminate = useMemo(
		() =>
			!!checkedItems?.length
				? checkedItems?.some((el) => el?.checked) && !allChecked
				: false,
		[allChecked, checkedItems],
	);

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const getSelectedIds = useCallback(() => {
		return checkedItems
			?.filter((el) => el?.checked)
			?.map((el) => el?.dataId);
	}, [checkedItems]);

	const deleteCallback = useCallback(
		(filter) => {
			setBulkDelete((prev) => ({
				...prev,
				filters: {
					...filter,
					filtros: requestParseTime(filter?.filtros),
				},
			}));

			setTimeout(() => {
				setCheckedItems([]);
				deleteWithFilters();
				gotoPage(0);
			}, 250);

			setTimeout(() => {
				if (!!queriesToInvalidadeOnRemove?.length) {
					queriesToInvalidadeOnRemove.forEach((item) => {
						if (Array.isArray(item)) {
							resetQuery
								? queryClient.resetQueries(item)
								: queryClient.invalidateQueries(item);
						} else {
							resetQuery
								? queryClient.resetQueries([item])
								: queryClient.invalidateQueries([item]);
						}
					});
				}
			}, 750);
		},
		[
			deleteWithFilters,
			gotoPage,
			queriesToInvalidadeOnRemove,
			queryClient,
			resetQuery,
		],
	);

	const updateFilters = useCallback(
		(newFilters) => {
			setFilters(newFilters);
			setFilteredTable(true);
		},
		[setFilters],
	);

	const renderFilters = useCallback(() => {
		return (
			<Flex
				mb='40px'
				mt='-80px'
			>
				<TableFilter
					dataTest={dataTest}
					mapFilters={mapaFiltros}
					setFilters={updateFilters}
					module={filterModule}
				/>
			</Flex>
		);
	}, [mapaFiltros, updateFilters, filterModule]);

	useEffect(() => {
		if (!!filterModule) {
			const newPageSize = getPageSize(pageSize);

			window.localStorage.setItem(`${filterModule}-list-page`, pageIndex);
			window.localStorage.setItem(
				`${filterModule}-list-size`,
				newPageSize,
			);

			setPagination({
				page: pageIndex,
				size: newPageSize,
			});
		}
	}, [filterModule, pageIndex, pageSize]);

	useEffect(() => {
		if (!!page?.length) {
			setCheckedItems(
				page.map((row) => {
					return {
						index: row?.index,
						dataId: row?.original?.id,
						checked: false,
					};
				}),
			);
		} else {
			setCheckedItems([]);
		}
	}, [page]);

	const renderTable = useCallback(() => {
		return (
			<Table
				{...getTableProps()}
				variant='simple'
				lineHeight={'24px'}
				justify='space-between'
				color='gray.500'
				mb='5px'
				size='xm'
				ml={'5px'}
			>
				<Thead>
					{headerGroups.map((headerGroup, index) => (
						<Tr
							{...headerGroup.getHeaderGroupProps()}
							key={index}
						>
							{headerGroup.headers.map((column, index) => (
								<Th
									// pe='10px'
									key={index}
									borderColor={borderColor}
									// textAlign='center'
									whiteSpace='nowrap'
								>
									{/* <Flex mb={'5em'}>{(column.canFilter && column.filter !== false) ? column.render("Filter") : <Flex mb={'3.29em'}></Flex> }</Flex> */}
									<Flex
										// {...column.getHeaderProps()}
										justify='space-between'
										// ml='2px'
										align='center'
										fontSize={{
											sm: '10px',
											lg: '11px',
										}}
										color='gray.400'
										w={
											column.type === 'checkBox' &&
											'fit-content'
										}
									>
										{column.type === 'checkBox' ? (
											<Box
												fontSize='10px'
												// ml='-15px'
												mr={10}
											>
												<Checkbox
													key='all'
													isChecked={allChecked}
													isIndeterminate={
														isIndeterminate
													}
													onChange={(e) => {
														let newCheckedItems;
														const selectedCount =
															checkedItems.filter(
																(el) =>
																	el.checked,
															).length;
														if (e.target.checked) {
															if (
																selectionLimit &&
																selectedCount >=
																	selectionLimit
															) {
																if (
																	typeof setLimitReached ===
																	'function'
																) {
																	setLimitReached(
																		true,
																	);
																}
																newCheckedItems =
																	checkedItems.map(
																		(
																			el,
																		) => ({
																			...el,
																			checked: false,
																		}),
																	);
															} else {
																const remainingCount =
																	selectionLimit
																		? selectionLimit -
																		  selectedCount
																		: checkedItems.length;
																let checkedCount = 0;
																newCheckedItems =
																	checkedItems.map(
																		(
																			el,
																		) => {
																			if (
																				checkedCount <
																					remainingCount &&
																				!el.checked
																			) {
																				checkedCount++;
																				return {
																					...el,
																					checked: true,
																				};
																			}
																			return el;
																		},
																	);
															}
														} else {
															newCheckedItems =
																checkedItems.map(
																	(el) => ({
																		...el,
																		checked: false,
																	}),
																);
														}

														setCheckedItems(
															newCheckedItems,
														);

														if (!e.target.checked) {
															if (
																typeof setLimitReached ===
																'function'
															) {
																setLimitReached(
																	false,
																);
															}
														}
													}}
													m='2em 0 0 1.5em'
												/>
											</Box>
										) : null}
										{column.helper ? (
											<Text
												fontSize='10px'
												display='flex'
												alignItems='center'
												gap='5px'
												ml='-15px'
											>
												{column.Header}
												<Tooltip
													label={
														column.helperText ||
														textHelper
													}
													placement='top'
													bg='gray.200'
													color='blue.800'
													hasArrow
													arrowSize={15}
												>
													<span>
														<IconHelp size={16} />
													</span>
												</Tooltip>
											</Text>
										) : (
											// column.Header
											// ■ Fixed position
											// <Box position={'fixed'} bg={'white'}>
											<Box
												fontSize='10px'
												ml='-15px'
											>
												<Box
													{...column.getHeaderProps(
														column.getSortByToggleProps(
															{
																title: `Ordenar coluna ${column.render(
																	'Header',
																)}`,
															},
														),
													)}
												>
													{column?.hover ? (
														<Tooltip
															label={column.hover}
															placement='top'
															bg='gray.200'
															color='blue.800'
															hasArrow
															arrowSize={15}
														>
															{column.render(
																'Header',
															)}
														</Tooltip>
													) : (
														column.render('Header')
													)}
												</Box>
												{/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
												<Flex my={'.5em'}>
													{column.canFilter &&
													column.filter !== false
														? column.render(
																'Filter',
														  )
														: null}
												</Flex>
											</Box>
										)}
									</Flex>
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row);
						return (
							<Tr
								data-test={`check_table-${tableTest}`}
								cursor='default'
								{...row.getRowProps()}
								key={index}
							>
								{row.cells.map((cell, index) => {
									let data = '';
									if (
										cell.column.type === 'text' ||
										cell.column.type == 'disabledCnpjCpf' ||
										cell.column.type ==
											'disabledCodigoProduto' ||
										cell.column.type === 'relation' ||
										cell.column.type === 'date' ||
										cell.column.type === 'autocomplete' ||
										cell.column.type === 'money' ||
										cell.column.type === 'number'
									) {
										data = (
											<Tooltip
												padding='20px'
												border={'1px solid #E0E5F2'}
												gap='10px'
												borderRadius={'16px'}
												background='white'
												display={'grid'}
												gridTemplateColumns={
													'repeat(2, auto)'
												}
												placement='top'
												label={
													<Text
														color={textColor}
														fontSize='sm'
														fontWeight='700'
														pl='.5em'
														wordBreak='break-word'
													>
														{cell.value}
													</Text>
												}
											>
												<Text
													color={textColor}
													fontSize='sm'
													fontWeight='700'
													whiteSpace='nowrap'
													overflow='hidden'
													textOverflow='ellipsis'
													w={
														cell.column.hover
															? 'fit-content'
															: customWidth ||
															  '100px'
													}
													pl='.5em'
													mt='.5em'
												>
													{cell.value}
												</Text>
											</Tooltip>
										);
									} else if (cell.column.type === 'toggle') {
										data = (
											<Switch
												colorScheme='red'
												size='sm'
												isChecked={cell.value}
											/>
										);
									} else if (
										cell.column.type === 'toggle-actionable'
									) {
										switch (module) {
											case 'Produtos':
												data = (
													<ToggleActionable
														dataTest={
															'switch-rateio-table-page-produtos'
														}
														checked={
															memoizedTableData[
																row.id
															].rateio
														}
														id={
															memoizedTableData[
																row.id
															].id
														}
														disabled={
															memoizedTableData[
																row.id
															]?.status.toUpperCase() ===
																'INATIVO' ||
															!memoizedTableData[
																row.id
															]?.familia_id
														}
													/>
												);
												break;
											case 'Carteiras':
												data = (
													<ToggleActionableCarteira
														checked={
															memoizedTableData[
																row.id
															].ruim
														}
														id={
															memoizedTableData[
																row.id
															].id
														}
													/>
												);
										}
									} else if (
										cell.column.type ===
										'toggleAndEditModal'
									) {
										switch (module) {
											case 'ticketMedio':
												data = (
													<EditModal
														checked={
															memoizedTableData[
																row.id
															].recalcular
														}
														id={
															memoizedTableData[
																row.id
															].id
														}
														title={
															'Editar Preço Médio'
														}
														text={
															'Por favor, insira o valor considerado para o preço médio.'
														}
														firstLabel={'Produto'}
														secondLabel={
															'Preço Médio'
														}
													/>
												);
												break;
											case 'Carteiras':
												data = (
													<EditModalCarteira
														checked={
															memoizedTableData[
																row.id
															].faturado
														}
														id={
															memoizedTableData[
																row.id
															].id
														}
														title={
															'Atualização de Carteira de Pedido para Faturada'
														}
														text={
															'Por favor, informe o código da nota fiscal referente a venda realizada para essa carteira de pedido, assim conseguimos salvar corretamente a carteira como faturada.'
														}
														firstLabel={
															'Nota Fiscal'
														}
													/>
												);
												break;
										}
									} else if (cell.column.type === 'jsx') {
										data = (
											<cell.column.jsx
												row={row.original}
												dataDelete={removeSingleMessage}
												filterModule={filterModule}
												queriesToInvalidadeOnRemove={
													queriesToInvalidadeOnRemove
												}
												queriesToInvalidadeOnEdit={
													queriesToInvalidadeOnEdit
												}
												idDelete={
													cell.column.module
														? memoizedTableData[
																row.id
														  ].id[0]
														: memoizedTableData[
																row.id
														  ].id
												}
												linkBtnEdit={
													cell.column.module
														? `${linkEditForm}/${
																memoizedTableData[
																	row.id
																].id[0]
														  }`
														: `${linkEditForm}/${
																memoizedTableData[
																	row.id
																].id
														  }`
												}
												resetQuery={resetQuery}
											/>
										);
									} else if (
										cell.column.type === 'jsxDeleteIcon'
									) {
										data = (
											<cell.column.jsx
												row={row.original}
												deleteActivityList={
													deleteActivityList
												}
												idDelete={
													cell.column.module
														? memoizedTableData[
																row.id
														  ].id[0]
														: memoizedTableData[
																row.id
														  ].id
												}
											/>
										);
									} else if (cell.column.type === 'roteiro') {
										const valueInput = getRoteiroInputValue(
											{
												row,
												cell,
												memoizedTableData,
											},
										);

										data = (
											<Flex
												cursor='pointer'
												h='max-content'
												w='max-content'
												mt={'0.5rem'}
											>
												{getRoteiroInput({
													memoizedTableData,
													row,
													cell,
													valueInput,
													setRoteiro,
													setDataToBody,
												})}
											</Flex>
										);
									} else if (cell.column.type === 'badge') {
										data = (
											<Status
												badgeTrueText={
													cell.column.badgeTrueText ||
													'Ativo'
												}
												status={cell.value}
												fontSize='sm'
												fontWeight='500'
												mt='.5em'
											>
												{cell.value}
											</Status>
										);
									} else if (
										cell.column.type === 'badge-yes-no'
									) {
										data = (
											<Status
												badgeTrueText={
													cell.column.badgeTrueText ||
													'SIM'
												}
												w={'auto'}
												status={cell.value}
												fontWeight='500'
												mt='.5em'
											>
												{cell.value}
											</Status>
										);
									} else if (
										cell.column.type === 'iconText'
									) {
										data = (
											<cell.column.jsx
												row={row.original}
											/>
										);
									} else if (
										cell.column.type === 'checkBox'
									) {
										const currentIndex =
											checkedItems?.length !==
											memoizedTableData?.length
												? checkedItems?.findIndex(
														(el) =>
															el?.dataId ===
															memoizedTableData[
																row.id
															]?.id,
												  )
												: Number(row.id);

										data = (
											<Checkbox
												key={
													checkedItems[
														currentIndex > -1
															? currentIndex
															: row.id
													]?.index
												}
												isChecked={
													checkedItems[
														currentIndex > -1
															? currentIndex
															: row.id
													]?.checked
												}
												onChange={(e) => {
													if (
														selectionLimit &&
														checkedItems.filter(
															(el) => el.checked,
														).length >=
															selectionLimit &&
														e.target.checked
													) {
														if (
															typeof setLimitReached ===
															'function'
														) {
															setLimitReached(
																true,
															);
														}
														return;
													}
													if (
														!isFilteredRowsTable &&
														!!dataCheckTable?.dados
															?.length &&
														!checkedItems?.length
													) {
														window.location.reload();
														return;
													}

													const newCheckedItems = [
														...checkedItems,
													];

													newCheckedItems[
														currentIndex > -1
															? currentIndex
															: row.id
													].checked =
														e.target.checked;
													setCheckedItems(
														newCheckedItems,
													);
													if (!e.target.checked) {
														if (
															typeof setLimitReached ===
															'function'
														) {
															setLimitReached(
																false,
															);
														}
													}
												}}
												m='.5em 0 0 2.25em'
											/>
										);
									}

									return (
										<Td
											{...cell.getCellProps()}
											key={index}
											fontSize={{
												sm: '14px',
											}}
											minW={{
												sm: '150px',
												md: '200px',
												lg: 'auto',
											}}
											borderColor='transparent'
											w={
												cell.column.type ===
													'checkBox' && '0px'
											}
										>
											{data}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</Table>
		);
	}, [
		getTableProps,
		headerGroups,
		getTableBodyProps,
		page,
		borderColor,
		allChecked,
		isIndeterminate,
		textHelper,
		dataCheckTable?.dados?.length,
		isFilteredRowsTable,
		checkedItems,
		prepareRow,
		textColor,
		module,
		memoizedTableData,
		removeSingleMessage,
		linkEditForm,
		deleteActivityList,
		setRoteiro,
		setDataToBody,
		filterModule,
		queriesToInvalidadeOnEdit,
		queriesToInvalidadeOnRemove,
	]);

	const renderPaginationInputs = useCallback(() => {
		return isPaginate === false ? null : (
			<Flex
				justifyContent='space-between'
				m={4}
				alignItems='center'
			>
				<Flex>
					<Button
						label='First Page'
						variant='no-effects'
						onClick={() => gotoPage(0)}
						isDisabled={!canPreviousPage}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdFirstPage}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
					<Button
						label='Previous Page'
						variant='no-effects'
						onClick={previousPage}
						isDisabled={!canPreviousPage}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdChevronLeft}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
				</Flex>
				<Flex alignItems='center'>
					<Text
						me='10px'
						minW='max-content'
						fontSize='sm'
						color='gray.500'
						fontWeight='normal'
					>
						Linhas por página
					</Text>
					<Select
						w={20}
						size='md'
						borderRadius='8px'
						mr={8}
						value={pageSize}
						onChange={(e) => setPageSize(Number(e.target.value))}
					>
						{PAGINATION_SIZE_LIST.map((s) => (
							<option value={s}>{s}</option>
						))}
					</Select>
					<Text
						flexShrink='0'
						mr={8}
						minW='max-content'
						fontSize='sm'
						color='gray.500'
						fontWeight='normal'
					>
						Página{' '}
						<Text
							fontWeight='bold'
							as='span'
						>
							{pageIndex + 1}
						</Text>{' '}
						de{' '}
						<Text
							fontWeight='bold'
							as='span'
						>
							{pageOptions.length}
						</Text>
					</Text>
					<Text
						flexShrink='0'
						me='10px'
						minW='max-content'
						fontSize='sm'
						color='gray.500'
						fontWeight='normal'
					>
						Ir para a página
					</Text>{' '}
					<NumberInput
						maxW={28}
						size='md'
						min={1}
						max={pageOptions.length}
						onChange={(value) => {
							const page = value ? value - 1 : 0;
							gotoPage(page);
						}}
						defaultValue={pageIndex + 1}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				</Flex>
				<Flex>
					<Button
						label='Next Page'
						variant='no-effects'
						onClick={nextPage}
						isDisabled={!canNextPage}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdChevronRight}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
					<Button
						label='Previous Page'
						variant='no-effects'
						onClick={() => gotoPage(pageCount - 1)}
						isDisabled={!canNextPage}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdLastPage}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
				</Flex>
			</Flex>
		);
	}, [
		canNextPage,
		canPreviousPage,
		gotoPage,
		isPaginate,
		nextPage,
		pageCount,
		pageIndex,
		pageOptions.length,
		pageSize,
		previousPage,
		setPageSize,
		textColor,
	]);

	return (
		<>
			<Flex
				direction='column'
				w='100%'
				px='0px'
				mt={noButton ? 'null' : '-10'}
				{...others}
			>
				{isSearch == false ? null : (
					<Flex
						align={{
							sm: 'flex-start',
							lg: 'flex-start',
						}}
						justify={'space-between'}
						w='100%'
						px='22px'
						mb='36px'
					>
						{showGenericSearchInput && (
							<>
								<SearchBar
									onChange={(e) =>
										setGlobalFilter(e.target.value)
									}
									h='44px'
									placeholder='Buscar...'
									w={{ lg: '390px' }}
									borderRadius='16px'
								/>
								<Spacer />
							</>
						)}
					</Flex>
				)}
				{/* <FilterInput columnsData={columnsData} tableData={tableData} setGlobalFilter={setGlobalFilter} /> */}
				{!!filterModule && showFilters && renderFilters()}
				{!!filterModule && bulkRemoval && (
					<CheckTableActions
						filterModule={filterModule}
						filters={filters}
						deleteMessage={removeAllMessage}
						deleteCallback={deleteCallback}
						allSelectedItens={allChecked}
						getSelectedIds={getSelectedIds}
						initialPageSize={memoizedTableData?.length}
						pageSize={page?.length}
						totalRegisters={dataCheckTable?.total_linhas}
					/>
				)}
				{/* Ideia que esse compoente seja utilizado quando vier edições em massa, não foi utilizado o de cima pois o componente esta mockado com informações sobre remoção, e para não dar conflitos decidi criar um novo "generico" */}
				{massEditingActions && (
					<CheckTableActionsEdit
						filters={filters}
						massEditingActions={massEditingActions}
						allSelectedItens={allChecked}
						getSelectedIds={getSelectedIds}
						pageSize={page?.length}
						totalRegisters={dataCheckTable?.total_linhas}
						dataAction={dataAction}
					/>
				)}
				<ScrollBar color='secondary'>
					<Box
						pt='1'
						pr='20px'
						alignContent='center'
						width='100%'
					>
						{renderedInModal ? (
							<Flex
								maxH='70vh'
								overflowY='auto'
							>
								{renderTable()}
							</Flex>
						) : (
							renderTable()
						)}
						{!!filterModule && isLoading ? (
							<LoadingSpinner mt='2vh' />
						) : null}
						{(!filterModule || !isLoading) &&
							renderPaginationInputs()}
					</Box>
				</ScrollBar>
			</Flex>
		</>
	);
}
