import { Box, Flex, HStack, IconButton } from '@chakra-ui/react';
import { IconListCheck } from '@tabler/icons';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useColorModeValue } from '@chakra-ui/react';

import {
	renderThumb,
	renderTrack,
	renderView,
} from 'components/scrollbar/Scrollbar';
import { HSeparator } from 'components/separator/Separator';
import TreeView, { ItensTreeView } from 'components/treeView/TreeView';
import { FilterOption, useContextColaboracaoConsenso } from '../../context';
import { ItemFamily, PropsItemFamily } from './ItemFamily';

import ManualPaging, {
	IManualPaging,
} from 'components/dataTables/ManualPaging';
import { useGetFilterDemandFamily } from 'services/queryClient/wrapperHooks/colabAndConsensus/filters/useGetFilterDemandFamily';
import { ItemRoot } from './ItemRoot';

const generateRoot = () => ({
	index: 'root',
	isOpen: false,
	children: [],
	data: 'Root item',
	hasChildren: true,
	indexComponent: 0,
	original: {},
});

export function MenuFamily() {
	const {
		setFamilyId,
		keysPage,
		cycleId,
		companyAreaId,
		salesChannelId,
		salesZoneId,
	} = useContextColaboracaoConsenso();

	const [pagination, setPagination] = useState<IManualPaging>({
		page: 0,
		size: 25,
		totalPages: 0,
	});
	const { data } = useGetFilterDemandFamily({
		pageName: keysPage.page,
		cycleId,
		companyAreaId,
		salesChannelId,
		salesZoneId,
		page: pagination.page,
		size: pagination.size,
	});

	const [sidebarState, setSidebarState] = useState<'closed' | 'opened'>(
		'opened',
	);

	let sidebarBgColor = useColorModeValue('easyBLUE.100', 'navy.800');
	let sidebarRadius = '16px';
	let sidebarMargins = '0px';

	const [familiesTreeView, setFamiliesTreeView] = useState<
		ItensTreeView<PropsItemFamily>
	>({
		root: generateRoot(),
	});
	// this is for the rest of the collapses
	let variantChange = '0.2s linear';
	let shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
		'unset',
	);

	const setOpen = (id: string) => {
		setFamiliesTreeView((preFamilyTreeView) => ({
			...preFamilyTreeView,
			[id]: {
				...preFamilyTreeView[id],
				isOpen: !preFamilyTreeView[id].isOpen,
			},
		}));
	};
	const generateTreeViews = (families: FilterOption[]) => {
		if (families) {
			const newFamiliesTreeView: ItensTreeView<PropsItemFamily> = {
				root: generateRoot(),
			};
			families?.forEach((family) => {
				const keyFamily = `familia-${family.value}`;
				newFamiliesTreeView.root.children.push(keyFamily);
				newFamiliesTreeView[keyFamily] = {
					id: family.value,
					index: keyFamily,
					children: [],
					data: family.label,
					hasChildren: true,
					indexComponent: 1,
					isOpen: familiesTreeView[keyFamily]?.isOpen || false,
					original: family,
					setOpen,
					extra: {
						setOpen,
						setFamilyId,
						original: family,
					},
				};
			});
			setFamiliesTreeView(newFamiliesTreeView);
		}
	};
	useEffect(() => {
		if (data) {
			if (data?.rows) {
				generateTreeViews(data?.rows);
				setPagination((prev) => ({
					...prev,
					totalPages: Math.ceil(data?.count / pagination.size),
				}));
			}
		}
	}, [data]);

	return (
		<Box>
			<IconButton
				margin={5}
				position='absolute'
				zIndex={1}
				onClick={() => {
					if (sidebarState === 'closed') setSidebarState('opened');
					else setSidebarState('closed');
				}}
				variant='outline'
				p='8px'
				icon={<IconListCheck />}
				borderRadius='15px'
				_hover={{ cursor: 'pointer' }}
				bg={'#FFFFFF'}
				aria-label={''}
			/>
			<Box
				bg={sidebarBgColor}
				opacity={sidebarState === 'opened' ? 1 : 0}
				transition={variantChange}
				w={sidebarState === 'opened' ? '290px' : '80px'}
				ms={{
					sm: '16px',
				}}
				my={{
					sm: '16px',
				}}
				h='calc(100vh - 32px)'
				m={sidebarMargins}
				borderRadius={sidebarRadius}
				minH='100%'
				overflowX='hidden'
				boxShadow={shadow}
			>
				{/** Componente de rolagem do menu */}
				<Scrollbars
					autoHide
					renderTrackVertical={renderTrack}
					renderThumbVertical={renderThumb}
					renderView={renderView}
				>
					{/** Brand */}
					<Flex
						align='center'
						justifyContent='space-between'
						// pt='15px'
						h='48px'
						p='4px'
						w='100%'
					>
						<HStack paddingTop={'36px'}>
							{/* COMPONENTE DO MENU: botão  */}
							{/******* COMPONENTE DO MENU: logo Easy360 ***/}
						</HStack>
					</Flex>
					{/******* COMPONENTE DO MENU: separador entre componentes do MENU ***/}
					<HSeparator bg='#E9EDF7' />
					<TreeView
						Containers={[ItemRoot, ItemFamily]}
						deeper={0}
						id='root'
						ids={[]}
						items={familiesTreeView}
						key='root'
					/>
					{data?.count && data?.count > pagination.size && (
						<ManualPaging
							pagination={pagination}
							setPagination={setPagination}
							totalItemsPageList={[25]}
							showGoToPage={false}
							showInputLinesPerPage={false}
							renderedInDarkMode={false}
						/>
					)}
				</Scrollbars>
			</Box>
		</Box>
	);
}
