import { useToast } from '@chakra-ui/react';
import { Cycle } from 'models/cycle.model';
import { useEffect, useMemo, useState } from 'react';
import {
	getCollaborationConsensusHistoryMutate,
	getStatisticIA,
	getStatisticMutate,
} from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { QuantityValues } from '../components/SectionExceptions';
import moment from 'moment';
export type FilterStatisticsKey =
	| 'manual'
	| 'movingAverage'
	| 'seasonality'
	| 'linearTenancy'
	| 'previousCycle'
	| 'aiPrediction';

type OutputSelectStatistic =
	| {
			total: number;
			media: number;
			values: QuantityValues;
			error: false;
			extraResult?: any;
	  }
	| {
			error: true;
			message: string;
	  }
	| undefined;
interface Query {
	cycleId: string;
	familyId: string;
	clientId?: string;
	productId?: string;
	salesChannelId?: string;
	salesZoneId?: string;
	companyAreaId?: string;
	page: string;
}

const onSelectStatistic = async (
	query: Query,
	statisticsValues: StatisticsValues,
	cycle: Cycle,
): Promise<OutputSelectStatistic> => {
	try {
		const { bd, value: typeStatistic, sigma } = statisticsValues;
		const statisticsData = await getStatisticMutate({
			...query,
			typeStatistic,
			andStatisticInterval: sigma,
		});
		const newEstatisticas: QuantityValues = {};
		let total = 0;
		let startMounth = false;
		if (!cycle) return;
		const dateCicloStart = new Date(+cycle.ano, +cycle?.ciclo);
		const dateCicloEnd = new Date(
			+cycle.ano,
			+cycle?.ciclo + (cycle?.duracao || 6),
		);
		for (const estatistica of statisticsData) {
			const [yearStatistic, mounth] = estatistica.data.split('-');
			const dateEstatistica = new Date(+yearStatistic, +mounth);
			if (startMounth) {
				const quatity = Math.round(Number(estatistica[bd])) || 0;
				total += quatity;
				newEstatisticas[+mounth] = quatity;
			}
			if (dateEstatistica.getTime() === dateCicloStart.getTime()) {
				startMounth = true;
			}
			if (dateEstatistica.getTime() >= dateCicloEnd.getTime()) {
				break;
			}
		}
		const tamanho = Object.keys(newEstatisticas).length;
		return {
			total,
			media: total / tamanho,
			values: newEstatisticas,
			error: false,
		};
	} catch (error: any) {
		const errorMessage =
			error?.response?.data[0]?.message ||
			error?.message ||
			'Erro ao buscar estatísticas.';
		return {
			error: true,
			message: errorMessage,
		};
	}
};
export interface StatisticsValues {
	value: string;
	bd: string;
	sigma?: Record<string, any>;
	query?: Record<string, any>;
	onSelect?: (query: Query, cycle: Cycle) => Promise<OutputSelectStatistic>;
	text: string;
}

const manual: StatisticsValues = {
	value: 'manual',
	bd: 'manual',
	text: 'Manual',
};
const movingAverage: StatisticsValues = {
	sigma: {
		desvioPadraoSigma: 3,
		mediaMovelQuantidadeIntervalo: 2,
	},
	value: 'mediaMovelQuantidade',
	bd: 'media_movel_quantidade',
	onSelect(query, cycle) {
		return onSelectStatistic(query, this, cycle);
	},
	text: 'Média Móvel',
};
const seasonality: StatisticsValues = {
	sigma: {
		sazonalidadeQuantidadeIntervalo: 2,
		desvioPadraoSigma: 3,
	},
	value: 'sazonalidadeQuantidade',
	bd: 'tendencia_sazonalidade_quantidade',
	onSelect(query, cycle) {
		return onSelectStatistic(query, this, cycle);
	},
	text: 'Sazonalidade',
};
const linearTenancy: StatisticsValues = {
	sigma: {
		desvioPadraoSigma: 3,
	},
	value: 'tendenciaLinearQuantidade',
	bd: 'tendencia_linear_quantidade',
	onSelect(query, cycle) {
		return onSelectStatistic(query, this, cycle);
	},
	text: 'Tendência Linear',
};
const previousCycle: StatisticsValues = {
	value: 'cicloAnterior',
	bd: 'ciclo_anterior',
	text: 'Ciclo Anterior',
	onSelect: async function (query) {
		try {
			const {
				cycleId,
				familyId,
				page,
				companyAreaId,
				salesChannelId,
				salesZoneId,
			} = query;
			const result = await getCollaborationConsensusHistoryMutate({
				cycleId,
				familyId,
				page,
				companyAreaId,
				aplicarCicloAnterior: true,
				salesChannelId,
				salesZoneId,
			});
			const quantities: Array<any> = result[0][page] || [];
			quantities?.shift();
			if (!quantities.length)
				return {
					error: true,
					message: 'Não há dados para o ciclo anterior',
				};
			const values = {};
			let total = 0;
			for (const item of quantities) {
				const quantity: number = +item[page] || 0;
				const mount = +item.mes_ano.split('-')[1];
				values[mount] = quantity;
				total += quantity;
			}
			return {
				total,
				error: false,
				media: total / Object.keys(values).length,
				values,
			};
		} catch (err: any) {
			return {
				error: true,
				message:
					err?.response?.data[0]?.details?.response?.message[0] ||
					'Erro ao buscar ciclo anterior',
			};
		}
	},
};

const aiPrediction: StatisticsValues = {
	value: 'aiPrediction',
	bd: 'aiPrediction',
	text: 'Pedir à IA',
	onSelect: async function (query, cycle) {
		try {
			const { cycleId, familyId, salesChannelId, salesZoneId } = query;

			const response = await getStatisticIA({
				cycleId,
				familyId,
				salesChannelId,
				salesZoneId,
			});

			const values = {};
			let total = 0;

			const arrayData = response?.result || [];
			const keyMonth = 'month';
			const keyQuantity = 'prediction';

			const cycleStart = moment(cycle?.data_inicio)
				.utc()
				.subtract(1, 'days');

			const cycleEnd = moment(cycle?.data_inicio)
				.utc()
				.add(cycle?.duracao || 6, 'months')
				.add(1, 'days');

			for (const statistic of arrayData) {
				const statisticDate = moment(statistic[keyMonth], 'YYYY-MM')
					.startOf('month')
					.utc();

				if (statisticDate.isBetween(cycleStart, cycleEnd)) {
					const [, month] = statistic[keyMonth].split('-');

					const value =
						Math.round(Number(statistic[keyQuantity])) || 0;

					const quantity = value < 0 ? 0 : value;

					total += quantity;
					values[+month] = quantity;
				}
			}

			return {
				total,
				error: false,
				media: total / Object.keys(values).length || 1,
				values,
				extraResult: {
					message: response?.mensagem,
					hasData: !!response,
					isInvalidData:
						!response?.result?.length || response?.depreciado,
				},
			};
		} catch (err: any) {
			return {
				error: true,
				message:
					'Ocorreu um erro ao processar sua solicitação. Contate o seu mentor ou envie um e-mail para: contato@easy360.ind.br.',
			};
		}
	},
};

export const statisticsValues: Record<FilterStatisticsKey, StatisticsValues> = {
	manual,
	linearTenancy,
	movingAverage,
	seasonality,
	previousCycle,
	aiPrediction,
};

export const statisticsValuesSelectException: Array<{
	label: string;
	value: FilterStatisticsKey;
}> = [
	{
		label: 'Manual',
		value: 'manual',
	},
	{
		label: 'Média Móvel',
		value: 'movingAverage',
	},
	{
		label: 'Sazonalidade',
		value: 'seasonality',
	},
	{
		label: 'Tendência Linear',
		value: 'linearTenancy',
	},
];

export const statisticsValuesSelect = [
	...statisticsValuesSelectException,
	{
		label: 'Ciclo Anterior',
		value: 'previousCycle',
	},
	{
		value: 'aiPrediction',
		label: 'Pedir à IA',
	},
];

interface UseSelectByStatisticProps {
	salesChannelId?: string;
	productId?: string;
	cycleId: string;
	setValues: Function;
	setTotal?: Function;
	setAverage?: Function;
	familyId: string;
	page: string;
	companyAreaId?: string;
	salesZoneId?: string;
	cycleData: Cycle;
	exception?: boolean;
}

// Aqui está a definição da função useSelectByStatistic
export const useSelectByStatistic = ({
	salesChannelId,
	cycleId,
	productId,
	setValues,
	setTotal,
	setAverage,
	cycleData,
	companyAreaId,
	familyId,
	page,
	salesZoneId,
	exception = false,
}: UseSelectByStatisticProps) => {
	const [valueSelect, setValueSelect] =
		useState<FilterStatisticsKey>('manual');

	const [extraResult, setExtraResult] = useState<any>({});

	useEffect(() => {
		if (valueSelect !== 'manual') {
			setValueSelect('manual');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [familyId, salesChannelId, salesZoneId]);

	const toast = useToast();
	const options = useMemo(() => {
		if (exception) return statisticsValuesSelectException;

		return statisticsValuesSelect;
	}, [exception]);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onChangeSelect = async (value: FilterStatisticsKey) => {
		if (exception && (productId === undefined || !productId)) {
			toast({
				description: `Selecione o produto para visualizar a estatistica`,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
			return;
		}
		const statisticsData: StatisticsValues = statisticsValues[value];
		try {
			if (statisticsData?.onSelect) {
				setIsLoading(true);
				const result = await statisticsData.onSelect(
					{
						cycleId,
						salesChannelId,
						productId,
						familyId,
						page,
						companyAreaId,
						salesZoneId,
					},
					cycleData,
				);
				if (result?.error) {
					toast({
						title: 'Erro ao buscar dados',
						description: result.message,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				} else if (result?.values) {
					setExtraResult(result.extraResult);
					setValues(result.values);
					setAverage?.(result.media);
					setTotal?.(result.total);
					setValueSelect(value);

					if (result?.extraResult?.isInvalidData) return;
				}
				setIsLoading(false);
			} else {
				setValueSelect(value);
			}
		} catch (err) {
			setIsLoading(false);
		}
	};

	return { onChangeSelect, valueSelect, options, isLoading, extraResult };
};
