import {
	Flex,
	Heading,
	Text,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';

import { columnsDataEstoqueFamilia } from './variables/columnsData';

import CheckTable from 'components/dataTables/CheckTable';

export const ModalFamiliaEstoque = (props) => {
	const { isOpen, onClose, family } = props;

	const data = family[0]?.produtos;

	const familia = data?.map((resource) => {
		return {
			id: resource.id,
			codigo: resource.codigo_produto,
			nome: resource.nome_produto,
			quantidade: resource.estoque_atual?.toLocaleString('pt-BR'),
		};
	});

	const dataDelete = {
		provider: 'activity',
		toastSuccessTitle: 'Excluido com sucesso!',
		toastSuccessDescription: 'Atividade excluida com sucesso.',
		toastErrorTitle: 'Erro ao excluir atividade!',
		title: 'Deseja realmente excluir esta Atividade?',
		text: [
			'Você está prestes a excluir esta atividade da sua base de cadastros.',
			'',
			'Você tem certeza que deseja excluir?',
		],
		invalidate: 'activities-list',
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'3xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalCloseButton />
				<ModalBody>
					<Heading
						color='gray.700'
						mb={16}
						size='md'
					>
						{family[0]?.nome_familia}
					</Heading>
					<Flex direction='column'>
						{/* { isLoading && <CircularProgress isIndeterminate color='red.600' /> }
                            { isError && <h1>Ocorreu um erro, tente novamente!</h1> } */}
						{data && (
							<CheckTable
								isSearch={false}
								columnsData={columnsDataEstoqueFamilia}
								textHelper='Índice de Rendimento Operacional Global dos equipamentos'
								dataDelete={dataDelete}
								tableData={familia}
								renderedInModal={true}
							/>
						)}
						{!data && (
							<Text
								textAlign={'center'}
								mb={25}
							>
								Sem produtos na família selecionada
							</Text>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
