import api from '../api';

export const getProjectionByDate = async ({ queryKey }) => {
	const [, mes, ano] = queryKey;
	const url =
		'/cadenciaVenda/cadenciaVendaByMesAno?ano=' + ano + '&mes=' + mes;
	const response = await api.get(url);
	return response.data;
};

// save
export const saveProjection = async (data) => {
	const url = '/cadenciaVenda';
	const response = await api.post(url, data);
	return response.data;
};

export const getProjectionFilters = async ({ queryKey }) => {
	const [, ano] = queryKey;
	const url = '/cadenciaVenda/filtros?ano=' + ano;
	const response = await api.get(url);
	return response.data;
};
export const getHolidays = async ({ queryKey }) => {
	const [, mes, ano] = queryKey;
	if (!mes || !ano) {
		return undefined;
	}

	const url = '/diaUtil/listFeriadosByMesAno?ano=' + ano + '&mes=' + mes;
	const response = await api.get(url);
	return response.data;
};
