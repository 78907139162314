import {
	defineBorderColorValue,
	defineInputValue,
} from './constants/inputsControl';
import { Input } from '@chakra-ui/react';
import { setInputsWithLocalstorage } from './constants/setInputsWithLocalstorage';
import { useEffect } from 'react';
import { InputsInterface } from './models/InputsModel';
import { getLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';

export const InputNumber = ({ ...others }: InputsInterface) => {
	const {
		dataTest,
		column,
		handleOnChange,
		inputValue,
		module,
		name,
		setInputValue,
		validation,
	} = others;

	const localStorageValue = getLocalStorageFilter(module);

	useEffect(() => {
		if (localStorageValue.length > 0) {
			setInputsWithLocalstorage({
				column,
				localStorageValue,
				name,
				setInputValue,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Input
			data-test={dataTest}
			type={'number'}
			borderRadius={'10px'}
			borderColor={defineBorderColorValue({
				name,
				validation,
			})}
			focusBorderColor='#e2e8f0'
			h={'3em'}
			fontSize={'12px'}
			color={'blue.800'}
			placeholder={'1.000,00'}
			name={name}
			onChange={(event) => handleOnChange(event)}
			value={defineInputValue({
				name,
				inputValue,
			})}
		/>
	);
};
