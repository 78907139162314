import { Flex } from '@chakra-ui/react';
import { setInputsWithLocalstorage } from './constants/setInputsWithLocalstorage';
import { useEffect, useState } from 'react';
import './styles/InputBoolean.css';
import { InputsInterface } from './models/InputsModel';
import { getLocalStorageFilter } from 'components/filter/utils/localStorageFilterManager';

export const InputBoolean = ({ ...others }: InputsInterface) => {
	const { column, handleOnChange, inputValue, module, name, setInputValue } =
		others;

	const [selected, setSelected] = useState('');

	const localStorageValue = getLocalStorageFilter(module);

	useEffect(() => {
		if (localStorageValue.length > 0) {
			setInputsWithLocalstorage({
				column,
				localStorageValue,
				name,
				setInputValue,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (inputValue.boolean !== null) {
			if (inputValue.boolean === true) {
				setSelected('sim');
			} else if (inputValue.boolean === false) {
				setSelected('nao');
			}
		}
	}, [inputValue?.boolean]);

	const handleOnChangeTwo = (event) => {
		const value = event.target.value;
		setSelected(value);
		const valueSelected = value === 'sim' ? true : false;
		const editedEvent = {
			target: {
				value: valueSelected,
				name: event.target.name,
			},
		};
		handleOnChange(editedEvent);
	};

	return (
		<Flex
			margin={'5px'}
			width={'100%'}
			justifyContent={'space-around'}
			m={0}
			fontSize={'14px'}
		>
			<Flex>
				<input
					className='checkbox-input'
					type='radio'
					name={name}
					id='sim'
					value={'sim'}
					onChange={(event) => handleOnChangeTwo(event)}
					checked={selected === 'sim'}
				/>
				<label
					className='radio-label'
					htmlFor='sim'
				>
					Sim
				</label>
			</Flex>

			<Flex>
				<input
					className='checkbox-input'
					type='radio'
					name={name}
					id='nao'
					value={'nao'}
					onChange={(event) => handleOnChangeTwo(event)}
					checked={selected === 'nao'}
				/>
				<label
					className='radio-label'
					htmlFor='nao'
				>
					Não
				</label>
			</Flex>
		</Flex>
	);
};
