

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Flex, Heading, Icon, Text, useColorModeValue
} from "@chakra-ui/react";
import { TablerIcon } from "@tabler/icons";

interface PrpsStatisticTable {
    name: string,
    icon: TablerIcon,
    message: string
}
export default function InformationNotFound(props: PrpsStatisticTable) {
    const { name, icon, message } = props;
    const textColor = useColorModeValue("secondaryGray.900", "white");
    return (<Flex
        direction='column'
        w='100%'
        alignItems={"center"}
        px='0px'>
        <Flex
            align={{ sm: "flex-start", lg: "center" }}
            w='100%'
            gap={"16px"}
            flexWrap={"wrap"}
            mt="20px"
            px='22px'>
            <Icon
                color='secondaryGray.500'
                as={icon}
                w='20px'
                h='20px' />
            <Heading
                color={textColor}
                fontSize='xl'
                fontWeight='700'
                lineHeight='100%'>
                {name}
            </Heading>


        </Flex>
            <Text>
                {message}
            </Text>
    </Flex>
    );
}
