import { currencyToNumber } from 'utils/functions/currencyToNumber';
import {
	columnFlexProps,
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { renderClient } from 'utils/forms/autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateForecast } from 'services/queryClient/wrapperHooks/forecast/useCreateForecast';
import { useEditForecast } from 'services/queryClient/wrapperHooks/forecast/useEditForecast';
import { useHistory } from 'react-router-dom';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';
import {
	validateByRegex,
	validateMin,
	validateRequired,
} from '../../utils/forms/validations';

interface IFormCadastrarEditarForecast {
	initialState: any;
	forecastId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarForecast = ({
	initialState,
	forecastId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarForecast) => {
	const history = useHistory();

	const isEditing = !!forecastId;

	const { data: SalesChannelData, isLoading: isLoadingSalesChannelData } =
		useSalesChannelList();

	const [formInitialState, setFormInitialState] = useState<any>();

	const [payload, setPayload] = useState<any>();

	const { mutate: createForecast } = useCreateForecast(payload);
	const { mutate: updateForecast } = useEditForecast(payload, forecastId);

	useEffect(() => {
		setFormInitialState(initialState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialState]);

	const goToForecast = useCallback(() => {
		history.goBack();
	}, [history]);

	const salesChannelOptions = useMemo(() => {
		return (
			SalesChannelData?.map((channel) => {
				return (
					<option value={channel?.id}>
						{channel?.nome_canal_venda}
					</option>
				);
			}) || []
		);
	}, [SalesChannelData]);

	const handleOnChangeCode = useCallback((event, form) => {
		form.setValue('sequencia_codigo', '');
	}, []);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				codigo: values?.codigo,
				sequencia_codigo: values?.sequencia_codigo,
				data: values?.data,
				cliente_id: values?.cliente_id,
				canal_venda_id: Number(values?.canal_venda_id),
				receita_bruta: currencyToNumber(values?.receita_bruta),
			};

			setPayload(body);

			if (isEditing) updateForecast();
			else createForecast();
		},
		[createForecast, isEditing, updateForecast],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps: {
					flexDirection: 'row',
					width: '100%',
				},
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'codigo',
							label: 'Código do Forecast',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
							inputProps: {
								'data-test':
									'input-codigo_do_forecast-cadastrar_editar-page-forecast',
								...defaultInputStyle,
								onChange: handleOnChangeCode,
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'sequencia_codigo',
							label: 'Sequência do Cód. do Forecast',
							showRequiredOnLabel: isEditing,
							validate: {
								...(isEditing ? validateRequired() : {}),
								...validateMin(
									1,
									'O valor deve ser maior ou igual a 1!',
								),
								...validateByRegex(
									/^[1-9]\d*$/,
									'O valor deve ser um número inteiro válido!',
								),
							},
							inputProps: {
								'data-test':
									'input-sequencia_do_cod_do_forecast-cadastrar_editar-page-forecast',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'date',
							name: 'data',
							label: 'Competência',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-competencia-cadastrar_editar-page-forecast',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda_id',
							label: 'Canal de venda',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-forecast',
								...defaultInputStyle,
								selectOptions: salesChannelOptions,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'cliente_id',
							label: 'Cliente',
							inputProps: {
								'data-test':
									'autocomplete-cliente-cadastrar_editar-page-forecast',
								...defaultInputStyle,
								searchType: 'cliente',
								renderInputLabel: renderClient,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.client
									? renderClient(
											autocompleteAdditionalData?.client,
									  )
									: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'receita_bruta',
							label: 'Receita bruta',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-receita_bruta-cadastrar_editar-page-forecast',
								...defaultInputStyle,
								allowNegativeValue: false,
							},
						}),
					},
				],
			},
		];
	}, [
		handleOnChangeCode,
		salesChannelOptions,
		autocompleteAdditionalData?.client,
		isEditing,
	]);

	const renderForm = useCallback(() => {
		if (isLoadingSalesChannelData) return <LoadingSpinner />;

		return (
			<GenericForm
				dataTestProps={{
					cancel: 'button-cancelar-page-forecast',
					save: 'button-salvar-page-forecast',
				}}
				initialState={formInitialState}
				formFlexProps={formFlexProps}
				rows={renderRows()}
				style={defaultFormStyle}
				onSubmit={onSubmit}
				onCancel={goToForecast}
			/>
		);
	}, [
		isLoadingSalesChannelData,
		formInitialState,
		renderRows,
		onSubmit,
		goToForecast,
	]);

	return renderForm();
};

export default FormCadastrarEditarForecast;
