import { useMutation } from '@tanstack/react-query';
import { getCustomerByNameOrCnpjWithLimit } from 'services/api/requests/customer';
import { IAutocomplete } from '../../../utils/forms/autocomplete';

export const useGetCustomerByNameOrCnpjAsMutate = ({
	busca,
	limit,
	orderBy,
	sort,
}: IAutocomplete) => {
	const _order = orderBy || 'razao_social';
	const _sort = sort || 'asc';
	return useMutation(() =>
		getCustomerByNameOrCnpjWithLimit(busca, limit, _order, _sort),
	);
};
