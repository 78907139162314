import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSalesZone } from 'services/api/requests/requests';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useCreateSalesZone = (form?, setForm?, formProps?) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(createSalesZone, {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['commercialHierarchy-list']);
			toast({
				title: 'Criado com sucesso!',
				description: 'Zona de Venda criada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
			if (form && setForm) {
				setForm({
					...form,
					zonaVenda: success.id,
				});
			}
			if (formProps) {
				const { setValue } = formProps;
				setValue('zona_venda', success?.id);
			}
			console.log('Zona de Venda criada com sucesso!');
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Zona de Venda!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
