import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { updateUser } from "services/api/requests/authentication";

export const useUpdateUsers = (id: number, body: any) => {
    const toast = useToast()
    const queryClient = useQueryClient()
    const history = useHistory()

    return useMutation(() => updateUser(id, body), {
        onSuccess: (sucess) => {
            queryClient.invalidateQueries(["users-list"])
            history.goBack()
            toast({
                title: 'Atualizado com sucesso!',
                description: "Usuário(a) atualizado(a) com sucesso.",
                position: 'bottom-left',
                status: 'success',
                duration: 8000,
                isClosable: true,
            })
        },
        onError: (error: any, variables, context) => {
            console.log(error.response.data)
            if (error.response.data.length === 1) {
                const errorMessage = error.response.data[0].message
                const pathNameAdjustment = error.response.data[0].path.replace(/_/g, " ")
                toast({
                    title: 'Erro ao atualizar usuário(a).',
                    description: `${errorMessage} Campo: ${pathNameAdjustment}`,
                    position: 'bottom-left',
                    status: 'error',
                    duration: 8000,
                    isClosable: true,
                })
            } else if (error.response.data.length > 1) {
                error.response.data.forEach((error, index) => {
                    const errorMessage = error.response.data[index].message
                    const pathNameAdjustment = error.response.data[index].path.replace(/_/g, " ")
                    toast({
                        title: 'Erro ao atualizar usuário(a).',
                        description: `${errorMessage} Campo: ${pathNameAdjustment}` ,
                        position: 'bottom-left',
                        status: 'error',
                        duration: 8000,
                        isClosable: true,
                    })
                })
            }
        },
    })
}