import {
	Button,
	Flex,
	Icon,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import {
	MdChevronLeft,
	MdChevronRight,
	MdFirstPage,
	MdLastPage,
} from 'react-icons/md';
import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';

export interface IManualPaging {
	page: number;
	size: number;
	totalPages: number;
	otherParams?: {
		[key: string]: any;
	};
}

interface IPagination {
	pagination: IManualPaging;
	setPagination: React.Dispatch<React.SetStateAction<IManualPaging>>;
	totalItemsPageList?: number[];
	refetchData?: () => void;
	showGoToFirstPage?: boolean;
	showGoToLastPage?: boolean;
	showGoToPreviousPage?: boolean;
	showGoToNextPage?: boolean;
	showGoToPage?: boolean;
	showInputLinesPerPage?: boolean;
	renderedInDarkMode?: boolean;
}

const ManualPaging = ({
	pagination,
	setPagination,
	totalItemsPageList = PAGINATION_SIZE_LIST,
	refetchData,
	showGoToFirstPage = true,
	showGoToLastPage = true,
	showGoToPreviousPage = true,
	showGoToNextPage = true,
	showGoToPage = true,
	showInputLinesPerPage = true,
	renderedInDarkMode = false,
}: IPagination) => {
	const colorScheme = renderedInDarkMode
		? ['white', 'secondaryGray.900']
		: ['secondaryGray.900', 'white'];

	const textColor = useColorModeValue(colorScheme[0], colorScheme[1]);

	function setPaginationPropsByName(name, value) {
		setPagination((prev: IManualPaging) => ({
			...prev,
			[name]: value,
			...(name === 'size'
				? {
						page: 0,
				  }
				: undefined),
		}));

		setTimeout(() => refetchData?.(), 200);
	}

	return (
		<Flex
			justifyContent='space-between'
			m={4}
			alignItems='center'
		>
			<Flex>
				{showGoToFirstPage && (
					<Button
						title='First Page'
						variant='no-effects'
						onClick={() => setPaginationPropsByName('page', 0)}
						isDisabled={pagination.page === 0}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdFirstPage}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
				)}
				{showGoToPreviousPage && (
					<Button
						title='Previous Page'
						variant='no-effects'
						onClick={() =>
							setPaginationPropsByName(
								'page',
								pagination.page - 1,
							)
						}
						isDisabled={pagination.page === 0}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdChevronLeft}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
				)}
			</Flex>
			<Flex alignItems='center'>
				{showInputLinesPerPage && (
					<>
						<Text
							me='10px'
							minW='max-content'
							fontSize='sm'
							color='gray.500'
							fontWeight='normal'
						>
							Linhas por página
						</Text>
						<Select
							w={20}
							size='md'
							borderRadius='8px'
							mr={8}
							value={pagination.size}
							onChange={(e) =>
								setPaginationPropsByName(
									'size',
									Number(e.target.value),
								)
							}
						>
							{totalItemsPageList.map((s) => (
								<option value={s}>{s}</option>
							))}
						</Select>
						<Text
							// flexShrink='0'
							mr={8}
							minW='max-content'
							fontSize='sm'
							color='gray.500'
							fontWeight='normal'
						>
							Página{' '}
							<Text
								fontWeight='bold'
								as='span'
							>
								{pagination.page + 1}
							</Text>{' '}
							de{' '}
							<Text
								fontWeight='bold'
								as='span'
							>
								{pagination.totalPages}
							</Text>
						</Text>
					</>
				)}
				{showGoToPage && (
					<>
						<Text
							// flexShrink='0'
							me='10px'
							minW='max-content'
							fontSize='sm'
							color='gray.500'
							fontWeight='normal'
						>
							Ir para a página
						</Text>{' '}
						<NumberInput
							maxW={28}
							size='md'
							min={1}
							max={pagination.totalPages}
							onChange={(value) => {
								const newPage = value ? Number(value) - 1 : 0;

								if (
									newPage > -1 &&
									newPage <= pagination.totalPages
								)
									setPaginationPropsByName('page', newPage);
							}}
							value={pagination.page + 1}
							defaultValue={pagination.page + 1}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
					</>
				)}
			</Flex>
			<Flex>
				{showGoToNextPage && (
					<Button
						title='Next Page'
						variant='no-effects'
						onClick={() =>
							setPaginationPropsByName(
								'page',
								pagination.page + 1,
							)
						}
						isDisabled={
							pagination.page === pagination.totalPages - 1
						}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdChevronRight}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
				)}
				{showGoToLastPage && (
					<Button
						title='Previous Page'
						variant='no-effects'
						onClick={() =>
							setPaginationPropsByName(
								'page',
								pagination.totalPages - 1,
							)
						}
						isDisabled={
							pagination.page === pagination.totalPages - 1
						}
						transition='all .5s ease'
						w='50px'
						h='40px'
						borderRadius='8px'
						bg='transparent'
						border='1px solid'
						borderColor={'gray.200'}
						_hover={{
							bg: 'whiteAlpha.100',
							opacity: '0.7',
						}}
					>
						<Icon
							as={MdLastPage}
							w='16px'
							h='16px'
							color={textColor}
						/>
					</Button>
				)}
			</Flex>
		</Flex>
	);
};

export default ManualPaging;
