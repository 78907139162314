import { Box } from '@chakra-ui/react';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { useContextColaboracaoConsenso } from '../context';

interface SectionColaboracaoConsensoProps {
	children: JSX.Element;
}
export const SectionColaboracaoConsenso = ({
	children,
}: SectionColaboracaoConsensoProps) => {
	const { familyId, companyArea, isLoadingFilters } =
		useContextColaboracaoConsenso();

	if (!isLoadingFilters && !familyId) {
		const alertTitle = 'Atenção!';
		const alertDescription = companyArea?.nome_area_empresa
			? `Este ciclo já terminou e não identificamos cadastro de colaboração para o ${companyArea?.nome_area_empresa}!`
			: `Este ciclo já terminou e não identificamos cadastro de consenso!`;
		return (
			<Box
				h={'100%'}
				paddingTop={4}
			>
				<AlertComponent
					title={alertTitle}
					description={alertDescription}
					status={'warning'}
					hasCloseButton
				/>
			</Box>
		);
	}

	return <>{!!familyId && children}</>;
};
