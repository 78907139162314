import React from 'react';
import { Select } from '@chakra-ui/react';

// Precisa incluir os Parametros Filter e filter no cabeçalho das tabelas. Ex
// {
//   Header: 'Nome Recurso',
//   accessor: 'nome_recurso',
//   disableSortBy: true,
//   type: 'text',
//   Filter: SelectColumnFilter,
//   filter: 'equals',
// }

export function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id },
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	// Render a multi-select box
	return (
		<Select
			borderRadius={'8px'}
			w={'100px'}
			// w={'100%'}
			h={'28px'}
			// ml='-15px'
			value={filterValue}
			onChange={e => {
				setFilter(e.target.value || undefined);
			}}
			fontSize='12px'
		>
			<option value=''>Selecione</option>
			{options.map((option, i) => (
				<option
					key={i}
					value={option}
				>
					{option}
				</option>
			))}
		</Select>
	);
}
