import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../../../components/card/Card';
import { useStateList } from '../../../../../services/queryClient/wrapperHooks/useStateList';
import { useSalesZoneList } from '../../../../../services/queryClient/wrapperHooks/useSalesZoneList';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import FormCadastrarEditarCidade from '../../../../../components/forms/FormCadastrarEditarCidade';
import { useGetCitySalesZoneWithId } from '../../../../../services/queryClient/wrapperHooks/useGetCitySalesZoneWithId';

export default function EditarCidade() {
	const { setters } = useContext(SidebarHelpContext);

	const { id } = useParams<any>();

	const { setDataSidebar } = setters;

	const [formInitialState, setFormInitialState] = useState<any>();
	const [additionalData, setAdditionalData] = useState<any>();

	const { data: cityZoneData, isLoading: isLoadingCityZoneData } =
		useGetCitySalesZoneWithId(id);

	const { data: StatesData, isLoading: isLoadingState } = useStateList();
	const { data: SalesZoneData, isLoading: isLoadingSalesZone } =
		useSalesZoneList();

	const helpEditarCidades: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Cidades',
			firstParagraph:
				'Para editar uma associação de Zona de Venda com uma Cidade, selecione o estado (UF), a cidade e a zona de venda desejada.',
			secondParagraph:
				'Observação: uma cidade só pode ser associada a uma zona de venda.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarCidades);
	}, [helpEditarCidades, setDataSidebar]);

	useEffect(() => {
		if (!!StatesData?.length) {
			const currentState = StatesData?.find(
				(el) => el?.nome === cityZoneData?.cidade?.estado?.nome_estado,
			);

			setAdditionalData((prev) => ({
				...prev,
				states: StatesData,
				uf: currentState?.sigla || null,
			}));

			setFormInitialState({
				uf_id: currentState?.id || null,
				cidade_id: cityZoneData?.cidade_api_id,
				nome_microrregiao:
					cityZoneData?.microrregiao?.nome_microrregiao,
				nome_mesorregiao:
					cityZoneData?.microrregiao?.mesorregiao?.nome_mesorregiao,
				zona_venda_id: cityZoneData?.zona_venda_id,
			});
		}

		if (!!SalesZoneData?.data?.length) {
			setAdditionalData((prev) => ({
				...prev,
				salesZone: SalesZoneData?.data,
			}));
		}
	}, [StatesData, SalesZoneData, cityZoneData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{isLoadingState ||
				isLoadingSalesZone ||
				isLoadingCityZoneData ? (
					<LoadingSpinner />
				) : (
					<FormCadastrarEditarCidade
						initialState={formInitialState}
						cityId={id}
						additionalData={additionalData}
					/>
				)}
			</Card>
		</Flex>
	);
}
