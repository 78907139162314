import { useQuery } from '@tanstack/react-query';
import { getProductionDashboardOptionsFilter } from 'services/api/requests/dashboardProducao/productionDashboardOptionsFilter';
export const useProductionDashboardOptionsFilter = (params) => {
	return useQuery(
		['production-dashboard-filter', params],
		getProductionDashboardOptionsFilter,
		{
			refetchOnWindowFocus: false,
		},
	);
};
