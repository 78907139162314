import { ButtonGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { ButtonComponent } from "components/buttons/ButtonComponent";

export const TermsAndPolicyModal = (props) => {

    const { isOpen, onClose, setChecked, checked } = props

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent
                borderRadius={20}
            >
                <ModalHeader
                    display="flex"
                    flexDir={"column"}
                >
                    Política Privacidade
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    overflowX={"auto"} maxHeight={"70vh"}
                >
                    <Text>
                        <Text as={"span"} color={"rgb(68, 68, 68)"}>
                        
                            <Text mb={5} textAlign="justify">Última atualização: 28 de março de 2023</Text>
                            <Text mb={3} textAlign="justify">
                               A <strong>Easy360 LTDA (Easy360)</strong> assume compromisso de garantir aos usuários dos seus serviços e
produtos a privacidade, liberdade de acesso, qualidade, transparência, segurança e não
discriminação, conforme as normas vigentes. 
                            </Text>

                           <Text mb={3} textAlign="justify"> Esta Política de Privacidade se aplica aos usuários dos nossos serviços e produtos disponibilizados
pela empresa <strong>Easy360</strong>, e explica as políticas e procedimentos sobre a coleta, uso e
                                compartilhamento dos dados pessoais.</Text>
                            <Text mb={3} textAlign="justify">
Ao realizar o cadastro na plataforma para utilizar os serviços e produtos Easy360, o Usuário
declara ter lido, compreendido e aceito todas as disposições desta Política de Privacidade, a qual
complementa os Termos de Uso aceitos quando do seu cadastramento junto ao respectivo
                                sistema.</Text>
                        
                            <Text>
                                1. ENTENDENDO OS CONCEITOS-CHAVES
                            </Text>
                            <Text mb={3} ml={4}  textAlign="justify">
                                   1.1 Para tornar mais clara a compreensão desta Política de Privacidade, definem-se os
                                seus principais conceitos: 
                            </Text>
                            <Text mb={3} ml={7} textAlign="justify"> 
                                a)<strong> Dado Pessoal: </strong> trata-se de qualquer informação relacionada à pessoa natural,
direta ou indiretamente, identificada ou identificável, seja em meio digital ou
físico. <br />
b)<strong> Titular do dado: </strong> é a pessoa natural a quem se referem os dados pessoais. <br />
c)<strong> Tratamento: </strong> refere-se a qualquer operação realizada com os dados pessoais,
como por exemplo, a coleta, produção, classificação, utilização, acesso,
reprodução, transmissão, distribuição, processamento, arquivamento,
armazenamento, eliminação, avaliação ou controle da informação, modificação,
comunicação, transferência ou extração. <br />
d)<strong> Consentimento: </strong> manifestação livre, informada e inequívoca pela qual o titular
concorda com o tratamento de seus dados pessoais para uma finalidade
determinada. <br />
e)<strong> Controlador: </strong> é a pessoa a quem compete as decisões do tratamento dos dados
pessoais, é quem determina o escopo do tratamento dos dados. <br />
f)<strong> Operador: </strong> é a pessoa que realiza o tratamento dos dados pessoais em nome do
controlador. <br />
g)<strong> Conta: </strong> significa uma conta única criada para que você acesse nosso Serviço ou
partes de nosso Serviço. <br />
h)<strong> Afiliada: </strong> significa uma entidade que controla, é controlada ou está sob controle
comum com uma parte, onde "controle" significa a propriedade de 50% ou mais
das ações, participação acionária ou outros valores mobiliários com direito a voto
                                ou eleição de diretores ou outra autoridade de gestão
i) <strong>Cookies:</strong> são arquivos de internet que armazenam de forma temporária o que
você está visitando na rede. São criados e enviados pelos websites ao seu
computador sempre que você os visita. <br />
j) <strong>Empresa:</strong> (referida como “a Empresa”, “Nós” ou “Nosso” nesse contrato) referese à empresa Easy360 LTDA, localizada na Avenida Comendador Franco, 1341 -
Jardim Botânico, Curitiba - PR, 80215-090. <br />
k) <strong>Aplicação:</strong> se refere a plataforma Easy360, o programa de software (SaaS)
fornecido pela Empresa. <br />
l) <strong>Serviço:</strong> refere-se à Aplicação. <br />
m) <strong>Dispositivo:</strong> significa qualquer dispositivo que possa acessar o Serviço, como um
computador, um celular ou um tablet digital. <br />
n) <strong>Prestador de Serviços:</strong> significa qualquer pessoa singular ou coletiva que
processe os dados em nome da Empresa. Refere-se a empresas ou indivíduos
terceiros empregados pela Empresa para facilitar o serviço, para fornecer o
Serviço em nome da Empresa, para executar serviços relacionados ao Serviço ou
para ajudar a Empresa a analisar como o Serviço é usado. <br />
o) <strong>Dados de Uso:</strong> referem-se aos dados coletados automaticamente, gerados pelo
uso da Aplicação ou da própria infraestrutura da Aplicação. <br />
p) <strong>Violação de dados pessoais:</strong> acessos não autorizados e de situações acidentais
ou ilícitas de destruição, perda, alteração, aquisição, uso, divulgação,
comunicação ou qualquer forma de tratamento inadequado ou ilícito.
                            </Text>                                
                            <Text>2. AUTORIZAÇÃO</Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                2.1 Ao concordar com os Termos de Uso da aplicação Easy360, o Usuário também
aceita esta Política de Privacidade, e consente com o tratamento dos seus dados
                                pessoais, na forma e hipóteses aplicáveis. <br />
                                2.2 O <strong>Usuário</strong> fica ciente de que as operações descritas nesta Política são
indispensáveis para utilizar os serviços e produtos disponibilizados pela Easy360,
sendo que a sua discordância em relação ao conteúdo deste documento
impossibilitará o início ou a continuidade do uso desses serviços e produtos
                            </Text>
                            <Text>3. TIPOS DE DADOS PESSOAIS COLETADOS</Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                3.1 A Easy360, como operadora de dados, poderá ter acesso a certas informações de
                                identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo. Informações pessoalmente identificáveis podem incluir, mas não estão limitadas
                            </Text>
                            <Text mb={3} ml={7} textAlign="justify">
                                a:
                                <Text ml={4}>
                                    a) Endereço eletrônico (e-mail) <br />
                                    b) Nome e sobrenome <br />
                                    c) Número de telefone <br />
                                    d) Endereço, Estado, Província, CEP/Código Postal, Cidade <br />
                                    e) Dados de Uso:
                                    <Text ml={4}>
                                        1. Os Dados de Uso são coletados automaticamente ao usar o
Serviço. <br />
2. Os Dados de Uso podem incluir informações como o endereço do
Protocolo de Internet do seu dispositivo (por exemplo, endereço
IP), tipo de navegador, versão do navegador, as páginas do nosso
Serviço que você visita, a hora e a data da sua visita, o tempo
gasto nessas páginas, identificadores exclusivos do dispositivo e
outros dados de diagnóstico. <br />
3. Quando você acessa o Serviço por ou através de um dispositivo
móvel, podemos coletar certas informações automaticamente,
incluindo, mas não limitado a, o tipo de dispositivo móvel que
você usa, seu ID exclusivo de dispositivo móvel, o endereço IP de
seu serviço móvel, seu sistema operacional móvel, o tipo de
navegador de Internet móvel que você usa, identificadores
exclusivos de dispositivo e outros dados de diagnóstico. <br />
4. Também podemos coletar informações que seu navegador envia
sempre que você visita nosso Serviço ou quando você acessa o
                                        Serviço por ou através de um dispositivo móvel.
                                    </Text>
                                </Text>
                                </Text>

                            <Text>4. TRANSFERÊNCIA DOS DADOS PARA OUTROS PAÍSES</Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                4.1 Os dados pessoais dos usuários poderão ser transferidos e tratados fora do Brasil,
como no caso de a Easy360 armazená-los em provedores de computação em nuvem
localizados em outros países, observadas as melhores práticas de segurança e
privacidade para garantir a integridade dos dados pessoais, bem como, em
                                conformidade com a legislação em vigor e demais normas regulamentares aplicáveis.
                            </Text>
                            <Text> 5. USO DOS DADOS PESSOAIS </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                5.1 A Easy360 pode utilizar os dados pessoais para os seguintes fins:
                            </Text>
                            <Text mb={3} ml={7} textAlign="justify">
                                a) <strong>Para fornecer e manter o nosso Serviço,</strong> incluindo para monitorizar a
utilização do nosso Serviço. <br />
b) <strong>Para gerenciar a sua conta:</strong> para gerir o seu registo como utilizador do
Serviço. Os Dados Pessoais que você fornece podem lhe dar acesso a
diferentes funcionalidades do Serviço que estão disponíveis para você
como um usuário registrado. <br />
c) <strong>Para a execução de um contrato:</strong> o desenvolvimento, a conformidade
e a realização do contrato de compra dos produtos, itens ou serviços
que você comprou ou de qualquer outro contrato conosco através do
                                Serviço. <br />
                                d) <strong>Para entrar em contato com você:</strong> Para contatá-lo por e-mail,
chamadas telefônicas, SMS ou outras formas equivalentes de
comunicação eletrônica, como notificações de um aplicativo ou
aplicativo móvel sobre atualizações ou comunicações informativas
relacionadas às funcionalidades, produtos ou serviços contratados,
incluindo as atualizações de segurança, quando necessário ou razoável
para sua implementação. <br />
e) <strong>Para fornecer </strong>notícias, ofertas especiais e informações gerais sobre
outros bens, serviços e eventos que oferecemos que são semelhantes
aos que você já comprou ou perguntou, a menos que você tenha
optado por não receber tais informações. <br /> 
f) <strong>Para gerenciar seus pedidos: </strong>para atender e gerenciar os seus pedidos. <br />
g) <strong> transferências de negócios: </strong>Para Podemos usar suas informações para
avaliar ou conduzir uma fusão, reestruturação, reorganização,
dissolução ou outra venda ou transferência de alguns ou todos os
nossos ativos, seja como uma empresa em andamento ou como parte
de um processo de falência, liquidação ou similar, no qual os Dados
Pessoais mantidos por nós sobre os usuários do nosso serviço estão
entre os ativos transferidos. <br />
h) <strong>Para outros fins:</strong> Podemos usar suas informações para outros
objetivos, como análise de dados, identificação de tendências de uso,
determinação da eficácia de nossas campanhas promocionais e para
avaliar e melhorar nosso serviço, produtos, marketing e sua
experiência
                            </Text>
                            <Text>
                                6. COMPARTILHAMENTO DOS DADOS PESSOAIS
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                6.1 Podemos compartilhar suas informações pessoais nas seguintes situações:
                            </Text>
                            <Text mb={3} ml={7} textAlign="justify">
                                a) <strong>Com Prestadores de Serviços: </strong>podemos compartilhar suas
informações pessoais com Provedores de Serviços para monitorar e
analisar o uso de nosso Serviço, para entrar em contato com você. <br />
b) <strong>Para transferências de negócios: </strong>podemos compartilhar ou
transferir suas informações pessoais em conexão com, ou durante as
negociações de, qualquer fusão, venda de ativos da empresa,
financiamento ou aquisição de todo ou parte de nossos negócios para
outra empresa. <br />
c) <strong>Com Afiliados:</strong> Podemos compartilhar suas informações com nossos
afiliados, nestes casos exigiremos que esses afiliados honrem esta
Política de Privacidade. As afiliadas incluem nossa empresa mãe e
                                quaisquer outras subsidiárias, parceiros de joint venture ou outras 
                                empresas que controlamos ou que estão sob controle comum
conosco. <br />
d) <strong>Com parceiros de negócios: </strong>podemos compartilhar suas informações
com nossos parceiros de negócios para oferecer a você determinados
produtos, serviços ou promoções. <br />
e) <strong>Com outros usuários:</strong> quando você compartilha informações
pessoais ou interage de outra forma nas áreas públicas com outros
usuários, tais informações podem ser visualizadas por todos os
usuários e podem ser distribuídas de forma indireta publicamente. <br />
f) <strong>Com o seu consentimento:</strong> podemos divulgar as suas informações
                                pessoais para qualquer outra finalidade com o seu consentimento.
                            </Text>
                            <Text>
                                7. ARMAZENAMENTO DE DADOS PESSOAIS
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Enquanto o Usuário for um cliente ou um cliente em prospecção da Easy360, para
atender às finalidades descritas nesta Política de Privacidade, os seus dados
                                pessoais poderão ser armazenados e mantidos de forma segura. <br />
                                2. A Easy360 utilizará os seus dados pessoais na medida do necessário para cumprir
as nossas obrigações legais (por exemplo, se formos obrigados a reter os seus
dados para cumprir as leis aplicáveis), resolver litígios e cumprir os nossos
                                acordos e políticas legais. <br />
                                3. A Easy360 também armazenará os dados de uso para fins de análise interna. Os
dados de uso geralmente são armazenados por um período mais curto, exceto
quando esses dados são usados para fortalecer a segurança ou para melhorar a
funcionalidade do nosso Serviço, ou quando somos legalmente obrigados a reter
                                esses dados por períodos mais longos.
                            </Text>
                            <Text>
                                8. TRANSFERÊNCIA DOS DADOS PESSOAIS
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Suas informações, incluindo Dados Pessoais, são processadas nos escritórios
operacionais da Empresa e em quaisquer outros locais onde as partes envolvidas
no processamento estejam localizadas. Isso significa que essas informações
podem ser transferidas para - e mantidas em - computadores localizados fora de
seu estado, província, país ou outra jurisdição governamental, onde as leis de
                                proteção de dados podem diferir daquelas de sua jurisdição. <br />
                                2. Seu consentimento com esta Política de Privacidade seguido pelo envio de suas
                                informações representa sua concordância com essa transferência. <br />
                                3. A Empresa tomará todas as medidas razoavelmente necessárias para garantir que
seus dados sejam tratados com segurança e de acordo com esta Política de
Privacidade e nenhuma transferência de seus dados pessoais ocorrerá para uma 
organização ou um país, a menos que haja controles adequados em vigor,
                                incluindo a segurança de seus dados e outras informações pessoais.
                            </Text>
                            <Text>
                                9. REMOÇÃO DOS DADOS PESSOAIS
                               </Text> 
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Você tem o direito de excluir ou solicitar que nós auxiliemos você na remoção dos
dados pessoais que coletamos sobre você. <br />
2. Nosso Serviço pode permitir que você exclua certas informações sobre você
dentro do próprio Serviço. <br />
3. Você pode atualizar, alterar ou excluir suas informações a qualquer momento,
entrando em sua conta, se tiver uma, e visitando a seção de configurações da
conta que permite gerenciar suas informações pessoais. Você também pode
entrar em contato conosco para solicitar acesso, corrigir ou excluir uma
informação pessoal que você nos forneceu. <br />
4. Observe, no entanto, que podemos precisar reter certa informação quando nós
                                temos a obrigação legal ou base legal para fazê-lo.
                            </Text>
                            <Text>
                                10. DIVULGAÇÃO DE SEUS DADOS PESSOAIS
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Transações Comerciais: Se a Empresa estiver envolvida em uma fusão, aquisição
ou venda de ativos, seus Dados Pessoais podem ser transferidos. Forneceremos
um aviso antes que seus Dados Pessoais sejam transferidos e fiquem sujeitos a
uma diferente Política de Privacidade. <br />
2. Aplicações de Lei: Em determinadas circunstâncias, a Empresa pode ser obrigada
a divulgar seus Dados Pessoais se for exigido por lei ou em resposta a pedidos
válidos de autoridades públicas (por exemplo, um tribunal ou uma agência
governamental). <br />
3. Outros requisitos legais: A Empresa pode divulgar seus Dados Pessoais na boa-fé
                                de que tal ação é necessária para:
                                <Text mb={3} ml={4} textAlign="justify">
                                    a) Cumprir uma obrigação legal <br />
b) Proteger e defender os direitos ou propriedades da Empresa <br />
c) Prevenir ou investigar possíveis irregularidades em conexão com o
Serviço <br />
d) Proteger a segurança pessoal dos Usuários do Serviço ou do público <br />
                                    e) Proteger contra responsabilidade legal 
                                </Text>
                                4. Segurança dos Dados Pessoais: A segurança dos seus Dados Pessoais é
importante para nós, mas lembre-se de que nenhum método de transmissão pela
Internet, ou método eletrônico de armazenamento, é 100% seguro. Embora nos
esforcemos para utilizar meios comercialmente aceitável para proteger seus
                                dados pessoais, nós não podemos garantir segurança absoluta.
                            </Text>
                            <Text>
                                11. PRIVACIDADE DAS CRIANÇAS
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Nosso Serviço não se dirige a menores de 12 anos incompletos. Não coletamos
intencionalmente informações de identificação pessoal de ninguém com menos
de 12 anos. Se você é pai ou responsável e está ciente que seu filho nos forneceu
Dados Pessoais, entre em contato conosco. Se tomarmos conhecimento de que
coletamos Dados Pessoais de qualquer pessoa com menos de 12 anos de idade
sem a verificação do consentimento dos pais, tomaremos medidas para remover
essas informações de nossos servidores. <br />
2. Se precisarmos confiar no consentimento como base legal para processar suas
informações e seu país exigir o consentimento de um dos pais, poderemos exigir
o consentimento de seus pais antes de coletarmos e usarmos essas informações. <br />
3. O tratamento de dados pessoais de crianças será realizado com o consentimento
específico e em destaque dado por pelo menos um dos pais ou pelo responsável
                                legal.
                            </Text>
                            <Text>
                                12. LINKS PARA OUTROS SITES
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Nosso serviço pode conter links para outros sites que não são operados por nós.
Se você clicar em um link de terceiros, você será direcionado para o site desse
terceiro. Recomendamos fortemente que reveja a Política de Privacidade de
cada site que visitar. <br />
2. Não temos controle e não assumimos a responsabilidade pelo conteúdo,
                                políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.
                            </Text>
                            <Text>
                                13. ALTERAÇÕES A ESTA POLÍTICA DE PRIVACIDADE
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Esta Política de Privacidade poderá sofrer alterações, em virtude de modificações
nas leis vigentes, necessidade de adequação tecnológica, mudanças ou inovação
nos serviços ou produtos. Essas atualizações passarão a ser válidas, eficazes e
vinculantes após serem publicadas no serviço Easy360, ou comunicadas aos
                                usuários por e-mail, via postal, ou qualquer outro meio eletrônico
                            </Text>
                            <Text>
                                14. FORO
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Ao realizar o cadastro no serviço Easy360, o Usuário está ciente de que são
aplicáveis as normas brasileiras para reger a relação regulamentada nesta
Política. <br />
2. Toda e qualquer questão oriunda da presente Política de Privacidade será
solucionada junto ao foro do município Curitiba/PR, com exclusão de qualquer
                                outro por mais privilegiado que seja.
                            </Text>
                            <Text>
                                15. FALE CONOSCO
                            </Text>
                            <Text mb={3} ml={4} textAlign="justify">
                                1. Se você tiver dúvidas sobre esta Política de Privacidade, você pode entrar em
                                contato conosco:
                                <Text mb={3} ml={4} textAlign="justify">
                                    a) Por e-mail: contato@easy360.ind.br <br />
                                    b) Por telefone: 054 99103-8968
                                </Text>
                                </Text>
        
                            

                        </Text>
                    </Text>

                </ModalBody>
                <ModalFooter>
                    <ButtonGroup spacing={"20px"}>
                        {/* <ButtonComponent
                            type={"ghost"}
                            title={"Cancelar"}
                            onClick={
                                () => {
                                    setChecked(false)
                                    onClose()
                                }
                            }
                        /> */}
                        <ButtonComponent
                            type={"primary"}
                            title={"Concordar e continuar"}
                            onClick={() => {
                                setChecked(true)
                                onClose()
                            }}
                        />
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}