/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Flex,
	Grid,
	Heading,
	Icon,
	Text,
	Tooltip,
	useColorModeValue,
} from '@chakra-ui/react';
import { TablerIcon } from '@tabler/icons';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import { TooltipHelper } from 'components/tooltip/TooltipHelper';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useContextColaboracaoConsenso } from 'pages/admin/demanda/templateCadastrarColabCons/context';
import GenericInput from '../inputs/GenericInput';
import { LoadingSpinner } from 'components/loading/Loading';
import SelectComponent from 'components/select/SelectComponent';

interface ComponetsRowsTextProps {
	column: ColumnData;
	row: Row | RowNew;
	cell: any;
}

export type ColumnData = {
	Header: string | JSX.Element;
	accessor: string;
	type: string;
	JSX?: (
		props: ComponetsRowsTextProps,
		component: JSX.Element,
	) => JSX.Element;
};

export type Row = {
	type: 'text';
	media: number | string;
	total: number | string;
	[key: string]: string | number | undefined | any;
};

export type RowNew = {
	type: 'text-hover';
	[key: string]: string | number | ValueHover | undefined;
};

export type RowInput = {
	type: 'input';
	media: number | string;
	total: number | string;
	errorConditional: (value: number | '') => boolean;
	preenchimento: {
		options: Array<{
			value: string;
			label: string;
		}>;
		onChange: (key: string, value: string) => void;
		onChangeSelect: (value: any) => Promise<void>;
		value: string;
		isLoading: boolean;
	};
	[key: string]: any;
};
export interface ValueHover {
	value: number | string;
	month: string;
	clientes: any;
	hasValue?: boolean;
	renderAsHover?: boolean;
	message?: string;
	onHoverCallback?: any;
	isLoading?: boolean;
}
export type RowHover = {
	type: 'text-hover';
	media: string | number;
	total: string | number;
	[key: string]: string | number | ValueHover | undefined;
};
interface PropsStatisticTable {
	columnsData: {
		header: ColumnData[];
		body: ColumnData[];
		footer: ColumnData[];
	};
	tableData: Array<Row | RowInput | RowHover | RowNew>;
	name?: string;
	icon?: TablerIcon;
	SectionButtons?: JSX.Element;
	sectionError?: boolean;
	conditionalFilter?: (props: ComponetsRowsTextProps) => boolean;
	onClickTable?: () => void;
	size?: number | string;
	applyLocaleString?: boolean;
	useInputMoney?: boolean;
	dataTest?: string;
	headerHelp?: any[];
}
interface ComponetsRowsInputProps {
	column: ColumnData;
	row: RowInput;
	cell: string | number;
	[key: string]: any;
}
interface ComponetsRowsHoverProps {
	column: ColumnData;
	row: RowHover;
	cell: ValueHover;
}

export const backgroundColors = {
	total: '#E9EDF7',
	media: '#F4F7FE',
	fatMeta: '#F4F7FE',
	fatBoaMeta: '#F4F7FE',
	fcstMeta: '#F4F7FE',
	fatBoaFcst: '#F4F7FE',
	crescimento: '#E0E5F2',
	fatHistorico: '#F4F7FE',
	fatRestrito: '#F4F7FE',
	fatBoaHistorico: '#F4F7FE',
	fatBoaRestrito: '#F4F7FE',
};
export const CellText = ({
	cell,
	showDecimals = true,
	applyLocaleString = false,
	column,
}): JSX.Element => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { configsCompany } = useGlobalContext();

	function render() {
		if (typeof cell === 'number') {
			if (showDecimals) {
				if (applyLocaleString) {
					return Number(
						cell.toFixed(configsCompany.precisao_decimal),
					)?.toLocaleString?.();
				}

				return Number(cell.toFixed(configsCompany.precisao_decimal));
			}
			return Number(cell.toFixed())?.toLocaleString?.();
		}

		return cell || '-';
	}

	return (
		<Text
			paddingX='8px'
			w={'100%'}
			h='100%'
			display={'flex'}
			alignItems='center'
			marginRight={0}
			color={textColor}
			fontSize='sm'
			fontWeight='700'
		>
			{render()}
		</Text>
	);
};

const StatisticTable = (props: PropsStatisticTable) => {
	const {
		columnsData,
		tableData,
		name,
		icon,
		SectionButtons,
		sectionError,
		conditionalFilter,
		onClickTable,
		size = 'auto',
		applyLocaleString = false,
		useInputMoney = false,
		dataTest,
		headerHelp = [],
	} = props;

	const rows = (column: ColumnData, type: any) => {
		return (
			<Grid
				bgColor={backgroundColors[column.accessor]}
				templateColumns={'auto'}
				alignContent={'center'}
				width={'100%'}
				flexGrow={1}
			>
				{tableData.map((row: any, index) => {
					const cell = row[column.accessor];
					if (
						index === tableData.length - 1 &&
						(tableData[index]?.canalVenda === 'Total' ||
							tableData[index]?.nomeFamilia === 'Total')
					) {
						if (type === 'footer') {
							return (
								<Flex
									h={'60px'}
									backgroundColor={'#E0E5F2'}
									display={
										!conditionalFilter ||
										conditionalFilter({ row, column, cell })
											? undefined
											: 'none'
									}
									width={'100%'}
									key={index}
								>
									<Text
										color='secondaryGray.900'
										fontSize='sm'
										fontWeight='700'
										lineHeight='100%'
										w='100%'
									>
										{componetsRows[row.type]({
											cell,
											column,
											row,
										})}
									</Text>
								</Flex>
							);
						}
						return (
							<Flex
								h={'60px'}
								display={
									!conditionalFilter ||
									conditionalFilter({ row, column, cell })
										? undefined
										: 'none'
								}
								backgroundColor={'#F4F7FE'}
								width={'100%'}
								key={index}
							>
								<Text
									color='secondaryGray.900'
									fontSize='sm'
									fontWeight='700'
									lineHeight='100%'
									textAlign='center'
									w='100%'
								>
									{componetsRows[row.type]({
										cell,
										column,
										row,
									})}
								</Text>
							</Flex>
						);
					}
					return (
						<Flex
							h={'60px'}
							w={size}
							key={index}
							display={
								!conditionalFilter ||
								conditionalFilter({ row, column, cell })
									? undefined
									: 'none'
							}
							data-test={`statistic_table-${column.accessor}`}
						>
							{column.JSX
								? column.JSX(
										{ cell, column, row },
										componetsRows[row.type]({
											cell,
											column,
											row,
										}),
								  )
								: componetsRows[row.type]({
										cell,
										column,
										row,
										applyLocaleString,
										useInputMoney,
										headerHelp,
								  })}
						</Flex>
					);
				})}
			</Grid>
		);
	};

	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const componetsRows = {
		text: CellText,
		input: CellInput,
		'text-hover': CellInputHover,
	};

	function getHelper(column) {
		if (!headerHelp?.length) return null;

		const help = headerHelp?.find(
			(help) => help.accessor === column.accessor,
		);

		if (!help) return null;

		return TooltipHelper(help?.tooltip || 'Não informado!');
	}

	return (
		<>
			<Flex
				direction='column'
				w='100%'
				px='0px'
				className='outer'
			>
				<Flex
					align={{ sm: 'flex-start', lg: 'center' }}
					w='100%'
					gap={'16px'}
					flexWrap={'wrap'}
					mt='20px'
					px='22px'
				>
					{icon && (
						<Icon
							color='secondaryGray.500'
							as={icon}
							w='20px'
							h='20px'
						/>
					)}
					<Heading
						color={sectionError ? 'red' : textColor}
						fontSize='xl'
						fontWeight='700'
						lineHeight='100%'
					>
						{name}
					</Heading>
					{SectionButtons}
				</Flex>
				<Grid
					templateColumns={'auto 1fr auto'}
					w='100$'
					color='gray.500'
					onClick={onClickTable}
					mb='5px'
					data-test={dataTest}
				>
					<Flex>
						{columnsData.header.map((column, index) => {
							return (
								<Flex key={index}>
									<Flex
										flexDirection={'column'}
										alignItems='start'
										justifyContent={'start'}
										key={index}
										borderColor={borderColor}
										padding={0}
										bgColor={
											backgroundColors[column.accessor]
										}
									>
										<Flex
											alignItems={'center'}
											paddingX={'8px'}
											h='40px'
											justify='space-between'
											padding='0'
											width={'100%'}
											fontSize={{
												sm: '10px',
												lg: '12px',
											}}
											color='gray.400'
											marginRight={'20px'}
										>
											{column.Header}
										</Flex>
										{rows(column, 'header')}
									</Flex>
								</Flex>
							);
						})}
					</Flex>
					<ScrollBar color='secondary'>
						<Flex
							position={'relative'}
							justifyContent={'space-around'}
							flexGrow={1}
						>
							{columnsData.body.map((column, index) => {
								return (
									<Flex
										flexGrow={1}
										key={index}
									>
										<Flex
											flexGrow={1}
											flexDirection={'column'}
											alignItems='start'
											justifyContent={'start'}
											key={index}
											borderColor={borderColor}
											padding={0}
											bgColor={
												backgroundColors[
													column.accessor
												]
											}
											data-test={`table-column-${column.Header}`}
										>
											<Flex
												paddingX={'8px'}
												alignItems={'center'}
												justify='space-between'
												padding='0'
												h='40px'
												width={'100%'}
												fontSize={{
													sm: '10px',
													lg: '12px',
												}}
												color='gray.400'
											>
												{column.Header}
											</Flex>
											{rows(column, 'body')}
										</Flex>
									</Flex>
								);
							})}
						</Flex>
					</ScrollBar>
					<Flex>
						{columnsData.footer.map((column, index) => {
							return (
								<Flex key={index}>
									<Flex
										flexDirection={'column'}
										alignItems='start'
										justifyContent={'start'}
										key={index}
										borderColor={borderColor}
										padding={0}
										bgColor={
											backgroundColors[column.accessor]
										}
									>
										<Flex
											h='40px'
											justify='space-between'
											padding='0'
											alignItems={'center'}
											width={'100%'}
											fontSize={{
												sm: '10px',
												lg: '12px',
											}}
											paddingX={'8px'}
											color='gray.400'
										>
											<Flex
												width='100%'
												gap={1}
												direction='row'
											>
												{column.Header}
												{getHelper(column)}
											</Flex>
										</Flex>
										{rows(column, 'footer')}
									</Flex>
								</Flex>
							);
						})}
					</Flex>
				</Grid>
			</Flex>
		</>
	);
};

export const CellClientInputHover = (cell, textColor) => {
	return (
		<Tooltip
			boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.08)'
			padding='20px'
			border={'1px solid #E0E5F2'}
			gap='10px'
			borderRadius={'16px'}
			background='white'
			display={'grid'}
			gridTemplateColumns={'repeat(2, auto)'}
			placement='top'
			label={
				<>
					<Text
						color={'easyBLUE.300'}
						fontWeight={700}
					>
						{cell.month}
					</Text>
					<Text
						color={'easyBLUE.300'}
						fontWeight={700}
					>
						{cell.value}
					</Text>
					{cell?.isLoading ? (
						<LoadingSpinner />
					) : (
						cell?.clientes?.map(({ nome, quantidade }, index) => {
							return (
								<>
									<Text
										color={'secondaryGray.500'}
										key={nome + index}
									>
										{nome}
									</Text>
									<Text
										color={'secondaryGray.500'}
										key={nome}
									>
										{quantidade}
									</Text>
								</>
							);
						})
					)}
				</>
			}
		>
			<Text
				paddingX='8px'
				alignItems={'center'}
				marginRight={0}
				color={textColor}
				fontSize='sm'
				display={'flex'}
				width={'100%'}
				height={'100%'}
				fontWeight='700'
				onMouseOver={cell?.onHoverCallback}
			>
				{cell.value || '-'}
			</Text>
		</Tooltip>
	);
};

const CellInputHover = ({
	cell,
	column,
}: ComponetsRowsHoverProps): JSX.Element => {
	const cell2 = cell as ValueHover;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const { keysPage } = useContextColaboracaoConsenso();

	if (
		column?.accessor === 'ciclo' &&
		keysPage.page === 'colaboracao' &&
		cell?.message
	) {
		return renderCycleCellHover(cell);
	}
	if (cell && (cell2?.clientes || cell2?.renderAsHover)) {
		return CellClientInputHover(cell, textColor);
	}

	return (
		<CellText
			cell={cell}
			column={undefined}
		/>
	);
};

const CellInput = ({
	cell,
	column,
	row,
	useInputMoney,
}: ComponetsRowsInputProps): JSX.Element => {
	if (column.Header === 'Prenchimento') {
		const { options, onChangeSelect, value } = row.preenchimento;

		return (
			<Flex
				width='100%'
				height={'100%'}
				paddingX='4px'
				paddingY='4px'
				position={'relative'}
			>
				<SelectComponent
					options={options}
					isLoading={row.preenchimento.isLoading}
					value={value}
					onChange={(newValue) => onChangeSelect(newValue)}
				/>
			</Flex>
		);
	}

	if (!isNaN(+column.accessor)) {
		const { configsCompany } = useGlobalContext();

		const defaultStyle = getDefaultStyle(cell, row);

		return (
			<Flex
				width='100%'
				height='100%'
				marginX='4px'
				alignItems='center'
				flexGrow={1}
			>
				{useInputMoney ? (
					<>
						<GenericInput
							type='product-quantity'
							inputProps={{
								maskConfig: {
									prefix: '',
									decimalLimit:
										configsCompany.precisao_decimal,
								},
								borderRadius: '15px',
								onChange: (value) => {
									row?.preenchimento?.onChange(
										column.accessor,
										value,
									);
								},
								style: defaultStyle,
								value: cell || cell === 0 ? cell : '',
							}}
						/>
					</>
				) : (
					<GenericInput type='text' />
				)}
			</Flex>
		);
	}
	return (
		<CellText
			cell={cell}
			column={undefined}
		/>
	);
};

export const generateRowsMonths = (
	startMounth: number,
	qtdMounth: number,
	abbreviatedMonth: boolean = true,
	addYear: boolean = false,
	startYear: number = 0,
	acessorIncludeYaer?: Boolean,
): ColumnData[] => {
	const mounths = [
		'JANEIRO',
		'FEVEREIRO',
		'MARÇO',
		'ABRIL',
		'MAIO',
		'JUNHO',
		'JULHO',
		'AGOSTO',
		'SETEMBRO',
		'OUTUBRO',
		'NOVEMBRO',
		'DEZEMBRO',
	];
	const indexSlice = abbreviatedMonth ? 3 : undefined;
	return Array.from({ length: qtdMounth }, (_, i) => {
		const date = new Date(startYear, startMounth + i);
		const mounth = date.getMonth();
		let year = '';
		if (addYear) year = '/' + date.getFullYear().toString().slice(-2);
		const Header = mounths[mounth].slice(0, indexSlice) + year;
		return {
			Header,
			accessor: acessorIncludeYaer
				? `${('0' + (mounth + 1)).slice(-2)}${year}`
				: (mounth + 1).toString(),
			type: 'text',
			disableSortBy: true,
		};
	});
};

export const renderCycleCellHover = (cell): JSX.Element => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Flex alignItems={'center'}>
			<Text
				paddingX='8px'
				w={'100%'}
				h='100%'
				display={'flex'}
				alignItems='center'
				marginRight={0}
				color={textColor}
				fontSize='sm'
				fontWeight='700'
			>
				{cell.value}
			</Text>
			{TooltipHelper(cell.message)}
		</Flex>
	);
};

const getDefaultStyle = (cell, row) => ({
	htmlSize: 4,
	paddingX: '8px',
	minW: '80px',
	className: 'input-tr',
	isInvalid: true,
	height: '100%',
	placeholder: '0',
	borderRadius: '2xl',
	value: cell || cell === 0 ? cell : '',
	width: '100%',
	border: '1px solid #E2E8F0',
	padding: '0 1rem',
	fontSize: '1rem',
	color: '#4A5568',
	outline: 'none',
	transition: 'all 0.2s',
	errorBorderColor: row?.errorConditional(cell as number | '')
		? 'gray.500'
		: 'red.300',
	'&:focus': {
		border: '1px solid #3182CE',
		boxShadow: '0 0 0 1px #3182CE',
	},
});

export default StatisticTable;
