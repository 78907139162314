import {
	Box,
	Flex,
	FormControl,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import { IconCloudUpload } from '@tabler/icons';
import DropzoneHeadersSheet from 'components/dropzone/DropzoneHeadersSheet';
import { useEffect, useState } from 'react';
import { useGetLineRestrictions } from 'services/queryClient/wrapperHooks/useGetLineRestrictions';
import { FileUploadErrorsModal } from './FileUploadErrorsModal';
import { LoadingUpload } from './LoadingUpload';
import { UseMutationResult } from '@tanstack/react-query';

interface FileUploadWithoutTemplateModalProps {
	headerText: string;
	module: string;
	isOpen: boolean;
	onClose: () => void;

	useUpload: (
		setSucessUpload: any,
		openTableModal: any,
		isOpen: any,
		onClose: any,
	) => UseMutationResult<any, unknown, void, unknown>;
	stringInvalidQuery: 'headers-sheet-reader';
	columnsData: any[];
	showLineRestricts?: boolean;
	hasBaseData?: boolean;
	rowHeaderStart?: number;
	setSucessImport?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FileUploadWithoutTemplateModal = (
	props: FileUploadWithoutTemplateModalProps,
) => {
	const {
		isOpen,
		onClose,
		headerText,
		module,
		useUpload,
		stringInvalidQuery,
		showLineRestricts,
		columnsData,
		hasBaseData,
		rowHeaderStart,
		setSucessImport,
	} = props;

	const [uploadPercentage, setUploadPercentage] = useState(0);
	const [sucessUpload, setSucessUpload] = useState(false);

	const [nameFile, setNameFile] = useState('');

	const [overwriteExistingData, setOverwriteExistingData] =
		useState<boolean>(false);

	const loadingModal = useDisclosure();
	const {
		isOpen: IsOpenTableModal,
		onClose: onCloseTableModal,
		onOpen: onOpenTableModal,
	} = useDisclosure();

	const easyRED = useColorModeValue('easyRED.300', 'easyRED.200');
	const easyBLUE = useColorModeValue('easyBLUE.300', 'easyBLUE.200');

	const {
		mutateAsync,
		data: uploadData,
		isSuccess,
	} = useUpload(setSucessUpload, onOpenTableModal, isOpen, onClose);
	const { data: lineRestrictions } = useGetLineRestrictions();

	useEffect(() => {
		if (setSucessImport && sucessUpload) {
			setSucessImport(true);
		}
	}, [setSucessImport, sucessUpload]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={'3xl'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={'30px'}
					aria-modal='false'
				>
					<ModalHeader
						textColor={easyBLUE}
						fontSize={'24px'}
						fontWeight='700'
					>
						{headerText}
						<Flex>
							<Text
								color={easyRED}
								fontSize='md'
								fontWeight='bold'
							>
								Tamanho máximo 1 MB (1.024 KB)
							</Text>
						</Flex>
					</ModalHeader>

					<ModalCloseButton />
					<ModalBody>
						{hasBaseData && (
							<FormControl
								mr='5px'
								mb='20px'
							></FormControl>
						)}
						<DropzoneHeadersSheet
							uploadPercentage={uploadPercentage}
							openLoading={loadingModal.onOpen}
							mutateAsync={mutateAsync}
							stringInvalidQuery={stringInvalidQuery}
							onClose={onClose}
							onCloseDropzone={loadingModal.onClose}
							isOpen={isOpen}
							onOpenTableModal={onOpenTableModal}
							sucessUpload={sucessUpload}
							setUploadPercentage={setUploadPercentage}
							setNameFile={setNameFile}
							overwriteExistingData={overwriteExistingData}
							setOverwriteExistingData={setOverwriteExistingData}
							setSucessUpload={setSucessUpload}
							rowHeaderStart={rowHeaderStart}
							content={
								<Box>
									<Icon
										as={IconCloudUpload}
										w='80px'
										h='80px'
										color={easyRED}
										mb={'30'}
									/>
									<Flex
										justify='center'
										mx='auto'
										mb='12px'
									>
										<Text
											fontSize='20px'
											fontWeight='700'
											color={easyBLUE}
										>
											Arraste seu arquivo ou{' '}
											<Text
												as={'span'}
												color={easyRED}
											>
												clique para selecionar
											</Text>
										</Text>
									</Flex>

									{showLineRestricts && (
										<Flex flexDirection={'column'}>
											<Text
												fontSize='12px'
												fontWeight='500'
												color='secondaryGray.500'
											>
												Quantidade de importações total:{' '}
												<Text
													as={'span'}
													color={easyBLUE}
												>
													{
														lineRestrictions?.quantidadeLinhasImportacaoArquivosTotal
													}
												</Text>
											</Text>
											<Text
												fontSize='12px'
												fontWeight='500'
												color='secondaryGray.500'
											>
												Quantidade de importações do
												dia:{' '}
												<Text
													as={'span'}
													color={easyBLUE}
												>
													{
														lineRestrictions?.quantidadeLinhasImportacaoArquivosPorDia
													}
												</Text>
											</Text>
										</Flex>
									)}
								</Box>
							}
						/>
					</ModalBody>
					<ModalFooter></ModalFooter>
				</ModalContent>
			</Modal>
			<>
				{uploadPercentage > 0 && (
					<LoadingUpload
						uploadPercentage={uploadPercentage}
						isOpen={loadingModal.isOpen}
						onClose={loadingModal.onClose}
						headerText={headerText}
						module={module}
					/>
				)}
				{!sucessUpload && isSuccess && (
					<FileUploadErrorsModal
						columnsData={columnsData}
						isOpen={IsOpenTableModal}
						onClose={onCloseTableModal}
						headerText={headerText}
						uploadData={uploadData}
						nameFile={nameFile}
					/>
				)}
			</>
		</>
	);
};
