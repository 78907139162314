import { useQuery } from '@tanstack/react-query';
import { getSumForSalesChannel } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useSumForSalesChannel = (
	cicloId: string,
	areaEmpresaId: string = '',
	familiaId: string,
	canalVendaId: string,
	zonaVendaId: string,
) => {
	return useQuery(
		[
			'sum-for-sales-channel',
			cicloId,
			areaEmpresaId,
			familiaId,
			canalVendaId,
		],
		getSumForSalesChannel,
		{
			refetchOnWindowFocus: false,
			enabled: !!zonaVendaId,
		},
	);
};
