import { useMutation } from '@tanstack/react-query';
import { getProductModelByFiltersAndLimit } from 'services/api/requests/productModel';
import { IAutocomplete } from 'utils/forms/autocomplete';

export const useProductModelListAsMutate = ({
	busca,
	limit,
	orderBy = 'nome_modelo',
	sort = 'ASC',
	familia_id,
	sub_familia_id,
	produtos_status,
}: IAutocomplete) => {
	return useMutation(() =>
		getProductModelByFiltersAndLimit(
			busca,
			limit,
			orderBy,
			sort,
			familia_id,
			sub_familia_id,
			produtos_status,
		),
	);
};
