import { columnsDataForcaVenda } from '../forcaVenda/variables/columnsDataForcaVenda';
import {
	forcaVendaDataDelete,
	forcaVendaDeleteMessage,
	forcaVendaDataDeleteAll,
} from '../forcaVenda/variables/deleteForcaVenda';
import { columnsDataMercado } from '../mercado/variables/columnsDataMercado';
import {
	mercadoDataDelete,
	mercadoDeleteMessage,
	mercadoDataDeleteAll,
} from '../mercado/variables/deleteMercado';
import { useGetSalesForceById } from 'services/queryClient/wrapperHooks/useGetSalesForceById';
import { useEditSalesForce } from 'services/queryClient/wrapperHooks/useEditSalesForce';
import { validateRequired } from 'utils/forms/validations';
import { useEditMarket } from 'services/queryClient/wrapperHooks/useEditMartket';
import { useGetMarketById } from 'services/queryClient/wrapperHooks/useGetMartketById';
import { defaultInputStyle } from 'utils/forms/defaultsProps';
import { columnsDataZonaVenda } from '../zonaVenda/variables/columnsDataZonaVenda';
import {
	zonaVendaDataDelete,
	zonaVendaDataDeleteAll,
	zonaVendaDeleteMessage,
} from '../zonaVenda/variables/deleteZonaVenda';
import { useEditSalesZone } from 'services/queryClient/wrapperHooks/useEditSalesZone';
import { useGetSalesZoneById } from 'services/queryClient/wrapperHooks/useGetSalesZoneById';
import { columnsDataCanalVenda } from '../canalVenda/variables/columnsDataCanalVenda';
import {
	canalVendaDataDelete,
	canalVendaDataDeleteAll,
	canalVendaDeleteMessage,
} from '../canalVenda/variables/deleteCanalVenda';
import { useGetSalesChannelById } from 'services/queryClient/wrapperHooks/useGetSalesChannelById';
import { useEditSalesChannel } from 'services/queryClient/wrapperHooks/useEditSalesChannel';

export const GetRequest = (module) => {
	switch (module) {
		case 'mercado':
			return useGetMarketById;
		case 'forcaVenda':
			return useGetSalesForceById;
		case 'zonaVenda':
			return useGetSalesZoneById;
		case 'canalVenda':
			return useGetSalesChannelById;
		default:
			throw new Error(
				`Não foi possível buscar ${module} pelo id, a query não foi implementada!`,
			);
	}
};

export const GetMutate = (module) => {
	switch (module) {
		case 'mercado':
			return useEditMarket;
		case 'forcaVenda':
			return useEditSalesForce;
		case 'zonaVenda':
			return useEditSalesZone;
		case 'canalVenda':
			return useEditSalesChannel;
		default:
			throw new Error('Mutate para atualização usando modal não existe!');
	}
};

export const buildCheckTableModalProps = (module, pathCompanyName?) => {
	function getProps() {
		switch (module) {
			case 'mercado':
				return {
					filterModule: 'mercado',
					columnsData: columnsDataMercado,
					modalTitle: 'Mercados',
					renderRow: (mercado) => mercado,
					simpleDelete: mercadoDataDelete,
					deleteMany: mercadoDeleteMessage,
					deleteAll: mercadoDataDeleteAll,
				};
			case 'forcaVenda':
				return {
					filterModule: 'forcaVenda',
					columnsData: columnsDataForcaVenda,
					modalTitle: 'Forças de Venda',
					renderRow: (forca) => forca,
					simpleDelete: forcaVendaDataDelete,
					deleteMany: forcaVendaDeleteMessage,
					deleteAll: forcaVendaDataDeleteAll,
				};
			case 'zonaVenda':
				return {
					filterModule: 'zonaVenda',
					columnsData: columnsDataZonaVenda,
					modalTitle: 'Zonas de Venda',
					renderRow: (zona) => ({
						id: zona.id,
						nome_zona_venda: zona.nome_zona_venda,
						canal_venda_id: zona.canal_venda_id,
						nome_canal_venda: zona.nome_canal_venda,
					}),
					simpleDelete: zonaVendaDataDelete,
					deleteMany: zonaVendaDeleteMessage,
					deleteAll: zonaVendaDataDeleteAll,
				};
			case 'canalVenda':
				return {
					filterModule: 'canalVenda',
					columnsData: columnsDataCanalVenda,
					modalTitle: 'Canais de Venda',
					renderRow: (canal) => ({
						id: canal.id,
						nome_canal_venda: canal.nome_canal_venda,
					}),
					simpleDelete: canalVendaDataDelete,
					deleteMany: canalVendaDeleteMessage,
					deleteAll: canalVendaDataDeleteAll,
				};
			default:
				throw new Error(
					'Modulo para renderizar a modal de listagem com filtros não implementado!',
				);
		}
	}

	return getProps();
};

export const buildCheckTableEditModalInitialState = (module, data) => {
	function getProps() {
		switch (module) {
			case 'mercado':
				return {
					nome_mercado: data?.nome_mercado || '',
				};
			case 'forcaVenda':
				return {
					nome_forca_venda: data?.nome_forca_venda || '',
				};
			case 'zonaVenda':
				return {
					nome_zona_venda: data?.nome_zona_venda || '',
				};
			case 'canalVenda':
				return {
					nome_canal_venda: data?.nome_canal_venda || '',
				};
			default:
				throw new Error(
					'Não foi possível criar o estado inicial do formulário!',
				);
		}
	}

	return getProps();
};

export const buildCheckTableEditModalFormInputFields = (module) => {
	function getProps() {
		switch (module) {
			case 'mercado':
				return [
					{
						type: 'text',
						name: 'nome_mercado',
						label: 'Mercado',
						validate: {
							...validateRequired(),
						},
						inputProps: {
							...defaultInputStyle,
							maxLength: 100,
						},
					},
				];
			case 'forcaVenda':
				return [
					{
						type: 'text',
						name: 'nome_forca_venda',
						label: 'Força de Venda',
						validate: {
							...validateRequired(),
						},
						inputProps: {
							...defaultInputStyle,
							maxLength: 100,
						},
					},
				];
			case 'zonaVenda':
				return [
					{
						type: 'text',
						name: 'nome_zona_venda',
						label: 'Zona de Venda',
						validate: {
							...validateRequired(),
						},
						inputProps: {
							...defaultInputStyle,
							maxLength: 100,
						},
					},
				];
			case 'canalVenda':
				return [
					{
						type: 'text',
						name: 'nome_canal_venda',
						label: 'Canal de Venda',
						validate: {
							...validateRequired(),
						},
						inputProps: {
							...defaultInputStyle,
							maxLength: 100,
						},
					},
				];
			default:
				throw new Error(
					'Não foi possível gerar os inputs para o formulário da modal!',
				);
		}
	}

	return getProps();
};

export const buildCheckTableEditModalHeaders = (module) => {
	function getProps() {
		switch (module) {
			case 'mercado':
				return 'Editar Mercado';
			case 'forcaVenda':
				return 'Editar Força de Venda';
			case 'zonaVenda':
				return 'Editar Zona de Venda';
			case 'canalVenda':
				return 'Editar Canal de Venda';
			default:
				throw new Error('Header para modal não fornecido!');
		}
	}

	return getProps();
};
