import { Route, Switch } from "react-router-dom";
// import routes from "../../routes";
import "mapbox-gl/dist/mapbox-gl.css";
import routesAdminEasy360 from "routes/routesAdminEasy360";
import Blank from "./types/Blank";
import CollaborationConsenting from "./types/CollaborationConsenting";
import Default from "./types/Default";


// Custom Chakra theme
export default function AdminDashboard(props) {
  const { ...rest } = props;
  // functions for changing the states from components

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (routes[i].name.includes("Editar") && window.location.href.includes(routes[i].layout + routes[i].editPath)) {
          return routes[i]
        } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i];
        }
      }
    }
    // console.log(activeRoute)
    return activeRoute;
  };
  const TemplatesPage = {
    default: Default,
    collaborationConsenting: CollaborationConsenting,
    blank: Blank
  }

  const getSwitchRoutes = (routes) => {
    const route = getActiveRoute(routes)
    const Template = TemplatesPage[route.templatePage || "default"]
    return Object.entries(TemplatesPage).map(([templateKey, ]) => {
      return (
        // <Switch>
          <Template
          route={route}
          >
            {getRoutes(routes, templateKey)}
          </Template>
        // </Switch>
      )
    })
  }

  const getRoutes = (routes, template) => {
    // const route = getActiveRoute(routes)
    
    return routes.map((prop, key) => {
      const templatePageKey = prop.templatePage || "default"
      const Template = TemplatesPage[templatePageKey]
      if (prop.layout === "/adminEasy360") {
        // console.log(template === templatePageKey, template, templatePageKey)
        return (
          <>
            {template === templatePageKey ? <Route
              path={prop.layout + prop.path}
              component={
                prop.component
              }
              key={key}
            />
              :
              <></>}
          </>
        )
      }
      if (prop.collapse) {
        return getRoutes(prop.items, template);
      }
      if (prop.category) {
        return getRoutes(prop.items, template);
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Switch>
        {getSwitchRoutes(routesAdminEasy360)}
        {/* <Redirect from='/admin' to='/admin/cadastros/produto/produtos' /> */}
      </Switch>
    </>
  );
}
