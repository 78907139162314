import { Tooltip } from '@chakra-ui/react';
import { IconHelp } from '@tabler/icons';

// Props do componente
// label: texto que aparece no tooltip
// customIcon: ícone customizado, caso não seja passado, o ícone padrão será o de ajuda
// rest: outras props que podem ser passadas para o tooltip, inclusive sobrescreve as props padrões

interface IconHelperTooltipProps {
	label: string;
	customIcon?: JSX.Element;
	[key: string]: any;
}

export const IconHelperTooltip = ({
	label,
	customIcon,
	...rest
}: IconHelperTooltipProps) => {
	return (
		<Tooltip
			label={label}
			placement='top'
			bg='gray.200'
			color='blue.800'
			hasArrow
			arrowSize={15}
			shouldWrapChildren
			{...rest}
		>
			{customIcon ? customIcon : <IconHelp size={16} />}
		</Tooltip>
	);
};
