import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarPrecoMedio from 'components/forms/FormCadastrarEditarPrecoMedio';

export default function CadastrarPrecoMedio() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastroPrecoMedio = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Preço Médio',
			firstParagraph: 'Para cadastrar um novo preço médio...',
			secondParagraph: '...',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroPrecoMedio);
	}, [helpCadastroPrecoMedio, setDataSidebar]);

	const formInitialState = {
		familia_id: null,
		produto_id: null,
		canal_venda_id: null,
		ticket_medio_editado: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarPrecoMedio
					initialState={formInitialState}
				/>
			</Card>
		</Flex>
	);
}
