import { useMutation } from '@tanstack/react-query';
import { IRequestSearchPagination } from 'pages/admin/demanda/templateCadastrarColabCons/components/MainFilter/InputSearch';
import { getFilterDemand } from 'services/api/requests/colabAndConsensus/colabAndConsensusFilters';

export interface UseGetSelectedFilterDemand {
	pageName: string;
	column_name: string;
	cycleId?: number | string;
	companyAreaId?: number | string;
	familyId?: number | string;
	salesChannelId?: number | string;
	salesZoneId?: number | string;
}
export const useGetFilterDemand = (
	query: UseGetSelectedFilterDemand & IRequestSearchPagination,
) => {
	return useMutation(() => getFilterDemand(query));
};
