import { Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { IconNewSection, IconTrash, IconPlus } from '@tabler/icons';
import StatisticTable, {
	generateRowsMonths,
	Row,
	RowInput,
} from 'components/dataTables/StatisticTable';
import { useEffect, useState } from 'react';

import { useContextColaboracaoConsenso } from '../context';
import { useSelectByStatistic } from '../hooks/useSelectByStatistic';
import { ProductException } from '../../consenso/context';
import { useAverageTicketByProduct } from 'services/queryClient/wrapperHooks/averageTicket/useAverageTicketByProduct';
import { currencyToNumber } from '../../../../../utils/functions/currencyToNumber';
import { defaultInputStyle } from 'utils/forms/defaultsProps';
import { renderProduct, IAutocomplete } from 'utils/forms/autocomplete';
import InputSearch from './MainFilter/InputSearch';
import { useProductListAsMutate } from 'services/queryClient/wrapperHooks/product/useProductListAsMutate';
import { useGlobalContext } from 'contexts/GlobalContext';
import { numberToLocaleString } from 'utils/functions/number';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';

export interface QuantityValues {
	comentario?: string;
	[key: string]: number | string | undefined | boolean | '';
}
export interface ColaboracoesData {
	colaboracao_produto: number;
	mes_ano: string;
	area_empresa_id: number;
	nome_area_empresa: string;
}
interface PropsSectionExceptions {
	exceptionId: string;
	onChangeProduct: (
		idException: number | string,
		p: ProductException,
	) => void;
	colaboracoes?: ColaboracoesData[];
	onDelete: (id: string) => void;
	exceptionIds: number[];
	exceptions: {
		[key: string]: QuantityValues;
	};
	setExceptions: React.Dispatch<
		React.SetStateAction<{
			[key: string]: QuantityValues;
		}>
	>;
	productExceptions: Record<string, ProductException>;
}
const columnFirtDataConsensoCadastrar = {
	Header: 'Prenchimento',
	accessor: 'preechimentoText',
	disableSortBy: true,
	type: 'text',
};
const columnsEndCadastrar = [
	{
		Header: 'MÉDIA',
		accessor: 'media',
		type: 'text',
	},
	{
		Header: 'TOTAL',
		accessor: 'total',
		type: 'text',
	},
];

export default function SectionExceptions({
	exceptionId,
	onChangeProduct,
	colaboracoes,
	onDelete,
	exceptionIds,
	exceptions,
	setExceptions,
	productExceptions,
}: PropsSectionExceptions) {
	const {
		familyId,
		cycle: cycleData,
		salesChannelId,
		companyAreaId,
		salesZoneId,
		keysPage,
	} = useContextColaboracaoConsenso();

	const { ciclo: cycle, duracao: cycleDuration } = cycleData;

	const [exceptionsRows, setExceptionsRows] = useState<Row[]>([]);

	const [ticketMedio, setTicketMedio] = useState<number>(0);
	const [previsaoReceita, setPrevisaoReceita] = useState<Row>({
		media: 0,
		total: 0,
		type: 'text',
	});

	useEffect(() => {
		const previsaoReceita: Row = {
			type: 'text',
			media: 0,
			total: 0,
			preechimentoText: 'Previsão de Receita',
		};
		for (const key in exceptions[exceptionId]) {
			const exception =
				typeof exceptions[exceptionId][key] === 'number'
					? exceptions[exceptionId][key]
					: String(exceptions[exceptionId][key])
							.replaceAll('.', '')
							.replace(',', '.');
			const value = exception ? +exception : 0;

			const previsao = value * ticketMedio;

			previsaoReceita[key] = Math.trunc(previsao)?.toLocaleString(
				'pt-BR',
				{
					style: 'currency',
					currency: 'BRL',
					maximumFractionDigits: 2,
				},
			);
		}
		setPrevisaoReceita(previsaoReceita);
	}, [exceptions[exceptionId], ticketMedio]);

	useEffect(() => {
		if (colaboracoes) {
			const controllArea = {};
			const newRowsExcecoes: Row[] = [];
			const totals: number[] = [];
			for (const colaboracao of colaboracoes) {
				const date = new Date(colaboracao.mes_ano);
				const mes =
					date.getMonth() + 2 === 13 ? 1 : date.getMonth() + 2;

				const indexRowExcecao =
					controllArea[colaboracao.area_empresa_id];

				if (indexRowExcecao === undefined) {
					controllArea[colaboracao.area_empresa_id] =
						newRowsExcecoes.length;

					newRowsExcecoes.push({
						media: 0,
						total: colaboracao.colaboracao_produto,
						type: 'text',
						preechimentoText: colaboracao.nome_area_empresa,
						[mes]: Number(
							colaboracao.colaboracao_produto,
						)?.toLocaleString('pt-BR'),
					});
					totals.push(+colaboracao.colaboracao_produto || 0);
				} else {
					newRowsExcecoes[indexRowExcecao][mes] = Number(
						colaboracao.colaboracao_produto,
					)?.toLocaleString('pt-BR');
					totals[indexRowExcecao] =
						totals[indexRowExcecao] +
							+colaboracao.colaboracao_produto || 0;
				}
				for (let i = 0; i < newRowsExcecoes.length; i++) {
					const rowException = newRowsExcecoes[i];
					const total = totals[i];
					rowException.media = numberToFormattedString(
						total / cycleDuration,
						precisao_decimal,
					);
					rowException.total = numberToFormattedString(
						total,
						precisao_decimal,
					);
				}
			}
			setExceptionsRows(newRowsExcecoes);
		}
	}, [colaboracoes, exceptions]);

	const exceptionProduct: ProductException = productExceptions[exceptionId];

	const { data: ticketMedioData } = useAverageTicketByProduct({
		productId: exceptionProduct?.id,
		salesChannelId,
	});

	useEffect(() => {
		if (ticketMedioData) {
			if (!ticketMedioData?.ticket_medio) {
				setTicketMedio(0);
			} else {
				setTicketMedio(
					(ticketMedioData && ticketMedioData.ticket_medio) || 0,
				);
			}
		}
	}, [ticketMedioData]);

	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const onChange = (key: string, value: string) => {
		let total = 0;

		const newExcecao = {
			...exceptions[exceptionId],
			[key]: value,
		};

		for (const key in newExcecao) {
			if (!isNaN(+key))
				total += currencyToNumber(String(newExcecao[key])) || 0;
		}

		newExcecao.total = numberToFormattedString(total, precisao_decimal);
		newExcecao.media = numberToFormattedString(
			total / cycleDuration || 0,
			precisao_decimal,
		);
		setExceptions({
			...exceptions,
			[exceptionId]: newExcecao,
		});
	};

	const errorConditional = (value: number | string) => {
		return value !== 0 && value !== '';
	};

	const deleteOrAddExcecaoHasConsenu = (action: 'delete' | 'add') => {
		const newExcecoes = { ...exceptions };

		const deleteValue = action === 'delete';
		newExcecoes[exceptionId].delete = deleteValue;

		if (deleteValue) {
			onDelete(exceptionId);
		}

		setExceptions(newExcecoes);
	};

	const { onChangeSelect, valueSelect, options } = useSelectByStatistic({
		salesChannelId,
		companyAreaId,
		cycleData,
		familyId,
		page: keysPage.page,
		salesZoneId,
		productId: exceptionProduct ? String(exceptionProduct?.id) : '',
		setValues: (values) =>
			setExceptions({ ...exceptions, [exceptionId]: values }),
		cycleId: String(cycleData.id),
		exception: true,
	});

	const excecao: RowInput = {
		type: 'input',
		...exceptions[exceptionId],
		preenchimento: {
			options,
			onChange,
			onChangeSelect,
			value: valueSelect,
		},
		errorConditional: errorConditional,
	} as RowInput;

	return (
		<StatisticTable
			icon={IconNewSection}
			sectionError={false}
			SectionButtons={
				<Flex
					w={'100%'}
					flexDirection='column'
				>
					<Text
						marginLeft={4}
						fontSize='sm'
						fontWeight='700'
					>
						{'Produto'}
					</Text>
					<Flex
						marginLeft={4}
						marginTop={2}
						gap={4}
						justifyContent={'start'}
						height={'40px'}
					>
						{exceptionsRows.length > 0 ? (
							<>
								<Tooltip
									label={renderProduct(exceptionProduct)}
									bg='gray.200'
									color='blue.800'
								>
									<Text
										left={'0'}
										backgroundColor={'white'}
										border={'1px'}
										paddingX={4}
										paddingY={2}
										overflow={'hidden'}
										borderRadius='8px'
										borderColor={'gray.200'}
										height={'40px'}
										width={'300px'}
										whiteSpace={'nowrap'}
									>
										{renderProduct(exceptionProduct)}
									</Text>
								</Tooltip>
								{/* Habilitação de inputs para usuário digitar valor de consenso excecao */}
								{excecao.delete ? (
									<Tooltip
										label={
											'Adicionar exceção para o produto.'
										}
										placement='top'
										bg='gray.200'
										color='blue.800'
										hasArrow
									>
										<Button
											onClick={() =>
												deleteOrAddExcecaoHasConsenu(
													'add',
												)
											}
										>
											<Icon
												color='secondaryGray.500'
												as={IconPlus}
												w='20px'
												h='20px'
											/>
										</Button>
									</Tooltip>
								) : (
									<Button
										onClick={() =>
											deleteOrAddExcecaoHasConsenu(
												'delete',
											)
										}
									>
										<Icon
											color='secondaryGray.500'
											as={IconTrash}
											w='20px'
											h='20px'
										/>
									</Button>
								)}
							</>
						) : (
							<>
								<Flex>
									<InputSearch<
										ProductException,
										IAutocomplete
									>
										inputProps={{
											dataTest:
												'input_search-produto-excecao',
											...defaultInputStyle,
											renderInputLabel: (value) =>
												value
													? value.codigo_produto +
													  ' - ' +
													  value.nome_produto
													: '',
											onChange: (
												_name: string,
												value: ProductException | null,
											) => {
												if (value) {
													onChangeProduct(
														exceptionId,
														value,
													);
												}
											},
											name: 'produto',
											value: exceptionProduct,
										}}
										useGetRequest={useProductListAsMutate}
										searchType='product'
										extraRequestData={{
											familyId,
											notProductIds: exceptionIds.filter(
												(id) =>
													+id !==
													+exceptionProduct?.id,
											),
											productId: exceptionProduct?.id,
										}}
									/>
								</Flex>

								<Button onClick={() => onDelete(exceptionId)}>
									<Icon
										color='secondaryGray.500'
										as={IconTrash}
										w='20px'
										h='20px'
									/>
								</Button>
							</>
						)}
					</Flex>
				</Flex>
			}
			name={'Exceção'}
			columnsData={{
				header: [columnFirtDataConsensoCadastrar],
				body: generateRowsMonths(Number(cycle), cycleDuration, false),
				footer: columnsEndCadastrar,
			}}
			tableData={[
				...exceptionsRows,
				...(excecao.delete && exceptionsRows.length
					? []
					: [excecao, previsaoReceita]),
			]}
			useInputMoney
		/>
	);
}
