import { getProductionTarget } from "services/api/requests/production-target"
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useGetProductionTarget = (age: number, month: number) => {
    const queryClient = useQueryClient();

    return useMutation(() => getProductionTarget(age, month), {
        onSuccess: (sucess) => {
            queryClient.invalidateQueries(["production-target-list"]);
        },
        onError: (error: any, variables, context) => {

        },
    })
}