import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataOrcamento = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Competência',
		accessor: 'competencia',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Familia',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Receita Bruta',
		accessor: 'receitaBruta',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data Registro',
		accessor: 'dataRegistro',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		filter: false,
		jsx: RowEditAndDelete,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'PRODUTO',
		accessor: 'produto',
	},
	{
		Header: 'FAMÍLIA',
		accessor: 'familia',
	},
	{
		Header: 'SUBFAMÍLIA',
		accessor: 'subfamilia',
	},
	{
		Header: 'MODELO',
		accessor: 'modelo',
	},
	{
		Header: 'COD.PRODUTO',
		accessor: 'codproduto',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];
