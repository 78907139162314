import { useMemo, useCallback, useState, useEffect } from 'react';

import {
	ButtonGroup,
	Select as ChakraSelect,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Text,
	useDisclosure,
	useToast,
	FormErrorMessage,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Tooltip,
	Image,
} from '@chakra-ui/react';
import { LoadingSpinner } from '../loading/Loading';
import {
	getProcesses,
	returnUploadedHeadersSheet,
	uploadProcessesSetings,
	getProcessesSetting,
	getBistoreDataTypes,
} from 'services/api/requests/injectBbe';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import headerImageExample from '../../assets/img/others/exemploCabeçalho.webp';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

import { ActivityModal } from 'pages/admin/cadastros/capacidade/roteiros/modal/ActivityModal.tsx';

import { InputFormLabel } from 'components/fields/InputFormLabel';
import { getStylesConfigSingle } from 'components/multiSelect/stylesConfig';

import { useForm, Controller } from 'react-hook-form';

import './FormBotSettings.css';
import { FileUploadWithoutTemplateModal } from 'components/modal/fileUpload/FileUploadWithoutTemplateModal';

import { useGlobalContext } from '../../contexts/GlobalContext';
import { any } from 'prop-types';
import { useUploadHeadersSheet } from 'services/queryClient/wrapperHooks/uploads/useUploadHeadersSheet';

const FormBotSettings = () => {
	const { userAttributes } = useGlobalContext();

	const { isOpen: isOpenActivityModal, onClose: onCloseActivityModal } =
		useDisclosure();

	const toast = useToast();

	const [availableTables, setAvailableTables] = useState();
	const [propsConfiguration, setPropsConfiguration] = useState();

	const [ProcessesConfigurationIdBbe, setProcessesConfigurationIdBbe] =
		useState();

	const [selectedTable, setSelectedTable] = useState();
	const [selectedUpdateColumn, setSelectedUpdateColumn] = useState();
	const [selectedTableColumn, setSelectedTableColumn] = useState();
	const [mostrarDataType, setMostrarDataType] = useState(false);
	const [selectedDataType, setSelectedDataType] = useState();
	const [selectedDataTypeValidation, setSelectedDataTypeValidation] =
		useState();
	const [dataTypes, setDataTypes] = useState();
	const [valueInputPathURL, setValueInputPathURL] = useState('');
	const [valueGoogleDriveFolderId, setValueGoogleDriveFolderId] =
		useState('');

	const [isAvailableButton, setIsAvailableButton] = useState(true);
	const [valueEasyCustomerIdToken, setValueEasyCustomerIdToken] =
		useState('');
	const [valueEasyCustomerRefreshToken, setValueEasyCustomerRefreshToken] =
		useState('');
	const [
		valueGoogleDriveResultsFolderId,
		setValueGoogleDriveResultsFolderId,
	] = useState('');

	const [columnsRelationsObject, setColumnsRelationsObject] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingTable, setIsLoadingTable] = useState(false);
	const [dataPropsTables, setDataPropsTables] = useState();
	const [dataSheetHeaders, setDataSheetHeaders] = useState();
	const [dataPropsConfigurations, setDataPropsConfigurations] = useState();
	const [dataValidationDataType, setDataValidationDataType] = useState();
	const [sucessImport, setSucessImport] = useState(false);
	const [arrayReturn, setArrayReturn] = useState([]);
	const [rowHeaderStart, setRowHeaderStart] = useState(1);
	const [dropdownAreDisabled, setDropdownAreDisabled] = useState(true);
	const [customerGoogleDriveLink, setCustomerGoogleDriveLink] = useState();
	const [importingButtonAreDisabled, setImportingButtonAreDisabled] =
		useState(true);
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	useEffect(() => {
		getProcesses().then((res) => {
			setAvailableTables((prevValue) => (prevValue = res));
			setIsLoading(false);

			getBistoreDataTypes().then((resDataTypes) => {
				setDataTypes((prevValue) => (prevValue = resDataTypes.data));
			});
		});
	}, []);

	const handleIncrementRowHeaderStart = () => {
		if (rowHeaderStart < 5) setRowHeaderStart((prevValue) => prevValue + 1);
	};
	const handleDecrementRowHeaderStart = () => {
		if (rowHeaderStart > 1) setRowHeaderStart((prevValue) => prevValue - 1);
	};

	const addRelation = async () => {
		if (!selectedTableColumn || !selectedUpdateColumn) {
			toast({
				title: 'Erro ao criar o vínculo!',
				description:
					'Por favor, selecione a coluna do vínculo que deseja relacionar.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
			return;
		}
		if (!selectedDataType) {
			toast({
				title: 'Erro ao criar o vínculo!',
				description:
					'Por favor, selecione a o tipo de dado que corresponde a essa coluna.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
			return;
		}
		const dataToUpdateInTable = propsConfiguration.map((data) => {
			if (data?.name === selectedTableColumn) {
				data.de = selectedUpdateColumn;
			}
			return (
				<Tr>
					{data?.de?.length > 20 ? (
						<Td className='tooltip'>
							{' '}
							<span className='tooltiptext'>{data.de}</span>
							{data?.de.substring(0, 20) + ' (...)'}
						</Td>
					) : (
						<Td>{data?.de}</Td>
					)}
					<Td
						className={
							!!data?.isRequired
								? 'tabela-table-obrigatoria'
								: null
						}
					>
						{data?.name + (!!data?.isRequired ? ' *' : '')}
					</Td>
				</Tr>
			);
		});

		let columnToUpdate = propsConfiguration.find(
			(data) => data.name === selectedTableColumn,
		);

		if (columnToUpdate) {
			let isPropertyInObject = false;
			columnsRelationsObject.map((obj) => {
				if (obj.hasOwnProperty(columnToUpdate.headerId)) {
					obj[columnToUpdate.headerId] = selectedUpdateColumn;
					obj['dataType'] = selectedDataType;
					obj['validationType'] =
						selectedDataTypeValidation !== ''
							? [selectedDataTypeValidation]
							: [];
					isPropertyInObject = true;
				}
			});

			if (!isPropertyInObject) {
				columnsRelationsObject[columnToUpdate.id][
					columnToUpdate.headerId
				] = selectedUpdateColumn;
				columnsRelationsObject[columnToUpdate.id]['dataType'] =
					selectedDataType;
				columnsRelationsObject[columnToUpdate.id]['validationType'] =
					selectedDataTypeValidation !== ''
						? [selectedDataTypeValidation]
						: [];
			}
			toast({
				title: 'Sucesso!',
				description: 'Vínculo criado com sucesso!',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		}

		const dataToUpdateInDropdown = propsConfiguration.map((data) => {
			if (data?.name === selectedTableColumn) {
				data.de = selectedUpdateColumn;
			}
			if (data.de) {
				return (
					<option
						value={data?.name}
						className={
							!!data?.isRequired ? 'tabela-obrigatoria' : null
						}
					>
						{`${data?.name} -> ${data?.de} `}
					</option>
				);
			}
			return (
				<option
					value={data?.name}
					className={!!data?.isRequired ? 'tabela-obrigatoria' : null}
				>
					{`${data?.name}`}
				</option>
			);
		});
		setDataPropsTables(dataToUpdateInDropdown);
		setDataPropsConfigurations(dataToUpdateInTable);

		var requiredFieldsAllowButton = [];
		propsConfiguration.forEach((config) => {
			const { headerId, isRequired } = config;

			if (isRequired) {
				const valorCampo = columnsRelationsObject.find((column) =>
					column.hasOwnProperty(headerId),
				);
				const valor = valorCampo ? valorCampo[headerId] : undefined;
				if (valor === '' || valor === undefined) {
					requiredFieldsAllowButton.push(headerId);
				}
			}
		});
		if (!!requiredFieldsAllowButton.length) {
			setIsAvailableButton(true);
		} else {
			setIsAvailableButton(false);
		}
	};

	useEffect(() => {
		if (selectedTable) {
			setIsLoadingTable(true);
			if (sucessImport) {
				setDropdownAreDisabled(false);
				setMostrarDataType(true);
			}
		} else {
			setCustomerGoogleDriveLink();
			setValueInputPathURL('');
			setImportingButtonAreDisabled(true);
			setDropdownAreDisabled(true);
			setMostrarDataType(false);
			setSelectedUpdateColumn('');
			setSelectedTableColumn('');
		}

		const selectedTableToFilterColumns = availableTables?.find(
			(table) => table.id === selectedTable,
		);

		let filteredProspsTables =
			selectedTableToFilterColumns?.settings?.settingsData
				?.spreadsheetColumnsTemplate;
		if (columnsRelationsObject?.length === 0) {
			let columnsObjectToPush = filteredProspsTables?.map((item) => ({
				[item.headerId]: '',
			}));

			if (typeof columnsObjectToPush !== 'undefined') {
				setColumnsRelationsObject(
					(prevValue) => (prevValue = columnsObjectToPush),
				);
			}
		}

		if (selectedTable) {
			getProcessesSetting(
				selectedTable,
				userAttributes?.['custom:CNPJ'],
			).then((res) => {
				if (res.data?.length > 0) {
					let returnedSettingObject = res.data[0];
					setProcessesConfigurationIdBbe(returnedSettingObject.id);
					setValueInputPathURL(
						returnedSettingObject.settings?.settings
							?.googleDrivePath,
					);
					setValueGoogleDriveFolderId(
						returnedSettingObject.settings?.settings
							?.googleDriveFolderId,
					);
					setCustomerGoogleDriveLink(
						returnedSettingObject.settings?.settings
							?.googleDriveFolderLink,
					);

					setValueEasyCustomerIdToken(
						returnedSettingObject.settings?.easyCustomerIdToken,
					);
					setValueEasyCustomerRefreshToken(
						returnedSettingObject.settings
							?.easyCustomerRefreshToken,
					);
					setValueGoogleDriveResultsFolderId(
						returnedSettingObject.settings?.settings
							?.googleDriveResultsFolderId,
					);

					let columnsRelation =
						returnedSettingObject.settings?.settings
							?.columnsRelations;
					if (columnsRelation?.length > 0) {
						let indexToDelete = null;
						const headerIds = filteredProspsTables.map(
							(item) => item.headerId,
						);
						columnsRelation.forEach((obj, index) => {
							let key = Object.keys(obj)[0];
							let isPropertyInHeaders = headerIds.find(
								(header) => header === key,
							);
							if (!isPropertyInHeaders) {
								indexToDelete = index;
							}
						});
						if (indexToDelete !== null)
							columnsRelation.splice(indexToDelete, 1);
						headerIds.forEach((header, index) => {
							let isHeaderInColumnRelation = false;
							columnsRelation.forEach(
								(columnRelationObj, index) => {
									let key = Object.keys(columnRelationObj)[0];
									if (key === header) {
										isHeaderInColumnRelation = true;
									}
								},
							);
							if (!isHeaderInColumnRelation) {
								let objectToPush = {
									[header]: '',
									dataType: '',
									validationType: [],
								};
								columnsRelation.push(objectToPush);
							}
						});
						setColumnsRelationsObject(
							(prevValue) => (prevValue = columnsRelation),
						);
						setIsAvailableButton(false);
					}
					setIsLoadingTable(false);

					columnsRelation?.forEach((obj) => {
						const key = Object.keys(obj)[0];
						const value = obj[key];

						const index = filteredProspsTables?.findIndex(
							(item) => item.headerId === key,
						);

						if (index !== -1) {
							filteredProspsTables[index].de = value;
						}
					});
				} else {
					setIsLoadingTable(false);
				}
				setImportingButtonAreDisabled(false);
				const filteredPropsFromTable = filteredProspsTables?.map(
					(data) => {
						if (data.de) {
							return (
								<option
									value={data?.name}
									className={
										!!data?.isRequired
											? 'tabela-obrigatoria'
											: null
									}
								>
									{`${data?.name} -> ${data?.de} `}
								</option>
							);
						}
						return (
							<option
								value={data?.name}
								className={
									!!data?.isRequired
										? 'tabela-obrigatoria'
										: null
								}
							>
								{`${data?.name}`}
							</option>
						);
					},
				);

				const columnsToPush = filteredProspsTables?.map((data) => {
					return (
						<Tr>
							{data?.de?.length > 20 ? (
								<Td className='tooltip'>
									{' '}
									<span className='tooltiptext'>
										{data.de}
									</span>
									{data?.de.substring(0, 20) + ' (...)'}
								</Td>
							) : (
								<Td>{data?.de}</Td>
							)}

							<Td
								className={
									!!data?.isRequired
										? 'tabela-table-obrigatoria'
										: null
								}
							>
								{data?.name + (!!data?.isRequired ? ' *' : '')}
							</Td>
						</Tr>
					);
				});

				setPropsConfiguration(filteredProspsTables);
				setDataPropsConfigurations(columnsToPush);
				setDataPropsTables(filteredPropsFromTable);
			});
		}
	}, [selectedTable]);

	useEffect(() => {
		if (typeof selectedTableColumn != 'undefined') {
			if (selectedTableColumn === '') {
				setMostrarDataType(false);
			} else {
				setMostrarDataType(true);
				let configurationToSetDataType = propsConfiguration.find(
					(conf) => conf.name === selectedTableColumn,
				);
				let selectedDataType = dataTypes.find(
					(dataType) =>
						dataType.name ===
						configurationToSetDataType?.dataTypeName,
				);
				setSelectedDataType(selectedDataType?.id);
			}
		} else {
			setMostrarDataType(false);
		}
	}, [selectedTableColumn]);

	const dataAvailableTables = useMemo(() =>
		availableTables?.map((data) => {
			return <option value={data?.id}>{`${data?.name}`}</option>;
		}),
	);

	const dataDataTypes = useMemo(() =>
		dataTypes?.map((data) => {
			return <option value={data?.id}>{`${data?.name}`}</option>;
		}),
	);

	useEffect(() => {
		const validationObjectToSearch = dataTypes?.find(
			(obj) => obj.id === selectedDataType,
		);
		const validationsByDataType =
			validationObjectToSearch?.validations?.map((data) => {
				return <option value={data?.id}>{`${data?.name}`}</option>;
			});
		setDataValidationDataType(validationsByDataType);
		setSelectedDataTypeValidation('');
	}, [selectedDataType]);

	useEffect(() => {
		if (sucessImport) {
			setArrayReturn(returnUploadedHeadersSheet());
			setIsAvailableButton(true);
			setDropdownAreDisabled(false);
			columnsRelationsObject.forEach((property) => {
				for (let key in property) {
					if (key !== 'validationType') {
						property[key] = '';
					}
				}
			});

			const dataToUpdateInTable = propsConfiguration?.map((data) => {
				delete data?.de;
				return (
					<Tr>
						{data?.de?.length > 20 ? (
							<Td className='tooltip'>
								{' '}
								<span className='tooltiptext'>{data.de}</span>
								{data?.de.substring(0, 20) + ' (...)'}
							</Td>
						) : (
							<Td>{data?.de}</Td>
						)}
						<Td
							className={
								!!data?.isRequired
									? 'tabela-table-obrigatoria'
									: null
							}
						>
							{data?.name + (!!data?.isRequired ? ' *' : '')}
						</Td>
					</Tr>
				);
			});
			const dataToUpdateInDropdown = propsConfiguration?.map((data) => {
				return (
					<option
						value={data?.name}
						className={
							!!data?.isRequired ? 'tabela-obrigatoria' : null
						}
					>
						{`${data?.name}`}
					</option>
				);
			});
			setDataPropsTables(dataToUpdateInDropdown);
			setDataPropsConfigurations(dataToUpdateInTable);
			setSucessImport(false);
		}
	}, [sucessImport]);

	useEffect(() => {
		if (arrayReturn.length > 0) {
			let optionsToAdd = arrayReturn.map((data) => {
				return <option value={data}>{`${data}`}</option>;
			});
			setDataSheetHeaders(optionsToAdd);
		}
	}, [arrayReturn]);

	const handleOnChangeSelectTable = useCallback(
		(e) => {
			setSelectedTable(e?.target?.value);
			if (e?.target?.value === '') {
				setDataPropsConfigurations();
				setDataPropsTables();
			}
		},
		[dataPropsTables],
	);

	const handleOnSelectUpdatedColumnScript = useCallback(
		(e) => {
			setSelectedUpdateColumn(e?.target?.value);
		},
		[reset],
	);

	const handleOnSelectTableColumnScript = useCallback(
		(e) => {
			setSelectedTableColumn(e?.target?.value);
		},
		[reset],
	);

	const handleOnSelectDataType = useCallback(
		(e) => {
			setSelectedDataType(e?.target?.value);
		},
		[reset],
	);

	const handleOnSelectDataTypeValidation = useCallback(
		(e) => {
			setSelectedDataTypeValidation(e?.target?.value);
		},
		[reset],
	);

	async function onSubmit(values) {
		let requiredFieldsEmptys = [];

		propsConfiguration.forEach((config) => {
			const { headerId, isRequired } = config;

			if (isRequired) {
				const valorCampo = columnsRelationsObject.find((column) =>
					column.hasOwnProperty(headerId),
				);
				const valor = valorCampo ? valorCampo[headerId] : undefined;
				if (valor === '' || valor === undefined) {
					requiredFieldsEmptys.push(headerId);
				}
			}
		});

		if (!!requiredFieldsEmptys?.length) {
			toast({
				title: 'Atenção!',
				description: 'Faltam campos obrigatórios!',
				position: 'bottom-left',
				status: 'warning',
				duration: 8000,
				isClosable: true,
			});
		} else {
			const body = {
				processesSettingProcessId: selectedTable,
				systemIsPost: false,
				settings: {
					easyCustomerId: userAttributes?.['custom:CNPJ'],
					easyCustomerFantasyName:
						userAttributes?.['custom:nomeFantasia'],
					easyCustomerEmail: userAttributes?.['email'],
					easyCustomerIdToken: valueEasyCustomerIdToken,
					easyCustomerRefreshToken: valueEasyCustomerRefreshToken,
					settings: {
						googleDrivePath: valueInputPathURL,
						googleDriveFolderId: valueGoogleDriveFolderId,
						googleDriveResultsFolderId:
							valueGoogleDriveResultsFolderId,
						columnsRelations: columnsRelationsObject,
						googleDriveFolderLink: customerGoogleDriveLink,
						headerLineNumber: rowHeaderStart,
					},
				},
			};
			if (ProcessesConfigurationIdBbe !== '') {
				Object.assign(body, {
					id: ProcessesConfigurationIdBbe,
				});
			}
			try {
				let succes = await uploadProcessesSetings(body);
				if (succes) {
					toast({
						title: 'Sucesso!',
						description: 'Configurações enviadas com sucesso.',
						position: 'bottom-left',
						status: 'success',
						duration: 8000,
						isClosable: true,
					});
				} else {
					toast({
						title: 'Ops! Houve algum erro',
						description: 'Não foi possivel enviar as configurações',
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				}
			} catch (error) {
				toast({
					title: 'Erro ao enviar as Configurações!',
					description: error,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		}
	}

	const {
		isOpen: isOpenImportModal,
		onOpen: onOpenImportModal,
		onClose: onCloseImportModal,
	} = useDisclosure();

	return (
		<Card className='form-cadastro'>
			<form onSubmit={handleSubmit(onSubmit)}>
				{!isLoading && (
					<Flex
						flexDirection={'row'}
						gap={4}
					>
						<Flex
							flexDirection={'column'}
							w={'50%'}
							mb='20px'
						>
							<FormControl
								mb='15px'
								isInvalid={errors.id_process_settings}
							>
								<Flex>
									<InputFormLabel
										htmlFor='id_process_settings'
										label='Processo de Automação'
										labelTooltip='Lista de possiveis processos para automatizar o processo de carga.'
										placement={'right'}
									/>
									<Text
										color='red.400'
										fontSize='sm'
										fontWeight='bold'
										ml={'-2'}
									>
										*
									</Text>
								</Flex>

								<Controller
									key='id'
									name='id'
									control={control}
									rules={{
										required: {
											value: true,
											message: 'Campo obrigatório',
										},
									}}
									render={({ field }) => (
										<ChakraSelect
											data-test='select-processo_de_automacao'
											placeholder='Selecione'
											styles={getStylesConfigSingle(
												'medium',
											)}
											onChange={(e) => {
												handleOnChangeSelectTable(e);
												field.onChange(e);
											}}
											value={selectedTable}
										>
											{dataAvailableTables}
										</ChakraSelect>
									)}
								/>
								<FormErrorMessage>
									{errors.id_process_settings &&
										errors.id_process_settings.message}
								</FormErrorMessage>
								<FormLabel
									mb='0'
									mt='10px'
									display='flex'
									alignItems='center'
								>
									<span>
										Caminho do Arquivo de Importação no{' '}
										<Text
											color='#42adef'
											fontWeight='bold'
											display='inline'
										>
											<a
												href={customerGoogleDriveLink}
												target='_blank'
												rel='noopener noreferrer'
												onClick={(e) => {
													if (
														!customerGoogleDriveLink
													) {
														e.preventDefault();
													}
												}}
											>
												Google Drive{' '}
											</a>
										</Text>
									</span>
									<InputFormLabel
										ml='5px'
										htmlFor='id_process_settings'
										label=' '
										labelTooltip='Somente armazene arquivos na pasta após finalizar
										configuração do robô. Nesta pasta deverão ser armazenados os arquivos desejados de importação.'
										placement={'right'}
									/>
								</FormLabel>
								<Input
									name='importingRoute'
									placeholder='URL do GoogleDrive'
									borderRadius={'16px'}
									color={'easyBLUE.300'}
									mb={'8px'}
									value={
										'Compartilhados Comigo\\' +
										valueInputPathURL
									}
									readOnly={true}
								/>
							</FormControl>

							<Flex
								gap={4}
								justifyContent={'left'}
								mb={2}
							>
								<Flex
									justifyContent={'left'}
									mb={5}
								>
									<InputFormLabel
										mt={2}
										htmlFor='id_process_settings'
										label='Número da Linha do Cabeçalho da Planilha Modelo'
										placement={'left'}
									/>
									<Text
										color='red.400'
										fontSize='sm'
										fontWeight='bold'
										pr='10px'
										ml='-8px'
										mt='5px'
									>
										*
									</Text>
									<NumberInput
										isDisabled={importingButtonAreDisabled}
										defaultValue={rowHeaderStart}
										min={1}
										max={5}
										value={rowHeaderStart}
										width='70px'
										borderRadius='0'
										onKeyDown={(event) => {
											if (event.key === 'ArrowUp') {
												handleIncrementRowHeaderStart();
											}
											if (event.key === 'ArrowDown') {
												handleDecrementRowHeaderStart();
											}
										}}
									>
										<NumberInputField
											readOnly={true}
											className='centered-text-number-input'
										/>
										<NumberInputStepper>
											<NumberIncrementStepper
												onClick={
													handleIncrementRowHeaderStart
												}
											/>
											<NumberDecrementStepper
												onClick={
													handleDecrementRowHeaderStart
												}
											/>
										</NumberInputStepper>
									</NumberInput>
								</Flex>
							</Flex>
							<Flex
								gap={4}
								justifyContent={'left'}
								mb={5}
								mt={-8}
							>
								<Text
									color='#B01A2E'
									fontSize='sm'
									display='inline-flex'
									alignItems='center'
								>
									Obrigatório mínimo de 1 cabeçalho!
									<Tooltip
										label={
											<Image
												src={headerImageExample}
												alt='Exemplo de cabeçalho'
											/>
										}
										maxW='1000px'
										maxH='1000px'
									>
										<QuestionOutlineIcon
											ml='10px'
											boxSize={3.5}
											color='gray.400'
											sx={{ fontWeight: 'bold' }}
										/>
									</Tooltip>
								</Text>
							</Flex>
							<Flex
								justifyContent={'left'}
								mb={2}
							>
								<InputFormLabel
									mt={2}
									htmlFor='id_process_settings'
									label='Planilha Modelo (Tamanho Máx 1MB)'
									placement={'left'}
								/>
								<Text
									color='red.400'
									fontSize='sm'
									fontWeight='bold'
									pr='10px'
									ml='-8px'
									mt='5px'
								>
									*
								</Text>
								<ButtonGroup
									spacing={5}
									mb='10px'
								>
									<ButtonComponent
										isDisabled={importingButtonAreDisabled}
										data-test='button-importar-robot'
										type={'ghost'}
										title={'Importar Planilha Modelo'}
										onClick={onOpenImportModal}
									/>

									<FileUploadWithoutTemplateModal
										isOpen={isOpenImportModal}
										onClose={onCloseImportModal}
										headerText={'Importar Planilha'}
										module={'HeadersSheet'}
										stringInvalidQuery='headers-sheet-reader'
										useUpload={useUploadHeadersSheet}
										columnsData={any}
										setSucessImport={setSucessImport}
										rowHeaderStart={rowHeaderStart}
										hasBaseData={false}
									/>
								</ButtonGroup>
							</Flex>
							<Flex>
								<InputFormLabel
									htmlFor='id_process_settings'
									label='Colunas do Processo de Importação'
									labelTooltip='Lista de colunas do processo de importação.'
									placement={'right'}
								/>
								<Text
									color='red.400'
									fontSize='sm'
									fontWeight='bold'
									ml={'-2'}
								>
									*
								</Text>
							</Flex>
							<ChakraSelect
								data-test='select-colunas_do_processo_de_importacao'
								placeholder='Selecione'
								h='44px'
								borderRadius={10}
								onChange={handleOnSelectTableColumnScript}
								value={selectedTableColumn}
								isDisabled={dropdownAreDisabled}
							>
								{dataPropsTables}
							</ChakraSelect>

							<FormControl
								mr='5px'
								mb='20px'
							>
								<FormLabel
									mb='0'
									mt='5px'
								>
									Colunas Identificadas na Planilha Modelo
									Importada
								</FormLabel>
								<ChakraSelect
									data-test='select-colunas_identificadas_na_planilha_importada'
									placeholder='Selecione'
									h='44px'
									borderRadius={10}
									onChange={handleOnSelectUpdatedColumnScript}
									value={selectedUpdateColumn}
									isDisabled={dropdownAreDisabled}
								>
									{dataSheetHeaders}
								</ChakraSelect>

								{mostrarDataType && (
									<Flex
										flexDirection={'column'}
										w={'50%'}
										marginTop={'10px'}
									>
										<Flex>
											<InputFormLabel
												htmlFor='id_process_settings'
												label='Tipo de Dado'
												labelTooltip='Tipos de dados disponíveis.'
												placement={'right'}
											/>
											<Text
												color='red.500'
												fontSize='sm'
												fontWeight='bold'
												ml={'-2'}
											>
												*
											</Text>
										</Flex>
										<ChakraSelect
											data-test='input-colunas_do_processo_de_importacao'
											placeholder='Selecione'
											h='44px'
											borderRadius={10}
											onChange={handleOnSelectDataType}
											value={selectedDataType}
										>
											{dataDataTypes}
										</ChakraSelect>
									</Flex>
								)}

								{mostrarDataType && (
									<Flex
										flexDirection={'column'}
										w={'50%'}
										mb='20px'
									>
										<FormLabel
											mb='0'
											mt='10px'
										>
											Validação
										</FormLabel>
										<ChakraSelect
											placeholder='Selecione'
											h='44px'
											borderRadius={10}
											onChange={
												handleOnSelectDataTypeValidation
											}
											value={selectedDataTypeValidation}
										>
											{dataValidationDataType}
										</ChakraSelect>
									</Flex>
								)}
							</FormControl>
						</Flex>
						<Flex
							w={'50%'}
							maxH='500px'
							overflowY='auto'
						>
							{!isLoadingTable && (
								<FormControl
									mr='5px'
									mb='20px'
									overflowX='hidden'
								>
									<FormLabel
										mb='0'
										htmlFor='linkTable'
									>
										Relações
										<h1 className='tabela-table-obrigatoria-table'>
											{' '}
											* Campos obrigatórios
										</h1>
									</FormLabel>
									<TableContainer>
										<Table variant='simple'>
											<Thead>
												<Tr>
													<Th>De</Th>
													<Th>Para</Th>
												</Tr>
											</Thead>
											<Tbody>
												{dataPropsConfigurations}
											</Tbody>
										</Table>
									</TableContainer>
								</FormControl>
							)}
							{isLoadingTable && <LoadingSpinner mt='2vh' />}
						</Flex>
					</Flex>
				)}
				{isLoading && <LoadingSpinner mt='2vh' />}
				<Flex
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'space-between'}
				>
					<ButtonComponent
						data-test='button-vincular-colunas'
						type={'ghost'}
						title={'Vincular Colunas'}
						isDisabled={importingButtonAreDisabled}
						onClick={addRelation}
					/>
					{!isLoading && (
						<ButtonComponent
							data-test='button-salvar-page-robot'
							type={'primary'}
							action='submit'
							title={'Salvar'}
							isDisabled={isAvailableButton}
						/>
					)}
				</Flex>
			</form>
			<ActivityModal
				isOpenActivity={isOpenActivityModal}
				onCloseActivity={onCloseActivityModal}
			/>
		</Card>
	);
};

export default FormBotSettings;
