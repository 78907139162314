import { RegisterTime, SelectorsData } from 'models/time-form.model';
import api from '../../api';

export const getSelectorsData = async ({
	queryKey,
}): Promise<SelectorsData> => {
	const [, productId, activityId, recursoId, data] = queryKey;
	const query = [] as string[];

	if (productId) query.push(`produto_id=${productId}`);
	if (activityId) query.push(`atividade_roteiro_id=${activityId}`);
	if (recursoId) query.push(`recurso_id=${recursoId}`);
	if (data) query.push(`data=${data}`);

	const response = await api.get(
		`/horaHora/dadosLancamento?${query.join('&')}`,
	);

	const dataAtual = new Date();
	const anoAtual = dataAtual.getFullYear();

	if (!response.data.anos.length) {
		response.data.anos.push({ ano: anoAtual });
	}

	return response.data;
};

export const registerTime = async (body: RegisterTime) => {
	const response = await api.post('/horaHora', body);
	return response.data;
};
