import { useQuery } from '@tanstack/react-query';
import {
	Filter,
	KeyFilter,
} from 'pages/admin/demanda/templateCadastrarColabCons/context';
import { getSelectedFilters } from 'services/api/requests/colabAndConsensus/colabAndConsensusFilters';

interface UseGetSelectedFilters {
	pageName: string;
	cycleId?: number | string;
	companyAreaId?: number | string;
	familyId?: number | string;
	salesChannelId?: number | string;
	salesZoneId?: number | string;
}
const keys = [
	'pageName',
	'cycleId',
	'companyAreaId',
	'familyId',
	'salesChannelId',
	'salesZoneId',
];
export const getKeysValuesSelectedFilters = (
	query: UseGetSelectedFilters,
	filterEmpty = true,
) => {
	const keysQ = [
		`selected-filters`,
		...keys.map((key) => (query[key] ? String(query[key]) : '')),
	];
	if (filterEmpty) {
		return keysQ.filter((key) => key !== '');
	}
	return keysQ;
};

export const useGetSelectedFilters = (
	query: UseGetSelectedFilters,
	onSuccess: ((data: Partial<Record<KeyFilter, Filter>>) => void) | undefined,
) => {
	return useQuery(
		getKeysValuesSelectedFilters(query, false),
		getSelectedFilters,
		{
			refetchOnWindowFocus: false,
			onSuccess,
		},
	);
};
