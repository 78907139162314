import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { exportRestrictedPlan } from 'services/api/requests/restrictedPlan';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useExportRestrictedPlan = (cicloId: string) => {
	const toast = useToast();
	return useQuery(['export-restrictedPlan', cicloId], exportRestrictedPlan, {
		refetchOnWindowFocus: false,
		retry: 1,
		enabled: false,
		onSuccess: () => {
			toast({
				title: 'Sucesso!',
				description: 'Exportação do arquivo realizada com sucesso!',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao exportar o plano restrito!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
