export const canalVendaDataDelete = {
	provider: 'salesChannel',
	toastSuccessTitle: 'Excluído com sucesso!',
	toastSuccessDescription: 'O canal de venda foi excluído com sucesso.',
	toastErrorTitle: 'Erro ao excluir canal de venda!',
	title: 'Deseja realmente excluir este canal de venda?',
	text: [
		'Você está prestes a excluir este canal de venda da sua base de cadastros. A exclusão não será executada caso o canal de venda esteja vinculado à:',
		'Rateio, Consenso, Colaborações, Plano irrestrito, Oportunidades da Exceção da Demanda',
		'Excluir este canal de venda resultará na remoção dos seguintes itens vinculados a ele:',
		'REPLACE_ME',
		'Você tem certeza que deseja excluir?',
	],
	invalidate: 'canalVenda-list',
};

export const canalVendaDeleteMessage = [
	'Você está prestes a excluir este(s) canal(is) de venda(s) da sua base de cadastros. A exclusão não será executada caso o canal(is) de venda(s) esteja vinculado à:',
	'Rateio, Consenso, Colaborações, Plano irrestrito, Oportunidades da Exceção da Demanda',
	'Excluir estes canal(is) de venda(s) resultará na remoção dos seguintes itens vinculados a ele(s):',
	'REPLACE_ME',
];

export const canalVendaDataDeleteAll = {
	provider: 'salesChannel',
	toastSuccessTitle: 'Registros excluídos com sucesso!',
	toastSuccessDescription: 'Os canais de vendas foram excluídas com sucesso',
	toastErrorTitle: 'Erro ao excluir canais de vendas!',
	invalidate: 'canalVenda-list',
};
