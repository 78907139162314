import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo } from 'react';
import FormCadastrarEditarEstoqueAtual from 'components/forms/FormCadastrarEditarEstoqueAtual';
import moment from 'moment';

export default function CadastrarEstoqueAtual() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpCadastrarEstoque: SidebarHelp = useMemo(
		() => ({
			title: 'Cadastrar Estoque Atual',
			firstParagraph: `O estoque atual aponta a quantidade de produtos em estoque para cada família em uma data específica. É importante atualizar o estoque atual diariamente para mantê-lo sempre atualizado.`,
			secondParagraph: `Para cadastrar um novo estoque, selecione a Data desejada, o Produto e entre com a quantidade em Estoque do produto selecionado. Clique em Salvar. Observação: é importante que todos os campos estejam preenchidos.`,
			// thirdParagraph: ``,
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarEstoque);
	}, [helpCadastrarEstoque, setDataSidebar]);

	// TO-DO: ajustar as propriedades
	const initialState = {
		data: moment().startOf('day').format('YYYY-MM-DD'),
		familia_id: null,
		produto_id: null,
		quantidade: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarEstoqueAtual initialState={initialState} />
		</Flex>
	);
}
