// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';

import { CircularProgress } from '@chakra-ui/react';

import { columnsDataMembros } from './variables/columnsDataMembros';

// import CheckTable from "components/dataTables/CheckTable";
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface Member {
	id: number;
	nome_membro: string;
	funcao: string;
	email: string;
	ativo: boolean | string;
	nome_area_empresa: string;
	area_empresa_id: number;
}

interface RowData {
	id: Array<number | boolean>;
	nomeMembro: string;
	funcao: string;
	email: string;
	areaEmpresa: string;
	ativo: string;
}

export default function CadastroMembros() {
	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;
	const helpMembers: SidebarHelp = useMemo(
		() => ({
			title: 'Membros',
			firstParagraph:
				'Nesta tela é possível visualizar todos os Membros da sua organização comercial, os quais são responsáveis pelas vendas nos mercados de atuação do seu negócio.',
			secondParagraph:
				'O membro se refere ao responsável pela venda. Acesse o botão “Cadastrar Membros” para adicionar um novo membro.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpMembers);
	}, [helpMembers, setDataSidebar]);

	const goToCadastroMembros = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/membros/criar`);
	}, [history, pathCompanyName]);

	const dataDelete = useMemo(
		() => ({
			provider: 'member',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription: 'O membro foi excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir membro!',
			title: 'Deseja realmente excluir este membro?',
			text: [
				'Você está prestes a excluir este membro da sua base de cadastros. Excluir este membro resultará na remoção dos seguintes itens vinculados a ele:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'membro-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes membros da sua base de cadastros. Excluir estes membros resultará na remoção dos seguintes itens vinculados a ele:',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'member',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'Os membros foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir membros!',
			invalidate: 'membro-list',
		}),
		[],
	);

	const renderRow = useCallback((member: Member): RowData => {
		const ativo = member.ativo ? 'Ativo' : 'Inativo';
		const id = [member.id, !member.ativo];

		return {
			id,
			nomeMembro: member.nome_membro,
			funcao: member.funcao,
			email: member.email,
			areaEmpresa: member.nome_area_empresa,
			ativo,
		};
	}, []);

	const renderButtons = useCallback((): JSX.Element => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonComponent
					data-test='button-cadastrar-membros'
					type={'primary'}
					title={'Cadastrar Membros'}
					onClick={goToCadastroMembros}
				/>
			</Flex>
		);
	}, [goToCadastroMembros]);

	const renderTable = (): JSX.Element => {
		return (
			<CheckTable
				dataTest='button-filtrar-membros'
				module='membro'
				linkEditForm={`/${pathCompanyName}/cadastros/membro/editar`}
				columnsData={columnsDataMembros}
				dataDelete={dataDelete}
				showGenericSearchInput={false}
				deleteMessage={deleteMessage}
				bulkDeleteProps={dataDeleteAll}
				setHasBaseData={setHasBaseData}
				renderRow={renderRow}
				filterModule='membro'
				tableTest='tabela-membros'
			/>
		);
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
