import { useState, useEffect, useCallback, useRef } from 'react';
import { Row, RowInput } from 'components/dataTables/Table';
import { RowHover, RowNew } from 'components/dataTables/StatisticTable';
import {
	tranformInputDashboardHover,
	numberAsCurrencyOrPercentage,
	buildCellHover,
} from 'utils/functions/salesDashboard';
import {
	columnFirstDataDashVendaFamilia,
	columnsBodyDashVendaFamilia,
	columnsEndDashVendaFamilia,
} from '../variables/columnsData';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useGetSalesDashboardClients } from 'services/queryClient/wrapperHooks/dashboardVendas/useGetSalesDashboard';
import { SalesDataTableHoverClients } from './SalesDashboardTable';
import TableFixedHeader from 'components/dataTables/TableFixedHeader';

export type DashboardVendasDadoByFamilia = {
	id?: number;
	nome_familia?: string;
	faturadoMesAtual: number;
	faturadoMesAnterior: number;
	faturadoAnoAnterior: number;
	carteiraBoa: number;
	planoRestrito?: number;
	meta: number;
	faturadoMaisCarteiraBoa: number;
	faturadoPorHistoricoPercentual: number;
	faturadoPorMetaPercentual: number;
	faturadoPorRestritoPercentual: number;
	faturadoMaisBoaPorHistoricoPercentual: number;
	faturadoMaisBoaPorMetaPercentual: number;
	faturadoMaisBoaPorRestritoPercentual: number;
};

export type SalesFamilyDataTable = {
	nomeFamilia: string;
	historico: string | SalesDataTableHoverClients;
	meta: string;
	planoRestrito: string;
	faturado: string | SalesDataTableHoverClients;
	mesAnterior: string | SalesDataTableHoverClients;
	boa: string | SalesDataTableHoverClients;
	fatBoa: string;
	fatHistorico: string;
	fatMeta: string;
	fatRestrito: string;
	fatBoaHistorico: string;
	fatBoaMeta: string;
	fatBoaRestrito: string;
	type: 'text-hover';
};

const SalesByFamilyDashboardTable = ({
	dataSalesDashboard,
	type,
	rowsKey,
	totalKey,
	date,
}) => {
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const [dataTable, setDataTable] = useState<
		(Row | RowInput | RowHover | RowNew)[]
	>([]);

	const [searchClients, setSearchClients] = useState<any>({
		column: undefined,
		quantity_clients: 5,
		sales_channel_id: undefined,
		family_id: undefined,
	});

	const [allClients, setAllClients] = useState<any>({});

	const timer = useRef<any>(null);

	const { data: clientsData, isLoading: isLoadingClients } =
		useGetSalesDashboardClients(
			date.toISOString().slice(0, 8) + '01',
			date.toISOString().slice(0, 10),
			searchClients.column,
			searchClients.quantity_clients,
			searchClients.sales_channel_id,
			searchClients.family_id,
		);

	const updateState = useCallback((column, requestParamName, entityId) => {
		clearTimeout(timer?.current);

		timer.current = setTimeout(() => {
			setSearchClients((prevState) => ({
				...prevState,
				column,
				[requestParamName]: entityId,
			}));
		}, 500);
	}, []);

	const formatData = useCallback(
		(
			data: DashboardVendasDadoByFamilia,
			isTotal = false,
		): SalesFamilyDataTable => {
			const {
				id,
				nome_familia = '',
				faturadoAnoAnterior = 0,
				meta = 0,
				planoRestrito = 0,
				faturadoMesAtual = 0,
				carteiraBoa = 0,
				faturadoMaisCarteiraBoa = 0,
				faturadoPorHistoricoPercentual = 0,
				faturadoPorMetaPercentual = 0,
				faturadoPorRestritoPercentual = 0,
				faturadoMaisBoaPorHistoricoPercentual = 0,
				faturadoMaisBoaPorMetaPercentual = 0,
				faturadoMaisBoaPorRestritoPercentual = 0,
				faturadoMesAnterior = 0,
			} = data;

			const previousYear = new Date(
				date.getFullYear() - 1,
				date.getMonth(),
			);
			const previousMonth = new Date(
				date.getFullYear(),
				date.getMonth() - 1,
			);

			const decimalPrecision =
				type === 'quantidade' && precisao_decimal > 0
					? precisao_decimal
					: 0;

			const displayedHistorico = isTotal
				? numberAsCurrencyOrPercentage(
						faturadoAnoAnterior,
						type === 'receita_bruta' ? 'money' : 'quantity',
						decimalPrecision,
				  )
				: type === 'receita_bruta'
				? buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						faturadoAnoAnterior,
						previousYear,
						'money',
						'historico',
						id,
						'family_id',
						decimalPrecision,
				  )
				: tranformInputDashboardHover(
						faturadoAnoAnterior,
						previousYear,
						'quantity',
						decimalPrecision,
				  );

			const displayedFaturado = isTotal
				? numberAsCurrencyOrPercentage(
						faturadoMesAtual,
						type === 'receita_bruta' ? 'money' : 'quantity',
						decimalPrecision,
				  )
				: type === 'receita_bruta'
				? buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						faturadoMesAtual,
						date,
						'money',
						'faturado',
						id,
						'family_id',
						decimalPrecision,
				  )
				: tranformInputDashboardHover(
						faturadoMesAtual,
						date,
						'quantity',
						decimalPrecision,
				  );

			const displayedBoa = isTotal
				? numberAsCurrencyOrPercentage(
						carteiraBoa,
						type === 'receita_bruta' ? 'money' : 'quantity',
						decimalPrecision,
				  )
				: type === 'receita_bruta'
				? buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						carteiraBoa,
						date,
						'money',
						'carteira_boa',
						id,
						'family_id',
						decimalPrecision,
				  )
				: tranformInputDashboardHover(
						carteiraBoa,
						date,
						'quantity',
						decimalPrecision,
				  );

			const displayedMesAnterior = isTotal
				? numberAsCurrencyOrPercentage(
						faturadoMesAnterior,
						type === 'receita_bruta' ? 'money' : 'quantity',
						decimalPrecision,
				  )
				: type === 'receita_bruta'
				? buildCellHover(
						updateState,
						allClients,
						isLoadingClients,
						faturadoMesAnterior,
						previousMonth,
						'money',
						'mes_anterior',
						id,
						'family_id',
						decimalPrecision,
				  )
				: tranformInputDashboardHover(
						faturadoMesAnterior,
						date,
						'quantity',
						decimalPrecision,
				  );

			return {
				nomeFamilia: isTotal ? 'Total' : nome_familia,
				historico: displayedHistorico,
				meta: numberAsCurrencyOrPercentage(
					meta,
					type === 'receita_bruta' ? 'money' : 'quantity',
					decimalPrecision,
				),
				planoRestrito: numberAsCurrencyOrPercentage(
					planoRestrito,
					type === 'receita_bruta' ? 'money' : 'quantity',
					decimalPrecision,
				),
				mesAnterior: displayedMesAnterior,
				faturado: displayedFaturado,
				boa: displayedBoa,
				fatBoa: numberAsCurrencyOrPercentage(
					faturadoMaisCarteiraBoa,
					type === 'receita_bruta' ? 'money' : 'quantity',
					decimalPrecision,
				),
				fatHistorico: numberAsCurrencyOrPercentage(
					faturadoPorHistoricoPercentual,
					'percent',
				),
				fatMeta: numberAsCurrencyOrPercentage(
					faturadoPorMetaPercentual,
					'percent',
				),
				fatRestrito: numberAsCurrencyOrPercentage(
					faturadoPorRestritoPercentual,
					'percent',
				),
				fatBoaHistorico: numberAsCurrencyOrPercentage(
					faturadoMaisBoaPorHistoricoPercentual,
					'percent',
				),
				fatBoaMeta: numberAsCurrencyOrPercentage(
					faturadoMaisBoaPorMetaPercentual,
					'percent',
				),
				fatBoaRestrito: numberAsCurrencyOrPercentage(
					faturadoMaisBoaPorRestritoPercentual,
					'percent',
				),
				type: 'text-hover',
			};
		},
		[
			allClients,
			date,
			isLoadingClients,
			precisao_decimal,
			type,
			updateState,
		],
	);

	useEffect(() => {
		if (!!dataSalesDashboard) {
			const linhas = dataSalesDashboard[rowsKey]?.map((data) =>
				formatData(data),
			);
			const total = formatData(dataSalesDashboard[totalKey], true);

			setDataTable([...linhas, total]);
		}
	}, [dataSalesDashboard, rowsKey, totalKey, formatData]);

	useEffect(() => {
		if (!!clientsData?.length) {
			const clients = clientsData?.map((client: any) => {
				const { razao_social, receita_bruta } = client;

				return {
					nome: razao_social,
					quantidade: numberAsCurrencyOrPercentage(
						receita_bruta,
						'money',
					),
				};
			});

			setAllClients((prevState: any) => ({
				...prevState,
				[searchClients.column]: {
					...prevState[searchClients.column],
					[searchClients.family_id]: clients,
				},
			}));
		} else {
			setAllClients((prevState: any) => ({
				...prevState,
				[searchClients.column]: {
					...prevState[searchClients.column],
					[searchClients.family_id]: [],
				},
			}));
		}
	}, [clientsData, searchClients.family_id, searchClients.column]);

	return (
		<>
			<TableFixedHeader
				columns={[
					...columnFirstDataDashVendaFamilia,
					...columnsBodyDashVendaFamilia,
					...columnsEndDashVendaFamilia,
				]}
				data={dataTable as any[]}
				dataTest={
					type === 'receita_bruta'
						? 'table-dashboard_de_vendas-familia-receita'
						: 'table-dashboard_de_vendas-familia-quantidade'
				}
				hasTotalRows={true}
			/>
		</>
	);
};

export default SalesByFamilyDashboardTable;
