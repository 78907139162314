import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { getStylesConfigSingle } from 'components/multiSelect/stylesConfig';
import Select, { SingleValue } from 'react-select';
import Capitalize from 'utils/strings';
import { ValuesFilter, Filters, FilterOption } from '../../context';

type PropsFilterInputOption = {
	valuesFilter: ValuesFilter;
	filters: Filters;
	setValuesFilter: React.Dispatch<React.SetStateAction<ValuesFilter>>;
	notShowLabel?: boolean;
	showMonths?: boolean;
};

export default function SectionFilterOptions({
	filters,
	setValuesFilter,
	valuesFilter,
	notShowLabel,
	showMonths,
}: PropsFilterInputOption) {
	const onChange = (option: SingleValue<FilterOption>, key: string) => {
		if (option === null) return;
		setValuesFilter({
			...valuesFilter,
			[key]: option as FilterOption,
		});
	};

	return (
		<>
			<Flex
				align={{ sm: 'flex-end', lg: 'flex-end' }}
				justify={{ sm: 'flex-start', lg: 'flex-start' }}
				gap='10px'
			>
				{Object.values(filters)
					.filter((filter) => showMonths || filter?.key !== 'meses')
					.map((filter) => {
						const { key, label, options } = filter;
						return (
							<FormControl
								minW={'200px'}
								key={key}
								mr='5px'
								className='filter-bar'
							>
								{!notShowLabel && (
									<FormLabel>{label}</FormLabel>
								)}
								<Select
									className='basic-single'
									placeholder='Selecione'
									styles={getStylesConfigSingle<any>(
										'medium',
									)}
									menuPosition='fixed'
									menuPortalTarget={document.body}
									onChange={(p: any) => onChange(p, key)}
									value={{
										...valuesFilter[key],
										label: Capitalize(
											valuesFilter[key]?.label || '',
										),
										value: valuesFilter[key]?.value || '',
										check:
											valuesFilter[key]?.check || false,
									}}
									options={
										options?.map(
											({ label, value, check }) => ({
												label: Capitalize(label || ''),
												value: value as string,
												check,
											}),
										) || ([] as any)
									}
								/>
							</FormControl>
						);
					})}
			</Flex>
		</>
	);
}
