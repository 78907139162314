import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useState } from 'react';

function SidebarHelpProvider(props) {
	const [dataSidebar, setDataSidebar] = useState<SidebarHelp>({
		title: '',
		firstParagraph: '',
		secondParagraph: '',
		urlVideo: '',
		firstLink: '',
		secondLink: '',
	});

	const states = { dataSidebar };
	const setters = { setDataSidebar };

	return (
		<SidebarHelpContext.Provider value={{ states, setters }}>
			{props.children}
		</SidebarHelpContext.Provider>
	);
}

export default SidebarHelpProvider;
