import api from '../api';

export const editBudget = async (body, id: number) => {
	const url = `/orcamento?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const getBudgetById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/orcamento/orcamentoById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const deleteBudget = async (id: number) => {
	const url = `orcamento?id=${id}`;
	const response = await api.delete(url);
	return response.data;
};

export const deleteBudgetByFilters = async (filters: any) => {
	const response = await api.post('orcamento/deletarPorFiltros', filters);
	return response.data;
};
