import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { LoadingSpinner } from 'components/loading/Loading';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

interface IRenderRowProps {
	row: any;
	index?: number;
	parentFilterParams?: any;
	[key: string]: any;
}

interface ITreeViewRowProps {
	renderRow: (props: IRenderRowProps) => any;
	infinityQueriesFunctions: Array<{
		queryKeyName: string;
		getData: (params: any) => Promise<any>;
		getNextPage: (lastPage, allPages, limit) => number | undefined;
		appendData: (data: any) => any;
		[key: string]: any;
	}>;
	filterKeyName?: string;
}

export interface ITreeViewProps {
	row: ITreeViewRowProps;
	hasHeader?: boolean;
	hasFooter?: boolean;
	deeper?: number;
	children?: Array<ITreeViewProps>;
	parentFilterParams?: any;
	parentData?: any;
	isFormList?: boolean;
	showScrollBar?: boolean;
}

export interface IGenericTreeViewInfiniteScrollProps {
	treeview: ITreeViewProps;
	formParams: {
		formProps: any;
		formListName?: string;
		renderizedObjectIds?: React.MutableRefObject<
			Map<string, Set<string | number>>
		>;
		mapFieldArrayForm?: React.MutableRefObject<Map<string, any>>;
		manageData?: Array<{
			deeper: number;
			renderHeader?: (props: any) => any;
			renderFooter?: (props: any) => any;
			transformDataToFormList?: (params: any) => any;
			getSharedDataChildren?: (params: any) => any;
		}>;
	};
}

const MAX_RESULTS_PAGE = 10;

const CACHE_TIME = 180000;

/**
 * TALVEZ AO UTILIZAR O FORM COM LISTA POSSA RESOLVER O PROBLEMA DE NÃO OBTER OS VALORES
 * NO SUBMIT DO FORM.
 * ATUALMENTE ESTÁ UTILIZANDO REGISTER COM COMPONENTE INPUT DO TIPO NUMBER.
 *
 */

const GenericTreeViewInfiniteScrollComponent = (
	props: IGenericTreeViewInfiniteScrollProps,
) => {
	const { treeview, formParams } = props;

	const {
		deeper = 0,
		hasHeader,
		hasFooter,
		parentData = {},
		row,
		children = [],
		parentFilterParams = {},
		isFormList = false,
		showScrollBar = false,
	} = treeview;

	const { renderRow, infinityQueriesFunctions, filterKeyName } = row;

	const { queryKeyName, getData, getNextPage, appendData } =
		infinityQueriesFunctions[0];

	const {
		formProps,
		formListName = 'form_list',
		manageData,
		renderizedObjectIds,
		mapFieldArrayForm,
	} = formParams;

	const { control } = formProps;

	const { ...fieldArrayProps } = useFieldArray({
		control,
		name: formListName,
	});

	const { fields: formListFields, append, move, remove } = fieldArrayProps;

	const [expandedRows, setExpandedRows] = useState<number[]>([]);

	const observer = useRef<IntersectionObserver>();

	const shouldRenderFooter = useRef<boolean>(true);

	const { data, error, isFetching, isLoading, fetchNextPage, hasNextPage } =
		useInfiniteQuery({
			queryKey: [queryKeyName, ...Object.values(parentFilterParams)],
			queryFn: ({ pageParam }) =>
				getData({
					...parentFilterParams,
					page: pageParam || 0,
					size: MAX_RESULTS_PAGE,
				}),
			getNextPageParam: (lastPage, allPages) =>
				getNextPage(lastPage, allPages, MAX_RESULTS_PAGE),
			retry: false,
			refetchOnWindowFocus: false,
			cacheTime: CACHE_TIME,
			staleTime: CACHE_TIME,
		});

	const lastElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (isLoading) return;

			/**
			 * Se já existir um observer, desconecta ele, afim de evitar a criação desnecessário
			 * de múltiplos observers.
			 */
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasNextPage && !isFetching) {
					fetchNextPage();
				}
			});

			if (node) {
				observer.current.observe(node);
			}
		},
		[fetchNextPage, hasNextPage, isFetching, isLoading],
	);

	const memoizedParentData = useMemo(() => parentData, [parentData]);

	const memoizedData = useMemo(() => appendData({ data }), [data?.pages]);

	const toggleRow = useCallback(
		(rowId: number) => {
			if (expandedRows.includes(rowId)) {
				setExpandedRows(expandedRows.filter((name) => name !== rowId));
			} else {
				setExpandedRows([...expandedRows, rowId]);
			}
		},
		[expandedRows],
	);

	const isRowExpanded = useCallback(
		(rowId) => expandedRows.includes(rowId),
		[expandedRows],
	);

	const buildChildrenProps = useCallback(
		(elementId, _children) => {
			const { getSharedDataChildren } =
				manageData?.find?.((el) => el?.deeper === deeper) || {};

			const sharedData =
				getSharedDataChildren?.({ data, parentId: elementId }) || {};

			return {
				treeview: {
					..._children,
					parentFilterParams: {
						...parentFilterParams,
						...(filterKeyName
							? { [filterKeyName]: elementId }
							: null),
					},
					parentData: sharedData,
				},
				formParams: {
					...formParams,
					renderizedObjectIds,
					formListName: `${formListName}_${elementId}`,
				},
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			deeper,
			filterKeyName,
			formListName,
			parentFilterParams,
			data,
			manageData,
		],
	);

	const render = useCallback(
		({ item, index, deeper, formListName = undefined }) => {
			const hasAlert =
				!!Object.keys(item?.alert || {}).length &&
				Object.keys(item?.alert || {})?.some((key) => item?.alert[key]);

			return (
				<>
					<Flex
						key={item?.id} // gerado pelo react-hook-form
						_hover={{
							backgroundColor: '#f4f7fe !important',
							color: '#42adef !important',
						}}
						data-test='generic_treeView_infinite_scroll-row'
						pt='5px'
						pb='5px'
						ref={lastElementRef}
						gap={3}
						pl={`${(30 * deeper) / 2}px`}
						borderBottom='1px solid #e2e8f0'
						bg={hasAlert ? 'easyRED.100' : ''}
					>
						<Flex
							w='40px !important'
							display='flex'
							alignItems='center'
							justifyContent='center'
						>
							{children?.length ? (
								<IconButton
									data-test='generic_treeView_infinite_scroll-expand'
									aria-label={`${item?.id}`}
									onClick={() => toggleRow(item?.id)}
									size='sm'
									icon={
										isRowExpanded(item?.id) ? (
											<ChevronDownIcon />
										) : (
											<ChevronRightIcon />
										)
									}
									w={6}
									h={6}
									color='black !important'
								/>
							) : null}
						</Flex>
						{renderRow({
							row: item,
							index,
							parentFilterParams,
							formListName,
						})}
					</Flex>
					{isRowExpanded(item?.id) && !!children?.length && (
						<GenericTreeViewInfiniteScroll
							{...buildChildrenProps(
								item?._id || item?.id,
								children[0],
							)}
						/>
					)}
				</>
			);
		},
		[
			buildChildrenProps,
			children,
			isRowExpanded,
			lastElementRef,
			parentFilterParams,
			renderRow,
			toggleRow,
		],
	);

	const renderRows = useCallback(
		(items, deeper) => {
			return items?.map((item, index) => render({ item, index, deeper }));
		},
		[render],
	);

	const renderRowsAsListForm = useCallback(
		(items, deeper) => {
			return items
				?.map((item, index) => {
					if (item?.isTotalRow) return null;

					return render({
						item,
						index,
						deeper,
						formListName,
					});
				})
				?.filter((el) => !!el);
		},
		[render, formListName],
	);

	const scrollCss = useMemo(
		() =>
			showScrollBar
				? {}
				: {
						/* Hiding scrollbar for Chrome, Safari and Opera */
						'&::-webkit-scrollbar': {
							display: 'none',
						},
						/* Hiding scrollbar for IE, Edge and Firefox */
						'scrollbar-width': 'none' /* Firefox */,
						'-ms-overflow-style': 'none' /* IE and Edge */,
				  },
		[showScrollBar],
	);

	const getHeader = useCallback(() => {
		if (hasHeader && !!memoizedData?.length) {
			const { renderHeader } =
				manageData?.find?.((el) => el?.deeper === deeper) || {};
			return (
				<Flex
					position='sticky'
					top='0'
					zIndex='1'
				>
					{renderHeader?.({ deeper, data: memoizedData })}
				</Flex>
			);
		}
		return null;
	}, [deeper, hasHeader, manageData, memoizedData]);

	const getFooter = useCallback(
		(data) => {
			if (hasFooter) {
				const { renderFooter } =
					manageData?.find?.((el) => el?.deeper === deeper) || {};

				if (isFormList)
					return renderFooter?.({
						deeper,
						data,
						append,
						remove,
						formListName,
					});
				else return renderFooter?.({ deeper, data });
			}
			return null;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[deeper, hasFooter, manageData, formListName, isFormList],
	);

	const getRows = useCallback(() => {
		const _rows = isFormList
			? renderRowsAsListForm(formListFields, deeper)
			: renderRows(memoizedData, deeper);

		return (
			<Flex
				display='flex'
				flexDirection='column'
				// position='relative'
			>
				{getHeader()}
				{_rows}
				{getFooter(memoizedParentData)}
			</Flex>
		);
	}, [
		deeper,
		formListFields,
		getHeader,
		getFooter,
		isFormList,
		memoizedData,
		memoizedParentData,
		renderRows,
		renderRowsAsListForm,
	]);

	useEffect(() => {
		if (
			shouldRenderFooter?.current &&
			!!Object.keys(memoizedParentData || {}).length
		) {
			getFooter(memoizedParentData);
			shouldRenderFooter.current = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedParentData]);

	useEffect(() => {
		if (isFormList && !!memoizedData?.length) {
			const { transformDataToFormList } =
				manageData?.find?.((el) => el?.deeper === deeper) || {};

			transformDataToFormList?.({
				formListName,
				data: memoizedData?.filter((el) => {
					if (!renderizedObjectIds?.current?.has(formListName))
						renderizedObjectIds?.current.set(
							formListName,
							new Set(),
						);

					const exists = renderizedObjectIds?.current
						?.get(formListName)
						?.has(el?.id);

					if (!exists) {
						renderizedObjectIds?.current
							?.get(formListName)
							?.add(el?.id);
					}

					return !exists;
				}),
				append,
				move,
				parentFilterParams,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedData, isFormList, append, manageData, deeper, move]);

	useEffect(() => {
		mapFieldArrayForm?.current?.set(formListName, fieldArrayProps);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Desconecta o observer ao desmontar o componente.
	 */
	useEffect(() => {
		return () => {
			mapFieldArrayForm?.current?.delete(formListName);

			if (observer.current) {
				observer.current.disconnect();
			}
		};
	}, []);

	/**
	 * Move o campo total para o final do formListFields ao carregar os primeiros dados
	 */
	useEffect(() => {
		if (
			hasFooter &&
			!!formListFields?.length &&
			formListFields?.length <= MAX_RESULTS_PAGE + 1
		) {
			const totalIndex = formListFields?.findIndex(
				(el: any) => el?.isTotalRow === true,
			);

			if (
				totalIndex !== -1 &&
				totalIndex !== formListFields?.length - 1
			) {
				move(totalIndex, formListFields?.length - 1);
			}
		}
	}, [formListFields, formListFields?.length, hasFooter, move]);

	return (
		<Flex
			display='column'
			maxH={deeper === 0 ? '62vh' : '55vh'}
			overflowY='auto'
			css={scrollCss}
			border={deeper === 0 ? '1px solid #e2e8f0' : 'none'}
			borderRadius='md'
		>
			{isLoading && (
				<LoadingSpinner
					pt='5px'
					pb='5px'
					color='easyBLUE.300'
				/>
			)}

			{getRows()}

			{error && (
				<Flex
					w='100%'
					justifyContent='center'
					alignItems='center'
				>
					<Text
						color='easyRED.300'
						fontSize='sm'
						pt='5px'
						pb='5px'
					>
						Ocorreu um erro ao buscar os dados.
					</Text>
				</Flex>
			)}

			{!isLoading && isFetching && (
				<LoadingSpinner
					message='carregando mais dados'
					pt='5px'
					pb='5px'
					color='easyBLUE.300'
				/>
			)}

			{!isLoading && !isFetching && !memoizedData?.length && (
				<Flex
					w='100%'
					justifyContent='center'
					alignItems='center'
				>
					<Text
						color='easyBLUE.300'
						fontSize='sm'
						pt='5px'
						pb='5px'
					>
						Não há dados para carregar.
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

const GenericTreeViewInfiniteScroll = memo(
	GenericTreeViewInfiniteScrollComponent,
);

export default GenericTreeViewInfiniteScroll;
