const generateColors = (data) => {
	if (data <= 25) {
		return [
			{
				offset: 25,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#CC2B01',
				opacity: 1,
			},
		];
	} else if (data > 25 && data <= 50) {
		return [
			{
				offset: 25,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 75,
				color: '#CC9702',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#CC9702',
				opacity: 1,
			},
		];
	} else if (data > 50 && data <= 75) {
		return [
			{
				offset: 10,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 60,
				color: '#CC9702',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#cccc02',
				opacity: 1,
			},
		];
	} else if (data > 75) {
		return [
			{
				offset: 10,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 30,
				color: '#CC9702',
				opacity: 1,
			},
			{
				offset: 50,
				color: '#cccc02',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#A9C701',
				opacity: 1,
			},
		];
	}
};

const setLabelChart = (data) => {
	if (data < 80) {
		return 'Desnivelado';
	}

	return 'Nivelado';
};

export const speedometerChartOptions = (data) => ({
	chart: {
		toolbar: {
			show: false,
		},
	},
	plotOptions: {
		radialBar: {
			startAngle: -90,
			endAngle: 90,
			hollow: {
				margin: 0,
				size: '70%',
				backgroundImage:
					'linear-gradient(180deg, rgba(255, 255, 255, 0) 70.31%, rgba(255, 255, 255, 0.3) 100%)',
				image: undefined,
				imageOffsetX: 0,
				imageOffsetY: 0,
				position: 'front',
				dropShadow: {
					enabled: true,
					top: 3,
					left: 0,
					blur: 4,
					opacity: 0.24,
				},
			},
			track: {
				show: true,
				startAngle: -90,
				endAngle: 270,
				strokeWidth: '200%',
				background: '#465060',
				// opacity: 0.6,
				margin: 10, // margin is in pixels
				dropShadow: {
					enabled: true,
					top: -3,
					left: 0,
					blur: 4,
					opacity: 0.35,
				},
			},

			dataLabels: {
				show: true,
				name: {
					// offsetY: 40,
					show: true,
					color: '#fff',
					fontSize: '25px',
					fontWeight: '700',
					fontFamily: 'DM Sans',
				},
				value: {
					color: '#fff',
					fontSize: '20px',
					show: true,
					fontWeight: '700',
					fontFamily: 'DM Sans',
					// offsetY: -10,
				},
			},
		},
	},
	fill: {
		type: 'gradient',
		gradient: {
			shade: 'dark',
			type: 'horizontal',
			shadeIntensity: 0.5,
			colorStops: generateColors(90),
			inverseColors: false,
			opacityFrom: 1,
			opacityTo: 1,
		},
	},
	stroke: {
		lineCap: 'round',
	},
	labels: [setLabelChart(data)],
	grid: {
		padding: {
			bottom: 40,
		},
	},
});

export const barChartOptions = (categories) => ({
	chart: {
		toolbar: {
			show: false,
		},
		type: 'bar',
	},
	plotOptions: {
		bar: {
			horizontal: false,
			borderRadius: 4,
			columnWidth: '55%',
			endingShape: 'rounded',
			dataLabels: {
				position: 'top',
			},
		},
	},
	tooltip: {
		theme: 'dark',
	},
	colors: ['#56CCF2', '#FC6574'],
	dataLabels: {
		enabled: true,
		offsetY: -20,
		style: {
			fontSize: '10px',
			colors: ['#fff'],
		},
		formatter: function (value) {
			const intValue = Math.trunc(value);
			return intValue.toLocaleString('pt-BR');
		},
	},
	stroke: {
		show: true,
		width: 2,
		colors: '#fff',
	},
	xaxis: {
		categories,
		labels: {
			style: {
				colors: '#fff',
			},
		},
	},
	yaxis: {
		show: false,
		labels: {
			formatter: (value) => {
				return value?.toLocaleString('pt-BR', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			},
		},
	},
	grid: {
		show: false,
	},
	legend: {
		show: true,
		labels: {
			colors: '#fff',
			useSeriesColors: false,
		},
	},
});
