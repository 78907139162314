import { ButtonGroup, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react"
import { IconLock } from "@tabler/icons"
import { ButtonComponent } from "components/buttons/ButtonComponent"

export const PasswordResetModal = (props) => {
    const { isOpen, onClose, passwordReset } = props
    const { id, login, status, tipo, usuario } = passwordReset && passwordReset
    const [nome, email, photo] = usuario

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent borderRadius={20} pt="20px">

                <ModalCloseButton />
                <ModalBody>
                    <Icon as={IconLock} color="easyRED.300" w={14} h={14}></Icon>
                    <Heading mb={2} size="md">
                        <Text
                            color="easyBLUE.300"
                            fontWeight={700}
                        >
                            Redefinir senha para o usuário
                            <Text
                                fontWeight="800"
                            >
                                {nome}
                            </Text>
                        </Text>
                    </Heading>
                    <Text color="gray.500">
                        Essa ação irá redefinir a senha do usuário para uma senha gerada automaticamente.
                        <Text>
                            Ao clicar em "Redefinir", o usuário receberá um e-mail na caixa de entrada do endereço: <b>{email}</b> com a nova senha. Deseja continuar?
                        </Text>
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <ButtonComponent type={"ghost"} title={"Cancelar"} onClick={onClose} />
                        <ButtonComponent type={"secondary"} title={"Redefinir"} onClick={() => { console.log("cliquei") }} />
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}