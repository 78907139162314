// Chakra imports
import {
  Flex, Grid,
  GridItem, Text, useColorModeValue
} from "@chakra-ui/react";
import { ButtonComponent } from "components/buttons/ButtonComponent";
import { VSeparator } from "components/separator/Separator";
// Custom components
import Card from "components/card/Card.js";
import Mastercard from "components/card/Mastercard";
import { useGlobalContext } from "contexts/GlobalContext";
import { useHistory } from "react-router-dom";


export default function YourCard(props) {
  const { ...rest } = props;

  const cardColor = useColorModeValue("white", "navy.700");

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const history = useHistory();
  const { pathCompanyName } = useGlobalContext()
  
  const goToPagamento = () => {
    history.push(`/${pathCompanyName}/cadastros/usuarios/pagamento`);
  };


  return (
    <Card {...rest}>
      <Flex pb='20px' align='center' direction='column' w='100%'>
      <Text
        color={textColor}
        fontSize='3xl'
        fontWeight='700'
        mb='10px'
        mx='auto'
        textAlign={'center'}>
          Cobrança do Plano
        </Text>
        <Text
        color='secondaryGray.600'
        fontSize='lg'
        fontWeight='500'
        maxW='350px'
        mx='auto'
        mb='10px'>
        Cobrança feita automaticamente.
      </Text>
      </Flex>
    
            <Mastercard number='7812 XXXX XXXX XXXX' cvv='09X' exp='05/24' />
         
            <Grid bg={cardColor}  p='15px' px='20px' mt='40px' templateColumns={'repeat(5, 2fr)'} >
        <GridItem as={Flex} direction='column' py='5px' textAlign={'left'} colSpan={2}>
          <Text
            fontSize='xs'
            color='secondaryGray.600'
            fontWeight='700'
            mb='5px'>
           Cobrança em:
          </Text>
          <Text fontSize='xs' color={textColor} fontWeight='700'>
            30 de Outubro
          </Text>
        </GridItem>
        <VSeparator margin="0 auto" colSpan={1} />
        <GridItem as={Flex} colSpan={2} direction='column' py='5px'>
         <ButtonComponent type="primary" title="Alterar" onClick={() => goToPagamento()}></ButtonComponent>
        </GridItem>
      </Grid>
    </Card>
  );
}
