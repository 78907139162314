import { numberToFormattedString } from './numberToFormateString';

function formatNumberToLocaleCurrency({
	value,
	locale = 'pt-BR',
	currency = 'BRL',
	maximumFractionDigits = 0,
}) {
	if (!value || typeof value !== 'number') return null;

	return Math.trunc(value)?.toLocaleString?.(locale, {
		style: 'currency',
		currency,
		maximumFractionDigits,
	});
}

export function converterStockData(data: any, decimalPrecision: number) {
	if (!data) return [];
	const [initialData, ...others] = data;

	if (!initialData) return others;

	const statisticData = { ...initialData };

	['MEDIA_MOVEL', 'SAZONALIDADE', 'TENDENCIA_LINEAR'].forEach((key) => {
		if (!statisticData[key]) statisticData[key] = {};

		statisticData[key].receita = formatNumberToLocaleCurrency({
			value: statisticData[key]?.receita,
		});
		statisticData[key].estoqueSugerido = numberToFormattedString(
			statisticData[key]?.estoqueSugerido,
			decimalPrecision,
		);
		statisticData[key].erro = numberToFormattedString(
			statisticData[key]?.erro,
			decimalPrecision,
		);

		statisticData[key].historicoErros = statisticData[
			key
		]?.historicoErros?.map?.((el) => {
			return {
				...el,
				erro: numberToFormattedString(+el?.erro || 0, decimalPrecision),
			};
		});
	});

	return [statisticData, ...others];
}
