import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import { ColorProps, useColorModeValue } from '@chakra-ui/system';
import { IconMessage } from '@tabler/icons';
import { currencyToNumber } from '../../../../../../utils/functions/currencyToNumber';

type TagInformation = {
	color: ColorProps['color'];
	text: string;
};
type TypesTagInformation =
	| 'optimistic'
	| 'moderate'
	| 'pessimistic'
	| 'invalid';
const tagsInforms: Record<TypesTagInformation, TagInformation> = {
	optimistic: {
		color: '#01B574',
		text: 'Otimista',
	},
	moderate: {
		color: '#BB9200',
		text: 'Moderado',
	},
	pessimistic: {
		color: '#B01A2E',
		text: 'Pessimista',
	},
	invalid: {
		color: '#505050',
		text: 'Sem colaboração',
	},
};
const getTag = (
	value: number,
	extremesValues?: ExtremesValues,
): TypesTagInformation => {
	if (!extremesValues) return 'invalid';
	const { max, min } = extremesValues;
	if (max === min) {
		return 'moderate';
	}
	if (value === min) {
		return 'pessimistic';
	}
	if (value === max) {
		return 'optimistic';
	}
	if (isNaN(value) || value === 0) {
		return 'invalid';
	}
	return 'moderate';
};
interface ExtremesValues {
	max: number;
	min: number;
}
export const CellMedia = (
	{ cell, row, column },
	ComponentRow,
	extremesValues?: ExtremesValues,
): JSX.Element => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	// const value = Number(cell.value)

	const exeptions = ['Previsão de Receita', undefined];
	const tagInforms =
		tagsInforms[
			getTag(currencyToNumber(String(row.total)), extremesValues)
		];

	// return <Text
	//     paddingLeft="8px"
	//     marginRight={0}
	//     alignItems="center"
	//     display={"flex"}
	//     gap="4px"
	//     color={textColor} fontSize='sm' fontWeight='700'>
	//     {exeptions.includes(cell) ?
	//         ComponentRow : <>
	//             {
	//                 <Flex gap={"3px"} alignItems={"center"}>
	//                     <Flex gap={"3px"} alignItems={"center"}>
	//                         <Text
	//                             display={"flex"}
	//                             align="center"
	//                             justifyContent={"center"}
	//                             paddingY={"3px"}
	//                             paddingX="4px"
	//                             backgroundColor={tagInforms.color + "1A"}
	//                             borderRadius="10px"
	//                             fontSize={"12px"}
	//                             minWidth="75px"
	//                             color={tagInforms.color}
	//                         >

	//                             {tagInforms.text}
	//                         </Text>
	//                         {cell}
	//                     </Flex>
	//                     {row.ata && <Tooltip
	//                         borderRadius={"16px"}
	//                         padding="20px"
	//                         title="Comentário"
	//                         border={"1px solid #E0E5F2"}
	//                         minWidth="200px"
	//                         boxShadow={"14px 17px 40px 4px rgba(112, 144, 176, 0.08)"}
	//                         label={<Flex
	//                             flexDirection={"column"}
	//                             gap={"10px"}
	//                         >
	//                             <Text color={"easyBLUE.300"}>
	//                                 Comentário
	//                             </Text>
	//                             <Text>

	//                                 {row.ata}
	//                             </Text>
	//                         </Flex>}
	//                         color={"secondaryGray.500"}
	//                         borderColor={"secondaryGray.100"}
	//                         bg="#ffffff">
	//                         <Flex>
	//                             <Icon
	//                                 color='secondaryGray.500'
	//                                 as={IconMessage}
	//                                 w='20px'
	//                                 h='20px' />
	//                         </Flex>
	//                     </Tooltip>}
	//                 </Flex>}
	//         </>
	//     }
	// </Text>
	return ComponentRow;
};
