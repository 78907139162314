import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { exportCollaborationConsensus } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
export const useExportCollaborationConsensus = (
	page: string,
	cicloId: string,
	areaEmpresaId: string,
) => {
	const toast = useToast();
	return useQuery(
		['export-collaboration-consensus', page, cicloId, areaEmpresaId],
		exportCollaborationConsensus,
		{
			refetchOnWindowFocus: false,
			retry: 1,
			enabled: false,
			onSuccess: () => {
				toast({
					title: 'Sucesso!',
					description: 'Exportação do arquivo realizada com sucesso!',
					status: 'success',
					duration: 8000,
					isClosable: true,
				});
			},
			onError: (err: any) => {
				toast({
					title: 'Erro!',
					description: err.response.data[0].message,
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
		},
	);
};
