import { useToast } from '@chakra-ui/react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createException } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useCreateException = (form?) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(createException, {
		onSuccess: sucess => {
			queryClient.invalidateQueries([
				'collaboration-consensus-exeption-by-family',
			]);

			toast({
				title: 'Exceção criada com sucesso!',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			console.log(error.response.data.message);
			toast({
				title: 'Erro ao criar exceção!',
				description:
					'Verifique se preencheu os campos corretamente de exceção.',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
