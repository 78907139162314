// Chakra imports
// Chakra imports
import {
	Flex,
	Stat,
	StatLabel,
	StatNumber,
	Text,
	Tooltip,
	useColorModeValue,
} from '@chakra-ui/react';
import { IconHelp } from '@tabler/icons';
// Custom components
import Card from 'components/card/Card.js';
// Custom icons

export default function MiniStatistics(props) {
	const {
		startContent,
		endContent,
		name,
		growth,
		value,
		data,
		textHelp,
		additionalInfo,
	} = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const helpStyle = {
		color: '#A3AED0',
		cursor: 'pointer',
	};

	return (
		<Card py='15px'>
			<Flex
				my='auto'
				// h='100%'
				align={{ base: 'center', xl: 'start' }}
				justify={{ base: 'center', xl: 'center' }}
			>
				{startContent}

				<Stat
					my='auto'
					ms={startContent ? '18px' : '0px'}
				>
					<StatLabel
						lineHeight='100%'
						color={textColorSecondary}
						fontSize={{
							base: 'sm',
						}}
					>
						<Flex
							gap={2}
							alignItems='center'
						>
							{name}
							{textHelp && (
								<Tooltip
									label={textHelp}
									placement='top'
									bg='gray.200'
									color='blue.800'
									hasArrow
									arrowSize={15}
								>
									<span style={helpStyle}>
										<IconHelp size={16} />
									</span>
								</Tooltip>
							)}
						</Flex>
						{data && (
							<Flex>
								<Text
									as={'span'}
									fontSize='xs'
									ml={1}
								>
									({data})
								</Text>
							</Flex>
						)}
					</StatLabel>
					<StatNumber
						color={textColor}
						fontSize={{
							base: '2xl',
						}}
					>
						{value}
					</StatNumber>
					{growth ? (
						<Flex align='center'>
							<Text
								color='green.500'
								fontSize='xs'
								fontWeight='700'
								me='5px'
							>
								{growth}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize='xs'
								fontWeight='400'
							>
								since last month
							</Text>
						</Flex>
					) : null}
				</Stat>
				<Flex
					ms='auto'
					w='max-content'
				>
					{endContent}
				</Flex>
			</Flex>
			{additionalInfo && (
				<Stat
					my='auto'
					ms='0px'
					pt='10px'
				>
					<StatLabel
						lineHeight='100%'
						color={textColorSecondary}
						fontSize={{
							base: 'xs',
						}}
					>
						<Flex
							direction='column'
							gap='1'
						>
							{additionalInfo}
						</Flex>
					</StatLabel>
				</Stat>
			)}
		</Card>
	);
}
