import {
	AspectRatio,
	Flex,
	Link,
	ListItem,
	Text,
	UnorderedList,
} from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext } from 'react';

export function ContentSidebarHelp(props) {
	const { states } = useContext(SidebarHelpContext);

	const { dataSidebar } = states;

	const {
		title,
		firstParagraph,
		secondParagraph,
		thirdParagraph,
		fourthParagraph,
		urlVideo,
		firstLink,
		secondLink,
		renderContentAsJSX = false,
		helpContentJSX,
	} = dataSidebar;

	function renderContent() {
		if (renderContentAsJSX) {
			return (
				<Flex
					display={'flex'}
					flexDirection={'column'}
				>
					<Text
						fontSize='22px'
						fontWeight={700}
						pt={3}
						color={'easyBLUE.300'}
					>
						{title}
					</Text>
					{helpContentJSX}
					<Text
						fontSize='16px'
						fontWeight={700}
						pt={3}
						color={'easyBLUE.300'}
					>
						Saiba mais em:
					</Text>
					<UnorderedList
						color={'easyBLUE.200'}
						py={5}
						mt='5px'
					>
						<ListItem>
							<Link
								href={firstLink}
								target='_blank'
							>
								Acesse nossa Capacitação CT360
							</Link>
						</ListItem>
						<ListItem>
							<Link
								href={secondLink}
								target='_blank'
							>
								Fale Conosco
							</Link>
						</ListItem>
					</UnorderedList>
				</Flex>
			);
		}

		return (
			<Flex
				display={'flex'}
				flexDirection={'column'}
			>
				<Text
					fontSize='22px'
					fontWeight={700}
					pt={3}
					color={'easyBLUE.300'}
				>
					{title}
				</Text>
				<Text
					fontSize='16px'
					fontWeight={400}
					pt={3}
					color={'easyBLUE.300'}
				>
					{firstParagraph}
				</Text>
				<Text
					fontSize='16px'
					fontWeight={400}
					pt={3}
					color={'easyBLUE.300'}
				>
					{secondParagraph}

					{/* <AspectRatio maxW='560px' ratio={2} my={8}>
                        <iframe
                            title='Easy360'
                            src={urlVideo}
                            allowFullScreen
                        />
                    </AspectRatio> */}
				</Text>
				<Text
					fontSize='16px'
					fontWeight={400}
					pt={3}
					color={'easyBLUE.300'}
				>
					{thirdParagraph}
				</Text>
				<Text
					fontSize='16px'
					fontWeight={400}
					pt={3}
					color={'easyBLUE.300'}
				>
					{fourthParagraph}
				</Text>
				<Text
					fontSize='16px'
					fontWeight={700}
					pt={3}
					color={'easyBLUE.300'}
				>
					Saiba mais em:
				</Text>
				<UnorderedList
					color={'easyBLUE.200'}
					py={5}
					mt='5px'
				>
					<ListItem>
						<Link
							href={firstLink}
							target='_blank'
						>
							Acesse nossa Capacitação CT360
						</Link>
					</ListItem>
					<ListItem>
						<Link
							href={secondLink}
							target='_blank'
						>
							Fale Conosco
						</Link>
					</ListItem>
				</UnorderedList>
			</Flex>
		);
	}

	return renderContent();
}
