import {
	Avatar,
	Box,
	ButtonGroup,
	Flex,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import FormCadastrarEditarPerfilUsuarios from './FormCadastrarEditarPerfilUsuarios';
import FormCadastrarEditarUsuarios from './FormCadastrarEditarUsuarios';
import Tasks from 'pages/admin/cadastros/usuarios/perfis/components/Tasks';
import { useEffect, useRef, useState } from 'react';
import { useGetPermissionsByUsersGroups } from 'services/queryClient/wrapperHooks/authentication/useGetPermissionsByUsersGroups';
import { useGetUsersGroup } from 'services/queryClient/wrapperHooks/authentication/useGetUsersGroup';
import { useHistory } from 'react-router-dom';
import { useSignUpUsers } from 'services/queryClient/wrapperHooks/authentication/useSignUpUsers';
import { useUpdateUsers } from 'services/queryClient/wrapperHooks/authentication/useUpdateUsers';

const TemplateCadastrarEditarUsuarios = (props) => {
	const {
		initialState,
		userId,
		selectedUserTypes,
		setSelectedUserTypes,
		additionalAutocompleteData,
	} = props;

	const isEditing = !!userId;
	const textColor = useColorModeValue('easyBLUE.300', 'white');

	const [payload, setPayload] = useState({});

	const [activeBullets, setActiveBullets] = useState({
		cadastro: true,
		perfil: false,
		imagem: false,
	});

	const { data: DataUsersGroup } = useGetUsersGroup();
	const { mutate: signUpUser, isLoading: IsLoadingSignUpUser } =
		useSignUpUsers();
	const {
		data: DataPermissionsByUserGroups,
		mutate: MutateDataPermissionsByUserGroups,
	} = useGetPermissionsByUsersGroups();

	useEffect(() => {
		if (selectedUserTypes?.length) {
			const usersGroup = selectedUserTypes?.map((type) => {
				return 'grupos_usuario=' + type.value;
			});
			const usersGroupString = usersGroup.join('&');
			MutateDataPermissionsByUserGroups(usersGroupString);
		}
	}, [MutateDataPermissionsByUserGroups, selectedUserTypes]);

	const history = useHistory();

	let goToPerfis = () => {
		history.goBack();
	};

	const cadastroTab = useRef();
	const perfilTab = useRef();
	const imagemTab = useRef();

	const bodyEditing = {
		nome: payload?.dbUserData?.nome,
		telefone: payload?.dbUserData?.telefone?.replace(/\D/g, ''),
		email: payload?.dbUserData?.email,
		cep: payload?.dbUserData?.cep?.replace(/\D/g, ''),
		numero: payload?.dbUserData?.numero?.toString(),
		complemento: payload?.dbUserData?.complemento,
		grupos: payload?.dbUserData?.grupos,
		bairro: payload?.dbUserData?.bairro,
		rua: payload?.dbUserData?.rua,
		endereco: `${payload?.dbUserData?.rua}, ${payload?.dbUserData?.bairro}`,
		membro_id: payload?.dbUserData?.membro_id,
	};

	const { mutate: UpdateUser } = useUpdateUsers(userId, bodyEditing);

	// Envio e validação do último passo do cadastro, aonde finalmente o usuário é cadastrado
	const submitLastStep = (e) => {
		e.preventDefault();
		if (isEditing) UpdateUser();
		else signUpUser(payload);
	};

	return (
		<Flex
			direction='column'
			align='center'
			pt={{ sm: '0px', lg: '0px' }}
			position='relative'
			mt={{ base: '0px', md: '35px', lg: '35px' }}
		>
			<Box
				h='45vh'
				bg='linear-gradient(135.81deg, #F0243E 15.92%, #314866 109.14%)'
				boxShadow={'0px 23.5407px 52.3127px rgba(112, 144, 176, 0.12)'}
				position='absolute'
				w='100%'
				borderRadius='30px'
			></Box>
			<Tabs
				variant='unstyled'
				zIndex='0'
				display='flex'
				flexDirection='column'
			>
				<TabList
					display='flex'
					align='center'
					alignSelf='center'
					justifySelf='center'
				>
					<Tab
						ref={cadastroTab}
						_focus='none'
						pointerEvents={'none'}
						w={{ sm: '120px', md: '250px', lg: '300px' }}
						onClick={() =>
							setActiveBullets({
								cadastro: true,
								perfil: false,
								imagem: false,
							})
						}
					>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: {
									sm: '120px',
									md: '250px',
									lg: '300px',
								},
								height: '3px',
								bg: activeBullets.perfil
									? 'easyBLUE.300'
									: 'easyBrandMenu.200',
								left: { sm: '12px', md: '30px' },
								top: {
									sm: activeBullets.cadastro ? '6px' : '4px',
									md: null,
								},
								position: 'absolute',
								bottom: activeBullets.cadastro
									? '40px'
									: '38px',

								transition: 'all .3s ease',
							}}
						>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={
									activeBullets.cadastro
										? 'easyBLUE.300'
										: 'easyBrandMenu.200'
								}
								bg={
									activeBullets.cadastro
										? 'linear-gradient(134deg, #CED7F5 0%, #00295f 100%)'
										: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(135deg, #CED7F5 0%, #516193 100%)'
								}
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={
									activeBullets.cadastro
										? 'easyBrandMenu.100'
										: 'easyBLUE.150'
								}
								fontWeight={
									activeBullets.cadastro ? '500' : 'normal'
								}
								display={{ sm: 'none', md: 'block' }}
							>
								Cadastro
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={perfilTab}
						pointerEvents={'none'}
						_focus='none'
						w={{ sm: '120px', md: '250px', lg: '300px' }}
						onClick={() =>
							setActiveBullets({
								cadastro: true,
								perfil: true,
								imagem: false,
							})
						}
					>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
							_before={{
								content: "''",
								width: {
									sm: '120px',
									md: '250px',
									lg: '300px',
								},
								height: '3px',
								bg: activeBullets.imagem
									? 'easyBLUE.300'
									: 'easyBrandMenu.200',
								left: { sm: '12px', md: '15px' },
								top: '6px',
								position: 'absolute',
								bottom: activeBullets.perfil ? '40px' : '38px',

								transition: 'all .3s ease',
							}}
						>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={
									activeBullets.perfil
										? 'easyBLUE.300'
										: 'easyBrandMenu.200'
								}
								bg={
									activeBullets.perfil
										? 'linear-gradient(134deg, #CED7F5 0%, #00295f 100%)'
										: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(135deg, #CED7F5 0%, #516193 100%)'
								}
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={
									activeBullets.perfil
										? 'easyBrandMenu.100'
										: 'easyBLUE.150'
								}
								fontWeight={
									activeBullets.perfil ? '500' : 'normal'
								}
								display={{ sm: 'none', md: 'block' }}
							>
								Perfil
							</Text>
						</Flex>
					</Tab>
					<Tab
						ref={imagemTab}
						pointerEvents={'none'}
						_focus='none'
						w={{ sm: '120px', md: '250px', lg: '300px' }}
						onClick={() =>
							setActiveBullets({
								cadastro: true,
								perfil: true,
								imagem: true,
							})
						}
					>
						<Flex
							direction='column'
							justify='center'
							align='center'
							position='relative'
						>
							<Box
								zIndex='1'
								border='2px solid'
								borderColor={
									activeBullets.imagem
										? 'easyBLUE.300'
										: 'easyBrandMenu.200'
								}
								bg={
									activeBullets.imagem
										? 'linear-gradient(134deg, #CED7F5 0%, #00295f 100%)'
										: 'linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), linear-gradient(135deg, #CED7F5 0%, #516193 100%)'
								}
								w='16px'
								h='16px'
								mb='8px'
								borderRadius='50%'
							/>
							<Text
								color={
									activeBullets.imagem
										? 'easyBrandMenu.100'
										: 'easyBLUE.150'
								}
								fontWeight={
									activeBullets.imagem ? '500' : 'normal'
								}
								display={{ sm: 'none', md: 'block' }}
							>
								Finalizar
							</Text>
						</Flex>
					</Tab>
				</TabList>
				<TabPanels
					mt='24px'
					maxW={{ md: '90%', lg: '100%' }}
					mx='auto'
				>
					<TabPanel
						p='0px'
						mx='auto'
					>
						<Card p='30px'>
							<Text
								color={textColor}
								fontSize='2xl'
								fontWeight='700'
							>
								Informações do Usuário
							</Text>
							<Flex
								direction='column'
								w='100%'
								mt={10}
							>
								<FormCadastrarEditarUsuarios
									initialState={initialState}
									perfilTab={perfilTab}
									payload={payload}
									setPayload={setPayload}
									userId={userId}
								/>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel
						p='0px'
						mx='auto'
					>
						<Card p='30px'>
							<Flex
								direction='column'
								w='100%'
								mt={8}
							>
								<FormCadastrarEditarPerfilUsuarios
									initialState={initialState}
									DataUsersGroup={DataUsersGroup}
									setSelectedUserTypes={setSelectedUserTypes}
									imagemTab={imagemTab}
									payload={payload}
									setPayload={setPayload}
									cadastroTab={cadastroTab}
									additionalAutocompleteData={
										additionalAutocompleteData
									}
								/>
								<Stack
									direction='column'
									spacing={9}
									mb='20px'
								>
									{selectedUserTypes?.length > 0 &&
									DataPermissionsByUserGroups ? (
										<Tasks
											dataPermissions={
												DataPermissionsByUserGroups
											}
										/>
									) : (
										<Box
											w='100%'
											justifyContent={'center'}
											alignItems={'center'}
										>
											<Text
												color={textColor}
												fontSize='lg'
												fontWeight='700'
												mt='20px'
												mb='20px'
												textAlign={'center'}
											>
												Selecione algum tipo de usuário
												para visualizar as permissões
											</Text>
										</Box>
									)}
								</Stack>
							</Flex>
						</Card>
					</TabPanel>
					<TabPanel
						p='0px'
						mx='auto'
					>
						<Card p='30px'>
							<Text
								color={textColor}
								fontSize='2xl'
								fontWeight='700'
								mb='40px'
							>
								Cadastrar Usuário
							</Text>
							<Flex
								direction='column'
								w='100%'
							>
								<Stack
									direction='column'
									spacing='40px'
									alignItems={'center'}
								>
									<Avatar
										size={'2xl'}
										w={'200px'}
										h={'200px'}
										name={payload?.dbUserData?.nome}
										bg={
											'linear-gradient(135.81deg, #F0243E 15.92%, #314866 109.14%)'
										}
										color={'white'}
									/>
									<Text
										fontSize='lg'
										fontWeight='500'
										color='easyBLUE.300'
									>
										Finalizar cadastro do usuário(a):{' '}
										<Text
											as={'span'}
											fontWeight='700'
										>
											{payload?.dbUserData?.nome}
										</Text>
									</Text>
								</Stack>
								<Flex
									mt='24px'
									justifyContent={'space-between'}
								>
									<ButtonComponent
										type={'ghost'}
										title={'Voltar'}
										onClick={() =>
											perfilTab.current.click()
										}
									/>
									<ButtonGroup spacing={'20px'}>
										<ButtonComponent
											type={'ghost'}
											title={'Cancelar'}
											onClick={goToPerfis}
										/>
										<ButtonComponent
											type={'primary'}
											title={'Salvar'}
											onClick={submitLastStep}
											isLoading={IsLoadingSignUpUser}
										/>
									</ButtonGroup>
								</Flex>
							</Flex>
						</Card>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	);
};

export default TemplateCadastrarEditarUsuarios;
