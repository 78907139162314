export const columnsDataHoraHora = [
	{
		Header: 'Cód. Produto',
		accessor: 'produto_id',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Atividade',
		accessor: 'atividade_roteiro_id',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Recurso',
		accessor: 'recurso_id',
		type: 'text',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Nº Orden',
		accessor: 'ordem_producao',
		type: 'text',
		dependsOnCreateable: true,
		dependesOn: 'zonaVenda',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'QTD. Peças Boas',
		accessor: 'pecas_boas',
		type: 'text',
		// descomentar linha do optional abaixo caso seja necessário identificar o campo Razão Social como um campo de preenchimento obrigatório
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'QTD. Refugo',
		accessor: 'refugo',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data',
		accessor: 'data',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Turno',
		accessor: 'turno_id',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Hora Inicial',
		accessor: 'hora_inicio',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Hora Final',
		accessor: 'hora_fim',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Tempo de Produção',
		accessor: 'tempo_de_producao',
		// filter: false,
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
];
