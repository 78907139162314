import { Time } from 'models/time-form.model';
import { useState } from 'react';
import { TimeContext } from 'contexts/TimeFormContext';

function TimeFormProvider(props) {
	const [editTimeForm, setEditTimeForm] = useState<Time | undefined>(
		undefined,
	);
	const [resetBreakForm, setResetBreakForm] = useState<boolean>(false);

	const states = { editTimeForm, resetBreakForm };
	const setters = { setEditTimeForm, setResetBreakForm };

	return (
		<TimeContext.Provider value={{ states, setters }}>
			{props.children}
		</TimeContext.Provider>
	);
}

export default TimeFormProvider;
