import { useQuery } from '@tanstack/react-query';
import {
	getResourceGroup,
	IQueryResourceGroup,
} from 'services/api/requests/dashboardCapacidade/resourceGroupList';

export const useResourceGroupList = (query?: IQueryResourceGroup) => {
	return useQuery(['resource-group-list'], () => getResourceGroup(query), {
		refetchOnWindowFocus: false,
	});
};
