import { useGlobalContext } from 'contexts/GlobalContext';
import CurrencyInput from 'react-currency-input-field';

import './DecimalField.css';
import { ChangeEventHandler } from 'react';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';

interface IDecimalField {
	defaultValue?: number | string;
	allowDecimals?: boolean;
	allowNegativeValue?: boolean;
	decimalsLimit?: number;
	decimalSeparator?: string;
	onChange?:
		| ((
				value: string | undefined,
				name?: string | undefined,
				values?: CurrencyInputOnChangeValues | undefined,
		  ) => void)
		| undefined;
	onChangeEvent?: ChangeEventHandler<HTMLInputElement>;
	groupSeparator?: string;
	style?: any;
	[key: string]: any;
}

const ProductQuantityField = ({
	dataTest,
	defaultValue = undefined,
	allowDecimals = true,
	allowNegativeValue = false,
	decimalSeparator = ',',
	groupSeparator = '.',
	maxLength = 15,
	style,
	...props
}: IDecimalField) => {
	const { onChange, onClick, onChangeEvent, ...others } = props;

	const { configsCompany } = useGlobalContext();
	return (
		<CurrencyInput
			data-test={dataTest}
			className='inputDecimal'
			intlConfig={{ locale: 'pt-BR' }}
			defaultValue={defaultValue}
			prefix=''
			allowDecimals={configsCompany.precisao_decimal !== 0}
			allowNegativeValue={allowNegativeValue}
			decimalsLimit={configsCompany.precisao_decimal}
			onValueChange={onChange}
			onChange={onChangeEvent}
			maxLength={maxLength}
			onClick={onClick}
			{...others}
			style={style}
		/>
	);
};

export default ProductQuantityField;
