import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarRecurso from '../../../../../components/forms/FormCadastrarEditarRecurso';
import { useGetResourceWithId } from '../../../../../services/queryClient/wrapperHooks/useGetResourceWithId';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import { useBrandList } from '../../../../../services/queryClient/wrapperHooks/useBrandList';
import { useModelList } from '../../../../../services/queryClient/wrapperHooks/useModelList';
import { useVersionList } from '../../../../../services/queryClient/wrapperHooks/useVersionList';
import { useAvailabilityMinutes } from '../../../../../services/queryClient/wrapperHooks/useAvailabilityMinutes';
import { numberToLocaleString } from '../../../../../utils/functions/number';

export default function EditarRecurso() {
	const { id: resourceId } = useParams<any>();

	const [formInitialState, setFormInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpRecursos: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Recursos',
			firstParagraph:
				'Para editar um recurso informe o nome do Recurso, Grupo do Recurso (possível cadastrar novo grupo no botão “Adicionar”), Marca, Modelo e Versão. Informe também se este recurso é Gargalo (recursos mais lentos) e seu IROG.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const { data: resourceData, isLoading: isLoadingResource } =
		useGetResourceWithId(resourceId);
	const { data: BrandData, isLoading: isLoadingBrandData } = useBrandList();
	const { data: ModelData, isLoading: isLoadingModelData } = useModelList();
	const { data: VersionData, isLoading: isLoadingVersionData } =
		useVersionList();
	const {
		data: AvailabilityMinutesData,
		isLoading: isLoadingAvailabilityMinutesData,
	} = useAvailabilityMinutes();

	useEffect(() => {
		setDataSidebar(helpRecursos);
	}, [helpRecursos, setDataSidebar]);

	useEffect(() => {
		if (resourceData) {
			setFormInitialState({
				id: resourceData?.id,
				maquina: String(resourceData?.maquina),
				descricao: resourceData?.descricao,
				grupo_recurso_id: resourceData?.grupo_recurso_id,
				disponibilidade_minutos_dia:
					resourceData?.disponibilidade_minutos_dia,
				nome_marca: resourceData['versao.modelo.marca.nome_marca'],
				nome_modelo: resourceData['versao.modelo.nome_modelo'],
				nome_versao: resourceData['versao.nome_versao'],
				gargalo: String(resourceData?.gargalo),
				tipo_dados_irog: 'MANUAL',
				disponibilidade_irog: numberToLocaleString({
					value: resourceData?.disponibilidade_irog,
					maximumFractionDigits: 2,
				}),
				desempenho_irog: numberToLocaleString({
					value: resourceData?.desempenho_irog,
					maximumFractionDigits: 2,
				}),
				qualidade_irog: numberToLocaleString({
					value: resourceData?.qualidade_irog,
					maximumFractionDigits: 2,
				}),
				irog: numberToLocaleString({
					value: resourceData?.irog,
					maximumFractionDigits: 2,
				}),
			});

			setAutocompleteAditionalData({
				resourceGroup: {
					grupo_recurso_id: resourceData['grupo_recurso.id'],
					nome_grupo_recurso:
						resourceData['grupo_recurso.nome_grupo_recurso'],
				},
				brandes: BrandData,
				models: ModelData,
				versions: VersionData,
				availableMinutes: AvailabilityMinutesData,
			});
		}
	}, [
		AvailabilityMinutesData,
		BrandData,
		ModelData,
		VersionData,
		resourceData,
	]);

	return isLoadingResource ||
		isLoadingAvailabilityMinutesData ||
		isLoadingBrandData ||
		isLoadingModelData ||
		isLoadingVersionData ||
		!formInitialState ? (
		<LoadingSpinner />
	) : (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarRecurso
					initialState={formInitialState}
					resourceId={resourceId}
					autocompleteAdditionalData={autocompleteAdditionalData}
				/>
			</Card>
		</Flex>
	);
}
