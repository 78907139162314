import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
	ButtonGroup,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconFolder } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';

export function RouterPrompt(props) {
	const {
		isOpen,
		onCloseConfirmSave,
		saveChanges,
		message = 'Deseja salvar suas edições antes de sair?',
		discardChanges = false,
	} = props;

	const history = useHistory();

	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');

	useEffect(() => {
		if (isOpen) {
			history.block(prompt => {
				setCurrentPath(prompt.pathname);
				setShowPrompt(true);
				return 'true';
			});
		} else {
			history.block(() => {});
		}

		return () => {
			history.block(() => {});
		};
	}, [history, isOpen]);

	const handleCancel = useCallback(async () => {
		history.block(() => {});
		history.push(currentPath);
		setShowPrompt(false);
	}, [currentPath, history]);

	const hideModal = useCallback(() => {
		setShowPrompt(false);
	}, []);

	return showPrompt ? (
		<Modal
			isOpen={isOpen}
			onClose={onCloseConfirmSave}
			size='xl'
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalBody>
					<Icon
						as={IconFolder}
						color='easyRED.300'
						w={14}
						h={14}
					></Icon>
					<Heading
						color='gray.700'
						mb={2}
						size='md'
					>
						Atenção
					</Heading>
					<Text color='gray.500'>{message}</Text>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							type={'ghost'}
							title={discardChanges ? 'Cancelar' : 'Não Salvar'}

							onClick={handleCancel}
						/>
						<ButtonComponent
							type={'secondary'}
							title={
								discardChanges
									? 'Sim, retornar as alterações'
									: 'Salvar'
							}
							onClick={discardChanges ? hideModal : saveChanges}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	) : null;
}
