import api from '../../api';
export const getProductionDashboardData = async (params?) => {
	const {
		queryKey: [, othersParams],
	} = params;
	const response = await api.post('/dashboardProducao/dadosDashboard', {
		anos: othersParams['anos'],
		meses: othersParams['meses'],
		dias: othersParams['dias'],
		turnos: othersParams['turnos'],
		tipoParada: othersParams['tipoParada'],
		ids_recursos: othersParams['recursos'],
		ids_familias: othersParams['familias'],
	});
	return response.data;
};

export const getProductionDashboardDataByFamilyStock = async ({ queryKey }) => {
	const [, page, size, familyId] = queryKey;

	const response = await api.get(
		'/dashboardProducao/dadosDashboard/estoqueFamilia',
		{
			params: {
				page,
				size,
				familyId,
			},
		},
	);

	return response.data;
};

export const getProductionDashboardDataByProductStock = async ({
	queryKey,
}) => {
	const [, page, size, productId, familyId, inRateio] = queryKey;

	const response = await api.get(
		'/dashboardProducao/dadosDashboard/estoqueProduto',
		{
			params: {
				page,
				size,
				productId,
				...(!!familyId ? { familyId } : {}),
				...(inRateio !== undefined ? { inRateio } : {}),
			},
		},
	);

	return response.data;
};

export const getProductionDashboardDataByProjectionProductStock = async ({
	queryKey,
}) => {
	const [, page, size, productId, familyId, inRateio] = queryKey;

	const response = await api.get(
		'/dashboardProducao/dadosDashboard/necessidadeEstoque',
		{
			params: {
				page,
				size,
				productId,
				...(!!familyId ? { familyId } : {}),
				...(inRateio !== undefined ? { inRateio } : {}),
			},
		},
	);

	return response.data;
};
