import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createPortfolio } from 'services/api/requests/portfolio';
import { useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export const useCreatePortfolio = (body) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => createPortfolio(body), {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['carteiraPedido-list']);
			history.goBack();
			toast({
				title: 'Criada com sucesso!',
				description: 'Carteira de pedido criada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			if (error.response.data.length > 0) {
				toast({
					title: 'Erro ao criar carteira de pedido!',
					description: error.response.data[0].message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Erro ao criar carteira de pedido!',
					description:
						'Verifique se os campos estão preenchidos corretamente. Todos os campos são de preenchimento obrigatório.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
