import { SelectedFilterBody } from 'components/filter/models/TableFilterModels';
import { InputNames } from '../models/InputsModel';
import { operationMap } from './InputsValidations';

interface SetInputsWithLocalstorage {
	column: string;
	localStorageValue: SelectedFilterBody[] | [];
	name: InputNames;
	setInputValue: any;
	setSelectedOptions?: any;
}

export function setInputsWithLocalstorage({
	column,
	localStorageValue,
	name,
	setInputValue,
	setSelectedOptions,
}: SetInputsWithLocalstorage) {
	if (localStorageValue) {
		const operation = operationMap[name];

		const value = localStorageValue.find((item) => {
			return item.nome_coluna === column && item.operacao === operation;
		});

		if (value) {
			if (setSelectedOptions) {
				setSelectedOptions(value.valores);
			} else {
				setInputValue((prev) => ({
					...prev,
					[name]: value.valores,
				}));
			}
		}
	}
}
