import { aws, IOptionCognitoConfig } from './easy/aws';
import { core, ICore } from './easy/core';
import { indicadores } from './easy/indicadores';
import { Isentry, sentry } from './easy/sentry';
import { biTec, IOptionBiTecConfig } from './external/bitec';
import { procedo } from './external/procedo';
export const environment =
	process.env.REACT_APP_FRONTEND_NODE_ENV ?? 'development';

export interface IEnvironmentEntity {
	development: any;
	staging?: any;
	production: any;
}
export interface IURL {
	url: string;
}
export interface IEnvironmentOption {
	core: ICore;
	procedo: IURL;
	indicadores: IURL;
	sentry: Isentry;
	aws: IOptionCognitoConfig;
	biTec: IOptionBiTecConfig;
}

export const environmentVars: IEnvironmentOption = {
	core: core[environment],
	procedo: procedo[environment],
	indicadores: indicadores[environment],
	sentry: sentry[environment],
	aws: aws[environment],
	biTec: biTec[environment],
};
