import { useQuery } from '@tanstack/react-query';
import {
	getAverageTicketById,
	getTicketMedioById,
} from 'services/api/requests/ticketMedio';

export const useGetTicketMedioById = (
	setForm: any,
	id: number,
	module?: string,
) => {
	return useQuery(['averageTicketById', id], getTicketMedioById, {
		onSuccess: (data) => {
			setForm({
				id: data.empresa_id,
				recalcular: data.recalcular,
				produto: data.produto.nome_produto,
				codProduto: data.produto.codigo_produto,
				ticketMedio: data.recalcular
					? data.ticket_medio_calculado?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL',
					  })
					: data.ticket_medio_editado?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL',
					  }),
				canalVenda: data.canalVenda.nome_canal_venda,
				familia: data.produto.familia.nome_familia,
			});
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
		enabled: module === 'modal' ? false : true,
	});
};

export const useGetAverageTicketWithId = (id: Number) => {
	return useQuery(['averageTicketWithId', id], getAverageTicketById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
