import React from 'react';
import {
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spacer,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MdMemory } from 'react-icons/md';

const AiPrediction = () => {
	const navbarIcon = useColorModeValue('gray.400', 'white');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
	);
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue(
		'#E6ECFA',
		'rgba(135, 140, 189, 0.3)',
	);
	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const handleLastProcessingClick = () => {
		history.push(`/${pathCompanyName}/ai-prediction/logs`);
	};

	return (
		<Menu>
			<TooltipStandard label={'Inteligência Artificial'}>
				<MenuButton
					p='0px'
					color={navbarIcon}
					_hover={{ color: '#314866', cursor: 'pointer' }}
					_active={{ color: '#02044A' }}
				>
					<Box>
						<Icon
							as={MdMemory}
							boxSize={5}
							display={'flex'}
							justifyContent={'center'}
						/>
					</Box>
				</MenuButton>
			</TooltipStandard>
			<MenuList
				boxShadow={shadow}
				p='0px'
				mt='10px'
				borderRadius='20px'
				bg={menuBg}
				border='none'
				w={'22rem'}
			>
				<div
					style={{
						position: 'absolute',
						top: 10,
						right: 0,
						bottom: 0,
						left: 0,
						height: '90%',
						backgroundImage: `url('assets/aiPredictionEasy.png')`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'right center',
						backgroundSize: 'contain',
						opacity: 0.1,
						pointerEvents: 'none',
					}}
				/>
				<Flex
					w='100%'
					mb='0px'
					pt={'2em'}
					marginLeft={'1em'}
					flexDirection={'column'}
					alignItems={'start'}
					position='relative'
				>
					<Text
						display={'flex'}
						flexDirection={'row'}
						textAlign={'center'}
						alignItems={'center'}
						borderColor={borderColor}
						fontSize='md'
						fontWeight='700'
						color={textColor}
						paddingRight={'2em'}
					>
						<Box>
							<Icon
								as={MdMemory}
								boxSize={8}
								display={'flex'}
								justifyContent={'center'}
							/>
						</Box>
						<Spacer mr={'10px'} />
						Inteligência Artificial
					</Text>
				</Flex>
				<Flex
					flexDirection='column'
					p='10px'
				>
					<MenuItem
						_hover={{ bg: 'none' }}
						_focus={{ bg: 'none' }}
						borderRadius='8px'
						px='14px'
						onClick={handleLastProcessingClick}
					>
						<Text
							fontSize='sm'
							color='easyBLUE.300'
							_hover={{ color: 'easyBLUE.200' }}
						>
							Atividades Recentes
						</Text>
					</MenuItem>
				</Flex>
			</MenuList>
		</Menu>
	);
};
export default AiPrediction;

const Box = styled.div`
	position: relative;
`;
