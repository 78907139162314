import { Flex, Icon, Stack } from '@chakra-ui/react';
import { IconEdit } from '@tabler/icons';
import { NavLink } from 'react-router-dom';

export function RowEdit(props) {
	const { linkBtnEdit } = props;

	return (
		<Flex
			cursor='pointer'
			h='max-content'
			w='max-content'
		>
			<Stack
				direction='row'
				alignSelf={{ base: 'unset', md: 'flex-end' }}
				spacing='8px'
			>
				<NavLink to={linkBtnEdit}>
					<Icon
						data-test='link-editar'
						color='secondaryGray.500'
						as={IconEdit}
						w='20px'
						h='20px'
					/>
				</NavLink>
			</Stack>
		</Flex>
	);
}
