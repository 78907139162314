import { useQuery } from '@tanstack/react-query';
import { collaborationConfig } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

interface UseGetCollaborationConfig {
	cycleId: string;
	companyAreaId: string | null;
}
export const useGetCollaborationConfig = ({
	companyAreaId,
	cycleId,
}: UseGetCollaborationConfig) => {
	return useQuery(
		['collaboration-config', cycleId || '', companyAreaId || ''],
		collaborationConfig,
		{
			refetchOnWindowFocus: false,
			enabled: !!companyAreaId,
		},
	);
};
