import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { signUpUser } from 'services/api/requests/authentication';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useSignUpUsers = () => {
	const toast = useToast();
	const queryClient = useQueryClient();
	const history = useHistory();

	return useMutation(signUpUser, {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['users-list']);
			history.goBack();
			toast({
				title: 'Cadastro realizado com sucesso!',
				description:
					'Usuário(a) cadastrado(a) com sucesso. Um e-mail com as informações de acesso foi enviado ao usuário(a).',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar/editar usuário!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
