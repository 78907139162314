import apiPython from '../../apiPython';

export const getAccuracyFilters = async (...queryKey) => {
	const [interval] = queryKey;

	const [year, month] = interval?.split?.('-') || [];

	const response = await apiPython.get('/accuracy/filters', {
		params: {
			year,
			month,
		},
	});
	return response.data;
};

export const getAccuracyData = async (body) => {
	const { interval, ...rest } = body;

	const [year, month] = interval?.split?.('-') || [];

	const response = await apiPython.post('/accuracy/view', {
		...rest,
		year,
		month,
	});

	return response.data;
};

export const getAccuracyProductData = async (body) => {
	const { interval, pageParam = 0, limit, ...rest } = body;

	const [year, month] = interval?.split?.('-') || [];

	const response = await apiPython.post('/accuracy/products', {
		...rest,
		year,
		month,
		page_number: pageParam,
		page_size: limit,
	});

	return response.data;
};

export const getAccuracyDataCommonData = async (...queryKey) => {
	const [uri, interval, demand_type, sales_channel, indicator, page, size] =
		queryKey;

	const [year, month] = interval?.split?.('-') || [];

	const response = await apiPython.get(`/accuracy/${uri}`, {
		params: {
			year,
			month,
			demand_type,
			sales_channel,
			indicator,
			page_size: size,
			page_number: page,
		},
	});

	return response.data;
};
