import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

// Não tive como tipar o chartData e o chartOptions, pois o objeto

interface ChartOptionsProps {
	[key: string]: any;
}
interface LineBarChartProps {
	chartData: [] | ApexOptions['series'];
	chartOptions: {} | ChartOptionsProps | ApexOptions;
}

export const LineBarChart = (props: LineBarChartProps) => {
	const [chartData, setChartData] = useState<[] | ApexOptions['series']>([]);
	const [chartOptions, setChartOptions] = useState<
		{} | ApexOptions | ChartOptionsProps
	>({});

	useEffect(() => {
		setChartData(props.chartData);
		setChartOptions(props.chartOptions);
	}, [props.chartData, props.chartOptions]);

	return (
		<Chart
			options={chartOptions}
			series={chartData}
			type='line'
			width='100%'
			height='300px'
		/>
	);
};
