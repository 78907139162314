import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';
import api from '../api';

export const createMember = async (body) => {
	const response = await api.post('/membro', body);
	return response.data;
};

export const deleteMember = async (id: number) => {
	const response = await api.delete(`membro?id=${id}`);
	return response.data;
};

export const fetchMembersByFilters = async (body) => {
	const requestBody = {
		paginacao: {
			page: body.page,
			size: body.limit || PAGINATION_SIZE_LIST[0],
			order: [],
		},
		filtros: body.filters,
	};

	let url = `/membro/view`;

	const response = await api.post(url, requestBody);
	return response?.data;
};

export const deleteMemberByFilters = async (filters: any) => {
	const response = await api.post('membro/deletarPorFiltros', filters);
	return response.data;
};
