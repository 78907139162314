import { Flex, Spinner } from '@chakra-ui/react';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';

export const LoadingSpinner = ({
	message = 'Carregando dados...',
	size = 'lg',
	speed = '0.65s',
	emptyColor = 'gray.200',
	color = 'blue.500',
	label = '',
	...props
}: any) => {
	return (
		<Flex
			w='100%'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			{...props}
		>
			<TooltipStandard label={label}>
				<Spinner
					thickness='4px'
					speed={speed}
					emptyColor={emptyColor}
					color={color}
					size={size}
				/>
			</TooltipStandard>
			<p>{message}</p>
		</Flex>
	);
};
