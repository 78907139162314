import api from '../api';

import { stopTypology } from 'models/stop-topology.model';

export const getStopTypology = async () => {
	const response = await api.get('/tipologiaParadas');
	return response.data;
};

export const createStopTopology = async (body: stopTypology) => {
	const response = await api.post('/tipologiaParadas', body);
	return response.data;
};

export const getStopTypologyById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const response = await api.get(
		`tipologiaParadas/tipologiaParadasById?id=${id}`,
	);
	return response.data;
};

export const editStopTopology = async (body: stopTypology, id: number) => {
	const url = `/tipologiaParadas?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deleteStopTopology = async (id: number) => {
	const response = await api.delete(`tipologiaParadas?id=${id}`);
	return response.data;
};

export const deleteStopTypologyByFilters = async (filters: any) => {
	const response = await api.post(
		'tipologiaParadas/deletarPorFiltros',
		filters,
	);
	return response.data;
};
