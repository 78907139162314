import { useQuery } from '@tanstack/react-query';
import { existsProductionRoadmapProducts } from 'services/api/requests/roadmap';

export const useExistsRoadmapProduction = () => {
	return useQuery(
		['roadmap-production-products-exists'],
		existsProductionRoadmapProducts,
		{
			refetchOnWindowFocus: false,
		},
	);
};
