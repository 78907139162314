import {
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Tfoot,
	Box,
	Text,
} from '@chakra-ui/react';
import {
	CellClientInputHover,
	CellText,
	backgroundColors,
} from './StatisticTable';

const TableFixedHeader = ({ columns, data, dataTest, ...props }) => {
	const { hasTotalRows } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	function renderHeaders() {
		return columns?.map((el) => {
			return (
				<Th
					key={el.accessor}
					bg={backgroundColors[el?.accessor]}
					textAlign='center'
				>
					<Text
						fontSize='smaller'
						fontWeight={500}
						color='secondaryGray.500'
					>
						{el.Header}
					</Text>
				</Th>
			);
		});
	}

	function renderRows() {
		const rows = hasTotalRows ? data.slice(0, data.length - 1) : data;

		return rows?.map((row, index) => {
			return (
				<Tr
					_hover={{
						backgroundColor: '#f4f7fe !important',
						color: '#42adef !important',
					}}
					data-test={`row-${index}`}
				>
					{columns?.map((el, columnIndex) => {
						const cell = row?.[el?.accessor];

						const renderAsHover =
							!!cell?.clientes || cell?.renderAsHover;

						let content: any = null;

						if (renderAsHover) {
							content = CellClientInputHover(cell, textColor);
						} else {
							content = (
								<CellText
									cell={cell}
									column={undefined}
								/>
							);
						}

						return (
							<Td
								key={`${el.accessor}-${columnIndex}`}
								bg={backgroundColors[el?.accessor]}
							>
								{content}
							</Td>
						);
					})}
				</Tr>
			);
		});
	}

	function renderFooter() {
		if (!hasTotalRows) return null;

		const footer = data[data.length - 1];

		return (
			<Tr bg='#e0e5f2'>
				{columns?.map((el, index) => {
					const cell = footer?.[el?.accessor];

					return (
						<Td key={`${el.accessor}-${index}`}>
							<CellText
								cell={cell}
								column={undefined}
							/>
						</Td>
					);
				})}
			</Tr>
		);
	}

	return (
		<Box
			overflowX='auto'
			overflowY='auto'
			maxHeight='600px'
			css={{
				'&::-webkit-scrollbar-track': {
					'box-shadow': 'inset 0 0 2px grey',
					'border-radius': '10px',
				},
				'&::-webkit-scrollbar-thumb': {
					background: 'rgb(194, 201, 225)',
					'border-radius': '10px',
				},
				'&::-webkit-scrollbar-thumb:hover': {
					background: 'rgb(177, 187, 221)',
				},
				'&::-webkit-scrollbar': {
					width: '8px',
					height: '8px',
				},
			}}
		>
			<Table
				variant='unstyled'
				boxShadow='1px 1px 1px 1.25px #ededed'
				borderRadius='md'
				rounded='md'
				size='sm'
				style={{
					borderCollapse: 'separate',
					borderSpacing: '0px 2.5px',
				}}
				data-test={dataTest}
			>
				<Thead
					position='sticky'
					top={0}
					bg='#f4f7fe'
				>
					{renderHeaders()}
				</Thead>
				<Tbody>{renderRows()}</Tbody>
				<Tfoot>{renderFooter()}</Tfoot>
			</Table>
		</Box>
	);
};

export default TableFixedHeader;
