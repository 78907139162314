import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';
import { useCallback, useEffect, useState } from 'react';
import {
	defaultModalFormStyle,
	formFlexProps,
	rowFlexProps,
} from 'utils/forms/defaultsProps';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useQueryClient } from '@tanstack/react-query';

import {
	buildCheckTableEditModalFormInputFields,
	buildCheckTableEditModalHeaders,
	buildCheckTableEditModalInitialState,
	GetRequest,
	GetMutate,
} from 'pages/admin/cadastros/comercial/mercado/functions/util';

const CheckTableEditModal = (props) => {
	const { filterModule, isOpen, onClose, idEdit, queriesToInvalidadeOnEdit } =
		props;

	const queryClient = useQueryClient();

	const [formInitialState, setFormInitialState] = useState<any>({});
	const [payload, setPayload] = useState<any>();

	const { data } = GetRequest(filterModule)(idEdit);
	const { mutate } = GetMutate(filterModule)(idEdit, payload, onClose);

	const getInitialState = useCallback(() => {
		return buildCheckTableEditModalInitialState(filterModule, data);
	}, [filterModule, data]);

	const buildHeader = useCallback(() => {
		return buildCheckTableEditModalHeaders(filterModule);
	}, [filterModule]);

	const getFormInputs = useCallback(() => {
		return buildCheckTableEditModalFormInputFields(filterModule);
	}, [filterModule]);

	const renderRows = useCallback((): IFormRows[] => {
		return (
			getFormInputs()?.map((input: any) => ({
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure(input),
					},
				],
			})) || []
		);
	}, [getFormInputs]);

	const onSubmit = useCallback(
		(values) => {
			setPayload(values);

			setTimeout(() => mutate(), 250);

			setTimeout(() => {
				if (!!queriesToInvalidadeOnEdit?.length) {
					queriesToInvalidadeOnEdit?.forEach((item) => {
						if (Array.isArray(item))
							queryClient.invalidateQueries(item);
						else queryClient.invalidateQueries([item]);
					});
				}
			}, 750);
		},
		[mutate, queriesToInvalidadeOnEdit, queryClient],
	);

	useEffect(() => {
		setFormInitialState(getInitialState());
	}, [getInitialState]);

	const renderForm = useCallback(
		() => (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				onEsc={onClose}
				size={'lg'}
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader color='gray.700'>{buildHeader()}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<GenericForm
							initialState={formInitialState}
							formFlexProps={formFlexProps}
							rows={renderRows()}
							style={defaultModalFormStyle}
							onSubmit={onSubmit}
							onCancel={onClose}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		),
		[buildHeader, formInitialState, isOpen, onClose, onSubmit, renderRows],
	);

	return renderForm();
};

export default CheckTableEditModal;
