import { ProductException } from 'pages/admin/demanda/consenso/context';

export interface IAutocomplete {
	busca?: string;
	limit?: number;
	orderBy?: string;
	sort?: string;
	[key: string]: any;
}

export function renderClient(client: any) {
	const { documento = '', razao_social = '' } = client;

	return `${documento} - ${razao_social}`;
}

export function renderFamily(family: any) {
	const { nome_familia = '' } = family;

	return nome_familia;
}

export function renderSubFamily(sub_family: any) {
	const { nome_sub_familia = '' } = sub_family;

	return nome_sub_familia;
}

export function renderProductModel(product_model: any) {
	const { nome_modelo = '' } = product_model;

	return nome_modelo;
}

export function renderProduct(
	product:
		| {
				codigo_produto?: string | number;
				nome_produto?: string | number;
		  }
		| ProductException,
) {
	if (!product) return '';
	const { codigo_produto = '', nome_produto = '' } = product;

	return `${codigo_produto} - ${nome_produto}`;
}

export function renderResourceGroup(resourceGroup: any) {
	const { nome_grupo_recurso = '' } = resourceGroup;

	return nome_grupo_recurso;
}

export function renderBrand(brand: any) {
	const { nome_marca = '' } = brand;

	return nome_marca;
}

export function filterBrand(value: any, search: string) {
	return value?.nome_marca?.toLowerCase()?.includes(search?.toLowerCase());
}

export function renderModel(model: any) {
	const { nome_modelo = '' } = model;

	return nome_modelo;
}

export function filterModel(value: any, search: string) {
	return value?.nome_modelo?.toLowerCase()?.includes(search?.toLowerCase());
}

export function renderVersion(version: any) {
	const { nome_versao = '' } = version;

	return nome_versao;
}

export function filterVersion(value: any, search: string) {
	return value?.nome_versao?.toLowerCase()?.includes(search?.toLowerCase());
}

export function renderCity(city: any) {
	const { nome = '' } = city || {};

	return nome;
}

export function renderMember(member: any) {
	const { nome_membro = '' } = member;

	return nome_membro;
}
