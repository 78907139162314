import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { saveProjection } from 'services/api/requests/projection';

export const useCreateProjection = (body) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => saveProjection(body), {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['projectionByDate']);
			toast({
				title: 'Salvo com sucesso!',
				description: 'Cadência de vendas salva com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			console.log(error.response.data.message);
			toast({
				title: 'Erro ao salvar cadência de vendas!',
				description:
					'Verifique se os campos estão preenchidos corretamente. Todos os campos são de preenchimento obrigatório.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
