import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getForecastById } from 'services/api/requests/forecast';

export const useGetForecastById = (setForm: any, id: number) => {
	return useQuery(['forecastById', id], getForecastById, {
		onSuccess: (data) => {
			setForm({
				competencia: moment(data.data, 'YYYY-MM-DD').format(
					'YYYY-MM-DD',
				),
				canalVenda: data.canalVenda.id,
				cliente: data.cliente?.id ? data.cliente?.id : '',
				receitaBruta: data.receita_bruta.toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				}),
			});
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};

export const useGetForecastWithId = (id: Number) => {
	return useQuery(['forecastWithId', id], getForecastById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
