import { PlacementWithLogical } from '@chakra-ui/react';

export const formFlexProps = {
	flexDirection: 'column',
};

export const rowFlexProps = {
	flexDirection: 'row',
};

export const columnFlexProps = {
	flexDirection: 'column',
	w: '100%',
};

export const formControlProps = {
	padding: '10px 0',
};

export const defaultInputStyle = {
	borderRadius: '10px',
};

export const defaultFormStyle = {
	padding: '20px 20px 30px 20px',
};

export const defaultModalFormStyle = {
	padding: '0 0 15px 0px',
};

interface ITooltipProps {
	placement: PlacementWithLogical | undefined;
	[key: string]: any;
}

export const defaultTooltipProps: ITooltipProps = {
	placement: 'top',
	bg: 'gray.200',
	color: 'blue.800',
	hasArrow: true,
	arrowSize: 15,
};
