import { useQuery } from '@tanstack/react-query';
import { fetchHourlyByFilters } from 'services/api/requests/lancamentoHoraHora/hourly';

export const useGetHourlyWithFilters = (variables: any) => {
	return useQuery(
		['hourly-list', variables],
		() => {
			const requestBody = {
				page: variables.page,
				size: variables.size,
				filters: variables.filters,
			};

			return fetchHourlyByFilters(requestBody);
		},

		{
			onError: (error) => {
				console.log('error', error);
			},
			refetchOnWindowFocus: false,
			retry: false,
		},
	);
};
