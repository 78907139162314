import api from '../api';

export const getSalesForceById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get('forcaVenda/forcaVendaById', {
		params: { id },
	});
	return response.data;
};

export const updateSalesForce = async (id: number, body) => {
	const response = await api.patch('forcaVenda', body, {
		params: { id },
	});
	return response.data;
};

export const deleteSalesForceByFilters = async (filters: any) => {
	const response = await api.post('forcaVenda/deletarPorFiltros', filters);
	return response.data;
};

export const deleteSalesForce = async (id: number) => {
	const response = await api.delete('forcaVenda', { params: { id } });
	return response.data;
};
