import { useQuery } from '@tanstack/react-query';
import { getFamiliesWithProducts } from 'services/api/requests/requests';

export const useFamilyListWithProducts = (
	produtos_status?: boolean,
	produtos_participa_rateio?: boolean,
) => {
	return useQuery(
		[
			'family-list-with-products',
			produtos_status,
			produtos_participa_rateio,
		],
		getFamiliesWithProducts,
		{
			refetchOnWindowFocus: false,
		},
	);
};
