
// Chakra imports
import { Box, Flex, Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";
import CircularProgress from "components/charts/CircularProgress";
import { VSeparator } from "components/separator/Separator";

// Custom components
import { ButtonComponent } from "components/buttons/ButtonComponent";
import Card from "components/card/Card.js";

export default function CicloDoPlano(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Text
        color={textColor}
        fontSize='3xl'
        fontWeight='700'
        mb='10px'
        mx='auto'
        textAlign={'center'}>
        Ciclo do Plano
      </Text>
      <Text
        color='secondaryGray.600'
        fontSize='lg'
        fontWeight='500'
        maxW='350px'
        mx='auto'
        mb='10px'>
        Faltam 12 dias para o fim do plano grátis
      </Text>
      <Flex
        justifyContent='center'
        alignItems='center'
        w='100%'
        px='10px'
        mb='8px'></Flex>
      <Box w='140px' mx='auto' mb='10px' mt='10px'>
        <CircularProgress title='Foi Utilizado' percentage='68' color="#F0243E"/>
      </Box>
      <Grid bg={cardColor}  p='15px' px='20px' mt='auto' templateColumns={'repeat(5, 2fr)'} >
        <GridItem as={Flex} direction='column' py='5px' textAlign={'left'} colSpan={2}>
          <Text
            fontSize='xs'
            color='secondaryGray.600'
            fontWeight='700'
            mb='5px'>
           Acaba em
          </Text>
          <Text fontSize='xs' color={textColor} fontWeight='700'>
            30 de Outubro
          </Text>
        </GridItem>
        <VSeparator margin="0 auto" colSpan={1} />
        <GridItem as={Flex} colSpan={2} direction='column' py='5px'>
         <ButtonComponent type="primary" title="Fatura" fontSize="sm"></ButtonComponent>
        </GridItem>
      </Grid>
    </Card>
  );
}
