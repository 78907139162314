import { useQuery } from '@tanstack/react-query';
import { getDownloadTemplateStock } from 'services/api/requests/uploads/uploads';

export const useDownloadTemplateActualStock = () => {
	return useQuery(
		['download-template-actual-stock'],
		getDownloadTemplateStock,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		},
	);
};
