import { currencyToNumber } from 'utils/functions/currencyToNumber';
import {
	defaultFormStyle,
	defaultInputStyle,
} from '../../utils/forms/defaultsProps';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { renderFamily } from 'utils/forms/autocomplete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateBudget } from 'services/queryClient/wrapperHooks/useCreateBudget';
import { useEditBudget } from 'services/queryClient/wrapperHooks/useEditBudget';
import { useHistory } from 'react-router-dom';
import {
	validateMinDecimal,
	validateRequired,
} from '../../utils/forms/validations';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';

interface IFormCadastrarEditarOrcamento {
	initialState: any;
	budgetId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarOrcamento = ({
	initialState,
	budgetId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarOrcamento) => {
	const history = useHistory();

	const isEditing = !!budgetId;

	const { data: SalesChannelData, isLoading: isLoadingSalesChannelData } =
		useSalesChannelList();

	const [formInitialState, setFormInitialState] = useState<any>();

	const [payload, setPayload] = useState<any>();

	const { mutate: createBudget } = useCreateBudget(payload);
	const { mutate: updateBudget } = useEditBudget(payload, budgetId);

	useEffect(() => {
		setFormInitialState(initialState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialState]);

	const goToBudgets = useCallback(() => {
		history.goBack();
	}, [history]);

	const salesChannelOptions = useMemo(() => {
		return (
			SalesChannelData?.map((channel) => {
				return (
					<option value={channel?.id}>
						{channel?.nome_canal_venda}
					</option>
				);
			}) || []
		);
	}, [SalesChannelData]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				mes_ano: values?.mes_ano,
				canal_venda_id: Number(values?.canal_venda_id),
				familia_id: values?.familia_id,
				quantidade: currencyToNumber(values?.quantidade),
				receita_bruta: currencyToNumber(values?.receita_bruta),
			};

			setPayload(body);

			if (isEditing) updateBudget();
			else createBudget();
		},
		[createBudget, isEditing, updateBudget],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'month',
							name: 'mes_ano',
							label: 'Competência',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-competencia-cadastrar_editar-page-orcamento',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda_id',
							label: 'Canal de venda',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-orcamento',
								...defaultInputStyle,
								selectOptions: salesChannelOptions,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-orcamento',
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'product-quantity',
							name: 'quantidade',
							label: 'Quantidade',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								validate: (value) =>
									validateMinDecimal(
										value,
										0,
										'O valor deve ser maior ou igual a 0!',
									),
							},
							inputProps: {
								'data-test':
									'input-quantidade-cadastrar_editar-page-orcamento',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'receita_bruta',
							label: 'Receita bruta',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-receita_bruta-cadastrar_editar-page-orcamento',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, [autocompleteAdditionalData, salesChannelOptions]);

	const renderForm = useCallback(() => {
		if (isLoadingSalesChannelData) return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-orcamento',
						save: 'button-salvar-page-orcamento',
					}}
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToBudgets}
				/>
			</>
		);
	}, [
		isLoadingSalesChannelData,
		formInitialState,
		renderRows,
		onSubmit,
		goToBudgets,
	]);

	return renderForm();
};

export default FormCadastrarEditarOrcamento;
