import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useToast } from '@chakra-ui/react';
import { editTicketMedio } from "services/api/requests/ticketMedio";

export const useEditTicketMedio = (body, id, edit?) => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const toast = useToast();

    return useMutation(() => editTicketMedio(body, id), {        
        onSuccess: (success) => {
            queryClient.invalidateQueries(["ticketMedio-list"]);
            edit && history.goBack();
            toast({
                title: 'Atualizado com sucesso!',
                description: "Preço Médio atualizado com sucesso.",
                position: 'bottom-left',
                status: 'success',
                duration: 8000,
                isClosable: true,
            })
        },
        onError: (error: any, variables, context) => {
            toast({
                title: 'Erro ao atualizar Preço Médio!',
                description: error.response.data.message[0].constraints.isNotEmpty,
                position: 'bottom-left',
                status: 'error',
                duration: 8000,
                isClosable: true,
            })
        },
    })
}