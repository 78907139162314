import { Flex, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import MiniStatistics from '../../../../components/card/MiniStatistics';
import IconBox from '../../../../components/icons/IconBox';
import { IconChartBar } from '@tabler/icons';
import { useMemo } from 'react';
import { numberToLocaleString } from 'utils/functions/number';
import { useAcuracidade } from './context';
import { AlertComponent } from 'components/alerts/AlertComponent';
import TableInifiteScroll from './tableInifiteScroll';

const AbaControleAcuracidade = () => {
	const {
		canShowAddtionalInfo,
		totalProductsWithoutFamily,
		accuracyDataControl: dataControl,
		accuracyDataTable: dataTable,
	} = useAcuracidade();

	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const headers = useMemo(
		() => [
			{
				key: 'descricao',
				label: 'Família - Produto (SKU)',
			},
			{
				key: 'previsto',
				label: 'Previsto (QTD)',
			},
			{
				key: 'realizado',
				label: 'Realizado (QTD)',
			},
			{
				key: 'custo_produto',
				label: 'Custo (R$)',
				prefixo: 'R$ ',
				canTruncValue: true,
				noDataHelp: 'Não existe custo cadastrado.',
			},
			{
				key: 'custo_previsto',
				label: 'Custo Previsto (R$)',
				prefixo: 'R$ ',
				canTruncValue: true,
			},
			{
				key: 'ticket_produto',
				label: 'Preço Médio (R$)',
				prefixo: 'R$ ',
				canTruncValue: true,
				noDataHelp: 'Não existe preço médio cadastrado.',
			},
			{
				key: 'ticket_medio_previsto',
				label: 'Receita Bruta Prevista (R$)',
				prefixo: 'R$ ',
				canTruncValue: true,
			},
			{
				key: 'erro',
				label: 'Erro (QTD)',
			},
			{
				key: 'erro_operacional',
				label: 'Erro Operacional (R$)',
				prefixo: 'R$ ',
				canTruncValue: true,
				noDataHelp: 'Erro operacional requer dado de custo cadastrado.',
			},
			{
				key: 'erro_comercial',
				label: 'Erro Comercial (R$)',
				prefixo: 'R$ ',
				canTruncValue: true,
				noDataHelp:
					'Erro comercial requer dado de preço médio cadastrado.',
			},
			{
				key: 'mape',
				label: 'MAPE (%)',
				sufixo: ' %',
				decimalPrecision: 1,
			},
			{
				key: 'peso',
				label: 'PESO (QTD)',
			},
			{
				key: 'wmape',
				label: 'WMAPE (%)',
				sufixo: ' %',
				decimalPrecision: 1,
			},
		],
		[],
	);

	function renderAddtionalInfo(label: string, type: string, value: number) {
		const renderedValue = ''
			.concat(label !== 'MAPE' ? 'R$ ' : '')
			.concat(Math.trunc(Number(value))?.toLocaleString('pt-BR'));

		return (
			<Flex
				direction='row'
				gap={1}
				alignItems='center'
				justifyContent='center'
			>
				<Flex
					color={type === 'aumento_estoque' ? 'orange' : 'red'}
					// float='right'
					// w='50%'
				>
					{renderedValue}
				</Flex>
				<Flex>
					{type === 'aumento_estoque'
						? ' aumento de estoque'
						: ' redução de estoque'}
				</Flex>
			</Flex>
		);
	}

	function renderCard(
		label: string,
		value: string | number,
		textHelp: string,
		icon: any,
		lackStock: number,
		surplusStock: number,
	) {
		function hasValue(value: any) {
			return value !== undefined && value !== null;
		}

		const additionalInfoData = (
			<>
				{hasValue(lackStock)
					? renderAddtionalInfo(
							label,
							'aumento_estoque',
							surplusStock,
					  )
					: null}
				{hasValue(surplusStock)
					? renderAddtionalInfo(label, 'reducao_estoque', lackStock)
					: null}
			</>
		);

		const noData = canShowAddtionalInfo ? <></> : null;

		return (
			<MiniStatistics
				startContent={
					<IconBox
						w='56px'
						h='56px'
						bg={boxBg}
						icon={
							<Icon
								w='32px'
								h='32px'
								as={icon}
								color={'easyRED.300'}
							/>
						}
					/>
				}
				name={label}
				textHelp={textHelp}
				value={value}
				additionalInfo={
					canShowAddtionalInfo && label !== 'WMAPE'
						? additionalInfoData
						: noData
				}
			/>
		);
	}

	function renderMiniStatistics() {
		return (
			<SimpleGrid
				columns={4}
				row={1}
				spacingX={2}
				width={{ base: '100%', md: '100%', xl: '100%' }}
			>
				{renderCard(
					'MAPE',
					`${numberToLocaleString({
						value: dataControl?.mape?.perc || 0,
						maximumFractionDigits: 1,
					})}%`,
					`O MAPE avalia a precisão de uma previsão comparando os valores previstos com os valores reais, expressando o
					erro como uma porcentagem da demanda real. Varia de 0% a 100%. Quanto mais próximo de 100% estiver o indicador,
					melhor será a acuracidade. É calculado somando os desvios entre realizado vs previsto de todos os produtos e
					dividido pelo realizado total, considerando apenas os volumes de produtos.`,
					IconChartBar,
					dataControl?.mape?.reducao_estoque || 0,
					dataControl?.mape?.aumento_estoque || 0,
				)}
				{renderCard(
					'WMAPE',
					`${numberToLocaleString({
						value: dataControl?.wmape?.perc || 0,
						maximumFractionDigits: 1,
					})}%`,
					`O WMAPE é uma variação do MAPE, atribuindo pesos de acordo com os erros de previsão dos produtos. Varia de 0% a 100%.
					Quanto mais próximo de 100% estiver o indicador, melhor será a acuracidade. É calculado somando os desvios entre
					realizado vs previsto de todos os produtos e dividido pelo realizado total, considerando o peso de cada produto.`,
					IconChartBar,
					dataControl?.wmape?.reducao_estoque || 0,
					dataControl?.wmape?.aumento_estoque || 0,
				)}
				{renderCard(
					'Acuracidade Comercial',
					`${numberToLocaleString({
						value: dataControl?.acuracidade_comercial?.perc || 0,
						maximumFractionDigits: 1,
					})}%`,
					`Corresponde à soma das variações entre o realizado vs previsto dos produtos, multiplicado pelo preço médio do canal de
					venda selecionado. Este indicador tem como finalidade mostrar o impacto que os erros das previsões de vendas têm na receita final.`,
					IconChartBar,
					dataControl?.acuracidade_comercial?.reducao_estoque || 0,
					dataControl?.acuracidade_comercial?.aumento_estoque || 0,
				)}
				{renderCard(
					'Acuracidade Operacional',
					`${numberToLocaleString({
						value: dataControl?.acuracidade_operacional?.perc || 0,
						maximumFractionDigits: 1,
					})}%`,
					`Corresponde à soma das variações entre o realizado vs previsto dos produtos, multiplicado pelo custo do produto.
					Este indicador tem como finalidade mostrar o impacto que os erros das previsões de vendas têm no custo operacional final.`,
					IconChartBar,
					dataControl?.acuracidade_operacional?.reducao_estoque || 0,
					dataControl?.acuracidade_operacional?.aumento_estoque || 0,
				)}
			</SimpleGrid>
		);
	}

	function renderAlertProductsWithoutFamily() {
		return totalProductsWithoutFamily?.totalProdutos > 0 ? (
			<Flex pt='20px'>
				<AlertComponent
					title='Observação!'
					description={`Existem ${
						totalProductsWithoutFamily?.totalProdutos || 0
					} produtos com vendas para o mês/ano selecionado,
						os quais não possuem família. Logo, estes itens não participam da acuracidade. Associe os produtos em
						suas respectivas famílias.`}
					status='info'
					hasCloseButton
				/>
			</Flex>
		) : null;
	}

	return (
		<>
			{renderMiniStatistics()}
			{renderAlertProductsWithoutFamily()}
			<TableInifiteScroll
				headers={headers}
				rows={dataTable?.dados}
				filterMap={dataTable?.mapa_filtro}
			/>
		</>
	);
};

export default AbaControleAcuracidade;
