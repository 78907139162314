import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { resource } from 'models/resource';
import { useToast } from '@chakra-ui/react';
import { createResource } from 'services/api/requests/resources';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useCreateResource = (body: resource) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => createResource(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['resource']);
			history.goBack();
			toast({
				title: 'Cadastro com sucesso!',
				description: 'Recurso criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			if (
				error?.response?.data &&
				Array.isArray(error.response.data) &&
				error.response.data.length
			) {
				error.response.data.forEach((e) => {
					toast({
						title: 'Erro ao cadastrar recurso!',
						description:
							e?.details?.message ??
							`Ocorreu um erro inesperado ao cadastrar o recurso ${body?.descricao}!`,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				});
			} else {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao cadastrar recurso!',
				);
				toastErrors?.forEach((element) => toast(element));
			}
		},
	});
};
