import { IEnvironmentEntity } from '../environment';
export interface ICore {
	api: string;
	alb: string;
}
export const core: IEnvironmentEntity = {
	development: {
		api: process.env.REACT_APP_FRONTEND_BACKEND_URL_DEVELOPMENT,
		alb: process.env.REACT_APP_FRONTEND_BACKEND_URL_DEVELOPMENT,
	},
	staging: {
		api: process.env.REACT_APP_FRONTEND_BACKEND_API_STAGING,
		alb: process.env.REACT_APP_FRONTEND_BACKEND_ALB_STAGING,
	},
	production: {
		api: process.env.REACT_APP_FRONTEND_BACKEND_API_PRODUCTION,
		alb: process.env.REACT_APP_FRONTEND_BACKEND_ALB_PRODUCTION,
	},
};
