import { useMutation } from '@tanstack/react-query';
import { IAutocomplete } from '../../../utils/forms/autocomplete';
import { getMemberByFilters } from 'services/api/requests/resources';

export const useMemberGroupListAsMutate = ({
	busca,
	limit,
	orderBy = 'nome_membro',
	sort = 'ASC',
}: IAutocomplete) => {
	return useMutation(() =>
		getMemberByFilters({ busca, limit, orderBy, sort }),
	);
};
