import { Table, Thead, Tbody, Tr, Th, Td, Icon } from '@chakra-ui/react';

import { IconTrash } from '@tabler/icons';

import './styles.css';
import { OpenDeleteModal, THead } from './VisualizarLancamentoModal';
import moment from 'moment';
import { Break } from 'models/time-form.model';

interface Props {
	parada: Break;
	openDeleteModal: OpenDeleteModal;
}

export const TabelaParada: React.FC<Props> = (props) => {
	const { parada, openDeleteModal } = props;

	const dateTimeIsoPattern = 'YYYY-MM-DDTHH:mm:ss.ZZZ';

	const thead = [
		{
			title: 'H. Inicial',
		},
		{
			title: 'H. Final',
		},
		{
			title: 'Tempo de Parada',
		},
		{
			title: 'Tipologia',
		},
		{
			title: 'Observações',
			colSpan: 11,
		},
	] as THead[];

	return (
		<Table
			whiteSpace='nowrap'
			variant='unstyled'
			key={parada.id}
		>
			<Thead
				marginBottom={0}
				borderBottomWidth={1}
				borderBottomColor={'secondaryGray.200'}
			>
				<Tr color='secondaryGray.600'>
					{thead.map((th) => (
						<Th
							key={th.title}
							colSpan={th?.colSpan}
						>
							{th.title}
						</Th>
					))}
					<Th />
				</Tr>
			</Thead>
			<Tbody>
				<Tr color='easyBLUE.300'>
					<Td>
						{moment(parada.hora_inicio, dateTimeIsoPattern).format(
							'HH:mm',
						)}
					</Td>
					<Td>
						{moment(parada.hora_fim, dateTimeIsoPattern).format(
							'HH:mm',
						)}
					</Td>
					<Td>
						{moment()
							.startOf('day')
							.add(parada.tempo_de_parada, 'seconds')
							.format('HH:mm')}
					</Td>
					<Td className='white-space width-300'>
						{parada.tipologiaParada.tipologia_parada}
					</Td>
					<Td
						className='white-space'
						colSpan={11}
					>
						{parada.ata}
					</Td>
					<Td textAlign='right'>
						<Icon
							as={IconTrash}
							color={'secondaryGray.500'}
							h={'20px'}
							w={'20px'}
							cursor='pointer'
							onClick={() => openDeleteModal('break', parada.id)}
						/>
					</Td>
				</Tr>
			</Tbody>
		</Table>
	);
};
