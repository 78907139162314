import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarProduto from '../../../../../components/forms/FormCadastrarEditarProduto';

export default function CadastrarProdutos() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastroProdutos = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Produtos',
			firstParagraph:
				'Para cadastrar um novo produto, selecione se ele participa do rateio, entre com o nome do Produto, Código do produto, Família e Subfamília.',
			secondParagraph:
				'Para cadastrar um produto em uma nova família, clique no campo de família e no botão Adicionar.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroProdutos);
	}, [helpCadastroProdutos, setDataSidebar]);

	const formInitialState = {
		nome_produto: '',
		codigo_produto: '',
		custo: '',
		familia_id: '',
		sub_familia_id: '',
		status: true,
		rateio: true,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarProduto initialState={formInitialState} />
			</Card>
		</Flex>
	);
}
