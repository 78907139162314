import { Flex, Icon, Stack, useDisclosure } from "@chakra-ui/react";
import { IconTrash } from "@tabler/icons";
import { DeleteModal } from "components/modal/DeleteModal";

export function DeleteIconColumn(props) {
    const { dataDelete, idDelete } = props;

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure()
    return <Flex cursor='pointer' h='max-content' w='max-content'>
        <Stack
            direction='row'
            alignSelf={{ base: "unset", md: "flex-end" }}
            spacing='8px'>

            <Icon
                color='secondaryGray.500'
                as={IconTrash}
                w='20px'
                h='20px'
                onClick={onOpenDelete}
            />

        </Stack>

        <DeleteModal isOpen={isOpenDelete} onClose={onCloseDelete} idDelete={idDelete} dataDelete={dataDelete} />
    </Flex>
}