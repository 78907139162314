import { Icon, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import { IconArrowDown, IconArrowUp } from "@tabler/icons";

export const CellCrescimento = ({ cell }): JSX.Element => {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const value = Number(cell)
    return <Text
        paddingLeft="8px"
        marginRight={0}
        alignItems="center"
        display={"flex"}
        color={textColor} fontSize='sm' fontWeight='700'>
        {cell && value ?
            <>
                {value.toFixed(2).replace('.', ',')} %
                {
                    value > 0 && <Icon
                        color='#01B574'
                        as={IconArrowUp}
                        w='14px'
                        h='14px' />
                }
                {
                    value < 0 && <Icon
                        color='#B01A2E'
                        as={IconArrowDown}
                        w='14px'
                        h='14px' />
                }
            </>
            :
            "-"
        }
    </Text>
}