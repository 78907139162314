import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface DonutChartProps {
	chartData: any;
	chartOptions: any;
}

interface chartData {
	name: string;
	data: (number | null)[];
}

const DonutChart = (props: DonutChartProps) => {
	const [chartData, setChartData] = useState<chartData[]>([]);
	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		setChartData(props.chartData);
		setChartOptions(props.chartOptions);
	}, [props]);

	return (
		<ReactApexChart
			options={chartOptions}
			series={chartData}
			type='donut'
			width='100%'
			height='300px'
		/>
	);
};

export default DonutChart;
