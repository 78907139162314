import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	salesDashboardClientsData,
	salesDashboardData,
	salesDashboardDataTable,
} from 'services/api/requests/dashboardVendas/salesDashboard';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useGetSalesDashboardAta = (startDate: string, endDate: string) => {
	const toast = useToast();

	/**
	 * Alterado para Mutation pois o useQuery antiga já estava desabilitada e não ficava armazenada em cache.
	 * Ocorria um bug ao usar useQuery que ao alterar os valores dos inputs de filtro, atualiza o atributo
	 *  data para undefined, o que ocasionava no ocultação de informações da tela, antes que a ação do filtro fosse acionado.
	 */
	return useQuery(['atas', startDate, endDate, 'ata'], salesDashboardData, {
		onError(error: any) {
			toast({
				title: 'Erro ao carregar os dados do dashboard!',
				description: error?.response?.data[0]?.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};

export const useGetSalesDashboardEstatisticas = (
	path: string,
	startDate: string,
	endDate: string,
	dashboardDataType: string,
	page?: number,
	limit?: number,
) => {
	const toast = useToast();
	return useMutation(
		() =>
			salesDashboardDataTable(
				startDate,
				endDate,
				path,
				dashboardDataType,
				page,
				limit,
			),
		{
			onError: (error: any, variables, context) => {
				toast({
					title: 'Erro ao carregar os dados do dashboard!',
					description: error?.response?.data[0]?.message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
		},
	);
};

export const useGetSalesDashboardClients = (
	startDate: string,
	endDate: string,
	column: string,
	quantity_clients?: number,
	sales_channel_id?: number,
	family_id?: number,
) => {
	const toast = useToast();
	return useQuery(
		[
			'salesDashboard-clients',
			startDate,
			endDate,
			column,
			quantity_clients,
			sales_channel_id,
			family_id,
		],
		salesDashboardClientsData,
		{
			onError: (error: any) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao listar os clientes com as maiores receitas!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
			refetchOnWindowFocus: false,
			enabled: !!column,
		},
	);
};

export const useGetSalesDashboardCadencia = (
	startDate: string,
	endDate: string,
) => {
	const toast = useToast();

	return useQuery(
		['salesDashboard-cadencia', startDate, endDate, 'cadencia'],
		salesDashboardData,
		{
			onError: (error: any) => {
				toast({
					title: 'Erro ao carregar os dados do dashboard!',
					description: error?.response?.data[0]?.message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
			refetchOnWindowFocus: false,
		},
	);
};
