export const columnsDataLogs = [
	{
		Header: 'Código de execução',
		accessor: 'process_id',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Descrição da Atividade',
		accessor: 'description',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Data',
		accessor: 'date',
		type: 'text',
		optional: true,
	},
	{
		Header: 'Fila',
		accessor: 'queued',
		type: 'text',
		optional: true,
	},
	{
		Header: 'Status',
		accessor: 'status',
		type: 'text',
		filter: false,
	},
];
