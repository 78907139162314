import React from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/css/App.css';

import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalProvider from 'contexts/providers/GlobalProvider';
import { queryClient } from 'services/queryClient/queryClient';
import AppRoutes from './app.routes';
import theme from './theme/theme';
// pagina do tipo componente funcional FC
const App: React.FC = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<GlobalProvider>
				<ChakraProvider theme={theme}>
					<BrowserRouter
						getUserConfirmation={() => {
							/* Empty callback to block the default browser prompt */
						}}
					>
						<AppRoutes />
					</BrowserRouter>
				</ChakraProvider>
				{/* Ferramenta de desenvolvimento ReactQuery */}
				<ReactQueryDevtools initialIsOpen={false} />
			</GlobalProvider>
		</QueryClientProvider>
	);
};

export default App;

// requisição get
