import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
	getProductionDashboardData,
	getProductionDashboardDataByFamilyStock,
	getProductionDashboardDataByProductStock,
	getProductionDashboardDataByProjectionProductStock,
} from 'services/api/requests/dashboardProducao/productionDashboardData';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useProductionDashboardData = (params) => {
	const toast = useToast();

	return useQuery(
		['production-dashboard-data', params],
		getProductionDashboardData,
		{
			refetchOnWindowFocus: false,
			enabled: false,
			onError: (error) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao buscar os dados para o Dashboard de Produção!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
		},
	);
};

export const useProductionDashboardDataByFamilyStock = (
	selectedTab,
	page,
	size,
	familyId?,
) => {
	const toast = useToast();

	return useQuery(
		['production-dashboard-data-by-family-stock', page, size, familyId],
		getProductionDashboardDataByFamilyStock,
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: selectedTab === 'familias',
			onError: (error) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao buscar os dados de estoque por família!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
		},
	);
};

export const useProductionDashboardDataByProductStock = (
	page,
	size,
	productId?,
	familyId?,
	inRateio?,
) => {
	const toast = useToast();

	return useQuery(
		[
			'production-dashboard-data-by-product-stock',
			page,
			size,
			productId,
			familyId,
			inRateio,
		],
		getProductionDashboardDataByProductStock,
		{
			refetchOnWindowFocus: false,
			retry: false,
			onError: (error) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao buscar os dados de estoque por produtos!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
		},
	);
};

export const useProductionDashboardDataByProjectionProductStock = (
	selectedTab,
	page,
	size,
	productId?,
	familyId?,
	inRateio?,
) => {
	const toast = useToast();

	return useQuery(
		[
			'production-dashboard-data-by-projection-product-stock',
			page,
			size,
			productId,
			familyId,
			inRateio,
		],
		getProductionDashboardDataByProjectionProductStock,
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: selectedTab === 'projecao_estoque',
			onError: (error) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao buscar os dados de projeção da necessidade estoque!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
		},
	);
};
