import { ResourceGroup } from 'models/capacity-dashboard.model';
import api from '../../api';
export interface IQueryResourceGroup {
	possui_recurso: boolean;
}
export const getResourceGroup = async (
	query?: IQueryResourceGroup,
): Promise<ResourceGroup[]> => {
	const response = await api.get('/dashboardCapacidade/gruposRecursoSelect', {
		params: query,
	});
	return response.data;
};
