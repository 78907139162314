export const columnFirtDataColaboracaoCadastrar =
{
  Header: "ANO",
  accessor: "ano",
  type: "text",
};

export const columnsEndCadastrar =
  [
    {
      Header: "MÉDIA",
      accessor: "media",
      type: "text",
    }, {
      Header: "TOTAL",
      accessor: "total",
      type: "text",
    },
  ]