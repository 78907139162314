import {
	Box,
	Button,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconLock, IconEye, IconEyeOff } from '@tabler/icons';
import { AlertComponent } from 'components/alerts/AlertComponent';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';
import { ChangeEvent, useCallback, useState } from 'react';
import { useUpdateUserAccount } from 'services/queryClient/wrapperHooks/authentication/useUpdateUserAccount';
import { makeGenericFormInputStructure } from 'utils/forms/defaultInputs';
import {
	columnFlexProps,
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from 'utils/forms/defaultsProps';
import {
	validateByRegex,
	validateMin,
	validateRequired,
} from 'utils/forms/validations';

interface FormData {
	nome: string;
	telefone: string;
	email: string;
	rua: string;
	numero: string | number;
	complemento: string;
	bairro: string;
	cep: string;
	senha: string;
	membro_id: string | number | null;
}

interface AccountCredentialsModalProps {
	isOpen: boolean;
	onClose: () => void;
	form?: FormData | any;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	body?: FormData | any;
	setUserAttributes: any;
	setUserInfos: any;
}

export const AccountCredentialsModal = (
	props: AccountCredentialsModalProps,
) => {
	const { isOpen, onClose, body, setUserAttributes, setUserInfos } = props;

	// controle de exibição da senha
	const [showPassword, setShowPassword] = useState<{
		passwordShow: boolean;
		confirmPasswordShow: boolean;
	}>({
		passwordShow: false,
		confirmPasswordShow: false,
	});

	const [initialState] = useState<any>({
		senha: '',
		confirmacao_de_senha: '',
	});

	const handleClickShowPassword = useCallback(
		(input: 'passwordShow' | 'confirmPasswordShow'): void => {
			setShowPassword((prevState) => ({
				...prevState,
				[input]: !prevState[input],
			}));
		},
		[],
	);

	// Implementação de validação de senha
	const [passwordError, setPasswordError] = useState<boolean>(false);

	// Lógica de atualização de conta com cenário de alteração de e-mail e senha
	const {
		mutateAsync: UpdateUserAccount,
		isLoading: IsLoadingUpdateUserAccount,
	} = useUpdateUserAccount(setUserAttributes, setUserInfos);

	const onSubmitUpdateUserAccount = (values) => {
		const hasPasswordError = values?.senha !== values?.confirmacao_de_senha;

		if (hasPasswordError) {
			setPasswordError(hasPasswordError);
		} else {
			setPasswordError(hasPasswordError);
			UpdateUserAccount({ ...body, senha: values?.senha });
		}
	};

	const renderAddonContentPassword = useCallback(
		(input: 'passwordShow' | 'confirmPasswordShow') => {
			return (
				<Button
					onClick={() => handleClickShowPassword(input)}
					borderLeftRadius={0}
					borderRightRadius={10}
					border={'1px solid #e2e8f0'}
					variant='ghost'
				>
					{showPassword[input] ? (
						<Icon as={IconEyeOff} />
					) : (
						<Icon as={IconEye} />
					)}
				</Button>
			);
		},
		[handleClickShowPassword, showPassword],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'senha',
							label: 'Digite a senha',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateByRegex(
									/(?=^.{8,30}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
									'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
								),
								...validateMin(8),
							},
							labelTooltip:
								'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Ab12345@',
								maxLength: 30,
								addonInputType: showPassword.passwordShow
									? 'text'
									: 'password',
								addonPosition: 'right',
								addonContent:
									renderAddonContentPassword('passwordShow'),
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'input-with-addon',
							name: 'confirmacao_de_senha',
							label: 'Digite a senha novamente',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateMin(8),
								...validateByRegex(
									/(?=^.{8,30}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
									'A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
								),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: Ab12345@',
								maxLength: 30,
								addonInputType: showPassword.confirmPasswordShow
									? 'text'
									: 'password',
								addonPosition: 'right',
								addonContent: renderAddonContentPassword(
									'confirmPasswordShow',
								),
							},
						}),
					},
				],
			},
		];
	}, [
		showPassword.passwordShow,
		showPassword.confirmPasswordShow,
		renderAddonContentPassword,
	]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'2xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconLock}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Alteração de e-mail: Atualização da senha necessária
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{/* <Box mb={6}> */}
					<Text
						color={'secondaryGray.500'}
						fontWeight={400}
						fontSize={'md'}
					>
						Ao realizar a alteração do seu e-mail, é importante
						também atualizar sua senha para garantir a segurança da
						sua conta.
						<Text
							as={'span'}
							fontWeight={'bold'}
						>
							{' '}
							Você pode optar por manter a mesma senha ou
							alterá-la conforme sua preferência.
						</Text>
					</Text>
					{/* </Box> */}
					<GenericForm
						initialState={initialState}
						formFlexProps={formFlexProps}
						rows={renderRows()}
						style={defaultFormStyle}
						onSubmit={onSubmitUpdateUserAccount}
						onCancel={onClose}
						loadingSubmit={IsLoadingUpdateUserAccount}
					/>
					{passwordError && (
						<AlertComponent
							title={'As senhas não correspondem!'}
							description={
								'Por favor, digite a mesma senha nos dois campos!'
							}
							status={'error'}
							hasCloseButton
							actionClose={() => setPasswordError(false)}
						/>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
