import { IRequestSearchPagination } from 'pages/admin/demanda/templateCadastrarColabCons/components/MainFilter/InputSearch';
import {
	Filter,
	FilterOption,
	KeyFilter,
} from 'pages/admin/demanda/templateCadastrarColabCons/context';
import api from 'services/api/api';
import { UseGetSelectedFilterDemand } from 'services/queryClient/wrapperHooks/colabAndConsensus/filters/useGetFilterDemand';

export const colabAndConsensusFilters = async ({ queryKey }) => {
	const [, page, cycle] = queryKey;

	const response = await api.get(`/${page}/filtros`, {
		params: {
			ciclo_id: cycle,
		},
	});
	return response.data;
};
export interface SelectedFilter {}
export type SelectedFiltersOutput = Partial<Record<KeyFilter, Filter>>;

export const getSelectedFilters = async ({
	queryKey,
}): Promise<SelectedFiltersOutput> => {
	const [
		,
		pageName,
		cycleId,
		companyAreaId,
		familyId,
		salesChannelId,
		salesZoneId,
	] = queryKey;

	const response = await api.get(`/${pageName}/selectedFilters`, {
		params: {
			ciclo_id: cycleId,
			area_empresa_id: companyAreaId,
			familia_id: familyId,
			canal_venda_id: salesChannelId,
			zona_venda_id: salesZoneId,
		},
	});
	return response.data;
};

export const getFilterDemand = async (
	query: UseGetSelectedFilterDemand &
		IRequestSearchPagination & { page?: number },
): Promise<{
	rows: FilterOption[];
	count: number;
}> => {
	const {
		pageName,
		search,
		limit,
		column_name,
		cycleId,
		companyAreaId,
		familyId,
		salesChannelId,
		salesZoneId,
		page = 0,
	} = query;
	const response = await api.get(`/${pageName}/filter`, {
		params: {
			search,
			column_name,
			ciclo_id: cycleId,
			area_empresa_id: companyAreaId,
			familia_id: familyId,
			canal_venda_id: salesChannelId,
			zona_venda_id: salesZoneId,
			size: limit,
			page,
		},
	});
	return response.data;
};
export const getFilterDemandFamily = async ({
	queryKey,
}): Promise<{
	rows: FilterOption[];
	count: number;
}> => {
	const [
		,
		pageName,
		cycleId,
		companyAreaId,
		salesChannelId,
		salesZoneId,
		size,
		page,
	] = queryKey;

	const response = await getFilterDemand({
		column_name: 'familia',
		pageName,
		search: '',
		companyAreaId,
		cycleId,
		page,
		salesChannelId,
		salesZoneId,
		limit: size,
	});
	return response;
};

export const getStatusDemand = async ({ queryKey }) => {
	const [, pageName, cycleId, companyAreaId] = queryKey;

	const response = await api.get(`/${pageName}/status`, {
		params: {
			ciclo_id: cycleId,
			area_empresa_id: companyAreaId,
		},
	});
	return response.data;
};
