import { useQuery } from '@tanstack/react-query';
import { getDownloadTemplateApportionment } from 'services/api/requests/uploads/uploads';

export const useDownloadTemplateApportionment = () => {
	return useQuery(
		['download-template-apportionment'],
		getDownloadTemplateApportionment,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		},
	);
};
