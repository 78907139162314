import api from 'services/api/api';
import apiPython from 'services/api/apiPython';

export const fetchByFiltersDependency = async (body, module) => {
	const url = `/${module}/view/filter`;
	const response = await api.post(url, body);
	return response.data;
};

export const fetchByFiltersDependencyFromExternalAPI = async (body) => {
	const url = '/accuracy/additional/filter';

	const { nome_coluna: column, page, size, pesquisa: search } = body;

	const response = await apiPython.get(url, {
		params: {
			column,
			search,
			page,
			size,
		},
	});
	return response.data;
};
