import { Badge, Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { columnsDataLogs } from './variables/columnsDataLogs';
import { calculateTimeDifference } from 'utils/functions/dateUtils';
import moment from 'moment';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { IconRefresh } from '@tabler/icons';
import { useQueryClient } from '@tanstack/react-query';

export default function AiPredictionLogsList() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpTipologiaParadas: SidebarHelp = useMemo(
		() => ({
			title: 'Predições Recentes da Inteligência Artificial',
			firstParagraph:
				'Nesta tela é possível acompanhar o histórico das predições feitas pela inteligência artificial da Easy360, conseguindo analisar o tipo de importação realizada, o sucesso ou insucesso da mesma e data/horário.',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const queryClient = useQueryClient();

	useEffect(() => {
		setDataSidebar(helpTipologiaParadas);
	}, [helpTipologiaParadas, setDataSidebar]);

	const tableColumns = useMemo(() => columnsDataLogs, []);

	const getStatusBadge = useCallback((status) => {
		switch (status) {
			case 'processing':
				return (
					<Badge
						bg='easyBLUE.300'
						variant='solid'
					>
						Processando
					</Badge>
				);
			case 'finished':
				return (
					<Badge
						bg='green.500'
						variant='solid'
					>
						Finalizado
					</Badge>
				);
			case 'failed':
				return (
					<Badge
						bg='easyRED.300'
						variant='solid'
					>
						Falha
					</Badge>
				);
			default:
				return (
					<Badge
						bg='orange.400' //started
						variant='solid'
					>
						Iniciado
					</Badge>
				);
		}
	}, []);

	const renderRow = useCallback(
		(recentActivity) => {
			return {
				process_id: recentActivity.process_id,
				description: 'Previsão de demanda através da IA',
				date: calculateTimeDifference(
					moment(recentActivity.date).utc(),
				),
				queued: recentActivity.queue || '-',
				status: getStatusBadge(recentActivity.status),
			};
		},
		[getStatusBadge],
	);

	const iconRefreshInvalidationQuery = () => {
		queryClient.resetQueries(['aiPrediction-list']);
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='40px'>
				<Flex mt='8px'>
					<ButtonComponent
						type='icon'
						iconType={<IconRefresh />}
						onClick={iconRefreshInvalidationQuery}
						iconSize='xs'
						border='none'
					/>
				</Flex>
				<Flex mt='50px' />
				<CheckTable
					dataTest='button-filtrar-vendas'
					module='vendas'
					columnsData={tableColumns}
					showGenericSearchInput={false}
					filterModule='aiPrediction'
					renderRow={renderRow}
					showFilters={false}
				/>
			</Card>
		</Flex>
	);
}
