import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { getBudgetById } from 'services/api/requests/budget';
export const useGetBudgetById = (setForm: any, id: number) => {
	return useQuery(['budgetById', id], getBudgetById, {
		onSuccess: (data) => {
			setForm({
				id: data.id,
				competencia: moment(data?.mes_ano, 'YYYY-MM')?.format(
					'YYYY-MM',
				),
				receitaBruta: data?.receita_bruta?.toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				}),
				canalVenda: data?.canal_venda_id,
				familia: data?.familia_id,
				quantidade: data?.quantidade?.toLocaleString('pt-BR'),
			});
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};

export const useGetBudgetWithId = (id: Number) => {
	return useQuery(['budgetWithId', id], getBudgetById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
