import { Switch } from '@chakra-ui/react';
import { useEditPortfolio } from 'services/queryClient/wrapperHooks/portfolio/useEditPortfolio';

interface ToggleActionableCarteiraProps {
	id: number;
	checked: boolean;
}
export function ToggleActionableCarteira(
	props: ToggleActionableCarteiraProps,
): JSX.Element {
	const { id, checked } = props;

	const body: {
		ruim: boolean;
	} = {
		ruim: !checked,
	};

	const { mutate } = useEditPortfolio(body, id, 'toggle');

	const updateItem = (): void => {
		mutate();
	};

	return (
		<Switch
			colorScheme='red'
			size='sm'
			isChecked={checked}
			onChange={updateItem}
		/>
	);
}
