import api from '../api';
import { QueryFunctionContext } from '@tanstack/react-query';

export const getDesiredStock = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo, ano] = queryKey;
	const response = await api.get(
		`/EstoqueDesejado/EstoqueDesejadoByAnoCiclo?ano=${ano}&ciclo=${ciclo}`,
	);
	return response.data;
};

export const deleteDesiredStock = async (id: number) => {
	const response = await api.delete(`estoquedesejado?id=${id}`);
	return response.data;
};

export const getStockOrientation = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo, ano, familiaId] = queryKey;

	return api.get(
		`/venda/projecaoEstoque?familia_id=${familiaId}&ano=${ano}&ciclo=${ciclo}`,
	);
};

export const createStockOrientation = async (body, id) => {
	if (id) {
		const response = await api.patch(`/EstoqueDesejado?id=${id}`, body);
		return response.data;
	}

	const response = await api.patch(`/EstoqueDesejado`, body);
	return response.data;
};

export const createActualStock = async (body) => {
	const response = await api.post('/estoque', body);
	return response.data;
};

export const getActualStockById = async ({ queryKey }) => {
	const [_key, id] = queryKey;
	const url = `estoque/estoqueById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const editActualStock = async (body, id: number) => {
	const url = `/estoque?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deleteActualStock = async (id: number) => {
	const response = await api.delete(`estoque?id=${id}`);
	return response.data;
};

export const deleteActualStockByFilters = async (filters: any) => {
	const response = await api.post('estoque/deletarPorFiltros', filters);
	return response.data;
};
