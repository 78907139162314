import { useQuery } from '@tanstack/react-query';
import { getTotalProductWithoutFamily } from 'services/api/requests/product';

export const useGetTotalProductWithoutFamily = (
	data_inicio?: string,
	data_fim?: string,
) => {
	return useQuery(
		['total-product-without-family-by-range-dates', data_inicio, data_fim],
		getTotalProductWithoutFamily,
		{
			onError: (error) => {
				console.log(error);
			},
			refetchOnWindowFocus: false,
			retry: false,
		},
	);
};
