import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateApportionInconsistencies } from 'services/api/requests/apportionment';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useUpdateApportionInconsistencies = (
	cicloId?: number | string,
) => {
	const toast = useToast();
	const queryClient = useQueryClient();
	return useMutation(updateApportionInconsistencies, {
		onSuccess: () => {
			queryClient.invalidateQueries(['apportionment-listById', cicloId]);

			queryClient.removeQueries({
				predicate: (query: any) =>
					query.queryKey[0].startsWith('apportionment-family-'),
			});

			queryClient.invalidateQueries({
				predicate: (query: any) =>
					query.queryKey[0] === 'apportionment-family',
			});

			toast({
				title: 'Sucesso!',
				description: 'Rateio atualizadas com sucesso!',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro!',
				'Não foi possível atualizar rateio!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
