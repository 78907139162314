import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { exportCommercialHierarchy } from 'services/api/requests/commercial';

export const useExportCommercialHierarchy = (cicloId: string) => {
	const toast = useToast();
	return useQuery(['export-hierarchy', cicloId], exportCommercialHierarchy, {
		refetchOnWindowFocus: false,
		retry: 1,
		enabled: false,
		onSuccess: () => {
			toast({
				title: 'Sucesso!',
				description: 'Exportação do arquivo realizada com sucesso!',
				status: 'success',
				duration: 8000,
				isClosable: true,
				position: 'bottom-left',
			});
		},
		onError: (err: any) => {
			toast({
				title: 'Erro!',
				description:
					'Não foi possível realizar a exportação do arquivo!',
				status: 'error',
				duration: 8000,
				isClosable: true,
				position: 'bottom-left',
			});
		},
	});
};
