import { Icon } from '@chakra-ui/icon';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/react';
import {
	IconFilePlus,
	IconLogin,
	IconPlus,
	IconRotateClockwise,
	IconTableImport,
	IconTrashX,
} from '@tabler/icons';

enum Status {
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	PROCESSING = 'PROCESSING',
	STARTED = 'STARTED',
	DEFAULT = 'DEFAULT',
}

enum Action {
	IMPORT = 'IMPORT',
	CREATE = 'CREATE',
	BULKCREATE = 'BULKCREATE',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	LOGIN = 'LOGIN',
	DEFAULT = 'DEFAULT',
}

const iconMap = {
	[Action.IMPORT]: IconTableImport,
	[Action.CREATE]: IconPlus,
	[Action.BULKCREATE]: IconTableImport,
	[Action.UPDATE]: IconRotateClockwise,
	[Action.DELETE]: IconTrashX,
	[Action.LOGIN]: IconLogin,
	[Action.DEFAULT]: IconFilePlus,
};

const colorMap = {
	[Status.STARTED]: 'orange.500',
	[Status.SUCCESS]: 'green.500',
	[Status.ERROR]: 'easyRED.300',
	[Status.PROCESSING]: 'blue.500',
	[Status.DEFAULT]: 'gray.500',
};

export const RowIcon = (props) => {
	const { row } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const IconComponent = iconMap[row.acao] || iconMap[Action.DEFAULT];
	const iconColor = colorMap[row.status] || colorMap[Status.DEFAULT];

	return (
		<Flex>
			<Box display='flex'>
				<Icon
					as={IconComponent}
					w={6}
					h={6}
					color={iconColor}
				/>
			</Box>

			<Text
				color={textColor}
				fontSize='sm'
				fontWeight='700'
				pl='.5em'
			>
				{row.descricaoAtividade}
			</Text>
		</Flex>
	);
};
