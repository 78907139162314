import { Flex } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/loading/Loading';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetStopTypologyById } from 'services/queryClient/wrapperHooks/useGetStopTypologyById';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';
import FormCadastrarEditarTipologia from 'components/forms/FormCadastrarEditarTipologia';
import moment from 'moment';

export default function EditarTipologia() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: typologyId } = useParams<any>();

	const { data: TypologyData, isLoading: isLoadingTypologyData } =
		useGetStopTypologyById(typologyId);

	const [initialState, setInitialState] = useState<any>();

	const helpEditarParada: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Parada',
			firstParagraph:
				'Para editar uma parada informe o nome do Parada, e se ela é do tipo programada (prevista) ou não programada.',
			secondParagraph:
				'Algumas paradas são comuns em diversos setores industriais, por isso a easy360 já retorna automaticamente uma lista de paradas.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarParada);
	}, [helpEditarParada, setDataSidebar]);

	useEffect(() => {
		if (TypologyData) {
			setInitialState({
				tipologia_parada: TypologyData?.tipologia_parada,
				programada: TypologyData?.programada,
				tempo_de_parada: moment
					.utc(TypologyData?.tempo_de_parada * 1000)
					.format('HH:mm:ss'),
			});
		}
	}, [TypologyData]);

	return isLoadingTypologyData || !initialState ? (
		<LoadingSpinner />
	) : (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarTipologia
					initialState={initialState}
					typologyId={typologyId}
				/>
			</Card>
		</Flex>
	);
}
