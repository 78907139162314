import api from 'services/api/api';

export const salesDashboard = async (
	start_date: string,
	end_date: string,
	path: string,
	dashboardDataType?: string,
	page?: number,
	limit?: number,
) => {
	const response = await api.get(`/dashboardVenda/dadosDashboard/${path}`, {
		params: {
			data_inicio: start_date,
			data_fim: end_date,
			...(dashboardDataType ? { dashboardDataType } : undefined),
			...(page !== undefined ? { page } : undefined),
			...(limit !== undefined ? { limit } : undefined),
		},
		timeout: 120000,
	});
	return response.data;
};

export const salesDashboardData = async ({ queryKey }) => {
	const [, start_date, end_date, path, dashboardDataType, page, limit] =
		queryKey;

	const response = await api.get(`/dashboardVenda/dadosDashboard/${path}`, {
		params: {
			data_inicio: start_date,
			data_fim: end_date,
			dashboardDataType,
			page,
			limit,
		},
		timeout: 120000,
	});
	return response.data;
};

export const salesDashboardDataTable = async (
	start_date,
	end_date,
	path,
	dashboardDataType,
	page,
	limit,
) => {
	const response = await api.get(`/dashboardVenda/dadosDashboard/${path}`, {
		params: {
			data_inicio: start_date,
			data_fim: end_date,
			dashboardDataType,
			page,
			limit,
		},
		timeout: 120000,
	});
	return response.data;
};

export const salesDashboardClientsData = async ({ queryKey }) => {
	const [
		,
		start_date,
		end_date,
		column,
		quantity_clients = 5,
		sales_channel_id,
		family_id,
	] = queryKey;

	const response = await api.get(
		'/dashboardVenda/dadosDashboard/estatistica/clientes',
		{
			params: {
				data_inicio: start_date,
				data_fim: end_date,
				coluna: column,
				quantidade_clientes: quantity_clients,
				canal_venda_id: sales_channel_id,
				familia_id: family_id,
			},
			timeout: 120000,
		},
	);

	return response.data;
};
