import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { columnsDataConsenso } from './variables/columnsData';
import { AlertComponent } from 'components/alerts/AlertComponent';

interface ConsensusObject {
	status: 'Completa' | 'Pendente';
	ano: number;
	cicloId: number;
	ciclo: string;
}
interface ConsensusList {
	status: boolean;
	ano: number;
	ciclo_id: number;
	ciclo: string;
	duracao: number;
	possui_rateio: boolean;
}

interface DataConsensus {
	ciclo_sem_rateio: boolean;
	dados: ConsensusList[];
	mapaFiltros: any;
	total_linhas: number;
}

export default function Consenso() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;
	const tableColumns = useMemo(() => columnsDataConsenso, []);
	const [dataConsensus, setDataConsensus] = useState<DataConsensus>();

	const helpConsensus: SidebarHelp = useMemo(
		() => ({
			title: 'Consensos',
			firstParagraph: `Etapa destinada a aplicação do consenso, sendo a fase de maior importância no processo de previsão de demanda, pois a partir da interação dos responsáveis pelo negócio, gera-se o senso comum refletido nas perspectivas [quantidade e receita] no tempo.`,
			secondParagraph: `Para o ciclo atual, em conjunto com todos os especialistas envolvidos, deve ser definido a demanda em quantidade para todas as famílias de produto em seus respectivos canais de vendas.`,
			thirdParagraph: `Acesse o botão para realizar seu consenso.`,
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpConsensus);
	}, [helpConsensus, setDataSidebar]);

	const renderRow = useCallback(
		(consensus: ConsensusList): ConsensusObject => {
			return {
				status: consensus.status ? 'Completa' : 'Pendente',
				ano: consensus.ano,
				cicloId: consensus.ciclo_id,
				ciclo: consensus.ciclo,
			};
		},
		[],
	);

	const renderAlertNotHaveApportionment = (
		data: DataConsensus | undefined,
	) => {
		if (!!data && data?.ciclo_sem_rateio) {
			return (
				<AlertComponent
					title={'Atenção!'}
					status={'warning'}
					description={
						'Existem ciclos sem rateio calculado. Importante revisá-los!'
					}
					hasCloseButton
				/>
			);
		}
	};
	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-consensos'
				module={'consenso'}
				columnsData={tableColumns}
				showGenericSearchInput={false}
				filterModule='consenso'
				renderRow={renderRow}
				noButton
				setData={setDataConsensus}
				pt={14}
			/>
		);
	}

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card>
				{renderAlertNotHaveApportionment(dataConsensus)}
				{renderTable()}
			</Card>
		</Flex>
	);
}
