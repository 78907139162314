import moment from 'moment';
import api from '../../api';

export const getOpportunity = async () => {
	const url = '/oportunidade';
	const response = await api.get(url);
	return response.data;
};

export const getOpportunityById = async ({ queryKey }) => {
	const [, id] = queryKey;
	const url = `/oportunidade/oportunidadeById?oportunidade_id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const createOpportunity = async (body) => {
	const url = '/oportunidade';
	const response = await api.post(url, body);
	return response.data;
};

export const editOpportunity = async (body, id: number) => {
	const url = `/oportunidade?empresa_id=1&oportunidade_id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deleteOpportunity = async (id: number) => {
	const url = `/oportunidade?empresa_id=1&oportunidade_id=${id}`;
	const response = await api.delete(url);
	return response.data;
};

export const getOpportunityConsensusException = async (body) => {
	const { cycleId, date, familyId } = body.preFilteredRows;

	const dateMoment = moment(date, 'YYYY-MM');
	// Obter o início do mês
	const startOfMonth = dateMoment.startOf('month').format('YYYY-MM-DD');
	// Obter o final do mês
	const endOfMonth = dateMoment.endOf('month').format('YYYY-MM-DD');
	console.log(body.limit);

	const requestBody = {
		paginacao: body.paginacao,
		ciclo_id: cycleId,
		filtros: [
			...body.filtros,
			{
				nome_coluna: 'data',
				operacao: 'greater_equal',
				tipo_valor: 'date',
				valores: startOfMonth,
			},
			{
				nome_coluna: 'data',
				operacao: 'less_equal',
				tipo_valor: 'date',
				valores: endOfMonth,
			},
			{
				nome_coluna: 'in_ciclo',
				operacao: 'equal',
				tipo_valor: 'boolean',
				valores: true,
			},
			{
				nome_coluna: 'familia_id',
				operacao: 'equal',
				tipo_valor: 'number',
				valores: familyId,
			},
		],
	};

	const response = await api.post('/oportunidade/view', requestBody);
	return response.data;
};

export const deleteOpportunityByFilters = async (filters: any) => {
	const response = await api.post('oportunidade/deletarPorFiltros', filters);
	return response.data;
};
