import { Time } from "models/time-form.model";
import { createContext, useContext } from "react";

interface TimeFormContext {
  states: {
    editTimeForm: Time | undefined;
    resetBreakForm: boolean;
  },
  setters: {
    setEditTimeForm: (lancamento: Time | undefined) => void;
    setResetBreakForm: (value: boolean) => void;
  }
}

export const TimeContext = createContext({
} as TimeFormContext);

export const useTimeFormContext = () => useContext(TimeContext)
