import { Cycle } from 'models/cycle.model';
import api from '../api';

export const getCycle = async () => {
	const response = await api.get(`/ciclo`);
	return response.data;
};
export const getCycleById = async ({ queryKey }): Promise<Cycle | null> => {
	const [, id] = queryKey;
	if (!id) {
		return null;
	}
	const filtros = [
		{
			nome_coluna: 'id',
			valores: +id,
			tipo_valor: 'number',
			operacao: 'equal',
		},
	];
	const paginacao = {
		page: 0,
		size: 1,
	};
	const response = await api.post(`/ciclo/view`, {
		paginacao,
		filtros,
	});
	return response.data?.dados[0];
};
