import { Flex, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import {
	IconHandStop,
	IconNotebook,
	IconRefreshAlert,
	IconTargetArrow,
} from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import StatisticTable, {
	Row,
	RowInput,
	generateRowsMonths,
} from 'components/dataTables/StatisticTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCollaborationConsensusByFamilyList } from 'services/queryClient/wrapperHooks/colabAndConsensus/filters/useCollaborationConsensusByFamilyList';
import { useCollaborationConsensusExeptionsByFamilyList } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationConsensusExeptionsByFamilyList';
import { useCollaborationsByFamily } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationsByFamilyList';
import { useCreateConsensus } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCreateConsensus';
import { useCreateException } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCreateException';
import { useDeleteCollaborationConsensusExeptionsByFamily } from 'services/queryClient/wrapperHooks/colabAndConsensus/useDeleteCollaborationConsensusExeptionsByFamily';
import ButtonExportCSV from '../../templateCadastrarColabCons/components/ButtonExportCSV';
import {
	ErrorComment,
	ModalComment,
} from '../../templateCadastrarColabCons/components/ComentarioJustificando';
import { useContextColaboracaoConsenso } from '../../templateCadastrarColabCons/context';
import { ProductException, useContextConsenso } from '../context';
import SectionExceptions, {
	ColaboracoesData,
} from '../../templateCadastrarColabCons/components/SectionExceptions';
import { CellArea } from './cells/CellArea';
import { CellCrescimento } from './cells/CellCrescimento';
import { CellMedia } from './cells/CellMedia';
import { CellTotal } from './cells/CellTotal';
import { useCollaborationExceptionsByFamilyId } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationExceptionsByFamilyId';
import { useCreateAtaConsenso } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCreateAtaConsenso';
import { useGetAtaConsenso } from 'services/queryClient/wrapperHooks/colabAndConsensus/useGetAtaConsenso';
import { useCollaborationConsensuFinalize } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationConsensuFinalize';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import { currencyToNumber } from '../../../../../utils/functions/currencyToNumber';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { useApplyConsensusApportionment } from 'services/queryClient/wrapperHooks/apportionment/useApplyConsensusApportionment';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import { useGlobalContext } from 'contexts/GlobalContext';
import { BodyCreateConsensus } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { useSelectByStatistic } from '../../templateCadastrarColabCons/hooks/useSelectByStatistic';
import { numberToLocaleString } from 'utils/functions/number';
import ModalAiPredictionRequest from './ModalAiPredictionRequest';

interface ExceptionsConsensus {
	id: string | number;
	empresa_id: string | number;
	ciclo_id: string | number;
	mes_ano: string;
	canal_venda_id: string | number | null;
	familia_id: string | number;
	produto_id: string | number;
	consenso: number;
	excecao: boolean;
	createdAt: string;
	updatedAt: string;
	oportunidade?: boolean;
}
interface ExceptionsCollaboration {
	area_empresa_id: string | number;
	colaboracao_produto: number;
	mes_ano: string;
	nome_area_empresa: string;
}
interface ExceptionsConsensusAndCollab {
	codigo_produto: string | number;
	id: string | number;
	nome_produto: string;
	consenso: ExceptionsConsensus[] | [];
	colaboracao?: ExceptionsCollaboration[];
	createdAd?: string;
	empresa_id?: string | number;
	estoque_atual?: string | number;
	familia_id?: string | number;
	participa_rateio?: boolean;
	participa_ticket_medio?: boolean;
	produto_relacionado_id?: string | number | null;
	status?: boolean;
	sub_familia_id?: string | number | null;
	updatedAd?: string;
}

const SectionConsensus = () => {
	const {
		consensus,
		setConsensus,
		exceptions,
		setExceptions,
		productExceptions,
		setProductExceptions,
	} = useContextConsenso();

	const {
		familyId,
		isLoadingFilters,
		salesChannelId,
		cycleId,
		cycle: cycleData,
		valuesFilter,
		averageTicket,
		keysPage,
		isFinished,
		confidenceInterval,
		allowFinalize,
	} = useContextColaboracaoConsenso();

	const { ciclo: cycle, duracao: cycleDuration, ano: yearCycle } = cycleData;

	const { mutate: createAta } = useCreateAtaConsenso();
	const { mutate: finalizar } = useCollaborationConsensuFinalize('consenso');

	const { data: dataAtaConsenso } = useGetAtaConsenso(cycleId, familyId);

	const { mutate: deleteConsensusException } =
		useDeleteCollaborationConsensusExeptionsByFamily();

	const [total, setTotal] = useState<number>(0);

	const { data: dataConsensusByFamily, isLoading: isLoadingConsensusData } =
		useCollaborationConsensusByFamilyList({
			page: keysPage.page,
			cycleId,
			salesChannelId,
			familyId,
		});
	const { data: dataConsensusExeptionByFamily } =
		useCollaborationConsensusExeptionsByFamilyList(
			keysPage.page,
			cycleId,
			salesChannelId,
			familyId,
		);
	const {
		data: dataCollaborationsByFamily,
		isLoading: isLoadingCollaborationByFamily,
	} = useCollaborationsByFamily({ cycleId, familyId });

	const { data: dataCollaborationExceptionsByFamily } =
		useCollaborationExceptionsByFamilyId(cycleId, familyId);

	const { mutate } = useCreateConsensus();
	const { mutate: mutateCreateException } = useCreateException();

	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [exceptionsColaboracaoes, setExceptionsColaboracaoes] = useState<
		Record<string, ColaboracoesData[]>
	>({});

	const [rowsColabs, setRowsColabs] = useState<Row[]>([]);
	const [extremesValues, setExtremeValues] = useState<{
		max: number;
		min: number;
		secondMax;
	}>();

	useEffect(() => {
		setAta(dataAtaConsenso?.anotacao || '');
	}, [dataAtaConsenso]);

	useEffect(() => {
		if (!!dataCollaborationsByFamily?.length) {
			setRowsColabs([]);
			setExtremeValues(undefined);

			const values: number[] = [];

			let max = Number.MIN_VALUE;
			let min = Number.MAX_VALUE;
			let secondMax;

			const newRowsColabs: Row[] = dataCollaborationsByFamily?.map(
				(colabArea) => {
					const newRows: Row = {
						media: 0,
						total: 0,
						type: 'text',
					};

					let total = 0;
					let media = 0;

					for (const colaboracao of colabArea?.colaboracao) {
						const [, mounth] = colaboracao.mes_ano.split('-');
						newRows[+mounth] = Number(
							colaboracao?.colaboracao,
						)?.toLocaleString('pt-BR');

						total += Number(colaboracao?.colaboracao || 0);
					}

					let crescimento;
					if (+colabArea.coloboracaoTotalCicloAnterior) {
						crescimento =
							(total * 100) /
								+colabArea.coloboracaoTotalCicloAnterior -
							100;
					}

					media = total / cycleDuration;

					newRows.preenchimentoText = colabArea.nome_area_empresa;
					newRows.crescimento = crescimento || '-';

					if (colabArea.colaboracao.length >= cycleDuration) {
						total > 0 && values.push(total);
					}

					newRows.atas = colabArea.atas;

					newRows.media = numberToLocaleString({
						value: media || 0,
						maximumFractionDigits: precisao_decimal,
					});
					newRows.total = numberToLocaleString({
						value: total || 0,
						maximumFractionDigits: precisao_decimal,
					});

					return newRows;
				},
			);

			values.sort((a, b) => {
				if (a > b) return 1;
				if (b > a) return -1;
				return 0;
			});

			if (values.length > 0) {
				max = values[values.length - 1];
				min = values[0];
				if (values[0] === values[1]) {
					secondMax = values[0];
				}
				if (values.length > 2) {
					if (values[0] === values[1] || values[0] === values[2]) {
						secondMax = values[0];
					} else if (values[1] === values[2]) {
						secondMax = values[2];
					}
				}
			}

			setRowsColabs(newRowsColabs);
			setExtremeValues({
				max,
				secondMax,
				min,
			});
		}
	}, [dataCollaborationsByFamily, cycleDuration]);

	const columnFirtDataConsensoCadastrar = {
		Header: 'Prenchimento',
		accessor: 'preenchimentoText',
		type: 'text',
		JSX: (props, ComponentRow) =>
			CellArea(props, ComponentRow, extremesValues),
	};

	const columnsEndCadastrar = [
		{
			Header: 'MÉDIA',
			accessor: 'media',
			type: 'text',
			JSX: (props, ComponentRow) =>
				CellMedia(props, ComponentRow, extremesValues),
		},
		{
			Header: 'TOTAL',
			accessor: 'total',
			type: 'text',
			JSX: (props, ComponentRow) =>
				CellTotal(props, ComponentRow, extremesValues),
		},
		{
			Header: 'CRESCIMENTO',
			accessor: 'crescimento',
			type: 'growth',
			JSX: (props) => CellCrescimento(props),
		},
	];

	const updateConsensusByDatabase = () => {
		if (dataConsensusByFamily) {
			const newConsenso = {};
			let total = 0;
			for (const consensus of dataConsensusByFamily.consensus) {
				const key = +consensus.mes_ano.split('-')[1];
				newConsenso[key] = consensus.consenso;
				total += consensus.consenso;
			}

			for (let i = 0; i < cycleDuration; i++) {
				const key = new Date(1, Number(cycle) + i).getMonth() + 1;
				if (newConsenso[key] === undefined) {
					newConsenso[key] = '';
				}
			}

			setConsensus({
				...newConsenso,
			});
			setTotal(total);
		}
	};

	const createConsensusAndCollabArray = async (): Promise<
		ExceptionsConsensusAndCollab[] | []
	> => {
		const idMapForCollabAndConsensus = {};

		for (const consensus of dataConsensusExeptionByFamily) {
			for (const consensusItem of consensus.consenso) {
				if (!consensusItem.oportunidade) {
					idMapForCollabAndConsensus[consensus.id] = { ...consensus };
				}
			}
		}

		for (const collab of dataCollaborationExceptionsByFamily) {
			const { id } = collab;

			if (idMapForCollabAndConsensus[id]) {
				idMapForCollabAndConsensus[id].colaboracao =
					collab.area_empresa?.flatMap((area) =>
						area.colaboracao?.map((collab) => ({
							mes_ano: collab.mes_ano,
							colaboracao_produto: collab.colaboracao,
							area_empresa_id: area.id,
							nome_area_empresa: area.nome_area_empresa,
						})),
					);
			} else {
				idMapForCollabAndConsensus[id] = {
					nome_produto: collab.nome_produto,
					id: collab.id,
					codigo_produto: collab.codigo_produto,
					consenso: [],
					colaboracao: collab.area_empresa?.flatMap((area) =>
						area.colaboracao?.map((collab) => ({
							mes_ano: collab.mes_ano,
							colaboracao_produto: collab.colaboracao,
							area_empresa_id: area.id,
							nome_area_empresa: area.nome_area_empresa,
						})),
					),
				};
			}
		}

		const consensusAndCollab: ExceptionsConsensusAndCollab[] =
			Object.values(idMapForCollabAndConsensus);

		return consensusAndCollab || [];
	};

	const updateConsensusExeptionByDatabase = async (): Promise<void> => {
		if (
			dataConsensusExeptionByFamily &&
			dataCollaborationExceptionsByFamily
		) {
			const newIdExceptionIdDeleteControl: Record<string, boolean> = {};
			const newExceptions: Record<string, any> = {};
			const newExceptionsProducts: Record<string, ProductException> = {};
			const newExceptionsCollabs: Record<string, any> = {};
			const consensusAndCollabArray: ExceptionsConsensusAndCollab[] | [] =
				await createConsensusAndCollabArray();

			for (const exception of consensusAndCollabArray) {
				const {
					consenso,
					colaboracao,
					id,
					nome_produto,
					codigo_produto,
				} = exception;

				if (consenso?.length || colaboracao?.length) {
					const newExeption: any = {
						delete: !consenso || consenso.length === 0,
						comentario: '',
					};

					let total = 0;
					const newColaboracao: any = {};

					if (consenso?.length) {
						newIdExceptionIdDeleteControl[id] = true;

						for (const consensus of consenso) {
							const key = +consensus.mes_ano.split('-')[1];
							newColaboracao[key] = Number(consensus.consenso);
							total += consensus.consenso;
						}
					}

					newColaboracao.total = numberToLocaleString({
						value: total,
						maximumFractionDigits: precisao_decimal,
					});
					newColaboracao.media = numberToLocaleString({
						value: total / cycleDuration,
						maximumFractionDigits: precisao_decimal,
					});

					for (let i = 0; i < cycleDuration; i++) {
						const key =
							new Date(1, Number(cycle) + i).getMonth() + 1;
						if (newColaboracao[key] === undefined) {
							newColaboracao[key] = '';
						}
					}

					const keyId = Date.now().toString() + id;
					newExceptions[keyId] = {
						...newColaboracao,
						...newExeption,
					};
					newExceptionsProducts[keyId] = {
						id,
						nome_produto,
						codigo_produto,
					};
				}

				newExceptionsCollabs[id.toString()] = colaboracao;
			}
			setExceptionsColaboracaoes(newExceptionsCollabs);
			setProductExceptions(newExceptionsProducts);
			setExceptions(newExceptions);
		}
	};

	useEffect(() => {
		updateConsensusByDatabase();
	}, [dataConsensusByFamily]);
	useEffect(() => {
		updateConsensusExeptionByDatabase();
	}, [dataConsensusExeptionByFamily, dataCollaborationExceptionsByFamily]);

	const onChange = useCallback(
		(key: string, value: string) => {
			let total = 0;
			const newConsenso = {
				...consensus,
				[key]: value,
			};
			for (const key in newConsenso) {
				total += currencyToNumber(String(newConsenso[key])) || 0;
			}
			setConsensus({
				...newConsenso,
			});
			setTotal(total);
		},
		[consensus, setConsensus],
	);

	const errorConditional = useCallback(
		(value: number | string) => {
			return (
				Number(confidenceInterval.min) < Number(value) &&
				Number(confidenceInterval.max) > Number(value)
			);
		},
		[confidenceInterval.max, confidenceInterval.min],
	);
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const { onChangeSelect, options, valueSelect, isLoading, extraResult } =
		useSelectByStatistic({
			cycleId,
			familyId,
			page: keysPage.page,
			cycleData,
			setTotal,
			setValues: setConsensus,
		});

	const consenso: RowInput = useMemo(() => {
		return {
			type: 'input',
			...consensus,
			media: numberToFormattedString(
				total / cycleDuration,
				precisao_decimal,
			),
			total: numberToFormattedString(total, precisao_decimal),
			preenchimento: {
				options,
				onChange: onChange,
				onChangeSelect,
				value: valueSelect,
				isLoading,
			},
			errorConditional,
		};
	}, [
		consensus,
		cycleDuration,
		errorConditional,
		isLoading,
		onChange,
		onChangeSelect,
		options,
		precisao_decimal,
		total,
		valueSelect,
	]);

	const previsaoReceita: Row = {
		type: 'text',
		media: Math.trunc(
			(total / cycleDuration) * averageTicket,
		)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		total: Math.trunc(total * averageTicket)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		preenchimentoText: 'Previsão de Receita',
	};

	for (const key in consensus) {
		const value = currencyToNumber(String(consensus[key]));
		const previsao = value ? value * averageTicket : 0;

		previsaoReceita[key] = Math.trunc(previsao).toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		});
	}
	const collumnsMounths = generateRowsMonths(
		cycle,
		cycleDuration,
		cycleDuration > 6,
	);

	const addExcecao = () => {
		const id: string = Date.now().toString();
		const newExeption = {
			comentario: '',
		};
		setExceptions({
			...exceptions,
			[id]: newExeption,
		});
		const element = document.getElementById('buttonSave');

		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const [showErrorComment, setShowErrorComment] = useState<boolean>(false);
	const [showModalConsenso, setShowModalConsenso] = useState<boolean>(false);
	const [ata, setAta] = useState('');

	const onSaveAta = (newAta: string) => {
		let bodyAta = {
			anotacao: newAta,
			ciclo_id: cycleId,
			familia_id: familyId,
		};
		setAta(newAta);
		createAta(bodyAta);
		setShowModalConsenso(false);
	};

	useEffect(() => {
		if (consensus) {
			const { max, min } = confidenceInterval;
			for (const key in consenso) {
				const colaboracao = consensus[key];
				if (Number(colaboracao) < min || Number(colaboracao) > max) {
					setShowErrorComment(true);
					return;
				}
			}
			setShowErrorComment(false);
		}
	}, [consenso, consensus, confidenceInterval]);

	const toast = useToast();

	const createConsenso = () => {
		const consensoBody: Array<{
			mes_ano: string;
			quantidade: number;
		}> = Array.from({
			length: cycleDuration,
		}).map((_, i) => {
			const mounth = ((cycle + i) % 12) + 1;
			const ano = (i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
			return {
				mes_ano: `${mounth < 10 ? '0' : ''}${mounth}/${ano}`,
				quantidade: currencyToNumber(consenso[mounth]),
			};
		});

		const body: BodyCreateConsensus = {
			ciclo_id: valuesFilter.ciclo?.value,
			familia_id: familyId,
			consensos: consensoBody,
		};

		mutate(body);

		let exceptionsBody: Array<{
			mes_ano: string;
			consenso: number;
			familia_id: number | string;
			produto_id: number | string;
			ciclo_id: number | string | undefined;
		}> = [];

		Object.entries(exceptions).forEach(([id, excecao]) => {
			const excecaoProduto: any = productExceptions[id];
			if (!excecaoProduto || excecao.delete === true) {
				toast({
					title: 'Exceção não cadastrada!',
					description: !excecaoProduto
						? `Selecione um produto para realizar a exceção.`
						: `Se quiser adicionar a exceção, clique no botão com ícone "+" para realizar o consenso do produto: ${excecaoProduto?.codigo_produto}`,
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
				return;
			}

			const consensusExcecaoBody: Array<{
				mes_ano: string;
				consenso: number;
				familia_id: number | string;
				produto_id: number | string;
				ciclo_id: number | string | undefined;
			}> = Array.from({
				length: cycleDuration,
			}).map((_, i) => {
				const mounth = ((cycle + i) % 12) + 1;
				const year =
					(i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
				return {
					mes_ano: `${year}-${mounth < 10 ? '0' : ''}${mounth}-01`,
					consenso: currencyToNumber(String(excecao[mounth])),
					familia_id: familyId,
					produto_id: excecaoProduto.id,
					ciclo_id: valuesFilter.ciclo?.value,
				};
			});

			exceptionsBody = [...exceptionsBody, ...consensusExcecaoBody];
		});
		exceptionsBody.length > 0 && mutateCreateException(exceptionsBody);
	};

	const clickCancel = () => {
		updateConsensusByDatabase();
		updateConsensusExeptionByDatabase();
	};
	const onChangeProduct = (
		idExcecao: number | string,
		produto: ProductException,
	) => {
		setProductExceptions({
			...productExceptions,
			[String(idExcecao)]: produto,
		});
	};

	const onDelete = (id: string) => {
		const newExceptions = { ...exceptions };
		delete newExceptions[id];

		const newId =
			id.length > 13 ? id.substring(13) : id.substring(id.length - 4);

		deleteConsensusException({
			page: keysPage.page,
			cycleId,
			productId: newId,
		});

		setExceptions(newExceptions);
	};

	const goToaccuracy = () => {
		history.push(`/${pathCompanyName}/demanda/acuracidade`);
	};

	const { mutate: mutateAppyConsensusApportioment } =
		useApplyConsensusApportionment();

	const onSubmitApplyConsensusApportionment = () => {
		const body = {
			ciclo_id: cycleId,
		};
		mutateAppyConsensusApportioment(body);
	};
	const exceptionIds = Object.keys(exceptions)
		.map((key) => +productExceptions[key]?.id)
		.filter((id) => id);

	useEffect(() => {
		if (extraResult?.hasData && extraResult?.isInvalidData) onOpen();
	}, [extraResult, extraResult?.hasData, extraResult?.isInvalidData, onOpen]);

	return isLoadingConsensusData ||
		isLoadingCollaborationByFamily ||
		isLoadingFilters ? (
		<LoadingSpinner h='50vh' />
	) : (
		<Flex flexDirection={'column'}>
			{showModalConsenso && (
				<ModalComment
					header='Ata de Consenso'
					description='Escreva aqui suas observações sobre este consenso'
					onClose={() => setShowModalConsenso(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showModalConsenso}
					valuesFilter={valuesFilter}
				/>
			)}
			<StatisticTable
				icon={IconHandStop}
				sectionError={Object.values(consensus).some(
					(number) => number === 0 || number === '',
				)}
				SectionButtons={
					<Flex
						cursor={'pointer'}
						gap={'10px'}
						marginBottom='10px'
						flexGrow={1}
						justifyContent={'flex-end'}
					>
						<TooltipStandard
							label='Recalcular Plano Irrestrito de todas as Famílias'
							shouldWrapChildren={true}
						>
							<ButtonComponent
								type='icon'
								iconType={<IconRefreshAlert />}
								onClick={onSubmitApplyConsensusApportionment}
							/>
						</TooltipStandard>
						<TooltipStandard
							label='Exportar CSV'
							shouldWrapChildren={true}
						>
							<ButtonExportCSV />
						</TooltipStandard>
						<TooltipStandard
							label='ATA de Consenso'
							shouldWrapChildren={true}
						>
							<ButtonComponent
								onClick={() => setShowModalConsenso(true)}
								iconType={<IconNotebook />}
								type='icon'
							/>
						</TooltipStandard>
						<TooltipStandard
							label='Acuracidade'
							shouldWrapChildren={true}
						>
							<ButtonComponent
								type='icon'
								iconType={<IconTargetArrow />}
								onClick={goToaccuracy}
							/>
						</TooltipStandard>
					</Flex>
				}
				name={'Consenso'}
				columnsData={{
					header: [columnFirtDataConsensoCadastrar],
					body: collumnsMounths,
					footer: columnsEndCadastrar,
				}}
				tableData={[...rowsColabs, consenso, previsaoReceita]}
				useInputMoney
			/>
			{showErrorComment && !ata && (
				<ErrorComment
					onClose={() => setShowErrorComment(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showErrorComment}
				/>
			)}
			{Object.keys(exceptions).map((id) => {
				const excecaoProduto = productExceptions[id];
				return (
					<SectionExceptions
						colaboracoes={
							exceptionsColaboracaoes[excecaoProduto?.id] || []
						}
						key={id}
						exceptionId={id}
						onChangeProduct={onChangeProduct}
						onDelete={onDelete}
						exceptionIds={exceptionIds}
						exceptions={exceptions}
						setExceptions={setExceptions}
						productExceptions={productExceptions}
					/>
				);
			})}
			<Flex
				justifyContent={'space-between'}
				id='buttonSave'
			>
				<ButtonComponent
					data-test='button-adicionar_a_excecao-consensos'
					title='Adicionar a exceção'
					onClick={() => addExcecao()}
				/>
				<Flex gap='10px'>
					<ButtonComponent
						data-test='button-cancelar-page-consensos'
						title='Cancelar'
						onClick={clickCancel}
					/>
					<ButtonComponent
						data-test='button-salvar-page-consensos'
						title='Salvar'
						onClick={createConsenso}
						type='primary'
						isDisabled={!ata && showErrorComment}
					/>
				</Flex>
			</Flex>
			{isFinished && (
				<Flex
					justifyContent={'end'}
					marginTop='20px'
					flexDirection={'column'}
					alignItems='end'
					gap={'16px'}
				>
					<ButtonComponent
						data-test='button-ir_para_analise_de_capacidade-consensos'
						title='Ir para Análise de Capacidade'
						type='primary'
						onClick={() =>
							history.push('../../analiseCapacidade/dashboard')
						}
					/>
				</Flex>
			)}
			{allowFinalize && (
				<Flex
					justifyContent={'end'}
					marginTop='20px'
					flexDirection={'column'}
					alignItems='end'
				>
					<Tooltip
						label='Adicionar valor 0 para as famílias não colaboradas e finalizar colaboração'
						placement='top'
						bg='gray.200'
						color='blue.800'
					>
						<span>
							<ButtonComponent
								data-test='button-finalizar_consenso-page-main-consensos'
								title='Finalizar Consenso'
								type='primary'
								onClick={() =>
									finalizar({
										ciclo_id: cycleId,
									})
								}
							/>
						</span>
					</Tooltip>
				</Flex>
			)}
			<ModalAiPredictionRequest
				isOpen={isOpen}
				onClose={onClose}
				message={extraResult?.message}
			/>
		</Flex>
	);
};

export default SectionConsensus;
