import React from 'react';
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Flex,
	Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { LoadingSpinner } from 'components/loading/Loading';

const SelectComponent = ({ options, value, onChange, isLoading }) => {
	const isIa = value === 'aiPrediction';
	const isManual = value === 'manual';

	const currentValue =
		options?.find((option) => option.value === value)?.label ||
		'Não especificado';

	const cssOptions = isIa
		? {
				color: 'easyRED.300',
				emptyColor: 'easyRED.400',
				size: 'md',
		  }
		: {};

	return (
		<Menu>
			<MenuButton
				data-test='select-colab_consenso'
				as={Button}
				bg={
					isIa
						? 'linear-gradient(180deg, rgba(240,36,62,1) 0%, rgba(176,26,46,1) 100%)'
						: undefined
				}
				color={isIa ? 'white' : 'undefined'}
				border='1px solid rgb(226, 232, 240)'
				textAlign='left'
				width='12rem'
				height='56px'
				rightIcon={
					!isManual && isLoading ? (
						<Flex>
							<LoadingSpinner
								message={false}
								size={'sm'}
								speed={'1.80s'}
								label={
									isIa
										? 'Processamento IA em andamento...'
										: 'Carregando...'
								}
								{...cssOptions}
							/>
							<ChevronDownIcon />
						</Flex>
					) : (
						<ChevronDownIcon />
					)
				}
				_hover={
					isIa
						? {
								background:
									'linear-gradient(180deg, rgba(240,36,62,1) 0%, rgba(176,26,46,1) 100%)',
						  }
						: { background: undefined }
				}
				_active={
					isIa
						? {
								background:
									'linear-gradient(180deg, rgba(240,36,62,1) 0%, rgba(176,26,46,1) 100%)',
						  }
						: { background: undefined }
				}
			>
				{currentValue}
			</MenuButton>
			<MenuList>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.value}
						onClick={() => onChange(option.value)}
					>
						{option.label}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default SelectComponent;
