import { useMutation } from '@tanstack/react-query';
import { getSubFamiliesByFiltersAndLimit } from 'services/api/requests/requests';
import { IAutocomplete } from '../../../utils/forms/autocomplete';

export const useSubFamilyListAsMutate = ({
	busca,
	limit,
	orderBy,
	sort,
	familia_id,
}: IAutocomplete) => {
	const _order = orderBy || 'nome_sub_familia';
	const _sort = sort || 'ASC';

	return useMutation(() =>
		getSubFamiliesByFiltersAndLimit(
			busca,
			limit,
			_order,
			_sort,
			familia_id,
		),
	);
};
