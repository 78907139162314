import { Flex, FormLabel, Text, Tooltip } from '@chakra-ui/react';
import { IconHelp } from '@tabler/icons';

export const InputFormLabel = (props) => {
	const {
		label,
		labelTooltip,
		placement,
		color = 'easyBLUE.300',
		...others
	} = props;
	const { isRequired = false, ...rest } = others;

	const helpStyle = {
		color: '#A3AED0',
		cursor: 'pointer',
	};

	const requiredStyle = {
		color: '#e53e3e',
	};

	function renderRequiredIcon() {
		return <span style={requiredStyle}>*</span>;
	}

	return (
		<FormLabel
			{...rest}
			mb='1px'
		>
			<Flex
				gap={1}
				alignItems={'center'}
			>
				<Text color={color}>{label}</Text>
				{isRequired ? renderRequiredIcon() : null}
				{labelTooltip && (
					<Tooltip
						label={labelTooltip}
						placement={placement ? placement : 'top'}
						bg='gray.200'
						color='blue.800'
						hasArrow
						arrowSize={15}
					>
						<span style={helpStyle}>
							<IconHelp size={16} />
						</span>
					</Tooltip>
				)}
			</Flex>
		</FormLabel>
	);
};
