import { useQuery } from '@tanstack/react-query';
import { getDownloadTemplateProductionRoadmap } from 'services/api/requests/uploads/uploads';

export const useDownloadTemplateProductionRoadmap = () => {
	return useQuery(
		['download-template-productionRoadmap'],
		getDownloadTemplateProductionRoadmap,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		},
	);
};
