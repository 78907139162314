import { editResource } from 'services/api/requests/resources';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useEditResource = (body: any, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editResource(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['recurso-list']);
			queryClient.invalidateQueries(['resourceWithId', id]);
			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Recurso atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
            				error,
            				'Erro ao atualizar recurso!',
            			);
            			toastErrors?.forEach((element) => toast(element));
		},
	});
};
