import { useQuery } from '@tanstack/react-query';
import { getLineRestrictions } from 'services/api/requests/lineRestrictions';
export const useGetLineRestrictions = () => {
	return useQuery(['LineRestrictions'], getLineRestrictions, {
		refetchOnWindowFocus: false,
		onError: (error) => {
			console.log(error);
		},
	});
};
