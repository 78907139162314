import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Select,
	Switch,
	Text,
} from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ButtonComponent } from '../../../../../../components/buttons/ButtonComponent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { calculateIntervalInRangeTimes as calcularTempoTotalTurno } from '../../../../../../utils/functions/dateUtils';
import { InputFormLabel } from '../../../../../../components/fields/InputFormLabel';

const SectionConfig = ({ ...props }) => {
	const { onSubmit, initialValues, setChangedForm, setReset } = props;

	const [horasSemanais, setHorasSemanais] = useState<any>();
	const [regimeTrabalho, setRegimeTrabalho] = useState<any>();
	const [sabadoIntegral, setSabadoIntegral] = useState<any>();
	const [totalTurnos, setTotalTurnos] = useState(1);

	const [showSwitch, setShowSwitch] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		getValues,
		reset,
	} = useForm({});

	setChangedForm(
		!(JSON.stringify(getValues()) === JSON.stringify(initialValues)),
	);

	const { fields, remove, append } = useFieldArray({
		name: 'turnos',
		control,
	});

	const horasSemanaOpcoes = useMemo(
		() => [
			<option value='40'>40 horas</option>,
			<option value='44'>44 horas</option>,
		],
		[],
	);

	const regimeTrabalhoOpcoes = useMemo(
		() => [
			<option value='true'>Segunda à sexta</option>,
			<option value='false'>Segunda à sábado</option>,
		],
		[],
	);

	const turnoOpcoes = useCallback(() => {
		const options = [
			<option value='1'>1 turno</option>,
			<option value='2'>2 turnos</option>,
		];

		if (
			getValues()?.horas_semana !== '44' ||
			getValues()?.regime === 'false'
		)
			options.push(<option value='3'>3 turnos</option>);
		else if (totalTurnos === 3) {
			setTotalTurnos(2);
			setValue('turno', '2');
		}

		return options;
	}, [getValues, totalTurnos, setValue]);

	const valorInicialTurno = useMemo(
		() => ({
			hora_inicio: '',
			hora_fim: '',
			duracao: '',
		}),
		[],
	);

	const calculateTotalShift = useCallback(() => {
		getValues()?.turnos?.forEach((element, index) => {
			const { hora_inicio, hora_fim } = element;

			if (!!hora_inicio && !!hora_fim) {
				setValue(
					`turnos.${index}.duracao`,
					calcularTempoTotalTurno(hora_inicio, hora_fim)?.format?.(
						'HH:mm',
					),
				);
			}
		});
	}, [getValues, setValue]);

	const renderSelectInput = useCallback(
		({ label, key, options, width, configOption }) => {
			return (
				<FormControl
					mr='5px'
					className='filter-bar'
					isInvalid={!!errors[key]}
				>
					<FormLabel>{label}</FormLabel>
					<Select
						data-test={`select-filtrar-${configOption}`}
						key={key}
						w={width}
						defaultValue={initialValues[key]}
						value={getValues()[key]}
						{...register(key, {
							required: 'Campo obrigatório',
						})}
					>
						{options}
					</Select>

					<FormErrorMessage>
						{!!errors[key] && errors[key]?.message}
					</FormErrorMessage>
				</FormControl>
			);
		},
		[errors, getValues, initialValues, register],
	);

	const renderInputTime = useCallback(
		({ element, index, label, key, turnoOption, ...props }) => {
			const isInvalid =
				!!(errors?.turnos as any)?.length &&
				(errors?.turnos as any)[index];
			return (
				<FormControl
					mb='20px'
					isInvalid={isInvalid}
				>
					<FormLabel mb='0'>{label}</FormLabel>
					<Input
						data-test={`preencher-horario-${turnoOption}`}
						key={`${element?.id}-${key}`}
						h='44px'
						borderRadius={10}
						type='time'
						{...props}
						{...register(`turnos.${index}.${key}`, {
							required: 'Campo Obrigatório',
						})}
					/>

					<FormErrorMessage>
						{isInvalid &&
							(errors?.turnos as any)[index]?.[key]?.message}
					</FormErrorMessage>
				</FormControl>
			);
		},
		[errors?.turnos, register],
	);

	const renderTurnos = useCallback(() => {
		return fields.map((el, index) => (
			<Flex
				gap={3}
				w='100%'
			>
				<Flex
					w='240px'
					fontWeight='500'
				>
					<Text mt='50%'>Turno {index + 1}</Text>
				</Flex>
				{renderInputTime({
					turnoOption: 'hora_inicio',
					el,
					index,
					key: 'hora_inicio',
					label: 'Hora Inicial',
					width: '200px',
				})}

				{renderInputTime({
					turnoOption: 'hora_fim',
					el,
					index,
					key: 'hora_fim',
					label: 'Hora Final',
					width: '200px',
				})}

				{renderInputTime({
					turnoOption: 'duracao',
					el,
					index,
					key: 'duracao',
					label: 'Tempo de produção',
					width: '200px',
					disabled: true,
				})}
			</Flex>
		));
	}, [fields, renderInputTime]);

	const resetFormFields = useCallback(() => {
		setReset(true);
		reset(initialValues);
	}, [initialValues, reset, setReset]);

	const renderForm = useCallback(() => {
		return (
			<Flex w='100%'>
				<form
					onSubmit={handleSubmit(onSubmit)}
					style={{ width: '100%' }}
				>
					<Flex
						direction='column'
						w='50%'
					>
						<Flex
							gap={3}
							mb={5}
						>
							{renderSelectInput({
								label: 'Horas Semanais',
								key: 'horas_semana',
								options: horasSemanaOpcoes,
								width: '250px',
								configOption: 'horas_semana',
							})}

							{renderSelectInput({
								label: 'Regime de Trabalho',
								key: 'regime',
								options: regimeTrabalhoOpcoes,
								width: '250px',
								configOption: 'regime',
							})}

							{renderSelectInput({
								label: 'Turno',
								key: 'turno',
								options: turnoOpcoes(),
								width: '210px',
								configOption: 'turno',
							})}
						</Flex>
						<Flex
							gap={3}
							mb={1}
							direction='column'
						>
							<Text
								fontSize={'20px'}
								fontWeight={600}
								color={'easyBLUE.300'}
							>
								Turnos
							</Text>
							{renderTurnos()}
						</Flex>

						{showSwitch && (
							<Flex
								gap={3}
								mb={5}
							>
								<FormControl
									mr='5px'
									className='filter-bar'
								>
									<InputFormLabel
										label='Você trabalha turno integral aos sábados'
										labelTooltip={
											showTooltip
												? `Observação: ao configurar 44 horas semanais de segunda à sábado, 
											sendo o sábado dia integral, aumenta a quantidade de horas totais trabalhadas na semana!`
												: null
										}
									/>
									<Switch
										key='sabado_inteiro'
										colorScheme='green'
										size='sm'
										{...register('sabado_inteiro')}
									/>
								</FormControl>
							</Flex>
						)}
					</Flex>
					<Flex
						justifyContent='end'
						gap={3}
					>
						<ButtonComponent
							type={'ghost'}
							title={'Cancelar'}
							onClick={resetFormFields}
						/>
						<ButtonComponent
							data-test='button-salvar-page-dias_uteis'
							type={'primary'}
							title={'Salvar'}
							action={'submit'}
							isDisabled={!!(errors?.turnos as any)?.length}
						/>
					</Flex>
				</form>
			</Flex>
		);
	}, [
		showSwitch,
		handleSubmit,
		horasSemanaOpcoes,
		regimeTrabalhoOpcoes,
		register,
		renderSelectInput,
		renderTurnos,
		turnoOpcoes,
		errors?.turnos,
		showTooltip,
		onSubmit,
		resetFormFields,
	]);

	useEffect(() => {
		const subscription = watch((value, { name = '' }) => {
			if (name === 'horas_semana') setHorasSemanais(value[name]);

			if (name === 'regime') {
				setShowSwitch(value[name] === 'false');
				setRegimeTrabalho(value[name]);
			}

			if (name === 'turno') setTotalTurnos(Number(value[name]));

			if (name === 'sabado_inteiro') setSabadoIntegral(value[name]);

			if (name.includes('hora_')) calculateTotalShift();
		});
		return () => subscription.unsubscribe();
	}, [calculateTotalShift, watch]);

	useEffect(() => {
		if (fields?.length === 0) {
			for (let i = 0; i < totalTurnos; i++) {
				append({ ...valorInicialTurno });
			}
		} else if (fields?.length > totalTurnos) {
			let countTurnosRemover = fields.length - totalTurnos;

			while (countTurnosRemover > 0) {
				remove(fields.length - 1);
				countTurnosRemover--;
			}
			calculateTotalShift();
		} else if (fields?.length < totalTurnos) {
			let countTurnosAdicionar = totalTurnos - fields.length;

			for (let i = 0; i < countTurnosAdicionar; i++) {
				append({ ...valorInicialTurno });
			}
			calculateTotalShift();
		}
	}, [
		totalTurnos,
		fields,
		append,
		remove,
		valorInicialTurno,
		calculateTotalShift,
	]);

	useEffect(() => {
		if (
			horasSemanais === '44' &&
			regimeTrabalho === 'false' &&
			totalTurnos === 1 &&
			sabadoIntegral
		)
			setShowTooltip(true);
		else setShowTooltip(false);
	}, [horasSemanais, regimeTrabalho, totalTurnos, sabadoIntegral]);

	useEffect(() => {
		if (initialValues && Object.keys(initialValues)?.length) {
			reset(initialValues);

			setHorasSemanais(initialValues?.horas_semana);
			setRegimeTrabalho(initialValues?.regime);
			setSabadoIntegral(initialValues?.sabado_inteiro);
			setTotalTurnos(Number(initialValues?.turno));

			if (initialValues?.regime === 'false') setShowSwitch(true);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (!showSwitch) setValue('sabado_inteiro', false);
	}, [showSwitch, setValue]);

	useEffect(() => {
		reset(initialValues);
	}, [reset, initialValues]);

	return (
		<Flex
			w='100%'
			direction='column'
		>
			<Flex w='100%'>
				<Text
					fontSize={'20px'}
					fontWeight={600}
					color={'easyBLUE.300'}
				>
					Configurações
				</Text>
			</Flex>

			{renderForm()}
		</Flex>
	);
};

export default SectionConfig;
