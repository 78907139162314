import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from '../loading/Loading';
import GenericForm, { IFormRows } from './GenericForm';
import {
	columnFlexProps,
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from '../../utils/forms/defaultsProps';
import { useHistory } from 'react-router-dom';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import {
	validateByRegex,
	validateMin,
	validateRequired,
} from '../../utils/forms/validations';
import moment from 'moment';
import { useSalesChannelList } from '../../services/queryClient/wrapperHooks/useSalesChannelList';
import { useSalesZoneList } from '../../services/queryClient/wrapperHooks/useSalesZoneList';
import {
	renderClient,
	renderFamily,
	renderProduct,
} from '../../utils/forms/autocomplete';
import { useCreateSale } from '../../services/queryClient/wrapperHooks/useCreateSale';
import { useEditSale } from '../../services/queryClient/wrapperHooks/useEditSale';
import { currencyToNumber } from '../../utils/functions/currencyToNumber';

interface IFormCadastrarEditarVendas {
	initialState: any;
	saleId?: number;
	autocompleteAdditionalData?: any;
}

const FormCadastrarEditarVendas = ({
	initialState,
	saleId = 0,
	autocompleteAdditionalData,
}: IFormCadastrarEditarVendas) => {
	const history = useHistory();

	const isEditing = !!saleId;

	const { data: SalesChannelData, isLoading: isLoadingSalesChannel } =
		useSalesChannelList();
	const { data: SalesZoneData, isLoading: IsLoadingSalesZone } =
		useSalesZoneList();

	const [saleChannelId, setSaleChannelId] = useState<number>();
	const [familyId, setFamilyId] = useState<number>();
	const [payload, setPayload] = useState<any>();

	const { mutate: createSale } = useCreateSale(payload);
	const { mutate: updateSale } = useEditSale(payload, saleId);

	useEffect(() => {
		setSaleChannelId(initialState?.canal_venda_id);
		setFamilyId(initialState?.familia_id);
	}, [initialState]);

	const goToVendas = useCallback(() => history.goBack(), [history]);

	const getMaxDate = useMemo(
		() => moment().startOf('day').format('YYYY-MM-DD'),
		[],
	);

	const channelOptions = useMemo(() => {
		return (
			SalesChannelData?.map((s) => (
				<option value={s?.id}>{s?.nome_canal_venda}</option>
			)) || []
		);
	}, [SalesChannelData]);

	const zoneOptions = useMemo(() => {
		return (
			SalesZoneData?.data
				?.filter((z) => z.canal_venda_id === saleChannelId)
				?.map((s) => (
					<option value={s?.id}>{s?.nome_zona_venda}</option>
				)) || []
		);
	}, [SalesZoneData, saleChannelId]);

	const handleOnChangeChannel = useCallback((event) => {
		setSaleChannelId(Number(event?.target?.value));
	}, []);

	const handleOnChangeFamily = useCallback((value) => {
		setFamilyId(value?.id);
	}, []);

	const handleOnChangeNF = useCallback((event, form) => {
		form.setValue('sequencia_nf', '');
	}, []);

	const isDisabledZoneSale = useCallback(() => {
		return !saleChannelId;
	}, [saleChannelId]);

	const isDisabledProductAutocomplete = useCallback(() => {
		return !familyId;
	}, [familyId]);

	const shouldClearAutocompleteProduct = useCallback(() => {
		if (!familyId) return false;

		return familyId !== autocompleteAdditionalData?.product?.familia?.id;
	}, [familyId, autocompleteAdditionalData?.product?.familia?.id]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				// Necessário enviar como datetime pois caso contrário o back devolve a data com
				// 1 dia a menos durante a edição
				data_venda:
					moment(values?.data_venda, 'YYYY-MM-DD').toISOString() ||
					null,
				nf: values?.nf || null,
				sequencia_nf: values?.sequencia_nf,
				canal_venda_id: values?.canal_venda_id
					? Number(values?.canal_venda_id)
					: null,
				zona_venda_id: values?.zona_venda_id
					? Number(values?.zona_venda_id)
					: null,
				cliente_id: values?.cliente_id || null,
				familia_id: values?.familia_id || null,
				produto_id: values?.produto_id || null,
				quantidade: values?.quantidade
					? currencyToNumber(values?.quantidade)
					: null,
				receita_bruta: values?.receita_bruta
					? currencyToNumber(values?.receita_bruta)
					: null,
			};

			setPayload(body);

			if (isEditing) updateSale();
			else createSale();
		},
		[createSale, isEditing, updateSale],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nf',
							label: 'NF',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-nf-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								onChange: handleOnChangeNF,
							},
							columnFlexProps: {
								...columnFlexProps,
								marginRight: '30px',
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'number',
							name: 'sequencia_nf',
							label: 'Sequência da Nota Fiscal',
							validate: {
								...validateMin(
									1,
									'O valor deve ser maior ou igual a 1!',
								),
								...validateByRegex(
									/^[1-9]\d*$/,
									'O valor deve ser um número inteiro válido!',
								),
							},
							inputProps: {
								'data-test':
									'input-sequencia_da_nota_fiscal-cadastrar_editar-page-vendas',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'date',
							name: 'data_venda',
							label: 'Data da NF',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-data_da_nf-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								max: getMaxDate,
							},
						}),
					},
				],
			},

			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'canal_venda_id',
							label: 'Canal de Venda',
							inputProps: {
								'data-test':
									'select-canal_de_venda-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								selectOptions: channelOptions,
								onChange: handleOnChangeChannel,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'zona_venda_id',
							label: 'Zona de Venda',
							disabled: isDisabledZoneSale,
							inputProps: {
								'data-test':
									'select-zona_de_venda-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								selectOptions: zoneOptions,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'cliente_id',
							label: 'Razão Social',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-razao_social-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								searchType: 'cliente',
								renderInputLabel: renderClient,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.client
									? renderClient(
											autocompleteAdditionalData?.client,
									  )
									: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'familia_id',
							label: 'Família',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'autocomplete-familia-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								searchType: 'familia',
								renderInputLabel: renderFamily,
								autocomplete: 'off',
								initialLabel: autocompleteAdditionalData?.family
									? renderFamily(
											autocompleteAdditionalData?.family,
									  )
									: '',
								onChange: handleOnChangeFamily,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete',
							name: 'produto_id',
							label: 'Produto',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							disabled: isDisabledProductAutocomplete,
							inputProps: {
								'data-test':
									'autocomplete-produto-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								searchType: 'produto',
								renderInputLabel: renderProduct,
								autocomplete: 'off',
								initialLabel:
									autocompleteAdditionalData?.product
										? renderProduct(
												autocompleteAdditionalData?.product,
										  )
										: '',
								extraRequestData: {
									familia_id: familyId,
								},
								clearInput: shouldClearAutocompleteProduct,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'product-quantity',
							name: 'quantidade',
							label: 'Quantidade',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-quantidade-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								allowNegativeValue: true,
								prefix: '',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'decimal',
							name: 'receita_bruta',
							label: 'Receita Bruta',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-receita_bruta-cadastrar_editar-page-vendas',
								...defaultInputStyle,
								allowNegativeValue: true,
							},
						}),
					},
				],
			},
		];
	}, [
		handleOnChangeNF,
		channelOptions,
		handleOnChangeChannel,
		isDisabledZoneSale,
		zoneOptions,
		autocompleteAdditionalData?.client,
		autocompleteAdditionalData?.family,
		autocompleteAdditionalData?.product,
		handleOnChangeFamily,
		isDisabledProductAutocomplete,
		familyId,
		shouldClearAutocompleteProduct,
	]);

	const renderForm = useCallback(() => {
		if (isLoadingSalesChannel || IsLoadingSalesZone)
			return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-vendas',
						save: 'button-salvar-page-vendas',
					}}
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToVendas}
				/>
			</>
		);
	}, [
		initialState,
		onSubmit,
		renderRows,
		goToVendas,
		isLoadingSalesChannel,
		IsLoadingSalesZone,
	]);

	return renderForm();
};

export default FormCadastrarEditarVendas;
