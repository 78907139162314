import api from '../api';

export const getCustomerToCollaborationConsensusByNameOrCnpjWithLimit = async (
	busca?: string,
	limit?: Number,
	orderBy?: string,
	sort?: string,
	extraRequestData?: any,
) => {
	const { ciclo_id, ...others } = extraRequestData || {};

	const payload = {
		busca,
		limit,
		orderBy,
		sort,
		ciclo_id,
		...others,
	};

	const response = await api.post(`venda/demand/clients/filter`, payload);
	return {
		rows: response.data,
		count: response.data.length === 0 ? 0 : response.data.length + 1,
	};
};
