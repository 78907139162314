import { SidebarHelp } from 'models/sidebar-help.model';

export interface Month {
	id: number;
	name: string;
	abbreviated: string;
}

export const months: Month[] = [
	{ id: 1, name: 'Janeiro', abbreviated: 'Jan' },
	{ id: 2, name: 'Fevereiro', abbreviated: 'Fev' },
	{ id: 3, name: 'Março', abbreviated: 'Mar' },
	{ id: 4, name: 'Abril', abbreviated: 'Abr' },
	{ id: 5, name: 'Maio', abbreviated: 'Mai' },
	{ id: 6, name: 'Junho', abbreviated: 'Jun' },
	{ id: 7, name: 'Julho', abbreviated: 'Jul' },
	{ id: 8, name: 'Agosto', abbreviated: 'Ago' },
	{ id: 9, name: 'Setembro', abbreviated: 'Set' },
	{ id: 10, name: 'Outubro', abbreviated: 'Out' },
	{ id: 11, name: 'Novembro', abbreviated: 'Nov' },
	{ id: 12, name: 'Dezembro', abbreviated: 'Dez' },
];

export const helpRateio: SidebarHelp = {
	title: 'Rateio',
	firstParagraph:
		'O rateio representa a porcentagem em vendas de cada produto em sua respectiva família e canal de venda. O rateio é calculado com base no histórico de vendas. Certifique-se de ter incluído os produtos desejados na participação do rateio, isso pode ser feito em Cadastros > Produto.',
	secondParagraph:
		'Para alterar o valor de rateio de algum produto, filtre o Ciclo e o Canal desejados, no campo do mês e produto desejados entre com o valor em porcentagem. Clique em “Salvar”. Atenção: o total do rateio sempre deverá totalizar 100%, caso contrário os valores de entrada estarão errados e deverão ser ajustados!',
	thirdParagraph: `O botão Recalcular irá calcular o rateio automaticamente com base no histórico de vendas.`,
	// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
	firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
	secondLink: 'https://calendly.com/suporte-easy360',
};
