import {
	Box,
	ButtonGroup,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateAta } from 'services/queryClient/wrapperHooks/useCreateAta';
import IconManager from '../icons/IconManager';
import DiscardChangesModal from './DiscardChangesModal';

interface AtaModalProps {
	isOpen: boolean;
	onClose: () => void;
	provider?: string;
	data?: any;
	dayMonthYearValidation?: any;
	monthValidation?: any;
	getUpdatedData?: () => void;
}

const AtaModal = (props: AtaModalProps) => {
	const {
		isOpen,
		onClose,
		provider,
		data,
		dayMonthYearValidation,
		monthValidation,
		getUpdatedData,
	} = props;

	const {
		isOpen: isOpenDiscardChange,
		onOpen: onOpenDiscardChange,
		onClose: onCloseDiscardChange,
	} = useDisclosure();

	const { permissionsPage } = useGlobalContext();

	const [message, setMessage] = useState('');
	const [initialMessage, setInitialMessage] = useState('');

	const actualDate = moment().format('YYYY-MM-DD');
	const actualDay = moment().format('DD');
	const actualMonth = moment().format('M');
	const actualYear = moment().format('YYYY');

	const dayValidationFormatProducao =
		dayMonthYearValidation &&
		dayMonthYearValidation['diasOptions']?.length > 0
			? dayMonthYearValidation['diasOptions'][
					dayMonthYearValidation['diasOptions'].length - 1
			  ]?.value
			: actualDay;

	const monthValidationFormatProducao =
		dayMonthYearValidation &&
		dayMonthYearValidation['mesesOptions']?.length > 0
			? dayMonthYearValidation['mesesOptions'][
					dayMonthYearValidation['mesesOptions'].length - 1
			  ]?.value
			: actualMonth;

	const yearValidationFormatProducao =
		dayMonthYearValidation &&
		dayMonthYearValidation['anosOptions']?.length > 0
			? dayMonthYearValidation['anosOptions'][
					dayMonthYearValidation['anosOptions'].length - 1
			  ]?.value
			: actualYear;

	const hasChanged = useMemo(() => {
		return message !== initialMessage;
	}, [message, initialMessage]);

	useEffect(() => {
		setMessage(data?.anotacao);
		setInitialMessage(data?.anotacao);
	}, [data]);

	const body = {
		tipo: provider,
		anotacao: message,
		data: monthValidation
			? moment(`${actualYear}/${monthValidation}/01`).format('YYYY-MM-DD')
			: (dayMonthYearValidation &&
					moment(
						`${
							dayMonthYearValidation['mesesOptions']?.[
								dayMonthYearValidation['mesesOptions']?.length -
									1
							]?.value
						}/${
							dayMonthYearValidation['diasOptions']?.[
								dayMonthYearValidation['diasOptions']?.length -
									1
							]?.value
						}/${
							dayMonthYearValidation['anosOptions']?.[
								dayMonthYearValidation['anosOptions']?.length -
									1
							]?.value
						}`,
					).format('YYYY-MM-DD')) ||
			  actualDate,
	};

	const canEditPreviousAtas =
		permissionsPage?.['atasPassadas']?.editar || false;

	const isDisabledProducao = !canEditPreviousAtas
		? dayValidationFormatProducao < actualDay ||
		  monthValidationFormatProducao < actualMonth ||
		  yearValidationFormatProducao < actualYear
		: false;

	const isDisabledCapacidade = !canEditPreviousAtas
		? monthValidation < actualMonth
		: false;

	const { mutate } = useCreateAta(body, true);

	const createAta = (event) => {
		event.preventDefault();

		setInitialMessage(message);

		mutate();

		setTimeout(() => getUpdatedData?.(), 200);
	};

	const handleDiscardChanges = () => {
		setMessage(data?.anotacao);

		setTimeout(() => onClose(), 200);
	};

	const resetDefaultValues = useCallback(() => {
		setMessage(data?.anotacao);
	}, [data?.anotacao]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={hasChanged ? onOpenDiscardChange : onClose}
				onEsc={hasChanged ? onOpenDiscardChange : onClose}
				isCentered
				size='6xl'
			>
				<ModalOverlay />
				<ModalContent borderRadius={20}>
					<ModalHeader
						display='flex'
						flexDir={'column'}
					>
						<IconManager
							type='edit'
							color={'easyRED.300'}
						/>
						<Flex
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Text
								fontSize='2xl'
								fontWeight={700}
								color={'easyBLUE.300'}
							>
								Registro de Ata
							</Text>
							<Box>
								<Text
									fontSize='md'
									fontWeight={700}
									color={'easyBLUE.300'}
								>
									Data:{' '}
									<Text
										as={'span'}
										fontSize='md'
										fontWeight={400}
										color={'secondaryGray.500'}
									>
										{monthValidation
											? moment().format('DD/MM/YYYY')
											: dayMonthYearValidation &&
											  moment(
													`${
														dayMonthYearValidation[
															'mesesOptions'
														]?.[
															dayMonthYearValidation[
																'mesesOptions'
															]?.length - 1
														]?.value
													}/${
														dayMonthYearValidation[
															'diasOptions'
														]?.[
															dayMonthYearValidation[
																'diasOptions'
															]?.length - 1
														]?.value
													}/${
														dayMonthYearValidation[
															'anosOptions'
														]?.[
															dayMonthYearValidation[
																'anosOptions'
															]?.length - 1
														]?.value
													}`,
											  ).format('DD/MM/YYYY')}
									</Text>
								</Text>
								{monthValidation && (
									<Text
										fontSize='md'
										fontWeight={700}
										color={'easyBLUE.300'}
									>
										Ciclo:{' '}
										<Text
											as={'span'}
											fontSize='md'
											fontWeight={400}
											color={'secondaryGray.500'}
										>
											{monthValidation}
										</Text>
									</Text>
								)}
							</Box>
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Textarea
							data-test='text_area-registro_de_ata-ata-modal-dashboard_de_producao'
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							rows={10}
							disabled={
								isDisabledProducao || isDisabledCapacidade
							}
							placeholder='Descrever...'
						></Textarea>
					</ModalBody>

					<ModalFooter>
						<ButtonGroup spacing={'20px'}>
							<ButtonComponent
								data-test='button-cancelar-modal-dashboard_de_producao'
								type='ghost'
								title='Cancelar'
								onClick={handleDiscardChanges}
							/>
							<ButtonComponent
								data-test='button-salvar-modal-dashboard_de_producao'
								isDisabled={
									isDisabledProducao || isDisabledCapacidade
								}
								type='primary'
								title='Salvar'
								onClick={createAta}
							/>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<DiscardChangesModal
				isOpen={isOpenDiscardChange}
				onClose={onCloseDiscardChange}
				onClosePreviousModal={onClose}
				resetDefaultValues={resetDefaultValues}
			/>
		</>
	);
};

export default AtaModal;
