import { useMutation, useQueryClient } from '@tanstack/react-query';
import { pathProduct } from 'services/api/requests/requests';
import { useToast } from '@chakra-ui/react';
export const usePathProduct = (query, product) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => pathProduct(query, product), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries({
				predicate: (query: any) =>
					query.queryKey[0].inlcudes('produto-list'),
			});

			toast({
				title: 'Alterado com sucesso!',
				description: 'Produto alterado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			queryClient.invalidateQueries(['productNotFamily-list']);
			queryClient.invalidateQueries(['family-list']);

			toast({
				title: 'Erro ao alterar',
				description: error.response.data.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
