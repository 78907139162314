/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Flex,
	Input,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from '@chakra-ui/react';
import { TablerIcon } from '@tabler/icons';
import { useMemo } from 'react';

import {
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
} from 'react-table';

import moment from 'moment';

export type ColumnData = {
	Header: string;
	accessor: string;
	type: string;
};

export type Row = {
	type: 'text';
	values?: {};
	'1'?: number | string;
	'2'?: number;
	'3'?: number;
	'4'?: number;
	'5'?: number;
	'6'?: number;
	'7'?: number;
	'8'?: number;
	'9'?: number;
	'10'?: number;
	'11'?: number;
	'12'?: number;
	[key: string]: string | string[] | {} | number | undefined;
};

export type RowInput = {
	type: 'input';
	'1'?: number;
	'2'?: number;
	'3'?: number;
	'4'?: number;
	'5'?: number;
	'6'?: number;
	'7'?: number;
	'8'?: number;
	'9'?: number;
	'10'?: number;
	'11'?: number;
	'12'?: number;
	title?: string;
	errorConditional: (value: number | '') => boolean;
	preenchimento?: {
		title?: string;
		selectValues?: Array<string>;
		onChange?: (key: string, value: string) => void;
		onChangeSelect?: (value: string) => void;
		value?: string;
	};
};

interface PrpsStatisticTable {
	columnsData: ColumnData[];
	tableData: Array<Row | RowInput>;
	name: string;
	year?: number;
	icon?: TablerIcon;
	SectionButtons?: JSX.Element;
	sectionError?: boolean;
	dataType?: string;
}
export default function StatisticTable(props: PrpsStatisticTable) {
	const { columnsData, tableData, year, dataType } = props;

	const columns = useMemo(() => columnsData, [columnsData]);
	const data = useMemo(() => tableData, [tableData]);

	const tableInstance = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	);

	const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
		tableInstance;

	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	return (
		<>
			{' '}
			<Flex
				direction='column'
				w='100%'
				px='0px'
			>
				<Flex
					align={{ sm: 'flex-start', lg: 'flex-start' }}
					justify={'space-between'}
					w='100%'
					mb='36px'
					overflowX={'visible'}
				>
					<Table
						{...getTableProps()}
						w='100%'
						variant='simple'
						justify='space-between'
						color='gray.500'
						mb='5px'
					>
						<Thead>
							{headerGroups.map((headerGroup, index) => (
								<Tr
									{...headerGroup.getHeaderGroupProps()}
									key={index}
								>
									{headerGroup.headers.map(
										(column, index) => {
											const backgroundColors = {
												total: '#E9EDF7',
												media: '#F4F7FE',
											};
											return (
												<Th
													{...column.getHeaderProps(
														column.getSortByToggleProps(),
													)}
													alignItems='start'
													justifyContent={'start'}
													key={index}
													borderColor={borderColor}
													padding={0}
													h='60px'
													paddingX={'8px'}
													bgColor={
														backgroundColors[
															column.id
														]
													}
												>
													<Flex
														justify='space-between'
														padding='0'
														width={'100%'}
														paddingLeft='8px'
														fontSize={{
															sm: '10px',
															lg: '12px',
														}}
														color='gray.400'
													>
														{column.render(
															'Header',
														)}
													</Flex>
												</Th>
											);
										},
									)}
								</Tr>
							))}
						</Thead>
						<Tbody
							overflowX={'scroll'}
							gap='0'
							{...getTableBodyProps()}
						>
							{page.map((row, index): JSX.Element => {
								prepareRow(row);

								const componetsRows = {
									text: CellText,
									input: CellInput,
								};

								return (
									<Tr
										{...row.getRowProps()}
										key={index}
										w='100%'
									>
										{row.cells.map((cell, index) => {
											return (
												<>
													<Td
														data-test={`section-table-dias_uteis`}
														{...cell.getCellProps()}
														padding={0}
														h='60px'
														alignItems='center'
														marginRight={0}
														key={index}
														fontSize={{
															sm: '14px',
														}}
														borderColor={
															borderColor
														}
														paddingX={'8px'}
														position='relative'
													>
														{componetsRows[
															row.original.type
														]({
															cell,
															index,
															year,
														})}
													</Td>
												</>
											);
										})}
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</Flex>
			</Flex>
		</>
	);
}

const isDisabled = (index, year) => {
	const currentMonth = moment().month() + 1;
	const currentYear = Number(moment().format('YYYY'));

	if (index <= currentMonth && year <= currentYear) {
		return true;
	}

	return false;
};

const CellInput = ({ cell, index, year }): JSX.Element => {
	if (!isNaN(cell.column.id)) {
		return (
			<Flex
				width='100%'
				height={'100%'}
				paddingX='4px'
				paddingY='4px'
				alignItems={'center'}
			>
				<Input
					htmlSize={4}
					isInvalid
					height={'100%'}
					disabled={isDisabled(index, year)}
					errorBorderColor={'gray.500'}
					placeholder='0'
					borderRadius='2xl'
					value={cell.value}
					onChange={(e) =>
						cell.row.original.preenchimento.onChange(
							cell.column.id,
							e.target.value,
							cell.row.original.preenchimento.key,
						)
					}
				/>
			</Flex>
		);
	}
	if (cell.column.Header === 'Ano') {
		const { selectValues, onChangeSelect, value, title } =
			cell.row.original.preenchimento;
		const textColor = useColorModeValue('secondaryGray.900', 'white');
		return (
			<Text
				paddingLeft='8px'
				marginRight={0}
				color={textColor}
				fontSize='sm'
				fontWeight='700'
			>
				{title}
			</Text>
		);
	}
	return <CellText cell={cell} />;
};

const CellText = ({ cell }): JSX.Element => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	return (
		<Text
			paddingLeft='8px'
			marginRight={0}
			color={textColor}
			fontSize='sm'
			fontWeight='700'
		>
			{cell.value || 0}
		</Text>
	);
};
export const generateRowsMonths = (
	ano: string,
	startMounth: number,
	qtdMounth: number,
	addYear: boolean = false,
	startYear: number = 0,
): ColumnData[] => {
	return Array.from({ length: qtdMounth }, (_, i) => {
		const mounth = (startMounth + i) % 12;
		let year = '';
		if (addYear)
			year =
				startMounth + i >= 12 ? `/${startYear + 1}` : `/${startYear}`;

		return {
			Header: ano,
			accessor: (mounth + 1).toString(),
			type: 'text',
		};
	});
};
