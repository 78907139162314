import { Flex, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { IconEdit, IconTrash } from '@tabler/icons';
import CheckTableEditModal from 'components/modal/CheckTableEditModal';
import { DeleteModal } from 'components/modal/DeleteModal';

const GenericRowEditAndDeleteModal = (props) => {
	const {
		dataDelete,
		idDelete: selectedId,
		filterModule,
		queriesToInvalidadeOnRemove,
		queriesToInvalidadeOnEdit,
	} = props;

	const {
		isOpen: isOpenDelete,
		onOpen: onOpenDelete,
		onClose: onCloseDelete,
	} = useDisclosure();

	const {
		isOpen: isOpenEdit,
		onOpen: onOpenEdit,
		onClose: onCloseEdit,
	} = useDisclosure();

	return (
		<Flex
			cursor='pointer'
			h='max-content'
			w='max-content'
		>
			<Stack
				direction='row'
				alignSelf={{ base: 'unset', md: 'flex-end' }}
				spacing='8px'
			>
				<Icon
					color='secondaryGray.500'
					as={IconEdit}
					w='20px'
					h='20px'
					onClick={onOpenEdit}
				/>
				<Icon
					color='secondaryGray.500'
					as={IconTrash}
					w='20px'
					h='20px'
					onClick={onOpenDelete}
				/>

				<DeleteModal
					isOpen={isOpenDelete}
					onClose={onCloseDelete}
					idDelete={selectedId}
					dataDelete={dataDelete}
					queriesToInvalidadeOnRemove={queriesToInvalidadeOnRemove}
				/>
				{isOpenEdit && (
					<CheckTableEditModal
						filterModule={filterModule}
						isOpen={isOpenEdit}
						onClose={onCloseEdit}
						idEdit={selectedId}
						queriesToInvalidadeOnEdit={queriesToInvalidadeOnEdit}
					/>
				)}
			</Stack>
		</Flex>
	);
};

export default GenericRowEditAndDeleteModal;
