import api from '../api';

export const getCities = async () => {
	const response = await api.get('/cidade');
	return response.data;
};

export const createCity = async (body) => {
	const response = await api.post('/cidade', body);
	return response.data;
};

export const deleteCity = async (id: number) => {
	const response = await api.delete(`cidade?id=${id}`);
	return response.data;
};

export const deleteCitySalesZone = async (id: number) => {
	const response = await api.delete(`zonaVenda/cidadeZonaVenda?id=${id}`);
	return response.data;
};

export const deleteCityByFilters = async (filters: any) => {
	const response = await api.post(
		'zonaVenda/zonaVendaCidade/deletarPorFiltros',
		filters,
	);
	return response.data;
};
