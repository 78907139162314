import Chart from 'react-apexcharts';

interface GroupedStackedBarChartProps {
	chartData: any[];
	chartOptions: any;
}

const GroupedStackedBarChart = (props: GroupedStackedBarChartProps) => {
	const { chartData, chartOptions } = props;
	return (
		<Chart
			options={chartOptions}
			series={chartData}
			type='bar'
			height={350}
		/>
	);
};

export default GroupedStackedBarChart;
