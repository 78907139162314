import axios from 'axios';
import { environmentVars } from '../variables/environment';
import apiBitec from '../apiBitec';

const BITEC_BBE_BIBOTS_API_URL = environmentVars.biTec.url;
const BITEC_BBE_BIBOTS_API_USERNAME = environmentVars.biTec.user;
const BITEC_BBE_BIBOTS_API_PASSWORD = environmentVars.biTec.password;
const BITEC_TOKEN = environmentVars.biTec.token;
let arrayHeadersUploadedSheet: any;
let sessionUid;
let urlWrapper = BITEC_BBE_BIBOTS_API_URL + 'bos-wrapper-get-data';
async function login() {
	const username = BITEC_BBE_BIBOTS_API_USERNAME;
	const password = BITEC_BBE_BIBOTS_API_PASSWORD;
	const apiLoginUrl = BITEC_BBE_BIBOTS_API_URL + 'users/login';

	try {
		const response = await axios.post(apiLoginUrl, { username, password });
		sessionUid = response.data.id;
	} catch (error) {
		console.error('Erro ao fazer login:', error);
	}
}

export const logoutBbe = () => {
	sessionUid = '';
};

export async function getProcesses() {
	try {
		return login().then(async () => {
			const response = await axios.get(urlWrapper, {
				headers: {
					Authorization: `Bearer ${sessionUid}`,
				},
				params: {
					collectionSettings: JSON.stringify({
						dataType: { id: 'singleView' },
						viewName: 'viewprocesses',
						name: 'processes',
					}),
					queryOptions: JSON.stringify({}),
				},
			});
			return response.data.data;
		});
	} catch (error) {
		console.error(error);
	}
}

export const uploadHeadersSheet = async (body) => {
	try {
		return new Promise((resolve, reject) => {
			let firstRow = body.arquivo[body.header - 1];
			arrayHeadersUploadedSheet = firstRow.filter(
				(elemento) => elemento !== 'numero_linha',
			);
			resolve(firstRow);
		});
	} catch (error) {
		console.error(error);
	}
};

export const uploadProcessesSetings = async (body) => {
	try {
		let urlToPostProcessesSetting =
			BITEC_BBE_BIBOTS_API_URL + 'processes-setting';
		return new Promise(async (resolve, reject) => {
			await axios
				.post(urlToPostProcessesSetting, body, {
					headers: {
						Authorization: `Bearer ${sessionUid}`,
					},
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error.message);
				});
		});
	} catch (error) {
		console.error(error);
	}
};

export const getProcessesSetting = async (processId, easyCustomerId) => {
	try {
		const response = await axios.get(urlWrapper, {
			headers: {
				Authorization: `Bearer ${sessionUid}`,
			},
			params: {
				collectionSettings: JSON.stringify({
					dataType: { id: 'singleView' },
					viewName: 'viewgetprocessessetting',
					name: 'processessetting',
				}),
				queryOptions: JSON.stringify({
					processesSettingProcessId: processId,
					easyCustomerId: easyCustomerId,
				}),
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const getBistoreDataTypes = async () => {
	try {
		const response = await axios.get(urlWrapper, {
			headers: {
				Authorization: `Bearer ${sessionUid}`,
			},
			params: {
				collectionSettings: JSON.stringify({
					dataType: { id: 'singleView' },
					viewName: 'viewbistoredatatypes',
					name: 'bistoredatatypes',
				}),
				queryOptions: JSON.stringify({}),
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
	}
};

export const returnUploadedHeadersSheet = () => {
	return arrayHeadersUploadedSheet;
};
