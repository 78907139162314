import {
	Flex,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
	useDisclosure,
} from '@chakra-ui/react';
import { IconAlertCircle, IconCheck, IconX } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useMemo, useState } from 'react';
import IconManager from '../../../../../components/icons/IconManager';
import DiscardChangesModal from 'components/modal/DiscardChangesModal';
import { useGlobalContext } from 'contexts/GlobalContext';
import moment from 'moment';
interface PropsComentarioJustificando {
	header?: string;
	description?: string;
	ata: string;
	onClose: () => void;
	onSave: (newAta: string) => void;
	showModal: boolean;
	valuesFilter?: any;
}

export const ModalComment = ({
	onClose,
	onSave,
	ata = '',
	header,
	description,
	showModal,
	valuesFilter,
}: PropsComentarioJustificando) => {
	const [newAta, setNewAta] = useState<string>(ata);

	const { permissionsPage } = useGlobalContext();
	const canEditPreviousAtas =
		permissionsPage?.['atasPassadas']?.editar || false;

	const actualMonth = moment().format('M');
	const actualYear = moment().format('YYYY');

	const cycleFiltered = valuesFilter?.ciclo?.label;
	const yearFiltered = valuesFilter?.ano?.label;

	const {
		isOpen: isOpenDiscardChange,
		onOpen: onOpenDiscardChange,
		onClose: onCloseDiscardChange,
	} = useDisclosure();

	const hasChange = useMemo(() => {
		return newAta !== ata;
	}, [newAta, ata]);

	const isDisabledAta = !canEditPreviousAtas
		? cycleFiltered < actualMonth || yearFiltered < actualYear
		: false;

	return (
		<>
			<Modal
				onClose={hasChange ? onOpenDiscardChange : onClose}
				onEsc={hasChange ? onOpenDiscardChange : onClose}
				size={'xl'}
				isOpen={showModal}
			>
				<ModalOverlay />
				<ModalContent borderRadius={20}>
					<ModalHeader
						fontSize={'24px'}
						color={'easyBLUE.300'}
					>
						<IconManager
							type='edit'
							color={'easyRED.300'}
						/>
						<Flex
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							{header}
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text
							mb='10px'
							color={'black'}
							fontSize={'14px'}
							fontWeight={'500'}
						>
							Comentário
						</Text>
						<Textarea
							value={newAta}
							onChange={(e) => setNewAta(e.target.value)}
							rows={10}
							placeholder={description}
							disabled={isDisabledAta}
						></Textarea>
					</ModalBody>
					<ModalFooter gap={'20px'}>
						<ButtonComponent
							onClick={onClose}
							title='Cancelar'
						/>
						<ButtonComponent
							onClick={() => onSave(newAta)}
							title='Salvar'
							type='primary'
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<DiscardChangesModal
				isOpen={isOpenDiscardChange}
				onClose={onCloseDiscardChange}
				onClosePreviousModal={onClose}
			/>
		</>
	);
};
export function ErrorComment({
	ata,
	onClose,
	onSave,
}: PropsComentarioJustificando) {
	const [newAta, setNewAta] = useState<string>(ata);
	return (
		<Flex
			marginBottom={'8px'}
			borderRadius={'12px'}
			backgroundColor={'easyRED.100'}
			padding={'8px'}
			gap='4px'
		>
			<Flex
				flexDirection={'column'}
				fontSize='14px'
				gap='10px'
				flexGrow={1}
			>
				<Flex
					maxWidth={'700px'}
					alignSelf='center'
					gap={'12px'}
				>
					<Flex gap={'12px'}>
						<Icon
							as={IconAlertCircle}
							w='18px'
							h='18px'
							color={'easyRED.400'}
						/>
						<Text>Atenção</Text>
					</Flex>
					<Text>
						Sua colaboração está fora do padrão histórico. Tem
						certeza de que quer seguir com estes valores? Caso sim,
						adicione um comentário justificando os valores da sua
						colaboração.
					</Text>
				</Flex>

				<Flex flexDirection={'column'}>
					<Text fontWeight={'500'}>Comentário</Text>
					<Flex>
						<Input
							data-test='input-modal-comentario'
							onChange={(e) => setNewAta(e.target.value)}
							value={newAta}
							borderRadius={'16px'}
							backgroundColor={'white'}
						/>
						<ButtonComponent
							data-test='button-save-modal-comentario'
							type='icon'
							size={6}
							onClick={() => {
								onSave(newAta);
							}}
							iconType={<IconCheck />}
						/>
					</Flex>
				</Flex>
			</Flex>
			<Icon
				data-test='button-close-modal-comentario'
				onClick={() => {
					if (ata) {
						onClose();
					}
				}}
				cursor={'pointer'}
				as={IconX}
				w='18px'
				h='18px'
				color={'easyRED.400'}
			/>
		</Flex>
	);
}
// export default function ComentarioJustificando(props: PropsComentarioJustificando) {
//     const { showModal, showErrorComment } = props
//     return <>
//         {
//             !showModal && showErrorComment && <ErrorComment {...props} />
//         }
//         {
//             showModal && <ModalComment  {...props} />
//         }
//     </>
// }
