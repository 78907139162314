import { useQuery } from '@tanstack/react-query';
import { getDownloadTemplateRelatedCustomer } from 'services/api/requests/uploads/uploads';

export const useDownloadTemplateRelatedCustomer = () => {
	return useQuery(
		['download-template-customer'],
		getDownloadTemplateRelatedCustomer,
		{
			refetchOnWindowFocus: false,
			enabled: false,
		},
	);
};
