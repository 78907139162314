import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
	prefix: 'R$ ',
	suffix: '',
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: '.',
	allowDecimal: true,
	decimalSymbol: ',',
	decimalLimit: 2, // how many digits allowed after the decimal
	integerLimit: 12, // limit length of integer numbers
	allowNegative: false,
	allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
	const currencyMask = createNumberMask({
		...defaultMaskOptions,
		...maskOptions,
		allowNegative: inputProps?.allowNegative || false,
	});

	//   style like others input
	return (
		<MaskedInput
			mask={currencyMask}
			{...inputProps}
			style={{
				width: '100%',
				height: '44px',
				borderRadius: '10px',
				border: '1px solid #E2E8F0',
				padding: '0 1rem',
				fontSize: '1rem',
				color: '#4A5568',
				outline: 'none',
				transition: 'all 0.2s',
				'&:focus': {
					border: '1px solid #3182CE',
					boxShadow: '0 0 0 1px #3182CE',
				},
			}}
		/>
	);
};

CurrencyInput.defaultProps = {
	inputMode: 'numeric',
	maskOptions: {},
};

CurrencyInput.propTypes = {
	inputmode: PropTypes.string,
	maskOptions: PropTypes.shape({
		prefix: PropTypes.string,
		suffix: PropTypes.string,
		includeThousandsSeparator: PropTypes.bool,
		thousandsSeparatorSymbol: PropTypes.string,
		allowDecimal: PropTypes.bool,
		decimalSymbol: PropTypes.string,
		decimalLimit: PropTypes.string,
		requireDecimal: PropTypes.bool,
		allowNegative: PropTypes.bool,
		allowLeadingZeroes: PropTypes.bool,
		integerLimit: PropTypes.number,
	}),
};

export default CurrencyInput;
