import api from 'services/api/api';

export const getDownloadTemplateProducts = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateProduto');
	console.log(response);

	return response.data;
};

export const getDownloadTemplateSales = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateVenda');
	console.log(response);

	return response.data;
};

export const getDownloadTemplateStock = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateEstoque');
	console.log(response);

	return response.data;
};
export const getDownloadTemplateOpportunity = async () => {
	const response = await api.get('uploadExcel/downloadTemplateOportunidade');
	console.log(response);

	return response.data;
};

export const getDownloadTemplateHourlyLaunch = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateHoraHora');
	console.log(response);

	return response.data;
};

export const getDownloadTemplatePortolio = async () => {
	const response = await api.get(
		'/uploadExcel/downloadTemplateCarteiraPedido',
	);
	console.log(response);

	return response.data;
};

export const getDownloadTemplateBudget = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateOrcamento');
	console.log(response);

	return response.data;
};

export const getDownloadTemplateForecast = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateForecast');
	console.log(response);

	return response.data;
};

export const uploadProducts = async (body) => {
	// Analisar progresso de upload, funcional apenas em redes mais lentas
	const response = await api.post(
		'/arquivoExcelProdutos/uploadArquivoProduto',
		body,
	);

	return response.data;
};

export const uploadOpportunity = async (body) => {
	// Analisar progresso de upload, funcional apenas em redes mais lentas
	const response = await api.post(
		'/arquivoExcelOportunidades/uploadArquivoOportunidade',
		body,
	);

	return response.data;
};

export const uploadStock = async (body) => {
	// Analisar progresso de upload, funcional apenas em redes mais lentas
	const response = await api.post(
		'/arquivoExcelEstoque/uploadArquivoEstoque',
		body,
	);

	return response.data;
};

export const uploadSales = async (body) => {
	const response = await api.post(
		'/arquivoExcelVendas/uploadArquivoVenda',
		body,
	);
	return response.data;
};

export const uploadHourlyLaunch = async (body) => {
	const response = await api.post(
		'/arquivoExcelHoraHoras/uploadArquivoHoraHora',
		body,
	);
	return response.data;
};

export const getDownloadTemplateProductionRoadmap = async (body) => {
	const response = await api.get(
		'/uploadExcel/downloadTemplateRoteiroProducao',
	);

	return response.data;
};

export const uploadProductionRoadmap = async (body) => {
	const response = await api.post(
		'/arquivoExcelRoteiroProducao/uploadArquivoRoteiroProducao',
		body,
	);
	return response.data;
};

export const getDownloadTemplateApportionment = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateRateio');

	return response.data;
};

export const uploadApportionment = async (body) => {
	const response = await api.post(
		'/arquivoExcelRateios/uploadArquivoRateio',
		body,
	);
	return response.data;
};

export const getDownloadTemplateHierarchy = async () => {
	const response = await api.get('/uploadExcel/downloadTemplateHierarquia');

	return response.data;
};

export const uploadCommercialHierarchy = async (body) => {
	const response = await api.post(
		'/arquivoExcelHierarquiaComercial/uploadArquivoHierarquiaComercial',
		body,
	);
	return response.data;
};

export const getDownloadTemplateRelatedCustomer = async () => {
	const response = await api.get(
		'/uploadExcel/downloadTemplateClientesRelacionado',
	);
	return response.data;
};

export const uploadRelatedCustomer = async (body) => {
	const response = await api.post(
		'/arquivoExcelCliente/uploadArquivoCliente',
		body,
	);
	return response.data;
};
