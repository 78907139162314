import { Flex } from '@chakra-ui/react';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { numberToLocaleString } from 'utils/functions/number';

const getCellWidth = (el) => {
	return el?.key === 'descricao' ? '320px !important' : '92px !important';
};

const renderFormattedValue = (value, header) => {
	if (value !== '-') {
		return ''
			.concat(!!value && value !== '-' ? header?.prefixo : '')
			.concat(value)
			.concat(!!value && value !== '-' ? header?.sufixo : '');
	}

	if (!!header?.noDataHelp)
		return (
			<TooltipStandard label={header?.noDataHelp}>
				{value}
			</TooltipStandard>
		);

	return value;
};

export const renderColumns = (keysHeaders, row, level) =>
	keysHeaders?.map((header) => {
		let value: string = '-';

		if (
			header?.key !== 'descricao' &&
			row[header?.key] !== null &&
			row[header?.key] !== undefined
		) {
			if (header?.canTruncValue) {
				value = Math.trunc(Number(row[header?.key]))?.toLocaleString(
					'pt-BR',
				);
			} else if (header?.decimalPrecision) {
				value = numberToLocaleString({
					value: Number(row[header?.key]),
					maximumFractionDigits: header?.decimalPrecision,
				});
			} else {
				value = Number(row[header?.key])?.toLocaleString('pt-BR');
			}
		}

		const text =
			header?.key === 'descricao' ? (
				<TooltipStandard label={row[header?.key]}>
					{row[header?.key]}
				</TooltipStandard>
			) : (
				<>{renderFormattedValue(value, header)}</>
			);

		return (
			<Flex
				fontSize='12px'
				w={getCellWidth(header)}
				{...(header?.key !== 'descricao'
					? { justifyContent: 'center' }
					: undefined)}
				alignItems='center'
			>
				{text}
			</Flex>
		);
	});
