import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';
import api from '../api';

export const getPortfolio = async (body) => {
	const url = '/carteiraPedido/view';
	console.log(body.limit, PAGINATION_SIZE_LIST[0]);

	const requestBody = {
		paginacao: {
			page: body.page,
			size: body.limit,
			order: [],
		},
		filtros: body.filters,
	};
	const response = await api.post(url, requestBody);
	return response.data;
};

export const getPortfolioById = async ({ queryKey }) => {
	const [_key, id] = queryKey;
	const url = `/carteiraPedido/carteiraPedidoById?carteira_pedido_id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const fetchPortfolioByFilters = async (body) => {
	const url = '/carteiraPedido/view';
	console.log(body.limit, PAGINATION_SIZE_LIST[0]);

	const requestBody = {
		paginacao: {
			page: body.page,
			size: body.limit || PAGINATION_SIZE_LIST[0],
			order: [],
		},
		filtros: body.filters,
	};
	const response = await api.post(url, requestBody);
	return response.data;
};

export const createPortfolio = async (body) => {
	const url = '/carteiraPedido';
	const response = await api.post(url, body);
	return response.data;
};

export const editPortfolio = async (body, id: number) => {
	const url = `/carteiraPedido?carteira_pedido_id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deletePortfolio = async (id: number) => {
	const url = `/carteiraPedido?carteira_pedido_id=${id}`;
	const response = await api.delete(url);
	return response.data;
};

export const deletePortfolioByFilters = async (filters: any) => {
	const response = await api.post(
		'carteiraPedido/deletarPorFiltros',
		filters,
	);
	return response.data;
};
