import { Flex, Icon, Stack, Tooltip, useDisclosure } from '@chakra-ui/react';
import { IconEdit, IconLock, IconTrash } from '@tabler/icons';
import { DeleteModal } from 'components/modal/DeleteModal';
import { PasswordResetModal } from 'components/modal/PasswordResetModal';
import { NavLink } from 'react-router-dom';

export function RowEditAndDelete(props) {
	const {
		linkBtnEdit,
		dataDelete,
		idDelete,
		passwordReset,
		queriesToInvalidadeOnRemove,
		resetQuery,
	} = props;

	const {
		isOpen: isOpenDelete,
		onOpen: onOpenDelete,
		onClose: onCloseDelete,
	} = useDisclosure();
	const {
		isOpen: isOpenPasswordReset,
		onOpen: onOpenPasswordReset,
		onClose: onClosePasswordReset,
	} = useDisclosure();

	return (
		<Flex
			cursor='pointer'
			h='max-content'
			w='max-content'
		>
			<Stack
				direction='row'
				alignSelf={{ base: 'unset', md: 'flex-end' }}
				spacing='8px'
			>
				<NavLink to={linkBtnEdit}>
					<Icon
						data-test={'link-editar'}
						color='secondaryGray.500'
						as={IconEdit}
						w='20px'
						h='20px'
					/>
				</NavLink>
				<Icon
					data-test={'link-excluir'}
					color='secondaryGray.500'
					as={IconTrash}
					w='20px'
					h='20px'
					onClick={onOpenDelete}
				/>
				<DeleteModal
					data-test={'modal-excluir'}
					isOpen={isOpenDelete}
					onClose={onCloseDelete}
					idDelete={idDelete}
					dataDelete={dataDelete}
					queriesToInvalidadeOnRemove={queriesToInvalidadeOnRemove}
					resetQuery={resetQuery}
				/>
				{passwordReset && (
					<PasswordResetModal
						isOpen={isOpenPasswordReset}
						onClose={onClosePasswordReset}
						passwordReset={passwordReset}
					/>
				)}
			</Stack>
		</Flex>
	);
}
