import { Button, IconButton } from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons';

// Props are passed in from the parent component

// Type
// 1. primary - primary button with blue background and white text
// 2. secondary - secondary button with red background and white text
// 3. ghost (no background) - default button with white background and blue text
// 4. icon - icon button with no background, no text, and only an icon

// Title
// Title is the text that will be displayed on the button
// obrigatory for type primary, secondary and ghost

// onClick
// onClick is the function that will be called when the button is clicked

// isDisabled
// isDisabled is a boolean that will be used to determine if the button should be disabled or not

// isLoading
// isLoading is a boolean that will be used to determine if the button should load or not

// action
// action is a string that will be used to determine the action that the button will perform, like submit, reset, etc.

// iconType
// iconType is the icon that will be displayed on the button
// passed as a prop from the parent component with the icon imported from @tabler/icon.
// example: <IconDownload /> or <IconUpload />
// obrigatory for type icon

// iconSize
// iconSize is the size of the icon that will be displayed on the button

// colorScheme={"brand"}

export const ButtonComponent = (props) => {
	const {
		type,
		title,
		onClick,
		isDisabled,
		isLoading,
		action,
		iconType,
		iconSize,
		loadingText,
		textColor,
		width,
		margin,
		marginTop,
		fontSize,
		colorType,
		px,
		py,
		...others
	} = props;

	switch (type) {
		case 'primary':
			return (
				<Button
					variant={'solid'}
					backgroundColor={'easyBLUE.300'}
					color={textColor ? textColor : 'easyBrandMenu.100'}
					onClick={onClick}
					px={px ? px : '30px'}
					py={py ? py : '8px'}
					fontWeight={500}
					borderRadius={'100px'}
					fontSize={fontSize ? fontSize : '16px'}
					_hover={{
						backgroundColor: 'easyBLUE.400',
					}}
					disabled={isDisabled}
					_active={{}}
					isLoading={isLoading}
					type={action}
					loadingText={loadingText}
					width={width ? width : 'auto'}
					margin={margin ? margin : '0'}
					marginTop={marginTop ? marginTop : '0'}
					{...others}
				>
					{title}
				</Button>
			);
		case 'secondary':
			return (
				<Button
					variant={'solid'}
					backgroundColor={'easyRED.300'}
					color={textColor ? textColor : 'easyBrandMenu.100'}
					onClick={onClick}
					px={px ? px : '30px'}
					py={py ? py : '8px'}
					fontWeight={500}
					borderRadius={'100px'}
					fontSize={fontSize ? fontSize : '16px'}
					_hover={{
						backgroundColor: 'easyRED.400',
					}}
					disabled={isDisabled}
					_active={{}}
					isLoading={isLoading}
					type={action}
					loadingText={loadingText}
					width={width ? width : 'auto'}
					marginTop={marginTop ? marginTop : '0'}
					{...others}
				>
					{title}
				</Button>
			);
		case 'ghost':
			return (
				<Button
					variant={'ghost'}
					color={textColor ? textColor : 'easyBLUE.300'}
					onClick={onClick}
					px={px ? px : '30px'}
					py={py ? py : '8px'}
					fontWeight={500}
					borderRadius={'100px'}
					fontSize={fontSize ? fontSize : '16px'}
					border={'1px solid'}
					borderColor={'easyBLUE.150'}
					type={action}
					width={width ? width : 'auto'}
					disabled={isDisabled}
					{...others}
				>
					{title}
				</Button>
			);
		case 'icon':
			return (
				<IconButton
					disabled={isDisabled}
					variant={colorType === 'warning' ? 'ghost' : 'solid'}
					color={
						colorType === 'warning'
							? 'orange.600'
							: colorType === 'danger'
							? 'easyRED.300'
							: 'easyBLUE.300'
					}
					onClick={onClick}
					icon={iconType}
					borderRadius={'15px'}
					border={'1px solid'}
					borderColor={
						colorType === 'warning'
							? 'transparent'
							: 'easyBrandMenu.200'
					}
					size={iconSize}
					{...others}
				>
					{others?.children || ''}
				</IconButton>
			);
		case 'clearWithIcon':
			return (
				<Button
					onClick={onClick}
					variant={'ghost'}
					leftIcon={
						<IconTrash
							width={'20px'}
							height={'20px'}
						/>
					}
					color={'easyBLUE.300'}
					size={'sm'}
				>
					{title}
				</Button>
			);
		default:
			return (
				<Button
					variant={'ghost'}
					color={'easyBLUE.300'}
					onClick={onClick}
					px={px ? px : '30px'}
					py={py ? py : '8px'}
					fontWeight={500}
					borderRadius={'100px'}
					fontSize={fontSize ? fontSize : '16px'}
					border={'1px solid'}
					borderColor={'easyBLUE.150'}
					width={width ? width : 'auto'}
					{...others}
				>
					{title}
				</Button>
			);
	}
};
