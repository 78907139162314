import { UseMutationResult } from '@tanstack/react-query';
import GenericInput from 'components/inputs/GenericInput';
import { LoadingSpinner } from 'components/loading/Loading';
import { useEffect, useRef, useState } from 'react';
import { defaultInputStyle } from 'utils/forms/defaultsProps';
import { IconX } from '@tabler/icons';

export interface IRequestSearchPagination {
	search: string;
	limit: number;
	[key: string]: any;
}
interface IInputSearch<T, Request> {
	searchType: string;
	inputProps: {
		dataTest?: string;
		value?: T;
		onChange?: (name: string, value: T | null) => void;
		renderInputLabel?: (value?: T) => string;
		clearInput?: () => boolean | void;
		getIcon?: (value: T) => JSX.Element | null;
		name: string;
		disabled?: boolean;
	};
	useGetRequest: (
		r: Request & IRequestSearchPagination,
	) => UseMutationResult<any, any, any, unknown>;
	extraRequestData: Request;
	onClean?: () => void;
}
export default function InputSearch<T, Request>({
	searchType,
	inputProps,
	useGetRequest,
	extraRequestData,
	onClean,
}: IInputSearch<T, Request>) {
	const {
		dataTest,
		value,
		onChange,
		renderInputLabel = () => 'Não implementado',
		clearInput = () => {},
		getIcon,
		name,
		disabled,
	} = inputProps;

	const [onFocus, setOnFocus] = useState<boolean>(false);
	const [isAddMoreResult, setIsAddMoreResult] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const [search, setSearch] = useState<string>('');
	const [limit, setLimit] = useState<number>(5);
	const [options, setOptions] = useState<any[]>([]);

	const [canShowOptions, setCanShowOptions] = useState<boolean>(false);

	const timer = useRef<any>(null);

	const isAddMoreResultRef = useRef(isAddMoreResult);

	const {
		data,
		mutate,
		isIdle: isFistDefinition,
		isLoading,
	} = useGetRequest({
		search,
		limit,
		...extraRequestData,
	});

	function addMoreResult() {
		setIsAddMoreResult(true);
		setLimit((prev) => prev + 5);
	}

	useEffect(() => {
		isAddMoreResultRef.current = isAddMoreResult;
	}, [isAddMoreResult]);

	useEffect(() => {
		if (!onFocus) {
			setTimeout(() => {
				if (!isAddMoreResultRef.current) {
					setOnFocus(false);
					setCanShowOptions(false);
					setLimit(5);
				} else {
					setOnFocus(true);
					setIsAddMoreResult(false);
				}
			}, 300);
		}
	}, [onFocus]);

	useEffect(() => {
		if (onFocus && inputRef.current) {
			inputRef.current.focus();
		}
		if (onFocus && !canShowOptions) {
			setCanShowOptions(true);
		}
	}, [onFocus]);

	function handleOnChange() {
		setCanShowOptions(true);
	}

	function handleOnClick() {
		setCanShowOptions(true);
	}

	function handleOnSelect(data) {
		setCanShowOptions(false);
		if (onChange) onChange(name, data);
	}

	function renderOptions() {
		function getOptionStyle(): React.CSSProperties {
			return !!getIcon
				? {
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						alignItems: 'center',
				  }
				: {};
		}

		return !!options?.length ? (
			<>
				{options?.map((d) => {
					const textValue = renderInputLabel(d);
					return (
						<div
							key={d?.id}
							className='option-item'
							onClick={() => handleOnSelect(d)}
							style={getOptionStyle()}
						>
							{!!getIcon ? (
								<>
									<div
										style={{
											flexGrow: 1,
										}}
									>
										{textValue}
									</div>
									<div>{getIcon(d)}</div>
								</>
							) : (
								textValue
							)}
						</div>
					);
				})}
			</>
		) : null;
	}

	function renderMoreOptions() {
		return searchType === 'cliente-demanda' ||
			options?.length < data?.count ? (
			<div
				key='action-add'
				className='option-add'
				onClick={() => addMoreResult()}
			>
				Mais resultados
			</div>
		) : null;
	}

	useEffect(() => {
		clearTimeout(timer?.current);

		timer.current = setTimeout(() => {
			mutate({
				search,
				limit,
				...extraRequestData,
			});
		}, 250);
	}, [search, limit]);

	useEffect(() => {
		if (!!data?.rows?.length) {
			setOptions(data?.rows);
		} else if (!isLoading) {
			setOptions([]);
		}
	}, [data]);

	useEffect(() => {
		if (onFocus) {
			mutate({
				search,
				limit,
				...extraRequestData,
			});
		}
	}, [limit, onFocus]);

	useEffect(() => {
		if (clearInput()) {
			if (onChange) onChange(name, null);
			setSearch('');
			setOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearInput]);

	function renderContent() {
		if (!canShowOptions) return null;
		if (!!options?.length)
			return (
				<div className={'option-container'}>
					{renderOptions()}
					{renderMoreOptions()}
					{isLoading && <LoadingSpinner />}
				</div>
			);
		if (!isFistDefinition)
			return (
				<div className={'option-container'}>
					<div
						key='no-options'
						className='no-options'
					>
						Nenhum registro encontrado!
					</div>
				</div>
			);
	}

	function render() {
		const genericInputProps = {
			'data-test': dataTest,
			defaultInputStyle,
			placeholder: 'Digite para pesquisar',
			onClick: handleOnClick,
			onChange: (e) => {
				setSearch(e.target.value);
				handleOnChange();
			},
			value: onFocus ? search : renderInputLabel(value),
			icon: (
				<>
					{value && !onFocus && getIcon ? getIcon(value) : null}
					{onClean && value && !onFocus && (
						<div
							onClick={onClean}
							style={{
								padding: '0 10px 0 10px',
							}}
						>
							<IconX
								size='16px'
								cursor='pointer'
								onClick={onClean}
							/>
						</div>
					)}
				</>
			),
			onFocus: () => setOnFocus(true),
			onBlur: () => {
				setOnFocus(false);
			},
			disabled,
			ref: inputRef,
			h: '44px',
		};

		return (
			<div style={{ width: '100%', position: 'relative' }}>
				<GenericInput
					type='text'
					inputProps={genericInputProps}
				/>
				{renderContent()}
			</div>
		);
	}

	return render();
}
