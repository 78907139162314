import api from '../api';

export const getRelatedCustomerById = async ({ queryKey }) => {
	const [_key, id] = queryKey;
	if (!id) {
		return;
	}
	const url = `/clienteRelacionado/clienteRelacionadoById?id=${id}`;
	const response = await api.get(url);
	return response.data;
};

export const getCustomerAndRelationshipById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const url = `/clienteRelacionado/clienteById?id=${id}`;

	const response = await api.get(url);
	return response.data;
};

export const getCustomerByNameOrCnpj = async ({ queryKey }) => {
	const [_key, name] = queryKey;
	const url = `/clienteRelacionado?busca=${name}&limit=10`;
	const response = await api.get(url);
	return response.data;
};

export const getCustomerByNameOrCnpjWithLimit = async (
	busca?: string,
	limit?: Number,
	orderBy?: string,
	sort?: string,
) => {
	const response = await api.get('clienteRelacionado', {
		params: {
			busca,
			limit,
			orderBy,
			sort,
		},
	});
	return response.data;
};

export const createCustomer = async (body) => {
	const url = '/clienteRelacionado';
	const response = await api.post(url, body);
	return response.data;
};

export const editRelatedCustomer = async (body, id: number) => {
	const url = `/clienteRelacionado?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};

export const deleteRelatedCustomerByFilters = async (filters: any) => {
	const response = await api.post(
		'clienteRelacionado/deletarPorFiltros',
		filters,
	);
	return response.data;
};

export const exportRelatedCustomer = async () => {
	const response = await api.get('/clienteRelacionado/exportCSV');
	return response.data;
};
