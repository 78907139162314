import { useQuery } from "@tanstack/react-query";
import { getHolidays } from "services/api/requests/projection";

export const useGetHolidays = (mes: number, ano: number) => {
    
    return useQuery(["holidaysByMes", mes, ano], getHolidays, {
        onError: (error) => {
            console.log(error)
        },
        refetchOnWindowFocus: false,
    })
}