import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetMemberById } from 'services/queryClient/wrapperHooks/useGetMemberById';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/loading/Loading';
import Card from 'components/card/Card';
import FormCadastrarEditarMembro from 'components/forms/FormCadastrarEditarMembro';
import { AlertComponent } from 'components/alerts/AlertComponent';

export default function EditarMembro() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: memberId } = useParams<any>();
	const [formInitialState, setFormInitialState] = useState<any>();

	const { data: MemberData, isLoading: isLoadingMemberData } =
		useGetMemberById(memberId);

	const helpEditarMembros: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Membros',
			firstParagraph:
				'Para editar um membro altere os dados de Nome do Membro, Função, E-mail, e Área da Empresa. O único campo obrigatório é o nome do membro.',
			secondParagraph:
				'Observação: o campo área da empresa se refere aos três setores especialistas responsáveis pelas colaborações do processo de demanda.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarMembros);
	}, [helpEditarMembros, setDataSidebar]);

	useEffect(() => {
		if (MemberData) {
			setFormInitialState({
				area_empresa_id: MemberData?.area_empresa_id || '',
				ativo: MemberData?.ativo,
				email: MemberData?.email || '',
				funcao: MemberData?.funcao || '',
				id: MemberData?.id,
				nome_membro: MemberData?.nome_membro,
				usuario_id: MemberData?.usuario?.id,
			});
		}
	}, [MemberData]);

	const renderAlert = (): JSX.Element | null => {
		if (MemberData?.usuario?.id) {
			return (
				<AlertComponent
					description={
						"Este membro está associado a um usuário do módulo de gestão de relacionamento. Lembre-se de que somente o próprio membro logado pode editar o e-mail. Para fazer isso, acesse 'Gerenciar Perfil' no menu superior direito."
					}
					status={'info'}
					hasCloseButton
				/>
			);
		}

		return null;
	};

	return isLoadingMemberData || !formInitialState ? (
		<LoadingSpinner />
	) : (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderAlert()}
				<FormCadastrarEditarMembro
					initialState={formInitialState}
					memberId={memberId}
				/>
			</Card>
		</Flex>
	);
}
