import { IconPlus, IconTrash } from '@tabler/icons';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { IFormColumn } from './GenericForm';
import {
	Flex,
	FormControl,
	FormErrorMessage,
	Icon,
	Tooltip,
} from '@chakra-ui/react';
import { InputFormLabel } from '../fields/InputFormLabel';
import { useFieldArray } from 'react-hook-form';
import { defaultTooltipProps } from '../../utils/forms/defaultsProps';
import RenderGenericInputForm from './RenderGenericInputForm';

interface IFormListHeader {
	label: string;
	headerFlexProps?: any;
}

export interface IFormListColumn {
	headers?: Array<IFormListHeader>;
	row: Array<IFormColumn>;
	formProps?: any;
	formListName: string;
	formLabelOnAppend?: string;
	formListRowInitialState: any;
}

const GenericFormList = ({
	headers,
	row,
	formProps,
	formListName,
	formLabelOnAppend = 'Adicionar',
	formListRowInitialState,
}: IFormListColumn) => {
	const {
		control,
		formState: { errors },
	} = formProps;

	const {
		fields: formListFields,
		append,
		remove,
	} = useFieldArray({
		control,
		name: formListName,
	});

	function renderButtonForInput(button: any, disabled: any, hidden: any) {
		if (!button) return null;

		const isHidden = hidden ? hidden(formProps) : false;

		if (isHidden) return null;

		const isDisabled = disabled ? disabled(formProps) : false;

		const {
			data,
			tooltip,
			icon,
			onClick: handleOnClick,
			buttonProps,
		} = button;

		const defaultButtonProps = {
			colorScheme: 'blue',
			color: 'white',
			borderColor: 'transparent',
		};

		const _buttonProps = buttonProps || defaultButtonProps;

		const buttonTag = (
			<ButtonComponent
				type='icon'
				onClick={() => handleOnClick(data, formProps)}
				iconType={icon || <IconPlus />}
				isDisabled={isDisabled}
				{..._buttonProps}
			/>
		);

		return !!tooltip ? (
			<Tooltip
				label={tooltip}
				{...defaultTooltipProps}
			>
				<span>{buttonTag}</span>
			</Tooltip>
		) : (
			buttonTag
		);
	}

	function renderFormInput(index: number, key: any, field: any) {
		const { formControlProps, input } = field;

		const {
			label,
			labelTooltip,
			showRequiredOnLabel,
			name,
			type,
			validate,
			inputProps,
			disabled,
			hidden,
			button,
			tooltip,
			tooltipProps,
		} = input;

		const isHidden = hidden ? hidden(formProps) : false;

		if (isHidden) return null;

		const isInvalid =
			!!errors[formListName]?.length && errors?.atividades[index];

		const fieldName = `${formListName}.${index}.${name}`;

		const _inputProps = {
			...inputProps,
			key,
			...(type === 'decimal' && isInvalid
				? {
						style: {
							border: '2px solid #ee5d50',
							'box-shadow': '#ee5d50',
						},
				  }
				: undefined),
		};

		const genericInput = (
			<RenderGenericInputForm
				name={fieldName}
				type={type}
				formProps={formProps}
				validate={validate}
				inputProps={_inputProps}
				disabled={disabled}
				tooltip={tooltip}
				tooltipProps={tooltipProps}
			/>
		);

		return (
			<FormControl
				{...formControlProps}
				isInvalid={isInvalid}
			>
				{label && (
					<InputFormLabel
						label={label}
						labelTooltip={labelTooltip}
						isRequired={showRequiredOnLabel}
					/>
				)}

				{button ? (
					<Flex {...button?.buttonFlexProps}>
						{genericInput}

						{Array.isArray(button)
							? button?.map((b) =>
									renderButtonForInput(b, disabled, hidden),
							  )
							: renderButtonForInput(button, disabled, hidden)}
					</Flex>
				) : (
					genericInput
				)}

				<FormErrorMessage>
					{isInvalid && errors[formListName][index]?.[name]?.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderHeaders() {
		if (!headers?.length) return null;

		const renderizedHeaders = headers?.map((h) => {
			const { label, headerFlexProps } = h;
			return <Flex {...headerFlexProps}>{label}</Flex>;
		});

		return <Flex direction='row'>{renderizedHeaders}</Flex>;
	}

	function renderRemoveButton(item: any, index: number) {
		return (
			<Flex
				w='2%'
				alignItems='center'
			>
				<FormControl key={`${item.id}-remove`}>
					<Tooltip
						label='Remover atividade'
						placement='top'
					>
						<span>
							<Icon
								color='secondaryGray.500'
								as={IconTrash}
								w='20px'
								h='20px'
								cursor='pointer'
								onClick={() => remove(index)}
							/>
						</span>
					</Tooltip>
				</FormControl>
			</Flex>
		);
	}

	function renderRow(item: any, index: number) {
		return row.map((r2) => {
			const { subColumns } = r2;

			return subColumns.map((f) => {
				const { columnFlexProps, inputs } = f;

				const renderizedInputs = inputs.map((input) =>
					renderFormInput(index, item.id, input),
				);

				return <Flex {...columnFlexProps}>{renderizedInputs}</Flex>;
			});
		});
	}

	function renderListButtonsActions() {
		const initialState = formListRowInitialState
			? { ...formListRowInitialState }
			: {};

		return (
			<Flex
				gap={4}
				justifyContent={'end'}
				mb={2}
			>
				<Flex
					gap={1}
					mb={!!formListFields?.length ? 2 : 0}
				>
					<ButtonComponent
						type={'primary'}
						title={formLabelOnAppend}
						onClick={() => append({ ...initialState })}
					/>
				</Flex>
			</Flex>
		);
	}

	function renderFormList() {
		return (
			<Flex
				direction='column'
				w='100%'
			>
				{renderListButtonsActions()}
				{!!formListFields?.length && (
					<>
						{renderHeaders()}
						<Flex
							direction='column'
							w='100%'
						>
							{formListFields.map((item, index) => (
								<Flex
									direction='row'
									w='100%'
								>
									{renderRow(item, index)}
									{renderRemoveButton(item, index)}
								</Flex>
							))}
						</Flex>
					</>
				)}
			</Flex>
		);
	}

	return renderFormList();
};

export default GenericFormList;
