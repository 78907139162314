export interface PropsContainerTreeView<T> {
	children: JSX.Element;
	data: string;
	datas: string[];
	deeper: number;
	id: string;
	index: string;
	idElement: number;
	isChildrenOfChannel: string;
	isChildrenOfFamily: string;
	channelRepresentative: string;
	original: any;
	isOpen: boolean;
	extra?: T;
}
export type PropsTreeViewContainers<T> = Array<
	(props: PropsContainerTreeView<T>) => JSX.Element
>;

export interface ItemTreeView<T> {
	index: string;
	data: string;
	isOpen: boolean;
	children: Array<string>;
	hasChildren: boolean;
	original: any;
	[key: string]: any;
}
export type ItensTreeView<T> = Record<string, ItemTreeView<T>>;
export interface PropsTreeView<T> {
	items: ItensTreeView<T>;
	id: string;
	Containers: PropsTreeViewContainers<T>;
	ids: string[];
	deeper: number;
	ortiginal?: any;
	filter?: any;
}

export default function TreeView<T>({
	Containers,
	items,
	id,
	ids,
	deeper,
	filter,
}: PropsTreeView<T>) {
	// const [showChidren, setShowChildren] = useState<boolean>(false);
	const Container = Containers[items[id].indexComponent];
	return (
		<Container
			data={items[id].data}
			datas={ids}
			deeper={deeper}
			id={id}
			index={items[id].index}
			idElement={items[id].id}
			isChildrenOfChannel={items[id].isChildrenOfChannel}
			isChildrenOfFamily={items[id].isChildrenOfFamily}
			channelRepresentative={items[id].channelRepresentative}
			extra={items[id].extra}
			original={items[id].original}
			isOpen={items[id].isOpen}
		>
			<>
				{items[id].children.map((id: string, index: number) => (
					<TreeView
						ids={[...ids, items[id].index]}
						key={id}
						Containers={Containers}
						items={items}
						id={id}
						deeper={deeper + 1}
						filter={filter}
					/>
				))}
			</>
		</Container>
	);
}
