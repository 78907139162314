import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarTipologia from 'components/forms/FormCadastrarEditarTipologia';

export default function CadastrarTipologias() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpTipologiaParadas = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Parada',
			firstParagraph:
				'Para cadastrar uma nova parada informe o nome do Parada, e se ela é do tipo programada (prevista) ou não programada.',
			secondParagraph:
				'Algumas paradas são comuns em diversos setores industriais, por isso a easy360 já retorna automaticamente uma lista de paradas.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpTipologiaParadas);
	}, [helpTipologiaParadas, setDataSidebar]);

	const formInitialState = {
		tipologia_parada: null,
		programada: false,
		tempo_de_parada: '00:00:00',
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarTipologia initialState={formInitialState} />
			</Card>
		</Flex>
	);
}
