import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataForecast } from './variables/columnsDataForecast';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDownloadTemplateForecast } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateForecast';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadForecast } from 'services/queryClient/wrapperHooks/uploads/useUploadForecast';

interface Forecast {
	id: number;
	codigo: string;
	sequencia_codigo: string | number;
	data: string;
	nome_canal_venda: string;
	razao_social: string;
	receita_bruta: number;
	data_registro: string;
}

export default function RotinaForecast() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataForecast, []);

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpForecast: SidebarHelp = useMemo(
		() => ({
			title: 'Forecast',
			firstParagraph: `Nesta tela é possível visualizar todos os Forecast de venda, ou seja, todas as oportunidades de venda no tempo.`,
			secondParagraph: `Acesse o botão “Cadastrar Forecast” para adicionar novas oportunidades ou importe um arquivo de forecast acessando Importar Forecast.`,
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'forecast',
			toastSuccessTitle: 'Excluida com sucesso!',
			toastSuccessDescription: 'A forecast foi excluida com sucesso.',
			toastErrorTitle: 'Erro ao excluir forecast!',
			title: 'Deseja realmente excluir este forecast?',
			text: [
				'Você está prestes a excluir este forecast da sua base de cadastros.',
				'',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'forecastDemanda-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes forecasts da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'forecast',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'As forecasts foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir forecasts!',
			invalidate: 'forecastDemanda-list',
		}),
		[],
	);

	const goToCadastroForecast = useCallback(() => {
		history.push(`/${pathCompanyName}/rotina/forecast/criar`);
	}, [history, pathCompanyName]);

	useEffect(() => {
		setDataSidebar(helpForecast);
	}, [helpForecast, setDataSidebar]);

	const renderRow = useCallback((forecast: Forecast) => {
		return {
			id: forecast.id,
			codigoForecast: forecast?.codigo,
			sequenciaCodigoForecast: forecast?.sequencia_codigo,
			competencia: forecast.data
				? moment(forecast.data, 'YYYY-MM-DD').format('DD/MM/YYYY')
				: '',
			canalVenda: forecast?.nome_canal_venda,
			cliente: forecast?.razao_social || '',
			receitaBruta: forecast.receita_bruta.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}),
			dataRegistro: forecast?.data_registro
				? moment
						.utc(forecast.data_registro)
						.local()
						.format('DD/MM/YYYY HH:mm:ss')
				: '',
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test={'button-importar-forecast'}
						type={'ghost'}
						title={'Importar Forecasts'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Forecasts'}
						module={'forecast'}
						useDownloadTemplate={useDownloadTemplateForecast}
						stringInvalidQuery='forecastDemanda-list'
						useUpload={useUploadForecast}
						showLineRestricts={false}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test={'button-cadastrar-forecast'}
						type={'primary'}
						title={'Cadastrar Forecast'}
						onClick={goToCadastroForecast}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroForecast,
		hasBaseData,
		isOpen,
		onClose,
		onOpen,
		tableColumns,
	]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-forecast'
				module={'Forecasts'}
				linkEditForm={`/${pathCompanyName}/rotina/forecast/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='forecastDemanda'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
			/>
		);
	}

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
