import { useAuthenticator } from '@aws-amplify/ui-react';
import {
	ButtonGroup,
	Checkbox,
	Flex,
	Icon,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { IconUserPlus } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { IconHelperTooltip } from 'components/icons/IconHelperTooltip';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { environmentVars } from 'services/api/variables/environment';
import { setLocalStorageCrm } from 'utils/functions/localStorageCrmManager';

interface CrmMemberAssociationModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const CrmMemberAssociationModal = ({
	isOpen,
	onClose,
}: CrmMemberAssociationModalProps) => {
	const history = useHistory();
	const { pathCompanyName, userInfos, userAttributes } = useGlobalContext();
	const { user } = useAuthenticator((context) => [context.user]);

	const [userType, setUserType] = useState('');
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

	const userGroups = user?.getSignInUserSession()?.getIdToken()?.payload?.[
		'cognito:groups'
	];

	useEffect(() => {
		if (userGroups?.includes('admin')) {
			setUserType('admin');
		} else {
			setUserType('user');
		}
	}, [userGroups]);

	const renderText = useCallback(
		(userType) => {
			const generateTextValues = () => {
				return {
					userTypeLabel: '',
					userTypeDescription:
						'mas você pode acessar a gestão de relacionamento mesmo sem essa associação.',
					userTypeAction:
						'No entanto, se desejar configurar a associação com um membro, por favor, vá até a tela de',
				};
			};

			const { userTypeLabel, userTypeDescription, userTypeAction } =
				generateTextValues();

			const commonTextPart = (
				<>
					<Text
						color={'secondaryGray.500'}
						fontWeight={400}
						fontSize={'md'}
					>
						Seu usuário {userTypeLabel} ainda não possui um membro
						associado a ele,
						<Text
							as={'span'}
							fontWeight={'bold'}
						>
							{' '}
							{userTypeDescription}
						</Text>
					</Text>
					<Text
						color={'secondaryGray.500'}
						fontWeight={400}
						fontSize={'md'}
						pb={4}
					>
						{userTypeAction}
						<Text
							as={'span'}
							fontWeight={'bold'}
						>
							{` Gerenciar Perfil `}
						</Text>
						clicando no botão abaixo ou acessando através do menu
						superior da tela.
					</Text>
				</>
			);

			if (userType === 'admin') {
				return (
					<>
						{commonTextPart}
						<Checkbox
							fontWeight={400}
							fontSize={'md'}
							color={'secondaryGray.500'}
							mt={4}
							onChange={() => {
								setIsCheckboxChecked(!isCheckboxChecked);
							}}
							checked={isCheckboxChecked}
						>
							<Flex alignItems={'center'}>
								<Text mr={1}>
									Não exibir essa mensagem novamente.
								</Text>
								<IconHelperTooltip label='Ao marcar essa opção e clicar em "Acessar", você não verá mais essa mensagem e será redirecionado automaticamente para a Gestão de Relacionamento sem a necessidade de configurar a associação com um membro.' />
							</Flex>
						</Checkbox>
					</>
				);
			}

			return commonTextPart;
		},
		[isCheckboxChecked, setIsCheckboxChecked],
	);

	const handleOnClick = (action: 'manageProfile' | 'accessCrm') => {
		if (action === 'manageProfile') {
			window.location.href = `/${pathCompanyName}/gerenciarPerfil`;
		} else if (action === 'accessCrm') {
			if (isCheckboxChecked) {
				setLocalStorageCrm('true');
			}
		}
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'3xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={'3xl'}>
				<ModalHeader>
					<Icon
						as={IconUserPlus}
						color={'easyRED.300'}
						boxSize={14}
					/>
					<Text
						fontWeight={700}
						color={'easyBLUE.300'}
						fontSize={'2xl'}
					>
						Configurar Associação de Membro
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody mb={4}>{renderText(userType)}</ModalBody>
				<ModalFooter>
					<ButtonGroup spacing={4}>
						<ButtonComponent
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							type={'secondary'}
							title={'Gerenciar Perfil'}
							onClick={() => handleOnClick('manageProfile')}
						/>
						<Link
							href={`${environmentVars.procedo.url}${userAttributes?.token}`}
							isExternal
						>
							<ButtonComponent
								type={'primary'}
								title={'Acessar'}
								onClick={() => handleOnClick('accessCrm')}
							/>
						</Link>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
