import { useMemo, useCallback } from 'react';

import { Input } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

interface IInputTimeMask {
	name: string;
	value: string;
	required?: boolean;
	maskFormat?: string;
	[key: string]: any;
}

const InputTimeMask = ({
	name,
	value,
	required = false,
	maskFormat = '12:34:34',
	...props
}: IInputTimeMask) => {
	const formatChars = useMemo(
		() => ({
			1: '[0-2]',
			2: '[0-9]',
			3: '[0-5]',
			4: '[0-9]',
			5: '[0-5]',
			6: '[0-9]',
		}),
		[],
	);

	const beforeMaskedValueChange = useCallback(
		newState => {
			let { value } = newState;

			// Conditional mask for the 2nd digit base on the first digit
			if (value.startsWith('2'))
				formatChars['2'] = '[0-3]'; // To block 24, 25, etc.
			else formatChars['2'] = '[0-9]'; // To allow 05, 12, etc.
			return { value, selection: newState.selection };
		},
		[formatChars],
	);

	return (
		<Input
			as={InputMask}
			mask={maskFormat}
			maskChar={null}
			name={name}
			value={value}
			required={required}
			formatChars={formatChars}
			beforeMaskedValueChange={e => beforeMaskedValueChange(e)}
			{...props}
		/>
	);
};

export default InputTimeMask;
