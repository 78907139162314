import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { createProduct } from 'services/api/requests/requests';
import { useToast } from '@chakra-ui/react';
export const useCreateProduct = (body) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => createProduct(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['produto-list']);

			queryClient.removeQueries({
				predicate: (query: any) =>
					query.queryKey[0].startsWith('apportionment-family'),
			});

			history.goBack();

			toast({
				title: 'Criado com sucesso!',
				description: 'Produto criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			error?.response?.data?.forEach((element: any) => {
				if (element.path !== 'empresa_id') {
					toast({
						title: 'Erro ao criar produto!',
						description: element.message,
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
				}
			});
		},
	});
};
