import {
	Box,
	ButtonGroup,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateAta } from 'services/queryClient/wrapperHooks/useCreateAta';
import IconManager from '../icons/IconManager';
import DiscardChangesModal from './DiscardChangesModal';
import GenericInput from 'components/inputs/GenericInput';
import { any } from 'prop-types';

interface AtaModalProductionProps {
	isOpen: boolean;
	onClose: () => void;
	provider?: string;
	data?: any;
	endDate?: string;
	refetchData?: any;
}

const AtaModalProduction = (props: AtaModalProductionProps) => {
	const {
		isOpen,
		onClose,
		provider,
		data,
		endDate,
		refetchData = () => {},
	} = props;

	const {
		isOpen: isOpenDiscardChange,
		onOpen: onOpenDiscardChange,
		onClose: onCloseDiscardChange,
	} = useDisclosure();

	const { permissionsPage } = useGlobalContext();

	const hasPermissionToEditPreviousAta =
		permissionsPage?.['atasPassadas']?.editar || false;

	const [payload, setPayload] = useState<any>();
	const [allAtas, setAllAtas] = useState<any[]>();
	const [message, setMessage] = useState('');
	const [initialMessage, setInitialMessage] = useState<string>('');
	const [selectedAta, setSelectedAta] = useState<any>();
	const [isUnchangedData, setIsUnchangedData] = useState<any>(true);

	useEffect(() => {
		if (isOpen) {
			if (allAtas && allAtas.length > 0) {
				if (!selectedAta) {
					setSelectedAta(allAtas[0]);
					setMessage(allAtas[0]?.anotacao);
					setInitialMessage(allAtas[0]?.anotacao);
				}
			}
		} else {
			clearState();
		}
	}, [isOpen, allAtas, selectedAta]);

	const clearState = () => {
		setMessage('');
		setInitialMessage('');
		setSelectedAta(null);
		setIsUnchangedData(true);
	};

	const currentDate = moment().startOf('day');
	const lastDateInterval = moment(endDate);

	const isPast = lastDateInterval.isBefore(
		moment().startOf('day').subtract({ day: 1 }),
	);

	const { mutate } = useCreateAta(payload, true);

	const createAta = (event) => {
		event.preventDefault();

		const body = {
			tipo: provider,
			anotacao: message,
			data: selectedAta?.data || endDate,
			editavel: hasPermissionToEditPreviousAta,
		};
		setPayload(body);
		setInitialMessage(message);

		setTimeout(() => {
			mutate(undefined, {
				onSuccess: (newAta) => {
					setAllAtas((prevAtas = []) => {
						const updatedAtas = [...prevAtas, newAta];
						return updatedAtas.sort(sortInputDateValues);
					});
					setSelectedAta(newAta);
					setMessage(newAta.anotacao);
					setInitialMessage(newAta.anotacao);
					onClose();
				},
			});
		}, 250);

		setTimeout(() => refetchData(), 500);
	};

	const dateFilterOptions = useMemo(
		() =>
			allAtas?.map?.((d) => (
				<option
					key={d?.id}
					value={d?.id}
				>
					{moment(d?.data)?.format('DD/MM/YYYY')}
				</option>
			)) || [],
		[allAtas],
	);

	const hasChange = useMemo(() => {
		return message !== initialMessage;
	}, [message, initialMessage]);

	function handleDateOnChange(e) {
		const objectData = allAtas?.find(
			(d) => d?.id === Number(e?.target?.value),
		);

		if (objectData) {
			setMessage(objectData?.anotacao);
			setInitialMessage(objectData?.anotacao);
			setSelectedAta(objectData);
		} else if (isPast) {
			setMessage('Não é possível cadastrar atas para o passado!');
			setInitialMessage('Não é possível cadastrar atas para o passado!');
		} else {
			setMessage('');
			setInitialMessage('');
			setSelectedAta(undefined);
		}
	}

	function sortInputDateValues(a, b) {
		if (moment(a?.data).isBefore(moment(b?.data))) return 1;
		if (moment(a?.data).isAfter(moment(b?.data))) return -1;
		return 0;
	}

	const isDisableActions = useCallback(() => {
		if (!hasPermissionToEditPreviousAta || isPast) return true;

		const selectedAtaDate = moment(selectedAta?.data);

		return selectedAtaDate.isBefore(currentDate);
	}, [hasPermissionToEditPreviousAta, currentDate, isPast, selectedAta]);

	const renderInput = useCallback(() => {
		return (
			<GenericInput
				type='textarea'
				inputProps={{
					'data-test':
						'text_area-registro_de_ata-ata-modal-dashboard_de_producao',
					borderRadius: '10px',
					value: message,
					rows: 10,
					placeholder: 'Escreva...',
					onChange: (e) => setMessage(e?.target?.value),
					disabled: isDisableActions(),
				}}
			/>
		);
	}, [isDisableActions, message]);

	useEffect(() => {
		if (data) {
			if (!isPast) {
				const atas: any[] = [];

				if (
					lastDateInterval.isAfter(
						moment().startOf('day').subtract({ day: 1 }),
					) &&
					lastDateInterval.isBefore(moment().endOf('month'))
				) {
					const formatedCurrentDate =
						currentDate?.format('YYYY-MM-DD');

					const existsCurrentDate = data?.some(
						(d) => d?.data === formatedCurrentDate,
					);

					if (!existsCurrentDate) {
						atas.push({
							id: -1,
							anotacao: '',
							data: formatedCurrentDate,
						});
					}
				}

				if (lastDateInterval.isAfter(currentDate)) {
					const existsEndDate = data?.some(
						(d) => d?.data === endDate,
					);

					if (
						!existsEndDate &&
						lastDateInterval.diff(currentDate) !== 0
					) {
						atas.push({
							id: -2,
							anotacao: '',
							data: endDate,
						});
					}
				}
				const sortedAtas = [...atas, ...data].sort(sortInputDateValues);
				setAllAtas(sortedAtas);

				if (isUnchangedData && sortedAtas?.length) {
					setSelectedAta(sortedAtas[0]);
					setMessage(sortedAtas[0]?.anotacao);
					setInitialMessage(sortedAtas[0]?.anotacao);
					setIsUnchangedData(false);
				}
			} else {
				const sortedAtas = data.sort(sortInputDateValues);

				setAllAtas(sortedAtas);

				if (isUnchangedData && sortedAtas?.length) {
					setSelectedAta(sortedAtas[0]);
					setMessage(sortedAtas[0]?.anotacao);
					setInitialMessage(sortedAtas[0]?.anotacao);
					setIsUnchangedData(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isPast, endDate]);

	const handleDiscardChanges = () => {
		setMessage(data?.anotacao);

		setTimeout(() => onClose(), 200);
	};

	const resetDefaultValues = useCallback(() => {
		setMessage(data?.anotacao);
	}, [data?.anotacao]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={hasChange ? onOpenDiscardChange : onClose}
				onEsc={hasChange ? onOpenDiscardChange : onClose}
				isCentered
				size='6xl'
			>
				<ModalOverlay />
				<ModalContent borderRadius={20}>
					<ModalHeader
						display='flex'
						flexDir='column'
					>
						<IconManager
							type='edit'
							color='easyRED.300'
						/>
						<Flex
							justifyContent='space-between'
							alignItems='center'
						>
							<Text
								fontSize='2xl'
								fontWeight={700}
								color='easyBLUE.300'
							>
								Registro de Ata
							</Text>
							{allAtas?.length ? (
								<Box>
									<GenericInput
										type='select'
										inputProps={{
											selectOptions: dateFilterOptions,
											borderRadius: '10px',
											defaultValue: moment(
												selectedAta?.data,
											)
												.utc()
												.format('DD/MM/YYYY'),
											onChange: handleDateOnChange,
											w: '200px',
										}}
									/>
								</Box>
							) : (
								<Text
									fontSize='10px'
									color='#718096'
								>
									Não existem atas para o período selecionado!
								</Text>
							)}
						</Flex>
						{allAtas?.length && (
							<Flex
								display='flex'
								flexDirection='row'
								justifyContent='end'
							>
								<Text
									fontSize='10px'
									color='#718096'
								>
									Retornando apenas dias que possuem registro
									de ata para o intervalo selecionado!
								</Text>
							</Flex>
						)}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>{renderInput()}</ModalBody>

					<ModalFooter>
						<ButtonGroup spacing={'20px'}>
							<ButtonComponent
								data-test='button-cancelar-modal-dashboard_de_producao'
								type='ghost'
								title='Cancelar'
								onClick={handleDiscardChanges}
							/>
							<ButtonComponent
								data-test='button-salvar-modal-dashboard_de_producao'
								type='primary'
								title='Salvar'
								onClick={createAta}
								isDisabled={isDisableActions()}
							/>
						</ButtonGroup>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<DiscardChangesModal
				isOpen={isOpenDiscardChange}
				onClose={onCloseDiscardChange}
				onClosePreviousModal={onClose}
				resetDefaultValues={resetDefaultValues}
			/>
		</>
	);
};

export default AtaModalProduction;
