export function formatNumberToCurrencyFromObject(
	value,
	minimumFractionDigits = 0,
	maximumFractionDigits = 3,
) {
	if (Array.isArray(value)) {
		return value?.reduce((acc, key) => {
			return [
				...acc,
				formatNumberToCurrencyFromObject(
					key,
					minimumFractionDigits,
					maximumFractionDigits,
				),
			];
		}, []);
	}

	if (typeof value === 'object') {
		const objectKeys = Object.keys(value);

		return objectKeys?.reduce((acc, key) => {
			return {
				...acc,
				[key]: formatNumberToCurrencyFromObject(
					value[key],
					minimumFractionDigits,
					maximumFractionDigits,
				),
			};
		}, {});
	}

	return typeof value === 'number'
		? value.toLocaleString('pt-BR', {
				minimumFractionDigits,
				maximumFractionDigits,
		  })
		: value;
}

interface INumberToLocaleString {
	value: number;
	locale?: string;
	minimumFractionDigits?: number;
	maximumFractionDigits?: number;
	[key: string]: any;
}

export function numberToLocaleString({
	value,
	locale = 'pt-BR',
	minimumFractionDigits = 0,
	maximumFractionDigits = 3,
	...others
}: INumberToLocaleString) {
	return value?.toLocaleString(locale, {
		minimumFractionDigits,
		maximumFractionDigits,
		...others,
	});
}
