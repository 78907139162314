import { useQuery } from '@tanstack/react-query';
import { getSalesChannelById } from 'services/api/requests/salesChannel';

export const useGetSalesChannelById = (id: Number) => {
	return useQuery(['salesChannelById', id], getSalesChannelById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
