import { defaultInputStyle } from '../../utils/forms/defaultsProps';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useEffect, useState } from 'react';
import { useCreateStopTopology } from 'services/queryClient/wrapperHooks/stopTypology/useCreateStopTypology';
import { useEditStopTopology } from 'services/queryClient/wrapperHooks/stopTypology/useEditStopTopology';
import { useHistory } from 'react-router-dom';
import {
	validateByRegex,
	validateRequired,
} from '../../utils/forms/validations';
import moment from 'moment';

interface IFormCadastrarEditarTipologia {
	initialState: any;
	typologyId?: number;
}

const FormCadastrarEditarTipologia = ({
	initialState,
	typologyId = 0,
}: IFormCadastrarEditarTipologia) => {
	const history = useHistory();

	const isEditing = !!typologyId;

	const [payload, setPayload] = useState<any>();
	const [hiddenTimeMask, setHiddenTimeMask] = useState<boolean>();
	const [formInitialState, setFormInitialState] = useState<any>();

	const { mutate: createTypology } = useCreateStopTopology(payload);
	const { mutate: updateTypology } = useEditStopTopology(payload, typologyId);

	useEffect(() => {
		setFormInitialState(initialState);
		setHiddenTimeMask(!initialState?.programada);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialState]);

	const goToTypology = useCallback(() => {
		history.goBack();
	}, [history]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				tipologia_parada: values?.tipologia_parada,
				programada: values?.programada,
				tempo_de_parada: values?.programada
					? moment.duration(values?.tempo_de_parada).asSeconds()
					: null,
			};

			setPayload(body);

			if (isEditing) updateTypology();
			else createTypology();
		},
		[createTypology, isEditing, updateTypology],
	);

	const handlerOnChangeProgramada = useCallback((e, formProps) => {
		if (formProps) {
			const { getValues } = formProps;
			setHiddenTimeMask(!getValues()?.programada);
		} else {
			setHiddenTimeMask(false);
		}
	}, []);

	const isHiddenInputTimeMask = useCallback(() => {
		return hiddenTimeMask;
	}, [hiddenTimeMask]);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'tipologia_parada',
							label: 'Nome da Parada',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								'data-test':
									'input-nome_da_parada-cadastrar_editar-page-paradas',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps: {
					flexDirection: 'row',
					w: '50%',
				},
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'switch',
							name: 'programada',
							label: 'Parada Programada',
							labelTooltip:
								'Caso seja selecionado indica que a parada é Programada.',
							inputProps: {
								'data-test':
									'switch-parada_programada-cadastrar_editar-page-paradas',
								colorScheme: 'red',
								onChange: handlerOnChangeProgramada,
							},
						}),
					},
					{
						...makeGenericFormInputStructure({
							type: 'timemask',
							name: 'tempo_de_parada',
							label: 'Tempo da parada',
							hidden: isHiddenInputTimeMask,
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
								...validateByRegex(
									/([0-1][0-9]|2[0-3]):([0-5][0-9]:)([0-5][0-9])/,
									'Horário inválido! Informe um valor entre 00:00:00 à 23:00:00.',
								),
							},
							inputProps: {
								...defaultInputStyle,
							},
						}),
					},
				],
			},
		];
	}, [handlerOnChangeProgramada, isHiddenInputTimeMask]);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-paradas',
						save: 'button-salvar-page-paradas',
					}}
					initialState={formInitialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					onSubmit={onSubmit}
					onCancel={goToTypology}
				/>
			</>
		);
	}, [formInitialState, renderRows, onSubmit, goToTypology]);

	return renderForm();
};

export default FormCadastrarEditarTipologia;
