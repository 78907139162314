import { RowEditAndDelete } from "components/icons/RowEditAndDelete";

export const tableColumnsManagement = [
  {
    Header: "Usuário",
    accessor: "usuario",
  },
  {
    Header: "Login",
    accessor: "login",
  },
  {
    Header: "Tipo",
    accessor: "tipo",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: " ",
    type: "jsx",
    accessor: "button",
    jsx: RowEditAndDelete,
    filter: false,
  },
];
