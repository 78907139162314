import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getApportionInconsistencies } from 'services/api/requests/apportionment';

export const useGetApportionInconsistencies = (cicloId?: string | number) => {
	const toast = useToast();
	return useQuery(
		['inconsistencies-apportionment', cicloId],
		getApportionInconsistencies,
		{
			retry: 1,
			enabled: false,
			onError: (err: any) => {
				toast({
					title: 'Erro!',
					description:
						'Não foi possível verificar a existencia de inconsistencia do rateio!',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			},
		},
	);
};
