import { editStopTopology } from 'services/api/requests/production';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { stopTypology } from 'models/stop-topology.model';

export const useEditStopTopology = (body: stopTypology, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editStopTopology(body, id), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['tipologiaParadas-list']);
			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Tipologia de parada atualizada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			if (error.response.data.length > 0) {
				toast({
					title: 'Erro ao atualizar tipologia de parada!',
					description: error.response.data[0].message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Erro ao atualizar tipologia de parada!',
					description:
						'Ocorreu um erro ao tentar atualizar a tipologia de parada, verifique os dados e tente novamente.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
