import {
	ButtonGroup,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';

export const ModalSave = (props) => {
	const { isOpen, saveChanges, onClose, setSaveYear } = props;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size='xl'
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalCloseButton />
				<ModalBody>
					<Heading
						color='gray.700'
						mb={2}
						size='md'
					>
						Atualizar Meta de Produção Anual
					</Heading>
					<br />
					<Text color='gray.500'>
						Você deseja atualizar este mesmo valor para todos os
						meses futuros do ano selecionado?
					</Text>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							type='ghost'
							onClick={() => {
								setSaveYear(false);
								saveChanges();
							}}
							title='Não, salvar só para mês selecionado'
						/>
						<ButtonComponent
							type='secondary'
							data-test='button-confirmar-salvar'
							onClick={() => {
								setSaveYear(true);
								saveChanges();
							}}
							title='Sim'
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
