import { createMember } from 'services/api/requests/requests';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useCreateMember = (body, stayOnPage?) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();
	return useMutation(() => createMember(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['membro-list']);
			if (!stayOnPage) history.goBack();
			toast({
				title: 'Criado com sucesso!',
				description: 'Membro criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Membro!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
