import { useQuery } from '@tanstack/react-query';
import { getCollaborationsByFamily } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

interface UseCollaborationsByFamily {
	cycleId: string;
	familyId: string;
}
export const useCollaborationsByFamily = ({
	cycleId,
	familyId,
}: UseCollaborationsByFamily) => {
	return useQuery(
		['collaborations-by-family', cycleId, familyId],
		getCollaborationsByFamily,
		{
			refetchOnWindowFocus: false,
		},
	);
};
