// Chakra imports
import { Box, Portal, useDisclosure } from "@chakra-ui/react";
// Layout components
import { useState } from "react";
import Navbar from "../../../components/navbar/NavbarAdmin";
import Sidebar from "../../../components/sidebar/Sidebar";


import "mapbox-gl/dist/mapbox-gl.css";


import SidebarHelp from "components/sidebar/sidebarHelp/SidebarHelp";
import "mapbox-gl/dist/mapbox-gl.css";
// import routes from "routes";
import { useGlobalContext } from "contexts/GlobalContext";
import SidebarHelpProvider from "contexts/providers/SidebarHelpProvider";
import { SidebarContext } from "contexts/SidebarContext";
// Custom Chakra theme
export default function Default(props) {
  const { ...rest } = props;
  const { routes } = useGlobalContext()
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const [sidebarState, setSidebarState] = useState('opened')
  const [sidebarHelpState, setSidebarHelpState] = useState('closed')


  function sidebarsSize() {
    if (sidebarState === 'closed' && sidebarHelpState === 'closed') {
      return { base: "100%", xl: "calc( 100% - 100px )" }
    } else if (sidebarState === 'closed' && sidebarHelpState === 'opened') {
      return { base: "100%", xl: "calc( 100% - 380px )" }
    } else if (sidebarState === 'opened' && sidebarHelpState === 'opened') {
      return { base: "100%", xl: "calc( 100% - 590px )" }
    } else {
      return { base: "100%", xl: "calc( 100% - 310px )" }
    }
  }
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  const route = props.route
  return (
    <Box
    //>>>DESCOMENTAR 
    // display={'flex'}
    >
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>
        <SidebarHelpProvider>
          {/*MENU*/}
          <Sidebar
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
            //>>>
            sidebarState={sidebarState}
            setSidebarState={setSidebarState}
            sidebarHelpState={sidebarHelpState}
            routes={routes}
            display='none'
            {...rest}
          />
          {/* <Box > */}
          <SidebarHelp
            sidebarHelpState={sidebarHelpState}
            setSidebarHelpState={setSidebarHelpState}
            display='none'
          />
          {/* </Box> */}
          {/* TABELA */}
          <Box
            float='right'
            minHeight='100vh'
            height='100%'
            overflow='auto'
            position='relative'
            // position='fixed'
            maxHeight='100%'
            //IMPLEMENTAÇÃO DO HELP-SIDEBAR
            w={sidebarsSize}
            mr={sidebarHelpState === 'opened' ? '285px' : '10px'}

            transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
            transitionDuration='.2s, .2s, .35s'
            transitionProperty='top, bottom, width'
            transitionTimingFunction='linear, linear, ease'>
            <Portal>
              <Box>
                <Navbar
                  sidebarState={sidebarState}
                  sidebarHelpState={sidebarHelpState}
                  setSidebarHelpState={setSidebarHelpState}
                  onOpen={onOpen}
                  // logoText={"Horizon UI Dashboard PRO"}
                  brandText={route.name}
                  // secondary={getActiveNavbar(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>
            <Box
              mx='auto'
              p={{ base: "20px", md: "30px" }}
              pe='20px'
              minH='100vh'
              pt='50px'
            >
              {props.children}
            </Box>
          </Box>
        </SidebarHelpProvider>
      </SidebarContext.Provider>
    </Box>
  );
}
