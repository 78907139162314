import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

// export const getMarkets = async () => {
//     const response = await api.get("/mercado")
//     return response.data
// };

export const getMarkets = async () => {
	const response = await api.get('/hierarquiaComercial');
	return response.data;
};

export const getFilterCommercialHierarchy = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, canal_venda_id] = queryKey;
	if (canal_venda_id) {
		const response = await api.get(
			`/hierarquiaComercial/filtrosHierarquiaComercial?canal_venda_id=${canal_venda_id}`,
		);
		return response.data;
	}
	const response = await api.get(
		'/hierarquiaComercial/filtrosHierarquiaComercial',
	);
	return response.data;
};

// export const getFilterCommercialHierarchy = async (params?) => {
//   const { mercado, canal, forca, zona, ...rest } = params;
//   const marketQuery = mercado.map((market) => `mercado=${market.value}`).join('&');
//   const channelQuery = canal.map((channel) => `canal=${channel.value}`).join('&');
//   const forcaQuery = forca.map((forca) => `forca=${forca.value}`).join('&');
//   const zonaQuery = zona.map((zona) => `zona=${zona.value}`).join('&');
// //   const memberQuery = membro.map((member) => `membro=${member.value}`).join('&');

//   const query = [] as string[]

//   if (marketQuery) query.push(marketQuery)
//   if (channelQuery) query.push(channelQuery);
//   if (forcaQuery) query.push(forcaQuery);
//   if (zonaQuery) query.push(zonaQuery);
// //   if (memberQuery) query.push(memberQuery);

//   const response = await api.get(`/hierarquiaComercial/filtrosHierarquiaComercial??${query.join('&')}`, {
//     params: {
//       ...rest,
//     },
//   });

//   return response.data;
// }

// export const createMarket = async (body) => {
//     const response = await api.post("/mercado", body);
//     return response.data
// };

export const createMarket = async (body) => {
	const response = await api.post('/hierarquiaComercial', body);
	return response.data;
};

// export const updateMarket = async (id: number) => {
// 	const response = await api.patch(
// 		`/hierarquiaComercial/hierarquiaComercialDelete?id=${id}`,
// 	);
// 	return response.data;
// };

export const deleteHierarchy = async (id: number) => {
	const response = await api.delete(`/hierarquiaComercial?id=${id}`);
	return response.data;
};

export const getMarketById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get('mercado/mercadoById', {
		params: { id },
	});
	return response.data;
};

export const updateMarket = async (id: number, body) => {
	const response = await api.patch('mercado', body, {
		params: { id },
	});
	return response.data;
};

export const deleteMarketByFilters = async (filters: any) => {
	const response = await api.post('mercado/deletarPorFiltros', filters);
	return response.data;
};

export const deleteMarket = async (id: number) => {
	const response = await api.delete('mercado', { params: { id } });
	return response.data;
};
