import GenericRowEditAndDeleteModal from 'components/icons/GenericRowEditAndDeleteModal';

export const columnsDataZonaVenda = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Canal de Venda',
		accessor: 'nome_canal_venda',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Nome do canal de venda',
	},
	{
		Header: 'Zona de Venda',
		accessor: 'nome_zona_venda',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
		hover: 'Nome da zona de venda',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: GenericRowEditAndDeleteModal,
		filter: false,
	},
];
