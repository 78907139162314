import {
	ButtonGroup,
	Heading,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useDeleteFamily } from 'services/queryClient/wrapperHooks/useDeleteFamily';
import { useDeleteProductModel } from 'services/queryClient/wrapperHooks/useDeleteProductModel';
import { useDeleteSubFamily } from 'services/queryClient/wrapperHooks/useDeleteSubFamily';
import { renderDeleteMessage } from 'utils/functions/modalFunctions';

interface IModalDeleteProps {
	type: 'family' | 'subFamily' | 'productModel';
	isOpen: boolean;
	onClose: () => void;
	removeId: number | string;
}

const ModalDelete = (props: IModalDeleteProps) => {
	const { type, isOpen, onClose, removeId } = props;

	const { mutate: deleteFamily } = useDeleteFamily();
	const { mutate: deleteSubFamily } = useDeleteSubFamily();
	const { mutate: deleteProductModel } = useDeleteProductModel();

	function getModalTitle() {
		switch (type) {
			case 'family':
				return 'Excluir família';
			case 'subFamily':
				return 'Excluir subfamília';
			case 'productModel':
				return 'Excluir modelo de produto';
		}
	}

	function renderMessage() {
		switch (type) {
			case 'family':
				return renderDeleteMessage([
					'Você está prestes a excluir esta família da sua base de cadastros. Os produtos dessa famíla não serão removidos, apenas ficarão sem família. Excluir esta família resultará na remoção dos seguintes itens vinculados a ela:',
					'Colaboração, Consenso, Rateio, Estoque, Orçamento',
					'Você tem certeza que deseja excluir?',
				]);
			case 'subFamily':
				return 'Você está prestes a excluir esta subfamília, isso resultará na remoção de todos os seus vínculos e históricos.';
			case 'productModel':
				return 'Você está prestes a excluir este modelo, isso resultará na remoção de todos os seus vínculos e históricos.';
		}
	}

	function handleDelete() {
		switch (type) {
			case 'family':
				deleteFamily(removeId);
				break;
			case 'subFamily':
				deleteSubFamily(removeId);
				break;
			case 'productModel':
				deleteProductModel(removeId);
				break;
		}

		onClose();
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader>
					<Heading
						color='gray.700'
						mb={2}
						size='md'
					>
						{getModalTitle()}
					</Heading>
				</ModalHeader>

				<ModalBody>{renderMessage()}</ModalBody>

				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							data-test='button-cancelar-modal-familia'
							type='ghost'
							title='Cancelar'
							onClick={onClose}
						/>
						<ButtonComponent
							data-test='button-excluir-modal-familia'
							type='secondary'
							title='Excluir'
							onClick={handleDelete}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ModalDelete;
