import api from '../api';

export const getRelatedCustomers = async () => {
	const response = await api.get('/clienteRelacionado');
	return response.data;
};

export const createRelatedCustomer = async (body) => {
	const response = await api.post('/clienteRelacionado', body);
	return response.data;
};

export const deleteRelatedCustomer = async (id: number) => {
	const response = await api.delete(`clienteRelacionado?id=${id}`);
	return response.data;
};
