import api from 'services/api/api';
import { PAGINATION_SIZE_LIST } from 'utils/consts/pagination';

export const hourly = async ({ queryKey }) => {
	const [key, date, recursoId, produtoId] = queryKey;

	let url = `/horaHora`;
	if (date && recursoId && produtoId) {
		url += `?data=${date}&recurso_id=${recursoId}&produto_id=${produtoId}`;
	} else if (date && recursoId) {
		url += `?data=${date}&recurso_id=${recursoId}`;
	} else if (date && produtoId) {
		url += `?data=${date}&produto_id=${produtoId}`;
	} else if (date) {
		url += `?data=${date}`;
	}

	const response = await api.get(url);
	return response.data;
};

export const fetchHourlyByFilters = async (body) => {
	const requestBody = {
		paginacao: {
			page: body.page,
			size: body.size || PAGINATION_SIZE_LIST[0],
			order: [],
		},
		filtros: body.filters,
	};

	const response = await api.post('/horaHora/view', requestBody);
	return response.data;
};

export const deleteHourlyByFilters = async (filters: any) => {
	const response = await api.post('horaHora/deletarPorFiltros', filters);
	return response.data;
};
