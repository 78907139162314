import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getPortfolioById } from 'services/api/requests/portfolio';

export const useGetPortfolioById = (setForm: any, id: number) => {
	return useQuery(['portfolioById', id], getPortfolioById, {
		onSuccess: (data) => {
			setForm({
				ruim: data.ruim,
				competencia: moment(data.data, 'YYYY-MM-DD').format(
					'YYYY-MM-DD',
				),
				notaFiscal: data.nf,
				canalVenda: data.canalVenda.id,
				cliente: data.cliente?.id,
				familia: data.produto?.familia?.id,
				codigoProduto: data.produto.id,
				quantidade: data.quantidade?.toLocaleString('pt-BR'),
				receitaBruta: data.receita_bruta.toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				}),
				faturado: data.faturado,
				cnpjCpf: '',
				clienteNome: '',
			});
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};

export const useGetPortfolioWithId = (id: Number) => {
	return useQuery(['portfolioWithId', id], getPortfolioById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
