import CurrencyInput from 'react-currency-input-field';

import './DecimalField.css';

interface IDecimalField {
	defaultValue?: number | string;
	allowDecimals?: boolean;
	allowNegativeValue?: boolean;
	decimalsLimit?: number;
	prefix?: string;
	sufix?: string;
	decimalSeparator?: string;
	onValueChange?: any;
	groupSeparator?: string;
	[key: string]: any;
}

const DecimalField = ({
	defaultValue = undefined,
	allowDecimals = true,
	allowNegativeValue = false,
	decimalsLimit = 2,
	prefix = 'R$ ',
	decimalSeparator = ',',
	groupSeparator = '.',
	maxLength = 15,
	...props
}: IDecimalField) => {
	const { onChange, ...others } = props;
	return (
		<CurrencyInput
			className='inputDecimal'
			intlConfig={{ locale: 'pt-BR' }}
			defaultValue={defaultValue}
			allowDecimals={allowDecimals}
			allowNegativeValue={allowNegativeValue}
			decimalsLimit={decimalsLimit}
			prefix={prefix}
			decimalSeparator={decimalSeparator}
			onValueChange={onChange}
			maxLength={maxLength}
			{...others}
		/>
	);
};

export default DecimalField;
