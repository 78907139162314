import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getParameterizationCompanyById } from 'services/api/requests/companyConfigs/parameterizationCompany';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useGetParameterizationCompanyById = () => {
	const toast = useToast();
	return useQuery(
		['parameterization-company-list'],
		getParameterizationCompanyById,
		{
			onError: (error) => {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					'Erro ao buscar parâmetros da empresa!',
				);
				toastErrors?.forEach((element) => toast(element));
			},
			refetchOnWindowFocus: false,
		},
	);
};
