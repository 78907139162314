import { createStopTopology } from 'services/api/requests/production';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { stopTypology } from 'models/stop-topology.model';

export const useCreateStopTopology = (body: stopTypology) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => createStopTopology(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['tipologiaParadas-list']);
			history.goBack();
			toast({
				title: 'Cadastrado com sucesso!',
				description:
					'Cadastro de tipologia de parada salvo com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			if (error.response.data.length > 0) {
				toast({
					title: 'Erro ao cadastrar tipologia de parada!',
					description: error.response.data[0].message,
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Erro ao cadastrar tipologia de parada!',
					description:
						'Ocorreu um erro ao tentar cadastrar a tipologia de parada, verifique os dados e tente novamente.',
					position: 'bottom-left',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		},
	});
};
