import { useCallback, useEffect, useMemo, useState } from 'react';

import './GenericAutocomplete.css';
import GenericInput from '../inputs/GenericInput';

interface IGenericAutocomplete {
	searchType: string;
	inputProps?: {
		ref?: any;
		value?: any;
		initialLabel?: string;
		onChange?: (value) => void;
		renderInputLabel?: (value: any) => string;
		clearInput?: () => boolean;
		useSearchAsNewValue?: boolean;
		filterByData?: {
			apiData: any[];
			filterData: (value: any, search: string) => boolean;
		};
		[key: string]: any;
	};
}

const GenericAutocompleteWithData = ({
	searchType,
	inputProps,
}: IGenericAutocomplete) => {
	const {
		ref,
		value,
		initialLabel,
		onChange,
		renderInputLabel = (value) => 'Não implementado',
		clearInput = () => {},
		hasButton = false,
		useSearchAsNewValue = false,
		filterByData = {},
		...othersInputProps
	}: any = inputProps;

	const { apiData, filterData } = filterByData;

	const [search, setSearch] = useState<string>('');
	const [limit, setLimit] = useState<number>(5);
	const [totalFilteredData, setTotalFilteredData] = useState<number>(5);
	const [options, setOptions] = useState<any[]>([]);

	const [isAddMoreResult, setAddMoreResult] = useState<boolean>(false);
	const [canRequestData, setCanRequestData] = useState<boolean>(false);
	const [canShowOptions, setCanShowOptions] = useState<boolean>(false);
	const [isFirstDefinition, setFirstDefinition] = useState<boolean>(true);

	const GetRequestProps = useCallback(() => {
		switch (searchType) {
			case 'cidade':
				return !!apiData?.length ? apiData : [];
			case 'marca':
				return !!apiData?.length ? apiData : [];
			case 'modelo':
				return !!apiData?.length ? apiData : [];
			case 'versao':
				return !!apiData?.length ? apiData : [];
			default:
				throw new Error('Mutate para autocomplete não implementado!');
		}
	}, [apiData, searchType]);

	const data = useMemo(() => GetRequestProps(), [GetRequestProps]);

	function addMoreResult() {
		setAddMoreResult(true);
		setLimit((prev) => prev + 5);
	}

	function handleOnChange(e: any) {
		if (onChange)
			useSearchAsNewValue ? onChange(e?.target?.value) : onChange(null);

		setCanRequestData(true);
		setSearch(e?.target?.value);

		setFirstDefinition(false);
	}

	function handleOnClick() {
		setCanShowOptions(true);
	}

	function handleOnMouseLeave() {
		setCanShowOptions(false);
	}

	function handleOnSelect(data, textValue) {
		setCanRequestData(false);
		setCanShowOptions(false);

		if (onChange) onChange(data);

		setSearch(textValue);

		setFirstDefinition(false);
	}

	function renderOptions() {
		return !!options?.length ? (
			<>
				{options?.map((d) => {
					const textValue = renderInputLabel(d);
					return (
						<div
							key={d?.id}
							className='option-item'
							onClick={() => handleOnSelect(d, textValue)}
							onKeyUp={() => {}}
						>
							{textValue}
						</div>
					);
				})}
			</>
		) : null;
	}

	function renderMoreOptions() {
		return options?.length < totalFilteredData ? (
			<div
				key='action-add'
				className='option-add'
				onClick={() => addMoreResult()}
				onKeyUp={() => {}}
			>
				Mais resultados
			</div>
		) : null;
	}

	useEffect(() => {
		if (canRequestData) {
			setLimit(5);
		}
	}, [search]);

	useEffect(() => {
		if (!!filterData) {
			const newOptions = data?.filter((el) => filterData(el, search));
			const total = newOptions?.length;

			setOptions(newOptions?.slice(0, limit));
			setTotalFilteredData(total);
		} else {
			setOptions(data);
			setTotalFilteredData(data?.length);
		}
	}, [filterData, limit, search]);

	useEffect(() => {
		if (isAddMoreResult) setAddMoreResult(false);
	}, [isAddMoreResult, limit]);

	useEffect(() => {
		setSearch(initialLabel);
	}, [initialLabel]);

	useEffect(() => {
		if (clearInput()) {
			if (onChange) onChange(null);
			setSearch('');
			setOptions([]);
		}
	}, [clearInput]);

	function renderContent() {
		if (!canShowOptions) return null;

		if (!!options?.length)
			return (
				<div
					className={
						hasButton
							? 'option-container-with-button'
							: 'option-container'
					}
					onMouseLeave={handleOnMouseLeave}
				>
					{renderOptions()}
					{renderMoreOptions()}
				</div>
			);

		if (!isFirstDefinition)
			return (
				<div
					className={
						hasButton
							? 'option-container-with-button'
							: 'option-container'
					}
					onMouseLeave={handleOnMouseLeave}
				>
					<div
						key='no-options'
						className='no-options'
					>
						Nenhum registro encontrado!
					</div>
				</div>
			);
	}

	function render() {
		const genericInputProps = {
			placeholder: 'Digite para pesquisar',
			...othersInputProps,
			onClick: handleOnClick,
			onChange: handleOnChange,
			value: search,
		};

		return (
			<div
				style={{ width: '100%' }}
				ref={ref}
			>
				<GenericInput
					type='text'
					inputProps={genericInputProps}
				/>

				{renderContent()}
			</div>
		);
	}

	return render();
};

export default GenericAutocompleteWithData;
