// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';

import Links from './Links';

// FUNCTIONS
function SidebarContent(props) {
	const { routes, sidebarState, sidebarHelpState } = props;
	// SIDEBAR
	return (
		<Flex
			direction='column'
			minH='100%'
			height='max-content'
			borderRadius='30px'
		>
			<Stack
				direction='column'
				mb='auto'
				mt='3px'
			>
				<Box
					ps='10px'
					pe={{ md: '16px', '2xl': '1px' }}
				>
					<Links
						routes={routes}
						sidebarState={sidebarState}
						sidebarHelpState={sidebarHelpState}
					/>
				</Box>
			</Stack>
		</Flex>
	);
}

export default SidebarContent;
