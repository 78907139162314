
export const nameMonths: string[] = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
]
export const getNameMonthByDate = (date: Date) => {
    return nameMonths[date.getMonth()];
}