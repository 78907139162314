import { useCallback, useEffect, useMemo, useState } from 'react';
import GenericForm, { IFormRows } from './GenericForm';
import {
	defaultFormStyle,
	formFlexProps,
	rowFlexProps,
	defaultInputStyle,
} from '../../utils/forms/defaultsProps';
import { useHistory } from 'react-router-dom';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { validateRequired } from '../../utils/forms/validations';
import { useCityByStateList } from '../../services/queryClient/wrapperHooks/useCityByStateList';
import { renderCity } from '../../utils/forms/autocomplete';
import { useCreateCitySalesZone } from '../../services/queryClient/wrapperHooks/useCreateCitySalesZone';
import { useUpdateCitySalesZone } from '../../services/queryClient/wrapperHooks/useUpdateCitySalesZone';

interface IFormCadastrarEditarCidade {
	initialState: any;
	cityId?: number;
	additionalData?: any;
}

const FormCadastrarEditarCidade = ({
	initialState,
	cityId,
	additionalData,
}: IFormCadastrarEditarCidade) => {
	const history = useHistory();

	const isEditing = !!cityId;

	const [formProps, setFormProps] = useState<any>();
	const [formInitialState, setFormInitialState] = useState<any>({});
	const [selectedUf, setSelectedUf] = useState<string>();
	const [selectedCity, setSelectedCity] = useState<any>();
	const [payload, setPayload] = useState<any>();

	const { data: citiesData } = useCityByStateList(selectedUf);

	const { mutate: createAssociation } = useCreateCitySalesZone(payload);
	const { mutate: editAssociation } = useUpdateCitySalesZone(payload, cityId);

	useEffect(() => {
		setFormInitialState(initialState);
		setSelectedUf(additionalData?.uf);
	}, [initialState, additionalData]);

	useEffect(() => {
		if (isEditing && !!citiesData?.length) {
			const currentCity = citiesData?.find(
				(el) => el?.id === initialState?.cidade_id,
			);

			setSelectedCity(currentCity);
		}
	}, [citiesData, initialState?.cidade_id, isEditing]);

	const statesOptions = useMemo(() => {
		return (
			additionalData?.states?.map((el) => (
				<option
					value={el?.id}
					key={el?.id}
				>
					{el?.nome}
				</option>
			)) || []
		);
	}, [additionalData?.states]);

	const saleZonesOptions = useMemo(() => {
		return (
			additionalData?.salesZone?.map((el) => (
				<option
					value={el?.id}
					key={el?.id}
				>
					{el?.nome_zona_venda} - {el?.canal_venda?.nome_canal_venda}
				</option>
			)) || []
		);
	}, [additionalData?.salesZone]);

	const handleOnChangeState = useCallback(
		(event, _formProps) => {
			if (!formProps) setFormProps(_formProps);

			const value = event?.target?.value;

			const state = additionalData?.states?.find(
				(el) => el?.id === Number(value),
			);

			setSelectedUf(state?.sigla);

			const { setValue } = _formProps;

			setValue('cidade_id', '');
			setSelectedCity(null);
		},
		[additionalData?.states, formProps],
	);

	const handleOnChangeCity = useCallback(
		(value: any, _formProps: any) => {
			if (!formProps) setFormProps(_formProps);

			const index = citiesData?.findIndex((el) => el?.id === value?.id);

			if (index !== -1) {
				setSelectedCity(citiesData[index]);
				const { setValue } = _formProps;

				setValue(
					'nome_microrregiao',
					citiesData[index]?.microrregiao?.nome,
				);
				setValue(
					'nome_mesorregiao',
					citiesData[index]?.microrregiao?.mesorregiao?.nome,
				);
			}
		},
		[citiesData, formProps],
	);

	const filterCities = useCallback((value: any, search: string) => {
		return value?.nome?.toLowerCase()?.includes(search?.toLowerCase());
	}, []);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'uf_id',
							label: 'UF',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'select-uf-cadastrar_editar-page-cidades',
								...defaultInputStyle,
								selectOptions: statesOptions,
								onChange: handleOnChangeState,
							},
							validate: {
								...validateRequired(),
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'autocomplete-with-data',
							name: 'cidade_id',
							label: 'Município',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'autocomplete-municipio-cadastrar_editar-page-cidades',
								...defaultInputStyle,
								searchType: 'cidade',
								useSearchAsNewValue: true,
								initialLabel: selectedCity?.nome || '',
								renderInputLabel: renderCity,
								filterByData: {
									apiData: citiesData,
									filterData: filterCities,
								},
								autocomplete: 'off',
								onChange: handleOnChangeCity,
							},
							validate: {
								...validateRequired(),
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_microrregiao',
							label: 'Microregião',
							inputProps: {
								'data-test':
									'input-microrregiao-cadastrar_editar-page-cidades',
								...defaultInputStyle,
								value: selectedCity?.microrregiao?.nome || '',
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_mesorregiao',
							label: 'Mesorregiao',
							inputProps: {
								'data-test':
									'input-mesorregiao-cadastrar_editar-page-cidades',
								...defaultInputStyle,
								value:
									selectedCity?.microrregiao?.mesorregiao
										?.nome || '',
							},
							disabled: () => true,
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'zona_venda_id',
							label: 'Zona de Venda',
							showRequiredOnLabel: true,
							inputProps: {
								'data-test':
									'select-zona_de_venda-cadastrar_editar-page-cidades',
								...defaultInputStyle,
								selectOptions: saleZonesOptions,
							},
							validate: {
								...validateRequired(),
							},
						}),
					},
				],
			},
		];
	}, [
		citiesData,
		filterCities,
		handleOnChangeCity,
		handleOnChangeState,
		saleZonesOptions,
		selectedCity?.microrregiao?.mesorregiao?.nome,
		selectedCity?.microrregiao?.nome,
		selectedCity?.nome,
		statesOptions,
	]);

	const goToCities = useCallback(() => {
		history.goBack();
	}, [history]);

	const onSubmit = useCallback(
		(values) => {
			const selectedState = additionalData?.states?.find(
				(el) => el?.id === Number(values?.uf_id),
			);

			const body = {
				cidade: {
					...selectedCity,
					microrregiao: {
						...selectedCity?.microrregiao,
						mesorregiao: {
							...selectedCity?.microrregiao?.mesorregiao,
							UF: selectedState || null,
						},
					},
				},
				estado: selectedState || null,
				zona_venda_id: Number(values?.zona_venda_id),
			};

			setPayload(body);

			if (isEditing) editAssociation();
			else createAssociation();
		},
		[
			additionalData?.states,
			createAssociation,
			editAssociation,
			isEditing,
			selectedCity,
		],
	);

	const renderForm = useCallback(() => {
		return (
			<GenericForm
				dataTestProps={{
					cancel: 'button-cancelar-page-cidades',
					save: 'button-salvar-page-cidades',
				}}
				initialState={formInitialState}
				formFlexProps={formFlexProps}
				rows={renderRows()}
				style={defaultFormStyle}
				onSubmit={onSubmit}
				onCancel={goToCities}
			/>
		);
	}, [goToCities, formInitialState, onSubmit, renderRows]);

	return renderForm();
};

export default FormCadastrarEditarCidade;
