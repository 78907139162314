import { IEnvironmentEntity } from '../environment';
export interface Isentry {
	dsn: string;
	env: string;
}
export const sentry: IEnvironmentEntity = {
	development: {
		dsn: process.env.REACT_APP_FRONTEND_SENTRY_DNS,
		env: process.env.REACT_APP_FRONTEND_NODE_ENV,
	},
	staging: {
		dsn: process.env.REACT_APP_FRONTEND_SENTRY_DNS,
		env: process.env.REACT_APP_FRONTEND_NODE_ENV,
	},
	production: {
		dsn: process.env.REACT_APP_FRONTEND_SENTRY_DNS,
		env: process.env.REACT_APP_FRONTEND_NODE_ENV,
	},
};
