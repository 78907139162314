// Chakra imports
import { Flex, Icon, Image, Text, useDisclosure } from '@chakra-ui/react';
import { IconCoin, IconDragDrop, IconChartPie3, IconHelp } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useContext, useEffect } from 'react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { TbClockShare } from 'react-icons/tb';
import { RecentActivityLogModal } from 'components/modal/RecentActivityLogModal';

export default function Introdução() {
	const { setters } = useContext(SidebarHelpContext);

	const {
		isOpen: isOpenRecentActivityLogModal,
		onOpen: onOpenRecentActivityLogModal,
		onClose: onCloseRecentActivityLogModal,
	} = useDisclosure();

	const { setDataSidebar } = setters;

	const helpMetas: SidebarHelp = {
		title: 'Seja Bem-Vindo a Easy360',
		firstParagraph:
			'Nesta tela é possível seguir os primeiros passos de configuração inicial da easy360! Para que você desfrute completamente da esy360, é importante subir seu histórico de vendas para em seguida calcular o preço médio dos produtos e rateio de vendas. Estas informações são fundamentais para o bom funcionamento da easy360!',
		// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
		firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
		secondLink: 'https://calendly.com/suporte-easy360',
	};

	useEffect(() => {
		setDataSidebar(helpMetas);
	}, []);

	const { pathCompanyName, userAttributes } = useGlobalContext();

	const history = useHistory();

	return (
		<Flex
			pt={{ base: '130px', md: '80px', xl: '70px' }}
			pl='20px'
			flexWrap='wrap'
		>
			<Card
				h={'80vh'}
				style={{
					backgroundImage: `url("assets/bg-home.webp")`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
				borderRadius='30px'
			>
				<Image
					w={'350px'}
					position={'absolute'}
					left={0}
					src='assets/grafismo1.png'
				/>
				<Image
					w={'350px'}
					position={'absolute'}
					right={0}
					src='assets/grafismo2.png'
				/>
				<Flex
					flexDirection={'column'}
					w={'100%'}
					margin={'0 auto'}
					alignItems={'center'}
					my={36}
					position={'absolute'}
				>
					<Text
						fontSize={'40px'}
						py={'10px'}
						mb={4}
						px={'30px'}
						border={'2px solid'}
						color={'#fff'}
					>
						{userAttributes ? userAttributes.name : ''}
					</Text>
					<Text
						fontSize={'40px'}
						mb={16}
						color={'#fff'}
					>
						Seja bem-vindo a Easy360!
					</Text>

					<Text
						fontSize={'20px'}
						color={'#fff'}
					>
						Vamos começar?
					</Text>
				</Flex>
				<Flex
					gap={4}
					position={'absolute'}
					w={'97%'}
					bottom={'-35px'}
				>
					<Card
						bgColor='#E9EDF7'
						borderRadius='30px'
						justifyContent={'space-between'}
					>
						<Flex flexDirection={'column'}>
							<Icon
								as={IconCoin}
								boxSize={12}
								color={'#F0243E'}
							/>
							<Text
								color={'#314866'}
								fontSize={'24px'}
								fontWeight={'600'}
							>
								Suba suas vendas
							</Text>
							<Text
								color={'#F0243E'}
								fontWeight={'600'}
							>
								Vendas
							</Text>
							<Text color={'#314866'}>
								Importe o seu histórico de vendas.
							</Text>
						</Flex>

						<ButtonComponent
							marginTop={'4px'}
							fontSize={'13px'}
							type={'secondary'}
							title={'Clique aqui'}
							onClick={() =>
								history.push(
									`/${pathCompanyName}/cadastros/comercial/venda`,
								)
							}
						/>
					</Card>
					<Card
						bgColor='#E9EDF7'
						borderRadius='30px'
						justifyContent={'space-between'}
					>
						<Flex flexDirection={'column'}>
							<Icon
								as={IconDragDrop}
								boxSize={12}
								color={'#F0243E'}
							/>
							<Text
								color={'#314866'}
								fontSize={'24px'}
								fontWeight={'600'}
							>
								Calcule seu preço médio
							</Text>
							<Text
								color={'#F0243E'}
								fontWeight={'600'}
							>
								Ticket
							</Text>
							<Text color={'#314866'}>
								Calcule o preço médio de vendas de todos seus
								produtos.
							</Text>
						</Flex>

						<ButtonComponent
							fontSize={'13px'}
							type={'secondary'}
							title={'Clique aqui'}
							onClick={() =>
								history.push(
									`/${pathCompanyName}/demanda/ticketMedio`,
								)
							}
						/>
					</Card>
					<Card
						bgColor='#E9EDF7'
						borderRadius='30px'
						justifyContent={'space-between'}
					>
						<Flex flexDirection={'column'}>
							<Icon
								as={IconChartPie3}
								boxSize={12}
								color={'#F0243E'}
							/>
							<Text
								color={'#314866'}
								fontSize={'24px'}
								fontWeight={'600'}
							>
								Calcule seu rateio
							</Text>
							<Text
								color={'#F0243E'}
								fontWeight={'600'}
							>
								Rateio
							</Text>
							<Text color={'#314866'}>
								Calcule o rateio de vendas dos seus produtos.
							</Text>
						</Flex>

						<ButtonComponent
							fontSize={'13px'}
							type={'secondary'}
							title={'Clique aqui'}
							onClick={() =>
								history.push(
									`/${pathCompanyName}/demanda/rateio`,
								)
							}
						/>
					</Card>
					<Card
						bgColor='#E9EDF7'
						borderRadius='30px'
						justifyContent={'space-between'}
					>
						<Flex flexDirection={'column'}>
							<Icon
								as={TbClockShare}
								boxSize={12}
								color={'#F0243E'}
							/>
							<Text
								color={'#314866'}
								fontSize={'24px'}
								fontWeight={'600'}
							>
								Atividades Recentes
							</Text>
							<Text
								color={'#F0243E'}
								fontWeight={'600'}
							>
								Atividades
							</Text>
							<Text color={'#314866'}>
								Analise as atividades recentes dos usuários.
							</Text>
						</Flex>

						<ButtonComponent
							fontSize={'13px'}
							type={'secondary'}
							title={'Clique aqui'}
							onClick={onOpenRecentActivityLogModal}
						/>
					</Card>
					<Card
						bgColor='#E9EDF7'
						borderRadius='30px'
						justifyContent={'space-between'}
					>
						<Flex flexDirection={'column'}>
							<Icon
								as={IconHelp}
								boxSize={12}
								color={'#F0243E'}
							/>
							<Text
								color={'#314866'}
								fontSize={'24px'}
								fontWeight={'600'}
							>
								Dúvidas
							</Text>
							<Text
								color={'#F0243E'}
								fontWeight={'600'}
							>
								Fale conosco
							</Text>
							<Text color={'#314866'}>
								Será sempre um prazer te ajudar!
							</Text>
						</Flex>

						<ButtonComponent
							fontSize={'13px'}
							type={'secondary'}
							title={'Clique aqui'}
							onClick={() =>
								(window.location.href = `https://calendly.com/suporte-easy360`)
							}
						/>
					</Card>
				</Flex>
			</Card>
			<RecentActivityLogModal
				isOpen={isOpenRecentActivityLogModal}
				onOpen={onOpenRecentActivityLogModal}
				onClose={onCloseRecentActivityLogModal}
			/>
		</Flex>
	);
}
