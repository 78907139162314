import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface BarChartProps {
	chartData: any;
	chartOptions: any;
}

interface chartData {
	name: string;
	data: (number | null)[];
}

const BarChart = (props: BarChartProps) => {
	const [chartData, setChartData] = useState<chartData[]>([]);
	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		setChartData(props.chartData);
		setChartOptions(props.chartOptions);
	}, [props]);
	return (
		<ReactApexChart
			options={chartOptions}
			series={chartData}
			type='bar'
			width='90%'
			height='300px'
		/>
	);
};

export default BarChart;
