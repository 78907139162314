import { DemandCapacity } from './../../../../models/capacity-dashboard.model';

interface DemandCapacityDictionary {
	[nome_grupo_recurso: string]: SumGraphData;
}

interface SumGraphData {
	sumCapacity: number;
	sumDemand: number;
}

interface BarChartData {
	name: string;
	data: number[];
}

export interface BarChartInterface {
	barChartData: BarChartData[];
	categories: string[];
}

export default function formatChartData(
	demandaCapacidade: DemandCapacity[],
): BarChartInterface {
	const sorted = demandaCapacidade.sort((a, b) => {
		const nameA = a.nome_grupo_recurso.toUpperCase();
		const nameB = b.nome_grupo_recurso.toUpperCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		return 0;
	});

	const dictionary = {} as DemandCapacityDictionary;
	sorted.forEach((demand) => {
		const verifyRangeNumber = (value: number | undefined): boolean => {
			return value !== undefined && (value < 0 || value >= 0);
		};

		const actualValueCapacity = verifyRangeNumber(
			dictionary[demand.nome_grupo_recurso]?.sumCapacity,
		)
			? dictionary[demand.nome_grupo_recurso]?.sumCapacity
			: 0;
		const actualValueDemand = verifyRangeNumber(
			dictionary[demand.nome_grupo_recurso]?.sumCapacity,
		)
			? dictionary[demand.nome_grupo_recurso]?.sumDemand
			: 0;

		const newValues = {
			sumCapacity: Number(demand.capacidade) + actualValueCapacity,
			sumDemand: Number(demand.demanda) + actualValueDemand,
		};

		dictionary[demand.nome_grupo_recurso] = newValues;
	});

	const maximumGraph = 5;

	const categories = Object.keys(dictionary).slice(0, maximumGraph);

	const barChartData = [
		{
			name: 'Soma de capacidade / h',
			data: Object.values(dictionary)
				.map((values) => Number(values.sumCapacity.toFixed(2)))
				.slice(0, maximumGraph),
		},
		{
			name: 'Soma de demanda / h',
			data: Object.values(dictionary)
				.map((values) => Number(values.sumDemand.toFixed(2)))
				.slice(0, maximumGraph),
		},
	];

	return {
		barChartData,
		categories,
	};
}
