import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { editRelatedCustomer } from 'services/api/requests/customer';

export const useEditRelatedCustomer = (body, id: number) => {
	const queryClient = useQueryClient();
	const history = useHistory();
	const toast = useToast();

	return useMutation(() => editRelatedCustomer(body, id), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['clienteRelacionado-list']);
			history.goBack();
			toast({
				title: 'Atualizado com sucesso!',
				description: 'Cliente Relacionamento atualizado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			console.log(error);
			toast({
				title: 'Erro ao atualizar Cliente Relacionamento!',
				description: 'Verifique se preencheu o campo Relacionamento.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
