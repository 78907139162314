import {
	defaultFormStyle,
	defaultInputStyle,
} from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import { LoadingSpinner } from '../loading/Loading';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useState } from 'react';
import { useCompanyAreaList } from 'services/queryClient/wrapperHooks/useCompanyAreaList';
import { useCreateMember } from 'services/queryClient/wrapperHooks/useCreateMember';
import { useEditMember } from 'services/queryClient/wrapperHooks/useEditMember';
import { useHistory } from 'react-router-dom';
import { validateRequired } from '../../utils/forms/validations';

interface IFormCadastrarEditarMembro {
	initialState: any;
	memberId?: number;
}

const FormCadastrarEditarMembro = ({
	initialState,
	memberId = 0,
}: IFormCadastrarEditarMembro) => {
	const history = useHistory();

	const [payload, setPayload] = useState<any>();

	const isEditing = !!memberId;

	const { data: CompanyAreaData, isLoading: isLoadingCompanyArea } =
		useCompanyAreaList();

	const { mutate: createMember } = useCreateMember(payload);
	const { mutate: updateMember } = useEditMember(payload, memberId);

	const goToMembros = useCallback(() => {
		history.goBack();
	}, [history]);

	const renderSelectCompanyArea = useCallback(() => {
		if (!CompanyAreaData?.data?.length) return [];

		return CompanyAreaData?.data?.map((company) => (
			<option
				value={company?.id}
				key={company?.id}
			>
				{company?.nome_area_empresa}
			</option>
		));
	}, [CompanyAreaData?.data]);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'switch',
							name: 'ativo',
							label: 'Ativo',
							inputProps: {
								'data-test':
									'switch-ativo-cadastrar_editar-page-membros',
								colorScheme: 'red',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome_membro',
							label: 'Membro',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								'data-test':
									'input-membro-cadastrar_editar-page-membros',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'funcao',
							label: 'Função',
							inputProps: {
								'data-test':
									'input-funcao-cadastrar_editar-page-membros',
								...defaultInputStyle,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'email',
							name: 'email',
							label: 'Email',
							inputProps: {
								'data-test':
									'input-email-cadastrar_editar-page-membros',
								...defaultInputStyle,
								disabled: isEditing && initialState?.usuario_id,
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'select',
							name: 'area_empresa_id',
							label: 'Área da Empresa',
							inputProps: {
								'data-test':
									'select-area_da_empresa-cadastrar_editar-page-membros',
								...defaultInputStyle,
								selectOptions: renderSelectCompanyArea(),
							},
						}),
					},
				],
			},
		];
	}, [renderSelectCompanyArea]);

	const onSubmit = useCallback(
		(values) => {
			const body = {
				area_empresa_id: values.area_empresa_id || null,
				ativo: values.ativo,
				email: values.email || null,
				funcao: values.funcao || null,
				id: values.id,
				nome_membro: values.nome_membro,
			};

			setPayload(body);

			if (isEditing) updateMember();
			else createMember();
		},
		[createMember, isEditing, updateMember],
	);

	const renderForm = useCallback(() => {
		if (isLoadingCompanyArea) return <LoadingSpinner />;

		return (
			<>
				<GenericForm
					dataTestProps={{
						cancel: 'button-cancelar-page-membros',
						save: 'button-salvar-page-membros',
					}}
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToMembros}
				/>
			</>
		);
	}, [goToMembros, initialState, isLoadingCompanyArea, onSubmit, renderRows]);

	return renderForm();
};

export default FormCadastrarEditarMembro;
