import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOpportunityByFilters } from 'services/api/requests/opportunity/opportunity';
import { deletePortfolioByFilters } from 'services/api/requests/portfolio';
import { deleteForecastByFilters } from 'services/api/requests/forecast';
import { deleteSaleByFilters } from 'services/api/requests/sale';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';
import { deleteHourlyByFilters } from '../../api/requests/lancamentoHoraHora/hourly';
import { deleteProductByFilters } from 'services/api/requests/product';
import { deleteActualStockByFilters } from 'services/api/requests/stock';
import { deleteBudgetByFilters } from 'services/api/requests/budget';
import { deleteHierarchyByFilters } from 'services/api/requests/commercial';
import { deleteMemberByFilters } from 'services/api/requests/member';
import { deleteResourceByFilters } from '../../api/requests/resources';
import { deleteCityByFilters } from 'services/api/requests/city';
import { deleteStopTypologyByFilters } from 'services/api/requests/production';
import { deleteRelatedCustomerByFilters } from 'services/api/requests/customer';
import { deleteMarketByFilters } from 'services/api/requests/market';
import { deleteSalesForceByFilters } from 'services/api/requests/salesForce';
import { deleteSalesZoneByFilters } from 'services/api/requests/salesZone';
import { deleteSalesChannelByFilters } from 'services/api/requests/salesChannel';

interface IDeleteModalByFilters {
	provider: string;
	toastSuccessTitle: string;
	toastSuccessDescription: string;
	toastProcessingTitle: string;
	toastProcessingDescription: string;
	toastErrorTitle: string;
	invalidate: string;
	filters: any;
}

export const useDeleteModalByFilters = ({
	provider,
	toastSuccessTitle,
	toastSuccessDescription,
	toastProcessingTitle,
	toastProcessingDescription,
	toastErrorTitle,
	invalidate,
	filters,
}: IDeleteModalByFilters) => {
	const returnContext = {
		sale: deleteSaleByFilters,
		opportunity: deleteOpportunityByFilters,
		budget: deleteBudgetByFilters,
		portfolio: deletePortfolioByFilters,
		hourly: deleteHourlyByFilters,
		forecast: deleteForecastByFilters,
		product: deleteProductByFilters,
		actualStock: deleteActualStockByFilters,
		hierarchy: deleteHierarchyByFilters,
		member: deleteMemberByFilters,
		resource: deleteResourceByFilters,
		city: deleteCityByFilters,
		stopTypology: deleteStopTypologyByFilters,
		relatedCustomer: deleteRelatedCustomerByFilters,
		market: deleteMarketByFilters,
		salesForce: deleteSalesForceByFilters,
		salesZone: deleteSalesZoneByFilters,
		salesChannel: deleteSalesChannelByFilters,
	};

	const queryClient = useQueryClient();
	const callFunctionContext = returnContext[provider];

	const toast = useToast();

	return useMutation(() => callFunctionContext(filters), {
		onSuccess: (success): void => {
			queryClient.invalidateQueries([invalidate]);
			if (success?.['processing_request']) {
				toast({
					title: toastProcessingTitle,
					description: toastProcessingDescription,
					position: 'bottom-left',
					status: 'warning',
					duration: 8000,
					isClosable: true,
				});
			} else {
				toast({
					title: toastSuccessTitle,
					description: toastSuccessDescription,
					position: 'bottom-left',
					status: 'success',
					duration: 8000,
					isClosable: true,
				});
			}

			if (provider === 'product') {
				queryClient.removeQueries({
					predicate: (query: any) =>
						query.queryKey[0].startsWith('apportionment-family'),
				});
			}
		},
		onError: (error: any): void => {
			if (provider === 'salesChannel') {
				toast({
					title: toastErrorTitle,
					description: `Não foi possível remover o(s) Canal(is) de Venda(s), pois o(s) mesmo(s) está(ão) relacionado(s) com Rateio, Consenso, Colaborações, Plano irrestrito ou Oportunidades da Exceção da Demanda`,
					position: 'bottom-left',
					status: 'error',
					duration: 12000,
					isClosable: true,
				});
			} else {
				const toastErrors: Array<any> = mapErrorToToastProps(
					error,
					toastErrorTitle,
				);
				toastErrors?.forEach((element) => toast(element));
			}
		},
	});
};
