import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
	Heading,
	Icon,
} from '@chakra-ui/react';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { useEffect, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import {
	renderDeleteMessage,
	renderDeleteMessageForSalesChannel,
} from '../../utils/functions/modalFunctions';
import { IconTrash } from '@tabler/icons';

interface ICheckTableActions {
	filterModule: string;
	filters: any;
	deleteCallback: (filters: any) => any;
	allSelectedItens: boolean;
	hasSelectedItem: boolean;
	getSelectedIds: () => any[];
	initialPageSize: number;
	pageSize: number;
	totalRegisters: number;
	deleteMessage: string | string[];
}

const CheckTableActions = ({
	filterModule,
	filters,
	deleteCallback,
	allSelectedItens,
	getSelectedIds,
	initialPageSize,
	pageSize,
	totalRegisters,
	deleteMessage,
}: ICheckTableActions) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const [isDeleteAll, setDeleteAll] = useState<boolean>(false);

	const selectedIds = getSelectedIds()?.map((e) => {
		return Array.isArray(e) ? e[0] : e;
	});

	function openModal() {
		onOpen();
	}

	function onDelete() {
		let deleteFilters = {};
		if (isDeleteAll)
			deleteFilters = {
				filtros: [...filters],
				todos: isDeleteAll,
				ids: [],
			};
		else
			deleteFilters = {
				filtros: [...filters],
				todos: isDeleteAll,
				ids: selectedIds,
			};

		deleteCallback(deleteFilters);
		setDeleteAll(false);
		onClose();
	}

	function renderDeleteButton() {
		if (!!selectedIds?.length || allSelectedItens) {
			return (
				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<AddIcon boxSize={3} />}
						size='large'
						fontWeight='100'
						px='30px'
						py='8px'
						border='1px solid'
						borderRadius='100px'
						borderColor='easyBLUE.150'
						fontSize='16px'
						color='easyBLUE.300'
						_hover={{ bg: '#FAFCFE' }}
						_focus={{ bg: 'white' }}
						_active={{ bg: '#E2E8F0', boxShadow: 'none' }}
					>
						<Text>Opções</Text>
					</MenuButton>
					<MenuList>
						<MenuItem onClick={openModal}>
							<Text color='easyBLUE.300'>
								Remover itens selecionados
							</Text>
						</MenuItem>
					</MenuList>
				</Menu>
			);
		}

		return null;
	}

	function renderDeleteAll() {
		if (allSelectedItens) {
			return (
				<Flex
					alignItems='center'
					ml='20px'
				>
					<Text>
						Todos os{' '}
						{isDeleteAll ? totalRegisters : selectedIds?.length}{' '}
						registros nesta página estão selecionados.
					</Text>
					{initialPageSize === pageSize &&
						totalRegisters > pageSize && (
							<ButtonComponent
								data-test='button-selecionar_remover_selecao'
								type='ghost'
								title={
									isDeleteAll
										? `Remover seleção dos ${totalRegisters} registros.`
										: `Selecionar todos os ${totalRegisters} registros.`
								}
								border='none'
								textColor='#0296f2'
								onClick={() => setDeleteAll((prev) => !prev)}
								px={2}
							/>
						)}
				</Flex>
			);
		}

		return null;
	}

	function renderModal() {
		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				onEsc={onClose}
				size='xl'
				isCentered
			>
				<ModalOverlay />
				<ModalContent
					borderRadius={20}
					pt='20px'
				>
					<ModalHeader>
						<Icon
							as={IconTrash}
							color='easyRED.300'
							w={14}
							h={14}
						></Icon>
						<Heading
							color='gray.700'
							mb={2}
							size='md'
						>
							Excluir itens selecionados
						</Heading>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{filterModule === 'canalVenda'
							? renderDeleteMessageForSalesChannel(deleteMessage)
							: renderDeleteMessage(deleteMessage)}
						<Text
							color='gray.500'
							fontSize='sm'
							fontWeight={200}
						>
							Deseja excluir{' '}
							{isDeleteAll || selectedIds?.length > 1
								? ` os ${
										isDeleteAll
											? totalRegisters
											: selectedIds?.length
								  } itens selecionados?`
								: 'o item selecionado?'}
						</Text>
					</ModalBody>
					<ModalFooter>
						<Flex gap={1}>
							<ButtonComponent
								type='ghost'
								title='Cancelar'
								onClick={onClose}
							/>
							<ButtonComponent
								type='primary'
								title='Excluir'
								onClick={onDelete}
							/>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	}

	useEffect(() => {
		if (!allSelectedItens && isDeleteAll) setDeleteAll(false);
	}, [allSelectedItens, isDeleteAll]);

	return (
		<Flex
			direction='row'
			ml='20px'
			mr='20px'
		>
			{renderDeleteButton()}
			{renderDeleteAll()}
			{renderModal()}
		</Flex>
	);
};

export default CheckTableActions;
