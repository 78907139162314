import { Flex } from '@chakra-ui/react';
import FormCadastroRoteiro from 'components/forms/FormCadastroRoteiro';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';

export default function CadastroRoteiros() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpTipologiaParadas: SidebarHelp = {
		title: 'Roteiros',
		firstParagraph:
			'Nesta tela é possível cadastrar e visualizar roteiros de produção. O roteiro de produção do produto apresenta todas as operações necessárias para fabricação de um produto, os grupos de recurso planejados, seus recursos habilitados, as atividades e os tempos planejados de setup e processamento.',
		secondParagraph:
			'Para cadastrar um roteiro selecione o Produto, e clique em “Incluir Atividades” para adicionar as atividades que compõem o roteiro de produção do produto. Acesse “Cadastrar Atividades” para adicionar atividades. Uma vez listada as atividades, informe a quantidade de itens produzidos, o tempo de máquina e o tempo do colaborador operando a máquina naquela atividade. Observação: para atividades manuais, deixe o campo de tempo de máquina zerado.',
		thirdParagraph:
			'Caso for necessário criar um roteiro de produção para um novo produto que será muito similar a um roteiro de produção de outro produto já criado na easy360. Nesse caso, ao invés de criar todo o roteiro de produção do início, pode ser mais interessante acessar o roteiro de produção de um produto similar e utilizar a funcionalidade "Reutilizar roteiro".',
		firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
		secondLink: 'https://calendly.com/suporte-easy360',
	};

	useEffect(() => {
		setDataSidebar(helpTipologiaParadas);
	}, []);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastroRoteiro />
		</Flex>
	);
}
