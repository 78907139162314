import { Cycle } from "models/cycle.model";
import { Option } from "models/option.model";

export default function cycleYearOptions(cycles: Cycle[]): Option[] {
  const options = [] as Option[];
  const currentYear = new Date().getFullYear();

  let sorted = cycles.sort((a, b) => a.ano - b.ano);

  let i = 0; 
  while(i >= 0 && i < sorted.length) {
    const current = sorted[i];
    options.push({
      label: current.ano,
      value: current.ano
    });

    sorted = sorted.slice(i);
    const nextIndex = sorted.findIndex((value) => value.ano !== current.ano && value.ano <= currentYear);
    i = nextIndex;
  }

  return options;
}
