export type DocumentType = 'CPF' | 'CNPJ' | 'ESTRANGEIRO';

export const formatCnpj = (cnpj: string): string => {
	// Limpa caracteres não numéricos
	const numericCnpj = cnpj.replace(/\D/g, '');

	// Adiciona a máscara
	const formattedCnpj = numericCnpj.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
		'$1.$2.$3/$4-$5',
	);

	return formattedCnpj;
};

export const formatCpf = (cpf: string): string => {
	const numericCpf = cpf.replace(/\D/g, '');

	// Adiciona a máscara
	const formattedCpf = numericCpf.replace(
		/^(\d{3})(\d{3})(\d{3})(\d{2})$/,
		'$1.$2.$3-$4',
	);

	return formattedCpf;
};

export const documentFormatters = (
	documentType: DocumentType,
	document: string,
): string => {
	// Verifica se o valor é indefinido ou vazio
	if (!document) {
		return '';
	}

	// Mapa de formatação por tipo de documento
	const documentTypesMap = {
		CPF: formatCpf(document),
		CNPJ: formatCnpj(document),
		ESTRANGEIRO: document,
	};

	return documentTypesMap[documentType] || document;
};
