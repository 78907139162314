import { Icon } from '@chakra-ui/react';
import { IconAlarm, IconEdit, IconInfoCircle } from '@tabler/icons';

interface IIconManager {
	type?: string;
	boxSize?: number;
	color?: string;
}

const IconManager = ({ type, boxSize = 12, color }: IIconManager) => {
	switch (type) {
		case 'edit':
			return (
				<Icon
					as={IconEdit}
					boxSize={boxSize}
					color={color}
				/>
			);
		case 'discard':
			return (
				<Icon
					as={IconAlarm}
					boxSize={boxSize}
					color={color}
				/>
			);
		default:
			return (
				<Icon
					as={IconInfoCircle}
					boxSize={boxSize}
					color={color}
				/>
			);
	}
};

export default IconManager;
