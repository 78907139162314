import { useToast } from '@chakra-ui/react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFinalizar } from 'services/api/requests/colabAndConsensus/collaborationConsensus';
import { getKeysValuesSelectedFilterDemandFamily } from './filters/useGetFilterDemandFamily';
import { getKeysValuesSelectedFilters } from './filters/useGetSelectedFilter';
import { getKeysValuesStatusDemand } from './filters/useGetStatusDemand';

export const useCollaborationConsensuFinalize = (
	page: string,
	after?: Function,
) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation((body: any) => postFinalizar(page, body), {
		onSuccess: (sucess, variables) => {
			const { area_empresa_id, ciclo_id } = variables;

			queryClient.invalidateQueries(
				getKeysValuesStatusDemand({
					pageName: page,
					cycleId: ciclo_id,
					companyAreaId: area_empresa_id,
				}),
			);
			queryClient.invalidateQueries(
				getKeysValuesSelectedFilters({
					pageName: page,
					cycleId: ciclo_id,
					companyAreaId: area_empresa_id,
				}),
			);
			queryClient.invalidateQueries(
				getKeysValuesSelectedFilterDemandFamily({
					pageName: page,
					cycleId: ciclo_id,
				}),
			);
			toast({
				title: 'Finalização feita com sucesso',
				status: 'success',
				duration: 9000,
				isClosable: true,
			});
			if (after) {
				after();
			}
		},
		onError: (error: any, variables, context) => {
			toast({
				title: 'Erro ao finalizar, tente novamente',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		},
	});
};
