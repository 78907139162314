import { useQuery } from '@tanstack/react-query';
import { getActualStockById } from 'services/api/requests/stock';

export const useGetActualStockWithId = (id: Number) => {
	return useQuery(['actualStockWithId', id], getActualStockById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
