
export const columnsDataRateio = [
    {
        Header: "ID",
        accessor: "id",
        disableSortBy: true,
        type: "id",
    },
    {
        Header: "Ciclo",
        accessor: "ciclo",
        type: "text"
    },
    {
        Header: "Ano",
        accessor: "ano",
        type: "relation",
    },
    {
        Header: "Canal de Venda",
        accessor: "canalVenda",
        type: "relation",
        optional: true,
    },

];

