import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createRelated } from 'services/api/requests/requests';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useCreateRelated = (body, form, setData) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => createRelated(body), {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['clienteRelacionado-list']);
			toast({
				title: 'Criado com sucesso!',
				description: 'Cliente relacionamento criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});

			if (form) {
				const { setValue } = form;

				setValue('cliente_relacionado_id', success?.id);
			}

			if (setData)
				setData((prev) => ({
					...prev,
					relationship: success,
				}));
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao criar cliente relacionamento.',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
