import CollunEditOrColab from "../components/RowEditOrColab";
// ■ SelectColumnFilter
import { SelectColumnFilter } from "components/dataTables/FilterTypes.js/SelectColumnFilter";

export const columnsDataColaboracao = [
  {
    Header: "Ano",
    accessor: "ano",
    type: "text",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
    {
      Header: "Ciclo",
      accessor: "ciclo",
      disableSortBy: true,
      type: "text",
      Filter: SelectColumnFilter,
      filter: "equals",
    },
   
    {
      Header: "Colaborador",
      accessor: "colaborador",
      type: "relation",
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    {
      Header: "Status",
      accessor: "status",
      type: "badge",
      badgeTrueText: 'Completa',
      Filter: SelectColumnFilter,
      filter: "equals",
    },
    {
      Header: "",
      accessor: "button",
      type: "jsx",
      jsx: CollunEditOrColab,
      filter: false
    }
  ];
  
  export const columJannsDataCheck = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
    {
      Header: "QUANTITY",
      accessor: "quantity",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
  ];
  
  export const columnsDataColumns = [
    {
      Header: "PRODUTO",
      accessor: "produto",
    },
    {
      Header: "FAMÍLIA",
      accessor: "familia",
    },
    {
      Header: "SUBFAMÍLIA",
      accessor: "subfamilia",
    },
    {
      Header: "MODELO",
      accessor: "modelo",
    },
    {
      Header: "COD.PRODUTO",
      accessor: "codproduto",
    },
    {
      Header: " ",
      accessor: "button",
    },
  ];
  
  export const columnsDataComplex = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "PROGRESS",
      accessor: "progress",
    },
  ];
  