import React from 'react';
import './styles.css';
import {
	Flex,
	Tr,
	Td,
	Button,
	Icon,
	Text,
	Tooltip,
	Checkbox,
} from '@chakra-ui/react';
import { IconTrash, IconEdit } from '@tabler/icons';
import { TabelaParada } from './TabelaParada';
import moment from 'moment';
import { Time } from 'models/time-form.model';

export type OpenDeleteModal = (type: any, id: number) => void;

interface Props {
	rowIndex: number;
	lancamento: Time;
	checkboxRows: any;
	setSelectedCheckboxRows: any;
	openDeleteModal: OpenDeleteModal;
	editTimeForm: (lancamento: any) => void;
}

export const ItemLancamento: React.FC<Props> = (props) => {
	const {
		rowIndex,
		lancamento,
		checkboxRows,
		setSelectedCheckboxRows,
		openDeleteModal,
		editTimeForm,
	} = props;

	const dateTimeIsoPattern = 'YYYY-MM-DDTHH:mm:ss.ZZZ';

	const openItem = (id) => {
		document.getElementById(id)?.classList.toggle('open');
		document.getElementById(`${id}-fold`)?.classList.toggle('open');
	};

	const product = `${lancamento?.produto.codigo_produto} - ${lancamento?.produto.nome_produto}`;

	function isChecked() {
		return checkboxRows[rowIndex]?.checked;
	}

	function handleOnCheck(e) {
		const newCheckedItems = [...checkboxRows];
		newCheckedItems[rowIndex].checked = e.target.checked;
		setSelectedCheckboxRows(newCheckedItems);
	}

	return (
		<React.Fragment key={lancamento?.id}>
			<Tr
				className='view'
				id={lancamento?.id?.toString()}
				color='easyBLUE.300'
			>
				<Td colSpan={2}>
					<Checkbox
						key={lancamento?.id}
						isChecked={isChecked()}
						onChange={handleOnCheck}
					/>
				</Td>
				<Td
					colSpan={2}
					onClick={() => openItem(lancamento?.id)}
				>
					{lancamento?.recurso.descricao}
				</Td>
				<Td>{lancamento?.ordem_producao || '-'}</Td>
				<Td>
					{moment(lancamento?.data, 'YYYY-MM-DD').format(
						'DD/MM/YYYY',
					)}
				</Td>
				<Td>{lancamento?.turnos?.turno}</Td>
				<Td>
					{moment(lancamento?.hora_inicio, dateTimeIsoPattern).format(
						'HH:mm',
					)}
				</Td>
				<Td>
					{moment(lancamento?.hora_fim, dateTimeIsoPattern).format(
						'HH:mm',
					)}
				</Td>
				<Td>
					{moment()
						.startOf('day')
						.add(lancamento.tempo_de_producao, 'seconds')
						.format('HH:mm')}
				</Td>
				<Td>
					<Tooltip
						label={product}
						backgroundColor='secondaryGray.900'
						borderRadius={4}
					>
						<Text className='product-info'>{product}</Text>
					</Tooltip>
				</Td>
				<Td>{lancamento?.pecas_boas?.toLocaleString('pt-BR')}</Td>
				<Td>{lancamento?.refugo?.toLocaleString('pt-BR')}</Td>
				<Td>{lancamento?.atividadeRoteiro?.descricao}</Td>
				<Td textAlign='right'>
					<Button
						size='sm'
						backgroundColor={
							lancamento?.paradaHoraHora?.length
								? 'green.100'
								: 'gray.200'
						}
						color={
							lancamento?.paradaHoraHora?.length
								? 'green.500'
								: 'gray.500'
						}
						onClick={() => openItem(lancamento?.id)}
					>
						{lancamento?.paradaHoraHora?.length
							? 'Ver Parada'
							: 'Sem Parada'}
					</Button>
				</Td>
				<Td
					textAlign='right'
					columnGap={2}
				>
					<Flex columnGap={2}>
						<Icon
							data-test={'link-editar'}
							as={IconEdit}
							color={'secondaryGray.500'}
							h={'20px'}
							w={'20px'}
							cursor='pointer'
							onClick={() => editTimeForm(lancamento)}
						/>

						<Icon
							data-test={'link-excluir'}
							as={IconTrash}
							color={'secondaryGray.500'}
							h={'20px'}
							w={'20px'}
							cursor='pointer'
							onClick={() =>
								openDeleteModal('time', lancamento?.id)
							}
						/>
					</Flex>
				</Td>
			</Tr>

			<Tr
				className='fold'
				id={`${lancamento?.id}-fold`}
			>
				<Td
					colSpan={16}
					padding='0'
					backgroundColor='secondaryGray.300'
				>
					{lancamento?.paradaHoraHora?.length ? (
						lancamento?.paradaHoraHora?.map((parada) => (
							<TabelaParada
								key={parada.id}
								parada={parada}
								openDeleteModal={openDeleteModal}
							/>
						))
					) : (
						<Text
							padding={4}
							color='easyBLUE.300'
						>
							{/* incluído provisóriamente o complemento: "não programadas" */}
							Sem registro de paradas não programadas.
						</Text>
					)}
				</Td>
			</Tr>
		</React.Fragment>
	);
};
