import {
	ButtonGroup,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
} from '@chakra-ui/react';
import { IconTrash } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useDeleteModal } from 'services/queryClient/wrapperHooks/useDeleteModal';
import {
	renderDeleteMessage,
	renderDeleteMessageForSalesChannel,
} from '../../utils/functions/modalFunctions';

export const DeleteModal = (props) => {
	const {
		isOpen,
		onClose,
		dataDelete,
		idDelete,
		queriesToInvalidadeOnRemove,
		resetQuery,
	} = props;

	const {
		title,
		text,
		provider,
		toastSuccessDescription,
		toastSuccessTitle,
		toastErrorTitle,
		invalidate,
	} = dataDelete;

	const { mutate } = useDeleteModal(
		provider,
		onClose,
		toastSuccessDescription,
		toastSuccessTitle,
		toastErrorTitle,
		idDelete,
		invalidate,
		queriesToInvalidadeOnRemove,
		resetQuery,
	);

	const deleteItem = (event) => {
		event.preventDefault();
		mutate();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size='xl'
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalCloseButton />
				<ModalBody>
					<Icon
						as={IconTrash}
						color='easyRED.300'
						w={14}
						h={14}
					></Icon>
					<Heading
						color='gray.700'
						mb={2}
						size='md'
					>
						{title}
					</Heading>
					{provider === 'salesChannel'
						? renderDeleteMessageForSalesChannel(text)
						: renderDeleteMessage(text)}
				</ModalBody>
				<ModalFooter>
					<ButtonGroup>
						<ButtonComponent
							data-test={'button-cancelar-modal_exclusao'}
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
						<ButtonComponent
							data-test={'button-excluir-modal_exclusao'}
							type={'secondary'}
							title={'Excluir'}
							onClick={deleteItem}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
