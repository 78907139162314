// chakra imports
import { Box, Button, Flex, HStack, useColorModeValue } from '@chakra-ui/react';

import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
	renderThumb,
	renderTrack,
	renderView,
} from '../../scrollbar/Scrollbar';

// Assers
import { IconHelp } from '@tabler/icons';

// Custom components

// import Content from "./components/Content";

import { ContentSidebarHelp } from './components/ContentSidebarHelp';

// FUNCTIONS
//** Menu principal da página
export default function SidebarHelp(props) {
	const sidebarHelpState = 'opened';

	const onClose = props['onClose'];

	// Chakra Color Mode
	let sidebarBgColor = useColorModeValue('easyBLUE.100', 'navy.800');
	let sidebarRadius = '30px';
	let sidebarMargins = '0px';

	// this is for the rest of the collapses
	let variantChange = '0.2s linear';
	let shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
		'unset',
	);

	//**
	//FUNCTION: Sidebar Change Size

	// SIDEBAR
	// Componente: MENU
	return (
		// <Box display={{ sm: "none", xl: "block" }} position='fixed' minH='100%' >
		<Box
			position={'fixed'}
			//Há necessidade de corrigir o posicionamento do componente SidebarHelp na página:
			//verificar a melhor forma de posicionar o elemento a direita
		>
			<Box
				// display={'none'}
				bg={sidebarBgColor}
				transition={variantChange}
				position={'absolute'}
				right='-304px'
				//Propriedade: WIDTH, altera o tamanho do MENU
				//Vericiar se a largura do Menu está correta
				// w= "285px"
				// w={toggleSidebar === false ? "290px" : "76px" }
				w={sidebarHelpState === 'opened' ? '290px' : '10px'}
				ms={{ sm: '200px' }}
				// my={toggleSidebar === false ? {sm: "16px",} : {sm:"500px"}}
				my={{ sm: '16px' }}
				h='calc(100vh - 32px)'
				m={sidebarMargins}
				borderRadius={sidebarRadius}
				minH='100%'
				overflowX='hidden'
				boxShadow={shadow}
			>
				{/** Componente de rolagem do menu */}
				<Scrollbars
					// autoHide
					renderTrackVertical={renderTrack}
					renderThumbVertical={renderThumb}
					renderView={renderView}
				>
					{/** Brand */}
					<Flex
						align='center'
						justifyContent='space-between'
						w='100%'
					>
						<HStack
							mb='auto'
							ml='10px'
							my='22px'
							display={'flex'}
							flexDirection={'column'}
							alignItems={'flex-start'}
							p='10px 10px'
						>
							{/* COMPONENTE DO MENU: botão */}
							{/******* COMPONENTE DO MENU: logo Easy360 ***/}
							<IconHelp
								width={48}
								height={48}
								color='#F0243E'
							/>
							{/* {children} */}

							<ContentSidebarHelp />

							<Button
								variant={'outline'}
								color={'#FFF'}
								backgroundColor={'#F0243E'}
								fontWeight={500}
								borderRadius={'20px'}
								px={8}
								_hover={'none'}
								onClick={onClose}
							>
								Entendi!
							</Button>
						</HStack>
					</Flex>
				</Scrollbars>
			</Box>
		</Box>
	);
}

// PROPS
SidebarHelp.propTypes = {
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	variant: PropTypes.string,
};
