import { useQuery } from '@tanstack/react-query';
import { getStopTypologyById } from 'services/api/requests/production';

export const useGetStopTypologyById = (id: Number) => {
	return useQuery(['typologyById', id], getStopTypologyById, {
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
	});
};
