import api from '../api';

export const getSalesZoneById = async ({ queryKey }) => {
	const [, id] = queryKey;

	const response = await api.get('zonaVenda/zonaVendaById', {
		params: { id },
	});
	return response.data;
};

export const updateSalesZone = async (id: number, body) => {
	const response = await api.patch('zonaVenda', body, {
		params: { id },
	});
	return response.data;
};

export const deleteSalesZoneByFilters = async (filters: any) => {
	const response = await api.post('zonaVenda/deletarPorFiltros', filters);
	return response.data;
};

export const deleteSalesZone = async (id: number) => {
	const response = await api.delete('zonaVenda', { params: { id } });
	return response.data;
};
