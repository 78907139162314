import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMarket } from 'services/api/requests/requests';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useCreateMarket = (formProps) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(createMarket, {
		onSuccess: (success) => {
			queryClient.invalidateQueries(['commercialHierarchy-list']);
			toast({
				title: 'Criado com sucesso!',
				description: 'Mercado criado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
			if (formProps) {
				const { setValue } = formProps;
				setValue('mercado', success?.id);
			}
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Mercado!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
