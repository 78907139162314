import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { deleteExeption } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useDeleteCollaborationConsensusExeptionsByFamily = () => {
	const toast = useToast();

	return useMutation(deleteExeption, {
		onError: (error: any) => {
			console.log(error.response.data.message);
			toast({
				title: 'Erro ao deletar',
				description: error.response.data.message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
