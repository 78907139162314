export const modalHeaderStyles = {
	filter: 'none',
	backdropFilter: 'blur(20px)',
	borderColor: 'transparent',
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	borderRadius: '16px',
	borderWidth: '1.5px',
	borderStyle: 'solid',
	transitionDelay: '0s, 0s, 0s, 0s',
	transitionDuration: ' 0.25s, 0.25s, 0.25s, 0s',
	transitionProperty: 'box-shadow, background-color, filter, border',
	transitionTimingFunction: 'linear, linear, linear, linear',
	alignItems: { xl: 'center' },
	minH: '75px',
	justifyContent: { xl: 'center' },
	lineHeight: '25.6px',
};
