import {
	Box,
	ButtonGroup,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import IconManager from '../icons/IconManager';

interface AtaModalProps {
	isOpen: boolean;
	onClose: () => void;
	onClosePreviousModal: () => void;
	resetDefaultValues?: () => void;
}

const DiscardChangesModal = (props: AtaModalProps) => {
	const {
		isOpen,
		onClose,
		onClosePreviousModal,
		resetDefaultValues = () => {},
	} = props;

	function discardChanges() {
		resetDefaultValues();

		setTimeout(() => {
			onClose();
			onClosePreviousModal();
		}, 200);
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
		>
			<ModalOverlay />
			<ModalContent borderRadius={20}>
				<ModalHeader
					display='flex'
					flexDir='column'
				>
					<IconManager
						type='discard'
						color='easyRED.300'
					/>
					<Flex
						justifyContent='space-between'
						alignItems='center'
					>
						<Text
							fontSize='2xl'
							fontWeight={700}
							color='easyBLUE.300'
						>
							Alterações não salvas
						</Text>
					</Flex>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text color='easyBLUE.300'>
						Existem alterações que não foram salvas. Deseja
						continuar?
					</Text>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup spacing='20px'>
						<ButtonComponent
							type='ghost'
							title='Cancelar'
							onClick={onClose}
						/>
						<ButtonComponent
							type='secondary'
							title='Descartar'
							onClick={discardChanges}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DiscardChangesModal;
