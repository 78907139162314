export const getLineChartOptionsSalesCadence = (categories: string[]) => {
	const lineChartOptionsSalesCadence = {
		chart: {
			toolbar: {
				show: true,
			},
			dropShadow: {
				enabled: true,
				top: 13,
				left: 0,
				blur: 10,
				opacity: 0.1,
				color: '#4318FF',
			},
		},
		colors: ['#01B574', '#B01A2E', '#567EB3', '#FDAB31'],
		markers: {
			size: 0,
			colors: 'white',
			strokeColors: ['#01B574', '#B01A2E', '#567EB3', '#FDAB31'],
			strokeWidth: 3,
			strokeOpacity: 0.9,
			strokeDashArray: 0,
			fillOpacity: 1,
			discrete: [],
			shape: 'circle',
			radius: 2,
			offsetX: 0,
			offsetY: 0,
			showNullDataPoints: true,
		},
		tooltip: {
			theme: 'dark',
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: 'smooth',
			// type: "line",
			dashArray: [null, 8, null, null],
			width: 3,
		},
		xaxis: {
			type: 'numeric',
			categories,
			labels: {
				style: {
					colors: '#A3AED0',
					fontSize: '12px',
					fontWeight: '500',
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			show: true,
			// forceNiceScale: false,
			// max: 100,
			labels: {
				style: {
					colors: '#A3AED0',
					fontSize: '12px',
					fontWeight: '500',
				},
				formatter: (value) => {
					const decimalPart = value.toString().split('.')[1];

					if (decimalPart && decimalPart.length > 3) {
						return value.toFixed(0) + '%';
					} else {
						return value.toString().replace('.', ',') + '%';
					}
				},
			},
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
		},
		grid: {
			show: true,
			// borderColor: '#90A4AE',
			strokeDashArray: 1,
			position: 'back',
		},
		legend: {
			show: true,
			fontSize: '12px',
			fontFamily: undefined,
			fontWeight: 400,
			labels: {
				colors: '#A3AED0',
			},
			markers: {
				width: [12, 15, 12, 12],
				height: [12, 4, 12, 12],
			},
			itemMargin: {
				horizontal: 15,
				vertical: 10,
			},
			offsetY: 10,
		},
	};
	return lineChartOptionsSalesCadence;
};
export const speedometerChartData = ['50'];

const generateColors = (data) => {
	if (data <= 25) {
		return [
			{
				offset: 25,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#CC2B01',
				opacity: 1,
			},
		];
	} else if (data > 25 && data <= 50) {
		return [
			{
				offset: 25,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 75,
				color: '#CC9702',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#CC9702',
				opacity: 1,
			},
		];
	} else if (data > 50 && data <= 75) {
		return [
			{
				offset: 10,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 60,
				color: '#CC9702',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#cccc02',
				opacity: 1,
			},
		];
	} else if (data > 75) {
		return [
			{
				offset: 10,
				color: '#CC2B01',
				opacity: 1,
			},
			{
				offset: 30,
				color: '#CC9702',
				opacity: 1,
			},
			{
				offset: 50,
				color: '#cccc02',
				opacity: 1,
			},
			{
				offset: 100,
				color: '#A9C701',
				opacity: 1,
			},
		];
	}
};

export const speedometerChartOptions = (
	data: number | null,
	percentToMeta: number,
	week: string,
	isLoading: boolean,
) => ({
	chart: {
		toolbar: {
			show: true,
		},
	},
	plotOptions: {
		radialBar: {
			startAngle: -90,
			endAngle: 90,
			hollow: {
				margin: 0,
				size: '70%',
				background: '#fff',
				image: undefined,
				imageOffsetX: 0,
				imageOffsetY: 0,
				position: 'front',
				dropShadow: {
					enabled: true,
					top: 3,
					left: 0,
					blur: 4,
					opacity: 0.24,
				},
			},
			track: {
				show: true,
				startAngle: -90,
				endAngle: 270,
				strokeWidth: '200%',
				// opacity: 0.6,
				margin: 10, // margin is in pixels
				dropShadow: {
					enabled: true,
					top: -3,
					left: 0,
					blur: 4,
					opacity: 0.35,
				},
			},

			dataLabels: {
				show: true,
				name: {
					// offsetY: 40,
					show: true,
					color: '#314866',
					fontSize: '25px',
					fontWeight: '700',
					fontFamily: 'DM Sans',
				},
				value: {
					formatter: function (val) {
						let truncatedNumber;

						if (data !== null) {
							truncatedNumber = data
								? Math.trunc(Number(data))
								: data;
						} else {
							truncatedNumber = val
								? Math.trunc(Number(val))
								: val;
						}

						if (percentToMeta === 0 && !week) {
							return ['Selecione os filtros'];
						} else if (percentToMeta === 0.5) {
							return [
								'Faturado + Boa',
								truncatedNumber?.toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL',
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
								}),
							];
						} else {
							return truncatedNumber?.toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							});
						}
					},
					color: '#314866',
					fontSize: '20px',
					show: true,
					fontWeight: '700',
					fontFamily: 'DM Sans',
					// offsetY: -10,
				},
			},
		},
	},
	fill: {
		type: 'gradient',
		gradient: {
			shade: 'dark',
			type: 'horizontal',
			shadeIntensity: 0.5,
			colorStops: generateColors(percentToMeta),
			inverseColors: false,
			opacityFrom: 1,
			opacityTo: 1,
		},
	},
	stroke: {
		lineCap: 'round',
	},
	labels: isLoading
		? ['Carregando...']
		: percentToMeta === 0.5
		? ['Sem Meta']
		: ['Faturado + Boa'],
	grid: {
		padding: {
			bottom: 40,
		},
	},
	noData: {
		text: 'Carregando',
		align: 'center',
		verticalAlign: 'middle',
		offsetX: 0,
		offsetY: 0,
		style: {
			color: '#314866',
			fontSize: '25px',
			fontFamily: 'DM Sans',
		},
	},
});
