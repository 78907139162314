import {
	CircularProgress,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';

import { useQuery } from '@tanstack/react-query';
import { getDesiredStock } from 'services/api/requests/stock';

import { columnsDataModalEstoque } from '../estoqueDesejado/variables/comunsData';

import CheckTable from 'components/dataTables/CheckTable';

import moment from 'moment';

export const ModalEstoqueDesejado = (props) => {
	const { isOpenModal, onCloseModal, ciclo, ano } = props;

	const { data, isLoading, isError } = useQuery(
		['estoque-desejado-list', ciclo, ano],
		getDesiredStock,
		{
			refetchOnWindowFocus: false,
		},
	);

	const estoque = data?.map((resource) => {
		return {
			id: resource.id,
			familia: resource?.nome_familia,
			ciclo: resource.ciclo,
			data: moment(resource.updatedAt).format('DD/MM/YYYY'),
			quantidade: Number(resource.quantidade)?.toLocaleString('pt-BR'),
		};
	});

	const dataDelete = {
		provider: 'desiredStock',
		toastSuccessTitle: 'Excluido com sucesso!',
		toastSuccessDescription: 'Estoque desejado excluido com sucesso.',
		toastErrorTitle: 'Erro ao excluir estoque desejado!',
		title: 'Deseja realmente excluir este Estoque?',
		text: [
			'Você está prestes a excluir este estoque da sua base de cadastros.',
			'',
			'Você tem certeza que deseja excluir?',
		],
		invalidate: 'estoque-desejado-list',
	};

	return (
		<Modal
			isOpen={isOpenModal}
			onClose={onCloseModal}
			size={'6xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalCloseButton />
				<ModalBody>
					<Heading
						color='gray.700'
						mb={6}
						size='md'
					>
						Estoque Desejado
					</Heading>
					<Flex
						direction='column'
						pt={6}
						pl={'7px'}
					>
						{isLoading && (
							<CircularProgress
								isIndeterminate
								color='red.600'
							/>
						)}
						{isError && <h1>Ocorreu um erro, tente novamente!</h1>}
						{data && (
							<CheckTable
								isSearch={false}
								columnsData={columnsDataModalEstoque}
								textHelper='Índice de Rendimento Operacional Global dos equipamentos'
								dataDelete={dataDelete}
								tableData={estoque}
								renderedInModal={true}
							/>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
