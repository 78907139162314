import { Flex } from '@chakra-ui/react';
import TemplateCadastrarEditarUsuarios from 'components/forms/TemplateCadastrarEditarUsuarios';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';

export default function CadastrarUsuarios() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const [selectedUserTypes, setSelectedUserTypes] = useState([]);

	const helpCadastroUsuarios: SidebarHelp = useMemo(
		() => ({
			title: 'Cadastrar Usuário',
			firstParagraph:
				'Para cadastrar um novo Usuário, primeiramente entre com as informações básicas dele como Nome, Telefone Comercial, CEP e uma senha temporária. Observação: uma vez cadastrado um usuário com seu respectivo e-mail, ele não poderá utilizar o mesmo e-mail para acessar outra conta da easy360.',
			secondParagraph:
				'Em seguida, informe o perfil do usuário que está sendo cadastrado, sendo permitido escolher entre Comercial, Marketing, Planejamento e Produção. Caso deseje que algum usuário possua mais de um perfil, selecione mais de um perfil. Observação: o perfil do usuário gerencia as telas e funcionalidades que cada usuário possui permissão de visualizar/editar.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroUsuarios);
	}, [helpCadastroUsuarios, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<TemplateCadastrarEditarUsuarios
				selectedUserTypes={selectedUserTypes}
				setSelectedUserTypes={setSelectedUserTypes}
			/>
		</Flex>
	);
}
