import { createContext, useContext, useState } from 'react';
import { ProductException } from '../consenso/context';
import { QuantityValues } from '../templateCadastrarColabCons/components/SectionExceptions';

const ContextColaboracao = createContext<IContext>({} as IContext);
interface IContext {
	colaboracoes: QuantityValues;
	exceptions: { [key: string]: QuantityValues };
	setColaboracoes: React.Dispatch<React.SetStateAction<QuantityValues>>;
	setExceptions: React.Dispatch<
		React.SetStateAction<{ [key: string]: QuantityValues }>
	>;
	productExceptions: Record<string, ProductException>;
	setProductExceptions: React.Dispatch<
		React.SetStateAction<Record<string, ProductException>>
	>;
}

export default function ProviderColaboracao({ children }) {
	const [colaboracoes, setColaboracoes] = useState<QuantityValues>({});

	const [exceptions, setExceptions] = useState<{
		[key: string]: QuantityValues;
	}>({});
	//Valores do remote servem para gurdar os valores do banco de maneira estatica para agizilar o cancelar

	const [productExceptions, setProductExceptions] = useState<
		Record<string, ProductException>
	>({});

	return (
		<ContextColaboracao.Provider
			value={{
				colaboracoes,
				setColaboracoes,
				exceptions,
				setExceptions,
				productExceptions,
				setProductExceptions,
			}}
		>
			{children}
		</ContextColaboracao.Provider>
	);
}

export const useContextColaboracao = () => {
	return useContext(ContextColaboracao);
};
