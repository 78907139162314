export const columnsDataEstoqueFamilia = [
	{
		Header: 'Código',
		accessor: 'codigo',
		disableSortBy: true,
		filter: false,
		type: 'text',
	},
	{
		Header: 'Nome',
		accessor: 'nome',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		filter: false,
		type: 'text',
	},
];

export const columnsDataEstoqueProduto = [
	{
		Header: 'Nome do Produto',
		accessor: 'nome_produto',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Quantidade',
		accessor: 'quantidade',
		filter: false,
		type: 'text',
	},
	{
		Header: 'Data',
		accessor: 'data',
		filter: false,
		type: 'text',
	},
];
