import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import {
	defaultFormStyle,
	defaultInputStyle,
	formFlexProps,
	rowFlexProps,
} from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from 'components/forms/GenericForm';
import { ChangeEvent, useCallback, useState } from 'react';
import { useCreateMember } from 'services/queryClient/wrapperHooks/useCreateMember';
import { makeGenericFormInputStructure } from 'utils/forms/defaultInputs';
import { validateRequired } from 'utils/forms/validations';

interface CreateMemberModalProps {
	isOpenModalCreate: boolean;
	onCloseModalCreate: () => void;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	form: any;
	dadosMembro: { nome: string; email: string };
}

interface BodyMember {
	ativo: boolean;
	email: string;
	nome_membro: string;
}

export const CreateMemberModal = (props: CreateMemberModalProps) => {
	const { isOpenModalCreate, onCloseModalCreate, dadosMembro } = props;

	const [payload, setPayload] = useState<BodyMember>({
		ativo: true,
		email: dadosMembro?.email,
		nome_membro: dadosMembro?.nome,
	});

	const { mutateAsync: createMember, isLoading: isLoadingCreateMember } =
		useCreateMember(payload, true);

	const onSubmitCreateMember = (values: any) => {
		setPayload({
			ativo: true,
			email: values.email,
			nome_membro: values.nome,
		});
		setTimeout(() => {
			createMember();
			onCloseModalCreate();
		}, 150);
	};

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'text',
							name: 'nome',
							label: 'Nome Completo',
							showRequiredOnLabel: true,
							validate: {
								...validateRequired(),
							},
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: João da Silva',
							},
						}),
					},
				],
			},
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'email',
							name: 'email',
							label: 'E-mail',
							showRequiredOnLabel: true,
							inputProps: {
								...defaultInputStyle,
								placeholder: 'ex.: joaodasilva@easy360.ind.br',
							},
							disabled: () => true,
						}),
					},
				],
			},
		];
	}, []);

	return (
		<Modal
			isOpen={isOpenModalCreate}
			onClose={onCloseModalCreate}
			onEsc={onCloseModalCreate}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader color={'easyBLUE.300'}>
					Cadastrar Membro
					<Text
						color='gray.500'
						fontSize='sm'
						fontWeight={500}
					>
						Realize o cadastro de membro para associá-lo a este
						usuário.
					</Text>
					<Text
						color='gray.500'
						fontSize='sm'
						fontWeight={200}
						mt={1}
					>
						Observação: O campo de e-mail não pode ser modificado,
						pois deve corresponder ao e-mail do usuário para
						permitir a associação entre eles.
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<GenericForm
						initialState={dadosMembro}
						formFlexProps={formFlexProps}
						rows={renderRows()}
						style={defaultFormStyle}
						onSubmit={onSubmitCreateMember}
						onCancel={onCloseModalCreate}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
