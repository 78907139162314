import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateApportionmentByFamilyAndMonth } from 'services/api/requests/apportionment';
import { mapErrorToToastProps } from 'utils/forms/mapErrorToToastProps';

export const useUpdateApportionmentByFamilyAndMonth = () => {
	const toast = useToast();
	const queryClient = useQueryClient();

	return useMutation(updateApportionmentByFamilyAndMonth, {
		onSuccess: () => {
			queryClient.invalidateQueries({
				predicate: (query: any) => {
					return query?.queryKey?.[0]?.startsWith(
						'apportionment-family',
					);
				},
			});
			toast({
				title: 'Rateio salvo com sucesso',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao salvar rateio',
				'Erro ao salvar rateio, tente novamente',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
