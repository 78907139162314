import { Cycle } from "models/cycle.model";

export default function cycleByYear(selectedYear: number, cycles: Cycle[]): Cycle[] {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  let sorted = cycles.filter((value) => value.ano === selectedYear).sort((a, b) => a.ciclo - b.ciclo);
  const uniqCycle = [] as Cycle[];

  let i = 0; 
  while (i >= 0 && i < sorted.length) {
    const current = sorted[i];
    uniqCycle.push(current);
    sorted = sorted.slice(i);

    const nextIndex = sorted.findIndex((value) => value.ciclo !== current.ciclo);
    i = nextIndex;
  }
  if (selectedYear !== currentYear) return uniqCycle;
  else return uniqCycle.filter((cycle) => cycle.ciclo <= currentMonth);
}