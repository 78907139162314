import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';
import { SelectColumnFilter } from 'components/dataTables/FilterTypes.js/SelectColumnFilter';

export const columnsDataMembros = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Membro',
		accessor: 'nomeMembro',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Função',
		accessor: 'funcao',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Email',
		accessor: 'email',
		type: 'text',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Área da Empresa',
		accessor: 'areaEmpresa',
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Ativo',
		accessor: 'ativo',
		type: 'badge',
		badgeTrueText: 'Ativo',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
		module: 'membros',
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const columnsDataSalesChannel = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'NOME',
		accessor: 'nome',
		type: 'text',
		body: 'nome_canal_venda',
	},
];

export const columnsDataSalesForce = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'NOME',
		accessor: 'nome',
		type: 'text',
		body: 'nome_forca_venda',
	},
	{
		Header: 'CANAL DE VENDA',
		accessor: 'canalVenda',
		type: 'relation',
		body: 'canal_venda_id',
	},
];

export const columnsDataSalesZone = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'ZONA DE VENDA',
		accessor: 'nome',
		type: 'text',
		body: 'nome_zona_venda',
	},
	{
		Header: 'CANAL DE VENDA',
		accessor: 'canalVenda',
		type: 'relation',
		body: 'canal_venda_id',
	},
	{
		Header: 'FORÇA DE VENDA',
		accessor: 'forcaVenda',
		type: 'relation',
		body: 'forca_venda_id',
	},
];

export const columnsDataCompanyArea = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'AREA EMPRESA',
		accessor: 'nome',
		type: 'text',
		body: 'nome_area_empresa',
	},
];
