import { useState, useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';
interface LineChartProps {
	chartData: any;
	chartOptions: any;
	width?: number | string;
	height?: number | string;
}
interface chartData {
	name: string;
	data: (number | null)[];
}

const LineChart = ({
	width = '100%',
	height = '300px',
	...props
}: LineChartProps) => {
	const [options, setOptions] = useState({});
	const [series, setSeries] = useState([]);

	/**
	 * Para evitar possíveis problemas com a renderização do gráfico,
	 * é necessário utilizar o useEffect abaixo e atribuir os valores dos
	 * states acima.
	 */
	useEffect(() => {
		setOptions(props?.chartOptions);
		setSeries(props?.chartData);
	}, [props?.chartData, props?.chartOptions]);

	return (
		<ReactApexChart
			options={options}
			series={series}
			type='line'
			width={width}
			height={height}
		/>
	);
};

export default LineChart;
