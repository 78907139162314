import {
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Tooltip,
	useToast,
} from '@chakra-ui/react';
import { IconDownload } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useExportCollaborationConsensus } from 'services/queryClient/wrapperHooks/colabAndConsensus/useExportCollaborationConsensus';
import { useExportRestrictedPlan } from 'services/queryClient/wrapperHooks/restrictedPlan/useExportRestrictedPlan';
import { useContextColaboracaoConsenso } from '../context';
import { useExistsRestrictedPlan } from 'services/queryClient/wrapperHooks/restrictedPlan/useExistsRestrictedPlan';

export default function ButtonExportCSV() {
	const [dataCSV, setDataCSV] = useState([]);
	const [planRestrictedKey, setPlanRestrictedKey] = useState<boolean>(false);
	const [hasRestrictedPlan, setHasRestrictedPlan] = useState<number>(0);

	const csvInstance = useRef<any | null>(null);
	const {
		keysPage,
		cycleId = '',
		companyAreaId,
		cycle: cycleData,
	} = useContextColaboracaoConsenso();
	const { ano: year, ciclo: cycle } = cycleData;
	const { pathCompanyName } = useGlobalContext();

	const toast = useToast();

	const { data: colaboracaoCSV, refetch } = useExportCollaborationConsensus(
		keysPage.page,
		cycleId,
		companyAreaId || '',
	);

	const { data: restrictedPlanCSV, refetch: refetchRestrictedPlan } =
		useExportRestrictedPlan(cycleId);

	const { data: existsRestrictedPlanData } = useExistsRestrictedPlan(
		Number(cycleId),
	);

	const getDataCsv = () => {
		return dataCSV;
	};
	useEffect(() => {
		if (planRestrictedKey && restrictedPlanCSV) {
			setDataCSV(restrictedPlanCSV);
		}
		if (colaboracaoCSV) {
			setDataCSV(colaboracaoCSV);
		}
	}, [colaboracaoCSV, restrictedPlanCSV, planRestrictedKey]);

	const CSVFileName = (): string => {
		const fileNameDefault = `easy360_${pathCompanyName}`;
		let fileNameSuffix = '';
		switch (keysPage.page) {
			case 'colaboracao':
				fileNameSuffix = 'colaboracao';
				break;
			case 'consenso':
				fileNameSuffix = 'planoIrrestrito_consenso';
				break;
			default:
				fileNameSuffix = '';
				break;
		}

		if (planRestrictedKey) {
			fileNameSuffix = 'planoRestrito_consenso';
		}

		return `${fileNameDefault}_${fileNameSuffix}_${cycle}_${year}.csv`;
	};

	return (
		<>
			{keysPage.page === 'consenso' ? (
				<Menu>
					<MenuButton
						data-test='button-exportar'
						as={IconButton}
						icon={<IconDownload />}
						variant='solid'
						color='easyBLUE.300'
						borderRadius={'15px'}
						border={'1px solid'}
						borderColor={'easyBrandMenu.200'}
					></MenuButton>
					<MenuList>
						<MenuItem
							data-test='button-exportar-plano_irrestrito'
							onClick={() => {
								// simular click no botão de download
								if (
									csvInstance.current &&
									csvInstance.current.link
								) {
									refetch().then((data) => {
										if (data['data'].length === 0) {
											toast({
												title: 'Erro!',
												description:
													'Por favor, realize o consenso de pelo menos uma família de produtos para liberar a exportação do plano irrestrito!',
												status: 'error',
												duration: 8000,
												isClosable: true,
											});
										} else {
											setDataCSV(data['data']);
											setPlanRestrictedKey(false);
											csvInstance.current.link.click();
										}
									});
								}
							}}
						>
							Exportar Plano Irrestrito (saída do consenso) para
							CSV
						</MenuItem>
						<MenuItem
							data-test='button-exportar-plano_restrito'
							isDisabled={
								!existsRestrictedPlanData?.existsRestrictedPlan
							}
							onClick={() => {
								if (
									csvInstance.current &&
									csvInstance.current.link
								) {
									refetchRestrictedPlan().then((data) => {
										if (data['data'].length === 0) {
											toast({
												title: 'Erro!',
												description:
													'Por favor, realize o consenso de pelo menos uma família de produtos para liberar a exportação do plano irrestrito!',
												status: 'error',
												duration: 8000,
												isClosable: true,
											});
										} else {
											setDataCSV(data['data']);
											setPlanRestrictedKey(true);
											csvInstance.current.link.click();
										}
									});
								}
							}}
						>
							Exportar Plano Restrito para CSV
						</MenuItem>
					</MenuList>
				</Menu>
			) : (
				<ButtonComponent
					iconType={<IconDownload />}
					type='icon'
					onClick={() => {
						// simular click no botão de download
						if (csvInstance.current && csvInstance.current.link) {
							refetch().then((data) => {
								if (data['data'].length === 0) {
									toast({
										title: 'Erro!',
										description:
											'Por favor, realize o consenso de pelo menos uma família de produtos para liberar a exportação do plano irrestrito!',
										status: 'error',
										duration: 8000,
										isClosable: true,
									});
								} else {
									setDataCSV(data['data']);
									csvInstance.current.link.click();
								}
							});
						}
					}}
				/>
			)}

			<CSVLink
				data={getDataCsv()}
				style={{ display: 'none' }}
				ref={csvInstance}
				filename={CSVFileName()}
			>
				{keysPage.page === 'consenso' ? (
					<Tooltip
						label='Exportação do plano irrestrito considerando apenas as famílias de produto que participam do rateio.'
						placement='top'
					>
						<span>
							<ButtonComponent
								iconType={<IconDownload />}
								type='icon'
							/>
						</span>
					</Tooltip>
				) : (
					<ButtonComponent
						iconType={<IconDownload />}
						type='icon'
					/>
				)}
			</CSVLink>
		</>
	);
}
