import { useQuery } from "@tanstack/react-query";
import { getProjectionFilters } from "services/api/requests/projection";

export const useGetProjectionFilters = (ano: number) => {
    
    return useQuery(["projectionFilters", ano], getProjectionFilters, {
        onError: (error) => {
            console.log(error)
        },
        refetchOnWindowFocus: false,
    })
}