import { Tooltip } from '@chakra-ui/react';
import { IconHelp } from '@tabler/icons';

export const TooltipHelper = (message: string): JSX.Element => (
	<Tooltip
		label={message}
		placement='top'
		bg='gray.200'
		color='blue.800'
		hasArrow
		arrowSize={15}
		shouldWrapChildren
	>
		<IconHelp
			size={16}
			color='#A3AED0'
			cursor='pointer'
		/>
	</Tooltip>
);
