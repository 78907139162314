import { RowIcon } from 'components/icons/RowIcon';

export const columnsDataRecentActivityLog = [
	{
		Header: 'Usuário',
		accessor: 'usuario',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Descrição da Atividade',
		accessor: 'descricaoAtividade',
		disableSortBy: true,
		type: 'iconText',
		jsx: RowIcon,
		filter: false,
	},
	{
		Header: 'Data',
		accessor: 'data',
		type: 'date',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Tempo da Atividade',
		accessor: 'tempoAtividade',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
];
