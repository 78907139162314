import {
	Box,
	ButtonGroup,
	Divider,
	Flex,
	Icon,
	List,
	ListIcon,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Textarea,
} from '@chakra-ui/react';
import {
	IconCirclePlus,
	IconCalendarStats,
	IconUsers,
	IconChartArea,
	IconLayoutGrid,
	IconArrowBigDown,
	IconAlertTriangle,
} from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'contexts/GlobalContext';

interface AtaModalProps {
	isOpen: boolean;
	onClose: () => void;
	resultByResource: any;
}

const AumentarCapacidadeModal = (props: AtaModalProps) => {
	const { isOpen, onClose, resultByResource } = props;
	const history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size={'6xl'}
		>
			<ModalOverlay />
			<ModalContent borderRadius={20}>
				<ModalHeader
					display='flex'
					flexDir={'column'}
				>
					<Icon
						as={IconCirclePlus}
						boxSize={12}
						color={'#567EB3'}
					/>
					<Flex
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Text
							fontSize='2xl'
							fontWeight={700}
							color={'#567EB3'}
						>
							Aumentar Capacidade
						</Text>
					</Flex>
					<Divider my={4} />
					<Text color={'#314866'}>
						Escolha uma das soluções abaixo:
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex gap={4}>
						<Card
							bgColor='#E9EDF7'
							borderRadius='30px'
							justifyContent={'space-between'}
						>
							<Flex flexDirection={'column'}>
								<Icon
									as={IconLayoutGrid}
									boxSize={12}
									color={'#F0243E'}
								/>
								<Text
									color={'#314866'}
									fontSize={'24px'}
									fontWeight={'600'}
								>
									Aumentar
								</Text>
								<Text
									color={'#F0243E'}
									fontWeight={'600'}
								>
									Quantidade de recurso
								</Text>
								<Text color={'#314866'}>
									Aumentar quantidade de recursos que estão
									restringindo o desempenho
								</Text>
							</Flex>
							<List
								spacing={2}
								color={'#fff'}
								mb={6}
								mt={6}
							>
								{resultByResource?.map((resource, index) => (
									<ListItem
										key={`${resource.id}-${index}`}
										backgroundColor={'#314866'}
										borderRadius={'6px'}
										padding={'4px'}
									>
										<ListIcon
											size={'20px'}
											as={IconAlertTriangle}
											color={'yellow.300'}
										/>
										{`${
											resource.nome_grupo_recurso
										}  ${Intl.NumberFormat('pt-BR').format(
											resource.resultado.toFixed(2),
										)}  h/mês`}
									</ListItem>
								))}
							</List>
							<ButtonComponent
								fontSize={'13px'}
								type={'secondary'}
								title={'Aumentar Quantidade Recursos'}
								onClick={() =>
									history.push(
										`/${pathCompanyName}/cadastros/capacidade/recurso`,
									)
								}
							/>
						</Card>
						<Card
							bgColor='#E9EDF7'
							borderRadius='30px'
							justifyContent={'space-between'}
						>
							<Flex flexDirection={'column'}>
								<Icon
									as={IconChartArea}
									boxSize={12}
									color={'#F0243E'}
								/>
								<Text
									color={'#314866'}
									fontSize={'24px'}
									fontWeight={'600'}
								>
									Melhorar
								</Text>
								<Text
									color={'#F0243E'}
									fontWeight={'600'}
								>
									IROG
								</Text>
								<Text color={'#314866'}>
									Aumentar o IROG dos recursos que estão
									restringindo o desempenho
								</Text>
							</Flex>
							<Icon
								margin={'auto'}
								mt={8}
								strokeWidth={0.5}
								mb={8}
								as={IconArrowBigDown}
								w={'120px'}
								h={'120px'}
								stroke={'1'}
								color={'#8F9BBA'}
							/>
							<ButtonComponent
								fontSize={'13px'}
								type={'secondary'}
								title={'Melhorar IROG'}
								onClick={() =>
									history.push(
										`/${pathCompanyName}/cadastros/capacidade/recurso`,
									)
								}
							/>
						</Card>
						<Card
							bgColor='#E9EDF7'
							borderRadius='30px'
							justifyContent={'space-between'}
						>
							<Flex flexDirection={'column'}>
								<Icon
									as={IconUsers}
									boxSize={12}
									color={'#F0243E'}
								/>
								<Text
									color={'#314866'}
									fontSize={'24px'}
									fontWeight={'600'}
								>
									Aumentar
								</Text>
								<Text
									color={'#F0243E'}
									fontWeight={'600'}
								>
									Quantidade de colaboradores
								</Text>
								<Text color={'#314866'}>
									Aumentar número de colaboradores
								</Text>
							</Flex>
							<Icon
								margin={'auto'}
								mt={8}
								strokeWidth={0.5}
								mb={8}
								as={IconArrowBigDown}
								w={'120px'}
								h={'120px'}
								stroke={'1'}
								color={'#8F9BBA'}
							/>
							<ButtonComponent
								fontSize={'13px'}
								type={'secondary'}
								title={'Aumentar Quant. Colaboradores'}
								onClick={() =>
									history.push(
										`/${pathCompanyName}/cadastros/capacidade/diaUtil`,
									)
								}
							/>
						</Card>
						<Card
							bgColor='#E9EDF7'
							borderRadius='30px'
							justifyContent={'space-between'}
						>
							<Flex flexDirection={'column'}>
								<Icon
									as={IconCalendarStats}
									boxSize={12}
									color={'#F0243E'}
								/>
								<Text
									color={'#314866'}
									fontSize={'24px'}
									fontWeight={'600'}
								>
									Aumentar
								</Text>
								<Text
									color={'#F0243E'}
									fontWeight={'600'}
								>
									Turnos de trabalho
								</Text>
								<Text color={'#314866'}>
									Aumentar turno de trabalho
								</Text>
							</Flex>
							<Icon
								margin={'auto'}
								mt={8}
								strokeWidth={0.5}
								mb={8}
								as={IconArrowBigDown}
								w={'120px'}
								h={'120px'}
								stroke={'1'}
								color={'#8F9BBA'}
							/>
							<ButtonComponent
								fontSize={'13px'}
								type={'secondary'}
								title={'Aumentar Quantidade Turnos'}
								onClick={() =>
									history.push(
										`/${pathCompanyName}/cadastros/capacidade/diaUtil`,
									)
								}
							/>
						</Card>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<ButtonGroup spacing={'20px'}>
						<ButtonComponent
							type={'ghost'}
							title={'Cancelar'}
							onClick={onClose}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default AumentarCapacidadeModal;
