import { useQuery } from '@tanstack/react-query';
import { getProductById } from 'services/api/requests/product';

export const useGetProductById = (
	setForm: any,
	id: number,
	module?: string,
	form?,
) => {
	return useQuery(['productById', id], getProductById, {
		onSuccess: (data) => {
			if (module) {
				setForm({
					...form,
					codigoProduto: data.codigo_produto,
				});
			} else {
				setForm({
					id: data.empresa_id,
					produto: data.nome_produto,
					rateio: data.participa_rateio,
					status: data.status,
					familia: data.familia?.id,
					subfamilia: data.sub_familia?.id,
					modelo: '',
					codproduto: data.codigo_produto,
				});
			}
		},
		onError: (error) => {
			console.log(error);
		},
		refetchOnWindowFocus: false,
		enabled: module ? false : true,
	});
};

export const useGetProductWithId = (id: number) => {
	return useQuery(['productWithId', id], getProductById, {
		refetchOnWindowFocus: false,
	});
};
